import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientVisitListItem } from './patient-visit-list-item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateHelper } from "../../../../core/helpers/date-helper";
import { PatientService } from "../../../../core/services/patient.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientDetail } from '../../../../core/models/patient-detail.model';
import { AddVisitModalComponent } from "../../../visit/add-visit-modal/add-visit-modal.component";

@Component({
  selector: 'app-patient-detail-visits',
  templateUrl: './patient-detail-visits.component.html',
  styleUrls: ['./patient-detail-visits.component.scss']
})
export class PatientDetailVisitsComponent implements OnInit {
  @ViewChild('addVisitModal') addVisitModal: AddVisitModalComponent;
  filterForm: UntypedFormGroup;
  patientTrialId: string;
  visits: PatientVisitListItem[] = [];
  visibleVisits: PatientVisitListItem[] = [];
  showUpcomingVisits = true;
  periodSelectOptions: { value: string, text: string }[] = [];
  timeMinutesToStr = DateHelper.minutesToTimeStr;
  patient: PatientDetail;

  constructor(private readonly patientService: PatientService, private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute, private readonly router: Router) { }

  ngOnInit() {
    this.periodSelectOptions.push({ value: 'upcoming', text: 'Upcoming' });
    this.periodSelectOptions.push({ value: 'historical', text: 'Previous' });

    this.filterForm = new UntypedFormGroup({
      showVisits: new UntypedFormControl('upcoming')
    });

    this.activatedRoute.params.subscribe(params => {
      this.patientTrialId = params.id;

      this.loadPatientDetails();
      this.loadVisits();
    });
  }

  loadPatientDetails() {
    this.patientService.retrievePatientDetailFromPatientTrialId(this.patientTrialId).subscribe({
      next: patient => {
        this.patient = patient;
      },
      error: error => {
        this.alertService.showErrorAlert(error);
      }
    });
  }


  loadVisits() {
    this.patientService.retrievePatientVisitsForPatientTrial(this.patientTrialId).subscribe({
      next: visits => {
        this.visits = visits;
        this.updateView();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Sorry, there was a problem loading visits!');
      }
    });
  }

  updateView() {
    this.visibleVisits = [];
    for (const visit of this.visits) {
      if ((this.showUpcomingVisits && visit.visitUpcoming) || (!this.showUpcomingVisits && !visit.visitUpcoming)) {
        this.visibleVisits.push(visit);
      }
    }
  }

  /**
   * Called when the user clicks the 'Add Visit' button, opens the modal window
   */
  onHandleAddNewVisit(): void {
    this.addVisitModal.show(this.patient.id, this.patient.trialId, {enableSelectPatientAndTrial: false});
  }

  /**
   * Called when a visit has been added or updated via the modal window
   */
  onHandleVisitAddedOrUpdated(): void {
    this.loadVisits();
  }

  onVisitClicked(patientTrialId: string, visitId: string) {
    this.router.navigate(['/patient', patientTrialId, 'visit', visitId]).then();
  }

  onFilterChange(filterValue) {
    this.showUpcomingVisits = filterValue === 'upcoming';
    this.updateView();
  }
}
