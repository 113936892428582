import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrl: './expandable-text.component.scss'
})
export class ExpandableTextComponent implements AfterViewInit {
  @Input('text') text: string;
  @Input('maxWordCount') maxWordCount: number = 30;

  truncatedText: string;
  expanded = false;
  expandable = false;


  ngAfterViewInit() {
    const words = this.text.split(' ');
    if (words.length <= this.maxWordCount) {
      this.truncatedText = this.text;
    } else {
      this.expandable = true;
      this.truncatedText = words.slice(0, this.maxWordCount).join(' ') + '...';
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}

