<app-modal #modal class="booking-config-modal">
  <div class="modal-header">
    <h2 class="modal-title">{{ config !== null ? 'Update Configuration' : 'Add Configuration' }}</h2>
  </div>
  <div class="modal-body">

    <form [formGroup]="form" aria-hidden="true">

      <div class="row">
        <div class="col-12">
          <label>Appointment Type Name</label>
          <div class="form-group">
            <input type="text" formControlName="name">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label>Start of Day</label>
          <div class="form-group type-time">
            <mat-form-field>
              <input type="time" matInput formControlName="dayStartMinutes">
            </mat-form-field>
          </div>
        </div>
        <div class="col-3">
          <label>End of Day</label>
          <div class="form-group type-time">
            <mat-form-field>
              <input type="time" matInput formControlName="dayEndMinutes">
            </mat-form-field>
          </div>
        </div>
        <div class="col-3">
          <label>Minutes between Start Times</label>
          <div class="form-group">
            <input type="number" matInput formControlName="aptIntervalMinutes">
          </div>
        </div>
        <div class="col-3">
          <label>Appointment Duration (mins)</label>
          <div class="form-group">
            <input type="number" matInput formControlName="aptDurationMinutes">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label>Appointments Per Time Slot</label>
          <div class="form-group">
            <input type="number" matInput formControlName="maxBookingsPerSlot">
          </div>
        </div>

        <div class="col-4">
          <label>Appointment set/change lead time (days)</label>
          <div class="form-group">
            <input type="number" matInput formControlName="earliestBookingDays">
          </div>
        </div>

        <div class="col-4">
          <div class="form-group inc-weekends">
            <label class="checkbox">
              <input type="checkbox" id="includeWeekends" formControlName="includeWeekends" value="true">
              <span></span>
            </label>
            <label class="checkbox-label" for="includeWeekends">Include Weekends</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label>Trial Admin Email</label>
          <div class="form-group">
            <input type="email" formControlName="trialEmail">
          </div>
        </div>

        <div class="col-4">
          <label>Support Email</label>
          <div class="form-group">
            <input type="email" formControlName="supportEmail">
          </div>
        </div>

        <div class="col-4">
          <label>Support Phone</label>
          <div class="form-group">
            <input type="text" formControlName="supportPhone">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Invite Email Intro Text</label>
          <div class="form-group">
            <textarea class="form-control" formControlName="inviteIntroText"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Invite Email Next Steps</label>
          <div class="form-group">
            <textarea class="form-control" formControlName="inviteNextStepsText"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Booking Confirmation Email Next Steps</label>
          <div class="form-group">
            <textarea class="form-control" formControlName="confirmationNextStepsText"></textarea>
          </div>
        </div>
      </div>

      <div class="buttons">
        <div class="row">
          <div class="col-6">
            <a (click)="hide()" class="btn btn-secondary">Cancel</a>
          </div>
          <div class="col-6 text-right">
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid || form.pristine" (click)="onSubmit()">
                        <span *ngIf="form.get('processing').value">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
              <span *ngIf="!form.get('processing').value">Save</span>
            </button>
          </div>
        </div>
      </div>


    </form>

  </div>
</app-modal>
