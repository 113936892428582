import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from "../../../shared/modal/modal.component";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { SiteAutocompleteComponent } from "../../../shared/site-autocomplete/site-autocomplete.component";
import {
  PatientVisit
} from "../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { PatientDetail } from "../../../core/models/patient-detail.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SiteList } from "../../../core/models/site-list.model";
import { TravelRequestedOptions } from "../../../core/constants/travel-requested-options";
import { CaregiverTravellingOptions } from "../../../core/constants/caregiver-travelling-options";
import { VisitAttendanceOptions } from "../../../core/constants/visit-attendance-options";
import { PatientService } from "../../../core/services/patient.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { SiteService } from "../../../core/services/site.service";
import { endDateAfterStartDateValidator } from "../../../core/validators/end-after-start-date.validator";
import { LogHelper } from "../../../core/helpers/log.helper";
import { SiteAutocomplete } from "../../../shared/site-autocomplete/site-autocomplete.model";
import { IllyTime } from "../../../core/helpers/date-helper";
import { PatientUpdateVisit } from "../../../core/services/interfaces/patient-update-visit.interface";
import { BookingStatusOptions } from "../../../core/constants/booking-status-options";
import { PublishedOptions } from "../../../core/constants/published-options";
import { SelectOption } from "../../../core/models/select-option.model";
import { AdminService } from "../../../core/services/admin.service";
import { ComponentBase } from "../../../core/component-base";
import { PatientUpdateAddressComponent } from "../../../shared/patient-update-address/patient-update-address.component";
import { Address } from "../../../shared/input-address/address.model";

@Component({
  selector: 'app-edit-visit-modal',
  templateUrl: './edit-visit-modal.component.html',
  styleUrls: ['./edit-visit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditVisitModalComponent extends ComponentBase implements OnInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('visitTypeSelect') visitTypeSelect: DropdownInputComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild('travelRequestedSelect') travelRequestedSelect: DropdownInputComponent;
  @ViewChild('caregiverTravellingSelect') caregiverTravellingSelect: DropdownInputComponent;
  @ViewChild('attendanceSelect') attendanceSelect: DropdownInputComponent;
  @ViewChild('bookingStatusSelect') bookingStatusSelect: DropdownInputComponent;
  @ViewChild('publishedStatusSelect') publishedStatusSelect: DropdownInputComponent;
  @ViewChild('templateDetailsSelect') templateDetailsSelect: DropdownInputComponent;
  @ViewChild('patientUpdateAddressModal') patientUpdateAddressModal: PatientUpdateAddressComponent;

  @Output('addedOrUpdated') addedOrUpdated = new EventEmitter();

  originalVisitType: string;
  patientTrialId: string;
  visitId: string;
  visit: PatientVisit;
  isFormProcessing = false;
  patient = new PatientDetail();
  form: UntypedFormGroup;
  trialSites = new SiteList();
  sites = new SiteList();
  siteOptions: { value: string, text: string, line1: string, line2: string }[] = [];
  visitTypeOptions: { value: string, text: string }[] = [];
  travelRequestedOptions = TravelRequestedOptions.all();
  caregiverTravellingOptions = CaregiverTravellingOptions.all();
  visitAttendanceOptions = VisitAttendanceOptions.all();
  bookingStatusOptions = BookingStatusOptions.all();
  publishedOptions = PublishedOptions.all();
  coordinatorOptions: SelectOption[] = [];
  templateDetailsOptions: SelectOption[] = [];

  constructor(private readonly patientService: PatientService,
    private readonly alertService: AlertService,
    private readonly templateService: TemplateService, private readonly siteService: SiteService, private adminService: AdminService) {
    super();
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.visitTypeOptions.push({ value: 'Home', text: 'Home' });
    this.visitTypeOptions.push({ value: 'OnSite', text: 'On-Site' });
    this.visitTypeOptions.push({ value: 'Virtual', text: 'Virtual' });
    this.visitTypeOptions.push({ value: 'Telephone', text: 'Telephone' });

    this.form = new UntypedFormGroup({
      date: new UntypedFormControl(''),
      endDate: new UntypedFormControl(''),
      time: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      visitTitle: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.maxLength(1000)),
      notes: new UntypedFormControl('', Validators.maxLength(1000)),
      visitType: new UntypedFormControl('', Validators.required),
      site: new UntypedFormControl(''),
      travelRequested: new UntypedFormControl(),
      caregiverTravelling: new UntypedFormControl(),
      attendance: new UntypedFormControl(),
      bookingStatus: new UntypedFormControl(),
      publishedStatus: new UntypedFormControl(),
      designatedContacts: new UntypedFormControl(),
      templateId: new UntypedFormControl()
    }, {
      validators: endDateAfterStartDateValidator,
      updateOn: 'change'
    });
  }

  loadCoordinators() {
    this.adminService.retrieveAdminUsers(
      1,
      9999,
      "ProjectCoordinatorExpenses,ProjectCoordinatorTravel").subscribe({
        next: results => {
          for (let result of results.results) {
            this.coordinatorOptions.push({ value: result.id, text: result.firstname + ' ' + result.lastname });
          }
        }, error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('There was a problem loading coordinators, please try again!');
        }
      });
  }

  /**
   * Method to reset the form values to default
   */
  resetForm(): void {
    // Using form.reset() breaks the autocomplete
    this.form.patchValue({
      patientId: '',
      patientName: '',
      trialId: '',
      date: '',
      time: '',
      endDate: '',
      endTime: '',
      description: '',
      notes: '',
      visitType: '',
      site: 'OnSite',
      travelRequested: '',
      caregiverTravelling: '',
      attendance: '',
      bookingStatus: '',
      publishedStatus: 'Draft',
      designatedContacts: '',
      templateId: '',
    });

    this.form.get('notes')?.reset();
    this.form.get('description')?.reset();

    this.onVisitTypeChanged('OnSite');
    this.bookingStatusSelect.setValue('NotStarted');

    this.publishedStatusSelect.setValue('Draft');
  }

  show(patientTrialId: string, visitId: string): void {
    this.loadCoordinators();
    this.modal.show();
    this.resetForm();

    this.visitId = visitId;
    this.patientTrialId = patientTrialId;

    this.loadPatientDetails(patientTrialId);
  }

  /**
   * Loads the patient details, then optionally loads sites and the visit afterwards
   * @param patientTrialId
   * @param loadPatientOnly
   */
  loadPatientDetails(patientTrialId: string, loadPatientOnly = false) {
    this.patientService.retrievePatientDetailFromPatientTrialId(patientTrialId).subscribe({
      next: patient => {
        this.patient = patient;

        // Load sites, and once site are loaded, load the visit
        if (!loadPatientOnly) {
          this.loadSites(1).then(() => {
            this.loadVisit(this.visitId);
          });
        }
      },
      error: () => {
        this.alertService.showWarningAlert('Unable to load patient information!');
      }
    });
  }

  hide(): void {
    this.modal.hide();
  }

  loadVisit(visitId: string): void {
    this.patientService.retrieveVisit(visitId).subscribe(visit => {
      this.visit = visit;

      let dateStr = '';
      if (visit.date !== null && visit.date !== undefined) {
        const date = new Date(visit.date);
        dateStr = (`00${date.getDate()}`).slice(-2) + '/' + (`00${+date.getMonth() + 1}`).slice(-2) + '/' + date.getFullYear();
      }

      let endDateStr = '';
      if (visit.endDate !== null && visit.endDate !== undefined) {
        const endDate = new Date(visit.endDate);
        endDateStr = (`00${endDate.getDate()}`).slice(-2) + '/' + (`00${+endDate.getMonth() + 1}`).slice(-2) + '/' + endDate.getFullYear();
      }

      this.visitTypeSelect.setValue(visit.type);
      if (this.siteAutocomplete && visit.siteId) {
        if (visit.siteId) {
          const text = visit.siteName + ' / ' + visit.siteAddress;
          const option = new SiteAutocomplete(visit.siteId,
            '',
            text.substring(0, 30) + ' ...',
            visit.siteName,
            visit.siteAddress + ' ' + visit.siteCountry);
          this.siteAutocomplete.onSelectOption(option);

          this.siteAutocomplete.enable();
          this.siteAutocomplete.setTrialId(visit.trialId);
        }
      }

      const time = visit.timeMinutes !== null ? IllyTime.parseMinutes(visit.timeMinutes) : null;
      const endTime = visit.endTimeMinutes !== null ? IllyTime.parseMinutes(visit.endTimeMinutes) : null;

      this.form.patchValue({
        date: dateStr,
        endDate: endDateStr,
        time: time !== null ? time.to24HourString() : '',
        endTime: endTime !== null ? endTime.to24HourString() : '',
        visitTitle: visit.title,
        description: visit.description,
        notes: visit.notes,
        site: visit.siteId,
        travelRequested: visit.travelRequested,
        caregiverTravelling: visit.caregiverTravelling,
        attendance: visit.attendance,
        bookingStatus: visit.bookingStatus,
        publishedStatus: visit.publishedStatus,
        designatedContacts: visit.designatedContacts,
        templateId: visit.templateId
      });

      if (!this.templateDetailsOptions.length) {
        for (let template of visit.trialVisitTemplates) {
          this.templateDetailsOptions.push({ value: template.id, text: template.templateText });
        }
      }

      this.originalVisitType = visit.type;

      this.travelRequestedSelect.setValue(visit.travelRequested);
      this.caregiverTravellingSelect.setValue(visit.caregiverTravelling);
      this.attendanceSelect.setValue(visit.attendance);
      this.bookingStatusSelect.setValue(visit.bookingStatus);
      this.templateDetailsSelect.setValue(visit.templateId);

      this.publishedStatusSelect.setValue(visit.publishedStatus);
    });
  }

  loadSites(pageNo: number = 1): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Load sites that are assigned to this trial
      this.siteService.retrieveSites(pageNo, this.patient.trialId, null, null, false, 9999).subscribe({
        next: siteList => {
          this.trialSites = siteList;

          for (const site of siteList.results) {
            const text = site.name + ' / ' + site.address;
            this.siteOptions.push({
              value: site.id,
              text: text.substring(0, 30) + ' ...',
              line1: site.name,
              line2: site.address + ' ' + site.country
            });
          }

          resolve();
        },
        error: () => {
          reject();
          this.alertService.showWarningAlert('There was a problem loading assigned sites!');
        }
      });
    });
  }

  /**
   * Updates the date in the form when you move your mouse around, a bit of a bodge fix because angular doesn't
   * automatically detect changes
   */
  onMouseEnterForm() {
    if (this.dateInput.nativeElement.value != this.form.get('date')) {
      this.form.patchValue({ date: this.dateInput.nativeElement.value });
    }

    if (this.endDateInput.nativeElement.value != this.form.get('endDate')) {
      this.form.patchValue({ endDate: this.endDateInput.nativeElement.value });
    }
  }

  onVisitTypeChanged(value: string) {
    if (value === 'Home' && (this.patient.address === null || this.patient.address === '')) {
      this.patientUpdateAddressModal.openModal(this.patient.id, 'Missing Address', 'If creating a home visit, you will need to set this patient up with a home address.');

      // Set the visit type back to the original value
      this.visitTypeSelect.setValue(this.originalVisitType);
      this.form.patchValue({ visitType: this.originalVisitType });
    } else {
      this.form.patchValue({ visitType: value });
    }
  }

  /**
   * Called when the patient address is updated - will reload the patient details
   */
  onPatientAddressUpdated(address: Address) {
    if (address === null || !address.hasValue())
      return;

    this.loadPatientDetails(this.patientTrialId, true);

    // Wait a second, and then set the trip type to home
    setTimeout(() => {
      this.visitTypeSelect.setValue('Home');
      this.form.patchValue({ visitType: 'Home' }); // Assume the user wants to create a home visit
    }, 1000);
  }

  onFormSubmit() {
    if (this.form.valid) {
      let time = IllyTime.parseString(this.form.get('time').value);
      let endTime = IllyTime.parseString(this.form.get('endTime').value);
      const dto: PatientUpdateVisit = {
        date: this.form.get('date').value,
        endDate: this.form.get('endDate').value,
        timeMinutes: time.totalMinutes(),
        endTimeMinutes: endTime.totalMinutes(),
        title: this.form.get('visitTitle').value,
        description: this.form.get('description').value,
        notes: this.form.get('notes').value,
        visitType: this.form.get('visitType').value,
        siteId: this.form.get('site').value,
        travelRequested: this.form.get('travelRequested').value,
        caregiverTravelling: this.form.get('caregiverTravelling').value,
        attendance: this.form.get('attendance').value,
        bookingStatus: this.form.get('bookingStatus')?.value,
        publishedStatus: this.form.get('publishedStatus')?.value,
        sendPatientNotification: true,
        designatedContacts: this.form.get('designatedContacts')?.value,
        templateId: this.form.get('templateId')?.value
      };

      this.isFormProcessing = true;
      this.patientService.updateVisit(this.patient.id, this.visit.id, dto).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Visit Updated Successfully.');
          this.isFormProcessing = false;
          this.addedOrUpdated.emit();
          this.hide();
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert(error.error.title);
        }
      });
    }
  }
}
