import { Component, Input, OnInit } from '@angular/core';
import { ExpenseClaimViewModel } from 'app/core/models/expense.model';
import { PatientExpenseListItem } from 'app/core/models/patient-expenses-list.model';

@Component({
  selector: 'app-expense-check-preview',
  templateUrl: './expense-check-preview.component.html',
  styleUrls: ['./expense-check-preview.component.scss']
})
export class ExpenseCheckPreviewComponent implements OnInit {
  @Input('expense') expense: ExpenseClaimViewModel;
  @Input('patientExpense') patientExpense: PatientExpenseListItem;
  @Input() dataLoaded: boolean;

  constructor() { }

  ngOnInit(): void {
    if (!this.expense) {
      this.expense = new ExpenseClaimViewModel();
      this.expense.bankFeesIncurred = this.patientExpense.bankFeesIncurred;
      this.expense.fixedFeePerVisit = this.patientExpense.fixedFeePerVisit;
      this.expense.approvalNoReceipts = this.patientExpense.approvalNoReceipts;
      this.expense.overspendMileage = this.patientExpense.overspendMileage;
      this.expense.overspendTravel = this.patientExpense.overspendTravel;
      this.expense.overspendApproved = this.patientExpense.overspendApproved;
    }
  }
}
