import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[table-inline-cell-checkbox]',
  templateUrl: './table-inline-cell-checkbox.component.html',
  styleUrl: './table-inline-cell-checkbox.component.scss'
})
export class TableInlineCellCheckboxComponent {
  @Input() checked: boolean = false;
  @Output('itemSelected') itemSelected = new EventEmitter<boolean>();

  onItemSelected(event: any){
    this.itemSelected.emit(event.target.checked);
  }
}
