import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BankCoverFee } from './../../../../../core/models/patient.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'patient-bank-cover-fee',
  templateUrl: './patient-bank-cover-fee.component.html',
  styleUrls: ['./patient-bank-cover-fee.component.scss'],
})
export class PatientBankCoverFeeComponent implements OnInit {
  @Input() currency: string;
  thresholdsForm: UntypedFormGroup;
  @Input() data: BankCoverFee;
  @Output() removeComponentEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() componentDataEmitter: EventEmitter<BankCoverFee> = new EventEmitter<BankCoverFee>();

  ngOnInit(): void {
    this.thresholdsForm = this.initiateForm();
    this.subscribeValueChanges();
  }

  initiateForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      thresholdValue: new UntypedFormControl(this.data?.thresholdValue, Validators.required),
      feeValue: new UntypedFormControl(this.data?.feeValue, Validators.required)
    })
  }

  subscribeValueChanges() {
    this.thresholdsForm.valueChanges.subscribe(_ => {
      this.data.thresholdValue = this.thresholdsForm.get("thresholdValue").value;
      this.data.feeValue = this.thresholdsForm.get("feeValue").value;
      this.componentDataEmitter.emit(this.data);
    })
  }

  removeComponent() {
    this.removeComponentEmitter.emit(this.data.id);
  }
}
