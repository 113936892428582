import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { SelectOption } from "../../../../../core/models/select-option.model";
import { UntypedFormGroup } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: '[visit-table-cell-multiselect]',
  templateUrl: './visit-table-cell-multiselect.component.html',
  styleUrls: ['./visit-table-cell-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisitTableCellMultiselectComponent implements OnDestroy {
  @Input('items') items: SelectOption[] = [];
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('minWidth') minWidth: string;

  @Output('valueChanged') valueChanged = new EventEmitter();

  private valueChangedSubject = new Subject<any>();
  private valueChangedSubscription: Subscription;

  constructor() {
    this.valueChangedSubscription = this.valueChangedSubject.pipe(
      debounceTime(2000)
    ).subscribe(value => {
      this.valueChanged.emit(value);
    });
  }

  onValueChanged(event: any) {
    this.valueChangedSubject.next(event);
  }

  ngOnDestroy() {
    this.valueChangedSubscription.unsubscribe();
  }

}
