import { Permissions } from './../../../core/constants/permissions';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PatientDetailActivityComponent } from './patient-detail-activity/patient-detail-activity.component';
import { PatientDetail } from "../../../core/models/patient-detail.model";
import { PatientService } from "../../../core/services/patient.service";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { PatientBasicInfo } from 'app/core/models/patient.model';
import { PatientEditModalComponent } from "../../../shared/patient-edit-modal/patient-edit-modal.component";

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('notificationModal') notificationModal: ModalComponent;
  @ViewChild('activityTab') activityTab: PatientDetailActivityComponent;
  @ViewChild('patientEditModal') patientEditModal: PatientEditModalComponent;

  selectedTab = 'visits';
  patientTrialId: string;
  patient = new PatientDetail();
  patientBasicInfo = new PatientBasicInfo();
  Permissions = Permissions;
  patientLoaded = false;

  notificationForm: UntypedFormGroup;
  notificationFormIsProcessing = false;

  constructor(public authService: AuthService, private readonly templateService: TemplateService,
    private readonly location: Location, private readonly patientService: PatientService,
    private readonly activatedRoute: ActivatedRoute, private readonly alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.activatedRoute.params.subscribe(params => {
      this.patientTrialId = params.id;
      this.loadPatient()
    });

    this.notificationForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      content: new UntypedFormControl('', Validators.required)
    });
  }

  ngAfterViewInit() {
  }

  loadPatient() {
    this.patientService.retrievePatientDetailFromPatientTrialId(this.patientTrialId).subscribe({
      next: patient => {
        LogHelper.log(patient);
        this.patient = patient;
        this.patientLoaded = true;
        this.patientBasicInfo = {
          firstName: patient.firstname,
          lastName: patient.lastname,
          email: patient.email
        } as PatientBasicInfo

        this.activatedRoute.fragment.subscribe((fragment) => {
          if (fragment != null) {
            this.selectedTab = fragment;
          }
        });
      },
      error: error => {
        LogHelper.log(error);
        if (error.status === 403) {
          this.alertService.showWarningAlert('You do not have permission to view this patient.');
          void this.router.navigate(['/patient']);
        } else {
          this.alertService.showWarningAlert('Unable to load patient information!');
        }
      }
    });
  }

  onBackClick() {
    this.location.back();
  }

  onSelectTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  onOpenNotificationPopup() {
    this.notificationForm.reset();
    this.notificationModal.show();
  }

  onSendToPatient() {
    if (!this.patient.email) {
      this.alertService.showWarningAlert('No email address associated with patient.');
    } else {
      this.patientService.sendClaimAccountEmail(this.patient.id).subscribe({
        next: () => {
          this.alertService.showSuccessAlert(`Email sent to ${this.patient.email}`);
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Sorry, there was a problem sending an email to the patient.');
        }
      });
    }
  }

  onSubmitNotification() {
    const form = this.notificationForm;

    if (form.valid) {
      this.notificationFormIsProcessing = true;
      this.patientService.sendPatientNotification(
        this.patient.id,
        this.patient.trialId,
        this.notificationForm.get('title').value,
        this.notificationForm.get('content').value).subscribe({
          next: () => {
            this.notificationFormIsProcessing = false;
            this.alertService.showSuccessAlert('Notification successfully sent to patient.');
            this.notificationModal.hide();

            if (this.selectedTab === 'activity') {
              this.activityTab.refreshView();
            }

            this.notificationForm.reset();
          },
          error: error => {
            this.notificationFormIsProcessing = false;
            this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, something went wrong!');
          }
        });
    }
  }

  getLoadingText() {
    return 'Loading patient...';
  }
}
