<div class="desc">
  <p *ngIf="expense.policyReminders || expense.trialExpensePolicyReminders" class="policy-reminder">
    <span class="text">
      <span class="text-style-1">Policy Reminders: </span>
      {{ buildPolicyRemindersText(expense) }}
    </span>
  </p>
  <p class="rejected-reason caxton-blue"
    *ngIf="(expense.state === ExpenseClaimState.Processing || expense.state === ExpenseClaimState.CaxtonFailed) && expense.caxtonTransferId !== null">
    <strong>Transfer ID:</strong> {{ expense.caxtonTransferId }}
  </p>
  <p *ngIf="expense.notes">{{ expense.notes }}</p>
  <p *ngIf="expense.adminExpenseNotes"><strong>Internal notes:</strong><br> {{ expense.adminExpenseNotes }}</p>
  <p *ngIf="expense.sendToFinanceReason && expense.state === ExpenseClaimState.Approved">
    <strong>Reason for manual payment:</strong> {{expense.sendToFinanceReason}}
  </p>
  <p *ngIf="selectedTab === ExpenseClaimState.CaxtonFailed && expense.caxtonFailedReason">
    <strong>Caxton failed reason:</strong> {{expense.caxtonFailedReason}}
  </p>
  <p *ngIf="(selectedTab === ExpenseClaimState.CaxtonApproved || selectedTab === ExpenseClaimState.Processing) && expense.caxtonFailedFindings">
    <strong>Caxton failed findings:</strong> {{expense.caxtonFailedFindings}}
  </p>
</div>
