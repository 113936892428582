import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { SelectOption } from "../../core/models/select-option.model";
import { PatientService } from "../../core/services/patient.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { LogHelper } from "../../core/helpers/log.helper";

@Component({
  selector: 'app-patient-autocomplete',
  templateUrl: './patient-autocomplete.component.html',
  styleUrls: ['./patient-autocomplete.component.scss']
})
export class PatientAutoCompleteComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) inputAutoComplete: MatAutocompleteTrigger;
  @Input('parentForm') parentForm: UntypedFormGroup = null;
  @Input('controlName') controlName: string;
  @Input('options') options: SelectOption[] = [];
  @Input('className') className = '';
  @Input('placeholder') placeholder = '';
  @Input('required') required = false;
  @Input('trialId') trialId = null;

  @Output('selectionChanged') selectedChanged = new EventEmitter<SelectOption>();

  protected filteredOptions: Observable<SelectOption[]>;

  selectedOption: SelectOption = null;
  protected input = new UntypedFormControl();

  constructor(private patientService: PatientService) {
  }

  ngOnInit() {
    this.filteredOptions = this.parentForm.get(this.controlName)?.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          if (val !== null && val.length >= 3) {
            return this._filter(val || '')
          } else {
            return [];
          }
        })
      );

    // Raise event when the value of the input is cleared
    this.input.valueChanges.subscribe(value => {
      if (value === null)
        value = '';

      this.parentForm.get(this.controlName).patchValue(value.value ?? value);

      if (value === null || value === '') {
        this.selectedOption = null;
        this.parentForm.get(this.controlName).patchValue('');
      }
    });

    if (this.required) {
      this.input.setValidators([Validators.required, this.patientNameValidator.bind(this)]);
    }
  }

  getSearchTerm(): string {
    return this.input.value;
  }

  forceSearch(term: string) {
    this.input.patchValue(term);
  }

  patientNameValidator(domainName: string): {[key: string]: any} | null {
    if (this.selectedOption) {
      return null;
    }

    return {'patientName': true};
  }

  /**
   * Called when a country is selected from the country dropdown
   * @param option
   */
  selectOption(option: SelectOption) {
    this.selectedOption = option;
    this.parentForm.get(this.controlName).patchValue(option.value);
    this.selectedChanged.emit(option);
    this.input.updateValueAndValidity();
  }

  /**
   * Used to determine what value should be rendered in the country field when a selection has been made
   * @param option
   */
  displayFn(option: SelectOption): string {
    return option && option.text ? option.text : '';
  }

  setOptions(options: SelectOption[]) {
    this.options = options;
  }

  filterOptions(term: string): void {
    this.filteredOptions = this._filter(term || '');
  }

  setSelectedOption(value: string) {
    const option = this.findOptionByValue(value);
    if (option !== null) {
      this.selectedOption = option;
      this.input.patchValue(option.value);
    }
  }

  onClear() {
    this.input.patchValue('');
    this.parentForm.get(this.controlName).updateValueAndValidity();
    this.parentForm.get(this.controlName).markAsUntouched();
    this.parentForm.get(this.controlName).markAsPristine();
  }

  findOptionByValue(value: string): SelectOption {
    let foundOption: SelectOption = null;

    this.options.forEach(option => {
      if (value.toLowerCase() === option.value.toLowerCase()) {

        foundOption = option;
      }
    });

    return foundOption;
  }

  _filter(val: string): Observable<any[]> {
    LogHelper.log('trial id ' + this.trialId);
    return this.patientService.suggestPatient(val, this.trialId).pipe(map((response: any[]) => response.filter(() => true)));
  }
}
