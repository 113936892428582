<app-header></app-header>

<div class="environment-indicator" *ngIf="environmentName !== 'PRODUCTION'">{{ environmentName }}</div>


<div class="left-right-container">
  <app-sidebar></app-sidebar>

  <div class="right-main-container">
    <div class="container main-container">
      <div class="row">
        <div class="col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

<app-alert></app-alert>

<div class="fixed-version-number">
  {{ versionNumber }}
</div>
