import { SearchPage } from "./search-page.model";

export class ReimbursementSearch extends SearchPage {
  results: ReimbursementSearchResult[] = [];
}

export class ReimbursementSearchResult {
  public type: string;
  public entityId: string;
  public patientCode: string;
  public trialCode: string;
  public baseCurrency: string;
  public visitTitle: string;
  public visitDate: Date;
  public purpose: string;
  public quotedAmount: number;
  public quotedCurrency: string;
  public invoicedAmount: number;
  public invoicedCurrency: string;
  public quotedAmountBC: number;
  public invoicedAmountBC: number;
  public paymentMethod: string;
  public notes: string;
  public status: string;
}

export interface ReimbursementAndTravelAmountUpdateResult {
  amountBc?: number;
}
