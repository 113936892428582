export class SiteContactSiteViewModel {
  public id: string;
  public irgSiteId: string;
  public siteId: string;
  public siteName: string;
  public trialCode: string;
  public trialNickname: string;
  public trialOpportunityNumber: string;
  public trialId: string;
  public role: number;
  public roleName: string;
  public deleted: boolean;

  constructor(id: string, irgSiteId: string, siteId: string, trialId: string, trialCode: string, trialNickname: string, trialOpportunityNumber: string, role: number, siteName: string, roleName: string, deleted: boolean) {
    this.id = id;
    this.irgSiteId = irgSiteId;
    this.siteId = siteId;
    this.trialId = trialId;
    this.trialCode = trialCode;
    this.trialNickname = trialNickname;
    this.trialOpportunityNumber = trialOpportunityNumber;
    this.role = role;
    this.siteName = siteName;
    this.roleName = roleName;
    this.deleted = deleted;
  }
}
