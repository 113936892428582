import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { VisitService } from "../../../../core/services/visit.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { SelectOption } from "../../../../core/models/select-option.model";

@Component({
  selector: 'app-duplicate-visit-modal',
  templateUrl: './duplicate-visit-modal.component.html',
  styleUrls: ['./duplicate-visit-modal.component.scss']
})
export class DuplicateVisitModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @Output('duplicated') duplicated = new EventEmitter();

  form: UntypedFormGroup;
  visitList: SelectOption[] = [];

  constructor(private _visitService: VisitService, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      visitId: new UntypedFormControl('', Validators.required),
      targetVisitId: new UntypedFormControl('', Validators.required),
      targetVisitTitle: new UntypedFormControl()
    });

    // Update validation for target visit title
    this.form.get('targetVisitId').valueChanges.subscribe(visitId => {
      if (visitId === 'NEW') {
        this.form.get('targetVisitTitle').setValidators([Validators.required]);
      } else {
        this.form.get('targetVisitTitle').clearValidators();
      }
    });
  }

  loadPatientVisits(visitId: string): void {
    this.visitList = [];

    this.visitList.push({value: 'NEW', text: '+ Duplicate to New Visit'});

    this._visitService.associatedVisits(visitId).subscribe({
      next: visits=> visits.forEach(visit => this.visitList.push({value: visit.id, text: visit.title})),
      error: error => this._alertService.showErrorAlert(error)
    });
  }

  show(visitId: string,): void {
    this.loadPatientVisits(visitId);
    this.form.reset();

    this.form.patchValue({
      processing: false,
      visitId: visitId,
    });

    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
  }

  onHandleFormSubmit(): void {
    const form = this.form;

    if (form.valid) {
      this.form.patchValue({processing: true});
      this._visitService.duplicateVisit(
        form.get('visitId').value,
        form.get('targetVisitId').value !== 'NEW' ? form.get('targetVisitId').value : null,
        form.get('targetVisitTitle').value
      ).subscribe({
        next: () => {
          this._alertService.showSuccessAlert('Visit duplicated successfully');
          this.hide();
          this.form.patchValue({processing: false});
          this.duplicated.emit();
        }, error: error => {
          this._alertService.showErrorAlert(error);
          this.form.patchValue({processing: false});
        }
      });
    }
  }

}
