<div class="change-logs">
  <div class="change-logs-export-btn" *ngIf="!hideExportBtn">
    <a class="btn btn-outline btn-slim" (click)="exportClicked.emit()">Export Change Log</a>
  </div>

  <div *ngIf="dataLoaded">
    <div class="change-log-entry" *ngFor="let entry of results.results">
      <p>{{ entry.description }}</p>
      <p class="actioned-by">by <strong>{{ entry.name }}</strong> on <strong>{{entry.date | date:'d MMM y'}}</strong> at
        <strong>{{entry.date | date:'h:mma'}}</strong></p>
    </div>
  </div>

  <div *ngIf="!dataLoaded">
    <div class="change-log-loader" *ngFor="let _ of loaderArray">
      <p class="action-loader"><ngx-skeleton-loader class="d-block" style="width: 70%;"></ngx-skeleton-loader></p>
      <p class="actioned-by-loader"><ngx-skeleton-loader class="d-block" style="width: 40%;"></ngx-skeleton-loader></p>
    </div>
  </div>
</div>

<app-pagination *ngIf="results != null" [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
  (pageSelected)="onChangePage($event)"></app-pagination>
