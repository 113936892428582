<div class="visit-table-cell-patient-code" (dblclick)="onEdit()" [class.editing]="editing" [class.editable]="editable" style="{{ minWidth ? 'min-width:' +minWidth : ''}}">
  <span *ngIf="!editing">

    <span class="cell__truncated">
      <a (click)="patientClicked.emit()">{{ truncatedText }}</a>
    </span>

    <span class="cell__full">
      <a (click)="patientClicked.emit()">{{ text }}</a>
    </span>

  </span>

  <span *ngIf="editing && editable" class="editing" [formGroup]="parentForm">
    <input #input type="text" [formControlName]="controlName" (blur)="onBlur()" (keyup.enter)="onBlur()" *ngIf="!textarea" />
    <textarea #input type="text" class="form-control" [formControlName]="controlName" (blur)="onBlur()" *ngIf="textarea"></textarea>
  </span>
</div>
