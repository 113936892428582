import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ExpenseCheckSubTotal } from 'app/core/models/expense-check.model';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { ExpenseCategorySelected as ExpenseCategorySelectedEvent } from 'app/core/models/expense.model';

@Component({
  selector: 'app-over-budget-categories-select-modal',
  templateUrl: './over-budget-categories-select-modal.component.html',
  styleUrl: './over-budget-categories-select-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OverBudgetCategoriesSelectModalComponent {
  @ViewChild('modal') modal: ModalComponent;
  continueAvailable: boolean = false;
  selectedCategories: string[] = [];
  subTotals: ExpenseCheckSubTotal[];
  loading: boolean = false;

  @Output() categoriesSelected: EventEmitter<string[]> = new EventEmitter<string[]>()

  constructor() { }

  hideModal() {
    this.subTotals = [];
    this.modal.hide();
  }

  onRaiseOverBudget() {
    this.categoriesSelected.emit(this.selectedCategories);
  }

  toggleCategory(event: ExpenseCategorySelectedEvent) {
    if (event.checked) {
      this.selectedCategories.push(event.category);
    } else {
      const indexToRemove = this.selectedCategories.indexOf(event.category);

      if (indexToRemove !== -1) {
        this.selectedCategories.splice(indexToRemove, 1);
      }
    }

    this.continueAvailable = this.selectedCategories.length !== 0;
  }
}
