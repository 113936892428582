<!-- Start: View head -->
<div class="view-head global-visit-list">
  <div class="row">

    <div class="col-12 d-none d-md-block col-md-3">
      <h2>Project Management</h2>
    </div>

    <div class="col-12 col-md-9 text-right">
      <div class="button-container">
        <a class="btn btn-primary" (click)="showExportModal()">Export</a>
      </div>

      <form [formGroup]="searchForm">
        <app-search-input #searchInput name="searchTerm" [parentForm]="searchForm"
          (inputChange)="onSearchInputChanged($event)"></app-search-input>
      </form>

      <div class="filter-button" *ngIf="selectedTab === 'trips_expenses'">
        <a class="btn btn-secondary" (click)="filterModal.show()"><i class="fas fa-filter"></i></a>
      </div>

      <app-dropdown-input #orderBySelect
        class="order-by-dropdown"
        *ngIf="selectedTab === 'over_budget_requests'"
         [options]="orderByOptions">
      </app-dropdown-input>

      <div class="toggle-button-wrapper">
        <label class="switch mb-0">
          <input (click)="onHandleViewMyTrials()" type="checkbox" [checked]="true">
          <span class="slider round"></span>
        </label>
        <label class="switch-label">My Trials</label>
      </div>

      <div class="toggle-button-wrapper" *ngIf="selectedTab === 'over_budget_requests'">
        <label class="switch mb-0">
          <input (click)="onHandleViewOnlyOpenOverBudgetRequests()" type="checkbox" [checked]="true">
          <span class="slider round"></span>
        </label>
        <label class="switch-label">Open only</label>
      </div>
    </div>

  </div>
</div>
<!-- End: View head -->

<div class="view-body global-visits">

  <div class="tabs-wrap">
    <ul class="nav nav-tabs">
      <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab === 'trips_expenses'}" (click)="onTabClick('trips_expenses')">
             Reimbursement and Travel
          </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"[ngClass]="{'active': selectedTab === 'over_budget_requests'}" (click)="onTabClick('over_budget_requests')">
          Over budget requests
        </a>
    </li>
    </ul>
  </div>

  <div class="content-panel">
    <div *ngIf="selectedTab === 'trips_expenses'" class="horizontal-scroll">
      <table class="pgo-data-grid full-width" aria-hidden="true">
        <thead>
          <tr>
            <th>Patient ID</th>
            <th>Trial Code</th>
            <th>Visit Title</th>
            <th>Date of Visit</th>
            <th>Purpose</th>
            <th>Type</th>
            <th>Quoted Am. (LC)</th>
            <th>Quoted Cur. (LC)</th>
            <th>Quoted Am. (BC)</th>
            <th>Quoted Cur. (BC)</th>
            <th>Invoiced Am. (LC)</th>
            <th>Invoiced Cur. (LC)</th>
            <th>Invoiced Am. (BC)</th>
            <th>Invoiced Cur. (BC)</th>
            <th>Payment Method</th>
            <th>Notes</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of results.results; let i = index">
            <td class="plain-text">{{result.patientCode}}</td>
            <td class="plain-text">{{result.trialCode}}</td>
            <td class="plain-text">{{result.visitTitle}}</td>
            <td class="plain-text">{{result.visitDate | date: 'd MMM yyyy'}}</td>
            <td class="plain-text">{{result.type === 'Travel' ? displayTravelPurpose(result.purpose) : result.purpose}}
            </td>
            <td class="plain-text">{{result.type}}</td>
            <td *ngIf="forms.length" table-cell-amount
                class="col-amount" controlName="quotedAmount"
                (valueChanged)="onQuotedAmountChanged(i, result.type)"
                [editable]="authService.hasRole(roleNames.ProjectManager)"
                [amount]="result.quotedAmount !== null ? result.quotedAmount.toString() : ''"
                [parentForm]="forms[i]">
            </td>
            <td *ngIf="forms.length" table-cell-currency
                class="col-currency" controlName="quotedCurrency"
                (valueChanged)="onQuotedCurrencyChanged(i, result.type)" [parentForm]="forms[i]"
                [editable]="authService.hasRole(roleNames.ProjectManager)">
            </td>
            <td *ngIf="forms.length" table-cell-amount
                class="col-amount" controlName="quotedAmountBc"
                (valueChanged)="onQuotedAmountBcChanged(i, result.type)"
                [editable]="authService.hasRole(roleNames.ProjectManager)"
                [amount]="result.quotedAmountBC !== null ? result.quotedAmountBC.toString() : ''"
                [parentForm]="forms[i]">
            </td>
            <td class="plain-text">{{result.baseCurrency}}</td>
            <td *ngIf="forms.length" table-cell-amount
                class="col-amount" controlName="invoicedAmount"
                (valueChanged)="onInvoicedAmountChanged(i)"
                [editable]="authService.hasRole(roleNames.ProjectManager) && result.type !== 'Reimbursement'"
                [amount]="result.invoicedAmount !== null ? result.invoicedAmount.toString() : ''"
                [parentForm]="forms[i]">
            </td>
            <td *ngIf="forms.length" table-cell-currency
                class="col-currency" controlName="invoicedCurrency"
                (valueChanged)="onInvoicedCurrencyChanged(i)" [parentForm]="forms[i]"
                [editable]="authService.hasRole(roleNames.ProjectManager) && result.type !== 'Reimbursement'">
            </td>
            <td *ngIf="forms.length" table-cell-amount
                class="col-amount" controlName="invoicedAmountBc"
                (valueChanged)="onInvoicedAmountBcChanged(i)"
                [editable]="authService.hasRole(roleNames.ProjectManager) && result.type !== 'Reimbursement'"
                [amount]="result.invoicedAmountBC !== null ? result.invoicedAmountBC.toString() : ''"
                [parentForm]="forms[i]">
            </td>
            <td class="plain-text">{{result.baseCurrency}}</td>
            <td class="plain-text">{{displayPaymentMethod(result.paymentMethod)}}</td>
            <td class="plain-text">{{result.notes}}</td>
            <td class="plain-text">{{displayStatus(result.type, result.status)}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="selectedTab === 'over_budget_requests'">
      <table class="pgo-data-grid full-width" aria-hidden="true">
        <thead>
          <tr>
            <th>Date raised</th>
            <th>Patient ID</th>
            <th>Trial Code</th>
            <th>Visit title</th>
            <th>Visit date</th>
            <th>Oracle code</th>
            <th>Category</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody *ngIf="overBudgetRequests">
          <ng-container *ngFor="let overBudgetRequest of overBudgetRequests.results">
            <tr class="over-budget-row">
              <td class="plain-text">
                <mat-icon (click)="getOverBudgetRequestItems(overBudgetRequest.id)"
                  *ngIf="!overBudgetRequestItemsLoading[overBudgetRequest.id] && !showOverBudgetRequestItems[overBudgetRequest.id]">expand_more</mat-icon>
                <mat-icon (click)="hideOverBudgetRequestItems(overBudgetRequest.id)"
                  *ngIf="!overBudgetRequestItemsLoading[overBudgetRequest.id] && showOverBudgetRequestItems[overBudgetRequest.id]">expand_less</mat-icon>
                <span *ngIf="overBudgetRequestItemsLoading[overBudgetRequest.id]" class="loader">
                  <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                </span>
                {{overBudgetRequest.dateRaised | date: 'dd/MM/yy'}}
              </td>
              <td class="plain-text">{{overBudgetRequest.patientId}}</td>
              <td class="plain-text">{{overBudgetRequest.trialCode}}</td>
              <td class="plain-text">{{overBudgetRequest.visitTitle}}</td>
              <td class="plain-text">{{overBudgetRequest.visitDate | date: 'dd/MM/yy'}}</td>
              <td class="plain-text">{{overBudgetRequest.oracleCode}}</td>
              <td class="plain-text">{{overBudgetRequest.category | enumToText: OverBudgetRequestCategory}}</td>
              <td class="plain-text">{{overBudgetRequest.status | enumToText: OverBudgetRequestStatus}}</td>
            </tr>
            <tr>
              <td colspan="8">
                <app-over-budget-request-items *ngIf="showOverBudgetRequestItems[overBudgetRequest.id]"
                  [overBudgetRequestItemsViewModel]="overBudgetRequestItemsViewModels[overBudgetRequest.id]"
                  [overBudgetRequestId]="overBudgetRequest.id"
                  [patientTrialId]="overBudgetRequest.patientTrialId"
                  [overBudgetRequestCategory]="overBudgetRequest.category"
                  (deleteRequest)="onDeleteRequest(overBudgetRequest.id)"
                  (referBackRequest)="onReferBackRequest(overBudgetRequest.id)"
                  (refreshItems)="getOverBudgetRequestItems(overBudgetRequest.id, true)"
                  (overBudgetRequestClosed)="overBudgetRequest.status = OverBudgetRequestStatus.Closed">
                </app-over-budget-request-items>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <app-pagination
      [currentPage]="selectedTab === 'trips_expenses' ? results.currentPage : overBudgetRequests?.currentPage"
      [totalPageCount]="selectedTab === 'trips_expenses' ? results.totalPageCount : overBudgetRequests?.totalPageCount"
      (pageSelected)="onChangePage($event)">
    </app-pagination>
  </div>

</div>

<!-- Start: Filter modal -->
<app-modal #filterModal>
  <div class="modal-header">
    <h2 class="modal-title">Filter Visits</h2>
  </div>
  <div class="modal-body" [formGroup]="filterForm">
    <form class="modal-form-full-width" [formGroup]="filterForm">
      <div class="form-group">
        <label>Date Range Start</label>
        <app-datepickerv2-input [parentForm]="filterForm" controlName="startDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Date Range End</label>
        <app-datepickerv2-input [parentForm]="filterForm" controlName="endDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Patient</label>
        <app-patient-autocomplete #patientAutocomplete [parentForm]="filterForm"
          (selectionChanged)="onPatientSelected($event)" controlName="patientId"
          placeholder="Patient Name or Id..."></app-patient-autocomplete>
      </div>
      <div class="form-group" *ngIf="filterForm.get('patientId').value">
        <label>Visit</label>
        <app-dropdown-input #visitSelect [options]="patientVisits" [parentForm]="filterForm" name="visitId"
          placeholder="Select Visit..."></app-dropdown-input>
      </div>
      <div class="form-group">
        <label>Trial</label>
        <app-trial-autocomplete #trialAutocomplete (valueChanged)="onFilterTrialChanged($event)" [form]="filterForm"
          [patientId]="filterForm.get('patientId').value" controlName="trialCode"
          placeholder="Select a trial..."></app-trial-autocomplete>
      </div>
      <div class="form-group">
        <label>Type</label>
        <app-dropdown-input #typeSelect [options]="types" [parentForm]="filterForm" name="type"
          placeholder="Select type..."></app-dropdown-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="filterModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onFilterData()" class="btn btn-primary">
          <span *ngIf="filterForm.get('processing')?.value">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!filterForm.get('processing')?.value">Apply Filter</span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <a (click)="onResetFilter()" class="btn btn-link">Reset Filter</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Filter modal -->

<!-- Start: Export modal -->
<app-modal #exportModal>
  <div class="modal-header">
    <h2 class="modal-title">Export</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Date Range Start</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="startDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Date Range End</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="endDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Trial<span class="required-star">*</span></label>
        <!-- <app-input-multi-select (onFilter)="filterTrials($event)"
          [parentForm]="exportForm" controlName="dropdownTrialIds" [items]="filteredTrials" inputPlaceholder="Please select..."
          searchInputPlaceholder="Search for trials..." [allowFiltering]="true" (onItemClick)="onHandleTrialClicked($event)"
          [allowSelectDeselect]="true"></app-input-multi-select> -->
        <app-trial-autocomplete #exportTrialAutocomplete (valueChanged)="onExportTrialChanged($event)"
          [form]="exportForm" controlName="trialId" placeholder="Select a trial..."></app-trial-autocomplete>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="onHideExportModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button [disabled]="exportForm.invalid" (click)="export()" class="btn btn-primary">
          <span *ngIf="processingRequest">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!processingRequest">Export</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export modal -->

<app-modal #confirmDeleteModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete request?</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="deleteOverBudgetRequestForm">
      <div class="form-group">
        <label>Reason for deletion</label>
        <textarea style="width: 100% !important" class="form-control" formControlName="notes" type="text"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-secondary" (click)="confirmDeleteModal.hide()">No</button>
      </div>
      <div class="col-6">
        <button (click)="deleteOverBudgetRequest()" class="btn btn-primary" [disabled]="loading || deleteOverBudgetRequestForm.invalid">
          <span *ngIf="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </span>
          <span *ngIf="!loading">Yes</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #referBackOverBudgetRequestModal>
  <div class="modal-header">
    <h2 class="modal-title">Refer back over budget request ?</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="referBackOverBudgetRequestForm">
      <div class="form-group">
        <label>Notes</label>
        <textarea style="width: 100% !important" class="form-control" formControlName="notes" type="text"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-secondary" (click)="referBackOverBudgetRequestModal.hide()">No</button>
      </div>
      <div class="col-6">
        <button (click)="referBackOverBudgetRequest()" class="btn btn-primary" [disabled]="loading || referBackOverBudgetRequestForm.invalid">
          <span *ngIf="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </span>
          <span *ngIf="!loading">Yes</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
