<div *ngIf="mileageRateRules?.length" class="rates-list">
  <div class="rates-rows">
    <div class="rates-columns">
      <label style="flex: 1 0 5%;">Engine Size</label>
      <label style="flex: 7 0 5%;">Rates</label>
      <label style="flex: 5 0 5%;">Notes</label>
    </div>
    <div class="rate" *ngFor="let rule of mileageRateRules" (click)="openUpdateRuleModal(rule)">
      <span style="flex: 1 0 5%;">{{rule.engineSize}}</span>
      <span style="flex: 7 0 5%;">{{getShorthandNotation(rule.distanceLimits)}}</span>
      <span style="flex: 5 0 5%;">{{rule.note}}</span>
    </div>
  </div>
</div>
