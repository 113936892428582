import { Permissions } from './../../../../../core/constants/permissions';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { PatientTrip, PatientVisit } from './patient-visit-detail.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { DateHelper } from "../../../../../core/helpers/date-helper";
import { PatientDetail } from "../../../../../core/models/patient-detail.model";
import { PatientService } from "../../../../../core/services/patient.service";
import { LogHelper } from "../../../../../core/helpers/log.helper";
import { AuthService } from "../../../../../core/services/auth.service";
import { AlertService } from "../../../../../shared/alert/alert.service";
import { TemplateService } from "../../../../../core/services/template.service";
import { ModalComponent } from "../../../../../shared/modal/modal.component";
import { StringHelper } from "../../../../../core/helpers/string-helper";
import { EditVisitModalComponent } from "../../../../visit/edit-visit-modal/edit-visit-modal.component";
import { AddEditTripModalComponent } from "../../../../visit/add-edit-trip-modal/add-edit-trip-modal.component";
import { TripBookingStatus } from "../../../../../core/constants/trip-booking-status";
import { TravelRequestService } from "../../../../../core/services/travel-request.service";

@Component({
  selector: 'app-patient-visit-detail',
  templateUrl: './patient-visit-detail.component.html',
  styleUrls: ['./patient-visit-detail.component.scss']
})
export class PatientVisitDetailComponent implements OnInit {
  @ViewChild('redDetailsBar') redDetailsBar: ElementRef;
  @ViewChild('deleteTripModal') deleteTripModal: ModalComponent;
  @ViewChild('deleteVisitModal') deleteVisitModal: ModalComponent;
  @ViewChild('editVisitModal') editVisitModal: EditVisitModalComponent;
  @ViewChild('addEditTripModal') addEditTripModal: AddEditTripModalComponent;

  patientTrialId: string;
  patient = new PatientDetail();
  visit = new PatientVisit();
  draggingAtIndex: number;
  isDraggingTrip = false;
  deleteTrip: PatientTrip;
  isDeleteProcessing = false;
  isDeleteVisitProcessing = false;
  timeMinutesToStr = DateHelper.minutesToTimeStr;
  stringHelper = StringHelper;
  Permissions = Permissions;
  maxTripRefreshAttempts = 10;
  tripRefreshAttempts = 0;
  refreshTripsInterval = 5000;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const redBarContainer = document.getElementById('red-bar-container');
    const redBarElement = this.redDetailsBar.nativeElement;
    const headerHeightAndOffset = document.getElementById('pgo-header').offsetHeight + 15;
    const containerTopPos = redBarContainer.getBoundingClientRect().top - headerHeightAndOffset;

    if (containerTopPos <= 0 && !redBarElement.classList.contains('fixed')) {
      redBarElement.style.width = redBarElement.offsetWidth + 'px';
      redBarElement.classList.add('fixed');
      redBarElement.style.top = headerHeightAndOffset + 'px';
      redBarContainer.style.height = redBarElement.offsetHeight + 30 + 'px';
    } else if (containerTopPos > 0 && redBarElement.classList.contains('fixed')) {
      redBarElement.classList.remove('fixed');
      redBarElement.style.width = null;
      redBarElement.style.top = null;
      redBarContainer.style.height = null;
    }
  }

  constructor(@Inject(DOCUMENT) private document: Document, public authService: AuthService,
    private readonly patientService: PatientService, private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute, private readonly templateService: TemplateService,
    private readonly router: Router, private readonly travelRequestService: TravelRequestService) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.loadVisit();
  }

  loadRefreshTrips() {
    if (this.tripRefreshAttempts < this.maxTripRefreshAttempts) {
      this.tripRefreshAttempts++;
            setTimeout(() => {
              this.loadVisit();
            }, this.refreshTripsInterval);
    }
  }

  loadVisit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.patientTrialId = params.id;
      this.patientService.retrieveVisit(params.visitId).subscribe({
        next: visit => {
          this.visit = visit;

          // If there are PDF's in the results that are still generating, refresh the trips every 5 seconds
          const tripsGeneratingPdfs = this.visit.trips.filter(trip => trip.pdfGenerating);
          if (tripsGeneratingPdfs.length > 0)
            this.loadRefreshTrips();
        },
        error: error => {
          LogHelper.log(error);
        }
      });

      this.patientService.retrievePatientDetailFromPatientTrialId(params.id).subscribe({
        next: patient => {
          this.patient = patient;
        },
        error: error => {
          this.alertService.showWarningAlert('Unable to load patient information!');
        }
      });
    });
  }

  onCopiedToClipboard(text: string) {
    this.alertService.showSuccessAlert(text);
  }

  onDeleteTrip(trip: PatientTrip) {
    this.deleteTrip = trip;
    this.deleteTripModal.show();
  }

  onConfirmDeleteTrip() {
    this.isDeleteProcessing = true;
    this.alertService.clearAll();
    this.patientService.deleteTrip(this.deleteTrip.id).subscribe({
      next: () => {
        const index: number = this.visit.trips.indexOf(this.deleteTrip);
        if (index !== -1) {
          this.visit.trips.splice(index, 1);
        }

        this.deleteTripModal.hide();
        this.alertService.showSuccessAlert('Trip successfully removed');
        this.isDeleteProcessing = false;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem removing the trip!');
        this.deleteTripModal.hide();
        this.isDeleteProcessing = false;
      }
    });
  }

  /**
   * Called when the user selects to edit the visit
   */
  onHandleEditVisit(): void {
    this.editVisitModal.show(this.patientTrialId, this.visit.id);
  }

  /**
   * Called when a new trip is added via the trip modal
   */
  onHandleTripAdded(data: {visitId: string, addMuvRides: boolean}): void {
    this.loadVisit();

    if (data.addMuvRides) {
      this.addEditTripModal.showEditTripModal(data.visitId, 'MUV Rides');
    }
  }

  /**
   * When dragging an item starts, store it's current index so we can refer to it later
   * @param index
   */
  onDrag(index: number) {
    this.draggingAtIndex = index;
    this.isDraggingTrip = true;
  }

  onDeleteVisitConfirmed() {
    this.isDeleteVisitProcessing = true;
    this.patientService.deleteVisit(this.visit.id).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Visit Successfully Delete.');
        this.router.navigate(['/patient', this.patientTrialId]).then();
      },
      error: () => {
        this.isDeleteVisitProcessing = false;
        this.deleteVisitModal.hide();
        this.alertService.showWarningAlert('There was a problem deleting the visit.');
      }
    });
  }

  /**
   * Re-order the trips array when a trip has been dropped into a new position
   * @param event
   */
  onDrop(event: CdkDragDrop<string[]>) {
    this.isDraggingTrip = false;
    moveItemInArray(this.visit.trips, this.draggingAtIndex, event.currentIndex);
    setTimeout(this.reOrderTrips, 1000); // Wait a second before persisting
    this.reOrderTrips();
  }

  reOrderTrips() {
    if (!this.isDraggingTrip) { // Prevent making an API call if the user is dragging another item
      const tripOrder: { tripId: string; order: number }[] = [];
      let i = 0;
      for (const trip of this.visit.trips) {
        tripOrder.push({ tripId: trip.id, order: i });
        i++;
      }
      this.patientService.reOrderTrips(tripOrder).subscribe({
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to re-order to trips!');
        }
      });
    }
  }

  getTripBookingStatusDescription(status: string): string {
    return TripBookingStatus.getDescription(status);
  }

  getDepartureLabel(type: string) {
    switch (type) {
      case 'Accommodation':
        return 'Check-in';
      case 'Rental Car':
        return 'Pick-up';
    }

    return 'Departure';
  }

  getArrivalLabel(type: string) {
    switch (type) {
      case 'Accommodation':
        return 'Check-out';
      case 'Rental Car':
        return 'Drop-off';
    }

    return 'Arrival';
  }

}
