import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from "../../../core/services/site.service";
import { Countries } from "../../../core/constants/countries";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { SelectOption } from "../../../core/models/select-option.model";
import { ApiConsumerSimple } from "../../../core/models/api-consumer-simple.model";
import { ApiConsumerService } from "../../../core/services/api-consumer.server";

@Component({
  selector: 'app-site-add',
  templateUrl: './site-add.component.html'
})
export class SiteAddComponent implements OnInit {
  form: UntypedFormGroup;
  isFormProcessing = false;
  countryOptions: { value: string, text: string }[] = [];
  apiConsumerOptions: SelectOption[] = [];

  constructor(private templateService: TemplateService, private apiConsumerService: ApiConsumerService, private readonly activatedRoute: ActivatedRoute,
              private readonly siteService: SiteService, private readonly alertService: AlertService, private readonly router: Router) {
    for (const country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      irgSiteId: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      siteAddress: new UntypedFormControl(null, Validators.required),
      apiConsumer: new UntypedFormControl(false),
      apiConsumerId: new UntypedFormControl('')
    });

    this.loadAndSetApiConsumerOptions();
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;
      this.siteService.createSite(
        this.form.get('name').value,
        this.form.get('address').value,
        this.form.get('country').value,
        this.form.get('irgSiteId').value,
        this.form.get('siteAddress').value,
        this.form.get('apiConsumerId').value
      ).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Site Added Successfully');
          this.router.navigate(['/site']);
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('Unable to create site, please try again!');
        }
      });
    }
  }

  /**
   * Retrieves and sets the api consumer options to be used in the api consumer dropdown
   */
  loadAndSetApiConsumerOptions(): void {
    this.apiConsumerService.getAllApiConsumers().subscribe({
      next: (apiConsumers: any) => {
        this.apiConsumerOptions = apiConsumers.map((apiConsumer: ApiConsumerSimple) => {
          const consumerName = apiConsumer.enabled ? apiConsumer.name : `${apiConsumer.name} (Temporarily disabled)`;
          return new SelectOption(apiConsumer.id, consumerName);
        });
      }
    });
  }
}
