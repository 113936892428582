<div class="search-container" #searchContainer>
  <div class="mobile-search-icon">
    <a (click)="onMobileIconClick()" class="btn btn-secondary icon-only" data-toggle="search"><i class="icon icon-search" aria-hidden="true"></i></a>
  </div>

  <div class="search-field" [formGroup]="parentForm" #inputContainer>
    <input #inputField type="text" autocomplete="off" [formControlName]="name" placeholder="Search ..." [ngClass]="{'filled': inputHasValue}" (input)="onInputValueChanged($event)" />
    <i class="icon icon-search" *ngIf="!inputHasValue" aria-hidden="true"></i>
    <i class="icon icon-cancel" *ngIf="inputHasValue" (click)="onClearInput()" aria-hidden="true"></i>
  </div>
</div>
