<div class="site-user-trial-list" [ngClass]="{'visibility-hidden': !showAssignForm}">
  <div class="row">
    <div class="col-12 text-right">
      <a class="btn btn-outline btn-outline-slim" (click)="onHandleShowAssignTrial()">Assign Site Contact</a>
    </div>
  </div>

  <table aria-hidden="true" class="with-heading-columns">
    <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Role</th>
      <th>Status</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let contact of assignedContacts; let i = index" [ngClass]="{'visibility-hidden': contact.deleted}">
      <td>{{ contact.fullname }}</td>
      <td>{{ contact.email }}</td>
      <td>{{ contact.roleName | pascalSpaces }}</td>
      <td><span class="badge" [ngClass]="{'badge-success': contact.isActive, 'badge-warning': !contact.isActive}">{{ contact.isActive ? 'Active' : 'Inactive' }}</span></td>
      <td class="btns site-actions">
        <a (click)="onHandleEditAtIndex(i)" class="btn btn-secondary btn-edit">Edit</a>
        <a (click)="unassignModel.showUsingDeleteIndex(i)" class="btn btn-outline btn-slim btn-auto-width" data-toggle="modal" data-target="#delete-user-modal">Unassign</a>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<div class="site-user-trial-assign" [ngClass]="{'visibility-hidden': showAssignForm}">
  <h2><a (click)="onHandleReturnToForm()"><i aria-hidden="true" class="far fa-angle-left"></i></a>
    <span *ngIf="modifyAtIndex >= 0">Edit Site Contact</span>
    <span *ngIf="modifyAtIndex < 0">Assign Site Contact</span>
  </h2>

  <table aria-hidden="true" class="simple-form">
    <tr>
      <th style="min-width: 50px;"><label>Site Contact</label></th>
      <td>
        <span *ngIf="modifyAtIndex >= 0" class="site-contact-name">{{ assignedContacts[modifyAtIndex].fullname }}
          ({{ assignedContacts[modifyAtIndex].email }})</span>

        <div [class.visibility-hidden]="modifyAtIndex >= 0">
          <app-site-contacts-dropdown #contactDropdown [options]="allContacts" [parentForm]="assignContactForm"
                                      controlName="siteContactId"></app-site-contacts-dropdown>
          <br>
          <a (click)="createSiteContactModal.open()" class="btn btn-outline btn-outline-slim">Add New Contact</a>
        </div>
      </td>
    </tr>

    <tr>
      <th style="vertical-align: middle; width: 120px;"><label>Site Role</label></th>
      <td>
        <app-dropdown-input #roleDropdown [parentForm]="assignContactForm" [options]="roleOptions" name="role" placeholder="Select role..."></app-dropdown-input>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <div class="btn-wrap">
          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-primary" (click)="onHandleAssignContact()" [disabled]="!assignContactForm.valid" *ngIf="modifyAtIndex < 0">Assign</button>
              <button type="submit" class="btn btn-primary" (click)="onHandleUpdateSite(modifyAtIndex)" [disabled]="!assignContactForm.valid" *ngIf="modifyAtIndex >= 0">Update</button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>

<!-- Start: Unassign site modal -->
<app-modal #unassignModel>
  <div class="modal-header">
    <h2 class="modal-title">Unassign Site Contact?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to unassign the site contact from this trial/site?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onHandleDeleteConfirmed(unassignModel.deleteIndex)" class="btn btn-primary">
          Unassign
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign site modal -->

<app-create-site-contact-modal #createSiteContactModal></app-create-site-contact-modal>
