import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertService } from "../../../shared/alert/alert.service";
import { VisitService } from "../../../core/services/visit.service";
import { VisitSearch, VisitSearchResult } from "../../../core/models/visit-search.model";
import { TemplateService } from "../../../core/services/template.service";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AdminService } from "../../../core/services/admin.service";
import { SelectOption } from "../../../core/models/select-option.model";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AuthService } from "../../../core/services/auth.service";
import { Permissions } from '../../../core/constants/permissions';
import {
  PatientTrip
} from "../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { PatientService } from "../../../core/services/patient.service";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PatientAutoCompleteComponent } from "../../../shared/patient-autocomplete/patient-autocomplete.component";
import { TrialAutocomplete } from "../../../shared/trial-autocomplete/trial-autocomplete.model";
import { VisitSearchCriteria } from "../../../core/models/visit-search-criteria.model";
import { BookingStatusOptions } from "../../../core/constants/booking-status-options";
import { TrialAutocompleteComponent } from "../../../shared/trial-autocomplete/trial-autocomplete.component";
import { AddEditTripModalComponent } from "../add-edit-trip-modal/add-edit-trip-modal.component";
import { MonthOptions } from "../../../core/constants/month-options";
import { VisitPatientDetailModalComponent } from "./visit-patient-detail-modal/visit-patient-detail-modal.component";
import { TripBookingStatus } from "../../../core/constants/trip-booking-status";
import {
  TripsOverBudgetRequestModalComponent
} from '../trips-over-budget-request-modal/trips-over-budget-request-modal.component';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisitsComponent implements OnInit, AfterViewInit {
  @ViewChild('deleteVisitModal') deleteVisitModal: ModalComponent;
  @ViewChild('patientAutocomplete') patientAutocomplete: PatientAutoCompleteComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent
  @ViewChild('exportPatientAutocomplete') exportPatientAutocomplete: PatientAutoCompleteComponent;
  @ViewChild('exportTrialAutocomplete') exportTrialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('filterModal') filterModal: ModalComponent;
  @ViewChild('exportModal') exportModal: ModalComponent;
  @ViewChild('horizontalScroll') horizontalScroll: ElementRef;
  @ViewChild('addEditTripModal') addEditTripModal: AddEditTripModalComponent;
  @ViewChild('deleteTripModal') deleteTripModal: ModalComponent;
  @ViewChild('visitPatientDetailModal') visitPatientDetailModal: VisitPatientDetailModalComponent;
  @ViewChild('tripsOverBudgetRequestModal') tripsOverBudgetRequestModal: TripsOverBudgetRequestModalComponent;

  monthOptions: SelectOption[] = MonthOptions.all();
  yearOptions: SelectOption[] = [];
  coordinatorOptions: SelectOption[] = [];
  filterDesignatedContacts: SelectOption[] = [];
  bookingStatusOptions = BookingStatusOptions.all();
  results = new VisitSearch();
  expandedVisits: string[] = [];
  searchForm: UntypedFormGroup;
  Permissions = Permissions;
  scrollPosition = 0;
  isDeleteProcessing = false;
  deleteTrip: PatientTrip;
  filterForm: UntypedFormGroup;
  exportForm: UntypedFormGroup;
  isDeleteVisitProcessing = false;
  deletingVisitId: string;
  pageSize = 25;
  overBudgetTrips: Record<string, string[]> = {};
  raiseOverBudgetAvailable: Record<string, boolean> = {};
  raiseOverBudgetLoading: Record<string, boolean> = {};

  currentMonth: { month: number, year: number };

  constructor(public _authService: AuthService, private _templateService: TemplateService, private _patientService: PatientService, private _adminService: AdminService, private _alertService: AlertService, private _visitService: VisitService, private _renderer: Renderer2) {
    this._templateService.showHeader();
  }

  ngOnInit(): void {
    const today = new Date();
    this.currentMonth = this.getCurrentMonthAndYear(today);

    for (let i = this.currentMonth.year - 2; i <= this.currentMonth.year + 1; i++) {
      this.yearOptions.push({ value: i.toString(), text: i.toString() });
    }

    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl(),
      myVisits: new UntypedFormControl(false)
    });

    this.filterForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      showDateRangeInputs: new UntypedFormControl(true),
      patientId: new UntypedFormControl(),
      trialId: new UntypedFormControl(),
      trialCode: new UntypedFormControl(),
      bookingStatus: new UntypedFormControl(''),
      designatedContact: new UntypedFormControl(''),
      showHistoricOpen: new UntypedFormControl(true),
      month: new UntypedFormControl((today.getMonth() + 1).toString()),
      year: new UntypedFormControl(today.getUTCFullYear().toString()),
      startDate: new UntypedFormControl(new Date()),
      endDate: new UntypedFormControl()
    });

    this.exportForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      patientId: new UntypedFormControl(),
      trialId: new UntypedFormControl(),
      trialCode: new UntypedFormControl(),
      bookingStatus: new UntypedFormControl(''),
      designatedContact: new UntypedFormControl(''),
      showHistoricOpen: new UntypedFormControl(true),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl()
    });

    this.loadCoordinators();
    this.loadVisits(1);
  }

  // Returns the current month and year
  private getCurrentMonthAndYear(date: Date): { month: number, year: number } {
    const month = date.getMonth();
    const year = date.getUTCFullYear();

    return { month: month, year: year };
  }

  ngAfterViewInit() {
    // Add the full-width classname to the main container to force the table to fill the available space
    const mainContainer = document.querySelector('.main-container');
    mainContainer.classList.add('full-width');

    // Add an all option to the booking status dropdown
    this.bookingStatusOptions.unshift({ value: '', text: 'All' });

    // Add an all option to the designated contact dropdown
    this.filterDesignatedContacts.unshift({ value: '', text: 'All' });

    // Set the trial Id when a trial code is selected
    this.trialAutocomplete.valueChanged.subscribe((trial: TrialAutocomplete) => {
      if (trial !== null) {
        this.filterForm.patchValue({ trialId: trial.id });
      } else {
        this.filterForm.patchValue({ trialId: null });
      }
    });

    // If end date is supplied in the advanced filter, disable 'Show open'
    /*
    this.filterForm.get('endDate').valueChanges.subscribe((endDate) => {
      if (endDate !== null) {
        this.filterForm.patchValue({showHistoricOpen: false});
      }
    });
     */

    this.filterForm.get('month').valueChanges.subscribe((month) => {
      this.setFilterRangeBasedOnMonthAndYear(+month, +this.filterForm.get('year').value);
    });

    this.filterForm.get('year').valueChanges.subscribe((year) => {
      this.setFilterRangeBasedOnMonthAndYear(+this.filterForm.get('month').value, +year);
    });
  }

  setFilterRangeBasedOnMonthAndYear(month: number, year: number) {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0);

    this.filterForm.patchValue({
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth
    });
  }

  onToggleDateRangeInputs(): void {
    this.filterForm.patchValue({ showDateRangeInputs: !this.filterForm.get('showDateRangeInputs').value });
  }

  /**
   * Called when the user clicks to apply a filter
   */
  onFilterVisits(): void {
    // When filtering date, criteria should be copied across to the export form
    this.exportForm.patchValue({
      processing: false,
      patientId: this.filterForm.get('patientId').value,
      trialId: this.filterForm.get('trialId').value,
      trialCode: this.filterForm.get('trialCode').value,
      bookingStatus: this.filterForm.get('bookingStatus').value,
      designatedContact: this.filterForm.get('designatedContact').value,
      showHistoricOpen: this.filterForm.get('showHistoricOpen').value,
      startDate: this.filterForm.get('startDate').value,
      endDate: this.filterForm.get('endDate').value
    });

    this.filterForm.patchValue({ processing: true });
    this.loadVisits(1);
    this.filterModal.hide();
  }

  /**
   * Called when the user selects to reset the advanced filter
   */
  onResetFilter(): void {
    const today = new Date();

    this.filterForm.patchValue({
      processing: false,
      showDateRangeInputs: true,
      patientId: null,
      trialId: null,
      trialCode: null,
      bookingStatus: '',
      designatedContact: '',
      showHistoricOpen: true,
      month: (today.getMonth() + 1).toString(),
      year: today.getUTCFullYear().toString(),
      startDate: new Date(),
      endDate: ''
    });

    this.trialAutocomplete.reset();
    this.patientAutocomplete.onClear();

    this.loadVisits(1);
    this.filterModal.hide();
  }

  onShowExportFilter(): void {
    this.exportModal.show();

    // We need to force the export patient autocomplete to perform the same search as applied to the filter
    // so that any pre-selected patient is available in the list of results for selection
    this.exportPatientAutocomplete.forceSearch(this.patientAutocomplete.getSearchTerm());

    this.exportTrialAutocomplete.setInitialValue(this.trialAutocomplete.selected.id, this.trialAutocomplete.selected.label);
  }

  loadCoordinators() {
    this._adminService.retrieveAdminUsers(
      1,
      9999,
      "ProjectCoordinatorExpenses,ProjectCoordinatorTravel").subscribe({
        next: results => {
          for (let result of results.results) {
            this.coordinatorOptions.push({ value: result.id, text: result.firstname + ' ' + result.lastname });
            this.filterDesignatedContacts.push({ value: result.id, text: result.firstname + ' ' + result.lastname });
          }
        }, error: error => {
          LogHelper.log(error);
          this._alertService.showWarningAlert('There was a problem loading coordinators, please try again!');
        }
      });
  }

  /**
   * Loads visits
   * @param page
   * @param showLoading
   */
  loadVisits(page = 1, showLoading = true): void {
    const criteria = new VisitSearchCriteria({
      page: page,
      pageSize: this.pageSize,
      myTasks: this.searchForm.get('myVisits').value,
      searchTerm: this.getFormControlValueOrNull(this.searchForm.get('searchTerm')),
      patientId: this.getFormControlValueOrNull(this.filterForm.get('patientId')),
      trialId: this.getFormControlValueOrNull(this.filterForm.get('trialId')),
      startDate: this.filterForm.get('startDate').value,
      endDate: this.filterForm.get('endDate').value,
      bookingStatus: this.filterForm.get('bookingStatus').value !== null && this.filterForm.get('bookingStatus').value !== '' ? this.filterForm.get('bookingStatus').value : null,
      showAllOpenVisits: this.filterForm.get('showHistoricOpen').value,
      designatedContact: this.filterForm.get('designatedContact').value
    });

    if (!this.results.loading) {
      this.results.loading = showLoading;
      this._visitService.visits(criteria).subscribe({
        next: (results) => {
          this.results = results;
          this.results.loading = false;
          this.filterForm.patchValue({ processing: false });

          // Go through all expanded visits and reload trips or else they will be empty
          this.expandedVisits.forEach(expanded => {
            this.loadTripsForVisits(expanded);
          });
        },
        error: (error) => {
          this._alertService.showErrorAlert(error);
          this.results.loading = false;
          this.filterForm.patchValue({ processing: false });
        }
      });
    }
  }

  private getFormControlValueOrNull(control: AbstractControl): string | null {
    return control.value != null && control.value !== '' ? control.value : null;
  }

  onExportTrialChanges(trial: TrialAutocomplete): void {
    this.exportForm.patchValue({ trialId: trial.id });
  }

  /**
   * Updates the scroll position when the user scrolls left/right on the table
   */
  onScroll() {
    fromEvent(this.horizontalScroll.nativeElement, 'scroll')
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.scrollPosition = this.horizontalScroll.nativeElement.scrollLeft;
      });
  }


  /**
   * Called when the search input has changed
   * @param searchTerm
   */
  onSearchInputChanged(searchTerm: string): void {
    if (searchTerm === '')
      this.searchForm.patchValue({ searchTerm: '' });

    this.loadVisits(1);
  }

  onHandleViewMyTasks(): void {
    const showMyVisits = this.searchForm.get('myVisits').value;
    this.searchForm.patchValue({ myVisits: !showMyVisits });

    setTimeout(() => {
      this.loadVisits(1);
    }, 600);
  }

  onChangePage(page: number): void {
    this.loadVisits(page);
  }

  /**
   * Adds a visit to the list of expanded visits
   * @param visitId
   */
  onExpandVisit(visitId: string): void {
    this.loadTripsForVisits(visitId);
    this.expandedVisits.push(visitId);
  }

  /**
   * Reloads visits when a visit has been added or updated via a modal window
   */
  onHandleVisitAddedOrUpdated(): void {
    this.loadVisits(this.results.currentPage, false);
  }

  /**
   * Removes a visit from the list of expanded visits
   * @param visitId
   */
  onCollapseVisit(visitId: string): void {
    this.expandedVisits.splice(this.expandedVisits.indexOf(visitId), 1);
  }

  /**
   * Called when a new trip has been added
   */
  onHandleTripAdded(data: { visitId: string, addMuvRides: boolean }): void {
    this.loadTripsForVisits(data.visitId);
  }

  /**
   * Called when an existing trip has been updated
   * @param visitId
   */
  onHandleTripUpdated(visitId: string): void {
    this.loadTripsForVisits(visitId);
  }

  loadTripsForVisits(visitId: string): void {
    if (visitId !== undefined && visitId !== null) {
      this._visitService.trips(visitId).subscribe({
        next: (trips) => {
          this.overBudgetTrips[visitId] = [];
          this.raiseOverBudgetAvailable[visitId] = false;
          this.raiseOverBudgetLoading[visitId] = false;
          let visit = this.results.results.find(x => x.id === visitId);
          visit.trips = trips;

          trips.forEach(t => {
            if (t.overBudget) {
              this.onTripSelected(true, t.id, visitId)
            }
          })
        },
        error: (error) => {
          LogHelper.log(error);
          this._alertService.showErrorAlert(error);
        }
      });
    }
  }

  /**
   * Called when the user selects to add a trip to a visit, opens the add trip modal
   * @param visitId
   */
  onAddTripToVisit(visitId: string, muvEnabled: boolean): void {
    this.addEditTripModal.showAddTripModal(visitId, muvEnabled);
  }

  onDeleteTrip(trip: PatientTrip) {
    this.deleteTrip = trip;
    this.deleteTripModal.show();
  }

  onConfirmDeleteTrip() {
    this.isDeleteProcessing = true;
    this._alertService.clearAll();
    this._patientService.deleteTrip(this.deleteTrip.id).subscribe({
      next: () => {
        this.loadVisits(this.results.currentPage, false);

        this.deleteTripModal.hide();
        this._alertService.showSuccessAlert('Trip successfully removed');
        this.isDeleteProcessing = false;
        this.loadTripsForVisits(this.deleteTrip.visitId);
        this.deleteTrip = null;
      },
      error: error => {
        LogHelper.log(error);
        this._alertService.showWarningAlert('There was a problem removing the trip!');
        this.deleteTripModal.hide();
        this.isDeleteProcessing = false;
      }
    });
  }

  onDeleteVisit(visitId: string) {
    this.deletingVisitId = visitId;
    this.deleteVisitModal.show();
  }

  onDeleteVisitConfirmed() {
    this.isDeleteVisitProcessing = true;
    this._patientService.deleteVisit(this.deletingVisitId).subscribe({
      next: () => {
        this._alertService.showSuccessAlert('Visit Successfully Deleted.');
        this.isDeleteVisitProcessing = false;
        this.deletingVisitId = null;
        this.deleteVisitModal.hide();
        this.loadVisits(this.results.currentPage, false);
      },
      error: (error) => {
        this.isDeleteVisitProcessing = false;
        this.deleteVisitModal.hide();
        this._alertService.showErrorAlert(error);
      }
    });
  }

  onVisitDuplicated(): void {
    this.loadVisits(this.results.currentPage, false);
  }

  onHandleShowPatientDetail(visit: VisitSearchResult): void {
    this.visitPatientDetailModal.show(visit.patientId, visit.patientTrialId, visit.id);
  }

  renderAmount(amount: number): string {
    if (amount === undefined || amount === null)
      return '';

    return amount.toFixed(2);
  }

  getTripBookingStatusDescription(status: string): string {
    return TripBookingStatus.getDescription(status);
  }

  onExportVisits(): void {
    const criteria = new VisitSearchCriteria({
      patientId: this.getFormControlValueOrNull(this.exportForm.get('patientId')),
      trialId: this.getFormControlValueOrNull(this.exportForm.get('trialId')),
      startDate: this.exportForm.get('startDate').value,
      endDate: this.exportForm.get('endDate').value,
      bookingStatus: this.exportForm.get('bookingStatus').value !== null && this.exportForm.get('bookingStatus').value !== '' ? this.exportForm.get('bookingStatus').value : null,
      showAllOpenVisits: this.exportForm.get('showHistoricOpen').value,
      designatedContact: this.exportForm.get('designatedContact').value
    });

    this.exportForm.patchValue({ processing: true });
    this._visitService.exportVisits(criteria).subscribe({
      next: () => {
        this._alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportForm.patchValue({ processing: false });
        this.exportModal.hide();
      },
      error: err => {
        this._alertService.showErrorAlert(err);
        this.exportForm.patchValue({ processing: false });
      }
    });
  }

  onTripSelected(selected: boolean, tripId: string, visitId: string) {
    if (selected) {
      if (this.overBudgetTrips[visitId] === undefined) {
        this.overBudgetTrips[visitId] = [];
      }

      this.overBudgetTrips[visitId].push(tripId);
    } else {
      const indexToRemove = this.overBudgetTrips[visitId].indexOf(tripId);

      if (indexToRemove !== -1) {
        this.overBudgetTrips[visitId].splice(indexToRemove, 1);
      }
    }

    this.raiseOverBudgetAvailable[visitId] = this.overBudgetTrips[visitId].length !== 0;
  }

  onRaiseOverBudget(visitId: string) {
    this.raiseOverBudgetLoading[visitId] = true;
    this._visitService.getOverBudgetRequestDetailsForTrips(this.overBudgetTrips[visitId]).subscribe({
      next: response => {
        this.tripsOverBudgetRequestModal.overBudgetRequestDetailsForTripsViewModel = response;
        this.tripsOverBudgetRequestModal.visitId = visitId;
        this.raiseOverBudgetLoading[visitId] = false;
        this.tripsOverBudgetRequestModal.show();
      },
      error: error => {
        this.raiseOverBudgetLoading[visitId] = false;
        this._alertService.showErrorResponse(error.error);
      }
    })
  }

  onOverBudgetRequestRaised(visitId: string) {
    this.loadTripsForVisits(visitId);

    const updatedVisit = this.results.results.find(visit => visit.id === visitId);

    if (updatedVisit) {
      updatedVisit.overBudgetRequestRaised = true;
    }
  }
}
