<div class="tab-pane fade show active details-view-results">
  <div class="patient-activity">
    <div *ngIf="activity != null">
      <div class="patient-activity-item" *ngFor="let notification of activity.results">
        <h2>{{ notification.title }}</h2>
        <p>{{ notification.content }}</p>

        <span class="date-ago">{{ notification.date | amTimeAgo }}</span>
      </div>
    </div>
  </div>

  <app-pagination *ngIf="activity != null" [currentPage]="activity.currentPage" [totalPageCount]="activity.totalPageCount" (pageSelected)="onPageSelect($event)"></app-pagination>
</div>
