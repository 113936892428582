<table [formGroup]="form">
  <tr *ngFor="let customField of customFields">
    <th [ngClass]="{'align-top': customField.type === 'LongText'}"><label class="label">{{customField.label}}</label></th>
    <td *ngIf="customField.type === 'Text'">
      <div class="form-group">
        <input type="text" class="form-control" [formControlName]="customField.id">
      </div>
    </td>
    <td *ngIf="customField.type === 'LongText'">
      <div class="form-group">
        <textarea class="form-control" [formControlName]="customField.id"></textarea>
      </div>
    </td>
  </tr>
</table>
