<div class="row">
  <div class="col-12 text-right">
    <div class="add-muv-ride" [class.highlight]="hasActiveRide">
      <button class="btn btn-primary btn-small" (click)="addTrip.emit()">+ MUV Ride</button>
      <span><strong>Warning:</strong> There are already active or pending rides for this trip</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <table aria-hidden="true" class="std-results-list with-heading-columns muv-rides">
      <thead>
      <tr>
        <th>Date Requested</th>
        <th>Trip Id</th>
        <th>Pickup Date / Time</th>
        <th>Status</th>
        <th>Type</th>
        <th>Amount Quoted</th>
        <th>Updated Date</th>
        <td></td>
      </tr>
      </thead>
      <tbody *ngIf="rides.length > 0">
      <tr *ngFor="let ride of rides">
        <td>{{ ride.created | date: 'd MMM yyyy' }}</td>
        <td><a [href]="muvViewRideUrl + 'user/rides/detail/' + ride.muvRideId" target="_blank">{{ ride.muvRideId }}</a></td>
        <td>{{ ride.pickupDate | date: 'd MMM yyyy HH:mm' }}</td>
        <td><app-muv-ride-state-badge [state]="ride.state"></app-muv-ride-state-badge></td>
        <td>{{ ride.rideType }}</td>
        <td>{{ ride.amountQuoted === null ? '-' : ride.amountQuoted + ' USD' }}</td>
        <td>{{ ride.updated | date: 'd MMM yyyy HH:mm' }}</td>
        <td class="actions">
          <button class="btn btn-secondary btn-small" (click)="onViewRide(ride)">View</button>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="rides.length === 0">
        <tr class="no-rides">
          <td colspan="6">No MUV Rides created for this trip.</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

