<div class="input-amount-currency" [formGroup]="form">
  <div class="amount-container">
    <input type="number" [formControlName]="amountControlName" [placeholder]="placeholder" (blur)="onAmountBlur()" (keydown)="numberHelper.decimalFilter($event)">
  </div>

  <div class="currency-container">
    <app-autosuggest-dropdown-input
      #currencySelect
      className="amount-currency"
      [controlName]="currencyControlName"
      [parentForm]="form"
      [options]="allCurrencies"
      [placeholder]="defaultCurrency"
      (selectValueChanged)="onCurrencySelectChanged($event)">
    </app-autosuggest-dropdown-input>
  </div>
</div>
