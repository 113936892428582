import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { FeedbackSearch } from "../models/FeedbackSearch";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private _http: HttpClient) {

  }

  /**
   * Retrieves patient feedback
   * @param searchTerm
   * @param country
   * @param page
   * @param orderBy
   */
  retrieveFeedback(searchTerm: string, country: string, page: number, orderBy: number) {
    let params = new HttpParams()
      .set('page', page.toString());
    if (searchTerm != null && searchTerm != '') {
      params = params.set('searchTerm', searchTerm);
    }

    if (country != null && country != '') {
      params = params.set('country', country);
    }

    params = params.set('orderBy', orderBy.toString());

    return this._http.get(environment.apiUrl + '/feedback', { params: params })
      .pipe(map(response => {
        return new FeedbackSearch().resultsFromResponse(response);
      }));
  }
}
