import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from '../../../../shared/alert/alert.service';
import { TemplateService } from '../../../../core/services/template.service';
import { SiteContactService } from '../../../../core/services/site-contact.service';
import { SelectOption } from '../../../../core/models/select-option.model';
import { DropdownInputComponent } from '../../../../shared/dropdown-input/dropdown-input.component';
import { ManageUserSitesComponent } from '../manage-user-sites/manage-user-sites.component';
import { SiteContactSiteViewModel } from '../SiteContactSite.view.model';

@Component({
  selector: 'app-edit-site-contact',
  templateUrl: './edit-site-contact.component.html',
  styleUrls: ['./edit-site-contact.component.scss']
})
export class EditSiteContactComponent implements OnInit, AfterViewInit {
  @ViewChild('statusDropdown') statusDropdown: DropdownInputComponent;
  @ViewChild('siteManager') siteManager: ManageUserSitesComponent;

  form: UntypedFormGroup;
  selectedTab = 'info';
  statusOptions: SelectOption[] = [
    { value: 'true', text: 'Active' },
    { value: 'false', text: 'Inactive' }
  ];

  assignedSites: { id: string, siteId: string, trialId: string, role: number, deleted: boolean }[] = [];
  hideFormButtons = false;

  constructor(private siteContactService: SiteContactService, private alertService: AlertService,
    private router: Router, private route: ActivatedRoute,
    private templateService: TemplateService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      processing: new UntypedFormControl(false),
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl({disabled: true, value: ''}, [Validators.required, Validators.email]),
      active: new UntypedFormControl('false'),
      consent: new UntypedFormControl(false, Validators.requiredTrue)
    });
  }

  ngAfterViewInit() {
    this.statusDropdown.setValue('false');
    this.cd.detectChanges();

    this.siteManager.assignedSitesChanged.subscribe(sites => {
      this.assignedSites = [];
      if (sites !== null && sites.length > 0) {
        sites.forEach(site => {
          this.assignedSites.push({ id: site.id, siteId: site.siteId, trialId: site.trialId, role: site.role, deleted: site.deleted });
        });
      }
    });

    this.route.params.subscribe(params => {
      this.siteContactService.retrieveSiteContact(params.id).subscribe(siteContact => {
        this.form.patchValue({
          id: siteContact.id,
          firstname: siteContact.firstname,
          lastname: siteContact.lastname,
          email: siteContact.email,
          active: siteContact.isActive ? 'true' : 'false',
          consent: siteContact.consent
        });

        this.statusDropdown.setValue(siteContact.isActive ? 'true' : 'false');

        // Map sites to assignedSites
        let tmpAssignedSites = siteContact.sites.map(contactSite => {
          return new SiteContactSiteViewModel(contactSite.id, contactSite.irgSiteId, contactSite.siteId, contactSite.trialId, contactSite.trialCode, contactSite.trialNickname, contactSite.trialOpportunityNumber, contactSite.roleId, contactSite.siteName, contactSite.role, false);
        });

        this.siteManager.setAssignedSites(tmpAssignedSites);
      });
    });

    this.siteManager.hideFormButtons.subscribe(hideFormButtons => {
      this.hideFormButtons = hideFormButtons;
    });
  }

  onTabClick(selection: string) {
    this.selectedTab = selection;
    this.hideFormButtons = selection !== 'info';
  }

  onFormSubmit() {
    let form = this.form;

    if (!form.valid)
      return;

    form.patchValue({ processing: true });

    this.siteContactService.updateSiteContact(
      form.get('id').value,
      form.get('firstname').value,
      form.get('lastname').value,
      form.get('active').value === 'true',
      this.assignedSites
    ).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Site contact successfully updated.');
        this.router.navigate(['/admin'], { fragment: 'site-contacts' }).then();
        form.value.processing = false;
      },
      error: err => {
        form.value.processing = false;
        this.alertService.showWarningAlert(err.error.title);
      }
    });
  }
}
