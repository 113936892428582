import {SelectOption} from "../models/select-option.model";

export class PublishedOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "Draft",
        "text": "Draft"
      },
      {
        "value": "Published",
        "text": "Published"
      }
    ];
  }
}
