import {SelectOption} from "../models/select-option.model";

export class VisitTypeOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "OnSite",
        "text": "On-Site"
      },
      {
        "value": "Home",
        "text": "Home Visit"
      },
      {
        "value": "Telephone",
        "text": "Telephone"
      },
      {
        "value": "Virtual",
        "text": "Virtual"
      }
    ];
  }
}
