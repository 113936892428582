import { AlertService } from './../../../../../shared/alert/alert.service';
import { TrialService } from 'app/core/services/trial.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ExpenseAutoApprovalRuleType,
  ExpenseAutoApprovalRuleTypeString,
  ExpenseRuleListItemViewModel,
  UpdateTrialPolicyExpenseRuleRequest
} from '../../trial-policy.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { Currencies } from 'app/core/constants/currency';
import { LogHelper } from 'app/core/helpers/log.helper';

@Component({
  selector: 'app-update-expense-rule-modal',
  templateUrl: './update-expense-rule-modal.component.html',
  styleUrls: ['./update-expense-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateExpenseRuleModalComponent implements OnInit {
  rule: ExpenseRuleListItemViewModel;
  policyId: string;
  existingCategories: string[] = [];

  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @ViewChild("typeSelect") typeSelect: DropdownInputComponent;
  @Output() ruleUpdated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('deleteExpenseRuleModal') deleteExpenseRuleModal: ModalComponent;

  ruleForm: UntypedFormGroup;
  expenseCategories: { id: string, value: string }[] = [];
  currencies: { value: string, text: string }[] = [];
  types: { value: string, text: string }[] = [];

  isFormProcessing = false;
  isDeleteProcessing = false;

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.initiateCurrencies();
    this.initRuleForm();
  }

  show() {
    this.initiateTypes();
    this.initiateCategories();

    this.patchForm();

    this.existingCategories = this.rule.categories.map(c => c.id);
    this.modal.show();
  }

  initRuleForm() {
    this.ruleForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl(''),
      receiptRequired: new UntypedFormControl(false),
      receiptRequiredValue: new UntypedFormControl(0, Validators.min(0)),
      allCategories: new UntypedFormControl(false),
      amount: new UntypedFormControl('', [Validators.min(0), Validators.required]),
      caregiverAmount: new UntypedFormControl('', Validators.min(0)),
      currency: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      categories: new UntypedFormControl([], Validators.required),
      overBudgetRequestInformation: new UntypedFormControl('')
    });

    this.ruleForm.get("allCategories").valueChanges.subscribe({
      next: val => {
        if (val) {
          this.ruleForm.get("categories").disable();
          this.ruleForm.get("categories").setValue([]);
          this.ruleForm.get("categories").clearValidators();
        } else {
          this.ruleForm.get("categories").enable();
          this.ruleForm.get("categories").setValidators(Validators.required);
        }
      }
    })
  }

  patchForm() {
    this.ruleForm.patchValue({
      name: this.rule.ruleName,
      note: this.rule.note,
      receiptRequired: this.rule.receiptRequired,
      receiptRequiredValue: this.rule.receiptRequiredValue,
      allCategories: this.rule.allCategories,
      amount: this.rule.amount,
      caregiverAmount: this.rule.caregiverAmount,
      overBudgetRequestInformation: this.rule.overBudgetRequestInformation,
      categories: this.rule.categories.map(c => c.id)
    });

    this.currencySelect.setValue(this.rule.currency);
    this.typeSelect.setValue(this.rule.type.toString());
  }

  initiateCurrencies() {
    if (!this.currencies.length) {
      this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
    }
  }

  initiateTypes() {
    if (!this.types.length) {
      this.types.push({ value: ExpenseAutoApprovalRuleType.Visit.toString(), text: ExpenseAutoApprovalRuleTypeString.Values[ExpenseAutoApprovalRuleType.Visit] });
      this.types.push({ value: ExpenseAutoApprovalRuleType.Day.toString(), text: ExpenseAutoApprovalRuleTypeString.Values[ExpenseAutoApprovalRuleType.Day] });
    }
  }

  initiateCategories() {
    if (!this.expenseCategories.length) {
      this.trialService.getPolicyCategories(this.policyId).subscribe({
        next: response => {
          response.forEach(c => this.expenseCategories.push({ id: c.id, value: c.name }));
        }
      })
    }
  }

  submit() {
    let request = this.ruleForm.value as UpdateTrialPolicyExpenseRuleRequest;
    request.id = this.rule.id;

    if (!request.receiptRequiredValue) {
      request.receiptRequiredValue = 0;
    }

    if (!request.categories) {
      request.categories = [];
    }

    this.isFormProcessing = true;

    this.trialService.updateTrialPolicyExpenseRule(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Expense rule successfully updated.");
        this.ruleUpdated.emit();
        this.isFormProcessing = false;
        this.ruleForm.reset();
        this.modal.hide();
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to update expense rule. Please check you input and try again.");
        this.isFormProcessing = false;
      }
    });
  }

  ruleFormValid(): boolean {
    return this.ruleForm.valid && (this.ruleForm.get("allCategories").value || this.ruleForm.get("categories").value.length);
  }

  deleteRule() {
    this.isDeleteProcessing = true;
    this.trialService.deleteTrialPolicyExpenseRule(this.rule.id).subscribe({
      next: () => {
        this.isDeleteProcessing = false;
        this.alertService.showSuccessAlert("Expense rule successfully deleted.");
        this.ruleUpdated.emit();
        this.modal.hide();
        this.deleteExpenseRuleModal.hide();
      },
      error: error => {
        this.isDeleteProcessing = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }
}
