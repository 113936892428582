export class CurrentAdmin {
  public id: string;
  public firstname: string;
  public lastname: string;
  public email: string;

  public static empty() {
    let admin = new CurrentAdmin();
    admin.id =  null;
    admin.firstname = 'Loading...';
    admin.lastname = '';
    admin.email = 'Loading...';

    return admin;
  }

  public static fromObject(obj: any) {
    let admin = new CurrentAdmin();
    admin.id = obj.id;
    admin.firstname = obj.firstname;
    admin.lastname = obj.lastname;
    admin.email = obj.email;

    return admin;
  }
}
