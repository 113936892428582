import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

@NgModule({
  declarations: [UnauthorisedComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class AuthModule { }
