import { RoleNames } from 'app/core/constants/role-names';
import { Permissions } from './../../../core/constants/permissions';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TemplateService } from '../../../core/services/template.service';
import { AuthService } from '../../../core/services/auth.service';
import { LogHelper } from '../../../core/helpers/log.helper';
import { AdminService } from '../../../core/services/admin.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
import { ObjectHelper } from '../../../core/helpers/object.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html'
})
export class AdminViewComponent implements OnInit {
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('usersDateFrom') usersDateFrom: ElementRef;
  @ViewChild('usersDateTo') usersDateTo: ElementRef;
  @ViewChild('changeLogModal') changeLogModal: ModalComponent;
  @ViewChild('exportModal') exportModal: ModalComponent;

  selectedTab: string;
  selectedTabChanged: BehaviorSubject<string>;
  exportForm: UntypedFormGroup;
  exportIsProcessing = false;
  exportUsersForm: UntypedFormGroup;
  Permissions = Permissions;
  RoleNames = RoleNames;

  constructor(private templateService: TemplateService, public authService: AuthService, private adminService: AdminService, private alertService: AlertService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.selectedTab = this.authService.hasPermission(Permissions.UserRead) &&
      (this.authService.hasRole(RoleNames.ProductAdministrator) || this.authService.hasRole(RoleNames.SystemAdministrator)) ?
      'cms-users' : 'site-contacts';

    this.selectedTabChanged = new BehaviorSubject<string>(this.selectedTab);
    this.templateService.showHeader();

    // Update the current tab if the fragment is supplied in the URL
    this.route.fragment.subscribe(fragment => {
      if (!ObjectHelper.isUndefinedNullOrEmpty(fragment)) {
        this.selectedTab = fragment;
        this.selectedTabChanged.next(fragment);
      }
    });

    // Listen for a tab being clicked and react to it
    this.selectedTabChanged.subscribe(selectedTab => {
      this.selectedTab = selectedTab;
    });

    // Export form
    this.exportForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl()
    });

    // Export users form
    this.exportUsersForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl(),
      allData: new UntypedFormControl(false)
    });

    this.exportUsersForm.get('allData').valueChanges.subscribe(allData => {
      if (allData) {
        this.exportUsersForm.patchValue({
          dateFrom: '',
          dateTo: ''
        });
      }
    });
  }

  onTabClick(selected: string) {
    this.selectedTabChanged.next(selected);
  }

  onShowExportModal() {
    this.exportModal.show();
  }

  onShowExportUsersModal() {
    this.changeLogModal.show();
  }

  updateFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  updateExportUsersFormValues() {
    this.exportUsersForm.patchValue({
      dateFrom: this.usersDateFrom.nativeElement.value,
      dateTo: this.usersDateTo.nativeElement.value
    });
  }

  /**
   * Starts the admin and site user export
   */
  onExportUsers(): void {
    this.exportUsersForm.patchValue({ processing: true });

    const from = this.exportUsersForm.get('dateFrom').value;
    const to = this.exportUsersForm.get('dateTo').value;

    this.adminService.exportUsers(from, to).subscribe({
      next: () => {
        this.exportModal.hide();
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportUsersForm.patchValue({
          processing: false,
          dateFrom: '',
          dateTo: ''
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportUsersForm.patchValue({ processing: false });
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }

  /**
   * Starts the change log export
   */
  onExportChangeLog() {
    this.exportIsProcessing = true;

    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;

    this.adminService.exportChangeLog(from, to).subscribe({
      next: () => {
        this.changeLogModal.hide();
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportIsProcessing = false;
        this.exportForm.patchValue({
          dateFrom: '',
          dateTo: ''
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }

  usersVisible() {
    return (environment.environment === "DEVELOPMENT" ||
      environment.environment === "STAGING") ||
      this.authService.hasPermission(Permissions.UserRead) &&
      (this.authService.hasRole(RoleNames.ProductAdministrator) ||
        this.authService.hasRole(RoleNames.SystemAdministrator))
  }
}
