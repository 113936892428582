import {SearchPage} from "./search-page.model";

export class ExpenseCategoryItem {
  constructor(public id: string, public name: string, public type: string, public internalType: string, public canDelete: boolean, public translations: ExpenseCategoryTranslation[]) {}
}

export class ExpenseSubCategoryItem {
  constructor(public id: number, public name: string) {}
}

export class ExpenseCategoryTranslation {
  public constructor(public id: string, public expenseCategoryId: string, public name: string, public culture: string) {
  }
}

export class ExpenseCategoryList extends SearchPage {
  public results: ExpenseCategoryItem[] = [];
}
