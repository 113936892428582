import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: '[visit-table-cell-date]',
  templateUrl: './visit-table-cell-date.component.html',
  styleUrls: ['./visit-table-cell-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisitTableCellDateComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @ViewChildren('td') tdElements: QueryList<ElementRef>;

  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('minWidth') minWidth: string;
  @Input('date') date: Date;

  changeCount = 0;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.parentForm.get(this.controlName).valueChanges.subscribe((value) => {
      this.onDateChanged();
    });
  }

  onDateChanged(): void {
    // We don't want to trigger the change event when the component is first loaded
    if (this.changeCount > 0) {
      this.valueChanged.emit();
    }

    this.changeCount++;
  }
}
