<app-modal #modal class="create-expense-rule-modal">
  <div class="modal-header">
    <h2 class="modal-title">Add Expense Rule</h2>
  </div>

  <div class="modal-body">
    <form [formGroup]="ruleForm">

      <div class="row">
        <div class="form-group col-md-6">
          <label for="name">Rule Name</label>
          <input type="text" class="form-control w-100" id="name" formControlName="name" name="name">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="categories">Categories Covered</label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="categories"
              [formGroup]="ruleForm" multiple>
              <mat-option *ngFor="let category of expenseCategories"
                [value]="category.id">{{category.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group col-md-6 align-self-end">
          <label class="checkbox">
            <input type="checkbox" id="allCategories" formControlName="allCategories">
            <span></span>
          </label>
          <label class="checkbox-label" for="allCategories">Global rule across all categories</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label for="amount">Patient limit</label>
          <input class="form-control w-100" type="number" id="amount" formControlName="amount" name="amount">
        </div>

        <div class="form-group col-md-4">
          <label for="amount">Caregiver limit</label>
          <input class="form-control w-100" type="number" id="caregiverAmount" formControlName="caregiverAmount" name="caregiverAmount">
        </div>

        <div class="form-group col-md-4">
          <label for="currency">Currency</label>
          <app-dropdown-input #currencySelect id="currency" [options]="currencies" [parentForm]="ruleForm"
            name="currency" placeholder="Select currency">
          </app-dropdown-input>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="type">Per</label>
          <app-dropdown-input #typeSelect id="type" [options]="types" [parentForm]="ruleForm" name="type"
            placeholder="Select type">
          </app-dropdown-input>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12 receipt-details-row">
          <div class="checkbox-group">
            <label class="checkbox">
              <input type="checkbox" id="receiptRequired" formControlName="receiptRequired">
              <span></span>
            </label>
            <label class="checkbox-label" for="receiptRequired">Receipts are Required for values above</label>
          </div>
          <input class="form-control" type="number" id="receiptRequiredValue" formControlName="receiptRequiredValue" name="receiptRequiredValue">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="note">Notes / Description</label>
          <textarea class="form-control" style="width: 100% !important;" id="note" formControlName="note"
            name="note"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="overBudgetRequestInformation">Additional information for over budget request</label>
          <textarea class="form-control" style="width: 100% !important;" id="overBudgetRequestInformation" formControlName="overBudgetRequestInformation"
            name="overBudgetRequestInformation"></textarea>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary w-auto" (click)="hideModal()">Cancel</button>
    <button type="submit" class="btn btn-primary w-auto" (click)="submit()" [disabled]="ruleForm.invalid">
      <span *ngIf="isFormProcessing">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!isFormProcessing">Save</span>
    </button>
  </div>
</app-modal>
