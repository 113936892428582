export class Airports {
  public static all() {
    return [
      {
        "Id": 1,
        "AirportCode": "21N",
        "AirportName": "Mattituck Airport",
        "City": "Mattituck",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 2,
        "AirportCode": "2G9",
        "AirportName": "Somerset County Airport",
        "City": "Friedens",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 3,
        "AirportCode": "36U",
        "AirportName": "Russ McDonald Field",
        "City": "Heber",
        "State": "UT",
        "Country": "United States"
      },
      {
        "Id": 4,
        "AirportCode": "40N",
        "AirportName": "Chester County Airport",
        "City": "Coatesville",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 5,
        "AirportCode": "4I3",
        "AirportName": "Knox County Airport",
        "City": "Mount Vernon",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 6,
        "AirportCode": "5B2",
        "AirportName": "Saratoga County Airport",
        "City": "Ballston Spa",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 7,
        "AirportCode": "6B9",
        "AirportName": "Skaneateles Aero Drome",
        "City": "Skaneateles",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 9,
        "AirportCode": "7B2",
        "AirportName": "Northampton Airport",
        "City": "Northampton",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 10,
        "AirportCode": "ABE",
        "AirportName": "Lehigh Valley International Airport",
        "City": "Allentown",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 11,
        "AirportCode": "ABQ",
        "AirportName": "Albuquerque International Sunport",
        "City": "Albuquerque",
        "State": "NM ",
        "Country": "United States"
      },
      {
        "Id": 12,
        "AirportCode": "ABZ",
        "AirportName": "Aberdeen Airport",
        "City": "Aberdeen",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 13,
        "AirportCode": "ACK",
        "AirportName": "Nantucket Memorial Airport",
        "City": "Nantucket",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 14,
        "AirportCode": "ACY",
        "AirportName": "Atlantic City International Airport",
        "City": "Atlantic City",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 15,
        "AirportCode": "ADS",
        "AirportName": "Addison Airport",
        "City": "Addison",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 16,
        "AirportCode": "AEP",
        "AirportName": "Jorge Newbery Airport",
        "City": "Buenos Aires",
        "State": "",
        "Country": "Argentina"
      },
      {
        "Id": 17,
        "AirportCode": "AGR",
        "AirportName": "Agra Air Force Station",
        "City": "Agra",
        "State": "Uttar Pradesh",
        "Country": "India"
      },
      {
        "Id": 18,
        "AirportCode": "AGS",
        "AirportName": "Augusta Regional Airport",
        "City": "Augusta",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 19,
        "AirportCode": "AKC",
        "AirportName": "Akron Fulton International",
        "City": "Akron",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 20,
        "AirportCode": "AKL",
        "AirportName": "Auckland International Airport",
        "City": "Auckland",
        "State": "",
        "Country": "New Zealand"
      },
      {
        "Id": 21,
        "AirportCode": "ALB",
        "AirportName": "Albany International Airport",
        "City": "Albany",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 22,
        "AirportCode": "ALN",
        "AirportName": "St. Louis Regional Airport",
        "City": "East Alton",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 23,
        "AirportCode": "ALO",
        "AirportName": "Waterloo Regional Airport",
        "City": "Waterloo",
        "State": "IA",
        "Country": "United States"
      },
      {
        "Id": 24,
        "AirportCode": "ALW",
        "AirportName": "Walla Walla Rgnl",
        "City": "Walla Walla",
        "State": "WA",
        "Country": "United States"
      },
      {
        "Id": 25,
        "AirportCode": "AMA",
        "AirportName": "Rick Husband Amarillo International Airport",
        "City": "Amarillo",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 26,
        "AirportCode": "AMD",
        "AirportName": "Sardar Vallabhbhai Patel International Airport",
        "City": "Ahmedabad",
        "State": "Gujrat",
        "Country": "India"
      },
      {
        "Id": 27,
        "AirportCode": "AMM",
        "AirportName": "Queen Alia International Airport",
        "City": "",
        "State": "",
        "Country": "Jordan"
      },
      {
        "Id": 28,
        "AirportCode": "AMS",
        "AirportName": "Schiphol Airport",
        "City": "Schiphol",
        "State": "Amsterdam",
        "Country": "Netherlands"
      },
      {
        "Id": 29,
        "AirportCode": "ANC",
        "AirportName": "Ted Stevens Anchorage International Airport",
        "City": "Anchorage",
        "State": "AK",
        "Country": "United States"
      },
      {
        "Id": 30,
        "AirportCode": "ANE",
        "AirportName": "Anoka County-Blaine Airport",
        "City": "Blaine",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 31,
        "AirportCode": "ANR",
        "AirportName": "Antwerp International Airport",
        "City": "Luchthavenlei",
        "State": "Antwerpen",
        "Country": "Belgium"
      },
      {
        "Id": 32,
        "AirportCode": "ANU",
        "AirportName": "V C Bird International Airport",
        "City": "Osbourn",
        "State": "",
        "Country": "Antigua and Barbuda"
      },
      {
        "Id": 33,
        "AirportCode": "APA",
        "AirportName": "Centennial Airport",
        "City": "Englewood",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 34,
        "AirportCode": "APC",
        "AirportName": "Napa County Airport",
        "City": "Napa",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 35,
        "AirportCode": "APF",
        "AirportName": "Naples Municipal Airport",
        "City": "Naples",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 36,
        "AirportCode": "ARB",
        "AirportName": "Ann Arbor International",
        "City": "Ann Arbor",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 37,
        "AirportCode": "ARN",
        "AirportName": "Stockholm Arlanda Airport",
        "City": "Stockholm-arlanda",
        "State": "",
        "Country": "SE"
      },
      {
        "Id": 38,
        "AirportCode": "ARR",
        "AirportName": "Alto Rio Senguerr Airport",
        "City": "Alto Rio Senguer",
        "State": "CB",
        "Country": "AR"
      },
      {
        "Id": 39,
        "AirportCode": "ASE",
        "AirportName": "Sardy Field",
        "City": "Aspen",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 40,
        "AirportCode": "ASH",
        "AirportName": "Nashua Airport Authority",
        "City": "Nashua",
        "State": "NH",
        "Country": "United States"
      },
      {
        "Id": 41,
        "AirportCode": "ASP",
        "AirportName": "Alice Springs Airport",
        "City": "Alice Springs",
        "State": "NT",
        "Country": "AU"
      },
      {
        "Id": 42,
        "AirportCode": "ASU",
        "AirportName": "Silvio Pettirossi International Airport",
        "City": "Aeropuerto",
        "State": "Luque",
        "Country": "Paraguay"
      },
      {
        "Id": 43,
        "AirportCode": "ATH",
        "AirportName": "Athens International Airport",
        "City": "Spata Artemida",
        "State": "Attica",
        "Country": "Greece"
      },
      {
        "Id": 44,
        "AirportCode": "ATL",
        "AirportName": "Hartsfield Jackson Atlanta International Airport",
        "City": "Atlanta",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 45,
        "AirportCode": "ATQ",
        "AirportName": "Sri Guru Ram Dass Jee International Airport",
        "City": "",
        "State": "Punjab",
        "Country": "India"
      },
      {
        "Id": 46,
        "AirportCode": "ATW",
        "AirportName": "Outagamie County Regional Airport",
        "City": "Appleton",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 47,
        "AirportCode": "AUA",
        "AirportName": "Reina Beatrix International Airport",
        "City": "Oranjestad",
        "State": "",
        "Country": "Aruba"
      },
      {
        "Id": 48,
        "AirportCode": "AUH",
        "AirportName": "InterContinental Abu Dhabi",
        "City": "Abu Dhabi ",
        "State": "",
        "Country": "United Arab Emirates"
      },
      {
        "Id": 49,
        "AirportCode": "AUS",
        "AirportName": "Austin-Bergstrom International Airport",
        "City": "Austin",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 50,
        "AirportCode": "AVL",
        "AirportName": "Asheville Regional Airport",
        "City": "Asheville",
        "State": "North Carolina",
        "Country": "United States"
      },
      {
        "Id": 51,
        "AirportCode": "AVP",
        "AirportName": "Wilkes-Barre/Scranton International Airport",
        "City": "Avoca",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 52,
        "AirportCode": "BAF",
        "AirportName": "Barnes Municipal Airport",
        "City": "Westfield",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 53,
        "AirportCode": "BAH",
        "AirportName": "Bahrain International Airport",
        "City": "Muharraq",
        "State": "",
        "Country": "Bahrain"
      },
      {
        "Id": 54,
        "AirportCode": "BAQ",
        "AirportName": "Ernesto Cortissoz International Airport",
        "City": "Atlantico",
        "State": "",
        "Country": "Colombia"
      },
      {
        "Id": 55,
        "AirportCode": "BCN",
        "AirportName": "Barcelona Airport",
        "City": "El Prat de Llobregat",
        "State": "",
        "Country": "Spain"
      },
      {
        "Id": 56,
        "AirportCode": "BCT",
        "AirportName": "Boca Raton Airport",
        "City": "Boca Raton",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 57,
        "AirportCode": "BDA",
        "AirportName": "L.F. Wade International Airport",
        "City": "Bermuda",
        "State": "",
        "Country": "Bermuda"
      },
      {
        "Id": 58,
        "AirportCode": "BDL",
        "AirportName": "Bradley International Airport",
        "City": "Windsor Locks",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 59,
        "AirportCode": "BDQ",
        "AirportName": "Vadodara Airport",
        "City": "Vadodara",
        "State": "Gujarat",
        "Country": "India"
      },
      {
        "Id": 60,
        "AirportCode": "BDR",
        "AirportName": "Igor I Sikorsky Memorial Airport",
        "City": "Bridgeport",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 61,
        "AirportCode": "BED",
        "AirportName": "Hanscom Field",
        "City": "Bedford",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 62,
        "AirportCode": "BEG",
        "AirportName": "Belgrade - Nikola Tesla",
        "City": "Belgrade",
        "State": "",
        "Country": "Serbia"
      },
      {
        "Id": 63,
        "AirportCode": "BER",
        "AirportName": "Berlin Brandenburg Airport",
        "City": "Schönefeld",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 64,
        "AirportCode": "BEY",
        "AirportName": "Beirut-Rafic Hariri International Airport",
        "City": "",
        "State": "",
        "Country": "LB"
      },
      {
        "Id": 65,
        "AirportCode": "BFH",
        "AirportName": "Bacacheri Airport",
        "City": "Bacacheri",
        "State": "Curitiba - PR",
        "Country": "Brazil"
      },
      {
        "Id": 66,
        "AirportCode": "BFI",
        "AirportName": "Boeing Field-King County International Airport",
        "City": "Seattle",
        "State": "WA",
        "Country": "United States"
      },
      {
        "Id": 67,
        "AirportCode": "BFL",
        "AirportName": "Meadows Field Airport",
        "City": "Bakersfield",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 68,
        "AirportCode": "BFM",
        "AirportName": "Brookley Aeroplex",
        "City": "Mobile",
        "State": "AL",
        "Country": "United States"
      },
      {
        "Id": 69,
        "AirportCode": "BGI",
        "AirportName": "Grantley Adams International Airport",
        "City": "Barbados",
        "State": "",
        "Country": ""
      },
      {
        "Id": 70,
        "AirportCode": "BGO",
        "AirportName": "Bergen Lufthavn",
        "City": "Bergen",
        "State": "",
        "Country": "Norway"
      },
      {
        "Id": 71,
        "AirportCode": "BHB",
        "AirportName": "Bar Harbor Airport",
        "City": "Trenton",
        "State": "ME",
        "Country": "United States"
      },
      {
        "Id": 72,
        "AirportCode": "BHD",
        "AirportName": "Barksdale Air Force Base",
        "City": "Barksdale AFB",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 73,
        "AirportCode": "BHM",
        "AirportName": "Birmingham International Airport",
        "City": "Birmingham",
        "State": "AL",
        "Country": "United States"
      },
      {
        "Id": 74,
        "AirportCode": "BHX",
        "AirportName": "Novotel Birmingham Airport",
        "City": "Birmingham",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 75,
        "AirportCode": "BIL",
        "AirportName": "Billings Logan International Airport",
        "City": "Billings",
        "State": "MT",
        "Country": "United States"
      },
      {
        "Id": 76,
        "AirportCode": "BIO",
        "AirportName": "Bilbao Airport",
        "City": "Bilbao",
        "State": "Biscay",
        "Country": "Spain"
      },
      {
        "Id": 77,
        "AirportCode": "BJC",
        "AirportName": "Rocky Mountain Metropolitan Airport",
        "City": "Broomfield",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 78,
        "AirportCode": "BKK",
        "AirportName": "Suvarnabhumi Airport",
        "City": "Samut Prakan",
        "State": "",
        "Country": "Thailand"
      },
      {
        "Id": 79,
        "AirportCode": "BKL",
        "AirportName": "Burke Lakefront Airport",
        "City": "Cleveland",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 80,
        "AirportCode": "BLM",
        "AirportName": "Monmouth Executive Airport",
        "City": "Wall Township",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 81,
        "AirportCode": "BLQ",
        "AirportName": "Aeroporto Guglielmo Marconi",
        "City": "Bologna",
        "State": "",
        "Country": "Italy"
      },
      {
        "Id": 82,
        "AirportCode": "BLR",
        "AirportName": "Bengaluru International Airport",
        "City": "Bangalore",
        "State": "Karnataka",
        "Country": "India"
      },
      {
        "Id": 83,
        "AirportCode": "BMA",
        "AirportName": "Swedavia AB Stockholm-Bromma Airport",
        "City": "Stockholm",
        "State": "",
        "Country": "SE"
      },
      {
        "Id": 84,
        "AirportCode": "BNA",
        "AirportName": "Nashville Airport Authority",
        "City": "Nashville",
        "State": "Tennessee",
        "Country": "United States"
      },
      {
        "Id": 85,
        "AirportCode": "BNE",
        "AirportName": "Brisbane Airport",
        "City": "Queensland",
        "State": "",
        "Country": "Australia"
      },
      {
        "Id": 86,
        "AirportCode": "BOD",
        "AirportName": "Bordeaux Aeroport",
        "City": "Mérignac",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 87,
        "AirportCode": "BOG",
        "AirportName": "El Dorado International Airport",
        "City": "Bogotá",
        "State": "Cundinamarca",
        "Country": "Colombia"
      },
      {
        "Id": 88,
        "AirportCode": "BOI",
        "AirportName": "Boise Air Terminal (Gowen Field)",
        "City": "Boise",
        "State": "ID",
        "Country": "USA"
      },
      {
        "Id": 89,
        "AirportCode": "BOM",
        "AirportName": "Chhatrapati Shivaji International Airport",
        "City": "Mumbai",
        "State": "MH",
        "Country": "India"
      },
      {
        "Id": 90,
        "AirportCode": "BOS",
        "AirportName": "Boston Logan International Airport",
        "City": "East Boston",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 91,
        "AirportCode": "BRC",
        "AirportName": "San Carlos de Bariloche International Airport",
        "City": "San Carlos de Bariloche",
        "State": "Río Negro Province",
        "Country": "Argentina"
      },
      {
        "Id": 92,
        "AirportCode": "BRU",
        "AirportName": "Brussels Airport",
        "City": "Zaventem",
        "State": "",
        "Country": "Belgium"
      },
      {
        "Id": 93,
        "AirportCode": "BSB",
        "AirportName": "Brasília International Airport",
        "City": "Brasília ",
        "State": "DF ",
        "Country": "Brazil"
      },
      {
        "Id": 94,
        "AirportCode": "BSL",
        "AirportName": "EuroAirport",
        "City": "Saint-Louis",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 95,
        "AirportCode": "BTR",
        "AirportName": "Baton Rouge Metropolitan Airport",
        "City": "Baton Rouge",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 96,
        "AirportCode": "BTV",
        "AirportName": "Burlington International Airport",
        "City": "South Burlington",
        "State": "VT",
        "Country": "United States"
      },
      {
        "Id": 97,
        "AirportCode": "BUD",
        "AirportName": "Budapest Liszt Ferenc International Airport",
        "City": "Budapest",
        "State": "Magyarország",
        "Country": "Hungary"
      },
      {
        "Id": 98,
        "AirportCode": "BUF",
        "AirportName": "Buffalo Niagara International Airport",
        "City": "Cheektowaga",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 99,
        "AirportCode": "BUR",
        "AirportName": "Bob Hope Airport",
        "City": "Burbank",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 100,
        "AirportCode": "BVY",
        "AirportName": "Beverly Airport",
        "City": "Beverly",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 101,
        "AirportCode": "BWI",
        "AirportName": "Baltimore/Washington International Thurgood Marshall Airport",
        "City": "Baltimore",
        "State": "MD",
        "Country": "United States"
      },
      {
        "Id": 102,
        "AirportCode": "BWN",
        "AirportName": "Brunei International Airport",
        "City": "Bandar Seri Begawan",
        "State": "",
        "Country": "Brunei"
      },
      {
        "Id": 103,
        "AirportCode": "BZN",
        "AirportName": "Bozeman Yellowstone International Airport - BZN",
        "City": "Belgrade",
        "State": "MT",
        "Country": "United States"
      },
      {
        "Id": 104,
        "AirportCode": "C16",
        "AirportName": "Frasca Airport.",
        "City": "Urbana",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 105,
        "AirportCode": "CAE",
        "AirportName": "Columbia Metropolitan Airport",
        "City": "West Columbia",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 106,
        "AirportCode": "CAI",
        "AirportName": "Cairo International Airport",
        "City": "Sheraton Al Matar",
        "State": "Qesm an Nuzhah",
        "Country": "Egypt"
      },
      {
        "Id": 107,
        "AirportCode": "CAK",
        "AirportName": "Akron-Canton Regional Airport",
        "City": "North Canton",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 108,
        "AirportCode": "CAN",
        "AirportName": "Canakkale Airport",
        "City": "",
        "State": "",
        "Country": "Turkey"
      },
      {
        "Id": 109,
        "AirportCode": "CBR",
        "AirportName": "Canberra International Airport",
        "City": "Canberra International Airport ACT",
        "State": "",
        "Country": "Australia"
      },
      {
        "Id": 110,
        "AirportCode": "CCA",
        "AirportName": "Clinton Muni Airport-CCA",
        "City": "Clinton",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 111,
        "AirportCode": "CCS",
        "AirportName": "Simon Bolivar Airport",
        "City": "Maiquetía",
        "State": "",
        "Country": "Venezuela"
      },
      {
        "Id": 112,
        "AirportCode": "CCU",
        "AirportName": "Netaji Subhash Chandra Bose International Airport",
        "City": "Kolkata",
        "State": "West Bengal",
        "Country": "India"
      },
      {
        "Id": 113,
        "AirportCode": "CDG",
        "AirportName": "Roissy Airport Express",
        "City": "Tremblay-en-France",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 114,
        "AirportCode": "CDW",
        "AirportName": "Essex County Airport",
        "City": "Fairfield",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 115,
        "AirportCode": "CEF",
        "AirportName": "Westover Metro Airport-CEF",
        "City": "Chicopee",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 116,
        "AirportCode": "CFE",
        "AirportName": "Aulnat Airport",
        "City": "Aulnat",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 117,
        "AirportCode": "CGH",
        "AirportName": "Aeroporto de Congonhas",
        "City": "",
        "State": "",
        "Country": "Brazil"
      },
      {
        "Id": 118,
        "AirportCode": "CGK",
        "AirportName": "Jakarta Airport",
        "City": "Pajang",
        "State": "Tangerang",
        "Country": "Indonesia"
      },
      {
        "Id": 119,
        "AirportCode": "CGN",
        "AirportName": "Cologne/Bonn Airport",
        "City": "Köln",
        "State": "",
        "Country": "Deutschland"
      },
      {
        "Id": 120,
        "AirportCode": "CHA",
        "AirportName": "Chattanooga Metropolitan Airport",
        "City": "Chattanooga",
        "State": "TN",
        "Country": "United States"
      },
      {
        "Id": 121,
        "AirportCode": "CHO",
        "AirportName": "Charlottesville Albemarle Airport",
        "City": "Charlottesville",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 122,
        "AirportCode": "CHS",
        "AirportName": "Charleston International Airport",
        "City": "Charleston",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 123,
        "AirportCode": "CIA",
        "AirportName": "Ciampino Airport",
        "City": "Ciampino",
        "State": "Lazio",
        "Country": "IT"
      },
      {
        "Id": 124,
        "AirportCode": "CID",
        "AirportName": "The Eastern Iowa Airport",
        "City": "Cedar Rapids",
        "State": "IA",
        "Country": "United States"
      },
      {
        "Id": 125,
        "AirportCode": "CLD",
        "AirportName": "McClellan-Palomar Airport",
        "City": "Carlsbad",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 126,
        "AirportCode": "CLE",
        "AirportName": "Cleveland Hopkins International Airport",
        "City": "Cleveland",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 127,
        "AirportCode": "CLO",
        "AirportName": "Alfonso Bonilla Aragon International Airport",
        "City": "",
        "State": "Valle del Cauca",
        "Country": "Colombia"
      },
      {
        "Id": 128,
        "AirportCode": "CLT",
        "AirportName": "Charlotte Douglas International Airport",
        "City": "Charlotte",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 129,
        "AirportCode": "CLW",
        "AirportName": "Clearwater Air Park",
        "City": "Clearwater",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 130,
        "AirportCode": "CMH",
        "AirportName": "John Glenn Columbus International Airport",
        "City": "Columbus",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 131,
        "AirportCode": "CMI",
        "AirportName": "University of Illinois Willard Airport",
        "City": "Savoy",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 132,
        "AirportCode": "CNF",
        "AirportName": "Tancredo Neves International Airport",
        "City": "Belo Horizonte - MG",
        "State": "",
        "Country": "Brasil"
      },
      {
        "Id": 133,
        "AirportCode": "CNS",
        "AirportName": "Cairns International Airport",
        "City": "Cairns QLD",
        "State": "",
        "Country": "Australia"
      },
      {
        "Id": 134,
        "AirportCode": "COK",
        "AirportName": "Cochin International Airport",
        "City": "Angamaly",
        "State": "Kerala",
        "Country": "India"
      },
      {
        "Id": 135,
        "AirportCode": "COP",
        "AirportName": "Cooperstown Airport",
        "City": "Cooperstown",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 136,
        "AirportCode": "COS",
        "AirportName": "Colorado Springs Airport",
        "City": "Colorado Springs",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 137,
        "AirportCode": "CPH",
        "AirportName": "Copenhagen Airport",
        "City": "Kastrup",
        "State": "Copenhagen",
        "Country": "Denmark"
      },
      {
        "Id": 138,
        "AirportCode": "CPS",
        "AirportName": "Curtiss Airport",
        "City": "St. Louis",
        "State": "MO",
        "Country": "United States"
      },
      {
        "Id": 139,
        "AirportCode": "CPT",
        "AirportName": "Cape Town International Airport",
        "City": "Cape Town",
        "State": "",
        "Country": "South Africa"
      },
      {
        "Id": 140,
        "AirportCode": "CRL",
        "AirportName": "Brussels South Charleroi Airport",
        "City": "Rue des Frères Wright 8",
        "State": "Gosselies",
        "Country": "Belgium"
      },
      {
        "Id": 141,
        "AirportCode": "CRP",
        "AirportName": "Corpus Christi International Airport",
        "City": "Corpus Christi",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 142,
        "AirportCode": "CRQ",
        "AirportName": "Caravelas Airport",
        "City": "Caravelas",
        "State": "BA",
        "Country": "BR"
      },
      {
        "Id": 143,
        "AirportCode": "CRW",
        "AirportName": "Yeager Airport",
        "City": "Charleston",
        "State": "WV",
        "Country": "United States"
      },
      {
        "Id": 144,
        "AirportCode": "CSG",
        "AirportName": "Columbus Airport",
        "City": "Columbus",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 145,
        "AirportCode": "CTG",
        "AirportName": "Rafael Nunez Airport",
        "City": "Cartagena",
        "State": "Bolivar",
        "Country": "Colombia"
      },
      {
        "Id": 146,
        "AirportCode": "CUL",
        "AirportName": "Fedl De Bachigualato Airport",
        "City": "Sinaloa",
        "State": "",
        "Country": "Mexico"
      },
      {
        "Id": 147,
        "AirportCode": "CUN",
        "AirportName": "Cancun Airport",
        "City": "Cancún",
        "State": "Quintana Roo",
        "Country": "Mexico"
      },
      {
        "Id": 148,
        "AirportCode": "CUZ",
        "AirportName": "Alejandro Velasco Astete International Airport",
        "City": "Cusco",
        "State": "",
        "Country": "Peru"
      },
      {
        "Id": 149,
        "AirportCode": "CVG",
        "AirportName": "Cincinnati/Northern Kentucky International Airport",
        "City": "Hebron",
        "State": "KY",
        "Country": "United States"
      },
      {
        "Id": 150,
        "AirportCode": "CVH",
        "AirportName": "Caviahue Airport",
        "City": "Caviahue",
        "State": "Neuquén Province",
        "Country": "Argentina"
      },
      {
        "Id": 151,
        "AirportCode": "CWB",
        "AirportName": "Afonso Pena Airport",
        "City": "Águas Belas - São José dos Pinhais ",
        "State": "PR",
        "Country": "Brazil"
      },
      {
        "Id": 152,
        "AirportCode": "CZM",
        "AirportName": "Cozumel Airport",
        "City": "Centro, Cozumel",
        "State": "Quintana Roo",
        "Country": "Mexico"
      },
      {
        "Id": 153,
        "AirportCode": "DAB",
        "AirportName": "Daytona Beach International Airport",
        "City": "Daytona Beach",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 154,
        "AirportCode": "DAL",
        "AirportName": "Dallas Love Field",
        "City": "Dallas",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 155,
        "AirportCode": "DAR",
        "AirportName": "Julius Nyerere International Airport",
        "City": "",
        "State": "",
        "Country": "Tanzania"
      },
      {
        "Id": 156,
        "AirportCode": "DAY",
        "AirportName": "James M Cox Dayton International Airport",
        "City": "Vandalia",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 157,
        "AirportCode": "DBQ",
        "AirportName": "Dubuque Airport",
        "City": "Dubuque",
        "State": "IA",
        "Country": "United States"
      },
      {
        "Id": 158,
        "AirportCode": "DBV",
        "AirportName": "Dubrovnik Airport",
        "City": "Mocici",
        "State": "",
        "Country": "Croatia"
      },
      {
        "Id": 159,
        "AirportCode": "DCA",
        "AirportName": "Ronald Reagan Washington National Airport",
        "City": "Washington",
        "State": "DC",
        "Country": "United States"
      },
      {
        "Id": 160,
        "AirportCode": "DDH",
        "AirportName": "William H Morse State Airport",
        "City": "Bennington",
        "State": "VT",
        "Country": "United States"
      },
      {
        "Id": 161,
        "AirportCode": "DEL",
        "AirportName": "Indira Gandhi International Airport",
        "City": "New Delhi",
        "State": "DL",
        "Country": "India"
      },
      {
        "Id": 162,
        "AirportCode": "DEN",
        "AirportName": "Denver International Airport",
        "City": "Denver",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 163,
        "AirportCode": "DET",
        "AirportName": "Coleman A. Young International Airport",
        "City": "Detroit",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 164,
        "AirportCode": "DFW",
        "AirportName": "Dallas/Fort Worth International Airport",
        "City": "Dallas",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 165,
        "AirportCode": "DLC",
        "AirportName": "Dalian Zhoushuizi International Airport",
        "City": "Dalian",
        "State": "Liaoning Province",
        "Country": "China"
      },
      {
        "Id": 166,
        "AirportCode": "DLH",
        "AirportName": "Duluth International Airport",
        "City": "Duluth",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 167,
        "AirportCode": "DME",
        "AirportName": "Domodedovo Airport",
        "City": "Baribino",
        "State": "Domodedovo district, Moscow region",
        "Country": "RU"
      },
      {
        "Id": 168,
        "AirportCode": "DPA",
        "AirportName": "DuPage County Airport",
        "City": "West Chicago",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 169,
        "AirportCode": "DRO",
        "AirportName": "Durango-La Plata County Airport",
        "City": "Durango",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 170,
        "AirportCode": "DRS",
        "AirportName": "Dresden Airport",
        "City": "Flughafenstraße",
        "State": "Dresden",
        "Country": "Germany"
      },
      {
        "Id": 171,
        "AirportCode": "DSI",
        "AirportName": "Destin Airport-DTS",
        "City": "Destin",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 173,
        "AirportCode": "DSV",
        "AirportName": "Dansville Muni",
        "City": "Dansville",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 174,
        "AirportCode": "DTM",
        "AirportName": "Dortmund Airport",
        "City": "Dortmund",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 175,
        "AirportCode": "DTW",
        "AirportName": "Detroit McNamara Terminal",
        "City": "Detroit",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 176,
        "AirportCode": "DUB",
        "AirportName": "Dublin International Airport",
        "City": "Dublin Airport",
        "State": "",
        "Country": "Ireland"
      },
      {
        "Id": 177,
        "AirportCode": "DUS",
        "AirportName": "Flughafen Düsseldorf Cargo GmbH",
        "City": "Düsseldorf",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 178,
        "AirportCode": "DXB",
        "AirportName": "Dubai International Airport",
        "City": "Dubai ",
        "State": "UAE",
        "Country": "United Arab Emirates"
      },
      {
        "Id": 179,
        "AirportCode": "DXR",
        "AirportName": "Danbury Municipal Airport",
        "City": "Danbury",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 180,
        "AirportCode": "EBB",
        "AirportName": "Entebbe International Airport",
        "City": "Entebbe",
        "State": "",
        "Country": "Uganda"
      },
      {
        "Id": 181,
        "AirportCode": "ECP",
        "AirportName": "Northwest Florida Beaches International Airport",
        "City": "Panama City",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 182,
        "AirportCode": "EDI",
        "AirportName": "Turnhouse Airport",
        "City": "Ingliston",
        "State": "Edinburgh",
        "Country": "United Kingdom"
      },
      {
        "Id": 183,
        "AirportCode": "EFD",
        "AirportName": "Ellington Field Airport",
        "City": "Houston",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 184,
        "AirportCode": "EGE",
        "AirportName": "Eagle County Airport",
        "City": "Gypsum",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 185,
        "AirportCode": "EIN",
        "AirportName": "Eindhoven Airport",
        "City": "Eindhoven",
        "State": "",
        "Country": "Netherlands"
      },
      {
        "Id": 186,
        "AirportCode": "ELM",
        "AirportName": "Elmira Corning Regional Airport",
        "City": "Horseheads",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 187,
        "AirportCode": "ELP",
        "AirportName": "El Paso International Airport",
        "City": "El Paso",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 188,
        "AirportCode": "ELZ",
        "AirportName": "Wellsville Municipal Airport",
        "City": "Wellsville",
        "State": "NY ",
        "Country": "USA"
      },
      {
        "Id": 189,
        "AirportCode": "EOH",
        "AirportName": "Olaya Herrera Airport",
        "City": "Medellín",
        "State": "Antioquia",
        "Country": "Colombia"
      },
      {
        "Id": 190,
        "AirportCode": "ERI",
        "AirportName": "Erie International Airport Tom Ridge Field",
        "City": "Erie",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 191,
        "AirportCode": "ETH",
        "AirportName": "Eilat Airport",
        "City": "",
        "State": "",
        "Country": "Israel"
      },
      {
        "Id": 192,
        "AirportCode": "EUG",
        "AirportName": "Eugene Airport",
        "City": "Douglas Dr",
        "State": "OR ",
        "Country": "USA"
      },
      {
        "Id": 193,
        "AirportCode": "EVV",
        "AirportName": "Evansville Regional Airport",
        "City": "Evansville",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 194,
        "AirportCode": "EVY",
        "AirportName": "Summit Airport",
        "City": "Middletown",
        "State": "DE",
        "Country": "United States"
      },
      {
        "Id": 195,
        "AirportCode": "EWB",
        "AirportName": "New Bedford Regional",
        "City": "New Bedford",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 196,
        "AirportCode": "EWR",
        "AirportName": "Newark International Airport",
        "City": "Newark",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 197,
        "AirportCode": "EZE",
        "AirportName": "Ministro Pistarini Airport",
        "City": "Ezeiza",
        "State": "Buenos Aires Province",
        "Country": "Argentina"
      },
      {
        "Id": 198,
        "AirportCode": "F45",
        "AirportName": "North Palm Beach County General Aviation",
        "City": "West Palm Beach",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 199,
        "AirportCode": "FAB",
        "AirportName": "Farnborough Airport",
        "City": "Farnborough",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 200,
        "AirportCode": "FAR",
        "AirportName": "Hector International Airport - Fargo",
        "City": "Fargo",
        "State": "ND",
        "Country": "United States"
      },
      {
        "Id": 201,
        "AirportCode": "FAT",
        "AirportName": "Fresno Yosemite International Airport",
        "City": "Fresno",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 202,
        "AirportCode": "FCM",
        "AirportName": "Flying Cloud Fields",
        "City": "Eden Prairie",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 203,
        "AirportCode": "FCO",
        "AirportName": "Aeroporto Fiumicino",
        "City": "Fiumicino",
        "State": "Province of Rome",
        "Country": "Italy"
      },
      {
        "Id": 204,
        "AirportCode": "FDK",
        "AirportName": "Frederick Municipal Airport",
        "City": "Frederick",
        "State": "MD",
        "Country": "United States"
      },
      {
        "Id": 205,
        "AirportCode": "FIT",
        "AirportName": "Fitchburg Municipal Airport",
        "City": "Fitchburg",
        "State": "MA ",
        "Country": "USA"
      },
      {
        "Id": 206,
        "AirportCode": "FLL",
        "AirportName": "Fort Lauderdale-Hollywood International Airport",
        "City": "Fort Lauderdale",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 207,
        "AirportCode": "FLN",
        "AirportName": "Hercilio Luz Airport",
        "City": "Carianos",
        "State": "Florianópolis - SC",
        "Country": "Brazil"
      },
      {
        "Id": 208,
        "AirportCode": "FLR",
        "AirportName": "Peretola Airport",
        "City": "Florence",
        "State": "Province of Florence",
        "Country": "Italy"
      },
      {
        "Id": 209,
        "AirportCode": "FMM",
        "AirportName": "Airpark Allgäu",
        "City": "Memmingerberg",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 210,
        "AirportCode": "FMY",
        "AirportName": "Page Field",
        "City": "FL",
        "State": "",
        "Country": "United States"
      },
      {
        "Id": 211,
        "AirportCode": "FNT",
        "AirportName": "Bishop International Airport",
        "City": "Flint Township",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 212,
        "AirportCode": "FOC",
        "AirportName": "Fuzhou Airport",
        "City": "",
        "State": "",
        "Country": "China"
      },
      {
        "Id": 213,
        "AirportCode": "FOK",
        "AirportName": "Francis S. Gabreski Airport",
        "City": "Westhampton Beach",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 214,
        "AirportCode": "FOR",
        "AirportName": "Pinto Martins International Airport",
        "City": "Fortaleza ",
        "State": "CE",
        "Country": "Brazil"
      },
      {
        "Id": 215,
        "AirportCode": "FPO",
        "AirportName": "Freeport International Airport",
        "City": "Freeport",
        "State": "",
        "Country": "Bahamas"
      },
      {
        "Id": 216,
        "AirportCode": "FRA",
        "AirportName": "Hilton Frankfurt Airport",
        "City": "Frankfurt",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 217,
        "AirportCode": "FRG",
        "AirportName": "Republic Airport",
        "City": "East Farmingdale",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 218,
        "AirportCode": "FSD",
        "AirportName": "Sioux Falls Regional Airport",
        "City": "Sioux Falls",
        "State": "SD",
        "Country": "United States"
      },
      {
        "Id": 219,
        "AirportCode": "FTW",
        "AirportName": "Meacham International Airport",
        "City": "Fort Worth",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 220,
        "AirportCode": "FTY",
        "AirportName": "Fulton County Airport-Brown Field",
        "City": "Atlanta",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 221,
        "AirportCode": "FUE",
        "AirportName": "Fuerteventura Airport",
        "City": "Fuerteventura",
        "State": "",
        "Country": "España"
      },
      {
        "Id": 222,
        "AirportCode": "FWA",
        "AirportName": "Fort Wayne International Airport",
        "City": "Fort Wayne",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 223,
        "AirportCode": "FXE",
        "AirportName": "Fort Lauderdale Executive",
        "City": "Fort Lauderdale",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 224,
        "AirportCode": "GAI",
        "AirportName": "Montgomery County Airpark",
        "City": "Gaithersburg",
        "State": "MD",
        "Country": "United States"
      },
      {
        "Id": 225,
        "AirportCode": "GCM",
        "AirportName": "Owen Roberts International Airport",
        "City": "George Town",
        "State": "Grand Cayman",
        "Country": "Cayman Islands"
      },
      {
        "Id": 226,
        "AirportCode": "GDL",
        "AirportName": "Miguel Hidalgo y Costilla International Airport",
        "City": "Jalisco",
        "State": "",
        "Country": "Mexico"
      },
      {
        "Id": 227,
        "AirportCode": "GEG",
        "AirportName": "Spokane International Airport",
        "City": "Spokane",
        "State": "WA",
        "Country": "United States"
      },
      {
        "Id": 228,
        "AirportCode": "GHG",
        "AirportName": "Marshfield Municipal Airport",
        "City": "Marshfield",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 229,
        "AirportCode": "GIG",
        "AirportName": "Rio de Janeiro-Galeão International Airport",
        "City": "Galeao",
        "State": "Rio de Janeiro - RJ",
        "Country": "Brazil"
      },
      {
        "Id": 230,
        "AirportCode": "GLA",
        "AirportName": "Glasgow Airport",
        "City": "Paisley",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 231,
        "AirportCode": "GMP",
        "AirportName": "Gimpo International Airport",
        "City": "Gangseo-gu",
        "State": "Seoul",
        "Country": "South Korea"
      },
      {
        "Id": 232,
        "AirportCode": "GMU",
        "AirportName": "Greenville Downtown Airport",
        "City": "Greenville",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 233,
        "AirportCode": "GNB",
        "AirportName": "Grenoble-Isère Airport",
        "City": "Saint-Étienne-de-Saint-Geoirs",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 234,
        "AirportCode": "GNV",
        "AirportName": "Gainesville Regional",
        "City": "Gainesville",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 235,
        "AirportCode": "GOA",
        "AirportName": "Cristoforo Colombo Airport",
        "City": "Genoa",
        "State": "Province of Genoa",
        "Country": "Italy"
      },
      {
        "Id": 236,
        "AirportCode": "GOI",
        "AirportName": "Dabolim Airport",
        "City": "Vasco da Gama",
        "State": "GA",
        "Country": "India"
      },
      {
        "Id": 237,
        "AirportCode": "GOT",
        "AirportName": "Landvetter Airport",
        "City": "Gothenburg\n",
        "State": "",
        "Country": "Sweden"
      },
      {
        "Id": 238,
        "AirportCode": "GPT",
        "AirportName": "Gulfport-Biloxi International Airport",
        "City": "Gulfport",
        "State": "MS",
        "Country": "United States"
      },
      {
        "Id": 239,
        "AirportCode": "GRB",
        "AirportName": "Austin Straubel International Airport",
        "City": "Green Bay",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 240,
        "AirportCode": "GRK",
        "AirportName": "Killeen-Fort Hood Regional Airport",
        "City": "Killeen",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 241,
        "AirportCode": "GRR",
        "AirportName": "Gerald R. Ford International Airport",
        "City": "Grand Rapids",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 242,
        "AirportCode": "GRU",
        "AirportName": "GRU Airport",
        "City": "Cumbica ",
        "State": "Guarulhos",
        "Country": "Brazil"
      },
      {
        "Id": 243,
        "AirportCode": "GSO",
        "AirportName": "Piedmont Triad International Airport",
        "City": "Greensboro",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 244,
        "AirportCode": "GSP",
        "AirportName": "Greenville-Spartanburg International Airport",
        "City": "Greer",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 245,
        "AirportCode": "GTF",
        "AirportName": "Great Falls International Airport",
        "City": "Great Falls",
        "State": "MT",
        "Country": "United States"
      },
      {
        "Id": 246,
        "AirportCode": "GUA",
        "AirportName": "La Aurora International Airport",
        "City": "Guatemala City",
        "State": "",
        "Country": "Guatemala"
      },
      {
        "Id": 247,
        "AirportCode": "GUM",
        "AirportName": "A. B. Won Pat International Airport",
        "City": "Tamuning",
        "State": "",
        "Country": "Guam"
      },
      {
        "Id": 248,
        "AirportCode": "GVA",
        "AirportName": "Geneva International Airport",
        "City": "Meyrin",
        "State": "",
        "Country": "Switzerland"
      },
      {
        "Id": 249,
        "AirportCode": "GYE",
        "AirportName": "Simon Bolivar Airport",
        "City": "Avenida de Las Americas",
        "State": "Guayaquil",
        "Country": "Ecuador"
      },
      {
        "Id": 250,
        "AirportCode": "HAJ",
        "AirportName": "Hannover Airport",
        "City": "Langenhagen",
        "State": " ",
        "Country": "Germany"
      },
      {
        "Id": 251,
        "AirportCode": "HAM",
        "AirportName": "Hamburg Airport",
        "City": "Hamburg",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 252,
        "AirportCode": "HAN",
        "AirportName": "Noi Bai International Airport",
        "City": "Quang Ti?n",
        "State": "Soc Son District, Hanoi",
        "Country": "Vietnam"
      },
      {
        "Id": 253,
        "AirportCode": "HDC",
        "AirportName": "Hammond Northshore Regional",
        "City": "Hammond",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 254,
        "AirportCode": "HDH",
        "AirportName": "Dillingham Airfield",
        "City": "Waialua",
        "State": "HI",
        "Country": "United States"
      },
      {
        "Id": 255,
        "AirportCode": "HEF",
        "AirportName": "Manassas Regional Airport",
        "City": "Manassas",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 256,
        "AirportCode": "HEL",
        "AirportName": "Helsinki Airport",
        "City": "Vantaa",
        "State": "Suomi",
        "Country": "Finland"
      },
      {
        "Id": 257,
        "AirportCode": "HFD",
        "AirportName": "Hartford-Brainard Airport",
        "City": "Hartford",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 258,
        "AirportCode": "HGH",
        "AirportName": "Hangzhou Xiaoshan International Airport",
        "City": "Hangzhou",
        "State": "Zhejiang",
        "Country": "China"
      },
      {
        "Id": 259,
        "AirportCode": "HHH",
        "AirportName": "Hilton Head Island Airport",
        "City": "Hilton Head Island",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 260,
        "AirportCode": "HHR",
        "AirportName": "Hawthorne Municipal Airport",
        "City": "Hawthorne",
        "State": "CA ",
        "Country": "United States"
      },
      {
        "Id": 261,
        "AirportCode": "HIO",
        "AirportName": "Portland-Hillsboro Airport",
        "City": "Hillsboro",
        "State": "OR",
        "Country": "United States"
      },
      {
        "Id": 262,
        "AirportCode": "HKG",
        "AirportName": "Hong Kong International Airport",
        "City": "Lantau",
        "State": "",
        "Country": "Hong Kong"
      },
      {
        "Id": 263,
        "AirportCode": "HKT",
        "AirportName": "Phuket International Airport",
        "City": "Thalang",
        "State": "Phuket",
        "Country": "Thailand"
      },
      {
        "Id": 264,
        "AirportCode": "HLN",
        "AirportName": "Helena Regional Airport",
        "City": "Helena",
        "State": "MT",
        "Country": "United States"
      },
      {
        "Id": 265,
        "AirportCode": "HND",
        "AirportName": "Haneda Airport",
        "City": "Ota",
        "State": "Tokyo",
        "Country": "Japan"
      },
      {
        "Id": 266,
        "AirportCode": "HNL",
        "AirportName": "Honolulu International Airport",
        "City": "Honolulu",
        "State": "HI",
        "Country": "United States"
      },
      {
        "Id": 267,
        "AirportCode": "HOT",
        "AirportName": "Memorial Field-Hot",
        "City": "Hot Springs",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 268,
        "AirportCode": "HOU",
        "AirportName": "William P Hobby Airport-Houston",
        "City": "Houston",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 269,
        "AirportCode": "HPN",
        "AirportName": "Westchester County Airport",
        "City": "White Plains",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 270,
        "AirportCode": "HPV",
        "AirportName": "Princeville Airport",
        "City": "Kilauea",
        "State": "HI",
        "Country": "United States"
      },
      {
        "Id": 271,
        "AirportCode": "HSV",
        "AirportName": "Huntsville International Airport",
        "City": "Huntsville",
        "State": "AL",
        "Country": "United States"
      },
      {
        "Id": 272,
        "AirportCode": "HTO",
        "AirportName": "East Hampton Airport",
        "City": "Wainscott",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 273,
        "AirportCode": "HTS",
        "AirportName": "Huntington Tri-State Airport",
        "City": "Huntington",
        "State": "WV",
        "Country": "United States"
      },
      {
        "Id": 274,
        "AirportCode": "HUM",
        "AirportName": "Houma-Terrebonne Airport",
        "City": "Houma",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 275,
        "AirportCode": "HVN",
        "AirportName": "Tweed New Haven Regional Airport",
        "City": "East Haven",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 276,
        "AirportCode": "HWD",
        "AirportName": "Hayward Executive Airport-Hwd",
        "City": "Hayward",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 277,
        "AirportCode": "HYA",
        "AirportName": "Barnstable Municipal Airport",
        "City": "Hyannis",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 278,
        "AirportCode": "HYD",
        "AirportName": "Rajiv Gandhi International Airport",
        "City": "Hyderabad",
        "State": "Andhra Pradesh",
        "Country": "India"
      },
      {
        "Id": 279,
        "AirportCode": "IAD",
        "AirportName": "Washington Dulles International Airport",
        "City": "Sterling",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 280,
        "AirportCode": "IAH",
        "AirportName": "George Bush Intercontinental Airport",
        "City": "Houston",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 281,
        "AirportCode": "ICN",
        "AirportName": "New Incheon International Airport",
        "City": "Incheon",
        "State": "",
        "Country": "South Korea"
      },
      {
        "Id": 282,
        "AirportCode": "ICT",
        "AirportName": "Wichita Mid-Continent Airport",
        "City": "Wichita",
        "State": "KS",
        "Country": "United States"
      },
      {
        "Id": 283,
        "AirportCode": "IJD",
        "AirportName": "Windham Airport",
        "City": "North Windham",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 284,
        "AirportCode": "IKK",
        "AirportName": "Greater Kankakee Airport",
        "City": "Kankakee",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 285,
        "AirportCode": "ILG",
        "AirportName": "New Castle Airport",
        "City": "New Castle",
        "State": "DE",
        "Country": "United States"
      },
      {
        "Id": 286,
        "AirportCode": "ILM",
        "AirportName": "Wilmington International Airport",
        "City": "Wilmington",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 287,
        "AirportCode": "IND",
        "AirportName": "Indianapolis International Airport",
        "City": "Indianapolis",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 288,
        "AirportCode": "INN",
        "AirportName": "Sixt Innsbruck Airport",
        "City": "Innsbruck",
        "State": "",
        "Country": "Austria"
      },
      {
        "Id": 289,
        "AirportCode": "INT",
        "AirportName": "Smith Reynolds Airport",
        "City": "Winston-Salem",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 290,
        "AirportCode": "INV",
        "AirportName": "Inverness Airport",
        "City": "Scotland",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 291,
        "AirportCode": "IQQ",
        "AirportName": "Cavancha Airport",
        "City": "",
        "State": "",
        "Country": "Chile"
      },
      {
        "Id": 292,
        "AirportCode": "ISM",
        "AirportName": "Kissimmee Gateway Airport",
        "City": "Kissimmee",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 293,
        "AirportCode": "ISP",
        "AirportName": "Long Island MacArthur Airport",
        "City": "Ronkonkoma",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 294,
        "AirportCode": "IST",
        "AirportName": "Istanbul Airport",
        "City": "Arnavutkoy",
        "State": "Istanbul",
        "Country": "Turkey"
      },
      {
        "Id": 295,
        "AirportCode": "ITH",
        "AirportName": "Ithaca Tompkins Regional Airport",
        "City": "Ithaca",
        "State": "NY ",
        "Country": "United States"
      },
      {
        "Id": 296,
        "AirportCode": "ITM",
        "AirportName": "Osaka International Airport",
        "City": "Toyonaka",
        "State": "Osaka Prefecture",
        "Country": "Japan"
      },
      {
        "Id": 297,
        "AirportCode": "IXC",
        "AirportName": "Chandigarh International Airport",
        "City": "Chandigarh",
        "State": "",
        "Country": "India"
      },
      {
        "Id": 298,
        "AirportCode": "IXL",
        "AirportName": "Kushok Bakula Rinpoche Airport",
        "City": "Leh",
        "State": "Jammu and Kashmir",
        "Country": "India"
      },
      {
        "Id": 299,
        "AirportCode": "JAC",
        "AirportName": "Jackson Hole Airport",
        "City": "Jackson",
        "State": "WY",
        "Country": "United States"
      },
      {
        "Id": 300,
        "AirportCode": "JAI",
        "AirportName": "Jaipur International Airport",
        "City": "Jaipur",
        "State": "Rajasthan",
        "Country": "India"
      },
      {
        "Id": 301,
        "AirportCode": "JAX",
        "AirportName": "Jacksonville International Airport",
        "City": "Jacksonville",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 302,
        "AirportCode": "JDH",
        "AirportName": "Jodhpur Airport",
        "City": "Jodhpur",
        "State": "Rajasthan",
        "Country": "India"
      },
      {
        "Id": 303,
        "AirportCode": "JED",
        "AirportName": "King Abdulaziz International Airport",
        "City": "Jeddah ",
        "State": "",
        "Country": "Saudi Arabia"
      },
      {
        "Id": 304,
        "AirportCode": "JFK",
        "AirportName": "John F. Kennedy International Airport",
        "City": "Jamaica",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 305,
        "AirportCode": "JNB",
        "AirportName": "Johannesburg International Airport",
        "City": "O.R. Tambo",
        "State": "Kempton Park",
        "Country": "South Africa"
      },
      {
        "Id": 306,
        "AirportCode": "JQF",
        "AirportName": "Concord Regional Airport",
        "City": "Concord",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 307,
        "AirportCode": "JRA",
        "AirportName": "Port Authority-West 30th Street Midtown Heliport",
        "City": "New York",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 308,
        "AirportCode": "JWN",
        "AirportName": "John C. Tune Airport",
        "City": "Nashville",
        "State": "TN",
        "Country": "United States"
      },
      {
        "Id": 309,
        "AirportCode": "KBP",
        "AirportName": "Boryspil' airport",
        "City": "",
        "State": "",
        "Country": "Ukraine"
      },
      {
        "Id": 310,
        "AirportCode": "KEF",
        "AirportName": "Keflavík International Airport",
        "City": "",
        "State": "",
        "Country": "Iceland"
      },
      {
        "Id": 311,
        "AirportCode": "KEH",
        "AirportName": "Kenmore Air Harbor Airport",
        "City": "Kenmore Air Harbor",
        "State": "WA",
        "Country": "United States"
      },
      {
        "Id": 312,
        "AirportCode": "KIX",
        "AirportName": "Kansai International Airport",
        "City": "Izumisano",
        "State": "Osaka Prefecture",
        "Country": "Japan"
      },
      {
        "Id": 313,
        "AirportCode": "KOA",
        "AirportName": "Keahole-Kona International Airport",
        "City": "Kailua-Kona",
        "State": "HI",
        "Country": "United States"
      },
      {
        "Id": 314,
        "AirportCode": "KRK",
        "AirportName": "John Paul II International Airport Kraków-Balice",
        "City": "Balice",
        "State": "",
        "Country": "Poland"
      },
      {
        "Id": 315,
        "AirportCode": "KTW",
        "AirportName": "Pyrzowice Airport",
        "City": "Gmina O?arowice",
        "State": "",
        "Country": "Poland"
      },
      {
        "Id": 316,
        "AirportCode": "KUL",
        "AirportName": "Kuala Lumpur International Airport",
        "City": "Sepang",
        "State": "Klia",
        "Country": "Malaysia"
      },
      {
        "Id": 317,
        "AirportCode": "LAN",
        "AirportName": "Capital Region International Airport",
        "City": "Lansing",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 318,
        "AirportCode": "LAS",
        "AirportName": "McCarran International Airport",
        "City": "Las Vegas",
        "State": "NV",
        "Country": "United States"
      },
      {
        "Id": 319,
        "AirportCode": "LAX",
        "AirportName": "Los Angeles International Airport",
        "City": "Los Angeles",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 321,
        "AirportCode": "LBA",
        "AirportName": "Leeds Bradford International Airport",
        "City": "Leeds",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 322,
        "AirportCode": "LBB",
        "AirportName": "Lubbock Preston Smith International Airport",
        "City": "Lubbock",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 323,
        "AirportCode": "LBG",
        "AirportName": "Le Bourget Airport",
        "City": "Le Bourget",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 324,
        "AirportCode": "LCA",
        "AirportName": "Larnaca Airport",
        "City": "",
        "State": "",
        "Country": ""
      },
      {
        "Id": 325,
        "AirportCode": "LCG",
        "AirportName": "A Coruña Airport",
        "City": "Rutis",
        "State": "GA",
        "Country": "ES"
      },
      {
        "Id": 326,
        "AirportCode": "LCH",
        "AirportName": "Lake Charles Regional Airport",
        "City": "Lake Charles",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 327,
        "AirportCode": "LCK",
        "AirportName": "Rickenbacker International Airport",
        "City": "Columbus",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 328,
        "AirportCode": "LCY",
        "AirportName": "London City Airport",
        "City": "London ",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 329,
        "AirportCode": "LDJ",
        "AirportName": "Linden Airport",
        "City": "Linden",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 330,
        "AirportCode": "LEB",
        "AirportName": "Lebanon Municipal Airport",
        "City": "Lebanon",
        "State": "NH",
        "Country": "United States"
      },
      {
        "Id": 331,
        "AirportCode": "LED",
        "AirportName": "Pulkovo 1 airport",
        "City": "Pulkovo",
        "State": "Leningrad Oblast",
        "Country": "Russia"
      },
      {
        "Id": 332,
        "AirportCode": "LEJ",
        "AirportName": "Leipzig/Halle Airport",
        "City": "Schkeuditz",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 333,
        "AirportCode": "LEW",
        "AirportName": "Auburn-Lewiston Municipal Airport",
        "City": "Auburn",
        "State": "ME",
        "Country": "United States"
      },
      {
        "Id": 334,
        "AirportCode": "LEX",
        "AirportName": "Blue Grass Airport",
        "City": "Lexington",
        "State": "KY",
        "Country": "United States"
      },
      {
        "Id": 335,
        "AirportCode": "LFT",
        "AirportName": "Lafayette Regional Airport",
        "City": "Lafayette",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 336,
        "AirportCode": "LGA",
        "AirportName": "LaGuardia Airport",
        "City": "Flushing",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 337,
        "AirportCode": "LGB",
        "AirportName": "Long Beach Airport-LGB",
        "City": "Long Beach",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 338,
        "AirportCode": "LGG",
        "AirportName": "Liège Airport",
        "City": "Grace-Hollogne",
        "State": "",
        "Country": "Belgium"
      },
      {
        "Id": 339,
        "AirportCode": "LGW",
        "AirportName": "Gatwick Airport",
        "City": "",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 340,
        "AirportCode": "LHR",
        "AirportName": "Heathrow Airport",
        "City": "London",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 341,
        "AirportCode": "LIH",
        "AirportName": "Lihue Airport",
        "City": "Lihue",
        "State": "HI",
        "Country": "USA"
      },
      {
        "Id": 342,
        "AirportCode": "LIM",
        "AirportName": "Jorge Chavez International Airport",
        "City": "Callao",
        "State": "",
        "Country": "Peru"
      },
      {
        "Id": 343,
        "AirportCode": "LIN",
        "AirportName": "Aeroporto Milano Linate",
        "City": "Segrate",
        "State": "Province of Milan",
        "Country": "Italy"
      },
      {
        "Id": 344,
        "AirportCode": "LIS",
        "AirportName": "Lisbon International Airport",
        "City": "Lisbon",
        "State": "",
        "Country": "Portugal"
      },
      {
        "Id": 345,
        "AirportCode": "LIT",
        "AirportName": "Adams Field",
        "City": "Little Rock",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 346,
        "AirportCode": "LJU",
        "AirportName": "Airport Ljubljana",
        "City": "",
        "State": "",
        "Country": "Slovenia"
      },
      {
        "Id": 347,
        "AirportCode": "LNS",
        "AirportName": "Lancaster Airport",
        "City": "Lititz",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 348,
        "AirportCode": "LOM",
        "AirportName": "Wings Field",
        "City": "Blue Bell",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 349,
        "AirportCode": "LOZ",
        "AirportName": "London-Corbin Airport-Magee Field",
        "City": "London",
        "State": "KY",
        "Country": "United States"
      },
      {
        "Id": 350,
        "AirportCode": "LPB",
        "AirportName": "El Alto Airport",
        "City": "El Alto",
        "State": "",
        "Country": "Bolivia"
      },
      {
        "Id": 351,
        "AirportCode": "LPR",
        "AirportName": "Elyria Airport-1G1",
        "City": "Elyria",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 352,
        "AirportCode": "LTN",
        "AirportName": "London Luton Airport",
        "City": "Luton",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 353,
        "AirportCode": "LUG",
        "AirportName": "Lugano Airport",
        "City": "Agno",
        "State": "",
        "Country": "Switzerland"
      },
      {
        "Id": 354,
        "AirportCode": "LUK",
        "AirportName": "Cincinnati Municipal Airport - Lunken Field",
        "City": "Cincinnati",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 355,
        "AirportCode": "LUX",
        "AirportName": "Luxembourg Airport",
        "City": "Niederanven",
        "State": "",
        "Country": "Luxembourg"
      },
      {
        "Id": 356,
        "AirportCode": "LWM",
        "AirportName": "Lawrence Municipal Airport",
        "City": "North Andover",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 357,
        "AirportCode": "LYS",
        "AirportName": "Lyon-Saint Exupéry Airport",
        "City": "Lyon Satolas Aeroport",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 358,
        "AirportCode": "MAA",
        "AirportName": "Madras International",
        "City": "Chennai",
        "State": "Tamil Nadu",
        "Country": "India"
      },
      {
        "Id": 359,
        "AirportCode": "MAD",
        "AirportName": "Madrid-Barajas Adolfo Suárez Airport",
        "City": "Madrid",
        "State": "Comunidad de Madrid",
        "Country": "Spain"
      },
      {
        "Id": 360,
        "AirportCode": "MAF",
        "AirportName": "Midland International Airport",
        "City": "Midland",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 361,
        "AirportCode": "MAN",
        "AirportName": "Manchester International Airport",
        "City": "Manchester International Airport",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 362,
        "AirportCode": "MBA",
        "AirportName": "Moi International Airport",
        "City": "Mombasa",
        "State": "",
        "Country": "Kenya"
      },
      {
        "Id": 363,
        "AirportCode": "MBS",
        "AirportName": "MBS International Airport",
        "City": "Freeland",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 364,
        "AirportCode": "MCI",
        "AirportName": "Kansas City International Airport",
        "City": "Kansas City",
        "State": "MO",
        "Country": "United States"
      },
      {
        "Id": 365,
        "AirportCode": "MCO",
        "AirportName": "Orlando International Airport",
        "City": "Orlando",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 366,
        "AirportCode": "MCT",
        "AirportName": "Muscat International Airport",
        "City": "Seeb",
        "State": "",
        "Country": "Oman"
      },
      {
        "Id": 367,
        "AirportCode": "MDE",
        "AirportName": "Jose Maria Cordova International Airport",
        "City": "Rionegro",
        "State": "Antioquia",
        "Country": "Colombia"
      },
      {
        "Id": 368,
        "AirportCode": "MDT",
        "AirportName": "Harrisburg International Airport",
        "City": "Middletown",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 369,
        "AirportCode": "MDW",
        "AirportName": "Midway International Airport",
        "City": "Chicago",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 370,
        "AirportCode": "MDZ",
        "AirportName": "El Plumerillo Airport",
        "City": "Mendoza",
        "State": "Mendoza Province",
        "Country": "Argentina"
      },
      {
        "Id": 371,
        "AirportCode": "MEL",
        "AirportName": "Melbourne International Airport",
        "City": "Tullamarine VIC",
        "State": "Victoria",
        "Country": "Australia"
      },
      {
        "Id": 372,
        "AirportCode": "MEM",
        "AirportName": "Memphis International Airport",
        "City": "Memphis",
        "State": "TN",
        "Country": "United States"
      },
      {
        "Id": 373,
        "AirportCode": "MER",
        "AirportName": "Trenton Mercer Airport",
        "City": "Ewing Township",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 374,
        "AirportCode": "MEX",
        "AirportName": "Mexico City Airport",
        "City": "Mexico City",
        "State": "Distrito Federal",
        "Country": "Mexico"
      },
      {
        "Id": 375,
        "AirportCode": "MGM",
        "AirportName": "Montgomery Regional Airport",
        "City": "Montgomery",
        "State": "AL",
        "Country": "United States"
      },
      {
        "Id": 376,
        "AirportCode": "MGY",
        "AirportName": "Dayton-Wright Brothers Airport",
        "City": "Miamisburg",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 377,
        "AirportCode": "MHT",
        "AirportName": "Manchester-Boston Regional Airport",
        "City": "Manchester",
        "State": "NH",
        "Country": "United States"
      },
      {
        "Id": 378,
        "AirportCode": "MIA",
        "AirportName": "Miami International Airport",
        "City": "Miami",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 379,
        "AirportCode": "MKC",
        "AirportName": "Kansas City Downtown Airport",
        "City": "Kansas City",
        "State": "MO",
        "Country": "United States"
      },
      {
        "Id": 380,
        "AirportCode": "MKE",
        "AirportName": "General Mitchell International Airport",
        "City": "Milwaukee",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 381,
        "AirportCode": "MKG",
        "AirportName": "Muskegon County Airport",
        "City": "Muskegon",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 382,
        "AirportCode": "MLA",
        "AirportName": "Malta International Airport",
        "City": "Luqa",
        "State": "",
        "Country": "Malta"
      },
      {
        "Id": 383,
        "AirportCode": "MLB",
        "AirportName": "Melbourne Airport",
        "City": "Melbourne",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 384,
        "AirportCode": "MLI",
        "AirportName": "Quad City International Airport",
        "City": "Moline",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 385,
        "AirportCode": "MLU",
        "AirportName": "Monroe Regional Airport",
        "City": "Monroe",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 386,
        "AirportCode": "MMU",
        "AirportName": "Morristown Municipal Airport",
        "City": "Morristown",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 387,
        "AirportCode": "MMX",
        "AirportName": "Malmö Airport",
        "City": "Malmö-Sturup",
        "State": "Sverige",
        "Country": "Sweden"
      },
      {
        "Id": 388,
        "AirportCode": "MNL",
        "AirportName": "Ninoy Aquino International Airport",
        "City": "Pasay City",
        "State": "Manila",
        "Country": "Philippines"
      },
      {
        "Id": 389,
        "AirportCode": "MOB",
        "AirportName": "Mobile Regional Airport",
        "City": "Mobile",
        "State": "AL",
        "Country": "United States"
      },
      {
        "Id": 390,
        "AirportCode": "MOD",
        "AirportName": "Modesto City Airport",
        "City": "Modesto",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 391,
        "AirportCode": "MOW",
        "AirportName": "Metropolitan Area Airport",
        "City": "Moscow",
        "State": "",
        "Country": "Russia"
      },
      {
        "Id": 392,
        "AirportCode": "MRS",
        "AirportName": "Marseille Airport",
        "City": "Marignane cedex",
        "State": "Aéroport Marseille Provence",
        "Country": "France"
      },
      {
        "Id": 393,
        "AirportCode": "MRY",
        "AirportName": "Monterey Peninsula Airport",
        "City": "Monterey",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 394,
        "AirportCode": "MSN",
        "AirportName": "Hertz",
        "City": "Madison",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 395,
        "AirportCode": "MSP",
        "AirportName": "Minneapolis-St Paul International Airport ( MSP) Terminal 1-Lindbergh",
        "City": "Minneapolis",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 396,
        "AirportCode": "MSV",
        "AirportName": "Sullivan County Airport",
        "City": "Sullivan",
        "State": "IN",
        "Country": ""
      },
      {
        "Id": 397,
        "AirportCode": "MSY",
        "AirportName": "Louis Armstrong New Orleans International Airport",
        "City": "Kenner",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 398,
        "AirportCode": "MTA",
        "AirportName": "Matamata Glider Airport",
        "City": "Waharoa",
        "State": "",
        "Country": "New Zealand"
      },
      {
        "Id": 399,
        "AirportCode": "MTG",
        "AirportName": "Mato Grosso Airport",
        "City": "Mato Grosso - MT",
        "State": "",
        "Country": "Brazil"
      },
      {
        "Id": 400,
        "AirportCode": "MTJ",
        "AirportName": "Montrose Regional Airport",
        "City": "Montrose",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 401,
        "AirportCode": "MTN",
        "AirportName": "Martin State Airport",
        "City": "Baltimore",
        "State": "MD",
        "Country": "United States"
      },
      {
        "Id": 402,
        "AirportCode": "MTY",
        "AirportName": "Monterrey International Airport",
        "City": "Ciudad Apodaca",
        "State": "Nuevo Leon",
        "Country": "MX"
      },
      {
        "Id": 403,
        "AirportCode": "MUC",
        "AirportName": "Munich Airport",
        "City": "München-Flughafen",
        "State": "",
        "Country": "Deutschland"
      },
      {
        "Id": 404,
        "AirportCode": "MVD",
        "AirportName": "Carrasco International Airport",
        "City": "Ciudad de la Costa",
        "State": "",
        "Country": "Uruguay"
      },
      {
        "Id": 405,
        "AirportCode": "MVT",
        "AirportName": "Morrisville-Stowe State Airport",
        "City": "Morrisville",
        "State": "VT",
        "Country": "United States"
      },
      {
        "Id": 406,
        "AirportCode": "MVY",
        "AirportName": "Marthas Vineyard Airport",
        "City": "West Tisbury",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 407,
        "AirportCode": "MXP",
        "AirportName": "Milano Malpensa",
        "City": "Ferno",
        "State": "Province of Varese",
        "Country": "Italy"
      },
      {
        "Id": 408,
        "AirportCode": "MYR",
        "AirportName": "Myrtle Beach International Airport",
        "City": "Myrtle Beach",
        "State": "SC",
        "Country": "United States"
      },
      {
        "Id": 409,
        "AirportCode": "N66",
        "AirportName": "Oneonta Municipal Airport",
        "City": "Oneonta",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 410,
        "AirportCode": "NAG",
        "AirportName": "Dr. Babasaheb Ambedkar International Airport",
        "City": "Nagpur",
        "State": "MH",
        "Country": "India"
      },
      {
        "Id": 411,
        "AirportCode": "NAP",
        "AirportName": "Naples Airport",
        "City": "Naples",
        "State": "Province of Naples",
        "Country": "Italy"
      },
      {
        "Id": 412,
        "AirportCode": "NAS",
        "AirportName": "Lyndon Pindling International Airport",
        "City": "New Providence",
        "State": "",
        "Country": "Bahamas"
      },
      {
        "Id": 413,
        "AirportCode": "NBO",
        "AirportName": "Jomo Kenyatta International Airport",
        "City": "Nairobi",
        "State": "",
        "Country": "Kenya"
      },
      {
        "Id": 414,
        "AirportCode": "NCE",
        "AirportName": "Cote d'Azur Airport",
        "City": "",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 415,
        "AirportCode": "NEW",
        "AirportName": "New Orleans Lakefront Airport",
        "City": "New Orleans",
        "State": "LA",
        "Country": "United States"
      },
      {
        "Id": 416,
        "AirportCode": "NGO",
        "AirportName": "Nagoya Airfield",
        "City": "Nishikasugai District",
        "State": "Aichi Prefecture",
        "Country": "Japan"
      },
      {
        "Id": 417,
        "AirportCode": "NRT",
        "AirportName": "Narita Airport",
        "City": "Narita",
        "State": "Chiba Prefecture",
        "Country": "Japan"
      },
      {
        "Id": 418,
        "AirportCode": "NTE",
        "AirportName": "Nantes Atlantique Airport",
        "City": "Bouguenais Cedex",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 419,
        "AirportCode": "NUE",
        "AirportName": "Nuremberg Airport",
        "City": "Nuremberg",
        "State": "",
        "Country": "90411"
      },
      {
        "Id": 420,
        "AirportCode": "OAK",
        "AirportName": "Oakland International Airport",
        "City": "Oakland",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 421,
        "AirportCode": "OGG",
        "AirportName": "Kahului Airport",
        "City": "Kahului",
        "State": "HI 96779",
        "Country": "USA"
      },
      {
        "Id": 422,
        "AirportCode": "OKC",
        "AirportName": "Will Rogers World Airport",
        "City": "Oklahoma City",
        "State": "OK",
        "Country": "United States"
      },
      {
        "Id": 423,
        "AirportCode": "OMA",
        "AirportName": "Eppley Airfield",
        "City": "Omaha",
        "State": "NE",
        "Country": "United States"
      },
      {
        "Id": 424,
        "AirportCode": "ONT",
        "AirportName": "LA/Ontario International Airport",
        "City": "Ontario",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 425,
        "AirportCode": "OPF",
        "AirportName": "Opa-locka Executive Airport",
        "City": "Opa-locka",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 426,
        "AirportCode": "OQN",
        "AirportName": "Brandywine Airport",
        "City": "West Chester",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 427,
        "AirportCode": "ORD",
        "AirportName": "Chicago O'Hare International Airport",
        "City": "Chicago",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 428,
        "AirportCode": "ORF",
        "AirportName": "Norfolk International Airport",
        "City": "Norfolk",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 429,
        "AirportCode": "ORH",
        "AirportName": "Worcester Regional Airport",
        "City": "Worcester",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 430,
        "AirportCode": "ORK",
        "AirportName": "Cork International Airport",
        "City": "",
        "State": "",
        "Country": "Ireland"
      },
      {
        "Id": 431,
        "AirportCode": "ORL",
        "AirportName": "Orlando Executive Airport",
        "City": "Orlando",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 432,
        "AirportCode": "ORY",
        "AirportName": "Orly Field",
        "City": "Orly Aérogares cedex",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 433,
        "AirportCode": "OSH",
        "AirportName": "Wittman Regional Airport",
        "City": "Oshkosh",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 434,
        "AirportCode": "OSL",
        "AirportName": "Oslo Airport, Gardermoen",
        "City": "Gardermoen",
        "State": "Norge",
        "Country": "Norway"
      },
      {
        "Id": 435,
        "AirportCode": "OST",
        "AirportName": "Ostend-Bruges International Airport",
        "City": "Oostende",
        "State": "",
        "Country": "Belgium"
      },
      {
        "Id": 436,
        "AirportCode": "OSU",
        "AirportName": "Ohio State University Airport",
        "City": "Columbus",
        "State": "OH ",
        "Country": "United States"
      },
      {
        "Id": 437,
        "AirportCode": "OTP",
        "AirportName": "Henri Coand? International Airport",
        "City": "Otopeni",
        "State": "",
        "Country": "Romania"
      },
      {
        "Id": 438,
        "AirportCode": "OWD",
        "AirportName": "Norwood Memorial Airport",
        "City": "Norwood",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 439,
        "AirportCode": "OXC",
        "AirportName": "Waterbury-Oxford Airport",
        "City": "Oxford",
        "State": "CT",
        "Country": "United States"
      },
      {
        "Id": 440,
        "AirportCode": "PAC",
        "AirportName": "Marcos A. Gelabert International Airport",
        "City": "Avenue Canfield",
        "State": "",
        "Country": "Panama"
      },
      {
        "Id": 441,
        "AirportCode": "PAT",
        "AirportName": "Lok Nayak Jayaprakash Narayan International Airport",
        "City": "Patna",
        "State": "Bihar",
        "Country": "India"
      },
      {
        "Id": 442,
        "AirportCode": "PBI",
        "AirportName": "Palm Beach International Airport",
        "City": "West Palm Beach",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 443,
        "AirportCode": "PCT",
        "AirportName": "Princeton Airport",
        "City": "Princeton",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 444,
        "AirportCode": "PDK",
        "AirportName": "Dekalb Peachtree Airport",
        "City": "Atlanta",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 445,
        "AirportCode": "PDP",
        "AirportName": "Capitan Corbeta CA Curbelo International Airport",
        "City": "Interbalnearia",
        "State": "",
        "Country": "Uruguay"
      },
      {
        "Id": 446,
        "AirportCode": "PDX",
        "AirportName": "Portland International Airport",
        "City": "Portland",
        "State": "OR",
        "Country": "United States"
      },
      {
        "Id": 447,
        "AirportCode": "PEE",
        "AirportName": "Bolshoye Savino Airport",
        "City": "N. Cokon",
        "State": "Perm Krai",
        "Country": "Russia"
      },
      {
        "Id": 448,
        "AirportCode": "PEK",
        "AirportName": "Capital International Airport",
        "City": "Beijing",
        "State": "Chaoyang",
        "Country": "China"
      },
      {
        "Id": 449,
        "AirportCode": "PER",
        "AirportName": "Perth International Airport",
        "City": "Redcliffe ",
        "State": "WA",
        "Country": "Australia"
      },
      {
        "Id": 450,
        "AirportCode": "PFO",
        "AirportName": "Paphos International Airport",
        "City": "Paphos",
        "State": "Acheleia",
        "Country": "Cyprus"
      },
      {
        "Id": 451,
        "AirportCode": "PGD",
        "AirportName": "Charlotte County Airport",
        "City": "Punta Gorda",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 452,
        "AirportCode": "PGV",
        "AirportName": "Pitt-Greenville Airport",
        "City": "Greenville",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 453,
        "AirportCode": "PHF",
        "AirportName": "Newport News-Williamsburg International Airport",
        "City": "Newport News",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 454,
        "AirportCode": "PHL",
        "AirportName": "Philadelphia International Airport",
        "City": "Philadelphia",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 455,
        "AirportCode": "PHX",
        "AirportName": "Phoenix Sky Harbor International Airport",
        "City": "Phoenix",
        "State": "AZ",
        "Country": "United States"
      },
      {
        "Id": 456,
        "AirportCode": "PIA",
        "AirportName": "Peoria International Airport",
        "City": "Bartonville",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 457,
        "AirportCode": "PIE",
        "AirportName": "Saint Petersburg-Clearwater International Airport",
        "City": "Clearwater",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 458,
        "AirportCode": "PIL",
        "AirportName": "Port Isabel-Cameron County Airport",
        "City": "Los Fresnos",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 459,
        "AirportCode": "PIT",
        "AirportName": "Pittsburgh International Airport",
        "City": "Coraopolis",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 460,
        "AirportCode": "PLB",
        "AirportName": "Plattsburgh International Airport",
        "City": "Plattsburgh",
        "State": "New York",
        "Country": "United States"
      },
      {
        "Id": 461,
        "AirportCode": "PLU",
        "AirportName": "Pampulha Airport",
        "City": "São Luís",
        "State": "Belo Horizonte - MG",
        "Country": "Brazil"
      },
      {
        "Id": 462,
        "AirportCode": "PMO",
        "AirportName": "Aeroporto di Palermo Falcone e Borsellino",
        "City": "Cinisi",
        "State": "Province of Palermo",
        "Country": "Italy"
      },
      {
        "Id": 463,
        "AirportCode": "PNE",
        "AirportName": "NE Philadelphia Airport-Pne",
        "City": "Philadelphia",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 464,
        "AirportCode": "PNQ",
        "AirportName": "Pune International Airport",
        "City": "Pune",
        "State": "MH",
        "Country": "India"
      },
      {
        "Id": 465,
        "AirportCode": "PNS",
        "AirportName": "Pensacola Gulf Coast Regional Airport",
        "City": "Pensacola",
        "State": "FL",
        "Country": "USA"
      },
      {
        "Id": 466,
        "AirportCode": "POA",
        "AirportName": "Salgado Filho International Airport",
        "City": "Porto Alegre",
        "State": "RS",
        "Country": "Brazil"
      },
      {
        "Id": 467,
        "AirportCode": "POS",
        "AirportName": "Piarco International Airport",
        "City": "",
        "State": "",
        "Country": "Trinidad and Tobago"
      },
      {
        "Id": 468,
        "AirportCode": "POU",
        "AirportName": "Dutchess County Airport",
        "City": "Wappingers Falls",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 469,
        "AirportCode": "PRG",
        "AirportName": "Vaclav Havel Airport Prague",
        "City": "Prague 6-Ruzyn?",
        "State": "",
        "Country": "Czech Republic"
      },
      {
        "Id": 470,
        "AirportCode": "PSA",
        "AirportName": "Pisa International Airport",
        "City": "Pisa",
        "State": "Province of Pisa",
        "Country": "Italy"
      },
      {
        "Id": 471,
        "AirportCode": "PSF",
        "AirportName": "Pittsfield Municipal Airport",
        "City": "Pittsfield",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 472,
        "AirportCode": "PSM",
        "AirportName": "Portsmouth International Airport at Pease",
        "City": "Newington",
        "State": "NH",
        "Country": "United States"
      },
      {
        "Id": 473,
        "AirportCode": "PSP",
        "AirportName": "Palm Springs International Airport",
        "City": "Palm Springs",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 474,
        "AirportCode": "PTK",
        "AirportName": "Oakland County International Airport",
        "City": "Waterford Township",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 475,
        "AirportCode": "PTW",
        "AirportName": "Pottstown Limerick Airport",
        "City": "Pottstown",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 476,
        "AirportCode": "PTY",
        "AirportName": "Tocumen International Airport",
        "City": "",
        "State": "",
        "Country": "Panama"
      },
      {
        "Id": 477,
        "AirportCode": "PVD",
        "AirportName": "Theodore Francis Green Memorial State Airport",
        "City": "Warwick",
        "State": "RI",
        "Country": "United States"
      },
      {
        "Id": 478,
        "AirportCode": "PVG",
        "AirportName": "Pudong International Airport",
        "City": "",
        "State": "",
        "Country": "China"
      },
      {
        "Id": 479,
        "AirportCode": "PVR",
        "AirportName": "Puerto Vallarta International Airport",
        "City": "Puerto Vallarta",
        "State": "Jalisco",
        "Country": "Mexico"
      },
      {
        "Id": 480,
        "AirportCode": "PWK",
        "AirportName": "Chicago Executive Airport",
        "City": "Wheeling",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 481,
        "AirportCode": "PWM",
        "AirportName": "Portland International Jetport",
        "City": "Portland",
        "State": "ME",
        "Country": "United States"
      },
      {
        "Id": 482,
        "AirportCode": "PYM",
        "AirportName": "Plymouth Municipal Airport",
        "City": "Plymouth",
        "State": "MA",
        "Country": "United States"
      },
      {
        "Id": 483,
        "AirportCode": "QRO",
        "AirportName": "Aeropuerto Internacional De Queretaro",
        "City": "Mexico City",
        "State": "DF",
        "Country": "Mexico"
      },
      {
        "Id": 484,
        "AirportCode": "RAK",
        "AirportName": "Marrakech-Menara Airport",
        "City": "Marrakesh",
        "State": "",
        "Country": "Morocco"
      },
      {
        "Id": 485,
        "AirportCode": "RDM",
        "AirportName": "Roberts Field-RDM",
        "City": "Redmond",
        "State": "OR",
        "Country": "United States"
      },
      {
        "Id": 486,
        "AirportCode": "RDU",
        "AirportName": "Raleigh-Durham International Airport",
        "City": "Cedar Fork",
        "State": "NC",
        "Country": "USA"
      },
      {
        "Id": 487,
        "AirportCode": "REC",
        "AirportName": "Guararapes International Airport",
        "City": "Recife",
        "State": "PE",
        "Country": "Brasil"
      },
      {
        "Id": 488,
        "AirportCode": "REP",
        "AirportName": "Siem Reap International Airport",
        "City": "Siem Reap",
        "State": "",
        "Country": "Cambodia"
      },
      {
        "Id": 489,
        "AirportCode": "RIC",
        "AirportName": "Richmond International Airport",
        "City": "Richmond",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 490,
        "AirportCode": "RIX",
        "AirportName": "RIGA International Airport",
        "City": "",
        "State": "",
        "Country": "Latvia"
      },
      {
        "Id": 491,
        "AirportCode": "RMN",
        "AirportName": "Stafford Regional Airport",
        "City": "Fredericksburg",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 492,
        "AirportCode": "RNO",
        "AirportName": "Reno-Tahoe International Airport",
        "City": "Reno",
        "State": "NV",
        "Country": "United States"
      },
      {
        "Id": 493,
        "AirportCode": "ROA",
        "AirportName": "Roanoke Regional Airport",
        "City": "Roanoke",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 494,
        "AirportCode": "ROC",
        "AirportName": "Greater Rochester International Airport",
        "City": "Rochester",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 495,
        "AirportCode": "ROG",
        "AirportName": "Airport: Rogers Muni-carter Field",
        "City": "Rogers",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 496,
        "AirportCode": "RST",
        "AirportName": "Rochester International Airport",
        "City": "Rochester",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 497,
        "AirportCode": "RSW",
        "AirportName": "Southwest Florida International Airport",
        "City": "Fort Myers",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 498,
        "AirportCode": "RTM",
        "AirportName": "Rotterdam The Hague Airport",
        "City": "Rotterdam",
        "State": "",
        "Country": "Netherlands"
      },
      {
        "Id": 499,
        "AirportCode": "RUH",
        "AirportName": "King Khaled International Airport",
        "City": "Bahban ",
        "State": "Riyadh ",
        "Country": "Saudi Arabia\n"
      },
      {
        "Id": 500,
        "AirportCode": "SAF",
        "AirportName": "Santa Fe Muni",
        "City": "Santa Fe",
        "State": "NM",
        "Country": "United States"
      },
      {
        "Id": 501,
        "AirportCode": "SAL",
        "AirportName": "Comalapa International Airport",
        "City": "San Salvador",
        "State": "",
        "Country": "El Salvador"
      },
      {
        "Id": 502,
        "AirportCode": "SAN",
        "AirportName": "San Diego International Airport",
        "City": "San Diego",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 503,
        "AirportCode": "SAT",
        "AirportName": "SAT San Antonio International Airport",
        "City": "San Antonio",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 504,
        "AirportCode": "SAV",
        "AirportName": "Savannah/Hilton Head International Airport",
        "City": "Savannah",
        "State": "GA",
        "Country": "United States"
      },
      {
        "Id": 505,
        "AirportCode": "SAW",
        "AirportName": "Sabiha Gokcen Airport",
        "City": "Istanbul",
        "State": "Istanbul Province",
        "Country": "Turkey"
      },
      {
        "Id": 506,
        "AirportCode": "SBA",
        "AirportName": "Santa Barbara Municipal Airport",
        "City": "Santa Barbara",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 507,
        "AirportCode": "SBM",
        "AirportName": "Sheboygan County Memorial Airport",
        "City": "Sheboygan Falls",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 508,
        "AirportCode": "SBN",
        "AirportName": "South Bend Regional Airport",
        "City": "South Bend",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 509,
        "AirportCode": "SBP",
        "AirportName": "San Luis Obispo County Regional Airport",
        "City": "San Luis Obispo",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 510,
        "AirportCode": "SBY",
        "AirportName": "Salisbury-Ocean City Wicomico Regional Airport",
        "City": "Salisbury",
        "State": "MD",
        "Country": "United States"
      },
      {
        "Id": 511,
        "AirportCode": "SCE",
        "AirportName": "University Park Airport",
        "City": "State College",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 512,
        "AirportCode": "SCF",
        "AirportName": "Scottsdale Municipal Airport",
        "City": "Scottsdale",
        "State": "AZ",
        "Country": "United States"
      },
      {
        "Id": 513,
        "AirportCode": "SCL",
        "AirportName": "Arturo Merino Benitez Airport",
        "City": "Pudahuel, Santiago",
        "State": "",
        "Country": "Chile"
      },
      {
        "Id": 514,
        "AirportCode": "SDF",
        "AirportName": "Louisville International Airport",
        "City": "Louisville",
        "State": "KY",
        "Country": "United States"
      },
      {
        "Id": 515,
        "AirportCode": "SDQ",
        "AirportName": "Las Americas Airport",
        "City": "Punta Caucedo",
        "State": "",
        "Country": "Dominican Republic"
      },
      {
        "Id": 516,
        "AirportCode": "SDU",
        "AirportName": "Santos Dumont Airport",
        "City": "Centro ",
        "State": "Rio de Janeiro - RJ",
        "Country": "Brazil"
      },
      {
        "Id": 517,
        "AirportCode": "SEA",
        "AirportName": "Seattle Tacoma International Airport",
        "City": "Seatac",
        "State": "WA",
        "Country": "United States"
      },
      {
        "Id": 518,
        "AirportCode": "SDX",
        "AirportName": "Sedona Airport",
        "City": "Sedona",
        "State": "AZ",
        "Country": "United States"
      },
      {
        "Id": 519,
        "AirportCode": "SFO",
        "AirportName": "San Francisco International Airport",
        "City": "San Francisco",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 520,
        "AirportCode": "SFV",
        "AirportName": "Santa Fe Do Sul Airport",
        "City": "Santa Fé do Sul",
        "State": "",
        "Country": "Brasil"
      },
      {
        "Id": 521,
        "AirportCode": "SFZ",
        "AirportName": "North Central State Airport",
        "City": "Smithfield",
        "State": "RI",
        "Country": "United States"
      },
      {
        "Id": 522,
        "AirportCode": "SGN",
        "AirportName": "Tan Son Nhat International Airport",
        "City": "Ho Chi Minh City",
        "State": "Tan Binh District",
        "Country": "Vietnam"
      },
      {
        "Id": 523,
        "AirportCode": "SGR",
        "AirportName": "Sugar Land Municipal Airport",
        "City": "Sugar Land",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 524,
        "AirportCode": "SGS",
        "AirportName": "Richard E Fleming Field-KSGS",
        "City": "South St. Paul",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 525,
        "AirportCode": "SHA",
        "AirportName": "Shanghai Hongqiao International Airport",
        "City": "Shanghai",
        "State": "Changning",
        "Country": "China"
      },
      {
        "Id": 526,
        "AirportCode": "SIN",
        "AirportName": "Changi International Airport",
        "City": "",
        "State": "",
        "Country": "Singapore"
      },
      {
        "Id": 527,
        "AirportCode": "SJC",
        "AirportName": "SJC - San Jose International Airport Terminal A",
        "City": "San Jose",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 528,
        "AirportCode": "SJD",
        "AirportName": "Los Cabos Airport",
        "City": "La Palma",
        "State": "Baja California Sur",
        "Country": "Mexico"
      },
      {
        "Id": 529,
        "AirportCode": "SJO",
        "AirportName": "Juan Santamaria International Airport",
        "City": "Alajuela",
        "State": "",
        "Country": "Costa Rica"
      },
      {
        "Id": 530,
        "AirportCode": "SJU",
        "AirportName": "Luis Muñoz Marín International Airport",
        "City": "Carolina",
        "State": "",
        "Country": "Puerto Rico"
      },
      {
        "Id": 531,
        "AirportCode": "SLC",
        "AirportName": "Salt Lake City International Airport",
        "City": "Salt Lake City",
        "State": "UT",
        "Country": "United States"
      },
      {
        "Id": 532,
        "AirportCode": "SLK",
        "AirportName": "Adirondack Regional Airport",
        "City": "Saranac Lake",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 533,
        "AirportCode": "SMF",
        "AirportName": "Sacramento International Airport",
        "City": "Sacramento",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 534,
        "AirportCode": "SMO",
        "AirportName": "Santa Monica Municipal Airport",
        "City": "Santa Monica",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 535,
        "AirportCode": "SMX",
        "AirportName": "Santa Maria Public Airport District",
        "City": "Santa Maria",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 536,
        "AirportCode": "SNA",
        "AirportName": "John Wayne Airport",
        "City": "Santa Ana",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 537,
        "AirportCode": "SNN",
        "AirportName": "Shannon International Airport",
        "City": "Shannon",
        "State": "",
        "Country": "Ireland"
      },
      {
        "Id": 538,
        "AirportCode": "SOF",
        "AirportName": "Letishte Sofia",
        "City": "Sofia",
        "State": "",
        "Country": "Bulgaria"
      },
      {
        "Id": 539,
        "AirportCode": "SOP",
        "AirportName": "Moore County Airport",
        "City": "Pinehurst/Southern Pines",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 540,
        "AirportCode": "SPI",
        "AirportName": "Abraham Lincoln Capital Airport",
        "City": "Springfield",
        "State": "IL",
        "Country": "United States"
      },
      {
        "Id": 541,
        "AirportCode": "SRQ",
        "AirportName": "Sarasota-Bradenton International Airport",
        "City": "Sarasota",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 542,
        "AirportCode": "SSA",
        "AirportName": "Airport Luis E. Magalhaes",
        "City": "São Cristóvão",
        "State": "Salvador - BA",
        "Country": "Brazil"
      },
      {
        "Id": 543,
        "AirportCode": "STL",
        "AirportName": "Lambert St. Louis International Airport",
        "City": "St. Louis",
        "State": "MO",
        "Country": "United States"
      },
      {
        "Id": 544,
        "AirportCode": "STN",
        "AirportName": "Stansted Airport London",
        "City": "Stansted",
        "State": "",
        "Country": "United Kingdom"
      },
      {
        "Id": 545,
        "AirportCode": "STP",
        "AirportName": "Saint Paul Downtown Airport - Holman Field",
        "City": "Saint Paul",
        "State": "MN",
        "Country": "United States"
      },
      {
        "Id": 546,
        "AirportCode": "STR",
        "AirportName": "Flughafen Stuttgart GmbH",
        "City": "Stuttgart",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 547,
        "AirportCode": "STS",
        "AirportName": "Charles M. Schulz - Sonoma County Airport",
        "City": "Santa Rosa",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 548,
        "AirportCode": "STT",
        "AirportName": "Cyril E King Airport",
        "City": "St. Thomas",
        "State": "",
        "Country": "U.S. Virgin Islands"
      },
      {
        "Id": 549,
        "AirportCode": "SUA",
        "AirportName": "Witham Field-Sua",
        "City": "Stuart",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 550,
        "AirportCode": "SUX",
        "AirportName": "Sioux Gateway Airport Colonel Bud Day Field",
        "City": "Sioux City",
        "State": "IA",
        "Country": "United States"
      },
      {
        "Id": 551,
        "AirportCode": "SVO",
        "AirportName": "Sheremetyevo Airport",
        "City": "Moscow",
        "State": "",
        "Country": "Russia"
      },
      {
        "Id": 552,
        "AirportCode": "SVQ",
        "AirportName": "San Pablo Airport",
        "City": "",
        "State": "",
        "Country": "Spain"
      },
      {
        "Id": 553,
        "AirportCode": "SWF",
        "AirportName": "Stewart International Airport",
        "City": "New Windsor",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 554,
        "AirportCode": "SWK",
        "AirportName": "Aeroporto Milano Linate",
        "City": "Segrate",
        "State": "Province of Milan",
        "Country": "Italy"
      },
      {
        "Id": 555,
        "AirportCode": "SXB",
        "AirportName": "Entzheim Airport",
        "City": "Entzheim",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 556,
        "AirportCode": "SXF",
        "AirportName": "Berlin Schönefeld Airport",
        "City": "Schönefeld",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 557,
        "AirportCode": "SYD",
        "AirportName": "Sydney Airport",
        "City": "Mascot NSW",
        "State": "",
        "Country": "Australia"
      },
      {
        "Id": 558,
        "AirportCode": "SYQ",
        "AirportName": "Tobias Bolanos International Airport",
        "City": "Andalucia",
        "State": "San Jose",
        "Country": "Costa Rica"
      },
      {
        "Id": 559,
        "AirportCode": "SYR",
        "AirportName": "Syracuse Hancock International Airport",
        "City": "Syracuse",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 560,
        "AirportCode": "SZG",
        "AirportName": "Salzburg Airport",
        "City": "Salzburg",
        "State": "",
        "Country": "Austria"
      },
      {
        "Id": 561,
        "AirportCode": "SZX",
        "AirportName": "Shenzhen Baoan International Airport",
        "City": "Shenzhen",
        "State": "Guangdong",
        "Country": "China"
      },
      {
        "Id": 562,
        "AirportCode": "TEB",
        "AirportName": "Teterboro Airport",
        "City": "Teterboro",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 563,
        "AirportCode": "TEX",
        "AirportName": "Telluride Regional Airport",
        "City": "Telluride",
        "State": "CO",
        "Country": "United States"
      },
      {
        "Id": 564,
        "AirportCode": "THV",
        "AirportName": "York Airport",
        "City": "York",
        "State": "PA",
        "Country": "United States"
      },
      {
        "Id": 565,
        "AirportCode": "TIV",
        "AirportName": "Tivat Airport",
        "City": "Tivat",
        "State": "",
        "Country": "Montenegro"
      },
      {
        "Id": 566,
        "AirportCode": "TLH",
        "AirportName": "Tallahassee Regional Airport: Sheriff's Hangar",
        "City": "Tallahassee",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 567,
        "AirportCode": "TLL",
        "AirportName": "Lennart Meri Tallinn Airport",
        "City": "Tallinn",
        "State": "",
        "Country": "Eesti"
      },
      {
        "Id": 568,
        "AirportCode": "TLN",
        "AirportName": "Hyeres Airport",
        "City": "Hyeres",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 569,
        "AirportCode": "TLS",
        "AirportName": "Blagnac Airport",
        "City": "Blagnac",
        "State": "",
        "Country": "France"
      },
      {
        "Id": 570,
        "AirportCode": "TLV",
        "AirportName": "Ben Gurion International Airport",
        "City": "Tel Aviv-Yafo",
        "State": "",
        "Country": "Israel"
      },
      {
        "Id": 571,
        "AirportCode": "TMB",
        "AirportName": "KENDALL-TAMIAMI EXECUTIVE GENERAL AIRPORT",
        "City": "Miami",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 572,
        "AirportCode": "TOL",
        "AirportName": "Toledo Express",
        "City": "Swanton",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 573,
        "AirportCode": "TPA",
        "AirportName": "Tampa International Airport",
        "City": "Tampa",
        "State": "FL",
        "Country": "USA"
      },
      {
        "Id": 574,
        "AirportCode": "TPE",
        "AirportName": "Taiwan Taoyuan International Airport ",
        "City": "Taoyuan County",
        "State": "",
        "Country": "Taiwan"
      },
      {
        "Id": 575,
        "AirportCode": "TRN",
        "AirportName": "Turin Airport",
        "City": "Caselle Torinese",
        "State": "Province of Turin",
        "Country": "Italy"
      },
      {
        "Id": 576,
        "AirportCode": "TSA",
        "AirportName": "Taipei Songshan Airport",
        "City": "Taipei City",
        "State": "Songshan District",
        "Country": "Taiwan"
      },
      {
        "Id": 577,
        "AirportCode": "TSN",
        "AirportName": "Binhai International Airport Hotel",
        "City": "Tianjin",
        "State": "",
        "Country": "China"
      },
      {
        "Id": 578,
        "AirportCode": "TTN",
        "AirportName": "Trenton Mercer Airport",
        "City": "Ewing Township",
        "State": "NJ",
        "Country": "United States"
      },
      {
        "Id": 579,
        "AirportCode": "TUL",
        "AirportName": "Tulsa International Airport",
        "City": "Tulsa",
        "State": "OK",
        "Country": "United States"
      },
      {
        "Id": 580,
        "AirportCode": "TUN",
        "AirportName": "Tunis-Carthage International Airport",
        "City": "Tunis",
        "State": "",
        "Country": "Tunisia"
      },
      {
        "Id": 581,
        "AirportCode": "TUS",
        "AirportName": "Tucson International Airport",
        "City": "Tucson",
        "State": "AZ",
        "Country": "United States"
      },
      {
        "Id": 582,
        "AirportCode": "TVC",
        "AirportName": "Cherry Capital Airport",
        "City": "Traverse City",
        "State": "MI",
        "Country": "United States"
      },
      {
        "Id": 583,
        "AirportCode": "TXK",
        "AirportName": "Texarkana Regional Airport",
        "City": "Texarkana",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 584,
        "AirportCode": "TXL",
        "AirportName": "Sixt Airport Berlin Tegel",
        "City": "Berlin",
        "State": "",
        "Country": "Germany"
      },
      {
        "Id": 585,
        "AirportCode": "TYQ",
        "AirportName": "Indianapolis Executive Airport",
        "City": "Zionsville",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 586,
        "AirportCode": "TYR",
        "AirportName": "Tyler Pounds Regional Airport",
        "City": "Tyler",
        "State": "TX",
        "Country": "United States"
      },
      {
        "Id": 587,
        "AirportCode": "TYS",
        "AirportName": "Knoxville McGhee Tyson Airport",
        "City": "Louisville",
        "State": "TN",
        "Country": "United States"
      },
      {
        "Id": 588,
        "AirportCode": "UDD",
        "AirportName": "Bermuda Dunes Airport",
        "City": "Bermuda Dunes",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 589,
        "AirportCode": "UDR",
        "AirportName": "Maharana Pratap Airport",
        "City": "Rajasthan",
        "State": "",
        "Country": "India"
      },
      {
        "Id": 590,
        "AirportCode": "UES",
        "AirportName": "WAUKESHA COUNTY AIRPORT",
        "City": "Waukesha",
        "State": "WI",
        "Country": "United States"
      },
      {
        "Id": 591,
        "AirportCode": "UIO",
        "AirportName": "Mariscal Sucre Airport",
        "City": "Andalucia",
        "State": "Quito Canton",
        "Country": "Ecuador"
      },
      {
        "Id": 592,
        "AirportCode": "ULC",
        "AirportName": "Los Cerrillos Airport",
        "City": "Santiago",
        "State": "",
        "Country": "Chile"
      },
      {
        "Id": 593,
        "AirportCode": "UMP",
        "AirportName": "Indianapolis Metropolitan Airport",
        "City": "Indianapolis",
        "State": "IN",
        "Country": "United States"
      },
      {
        "Id": 594,
        "AirportCode": "UPE",
        "AirportName": "MDO - El Jagüel Airport",
        "City": "Maldonado",
        "State": "",
        "Country": "Uruguay"
      },
      {
        "Id": 595,
        "AirportCode": "UUU",
        "AirportName": "Newport State Airport",
        "City": "Middletown",
        "State": "RI",
        "Country": "United States"
      },
      {
        "Id": 596,
        "AirportCode": "VCE",
        "AirportName": "Courtyard Venice",
        "City": "Tessera",
        "State": "Province of Venice",
        "Country": "Italy"
      },
      {
        "Id": 597,
        "AirportCode": "VGC",
        "AirportName": "Hamilton Municipal Airport",
        "City": "Hamilton",
        "State": "NY",
        "Country": "United States"
      },
      {
        "Id": 598,
        "AirportCode": "VIE",
        "AirportName": "Flughafen Wien - Vienna International Airport",
        "City": "Flughafen ",
        "State": "",
        "Country": "Austria"
      },
      {
        "Id": 599,
        "AirportCode": "VKO",
        "AirportName": "Vnukovo Airport",
        "City": "Moscow",
        "State": "",
        "Country": "Russia"
      },
      {
        "Id": 600,
        "AirportCode": "VLC",
        "AirportName": "Valencia Airport\n",
        "City": "Manises",
        "State": "",
        "Country": "Spain"
      },
      {
        "Id": 601,
        "AirportCode": "VNY",
        "AirportName": "Van Nuys Airport",
        "City": "Van Nuys",
        "State": "CA",
        "Country": "United States"
      },
      {
        "Id": 602,
        "AirportCode": "VPS",
        "AirportName": "Northwest Florida Regional Airport",
        "City": "Eglin Air Force Base",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 603,
        "AirportCode": "VRB",
        "AirportName": "Vero Beach Municipal Airport",
        "City": "Vero Beach",
        "State": "FL",
        "Country": "United States"
      },
      {
        "Id": 604,
        "AirportCode": "WAW",
        "AirportName": "Frederic Chopin Airport",
        "City": "Warsaw",
        "State": "",
        "Country": "Poland"
      },
      {
        "Id": 605,
        "AirportCode": "WLG",
        "AirportName": "Wellington International Airport",
        "City": "Rongotai",
        "State": "Wellington",
        "Country": "New Zealand"
      },
      {
        "Id": 606,
        "AirportCode": "XIY",
        "AirportName": "Xi'an Xianyang International Airport",
        "City": "Xianyang",
        "State": "Shaanxi",
        "Country": "China"
      },
      {
        "Id": 607,
        "AirportCode": "XNA",
        "AirportName": "Northwest Arkansas Regional Airport",
        "City": "Bentonville",
        "State": "AR",
        "Country": "United States"
      },
      {
        "Id": 608,
        "AirportCode": "YEG",
        "AirportName": "Edmonton International Airport",
        "City": "Calmar",
        "State": "AB",
        "Country": "Canada"
      },
      {
        "Id": 609,
        "AirportCode": "YHC",
        "AirportName": "Vancouver Harbour Water Airport",
        "City": "Vancouver",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 610,
        "AirportCode": "YHZ",
        "AirportName": "Halifax Stanfield International Airport",
        "City": "Goffs",
        "State": "NS",
        "Country": "Canada"
      },
      {
        "Id": 611,
        "AirportCode": "YKF",
        "AirportName": "The Region of Waterloo International Airport",
        "City": "Breslau",
        "State": "ON",
        "Country": "Canada"
      },
      {
        "Id": 612,
        "AirportCode": "YLW",
        "AirportName": "Kelowna International Airport",
        "City": "Kelowna",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 613,
        "AirportCode": "YNG",
        "AirportName": "Youngstown-Warren Regional Airport",
        "City": "Vienna",
        "State": "OH",
        "Country": "United States"
      },
      {
        "Id": 614,
        "AirportCode": "YOW",
        "AirportName": "Ottawa International Airport",
        "City": "Ottawa",
        "State": "ON",
        "Country": "CA"
      },
      {
        "Id": 615,
        "AirportCode": "YQB",
        "AirportName": "Québec City Jean Lesage International Airport",
        "City": "Quebec City",
        "State": "QC",
        "Country": "Canada"
      },
      {
        "Id": 616,
        "AirportCode": "YQG",
        "AirportName": "Windsor International Airport",
        "City": "Windsor",
        "State": "ON",
        "Country": "Canada"
      },
      {
        "Id": 617,
        "AirportCode": "YQR",
        "AirportName": "Regina International Airport",
        "City": "Regina",
        "State": "SK",
        "Country": "Canada"
      },
      {
        "Id": 618,
        "AirportCode": "YSJ",
        "AirportName": "Saint John Airport",
        "City": "St John",
        "State": "",
        "Country": "Canada"
      },
      {
        "Id": 619,
        "AirportCode": "YTZ",
        "AirportName": "Billy Bishop Toronto City Airport",
        "City": "Toronto",
        "State": "ON",
        "Country": "Canada"
      },
      {
        "Id": 620,
        "AirportCode": "YUL",
        "AirportName": "Pierre Elliott Trudeau International Airport",
        "City": "Dorval",
        "State": "QC",
        "Country": "Canada"
      },
      {
        "Id": 621,
        "AirportCode": "YVR",
        "AirportName": "Vancouver International Airport",
        "City": "Richmond",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 622,
        "AirportCode": "YWG",
        "AirportName": "Winnipeg James Armstrong Richardson International Airport",
        "City": "Winnipeg",
        "State": "MB",
        "Country": "Canada"
      },
      {
        "Id": 623,
        "AirportCode": "YWH",
        "AirportName": "Victoria Harbour Water Aerodrome",
        "City": "Victoria",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 624,
        "AirportCode": "YXE",
        "AirportName": "John G. Diefenbaker International Airport - Saskatoon",
        "City": "Saskatoon",
        "State": "SK",
        "Country": "Canada"
      },
      {
        "Id": 625,
        "AirportCode": "YXU",
        "AirportName": "London International Airport",
        "City": "London",
        "State": "ON",
        "Country": "Canada"
      },
      {
        "Id": 626,
        "AirportCode": "YYC",
        "AirportName": "Calgary International Airport",
        "City": "Calgary",
        "State": "AB",
        "Country": "Canada"
      },
      {
        "Id": 627,
        "AirportCode": "YYG",
        "AirportName": "Charlottetown Airport",
        "City": "Charlottetown",
        "State": "PE",
        "Country": "Canada"
      },
      {
        "Id": 628,
        "AirportCode": "YYJ",
        "AirportName": "Victoria International Airport",
        "City": "Sidney",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 629,
        "AirportCode": "YYM",
        "AirportName": "Cowley Airport",
        "City": "",
        "State": "",
        "Country": "Canada"
      },
      {
        "Id": 630,
        "AirportCode": "YYZ",
        "AirportName": "Toronto Pearson International Airport",
        "City": "Mississauga",
        "State": "ON",
        "Country": "Canada"
      },
      {
        "Id": 631,
        "AirportCode": "ZRH",
        "AirportName": "Zurich Airport",
        "City": "Kloten",
        "State": "",
        "Country": "Switzerland"
      },
      {
        "Id": 632,
        "AirportCode": "ZWU",
        "AirportName": "Union Station",
        "City": "Washington",
        "State": "VA",
        "Country": "United States"
      },
      {
        "Id": 633,
        "AirportCode": "RBD",
        "AirportName": "Dallas Executive Airport",
        "City": "Dallas",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 634,
        "AirportCode": "KHI",
        "AirportName": "Jinnah International Airport",
        "City": "Karachi",
        "State": "Sind",
        "Country": "PK"
      },
      {
        "Id": 635,
        "AirportCode": "LHE",
        "AirportName": "Allama Iqbal International Airport",
        "City": "Lahore",
        "State": "Punjab",
        "Country": "PK"
      },
      {
        "Id": 636,
        "AirportCode": "SGF",
        "AirportName": "Springfield-Branson National Airport",
        "City": "Springfield",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 637,
        "AirportCode": "HII",
        "AirportName": "Lake Havasu City Municipal Airport",
        "City": "Lake Havasu City",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 638,
        "AirportCode": "BIS",
        "AirportName": "Bismarck Municipal Airport",
        "City": "Bismarck",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 639,
        "AirportCode": "FCA",
        "AirportName": "Glacier Park International Airport",
        "City": "Kalispell",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 640,
        "AirportCode": "MSO",
        "AirportName": "Missoula International Airport",
        "City": "Missoula",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 641,
        "AirportCode": "RAP",
        "AirportName": "Rapid City Regional Airport",
        "City": "Rapid City",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 642,
        "AirportCode": "CWA",
        "AirportName": "Central Wisconsin Airport",
        "City": "Mosinee",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 643,
        "AirportCode": "EDC",
        "AirportName": "Austin Executive Airport",
        "City": "Pflugerville",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 644,
        "AirportCode": "YUM",
        "AirportName": "Yuma International Airport",
        "City": "Yuma",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 645,
        "AirportCode": "BGR",
        "AirportName": "Bangor International Airport",
        "City": "Bangor",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 646,
        "AirportCode": "LWS",
        "AirportName": "Lewiston - Nez Perce County Regional Airport",
        "City": "Lewiston",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 647,
        "AirportCode": "PIB",
        "AirportName": "Hattiesburg-Laurel Regional Airport",
        "City": "Moselle",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 648,
        "AirportCode": "AFW",
        "AirportName": "Alliance Ft. Worth Airport",
        "City": "Fort Worth",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 649,
        "AirportCode": "JAN",
        "AirportName": "Jackson-Medgar Wiley Evers International Airport",
        "City": "Jackson",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 650,
        "AirportCode": "ABY",
        "AirportName": "Southwest Georgia Regional Airport",
        "City": "Albany",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 651,
        "AirportCode": "COU",
        "AirportName": "Columbia Regional Airport",
        "City": "Columbia",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 652,
        "AirportCode": "DEC",
        "AirportName": "Decatur Airport",
        "City": "Decatur",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 653,
        "AirportCode": "OPO",
        "AirportName": "Francisco de Sá Carneiro Airport",
        "City": "Vila Verde",
        "State": "Braga",
        "Country": "PT"
      },
      {
        "Id": 654,
        "AirportCode": "TRI",
        "AirportName": "Tri-Cities Regional Airport",
        "City": "Blountville",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 655,
        "AirportCode": "AZA",
        "AirportName": "Phoenix-Mesa Gateway Airport",
        "City": "Mesa",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 656,
        "AirportCode": "LYH",
        "AirportName": "Lynchburg Regional Airport",
        "City": "Lynchburg",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 657,
        "AirportCode": "BGM",
        "AirportName": "Greater Binghamton Airport",
        "City": "Johnson City",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 658,
        "AirportCode": "KIP",
        "AirportName": "Kickapoo Downtown Airport",
        "City": "Wichita Falls",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 659,
        "AirportCode": "AUG",
        "AirportName": "Augusta State Airport",
        "City": "Augusta",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 660,
        "AirportCode": "GON",
        "AirportName": "Groton New London Airport",
        "City": "Groton",
        "State": "CT",
        "Country": "US"
      },
      {
        "Id": 661,
        "AirportCode": "ABI",
        "AirportName": "Abilene Regional Airport",
        "City": "Abilene",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 662,
        "AirportCode": "GJT",
        "AirportName": "Grand Junction Regional Airport",
        "City": "Grand Junction",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 663,
        "AirportCode": "BQK",
        "AirportName": "Brunswick Golden Isles Airport",
        "City": "Brunswick",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 664,
        "AirportCode": "HRL",
        "AirportName": "Valley International Airport",
        "City": "Harlingen",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 665,
        "AirportCode": "GUC",
        "AirportName": "Gunnison-Crested Butte Regional Airport",
        "City": "Gunnison",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 666,
        "AirportCode": "EKA",
        "AirportName": "Arcata/Eureka Airport",
        "City": "McKinleyville",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 667,
        "AirportCode": "NYP",
        "AirportName": "New York Penn Station",
        "City": "New York",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 668,
        "AirportCode": "BLI",
        "AirportName": "Bellingham International Airport",
        "City": "Bellingham",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 669,
        "AirportCode": "BBY",
        "AirportName": "Branson Airport",
        "City": "Hollister",
        "State": "MO",
        "Country": "United States"
      },
      {
        "Id": 670,
        "AirportCode": "STE",
        "AirportName": "Stevens Point Municipal Airport",
        "City": "Stevens Point",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 671,
        "AirportCode": "ACT",
        "AirportName": "Waco Regional Airport",
        "City": "Waco",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 672,
        "AirportCode": "SHV",
        "AirportName": "Shreveport Regional Airport",
        "City": "Shreveport",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 673,
        "AirportCode": "BMI",
        "AirportName": "Central Illinois Regional Airport",
        "City": "Bloomington",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 674,
        "AirportCode": "CPR",
        "AirportName": "Casper–Natrona County International Airport",
        "City": "Casper",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 675,
        "AirportCode": "RDD",
        "AirportName": "Redding Municipal Airport",
        "City": "Redding",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 676,
        "AirportCode": "SXM",
        "AirportName": "Princess Juliana International Airport",
        "City": "Simpson Bay",
        "State": "Sint Maarten",
        "Country": "SX"
      },
      {
        "Id": 677,
        "AirportCode": "MYV",
        "AirportName": "Yuba County Airport",
        "City": "Olivehurst",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 678,
        "AirportCode": "GYR",
        "AirportName": "Phoenix Goodyear Airport GYR",
        "City": "Goodyear",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 679,
        "AirportCode": "DHN",
        "AirportName": "Dothan Regional Airport",
        "City": "Midland City",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 680,
        "AirportCode": "FAI",
        "AirportName": "Fairbanks Intl Airport",
        "City": "Fairbanks",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 681,
        "AirportCode": "BJI",
        "AirportName": "Bemidji Regional Airport",
        "City": "Bemidji",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 682,
        "AirportCode": "PMI",
        "AirportName": "Palma de Mallorca Airport",
        "City": "Palma",
        "State": "SP",
        "Country": "Spain"
      },
      {
        "Id": 683,
        "AirportCode": "ADL",
        "AirportName": "Adelaide Airport",
        "City": "Adelaide",
        "State": "SA",
        "Country": "AU"
      },
      {
        "Id": 684,
        "AirportCode": "LBG",
        "AirportName": "Paris-Le Bourget Airport",
        "City": "Le Bourget",
        "State": "LB",
        "Country": "France"
      },
      {
        "Id": 685,
        "AirportCode": "ORY",
        "AirportName": "Paris Orly Airport",
        "City": "Orly",
        "State": "Orly",
        "Country": "France"
      },
      {
        "Id": 686,
        "AirportCode": "CDG",
        "AirportName": "Charles de Gaulle Airport",
        "City": "Paris",
        "State": "ROISSY",
        "Country": "France"
      },
      {
        "Id": 687,
        "AirportCode": "BVA",
        "AirportName": "Beauvais?Tillé Airport",
        "City": "l'aéroport",
        "State": "Tillé",
        "Country": "France"
      },
      {
        "Id": 688,
        "AirportCode": "CDG",
        "AirportName": "Aéroports De Paris",
        "City": "Paris",
        "State": "Paris",
        "Country": "France"
      },
      {
        "Id": 691,
        "AirportCode": "RAO",
        "AirportName": "Dr. Leite Lopes Ribeirão Preto State Airport",
        "City": "Ribeirão Preto",
        "State": "São Paulo",
        "Country": "Brazil"
      },
      {
        "Id": 692,
        "AirportCode": "LNK",
        "AirportName": "Lincoln Airport",
        "City": "Lincoln",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 693,
        "AirportCode": "MFE",
        "AirportName": "McAllen International Airport",
        "City": "McAllen",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 694,
        "AirportCode": "GFK",
        "AirportName": "GFK Grand Forks International Airport",
        "City": "Grand Forks",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 695,
        "AirportCode": "VAR",
        "AirportName": "Airport - Varna",
        "City": "Varna",
        "State": "Varna",
        "Country": "BG"
      },
      {
        "Id": 696,
        "AirportCode": "PUJ",
        "AirportName": "Punta Cana International Airport",
        "City": "Punta Cana",
        "State": "La Altagracia",
        "Country": "Dominican Republic"
      },
      {
        "Id": 697,
        "AirportCode": "CSL",
        "AirportName": "Cabo San Lucas International Airport",
        "City": "Cabo San Lucas",
        "State": "B.C.S.",
        "Country": "MX"
      },
      {
        "Id": 698,
        "AirportCode": "OBO",
        "AirportName": "Tokachi Obihiro Airport",
        "City": "Obihiro",
        "State": "Hokkaido",
        "Country": "JP"
      },
      {
        "Id": 699,
        "AirportCode": "VCP",
        "AirportName": "Viracopos International Airport",
        "City": "Parque Viracopos",
        "State": "SP",
        "Country": "BR"
      },
      {
        "Id": 700,
        "AirportCode": "GVR",
        "AirportName": "Aeroporto de Governador Valadares",
        "City": "Distrito Industrial",
        "State": "MG",
        "Country": "BR"
      },
      {
        "Id": 701,
        "AirportCode": "NAT",
        "AirportName": "Aeroporto Internacional Governador Aluízio Alves",
        "City": "São Gonçalo do Amarante",
        "State": "RN",
        "Country": "BR"
      },
      {
        "Id": 702,
        "AirportCode": "TOF",
        "AirportName": "Tomsk Bogashevo Airport",
        "City": "Tomsk",
        "State": "Tomsk Oblast",
        "Country": "RU"
      },
      {
        "Id": 703,
        "AirportCode": "ABA",
        "AirportName": "Abakan International Airport",
        "City": "Abakan",
        "State": "Khakasiya Republits",
        "Country": "Russia"
      },
      {
        "Id": 704,
        "AirportCode": "SEZ",
        "AirportName": "Seychelles",
        "City": "Victoria",
        "State": "Seychelles",
        "Country": "SC"
      },
      {
        "Id": 705,
        "AirportCode": "MQT",
        "AirportName": "Sawyer International Airport / MQT",
        "City": "Gwinn",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 706,
        "AirportCode": "AGP",
        "AirportName": "Malaga airport - Costa del Sol",
        "City": "Málaga",
        "State": "AL",
        "Country": "Spain"
      },
      {
        "Id": 707,
        "AirportCode": "LPA",
        "AirportName": "Aeropuerto de Gran Canaria",
        "City": "Las Palmas",
        "State": "CN",
        "Country": "Spain"
      },
      {
        "Id": 708,
        "AirportCode": "IFO",
        "AirportName": "Ivano-Frankivsk International Airport",
        "City": "Ivano-Frankivsk",
        "State": "Ivano-Frankivsk Oblast",
        "Country": "Ukraine"
      },
      {
        "Id": 709,
        "AirportCode": "LWO",
        "AirportName": "Lviv Danylo Halytskyi International Airport",
        "City": "Lviv",
        "State": "L'vivs'ka oblast",
        "Country": "Ukraine"
      },
      {
        "Id": 710,
        "AirportCode": "UME",
        "AirportName": "Umeå Airport",
        "City": "Umeå",
        "State": "Umeå",
        "Country": "Sweden"
      },
      {
        "Id": 711,
        "AirportCode": "SFT",
        "AirportName": "Skellefteå Airport",
        "City": "Skellefteå",
        "State": "Västerbotten County",
        "Country": "SE"
      },
      {
        "Id": 712,
        "AirportCode": "DIA",
        "AirportName": "Doha International Airport",
        "City": "Doha",
        "State": "Doha",
        "Country": "QA"
      },
      {
        "Id": 713,
        "AirportCode": "PLZ",
        "AirportName": "Port Elizabeth International",
        "City": "Port Elizabeth",
        "State": "EC",
        "Country": "South Africa"
      },
      {
        "Id": 714,
        "AirportCode": "TIJ",
        "AirportName": "General Abelardo L. Rodriguez International Airport",
        "City": "Tijuana",
        "State": "",
        "Country": "Mexico"
      },
      {
        "Id": 715,
        "AirportCode": "RDG",
        "AirportName": "Reading Regional Airport",
        "City": "Reading",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 716,
        "AirportCode": "YKM",
        "AirportName": "Yakima Airport - McAllister Field",
        "City": "Yakima",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 717,
        "AirportCode": "KEJ",
        "AirportName": "Kemerovo Airport",
        "City": "Kemerovo",
        "State": "Kemerovo Oblast",
        "Country": "RU"
      },
      {
        "Id": 718,
        "AirportCode": "OMS",
        "AirportName": "Omskiy Aeroport",
        "City": "Omsk",
        "State": "Omsk Oblast",
        "Country": "RU"
      },
      {
        "Id": 719,
        "AirportCode": "EWN",
        "AirportName": "Costal Carolina Regional Airport",
        "City": "New Bern",
        "State": "NC",
        "Country": "United States"
      },
      {
        "Id": 720,
        "AirportCode": "SLE",
        "AirportName": "McNary Field",
        "City": "Salem",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 721,
        "AirportCode": "DSM",
        "AirportName": "Des Moines International Airport",
        "City": "Des Moines",
        "State": " IA",
        "Country": "United States"
      },
      {
        "Id": 722,
        "AirportCode": "LBF",
        "AirportName": "North Platte Airport",
        "City": "North Platte",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 723,
        "AirportCode": "EAS",
        "AirportName": "San Sebastian Airport",
        "City": "Gipuzkoa",
        "State": "PV",
        "Country": "Spain"
      },
      {
        "Id": 724,
        "AirportCode": "FLG",
        "AirportName": "Flagstaff Pulliam Airport",
        "City": "Flagstaff",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 725,
        "AirportCode": "DDC",
        "AirportName": "Dodge City Regional Airport",
        "City": "Dodge City",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 726,
        "AirportCode": "OVD",
        "AirportName": "Asturias Airport",
        "City": "Asturias",
        "State": "Asturias",
        "Country": "Spain"
      },
      {
        "Id": 727,
        "AirportCode": "SCQ",
        "AirportName": "Santiago de Compostela Airport",
        "City": "Santiago de Compostela",
        "State": "GA",
        "Country": "Spain"
      },
      {
        "Id": 728,
        "AirportCode": "XRY",
        "AirportName": "Jerez Airport",
        "City": "Jerez de la Frontera",
        "State": "Cádiz",
        "Country": "Spain"
      },
      {
        "Id": 729,
        "AirportCode": "POZ",
        "AirportName": "Poznan Airport",
        "City": "Poznań",
        "State": "wielkopolskie",
        "Country": "PL"
      },
      {
        "Id": 730,
        "AirportCode": "WRO",
        "AirportName": "Wroclaw Nicolaus Copernicus Airport",
        "City": "Wrocław",
        "State": "Województwo dolnośląskie",
        "Country": "PL"
      },
      {
        "Id": 731,
        "AirportCode": "SZZ",
        "AirportName": "Solidarity Szczecin-Goleniów Airport",
        "City": "Goleniów",
        "State": "Województwo zachodniopomorskie",
        "Country": "PL"
      },
      {
        "Id": 732,
        "AirportCode": "RZE",
        "AirportName": "Rzeszow-Jasionka Airport",
        "City": "Jasionka",
        "State": "Województwo podkarpackie",
        "Country": "PL"
      },
      {
        "Id": 733,
        "AirportCode": "GDN",
        "AirportName": "Gdansk Lech Walesa Airport",
        "City": "Gdańsk",
        "State": "pomorskie",
        "Country": "PL"
      },
      {
        "Id": 734,
        "AirportCode": "ESB",
        "AirportName": "Ankara Esenboga Airport",
        "City": "Ankara",
        "State": "Ankara",
        "Country": "TR"
      },
      {
        "Id": 735,
        "AirportCode": "RBR",
        "AirportName": "Rio Branco International Airport",
        "City": "Rio Branco",
        "State": "AC",
        "Country": "BR"
      },
      {
        "Id": 736,
        "AirportCode": "ANF",
        "AirportName": "Aeropuerto Andrés Sabella",
        "City": "Antofagasta",
        "State": "Antofagasta",
        "Country": "CL"
      },
      {
        "Id": 737,
        "AirportCode": "ARI",
        "AirportName": "Aeropuerto Internacional Chacalluta",
        "City": "Arica",
        "State": "Arica y Parinacota",
        "Country": "CL"
      },
      {
        "Id": 738,
        "AirportCode": "CJC",
        "AirportName": "Airport El Loa Calama (CJC)",
        "City": "Calama",
        "State": "Antofagasta",
        "Country": "CL"
      },
      {
        "Id": 739,
        "AirportCode": "CPO",
        "AirportName": "Desierto de Atacama",
        "City": "Copiapo",
        "State": "Antofagasta",
        "Country": "CL"
      },
      {
        "Id": 740,
        "AirportCode": "IPC",
        "AirportName": "Mataveri Airport",
        "City": "Isla de Pascua",
        "State": "Valparaíso",
        "Country": "CL"
      },
      {
        "Id": 741,
        "AirportCode": "LSC",
        "AirportName": "La Florida International Airport",
        "City": "La Serena",
        "State": "Coquimbo",
        "Country": "CL"
      },
      {
        "Id": 742,
        "AirportCode": "PMC",
        "AirportName": "El Tepual Aeropuerto",
        "City": "Puerto Montt",
        "State": "Los Lagos",
        "Country": "CL"
      },
      {
        "Id": 743,
        "AirportCode": "PUQ",
        "AirportName": "Presidente Carlos Ibáñez del Campo International Airport",
        "City": "Punta Arenas",
        "State": "Magallanes y la Antártica Chilena",
        "Country": "CL"
      },
      {
        "Id": 744,
        "AirportCode": "ZCO",
        "AirportName": "Araucanía Airport (Temuco)",
        "City": "Freire",
        "State": "Araucanía",
        "Country": "CL"
      },
      {
        "Id": 745,
        "AirportCode": "TBS",
        "AirportName": "Tbilisi International Airport",
        "City": "Isani-Samgori",
        "State": "Tbilisi",
        "Country": "GE"
      },
      {
        "Id": 746,
        "AirportCode": "GRQ",
        "AirportName": "Groningen Airport Eelde",
        "City": "Eelde",
        "State": "GR",
        "Country": "NL"
      },
      {
        "Id": 747,
        "AirportCode": "IEV",
        "AirportName": "Kyiv International Airport (Zhuliany)",
        "City": "Kyiv",
        "State": "Ukraine",
        "Country": "UA"
      },
      {
        "Id": 748,
        "AirportCode": "BTI",
        "AirportName": "Barter Island LRRS Airport",
        "City": "Barter Island",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 749,
        "AirportCode": "LUR",
        "AirportName": "Cape Lisburne LRRS Airport",
        "City": "Cape Lisburne",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 750,
        "AirportCode": "PIZ",
        "AirportName": "Point Lay LRRS Airport",
        "City": "Point Lay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 751,
        "AirportCode": "ITO",
        "AirportName": "Hilo International Airport",
        "City": "Hilo",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 752,
        "AirportCode": "BTT",
        "AirportName": "Bettles Airport",
        "City": "Bettles",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 753,
        "AirportCode": "Z84",
        "AirportName": "Clear Airport",
        "City": "Clear Mews",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 754,
        "AirportCode": "UTO",
        "AirportName": "Indian Mountain LRRS Airport",
        "City": "Indian Mountains",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 755,
        "AirportCode": "FYU",
        "AirportName": "Fort Yukon Airport",
        "City": "Fort Yukon",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 756,
        "AirportCode": "SVW",
        "AirportName": "Sparrevohn LRRS Airport",
        "City": "Sparrevohn",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 757,
        "AirportCode": "FRN",
        "AirportName": "Bryant Army Heliport",
        "City": "Fort Richardson",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 758,
        "AirportCode": "TLJ",
        "AirportName": "Tatalina LRRS Airport",
        "City": "Tatalina",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 759,
        "AirportCode": "CZF",
        "AirportName": "Cape Romanzof LRRS Airport",
        "City": "Cape Romanzof",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 760,
        "AirportCode": "SNP",
        "AirportName": "St Paul Island Airport",
        "City": "St. Paul Island",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 761,
        "AirportCode": "EHM",
        "AirportName": "Cape Newenham LRRS Airport",
        "City": "Cape Newenham",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 762,
        "AirportCode": "STG",
        "AirportName": "St George Airport",
        "City": "Point Barrow",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 763,
        "AirportCode": "ILI",
        "AirportName": "Iliamna Airport",
        "City": "Iliamna",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 764,
        "AirportCode": "PTU",
        "AirportName": "Platinum Airport",
        "City": "Port Moller",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 765,
        "AirportCode": "BMX",
        "AirportName": "Big Mountain Airport",
        "City": "Big Mountain",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 766,
        "AirportCode": "OSC",
        "AirportName": "Oscoda Wurtsmith Airport",
        "City": "Oscoda",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 767,
        "AirportCode": "OAR",
        "AirportName": "Marina Municipal Airport",
        "City": "Fort Ord",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 768,
        "AirportCode": "MHR",
        "AirportName": "Sacramento Mather Airport",
        "City": "Sacramento",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 769,
        "AirportCode": "BYS",
        "AirportName": "Bicycle Lake Army Air Field",
        "City": "Fort Irwin",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 770,
        "AirportCode": "NXP",
        "AirportName": "Twentynine Palms (Self) Airport",
        "City": "Twenty Nine Palms",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 771,
        "AirportCode": "FSM",
        "AirportName": "Fort Smith Regional Airport",
        "City": "Fort Smith",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 772,
        "AirportCode": "MRI",
        "AirportName": "Merrill Field",
        "City": "Anchorage",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 773,
        "AirportCode": "GNT",
        "AirportName": "Grants-Milan Municipal Airport",
        "City": "Grants",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 774,
        "AirportCode": "PNC",
        "AirportName": "Ponca City Regional Airport",
        "City": "Ponca City",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 775,
        "AirportCode": "SVN",
        "AirportName": "Hunter Army Air Field",
        "City": "Hunter Aaf",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 776,
        "AirportCode": "PBF",
        "AirportName": "Grider Field",
        "City": "Pine Bluff",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 777,
        "AirportCode": "NSE",
        "AirportName": "Whiting Field Naval Air Station - North",
        "City": "Milton",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 778,
        "AirportCode": "HNM",
        "AirportName": "Hana Airport",
        "City": "Hana",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 779,
        "AirportCode": "PRC",
        "AirportName": "Ernest A. Love Field",
        "City": "Prescott",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 780,
        "AirportCode": "SUU",
        "AirportName": "Travis Air Force Base",
        "City": "Fairfield",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 781,
        "AirportCode": "RME",
        "AirportName": "Griffiss International Airport",
        "City": "Rome",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 782,
        "AirportCode": "ENV",
        "AirportName": "Wendover Airport",
        "City": "Wendover",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 783,
        "AirportCode": "NOW",
        "AirportName": "Port Angeles Cgas Airport",
        "City": "Port Angeles",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 784,
        "AirportCode": "DLG",
        "AirportName": "Dillingham Airport",
        "City": "Dillingham",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 785,
        "AirportCode": "HRO",
        "AirportName": "Boone County Airport",
        "City": "Harrison",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 786,
        "AirportCode": "GGG",
        "AirportName": "East Texas Regional Airport",
        "City": "Longview",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 787,
        "AirportCode": "AND",
        "AirportName": "Anderson Regional Airport",
        "City": "Andersen",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 788,
        "AirportCode": "HWO",
        "AirportName": "North Perry Airport",
        "City": "Hollywood",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 789,
        "AirportCode": "CTB",
        "AirportName": "Cut Bank International Airport",
        "City": "Cutbank",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 790,
        "AirportCode": "ARA",
        "AirportName": "Acadiana Regional Airport",
        "City": "Louisiana",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 791,
        "AirportCode": "DUG",
        "AirportName": "Bisbee Douglas International Airport",
        "City": "Douglas",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 792,
        "AirportCode": "BIG",
        "AirportName": "Allen Army Airfield",
        "City": "Delta Junction",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 793,
        "AirportCode": "CNW",
        "AirportName": "TSTC Waco Airport",
        "City": "Waco",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 794,
        "AirportCode": "ANN",
        "AirportName": "Annette Island Airport",
        "City": "Annette Island",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 795,
        "AirportCode": "CAR",
        "AirportName": "Caribou Municipal Airport",
        "City": "Caribou",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 796,
        "AirportCode": "LRF",
        "AirportName": "Little Rock Air Force Base",
        "City": "Jacksonville",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 797,
        "AirportCode": "HUA",
        "AirportName": "Redstone Army Air Field",
        "City": "Redstone",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 798,
        "AirportCode": "POB",
        "AirportName": "Pope Field",
        "City": "Fort Bragg",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 799,
        "AirportCode": "DHT",
        "AirportName": "Dalhart Municipal Airport",
        "City": "Dalhart",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 800,
        "AirportCode": "DLF",
        "AirportName": "Laughlin Air Force Base",
        "City": "Del Rio",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 801,
        "AirportCode": "ANB",
        "AirportName": "Anniston Metropolitan Airport",
        "City": "Anniston",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 802,
        "AirportCode": "DOV",
        "AirportName": "Dover Air Force Base",
        "City": "Dover",
        "State": "DE",
        "Country": "US"
      },
      {
        "Id": 803,
        "AirportCode": "FME",
        "AirportName": "Tipton Airport",
        "City": "Fort Meade",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 804,
        "AirportCode": "NID",
        "AirportName": "China Lake Naws (Armitage Field) Airport",
        "City": "China Lake",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 805,
        "AirportCode": "HON",
        "AirportName": "Huron Regional Airport",
        "City": "Huron",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 806,
        "AirportCode": "JNU",
        "AirportName": "Juneau International Airport",
        "City": "Juneau",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 807,
        "AirportCode": "INS",
        "AirportName": "Creech Air Force Base",
        "City": "Indian Springs",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 808,
        "AirportCode": "GCK",
        "AirportName": "Garden City Regional Airport",
        "City": "Garden City",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 809,
        "AirportCode": "MOT",
        "AirportName": "Minot International Airport",
        "City": "Minot",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 810,
        "AirportCode": "HHI",
        "AirportName": "Wheeler Army Airfield",
        "City": "Wahiawa",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 811,
        "AirportCode": "MXF",
        "AirportName": "Maxwell Air Force Base",
        "City": "Montgomery",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 813,
        "AirportCode": "FCS",
        "AirportName": "Butts AAF (Fort Carson) Air Field",
        "City": "Fort Carson",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 814,
        "AirportCode": "NKX",
        "AirportName": "Miramar Marine Corps Air Station - Mitscher Field",
        "City": "Miramar",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 815,
        "AirportCode": "LUF",
        "AirportName": "Luke Air Force Base",
        "City": "Phoenix",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 817,
        "AirportCode": "HUL",
        "AirportName": "Houlton International Airport",
        "City": "Houlton",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 818,
        "AirportCode": "END",
        "AirportName": "Vance Air Force Base",
        "City": "Enid",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 819,
        "AirportCode": "NTD",
        "AirportName": "Point Mugu Naval Air Station (Naval Base Ventura Co)",
        "City": "Point Mugu",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 820,
        "AirportCode": "EDW",
        "AirportName": "Edwards Air Force Base",
        "City": "Edwards Afb",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 821,
        "AirportCode": "NLC",
        "AirportName": "Lemoore Naval Air Station (Reeves Field) Airport",
        "City": "Lemoore",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 822,
        "AirportCode": "FAF",
        "AirportName": "Felker Army Air Field",
        "City": "Fort Eustis",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 823,
        "AirportCode": "HOP",
        "AirportName": "Campbell AAF (Fort Campbell) Air Field",
        "City": "Hopkinsville",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 824,
        "AirportCode": "NHK",
        "AirportName": "Patuxent River Naval Air Station/Trapnell Field Aiport",
        "City": "Patuxent River",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 825,
        "AirportCode": "PSX",
        "AirportName": "Palacios Municipal Airport",
        "City": "Palacios",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 826,
        "AirportCode": "BYH",
        "AirportName": "Arkansas International Airport",
        "City": "Blytheville",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 827,
        "AirportCode": "TIK",
        "AirportName": "Tinker Air Force Base",
        "City": "Oklahoma City",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 828,
        "AirportCode": "PUB",
        "AirportName": "Pueblo Memorial Airport",
        "City": "Pueblo",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 829,
        "AirportCode": "PQI",
        "AirportName": "Northern Maine Regional Airport at Presque Isle",
        "City": "Presque Isle",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 830,
        "AirportCode": "GRF",
        "AirportName": "Gray Army Air Field",
        "City": "Fort Lewis",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 831,
        "AirportCode": "ADQ",
        "AirportName": "Kodiak Airport",
        "City": "Kodiak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 832,
        "AirportCode": "UPP",
        "AirportName": "Upolu Airport",
        "City": "Opolu",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 833,
        "AirportCode": "MKO",
        "AirportName": "Davis Field",
        "City": "Muskogee",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 834,
        "AirportCode": "INL",
        "AirportName": "Falls International Airport",
        "City": "International Falls",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 835,
        "AirportCode": "CDS",
        "AirportName": "Childress Municipal Airport",
        "City": "Childress",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 836,
        "AirportCode": "BIX",
        "AirportName": "Keesler Air Force Base",
        "City": "Biloxi",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 837,
        "AirportCode": "LSF",
        "AirportName": "Lawson Army Air Field (Fort Benning)",
        "City": "Fort Benning",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 838,
        "AirportCode": "FRI",
        "AirportName": "Marshall Army Air Field",
        "City": "Fort Riley",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 839,
        "AirportCode": "MUE",
        "AirportName": "Waimea Kohala Airport",
        "City": "Kamuela",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 840,
        "AirportCode": "MSS",
        "AirportName": "Massena International Richards Field",
        "City": "Massena",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 841,
        "AirportCode": "HKY",
        "AirportName": "Hickory Regional Airport",
        "City": "Hickory",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 842,
        "AirportCode": "SPG",
        "AirportName": "Albert Whitted Airport",
        "City": "St. Petersburg",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 843,
        "AirportCode": "MLT",
        "AirportName": "Millinocket Municipal Airport",
        "City": "Millinocket",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 844,
        "AirportCode": "ADW",
        "AirportName": "Andrews Air Force Base",
        "City": "Camp Springs",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 845,
        "AirportCode": "VCV",
        "AirportName": "Southern California Logistics Airport",
        "City": "Victorville",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 846,
        "AirportCode": "CEW",
        "AirportName": "Bob Sikes Airport",
        "City": "Crestview",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 847,
        "AirportCode": "GTB",
        "AirportName": "Wheeler Sack Army Air Field",
        "City": "Fort Drum",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 848,
        "AirportCode": "PHN",
        "AirportName": "St Clair County International Airport",
        "City": "Port Huron",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 849,
        "AirportCode": "DMA",
        "AirportName": "Davis Monthan Air Force Base",
        "City": "Tucson",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 850,
        "AirportCode": "NPA",
        "AirportName": "Pensacola Naval Air Station/Forrest Sherman Field",
        "City": "Pensacola",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 851,
        "AirportCode": "RDR",
        "AirportName": "Grand Forks Air Force Base",
        "City": "Red River",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 852,
        "AirportCode": "BKF",
        "AirportName": "Buckley Air Force Base",
        "City": "Buckley",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 853,
        "AirportCode": "ORT",
        "AirportName": "Northway Airport",
        "City": "Northway",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 854,
        "AirportCode": "PAQ",
        "AirportName": "Palmer Municipal Airport",
        "City": "Palmer",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 855,
        "AirportCode": "BRW",
        "AirportName": "Wiley Post Will Rogers Memorial Airport",
        "City": "Barrow",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 856,
        "AirportCode": "NUW",
        "AirportName": "Whidbey Island Naval Air Station /Ault Field/ Airport",
        "City": "Whidbey Island",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 857,
        "AirportCode": "ALI",
        "AirportName": "Alice International Airport",
        "City": "Alice",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 858,
        "AirportCode": "VAD",
        "AirportName": "Moody Air Force Base",
        "City": "Valdosta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 859,
        "AirportCode": "GLS",
        "AirportName": "Scholes International At Galveston Airport",
        "City": "Galveston",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 860,
        "AirportCode": "IPT",
        "AirportName": "Williamsport Regional Airport",
        "City": "Williamsport",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 861,
        "AirportCode": "SZL",
        "AirportName": "Whiteman Air Force Base",
        "City": "Knobnoster",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 862,
        "AirportCode": "GWO",
        "AirportName": "Greenwood–Leflore Airport",
        "City": "Greenwood",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 863,
        "AirportCode": "JBR",
        "AirportName": "Jonesboro Municipal Airport",
        "City": "Jonesboro",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 864,
        "AirportCode": "LNA",
        "AirportName": "Palm Beach County Park Airport",
        "City": "West Palm Beach",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 865,
        "AirportCode": "NZY",
        "AirportName": "North Island Naval Air Station-Halsey Field",
        "City": "San Diego",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 866,
        "AirportCode": "BIF",
        "AirportName": "Biggs Army Air Field (Fort Bliss)",
        "City": "El Paso",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 867,
        "AirportCode": "CNM",
        "AirportName": "Cavern City Air Terminal",
        "City": "Carlsbad",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 868,
        "AirportCode": "BET",
        "AirportName": "Bethel Airport",
        "City": "Bethel",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 869,
        "AirportCode": "LOU",
        "AirportName": "Bowman Field",
        "City": "Louisville",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 870,
        "AirportCode": "FHU",
        "AirportName": "Sierra Vista Municipal Libby Army Air Field",
        "City": "Fort Huachuca",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 871,
        "AirportCode": "HUF",
        "AirportName": "Terre Haute International Hulman Field",
        "City": "Terre Haute",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 872,
        "AirportCode": "HVR",
        "AirportName": "Havre City County Airport",
        "City": "Havre",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 873,
        "AirportCode": "MWH",
        "AirportName": "Grant County International Airport",
        "City": "Grant County Airport",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 874,
        "AirportCode": "MPV",
        "AirportName": "Edward F Knapp State Airport",
        "City": "Montpelier",
        "State": "VT",
        "Country": "US"
      },
      {
        "Id": 875,
        "AirportCode": "CDV",
        "AirportName": "Merle K (Mudhole) Smith Airport",
        "City": "Cordova",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 876,
        "AirportCode": "BPT",
        "AirportName": "Southeast Texas Regional Airport",
        "City": "Beaumont",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 877,
        "AirportCode": "HIF",
        "AirportName": "Hill Air Force Base",
        "City": "Ogden",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 878,
        "AirportCode": "OME",
        "AirportName": "Nome Airport",
        "City": "Nome",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 879,
        "AirportCode": "MNM",
        "AirportName": "Menominee Marinette Twin County Airport",
        "City": "Macon",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 880,
        "AirportCode": "CXO",
        "AirportName": "Lone Star Executive Airport",
        "City": "Conroe",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 881,
        "AirportCode": "SCC",
        "AirportName": "Deadhorse Airport",
        "City": "Deadhorse",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 882,
        "AirportCode": "COF",
        "AirportName": "Patrick Air Force Base",
        "City": "Coco Beach",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 883,
        "AirportCode": "RCA",
        "AirportName": "Ellsworth Air Force Base",
        "City": "Rapid City",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 884,
        "AirportCode": "ENA",
        "AirportName": "Kenai Municipal Airport",
        "City": "Kenai",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 885,
        "AirportCode": "MLC",
        "AirportName": "Mc Alester Regional Airport",
        "City": "Mcalester",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 886,
        "AirportCode": "IAG",
        "AirportName": "Niagara Falls International Airport",
        "City": "Niagara Falls",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 887,
        "AirportCode": "CFD",
        "AirportName": "Coulter Field",
        "City": "Bryan",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 888,
        "AirportCode": "ESF",
        "AirportName": "Esler Regional Airport",
        "City": "Alexandria",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 889,
        "AirportCode": "LTS",
        "AirportName": "Altus Air Force Base",
        "City": "Altus",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 890,
        "AirportCode": "MIB",
        "AirportName": "Minot Air Force Base",
        "City": "Minot",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 891,
        "AirportCode": "BAB",
        "AirportName": "Beale Air Force Base",
        "City": "Marysville",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 892,
        "AirportCode": "GSB",
        "AirportName": "Seymour Johnson Air Force Base",
        "City": "Goldsboro",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 893,
        "AirportCode": "RIU",
        "AirportName": "Rancho Murieta Airport",
        "City": "Rancho Murieta",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 894,
        "AirportCode": "PMB",
        "AirportName": "Pembina Municipal Airport",
        "City": "Pembina",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 895,
        "AirportCode": "POE",
        "AirportName": "Polk Army Air Field",
        "City": "Fort Polk",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 896,
        "AirportCode": "EIL",
        "AirportName": "Eielson Air Force Base",
        "City": "Fairbanks",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 897,
        "AirportCode": "HIB",
        "AirportName": "Range Regional Airport",
        "City": "Hibbing",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 898,
        "AirportCode": "LFK",
        "AirportName": "Angelina County Airport",
        "City": "Lufkin",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 899,
        "AirportCode": "ADM",
        "AirportName": "Ardmore Municipal Airport",
        "City": "Ardmore",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 900,
        "AirportCode": "WRI",
        "AirportName": "Mc Guire Air Force Base",
        "City": "Wrightstown",
        "State": "NJ",
        "Country": "US"
      },
      {
        "Id": 901,
        "AirportCode": "SBO",
        "AirportName": "Emanuel County Airport",
        "City": "Santa Barbara",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 902,
        "AirportCode": "ISN",
        "AirportName": "Sloulin Field International Airport",
        "City": "Williston",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 903,
        "AirportCode": "BDE",
        "AirportName": "Baudette International Airport",
        "City": "Baudette",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 904,
        "AirportCode": "SAC",
        "AirportName": "Sacramento Executive Airport",
        "City": "Sacramento",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 905,
        "AirportCode": "HOM",
        "AirportName": "Homer Airport",
        "City": "Homer",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 906,
        "AirportCode": "TBN",
        "AirportName": "Waynesville-St. Robert Regional Forney field",
        "City": "Fort Leonardwood",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 907,
        "AirportCode": "MGE",
        "AirportName": "Dobbins Air Reserve Base",
        "City": "Marietta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 908,
        "AirportCode": "SKA",
        "AirportName": "Fairchild Air Force Base",
        "City": "Spokane",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 909,
        "AirportCode": "HTL",
        "AirportName": "Roscommon County - Blodgett Memorial Airport",
        "City": "Houghton Lake",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 910,
        "AirportCode": "PAM",
        "AirportName": "Tyndall Air Force Base",
        "City": "Panama City",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 911,
        "AirportCode": "TCM",
        "AirportName": "McChord Air Force Base",
        "City": "Tacoma",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 912,
        "AirportCode": "HLR",
        "AirportName": "Hood Army Air Field",
        "City": "Fort Hood",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 913,
        "AirportCode": "MIV",
        "AirportName": "Millville Municipal Airport",
        "City": "Millville",
        "State": "NJ",
        "Country": "US"
      },
      {
        "Id": 914,
        "AirportCode": "SPS",
        "AirportName": "Sheppard Air Force Base-Wichita Falls Municipal Airport",
        "City": "Wichita Falls",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 915,
        "AirportCode": "MCC",
        "AirportName": "Mc Clellan Airfield",
        "City": "Sacramento",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 916,
        "AirportCode": "INK",
        "AirportName": "Winkler County Airport",
        "City": "Wink",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 917,
        "AirportCode": "IPL",
        "AirportName": "Imperial County Airport",
        "City": "Imperial",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 918,
        "AirportCode": "LRD",
        "AirportName": "Laredo International Airport",
        "City": "Laredo",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 919,
        "AirportCode": "EDF",
        "AirportName": "Elmendorf Air Force Base",
        "City": "Anchorage",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 920,
        "AirportCode": "OTZ",
        "AirportName": "Ralph Wien Memorial Airport",
        "City": "Kotzebue",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 921,
        "AirportCode": "AOO",
        "AirportName": "Altoona Blair County Airport",
        "City": "Altoona",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 922,
        "AirportCode": "DYS",
        "AirportName": "Dyess Air Force Base",
        "City": "Abilene",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 923,
        "AirportCode": "ELD",
        "AirportName": "South Arkansas Regional At Goodwin Field",
        "City": "El Dorado",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 924,
        "AirportCode": "INJ",
        "AirportName": "Hillsboro Municipal Airport",
        "City": "Hillsboro",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 925,
        "AirportCode": "MKL",
        "AirportName": "Mc Kellar Sipes Regional Airport",
        "City": "Jackson",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 926,
        "AirportCode": "MKK",
        "AirportName": "Molokai Airport",
        "City": "Molokai",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 927,
        "AirportCode": "FTK",
        "AirportName": "Godman Army Air Field",
        "City": "Fort Knox",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 928,
        "AirportCode": "SJT",
        "AirportName": "San Angelo Regional Mathis Field",
        "City": "San Angelo",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 929,
        "AirportCode": "CXL",
        "AirportName": "Calexico International Airport",
        "City": "Calexico",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 930,
        "AirportCode": "CIC",
        "AirportName": "Chico Municipal Airport",
        "City": "Chico",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 931,
        "AirportCode": "CLL",
        "AirportName": "Easterwood Field",
        "City": "College Station",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 932,
        "AirportCode": "SFF",
        "AirportName": "Felts Field",
        "City": "Spokane",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 933,
        "AirportCode": "TNT",
        "AirportName": "Dade Collier Training and Transition Airport",
        "City": "Miami",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 934,
        "AirportCode": "OGS",
        "AirportName": "Ogdensburg International Airport",
        "City": "Ogdensburg",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 935,
        "AirportCode": "SKF",
        "AirportName": "Lackland Air Force Base",
        "City": "San Antonio",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 936,
        "AirportCode": "SSC",
        "AirportName": "Shaw Air Force Base",
        "City": "Sumter",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 937,
        "AirportCode": "GVT",
        "AirportName": "Majors Airport",
        "City": "Greenvile",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 938,
        "AirportCode": "ROW",
        "AirportName": "Roswell International Air Center Airport",
        "City": "Roswell",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 939,
        "AirportCode": "BRO",
        "AirportName": "Brownsville South Padre Island International Airport",
        "City": "Brownsville",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 940,
        "AirportCode": "WWD",
        "AirportName": "Cape May County Airport",
        "City": "Wildwood",
        "State": "NJ",
        "Country": "US"
      },
      {
        "Id": 941,
        "AirportCode": "MTC",
        "AirportName": "Selfridge Angb Airport",
        "City": "Mount Clemens",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 942,
        "AirportCode": "FMN",
        "AirportName": "Four Corners Regional Airport",
        "City": "Farmington",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 943,
        "AirportCode": "NQX",
        "AirportName": "Naval Air Station Key West/Boca Chica Field",
        "City": "Key West",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 944,
        "AirportCode": "HOB",
        "AirportName": "Lea County Regional Airport",
        "City": "Hobbs",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 945,
        "AirportCode": "MCN",
        "AirportName": "Middle Georgia Regional Airport",
        "City": "Macon",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 946,
        "AirportCode": "TCS",
        "AirportName": "Truth Or Consequences Municipal Airport",
        "City": "Truth Or Consequences",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 947,
        "AirportCode": "PMD",
        "AirportName": "Palmdale Regional/USAF Plant 42 Airport",
        "City": "Palmdale",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 948,
        "AirportCode": "RND",
        "AirportName": "Randolph Air Force Base",
        "City": "San Antonio",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 949,
        "AirportCode": "NJK",
        "AirportName": "El Centro Naf Airport",
        "City": "El Centro",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 950,
        "AirportCode": "FYV",
        "AirportName": "Drake Field",
        "City": "Fayetteville",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 951,
        "AirportCode": "FSI",
        "AirportName": "Henry Post Army Air Field (Fort Sill)",
        "City": "Fort Sill",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 952,
        "AirportCode": "PNM",
        "AirportName": "Princeton Municipal Airport",
        "City": "Princeton",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 953,
        "AirportCode": "FFO",
        "AirportName": "Wright-Patterson Air Force Base",
        "City": "Dayton",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 954,
        "AirportCode": "GAL",
        "AirportName": "Edward G. Pitka Sr Airport",
        "City": "Galena",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 955,
        "AirportCode": "MWL",
        "AirportName": "Mineral Wells Airport",
        "City": "Mineral Wells",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 956,
        "AirportCode": "IAB",
        "AirportName": "Mc Connell Air Force Base",
        "City": "Wichita",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 957,
        "AirportCode": "NBG",
        "AirportName": "New Orleans NAS JRB/Alvin Callender Field",
        "City": "New Orleans",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 958,
        "AirportCode": "BFT",
        "AirportName": "Beaufort County Airport",
        "City": "Beaufort",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 959,
        "AirportCode": "PBG",
        "AirportName": "Plattsburgh International Airport",
        "City": "Plattsburgh",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 960,
        "AirportCode": "APG",
        "AirportName": "Phillips Army Air Field",
        "City": "Aberdeen",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 961,
        "AirportCode": "TCC",
        "AirportName": "Tucumcari Municipal Airport",
        "City": "Tucumcari",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 962,
        "AirportCode": "ZUN",
        "AirportName": "Black Rock Airport",
        "City": "Zuni Pueblo",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 963,
        "AirportCode": "NQA",
        "AirportName": "Millington Regional Jetport Airport",
        "City": "Millington",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 964,
        "AirportCode": "EKN",
        "AirportName": "Elkins-Randolph Co-Jennings Randolph Field",
        "City": "Elkins",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 965,
        "AirportCode": "NUQ",
        "AirportName": "Moffett Federal Airfield",
        "City": "Mountain View",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 966,
        "AirportCode": "BKH",
        "AirportName": "Barking Sands Airport",
        "City": "Barking Sands",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 967,
        "AirportCode": "DRI",
        "AirportName": "Beauregard Regional Airport",
        "City": "Deridder",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 968,
        "AirportCode": "BSF",
        "AirportName": "Bradshaw Army Airfield",
        "City": "Bradshaw Field",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 969,
        "AirportCode": "OLS",
        "AirportName": "Nogales International Airport",
        "City": "Nogales",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 970,
        "AirportCode": "MCF",
        "AirportName": "Mac Dill Air Force Base",
        "City": "Tampa",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 971,
        "AirportCode": "BLV",
        "AirportName": "Scott AFB/Midamerica Airport",
        "City": "Belleville",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 972,
        "AirportCode": "DRT",
        "AirportName": "Del Rio International Airport",
        "City": "Del Rio",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 973,
        "AirportCode": "AKN",
        "AirportName": "King Salmon Airport",
        "City": "King Salmon",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 974,
        "AirportCode": "MUI",
        "AirportName": "Muir Army Air Field (Fort Indiantown Gap) Airport",
        "City": "Muir",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 975,
        "AirportCode": "JHM",
        "AirportName": "Kapalua Airport",
        "City": "Lahania-kapalua",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 976,
        "AirportCode": "HST",
        "AirportName": "Homestead ARB Airport",
        "City": "Homestead",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 977,
        "AirportCode": "RAL",
        "AirportName": "Riverside Municipal Airport",
        "City": "Riverside",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 978,
        "AirportCode": "FLV",
        "AirportName": "Sherman Army Air Field",
        "City": "Fort Leavenworth",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 979,
        "AirportCode": "WAL",
        "AirportName": "Wallops Flight Facility Airport",
        "City": "Wallops Island",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 980,
        "AirportCode": "HMN",
        "AirportName": "Holloman Air Force Base",
        "City": "Alamogordo",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 981,
        "AirportCode": "NXX",
        "AirportName": "Willow Grove Naval Air Station/Joint Reserve Base",
        "City": "Willow Grove",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 982,
        "AirportCode": "CYS",
        "AirportName": "Cheyenne Regional Jerry Olson Field",
        "City": "Cheyenne",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 983,
        "AirportCode": "SCK",
        "AirportName": "Stockton Metropolitan Airport",
        "City": "Stockton",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 984,
        "AirportCode": "KTN",
        "AirportName": "Ketchikan International Airport",
        "City": "Ketchikan",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 985,
        "AirportCode": "YIP",
        "AirportName": "Willow Run Airport",
        "City": "Detroit",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 986,
        "AirportCode": "VBG",
        "AirportName": "Vandenberg Air Force Base",
        "City": "Lompoc",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 987,
        "AirportCode": "NEL",
        "AirportName": "Lakehurst Maxfield Field Airport",
        "City": "Lakehurst",
        "State": "NJ",
        "Country": "US"
      },
      {
        "Id": 988,
        "AirportCode": "LSV",
        "AirportName": "Nellis Air Force Base",
        "City": "Las Vegas",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 989,
        "AirportCode": "RIV",
        "AirportName": "March ARB Airport",
        "City": "Riverside",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 990,
        "AirportCode": "UGN",
        "AirportName": "Waukegan National Airport",
        "City": "Chicago",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 991,
        "AirportCode": "SKY",
        "AirportName": "Griffing Sandusky Airport",
        "City": "Sandusky",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 992,
        "AirportCode": "PAE",
        "AirportName": "Snohomish County (Paine Field) Airport",
        "City": "Everett",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 993,
        "AirportCode": "MUO",
        "AirportName": "Mountain Home Air Force Base",
        "City": "Mountain Home",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 994,
        "AirportCode": "CDC",
        "AirportName": "Cedar City Regional Airport",
        "City": "Cedar City",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 995,
        "AirportCode": "NGU",
        "AirportName": "Norfolk Ns (Chambers Fld) Airport",
        "City": "Norfolk",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 996,
        "AirportCode": "NYG",
        "AirportName": "Quantico MCAF /Turner field",
        "City": "Quantico",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 997,
        "AirportCode": "CVS",
        "AirportName": "Cannon Air Force Base",
        "City": "Clovis",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 998,
        "AirportCode": "NGF",
        "AirportName": "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
        "City": "Kaneohe Bay",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 999,
        "AirportCode": "OFF",
        "AirportName": "Offutt Air Force Base",
        "City": "Omaha",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1000,
        "AirportCode": "GKN",
        "AirportName": "Gulkana Airport",
        "City": "Gulkana",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1001,
        "AirportCode": "ART",
        "AirportName": "Watertown International Airport",
        "City": "Watertown",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1002,
        "AirportCode": "FOD",
        "AirportName": "Fort Dodge Regional Airport",
        "City": "Fort Dodge",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1003,
        "AirportCode": "BAD",
        "AirportName": "Barksdale Air Force Base",
        "City": "Shreveport",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 1004,
        "AirportCode": "FOE",
        "AirportName": "Topeka Regional Airport - Forbes Field",
        "City": "Topeka",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1005,
        "AirportCode": "COT",
        "AirportName": "Cotulla-La Salle County Airport",
        "City": "Cotulla",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1006,
        "AirportCode": "HBR",
        "AirportName": "Hobart Regional Airport",
        "City": "Hobart",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1007,
        "AirportCode": "LNY",
        "AirportName": "Lanai Airport",
        "City": "Lanai",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 1008,
        "AirportCode": "AEX",
        "AirportName": "Alexandria International Airport",
        "City": "Alexandria",
        "State": "LA",
        "Country": "US"
      },
      {
        "Id": 1009,
        "AirportCode": "WSD",
        "AirportName": "Condron Army Air Field",
        "City": "White Sands",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1010,
        "AirportCode": "CDB",
        "AirportName": "Cold Bay Airport",
        "City": "Cold Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1011,
        "AirportCode": "SIT",
        "AirportName": "Sitka Rocky Gutierrez Airport",
        "City": "Sitka",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1012,
        "AirportCode": "DUT",
        "AirportName": "Unalaska Airport",
        "City": "Unalaska",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1013,
        "AirportCode": "VDZ",
        "AirportName": "Valdez Pioneer Field",
        "City": "Valdez",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1014,
        "AirportCode": "LFI",
        "AirportName": "Langley Air Force Base",
        "City": "Hampton",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1015,
        "AirportCode": "CBM",
        "AirportName": "Columbus Air Force Base",
        "City": "Colombus",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1016,
        "AirportCode": "NTU",
        "AirportName": "Oceana NAS",
        "City": "Oceana",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1017,
        "AirportCode": "GUS",
        "AirportName": "Grissom Air Reserve Base",
        "City": "Peru",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1018,
        "AirportCode": "SEM",
        "AirportName": "Craig Field",
        "City": "Selma",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1019,
        "AirportCode": "EYW",
        "AirportName": "Key West International Airport",
        "City": "Key West",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1020,
        "AirportCode": "FLO",
        "AirportName": "Florence Regional Airport",
        "City": "Florence",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1021,
        "AirportCode": "FBK",
        "AirportName": "Ladd AAF Airfield",
        "City": "Fort Wainwright",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1022,
        "AirportCode": "MMV",
        "AirportName": "Mc Minnville Municipal Airport",
        "City": "Mackminnville",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1023,
        "AirportCode": "WRB",
        "AirportName": "Robins Air Force Base",
        "City": "Macon",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1024,
        "AirportCode": "PUW",
        "AirportName": "Pullman Moscow Regional Airport",
        "City": "Pullman",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1025,
        "AirportCode": "TKA",
        "AirportName": "Talkeetna Airport",
        "City": "Talkeetna",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1026,
        "AirportCode": "BTM",
        "AirportName": "Bert Mooney Airport",
        "City": "Butte",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1027,
        "AirportCode": "RKD",
        "AirportName": "Knox County Regional Airport",
        "City": "Rockland",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 1028,
        "AirportCode": "RFD",
        "AirportName": "Chicago Rockford International Airport",
        "City": "Rockford",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1029,
        "AirportCode": "AZO",
        "AirportName": "Kalamazoo Battle Creek International Airport",
        "City": "Kalamazoo",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1030,
        "AirportCode": "LSE",
        "AirportName": "La Crosse Municipal Airport",
        "City": "La Crosse",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1031,
        "AirportCode": "MHK",
        "AirportName": "Manhattan Regional Airport",
        "City": "Manhattan",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1032,
        "AirportCode": "FKL",
        "AirportName": "Venango Regional Airport",
        "City": "Franklin",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1033,
        "AirportCode": "SGU",
        "AirportName": "St George Municipal Airport",
        "City": "Saint George",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1034,
        "AirportCode": "DWH",
        "AirportName": "David Wayne Hooks Memorial Airport",
        "City": "Houston",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1035,
        "AirportCode": "S46",
        "AirportName": "Port O'Connor Private Airport",
        "City": "Port O\\\\'Connor",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1036,
        "AirportCode": "PFN",
        "AirportName": "Panama City-Bay Co International Airport",
        "City": "Panama City",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1037,
        "AirportCode": "IDA",
        "AirportName": "Idaho Falls Regional Airport",
        "City": "Idaho Falls",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1038,
        "AirportCode": "MFR",
        "AirportName": "Rogue Valley International Medford Airport",
        "City": "Medford",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1039,
        "AirportCode": "PKB",
        "AirportName": "Mid Ohio Valley Regional Airport",
        "City": "PARKERSBURG",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1040,
        "AirportCode": "PAH",
        "AirportName": "Barkley Regional Airport",
        "City": "PADUCAH",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1041,
        "AirportCode": "PGA",
        "AirportName": "Page Municipal Airport",
        "City": "Page",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1042,
        "AirportCode": "BLH",
        "AirportName": "Blythe Airport",
        "City": "Blythe",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1043,
        "AirportCode": "PSG",
        "AirportName": "Petersburg James A Johnson Airport",
        "City": "Petersburg",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1044,
        "AirportCode": "SFB",
        "AirportName": "Orlando Sanford International Airport",
        "City": "Sanford",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1045,
        "AirportCode": "JST",
        "AirportName": "John Murtha Johnstown Cambria County Airport",
        "City": "Johnstown",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1046,
        "AirportCode": "GCN",
        "AirportName": "Grand Canyon National Park Airport",
        "City": "Grand Canyon",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1047,
        "AirportCode": "CVN",
        "AirportName": "Clovis Municipal Airport",
        "City": "Clovis",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1048,
        "AirportCode": "FST",
        "AirportName": "Fort Stockton Pecos County Airport",
        "City": "Fort Stockton",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1049,
        "AirportCode": "LVS",
        "AirportName": "Las Vegas Municipal Airport",
        "City": "Las Vegas",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1050,
        "AirportCode": "IWS",
        "AirportName": "West Houston Airport",
        "City": "Houston",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1051,
        "AirportCode": "LHX",
        "AirportName": "La Junta Municipal Airport",
        "City": "La Junta",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1052,
        "AirportCode": "LRU",
        "AirportName": "Las Cruces International Airport",
        "City": "Las Cruces",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1053,
        "AirportCode": "BKD",
        "AirportName": "Stephens County Airport",
        "City": "Breckenridge",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1054,
        "AirportCode": "TPL",
        "AirportName": "Draughon Miller Central Texas Regional Airport",
        "City": "Temple",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1055,
        "AirportCode": "OZA",
        "AirportName": "Ozona Municipal Airport",
        "City": "Ozona",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1056,
        "AirportCode": "CGF",
        "AirportName": "Cuyahoga County Airport",
        "City": "Richmond Heights",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1057,
        "AirportCode": "MFD",
        "AirportName": "Mansfield Lahm Regional Airport",
        "City": "Mansfield",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1058,
        "AirportCode": "LAW",
        "AirportName": "Lawton Fort Sill Regional Airport",
        "City": "Lawton",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1059,
        "AirportCode": "FNL",
        "AirportName": "Fort Collins Loveland Municipal Airport",
        "City": "Fort Collins",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1060,
        "AirportCode": "TVL",
        "AirportName": "Lake Tahoe Airport",
        "City": "South Lake Tahoe",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1061,
        "AirportCode": "TWF",
        "AirportName": "Joslin Field Magic Valley Regional Airport",
        "City": "Twin Falls",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1062,
        "AirportCode": "CON",
        "AirportName": "Concord Municipal Airport",
        "City": "Concord NH",
        "State": "NH",
        "Country": "US"
      },
      {
        "Id": 1063,
        "AirportCode": "STC",
        "AirportName": "St Cloud Regional Airport",
        "City": "Saint Cloud",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1064,
        "AirportCode": "GTR",
        "AirportName": "Golden Triangle Regional Airport",
        "City": "Columbus Mississippi",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1065,
        "AirportCode": "HQM",
        "AirportName": "Bowerman Airport",
        "City": "Hoquiam",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1066,
        "AirportCode": "MGW",
        "AirportName": "Morgantown Municipal Walter L. Bill Hart Field",
        "City": "Morgantown",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1067,
        "AirportCode": "GCC",
        "AirportName": "Gillette Campbell County Airport",
        "City": "Gillette",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1068,
        "AirportCode": "MEI",
        "AirportName": "Key Field",
        "City": "Meridian",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1069,
        "AirportCode": "CEZ",
        "AirportName": "Cortez Municipal Airport",
        "City": "Cortez",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1070,
        "AirportCode": "HDN",
        "AirportName": "Yampa Valley Airport",
        "City": "Hayden",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1071,
        "AirportCode": "GUP",
        "AirportName": "Gallup Municipal Airport",
        "City": "Gallup",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1072,
        "AirportCode": "LBL",
        "AirportName": "Liberal Mid-America Regional Airport",
        "City": "Liberal",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1073,
        "AirportCode": "LAA",
        "AirportName": "Lamar Municipal Airport",
        "City": "Lamar",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1074,
        "AirportCode": "GLD",
        "AirportName": "Renner Field-Goodland Municipal Airport",
        "City": "Goodland",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1075,
        "AirportCode": "COD",
        "AirportName": "Yellowstone Regional Airport",
        "City": "Cody",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1076,
        "AirportCode": "JLN",
        "AirportName": "Joplin Regional Airport",
        "City": "Joplin",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1077,
        "AirportCode": "SMD",
        "AirportName": "Smith Field",
        "City": "Fort Wayne IN",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1078,
        "AirportCode": "ACV",
        "AirportName": "Arcata Airport",
        "City": "Arcata CA",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1079,
        "AirportCode": "OAJ",
        "AirportName": "Albert J Ellis Airport",
        "City": "Jacksonville NC",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1080,
        "AirportCode": "TCL",
        "AirportName": "Tuscaloosa Regional Airport",
        "City": "Tuscaloosa AL",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1081,
        "AirportCode": "ABR",
        "AirportName": "Aberdeen Regional Airport",
        "City": "Aberdeen",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1082,
        "AirportCode": "AHN",
        "AirportName": "Athens Ben Epps Airport",
        "City": "Athens",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1083,
        "AirportCode": "ALM",
        "AirportName": "Alamogordo White Sands Regional Airport",
        "City": "Alamogordo",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1084,
        "AirportCode": "APN",
        "AirportName": "Alpena County Regional Airport",
        "City": "Alpena",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1085,
        "AirportCode": "ATY",
        "AirportName": "Watertown Regional Airport",
        "City": "Watertown",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1086,
        "AirportCode": "BFD",
        "AirportName": "Bradford Regional Airport",
        "City": "Bradford",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1087,
        "AirportCode": "BFF",
        "AirportName": "Western Neb. Rgnl/William B. Heilig Airport",
        "City": "Scottsbluff",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1088,
        "AirportCode": "BKW",
        "AirportName": "Raleigh County Memorial Airport",
        "City": "Beckley",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1089,
        "AirportCode": "BRL",
        "AirportName": "Southeast Iowa Regional Airport",
        "City": "Burlington",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1090,
        "AirportCode": "CEC",
        "AirportName": "Jack Mc Namara Field Airport",
        "City": "Crescent City",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1091,
        "AirportCode": "CGI",
        "AirportName": "Cape Girardeau Regional Airport",
        "City": "Cape Girardeau",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1092,
        "AirportCode": "CIU",
        "AirportName": "Chippewa County International Airport",
        "City": "Sault Ste Marie",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1093,
        "AirportCode": "CKB",
        "AirportName": "North Central West Virginia Airport",
        "City": "Clarksburg",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1094,
        "AirportCode": "CLM",
        "AirportName": "William R Fairchild International Airport",
        "City": "Port Angeles",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1095,
        "AirportCode": "CMX",
        "AirportName": "Houghton County Memorial Airport",
        "City": "Hancock",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1096,
        "AirportCode": "DUJ",
        "AirportName": "DuBois Regional Airport",
        "City": "Du Bois",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1097,
        "AirportCode": "EAU",
        "AirportName": "Chippewa Valley Regional Airport",
        "City": "Eau Claire",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1098,
        "AirportCode": "EKO",
        "AirportName": "Elko Regional Airport",
        "City": "Elko",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1099,
        "AirportCode": "FAY",
        "AirportName": "Fayetteville Regional Grannis Field",
        "City": "Fayetteville",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1100,
        "AirportCode": "GGW",
        "AirportName": "Wokal Field Glasgow International Airport",
        "City": "Glasgow",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1101,
        "AirportCode": "GRI",
        "AirportName": "Central Nebraska Regional Airport",
        "City": "Grand Island",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1102,
        "AirportCode": "IRK",
        "AirportName": "Kirksville Regional Airport",
        "City": "Kirksville",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1103,
        "AirportCode": "JMS",
        "AirportName": "Jamestown Regional Airport",
        "City": "Jamestown",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 1104,
        "AirportCode": "LAR",
        "AirportName": "Laramie Regional Airport",
        "City": "Laramie",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1105,
        "AirportCode": "LBE",
        "AirportName": "Arnold Palmer Regional Airport",
        "City": "Latrobe",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1106,
        "AirportCode": "LMT",
        "AirportName": "Klamath Falls Airport",
        "City": "Klamath Falls",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1107,
        "AirportCode": "LWT",
        "AirportName": "Lewistown Municipal Airport",
        "City": "Lewistown",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1108,
        "AirportCode": "MLS",
        "AirportName": "Frank Wiley Field",
        "City": "Miles City",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1109,
        "AirportCode": "MSL",
        "AirportName": "Northwest Alabama Regional Airport",
        "City": "Muscle Shoals",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1110,
        "AirportCode": "OTH",
        "AirportName": "Southwest Oregon Regional Airport",
        "City": "North Bend",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1111,
        "AirportCode": "OWB",
        "AirportName": "Owensboro Daviess County Airport",
        "City": "Owensboro",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1112,
        "AirportCode": "PIH",
        "AirportName": "Pocatello Regional Airport",
        "City": "Pocatello",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1113,
        "AirportCode": "PIR",
        "AirportName": "Pierre Regional Airport",
        "City": "Pierre",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1114,
        "AirportCode": "PLN",
        "AirportName": "Pellston Regional Airport of Emmet County Airport",
        "City": "Pellston",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1115,
        "AirportCode": "RHI",
        "AirportName": "Rhinelander Oneida County Airport",
        "City": "Rhinelander",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1116,
        "AirportCode": "RKS",
        "AirportName": "Rock Springs Sweetwater County Airport",
        "City": "Rock Springs",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1117,
        "AirportCode": "RUT",
        "AirportName": "Rutland - Southern Vermont Regional Airport",
        "City": "Rutland",
        "State": "VT",
        "Country": "US"
      },
      {
        "Id": 1118,
        "AirportCode": "SHR",
        "AirportName": "Sheridan County Airport",
        "City": "Sheridan",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1119,
        "AirportCode": "SLN",
        "AirportName": "Salina Municipal Airport",
        "City": "Salina",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1120,
        "AirportCode": "TUP",
        "AirportName": "Tupelo Regional Airport",
        "City": "Tupelo",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1121,
        "AirportCode": "UIN",
        "AirportName": "Quincy Regional Baldwin Field",
        "City": "Quincy",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1122,
        "AirportCode": "VCT",
        "AirportName": "Victoria Regional Airport",
        "City": "Victoria",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1123,
        "AirportCode": "VLD",
        "AirportName": "Valdosta Regional Airport",
        "City": "Valdosta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1124,
        "AirportCode": "WRL",
        "AirportName": "Worland Municipal Airport",
        "City": "Worland",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1125,
        "AirportCode": "ADK",
        "AirportName": "Adak Airport",
        "City": "Adak Island",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1126,
        "AirportCode": "GST",
        "AirportName": "Gustavus Airport",
        "City": "Gustavus",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1127,
        "AirportCode": "SGY",
        "AirportName": "Skagway Airport",
        "City": "Skagway",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1128,
        "AirportCode": "HCR",
        "AirportName": "Holy Cross Airport",
        "City": "Holy Cross",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1129,
        "AirportCode": "HNS",
        "AirportName": "Haines Airport",
        "City": "Haines",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1130,
        "AirportCode": "KLG",
        "AirportName": "Kalskag Airport",
        "City": "Kalskag",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1131,
        "AirportCode": "MCG",
        "AirportName": "McGrath Airport",
        "City": "Mcgrath",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1132,
        "AirportCode": "MOU",
        "AirportName": "Mountain Village Airport",
        "City": "Mountain Village",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1133,
        "AirportCode": "ANI",
        "AirportName": "Aniak Airport",
        "City": "Aniak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1134,
        "AirportCode": "VAK",
        "AirportName": "Chevak Airport",
        "City": "Chevak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1135,
        "AirportCode": "WRG",
        "AirportName": "Wrangell Airport",
        "City": "Wrangell",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1136,
        "AirportCode": "LUP",
        "AirportName": "Kalaupapa Airport",
        "City": "Molokai",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 1137,
        "AirportCode": "WKK",
        "AirportName": "Aleknagik / New Airport",
        "City": "Aleknagik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1138,
        "AirportCode": "BLF",
        "AirportName": "Mercer County Airport",
        "City": "Bluefield",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1139,
        "AirportCode": "GLH",
        "AirportName": "Mid Delta Regional Airport",
        "City": "Greenville",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1140,
        "AirportCode": "PSC",
        "AirportName": "Tri Cities Airport",
        "City": "Pasco",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1141,
        "AirportCode": "KQA",
        "AirportName": "Akutan Seaplane Base",
        "City": "Akutan",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1142,
        "AirportCode": "LPS",
        "AirportName": "Lopez Island Airport",
        "City": "Lopez",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1143,
        "AirportCode": "WKL",
        "AirportName": "Waikoloa Heliport",
        "City": "Waikoloa Village",
        "State": "HI",
        "Country": "US"
      },
      {
        "Id": 1144,
        "AirportCode": "PWT",
        "AirportName": "Bremerton National Airport",
        "City": "Bremerton",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1145,
        "AirportCode": "SPW",
        "AirportName": "Spencer Municipal Airport",
        "City": "Spencer",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1146,
        "AirportCode": "JEF",
        "AirportName": "Jefferson City Memorial Airport",
        "City": "Jefferson City",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1147,
        "AirportCode": "PVC",
        "AirportName": "Provincetown Municipal Airport",
        "City": "Provincetown",
        "State": "MA",
        "Country": "US"
      },
      {
        "Id": 1148,
        "AirportCode": "FUL",
        "AirportName": "Fullerton Municipal Airport",
        "City": "Fullerton",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1149,
        "AirportCode": "SUN",
        "AirportName": "Friedman Memorial Airport",
        "City": "Hailey",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1150,
        "AirportCode": "MCW",
        "AirportName": "Mason City Municipal Airport",
        "City": "Mason City",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1151,
        "AirportCode": "AKP",
        "AirportName": "Anaktuvuk Pass Airport",
        "City": "Anaktuvuk Pass",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1152,
        "AirportCode": "ANV",
        "AirportName": "Anvik Airport",
        "City": "Anvik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1153,
        "AirportCode": "ATK",
        "AirportName": "Atqasuk Edward Burnell Sr Memorial Airport",
        "City": "Atqasuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1154,
        "AirportCode": "GAM",
        "AirportName": "Gambell Airport",
        "City": "Gambell",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1155,
        "AirportCode": "HPB",
        "AirportName": "Hooper Bay Airport",
        "City": "Hooper Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1156,
        "AirportCode": "KAL",
        "AirportName": "Kaltag Airport",
        "City": "Kaltag",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1157,
        "AirportCode": "KSM",
        "AirportName": "St Mary's Airport",
        "City": "St Mary's",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1158,
        "AirportCode": "KVL",
        "AirportName": "Kivalina Airport",
        "City": "Kivalina",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1159,
        "AirportCode": "MYU",
        "AirportName": "Mekoryuk Airport",
        "City": "Mekoryuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1160,
        "AirportCode": "RBY",
        "AirportName": "Ruby Airport",
        "City": "Ruby",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1161,
        "AirportCode": "SHH",
        "AirportName": "Shishmaref Airport",
        "City": "Shishmaref",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1162,
        "AirportCode": "SVA",
        "AirportName": "Savoonga Airport",
        "City": "Savoonga",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1163,
        "AirportCode": "WTK",
        "AirportName": "Noatak Airport",
        "City": "Noatak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1164,
        "AirportCode": "ARC",
        "AirportName": "Arctic Village Airport",
        "City": "Arctic Village",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1165,
        "AirportCode": "HGR",
        "AirportName": "Hagerstown Regional Richard A Henson Field",
        "City": "Hagerstown",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 1166,
        "AirportCode": "SDP",
        "AirportName": "Sand Point Airport",
        "City": "Sand Point",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1167,
        "AirportCode": "DRG",
        "AirportName": "Deering Airport",
        "City": "Deering",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1168,
        "AirportCode": "IGG",
        "AirportName": "Igiugig Airport",
        "City": "Igiugig",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1169,
        "AirportCode": "KNW",
        "AirportName": "New Stuyahok Airport",
        "City": "New Stuyahok",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1170,
        "AirportCode": "KVC",
        "AirportName": "King Cove Airport",
        "City": "King Cove",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1171,
        "AirportCode": "PTH",
        "AirportName": "Port Heiden Airport",
        "City": "Port Heiden",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1172,
        "AirportCode": "TOG",
        "AirportName": "Togiak Airport",
        "City": "Togiak Village",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1173,
        "AirportCode": "ESC",
        "AirportName": "Delta County Airport",
        "City": "Escanaba",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1174,
        "AirportCode": "YAK",
        "AirportName": "Yakutat Airport",
        "City": "Yakutat",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1175,
        "AirportCode": "MWA",
        "AirportName": "Williamson County Regional Airport",
        "City": "Marion",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1176,
        "AirportCode": "IMT",
        "AirportName": "Ford Airport",
        "City": "Iron Mountain",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1177,
        "AirportCode": "AET",
        "AirportName": "Allakaket Airport",
        "City": "Allakaket",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1178,
        "AirportCode": "MGC",
        "AirportName": "Michigan City Municipal Airport",
        "City": "Michigan City",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1179,
        "AirportCode": "SWD",
        "AirportName": "Seward Airport",
        "City": "Seward",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1180,
        "AirportCode": "GRM",
        "AirportName": "Grand Marais Cook County Airport",
        "City": "Grand Marais",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1181,
        "AirportCode": "AUW",
        "AirportName": "Wausau Downtown Airport",
        "City": "Wausau",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1182,
        "AirportCode": "MIE",
        "AirportName": "Delaware County Johnson Field",
        "City": "Muncie",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1183,
        "AirportCode": "LAF",
        "AirportName": "Purdue University Airport",
        "City": "Lafayette",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1184,
        "AirportCode": "VGT",
        "AirportName": "North Las Vegas Airport",
        "City": "Las Vegas",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1185,
        "AirportCode": "ENW",
        "AirportName": "Kenosha Regional Airport",
        "City": "Kenosha",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1186,
        "AirportCode": "RIW",
        "AirportName": "Riverton Regional Airport",
        "City": "Riverton WY",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1187,
        "AirportCode": "PDT",
        "AirportName": "Eastern Oregon Regional At Pendleton Airport",
        "City": "Pendleton",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1188,
        "AirportCode": "EAT",
        "AirportName": "Pangborn Memorial Airport",
        "City": "Wenatchee",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1189,
        "AirportCode": "GYY",
        "AirportName": "Gary Chicago International Airport",
        "City": "Gary",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1190,
        "AirportCode": "BRD",
        "AirportName": "Brainerd Lakes Regional Airport",
        "City": "Brainerd",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1191,
        "AirportCode": "LWB",
        "AirportName": "Greenbrier Valley Airport",
        "City": "Lewisburg",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1192,
        "AirportCode": "CYF",
        "AirportName": "Chefornak Airport",
        "City": "Chefornak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1193,
        "AirportCode": "OXR",
        "AirportName": "Oxnard Airport",
        "City": "Oxnard",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1194,
        "AirportCode": "SCH",
        "AirportName": "Schenectady County Airport",
        "City": "Scotia NY",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1195,
        "AirportCode": "UST",
        "AirportName": "Northeast Florida Regional Airport",
        "City": "St. Augustine Airport",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1196,
        "AirportCode": "LCQ",
        "AirportName": "Lake City Gateway Airport",
        "City": "Lake City",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1197,
        "AirportCode": "LGU",
        "AirportName": "Logan-Cache Airport",
        "City": "Logan",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1198,
        "AirportCode": "BMC",
        "AirportName": "Brigham City Airport",
        "City": "Brigham City",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1199,
        "AirportCode": "MLD",
        "AirportName": "Malad City Airport",
        "City": "Malad City",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1200,
        "AirportCode": "ERV",
        "AirportName": "Kerrville Municipal Louis Schreiner Field",
        "City": "Kerrville",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1201,
        "AirportCode": "GED",
        "AirportName": "Sussex County Airport",
        "City": "Georgetown",
        "State": "DE",
        "Country": "US"
      },
      {
        "Id": 1202,
        "AirportCode": "GBN",
        "AirportName": "Great Bend Municipal Airport",
        "City": "Great Bend",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1203,
        "AirportCode": "HYS",
        "AirportName": "Hays Regional Airport",
        "City": "Hays",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1204,
        "AirportCode": "SUS",
        "AirportName": "Spirit of St Louis Airport",
        "City": null,
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1205,
        "AirportCode": "LYU",
        "AirportName": "Ely Municipal Airport",
        "City": "Ely",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1206,
        "AirportCode": "GPZ",
        "AirportName": "Grand Rapids Itasca Co-Gordon Newstrom field",
        "City": "Grand Rapids MN",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1207,
        "AirportCode": "TVF",
        "AirportName": "Thief River Falls Regional Airport",
        "City": "Thief River Falls",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1208,
        "AirportCode": "EGV",
        "AirportName": "Eagle River Union Airport",
        "City": "Eagle River",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1209,
        "AirportCode": "ARV",
        "AirportName": "Lakeland-Noble F. Lee Memorial field",
        "City": "Minocqua - Woodruff",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1210,
        "AirportCode": "IKV",
        "AirportName": "Ankeny Regional Airport",
        "City": "Ankeny",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1211,
        "AirportCode": "NGP",
        "AirportName": "Corpus Christi Naval Air Station/Truax Field",
        "City": "Corpus Christi",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1212,
        "AirportCode": "AVX",
        "AirportName": "Catalina Airport",
        "City": "Catalina Island",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1213,
        "AirportCode": "MHV",
        "AirportName": "Mojave Airport",
        "City": "Mojave",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1214,
        "AirportCode": "HUT",
        "AirportName": "Hutchinson Municipal Airport",
        "City": "Hutchinson",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1215,
        "AirportCode": "STJ",
        "AirportName": "Rosecrans Memorial Airport",
        "City": "Rosecrans",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1216,
        "AirportCode": "VOK",
        "AirportName": "Volk Field",
        "City": "Camp Douglas",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1217,
        "AirportCode": "TOA",
        "AirportName": "Zamperini Field",
        "City": "Torrance",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1218,
        "AirportCode": "MBL",
        "AirportName": "Manistee Co Blacker Airport",
        "City": "Manistee",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1219,
        "AirportCode": "WFK",
        "AirportName": "Northern Aroostook Regional Airport",
        "City": "Frenchville",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 1220,
        "AirportCode": "JHW",
        "AirportName": "Chautauqua County-Jamestown Airport",
        "City": "Jamestown",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1221,
        "AirportCode": "SME",
        "AirportName": "Lake Cumberland Regional Airport",
        "City": "Somerset",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1222,
        "AirportCode": "SHD",
        "AirportName": "Shenandoah Valley Regional Airport",
        "City": "Weyers Cave",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1223,
        "AirportCode": "DVL",
        "AirportName": "Devils Lake Regional Airport",
        "City": "Devils Lake",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 1224,
        "AirportCode": "DIK",
        "AirportName": "Dickinson Theodore Roosevelt Regional Airport",
        "City": "Dickinson",
        "State": "ND",
        "Country": "US"
      },
      {
        "Id": 1225,
        "AirportCode": "SDY",
        "AirportName": "Sidney Richland Municipal Airport",
        "City": "Sidney",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1226,
        "AirportCode": "CDR",
        "AirportName": "Chadron Municipal Airport",
        "City": "Chadron",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1227,
        "AirportCode": "AIA",
        "AirportName": "Alliance Municipal Airport",
        "City": "Alliance",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1228,
        "AirportCode": "MCK",
        "AirportName": "Mc Cook Ben Nelson Regional Airport",
        "City": "McCook",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1229,
        "AirportCode": "MTH",
        "AirportName": "The Florida Keys Marathon Airport",
        "City": "Marathon",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1230,
        "AirportCode": "GDV",
        "AirportName": "Dawson Community Airport",
        "City": "Glendive",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1231,
        "AirportCode": "OLF",
        "AirportName": "L M Clayton Airport",
        "City": "Wolf Point",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1232,
        "AirportCode": "WYS",
        "AirportName": "Yellowstone Airport",
        "City": "West Yellowstone",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1233,
        "AirportCode": "ALS",
        "AirportName": "San Luis Valley Regional Bergman Field",
        "City": "Alamosa",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1234,
        "AirportCode": "CNY",
        "AirportName": "Canyonlands Field",
        "City": "Moab",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1235,
        "AirportCode": "ELY",
        "AirportName": "Ely Airport Yelland Field",
        "City": "Ely",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1236,
        "AirportCode": "VEL",
        "AirportName": "Vernal Regional Airport",
        "City": "Vernal",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1237,
        "AirportCode": "SRR",
        "AirportName": "Sierra Blanca Regional Airport",
        "City": "Ruidoso",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1238,
        "AirportCode": "SOW",
        "AirportName": "Show Low Regional Airport",
        "City": "Show Low",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1239,
        "AirportCode": "MYL",
        "AirportName": "McCall Municipal Airport",
        "City": "McCall",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1240,
        "AirportCode": "SMN",
        "AirportName": "Lemhi County Airport",
        "City": "Salmon",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1241,
        "AirportCode": "MMH",
        "AirportName": "Mammoth Yosemite Airport",
        "City": "Mammoth Lakes",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1242,
        "AirportCode": "FRD",
        "AirportName": "Friday Harbor Airport",
        "City": "Friday Harbor",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1243,
        "AirportCode": "ESD",
        "AirportName": "Orcas Island Airport",
        "City": "Eastsound",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1244,
        "AirportCode": "AST",
        "AirportName": "Astoria Regional Airport",
        "City": "Astoria",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1245,
        "AirportCode": "ONP",
        "AirportName": "Newport Municipal Airport",
        "City": "Newport",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1246,
        "AirportCode": "EMK",
        "AirportName": "Emmonak Airport",
        "City": "Emmonak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1247,
        "AirportCode": "UNK",
        "AirportName": "Unalakleet Airport",
        "City": "Unalakleet",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1248,
        "AirportCode": "UUK",
        "AirportName": "Ugnu-Kuparuk Airport",
        "City": "Kuparuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1249,
        "AirportCode": "SHX",
        "AirportName": "Shageluk Airport",
        "City": "Shageluk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1250,
        "AirportCode": "NUI",
        "AirportName": "Nuiqsut Airport",
        "City": "Nuiqsut",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1251,
        "AirportCode": "EEK",
        "AirportName": "Eek Airport",
        "City": "Eek",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1252,
        "AirportCode": "KUK",
        "AirportName": "Kasigluk Airport",
        "City": "Kasigluk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1253,
        "AirportCode": "KWT",
        "AirportName": "Kwethluk Airport",
        "City": "Kwethluk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1254,
        "AirportCode": "KWK",
        "AirportName": "Kwigillingok Airport",
        "City": "Kwigillingok",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1255,
        "AirportCode": "MLL",
        "AirportName": "Marshall Don Hunter Sr Airport",
        "City": "Marshall",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1256,
        "AirportCode": "RSH",
        "AirportName": "Russian Mission Airport",
        "City": "Russian Mission",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1257,
        "AirportCode": "KGK",
        "AirportName": "Koliganek Airport",
        "City": "Koliganek",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1258,
        "AirportCode": "KMO",
        "AirportName": "Manokotak Airport",
        "City": "Manokotak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1259,
        "AirportCode": "CIK",
        "AirportName": "Chalkyitsik Airport",
        "City": "Chalkyitsik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1260,
        "AirportCode": "EAA",
        "AirportName": "Eagle Airport",
        "City": "Eagle",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1261,
        "AirportCode": "HUS",
        "AirportName": "Hughes Airport",
        "City": "Hughes",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1262,
        "AirportCode": "HSL",
        "AirportName": "Huslia Airport",
        "City": "Huslia",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1263,
        "AirportCode": "NUL",
        "AirportName": "Nulato Airport",
        "City": "Nulato",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1264,
        "AirportCode": "VEE",
        "AirportName": "Venetie Airport",
        "City": "Venetie",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1265,
        "AirportCode": "WBQ",
        "AirportName": "Beaver Airport",
        "City": "Beaver",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1266,
        "AirportCode": "CEM",
        "AirportName": "Central Airport",
        "City": "Central",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1267,
        "AirportCode": "SHG",
        "AirportName": "Shungnak Airport",
        "City": "Shungnak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1268,
        "AirportCode": "IYK",
        "AirportName": "Inyokern Airport",
        "City": "Inyokern",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1269,
        "AirportCode": "VIS",
        "AirportName": "Visalia Municipal Airport",
        "City": "Visalia",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1270,
        "AirportCode": "MCE",
        "AirportName": "Merced Regional Macready Field",
        "City": "Merced",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1271,
        "AirportCode": "AGN",
        "AirportName": "Angoon Seaplane Base",
        "City": "Angoon",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1272,
        "AirportCode": "ELV",
        "AirportName": "Elfin Cove Seaplane Base",
        "City": "Elfin Cove",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1273,
        "AirportCode": "FNR",
        "AirportName": "Funter Bay Seaplane Base",
        "City": "Funter Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1274,
        "AirportCode": "HNH",
        "AirportName": "Hoonah Airport",
        "City": "Hoonah",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1275,
        "AirportCode": "AFE",
        "AirportName": "Kake Airport",
        "City": "Kake",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1276,
        "AirportCode": "MTM",
        "AirportName": "Metlakatla Seaplane Base",
        "City": "Metakatla",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1277,
        "AirportCode": "HYG",
        "AirportName": "Hydaburg Seaplane Base",
        "City": "Hydaburg",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1278,
        "AirportCode": "EGX",
        "AirportName": "Egegik Airport",
        "City": "Egegik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1279,
        "AirportCode": "KPV",
        "AirportName": "Perryville Airport",
        "City": "Perryville",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1280,
        "AirportCode": "PIP",
        "AirportName": "Pilot Point Airport",
        "City": "Pilot Point",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1281,
        "AirportCode": "WSN",
        "AirportName": "South Naknek Nr 2 Airport",
        "City": "South Naknek",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1282,
        "AirportCode": "AKK",
        "AirportName": "Akhiok Airport",
        "City": "Akhiok",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1283,
        "AirportCode": "KYK",
        "AirportName": "Karluk Airport",
        "City": "Karluk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1284,
        "AirportCode": "KLN",
        "AirportName": "Larsen Bay Airport",
        "City": "Larsen Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1285,
        "AirportCode": "ABL",
        "AirportName": "Ambler Airport",
        "City": "Ambler",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1286,
        "AirportCode": "BKC",
        "AirportName": "Buckland Airport",
        "City": "Buckland",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1287,
        "AirportCode": "IAN",
        "AirportName": "Bob Baker Memorial Airport",
        "City": "Kiana",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1288,
        "AirportCode": "OBU",
        "AirportName": "Kobuk Airport",
        "City": "Kobuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1289,
        "AirportCode": "ORV",
        "AirportName": "Robert (Bob) Curtis Memorial Airport",
        "City": "Noorvik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1290,
        "AirportCode": "WLK",
        "AirportName": "Selawik Airport",
        "City": "Selawik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1291,
        "AirportCode": "KTS",
        "AirportName": "Brevig Mission Airport",
        "City": "Brevig Mission",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1292,
        "AirportCode": "ELI",
        "AirportName": "Elim Airport",
        "City": "Elim",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1293,
        "AirportCode": "GLV",
        "AirportName": "Golovin Airport",
        "City": "Golovin",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1294,
        "AirportCode": "TLA",
        "AirportName": "Teller Airport",
        "City": "Teller",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1295,
        "AirportCode": "WAA",
        "AirportName": "Wales Airport",
        "City": "Wales",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1296,
        "AirportCode": "WMO",
        "AirportName": "White Mountain Airport",
        "City": "White Mountain",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1297,
        "AirportCode": "KKA",
        "AirportName": "Koyuk Alfred Adams Airport",
        "City": "Koyuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1298,
        "AirportCode": "SMK",
        "AirportName": "St Michael Airport",
        "City": "St. Michael",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1299,
        "AirportCode": "SKK",
        "AirportName": "Shaktoolik Airport",
        "City": "Shaktoolik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1300,
        "AirportCode": "TNC",
        "AirportName": "Tin City Long Range Radar Station Airport",
        "City": "Tin City",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1301,
        "AirportCode": "AKB",
        "AirportName": "Atka Airport",
        "City": "Atka",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1302,
        "AirportCode": "CYT",
        "AirportName": "Yakataga Airport",
        "City": "Yakataga",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1303,
        "AirportCode": "AUK",
        "AirportName": "Alakanuk Airport",
        "City": "Alakanuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1304,
        "AirportCode": "KPN",
        "AirportName": "Kipnuk Airport",
        "City": "Kipnuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1305,
        "AirportCode": "KFP",
        "AirportName": "False Pass Airport",
        "City": "False Pass",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1306,
        "AirportCode": "NLG",
        "AirportName": "Nelson Lagoon Airport",
        "City": "Nelson Lagoon",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1307,
        "AirportCode": "PML",
        "AirportName": "Port Moller Airport",
        "City": "Cold Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1308,
        "AirportCode": "KLW",
        "AirportName": "Klawock Airport",
        "City": "Klawock",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1309,
        "AirportCode": "KWN",
        "AirportName": "Quinhagak Airport",
        "City": "Quinhagak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1310,
        "AirportCode": "KOT",
        "AirportName": "Kotlik Airport",
        "City": "Kotlik",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1311,
        "AirportCode": "KYU",
        "AirportName": "Koyukuk Airport",
        "City": "Koyukuk",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1312,
        "AirportCode": "SCM",
        "AirportName": "Scammon Bay Airport",
        "City": "Scammon Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1313,
        "AirportCode": "NNL",
        "AirportName": "Nondalton Airport",
        "City": "Nondalton",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1314,
        "AirportCode": "KKH",
        "AirportName": "Kongiganak Airport",
        "City": "Kongiganak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1315,
        "AirportCode": "NIB",
        "AirportName": "Nikolai Airport",
        "City": "Nikolai",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1316,
        "AirportCode": "AKI",
        "AirportName": "Akiak Airport",
        "City": "Akiak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1317,
        "AirportCode": "AIN",
        "AirportName": "Wainwright Airport",
        "City": "Wainwright",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1318,
        "AirportCode": "NCN",
        "AirportName": "Chenega Bay Airport",
        "City": "Chenega",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1319,
        "AirportCode": "TKJ",
        "AirportName": "Tok Junction Airport",
        "City": "Tok",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1320,
        "AirportCode": "IRC",
        "AirportName": "Circle City /New/ Airport",
        "City": "Circle",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1321,
        "AirportCode": "SLQ",
        "AirportName": "Sleetmute Airport",
        "City": "Sleetmute",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1322,
        "AirportCode": "HKB",
        "AirportName": "Healy River Airport",
        "City": "Healy",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1323,
        "AirportCode": "AQC",
        "AirportName": "Klawock Seaplane Base",
        "City": "Klawock",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1324,
        "AirportCode": "MHM",
        "AirportName": "Minchumina Airport",
        "City": "Lake Minchumina",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1325,
        "AirportCode": "MLY",
        "AirportName": "Manley Hot Springs Airport",
        "City": "Manley Hot Springs",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1326,
        "AirportCode": "MRB",
        "AirportName": "Eastern WV Regional Airport/Shepherd Field",
        "City": "Martinsburg",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1327,
        "AirportCode": "RKH",
        "AirportName": "Rock Hill - York County Airport",
        "City": "Rock Hill",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1328,
        "AirportCode": "AGC",
        "AirportName": "Allegheny County Airport",
        "City": "Pittsburgh",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1329,
        "AirportCode": "DTS",
        "AirportName": "Destin Executive Airport",
        "City": "Destin",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1330,
        "AirportCode": "ISO",
        "AirportName": "Kinston Regional Jetport At Stallings Field",
        "City": "Kinston",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1331,
        "AirportCode": "FFA",
        "AirportName": "First Flight Airport",
        "City": "Kill Devil Hills",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1332,
        "AirportCode": "PVU",
        "AirportName": "Provo Municipal Airport",
        "City": "Provo",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1333,
        "AirportCode": "SBS",
        "AirportName": "Steamboat Springs Bob Adams Field",
        "City": "Steamboat Springs",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1334,
        "AirportCode": "DTA",
        "AirportName": "Delta Municipal Airport",
        "City": "Delta",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1335,
        "AirportCode": "RIF",
        "AirportName": "Richfield Municipal Airport",
        "City": "Richfield",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1336,
        "AirportCode": "PUC",
        "AirportName": "Carbon County Regional/Buck Davis Field",
        "City": "Price",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1337,
        "AirportCode": "LAM",
        "AirportName": "Los Alamos Airport",
        "City": "Los Alamos",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1338,
        "AirportCode": "INW",
        "AirportName": "Winslow Lindbergh Regional Airport",
        "City": "Winslow",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1339,
        "AirportCode": "DGL",
        "AirportName": "Douglas Municipal Airport",
        "City": "Douglas",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1340,
        "AirportCode": "BOW",
        "AirportName": "Bartow Municipal Airport",
        "City": "Bartow",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1341,
        "AirportCode": "LVK",
        "AirportName": "Livermore Municipal Airport",
        "City": "Livermore",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1342,
        "AirportCode": "RMY",
        "AirportName": "Mariposa Yosemite Airport",
        "City": "Mariposa",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1343,
        "AirportCode": "TRM",
        "AirportName": "Jacqueline Cochran Regional Airport",
        "City": "Palm Springs",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1344,
        "AirportCode": "ZSY",
        "AirportName": "Scottsdale Airport",
        "City": "Scottsdale",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1345,
        "AirportCode": "OLM",
        "AirportName": "Olympia Regional Airport",
        "City": "Olympia",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1346,
        "AirportCode": "DWA",
        "AirportName": "Yolo County Davis Woodland Winters Airport",
        "City": "Davis-Woodland-Winters",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1347,
        "AirportCode": "RIL",
        "AirportName": "Garfield County Regional Airport",
        "City": "Rifle",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1348,
        "AirportCode": "SAA",
        "AirportName": "Shively Field",
        "City": "SARATOGA",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1349,
        "AirportCode": "BMG",
        "AirportName": "Monroe County Airport",
        "City": "Bloomington",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1350,
        "AirportCode": "SDM",
        "AirportName": "Brown Field Municipal Airport",
        "City": "San Diego",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1351,
        "AirportCode": "VNC",
        "AirportName": "Venice Municipal Airport",
        "City": "Venice",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1352,
        "AirportCode": "PHK",
        "AirportName": "Palm Beach County Glades Airport",
        "City": "Pahokee",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1353,
        "AirportCode": "SBD",
        "AirportName": "San Bernardino International Airport",
        "City": "San Bernardino",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1354,
        "AirportCode": "SQL",
        "AirportName": "San Carlos Airport",
        "City": "San Carlos",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1355,
        "AirportCode": "RWI",
        "AirportName": "Rocky Mount Wilson Regional Airport",
        "City": "Rocky Mount",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1356,
        "AirportCode": "SXQ",
        "AirportName": "Soldotna Airport",
        "City": "Soldotna",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1357,
        "AirportCode": "SEE",
        "AirportName": "Gillespie Field",
        "City": "El Cajon",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1358,
        "AirportCode": "TKF",
        "AirportName": "Truckee Tahoe Airport",
        "City": "Truckee",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1359,
        "AirportCode": "RYY",
        "AirportName": "Cobb County-Mc Collum Field",
        "City": "Atlanta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1360,
        "AirportCode": "4U9",
        "AirportName": "Dell Flight Strip",
        "City": "Dell",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1361,
        "AirportCode": "LVM",
        "AirportName": "Mission Field",
        "City": "Livingston-Montana",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1362,
        "AirportCode": "6S0",
        "AirportName": "Big Timber Airport",
        "City": "Big Timber",
        "State": "MT",
        "Country": "US"
      },
      {
        "Id": 1363,
        "AirportCode": "BIV",
        "AirportName": "Tulip City Airport",
        "City": "Holland",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1364,
        "AirportCode": "LAL",
        "AirportName": "Lakeland Linder Regional Airport",
        "City": "Lakeland",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1365,
        "AirportCode": "IDL",
        "AirportName": "Indianola Municipal Airport",
        "City": "New York",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1366,
        "AirportCode": "RBK",
        "AirportName": "French Valley Airport",
        "City": "Murrieta-Temecula",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1367,
        "AirportCode": "PCW",
        "AirportName": "Carl R Keller Field",
        "City": "Port Clinton",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1368,
        "AirportCode": "RID",
        "AirportName": "Richmond Municipal Airport",
        "City": "Richmond",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1369,
        "AirportCode": "FDY",
        "AirportName": "Findlay Airport",
        "City": "Findley",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1370,
        "AirportCode": "SPF",
        "AirportName": "Black Hills Airport-Clyde Ice Field",
        "City": "Spearfish-South Dakota",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1371,
        "AirportCode": "OLV",
        "AirportName": "Olive Branch Airport",
        "City": "Olive Branch",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1372,
        "AirportCode": "UTM",
        "AirportName": "Tunica Municipal Airport",
        "City": "Tunica",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1373,
        "AirportCode": "MWC",
        "AirportName": "Lawrence J Timmerman Airport",
        "City": "Milwaukee",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1374,
        "AirportCode": "JVL",
        "AirportName": "Southern Wisconsin Regional Airport",
        "City": "Janesville",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1375,
        "AirportCode": "GKY",
        "AirportName": "Arlington Municipal Airport",
        "City": "Arlington",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1376,
        "AirportCode": "LZU",
        "AirportName": "Gwinnett County Briscoe Field",
        "City": "Lawrenceville",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1377,
        "AirportCode": "BWG",
        "AirportName": "Bowling Green Warren County Regional Airport",
        "City": "Bowling Green",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1378,
        "AirportCode": "RVS",
        "AirportName": "Richard Lloyd Jones Jr Airport",
        "City": "Tulsa",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1379,
        "AirportCode": "BCE",
        "AirportName": "Bryce Canyon Airport",
        "City": "Bryce Canyon",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1380,
        "AirportCode": "JCI",
        "AirportName": "New Century Aircenter Airport",
        "City": "Olathe",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1381,
        "AirportCode": "ESN",
        "AirportName": "Easton Newnam Field",
        "City": "Easton",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 1382,
        "AirportCode": "DUC",
        "AirportName": "Halliburton Field",
        "City": "Duncan",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1383,
        "AirportCode": "UVA",
        "AirportName": "Garner Field",
        "City": "Uvalde",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1384,
        "AirportCode": "LOT",
        "AirportName": "Lewis University Airport",
        "City": "Lockport",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1385,
        "AirportCode": "CCR",
        "AirportName": "Buchanan Field",
        "City": "Concord",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1386,
        "AirportCode": "OCA",
        "AirportName": "Ocean Reef Club Airport",
        "City": "Ocean Reef Club Airport",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1387,
        "AirportCode": "SGH",
        "AirportName": "Springfield-Beckley Municipal Airport",
        "City": "Springfield",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1388,
        "AirportCode": "TOP",
        "AirportName": "Philip Billard Municipal Airport",
        "City": "Topeka",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1389,
        "AirportCode": "TKI",
        "AirportName": "Collin County Regional At Mc Kinney Airport",
        "City": "DALLAS",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1390,
        "AirportCode": "KLS",
        "AirportName": "Southwest Washington Regional Airport",
        "City": "Kelso",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1391,
        "AirportCode": "ILN",
        "AirportName": "Wilmington Airpark",
        "City": "Wilmington",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1392,
        "AirportCode": "AVW",
        "AirportName": "Marana Regional Airport",
        "City": "Tucson",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1393,
        "AirportCode": "CGZ",
        "AirportName": "Casa Grande Municipal Airport",
        "City": "Casa Grande",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1394,
        "AirportCode": "BXK",
        "AirportName": "Buckeye Municipal Airport",
        "City": "Buckeye",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1395,
        "AirportCode": "E63",
        "AirportName": "Gila Bend Municipal Airport",
        "City": "Gila Bend",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1396,
        "AirportCode": "MMI",
        "AirportName": "McMinn County Airport",
        "City": "Athens",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 1397,
        "AirportCode": "STK",
        "AirportName": "Sterling Municipal Airport",
        "City": "Sterling",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1398,
        "AirportCode": "RWL",
        "AirportName": "Rawlins Municipal Airport/Harvey Field",
        "City": "Rawlins",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1399,
        "AirportCode": "AIZ",
        "AirportName": "Lee C Fine Memorial Airport",
        "City": "Kaiser Lake Ozark",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1400,
        "AirportCode": "TVI",
        "AirportName": "Thomasville Regional Airport",
        "City": "Thomasville",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1401,
        "AirportCode": "HSH",
        "AirportName": "Henderson Executive Airport",
        "City": "Henderson",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1402,
        "AirportCode": "TMA",
        "AirportName": "Henry Tift Myers Airport",
        "City": "Tifton",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1403,
        "AirportCode": "DVT",
        "AirportName": "Phoenix Deer Valley Airport",
        "City": "Phoenix ",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1404,
        "AirportCode": "HDO",
        "AirportName": "South Texas Regional Airport at Hondo",
        "City": "Hondo",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1405,
        "AirportCode": "MCL",
        "AirportName": "McKinley National Park Airport",
        "City": "McKinley Park",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1406,
        "AirportCode": "LHD",
        "AirportName": "Lake Hood Seaplane Base",
        "City": "Anchorage",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1407,
        "AirportCode": "PPC",
        "AirportName": "Prospect Creek Airport",
        "City": "Prospect Creek",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1408,
        "AirportCode": "HLG",
        "AirportName": "Wheeling Ohio County Airport",
        "City": "Wheeling",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1409,
        "AirportCode": "FZG",
        "AirportName": "Fitzgerald Municipal Airport",
        "City": "Fitzgerald",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1410,
        "AirportCode": "RKP",
        "AirportName": "Aransas County Airport",
        "City": "Rockport",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1411,
        "AirportCode": "CKF",
        "AirportName": "Crisp County Cordele Airport",
        "City": "Cordele",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1412,
        "AirportCode": "OMN",
        "AirportName": "Ormond Beach Municipal Airport",
        "City": "Ormond Beach",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1413,
        "AirportCode": "TTD",
        "AirportName": "Portland Troutdale Airport",
        "City": "Troutdale",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1414,
        "AirportCode": "CVX",
        "AirportName": "Charlevoix Municipal Airport",
        "City": "Charelvoix",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1415,
        "AirportCode": "XFL",
        "AirportName": "Flagler County Airport",
        "City": "Flagler",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1416,
        "AirportCode": "MVL",
        "AirportName": "Morrisville Stowe State Airport",
        "City": "Morrisville",
        "State": "VT",
        "Country": "US"
      },
      {
        "Id": 1417,
        "AirportCode": "WST",
        "AirportName": "Westerly State Airport",
        "City": "Washington County",
        "State": "RI",
        "Country": "US"
      },
      {
        "Id": 1418,
        "AirportCode": "BID",
        "AirportName": "Block Island State Airport",
        "City": "Block Island",
        "State": "RI",
        "Country": "US"
      },
      {
        "Id": 1419,
        "AirportCode": "NME",
        "AirportName": "Nightmute Airport",
        "City": "Nightmute",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1420,
        "AirportCode": "OOK",
        "AirportName": "Toksook Bay Airport",
        "City": "Toksook Bay",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1421,
        "AirportCode": "BGE",
        "AirportName": "Decatur County Industrial Air Park",
        "City": "Bainbridge",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1422,
        "AirportCode": "SPZ",
        "AirportName": "Silver Springs Airport",
        "City": "Silver Springs",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1423,
        "AirportCode": "WHP",
        "AirportName": "Whiteman Airport",
        "City": "Los Angeles",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1424,
        "AirportCode": "MAE",
        "AirportName": "Madera Municipal Airport",
        "City": "Madera",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1425,
        "AirportCode": "AAF",
        "AirportName": "Apalachicola Regional Airport",
        "City": "Apalachicola",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1426,
        "AirportCode": "DQH",
        "AirportName": "Douglas Municipal Airport",
        "City": "Douglas",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1427,
        "AirportCode": "FRP",
        "AirportName": "St Lucie County International Airport",
        "City": "Fort Pierce",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1428,
        "AirportCode": "TAN",
        "AirportName": "Taunton Municipal King Field",
        "City": "Taunton",
        "State": "MA",
        "Country": "US"
      },
      {
        "Id": 1429,
        "AirportCode": "OQU",
        "AirportName": "Quonset State Airport",
        "City": "North Kingstown",
        "State": "RI",
        "Country": "US"
      },
      {
        "Id": 1430,
        "AirportCode": "MGJ",
        "AirportName": "Orange County Airport",
        "City": "Montgomery",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1431,
        "AirportCode": "CXY",
        "AirportName": "Capital City Airport",
        "City": "Harrisburg",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1432,
        "AirportCode": "VPC",
        "AirportName": "Cartersville Airport",
        "City": "Cartersville",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1433,
        "AirportCode": "PYP",
        "AirportName": "Centre-Piedmont-Cherokee County Regional Airport",
        "City": "Centre",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1434,
        "AirportCode": "RMG",
        "AirportName": "Richard B Russell Airport",
        "City": "Rome",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1435,
        "AirportCode": "GAD",
        "AirportName": "Northeast Alabama Regional Airport",
        "City": "Gadsden",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1436,
        "AirportCode": "DKX",
        "AirportName": "Knoxville Downtown Island Airport",
        "City": "Knoxville",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 1437,
        "AirportCode": "WDR",
        "AirportName": "Barrow County Airport",
        "City": "Winder",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1438,
        "AirportCode": "JYL",
        "AirportName": "Plantation Airpark",
        "City": "Sylvania",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1439,
        "AirportCode": "DNN",
        "AirportName": "Dalton Municipal Airport",
        "City": "Dalton",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1440,
        "AirportCode": "CTJ",
        "AirportName": "West Georgia Regional O V Gray Field",
        "City": "Carrollton",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1441,
        "AirportCode": "LGC",
        "AirportName": "Lagrange Callaway Airport",
        "City": "LaGrange",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1442,
        "AirportCode": "MLJ",
        "AirportName": "Baldwin County Airport",
        "City": "Milledgeville",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1443,
        "AirportCode": "PIM",
        "AirportName": "Harris County Airport",
        "City": "Pine Mountain",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1444,
        "AirportCode": "FFC",
        "AirportName": "Peachtree City Falcon Field",
        "City": "Atlanta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1445,
        "AirportCode": "GVL",
        "AirportName": "Lee Gilmer Memorial Airport",
        "City": "Gainesville",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1446,
        "AirportCode": "PHD",
        "AirportName": "Harry Clever Field",
        "City": "New Philadelpha",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1447,
        "AirportCode": "UDG",
        "AirportName": "Darlington County Jetport Airport",
        "City": "Darlington",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1448,
        "AirportCode": "DNL",
        "AirportName": "Daniel Field",
        "City": "Augusta",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1449,
        "AirportCode": "MRN",
        "AirportName": "Foothills Regional Airport",
        "City": "Morganton",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1450,
        "AirportCode": "PBX",
        "AirportName": "Pike County-Hatcher Field",
        "City": "Pikeville",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1451,
        "AirportCode": "TOC",
        "AirportName": "Toccoa Airport - R.G. Letourneau Field",
        "City": "Toccoa",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1452,
        "AirportCode": "57C",
        "AirportName": "East Troy Municipal Airport",
        "City": "East Troy",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1453,
        "AirportCode": "LWC",
        "AirportName": "Lawrence Municipal Airport",
        "City": "Lawrence",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1454,
        "AirportCode": "EGT",
        "AirportName": "Wellington Municipal Airport",
        "City": "Wellington",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1455,
        "AirportCode": "PMP",
        "AirportName": "Pompano Beach Airpark",
        "City": "Pompano Beach",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1456,
        "AirportCode": "EET",
        "AirportName": "Shelby County Airport",
        "City": "Alabaster",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1457,
        "AirportCode": "FBG",
        "AirportName": "Simmons Army Air Field",
        "City": "Fredericksburg",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1458,
        "AirportCode": "RAC",
        "AirportName": "John H Batten Airport",
        "City": "Racine",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1459,
        "AirportCode": "REI",
        "AirportName": "Redlands Municipal Airport",
        "City": "Redlands",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1460,
        "AirportCode": "RIR",
        "AirportName": "Flabob Airport",
        "City": "Riverside",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1461,
        "AirportCode": "TIW",
        "AirportName": "Tacoma Narrows Airport",
        "City": "Tacoma",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1462,
        "AirportCode": "JKA",
        "AirportName": "Jack Edwards Airport",
        "City": "Gulf Shores",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1463,
        "AirportCode": "HZL",
        "AirportName": "Hazleton Municipal Airport",
        "City": "Hazleton",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1464,
        "AirportCode": "CBE",
        "AirportName": "Greater Cumberland Regional Airport",
        "City": "Cumberland",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1465,
        "AirportCode": "LNR",
        "AirportName": "Tri-County Regional Airport",
        "City": "Lone Rock",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1466,
        "AirportCode": "JOT",
        "AirportName": "Joliet Regional Airport",
        "City": "Joliet",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1467,
        "AirportCode": "VYS",
        "AirportName": "Illinois Valley Regional Airport-Walter A Duncan Field",
        "City": "Peru",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1468,
        "AirportCode": "JXN",
        "AirportName": "Jackson County Reynolds Field",
        "City": "Jackson",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1469,
        "AirportCode": "BBX",
        "AirportName": "Wings Field",
        "City": "Philadelphia",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1470,
        "AirportCode": "OBE",
        "AirportName": "Okeechobee County Airport",
        "City": "Okeechobee",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1471,
        "AirportCode": "SEF",
        "AirportName": "Sebring Regional Airport",
        "City": "Sebring",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1472,
        "AirportCode": "AVO",
        "AirportName": "Avon Park Executive Airport",
        "City": "Avon Park",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1473,
        "AirportCode": "GIF",
        "AirportName": "Winter Haven Municipal Airport - Gilbert Field",
        "City": "Winter Haven",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1474,
        "AirportCode": "ZPH",
        "AirportName": "Zephyrhills Municipal Airport",
        "City": "Zephyrhills",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1475,
        "AirportCode": "OCF",
        "AirportName": "Ocala International Airport - Jim Taylor Field",
        "City": "Ocala",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1476,
        "AirportCode": "JES",
        "AirportName": "Jesup Wayne County Airport",
        "City": "Jesup",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1477,
        "AirportCode": "52A",
        "AirportName": "Madison Municipal Airport",
        "City": "Madison",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1478,
        "AirportCode": "CCO",
        "AirportName": "Newnan Coweta County Airport",
        "City": "Newnan",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1479,
        "AirportCode": "HQU",
        "AirportName": "Thomson-McDuffie County Airport",
        "City": "Thomson",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1480,
        "AirportCode": "AIK",
        "AirportName": "Aiken Municipal Airport",
        "City": "Aiken",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1481,
        "AirportCode": "CDN",
        "AirportName": "Woodward Field",
        "City": "Camden",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1482,
        "AirportCode": "LBT",
        "AirportName": "Lumberton Regional Airport",
        "City": "Lumberton",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1483,
        "AirportCode": "RCZ",
        "AirportName": "Richmond County Airport",
        "City": "Rockingham",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1484,
        "AirportCode": "DLL",
        "AirportName": "Baraboo Wisconsin Dells Airport",
        "City": "Baraboo",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1485,
        "AirportCode": "SVH",
        "AirportName": "Statesville Regional Airport",
        "City": "Statesville",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1486,
        "AirportCode": "BUU",
        "AirportName": "Burlington Municipal Airport",
        "City": "Burlington",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1487,
        "AirportCode": "LHV",
        "AirportName": "William T. Piper Memorial Airport",
        "City": "Lock Haven",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1488,
        "AirportCode": "DKK",
        "AirportName": "Chautauqua County-Dunkirk Airport",
        "City": "Dunkirk",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1489,
        "AirportCode": "VAY",
        "AirportName": "South Jersey Regional Airport",
        "City": "Mount Holly",
        "State": "NJ",
        "Country": "US"
      },
      {
        "Id": 1490,
        "AirportCode": "ANQ",
        "AirportName": "Tri State Steuben County Airport",
        "City": "Angola",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1491,
        "AirportCode": "VNW",
        "AirportName": "Van Wert County Airport",
        "City": "Van Wert",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1492,
        "AirportCode": "GVQ",
        "AirportName": "Genesee County Airport",
        "City": "Batavia",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1493,
        "AirportCode": "CGX",
        "AirportName": "Chicago Meigs Airport",
        "City": "Chicago",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1494,
        "AirportCode": "JZP",
        "AirportName": "Pickens County Airport",
        "City": "Jasper",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1495,
        "AirportCode": "CRE",
        "AirportName": "Grand Strand Airport",
        "City": "North Myrtle Beach",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1496,
        "AirportCode": "IGQ",
        "AirportName": "Lansing Municipal Airport",
        "City": "Lansing",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1497,
        "AirportCode": "RNM",
        "AirportName": "Ramona Airport",
        "City": "Ramona",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1498,
        "AirportCode": "OEB",
        "AirportName": "Branch County Memorial Airport",
        "City": "Coldwater",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1499,
        "AirportCode": "WBW",
        "AirportName": "Wilkes Barre Wyoming Valley Airport",
        "City": "Wilkes-Barre",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1500,
        "AirportCode": "LNN",
        "AirportName": "Willoughby Lost Nation Municipal Airport",
        "City": "Willoughby",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1501,
        "AirportCode": "FFT",
        "AirportName": "Capital City Airport",
        "City": "Frankfort",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1502,
        "AirportCode": "MRK",
        "AirportName": "Marco Island Airport",
        "City": "Marco Island Airport",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1503,
        "AirportCode": "DRM",
        "AirportName": "Drummond Island Airport",
        "City": "Drummond Island",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1504,
        "AirportCode": "GDW",
        "AirportName": "Gladwin Zettel Memorial Airport",
        "City": "Gladwin",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1505,
        "AirportCode": "LWA",
        "AirportName": "South Haven Area Regional Airport",
        "City": "South Haven",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1506,
        "AirportCode": "MFI",
        "AirportName": "Marshfield Municipal Airport",
        "City": "Marshfield",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1507,
        "AirportCode": "ISW",
        "AirportName": "Alexander Field South Wood County Airport",
        "City": "Wisconsin Rapids",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1508,
        "AirportCode": "CWI",
        "AirportName": "Clinton Municipal Airport",
        "City": "Clinton",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1509,
        "AirportCode": "POF",
        "AirportName": "Poplar Bluff Municipal Airport",
        "City": "Poplar Bluff",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1510,
        "AirportCode": "EPM",
        "AirportName": "Eastport Municipal Airport",
        "City": "Eastport",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 1511,
        "AirportCode": "EOK",
        "AirportName": "Keokuk Municipal Airport",
        "City": "Keokuk",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1512,
        "AirportCode": "FLD",
        "AirportName": "Fond du Lac County Airport",
        "City": "Fond du Lac",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1513,
        "AirportCode": "PCZ",
        "AirportName": "Waupaca Municipal Airport",
        "City": "Waupaca",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1514,
        "AirportCode": "ERY",
        "AirportName": "Luce County Airport",
        "City": "Newberry",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1515,
        "AirportCode": "GQQ",
        "AirportName": "Galion Municipal Airport",
        "City": "Galion",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1516,
        "AirportCode": "CKV",
        "AirportName": "Clarksville–Montgomery County Regional Airport",
        "City": "Clarksville",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 1517,
        "AirportCode": "LPC",
        "AirportName": "Lompoc Airport",
        "City": "Lompoc",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1518,
        "AirportCode": "CTH",
        "AirportName": "Chester County G O Carlson Airport",
        "City": "Coatesville",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1519,
        "AirportCode": "LKP",
        "AirportName": "Lake Placid Airport",
        "City": "Lake Placid",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1520,
        "AirportCode": "AOH",
        "AirportName": "Lima Allen County Airport",
        "City": "Lima",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1521,
        "AirportCode": "SSI",
        "AirportName": "Malcolm McKinnon Airport",
        "City": "Brunswick",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1522,
        "AirportCode": "BFP",
        "AirportName": "Beaver County Airport",
        "City": "Beaver Falls",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1523,
        "AirportCode": "GGE",
        "AirportName": "Georgetown County Airport",
        "City": "Georgetown",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1524,
        "AirportCode": "HDI",
        "AirportName": "Hardwick Field",
        "City": "Cleveland",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 1525,
        "AirportCode": "RNT",
        "AirportName": "Renton Municipal Airport",
        "City": "Renton",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1526,
        "AirportCode": "POC",
        "AirportName": "Brackett Field",
        "City": "La Verne",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1527,
        "AirportCode": "CTY",
        "AirportName": "Cross City Airport",
        "City": "Cross City",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1528,
        "AirportCode": "CEU",
        "AirportName": "Oconee County Regional Airport",
        "City": "Clemson",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1529,
        "AirportCode": "BEC",
        "AirportName": "Beech Factory Airport",
        "City": "Wichita",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1530,
        "AirportCode": "GTU",
        "AirportName": "Georgetown Municipal Airport",
        "City": "Georgetown",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1531,
        "AirportCode": "SNY",
        "AirportName": "Sidney Municipal-Lloyd W Carr Field",
        "City": "Sidney",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1532,
        "AirportCode": "ECA",
        "AirportName": "Iosco County Airport",
        "City": "East Tawas",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1533,
        "AirportCode": "JYO",
        "AirportName": "Leesburg Executive Airport",
        "City": "Leesburg",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1534,
        "AirportCode": "SDC",
        "AirportName": "Williamson Sodus Airport",
        "City": "Williamson",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1535,
        "AirportCode": "WBU",
        "AirportName": "Boulder Municipal Airport",
        "City": "Boulder",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1536,
        "AirportCode": "PAO",
        "AirportName": "Palo Alto Airport of Santa Clara County",
        "City": "Palo Alto",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1537,
        "AirportCode": "FFZ",
        "AirportName": "Falcon Field",
        "City": "Mesa",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1538,
        "AirportCode": "P08",
        "AirportName": "Coolidge Municipal Airport",
        "City": "Cooldige",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1539,
        "AirportCode": "P52",
        "AirportName": "Cottonwood Airport",
        "City": "Cottonwood",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1540,
        "AirportCode": "A39",
        "AirportName": "Ak-Chin Regional Airport",
        "City": "Phoenix",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1541,
        "AirportCode": "E25",
        "AirportName": "Wickenburg Municipal Airport",
        "City": "Wickenburg",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1542,
        "AirportCode": "EEN",
        "AirportName": "Dillant Hopkins Airport",
        "City": "Keene",
        "State": "NH",
        "Country": "US"
      },
      {
        "Id": 1543,
        "AirportCode": "IOW",
        "AirportName": "Iowa City Municipal Airport",
        "City": "Iowa City",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1544,
        "AirportCode": "MWM",
        "AirportName": "Windom Municipal Airport",
        "City": "Windom",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1545,
        "AirportCode": "ANP",
        "AirportName": "Lee Airport",
        "City": "Annapolis",
        "State": "MD",
        "Country": "US"
      },
      {
        "Id": 1546,
        "AirportCode": "PEQ",
        "AirportName": "Pecos Municipal Airport",
        "City": "Pecos",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1547,
        "AirportCode": "HBG",
        "AirportName": "Hattiesburg Bobby L Chain Municipal Airport",
        "City": "Hattiesburg",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1548,
        "AirportCode": "YKN",
        "AirportName": "Chan Gurney Municipal Airport",
        "City": "Yankton",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1549,
        "AirportCode": "4A7",
        "AirportName": "Atlanta South Regional Airport/Tara Field",
        "City": "Hampton",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1550,
        "AirportCode": "IMM",
        "AirportName": "Immokalee Regional Airport",
        "City": "Immokalee ",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1551,
        "AirportCode": "PTB",
        "AirportName": "Dinwiddie County Airport",
        "City": "Petersburg",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1552,
        "AirportCode": "MZJ",
        "AirportName": "Pinal Airpark",
        "City": "Marana",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1553,
        "AirportCode": "GEU",
        "AirportName": "Glendale Municipal Airport",
        "City": "Glendale",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1554,
        "AirportCode": "SAD",
        "AirportName": "Safford Regional Airport",
        "City": "Safford",
        "State": "AZ",
        "Country": "US"
      },
      {
        "Id": 1555,
        "AirportCode": "SIK",
        "AirportName": "Sikeston Memorial Municipal Airport",
        "City": "Sikeston",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1556,
        "AirportCode": "GFL",
        "AirportName": "Floyd Bennett Memorial Airport",
        "City": "Queensbury",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1557,
        "AirportCode": "CGC",
        "AirportName": "Crystal River Airport",
        "City": "Crystal River",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1558,
        "AirportCode": "LHM",
        "AirportName": "Lincoln Regional Karl Harder Field",
        "City": "Lincoln",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1559,
        "AirportCode": "FZI",
        "AirportName": "Fostoria Metropolitan Airport",
        "City": "Fostoria",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1560,
        "AirportCode": "IZG",
        "AirportName": "Eastern Slopes Regional Airport",
        "City": "Fryeburg",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 1561,
        "AirportCode": "COE",
        "AirportName": "Coeur D'Alene - Pappy Boyington Field",
        "City": "Coeur d'Alene",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1562,
        "AirportCode": "BMT",
        "AirportName": "Beaumont Municipal Airport",
        "City": "Beaumont",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1563,
        "AirportCode": "DNV",
        "AirportName": "Vermilion Regional Airport",
        "City": "Danville",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1564,
        "AirportCode": "TIX",
        "AirportName": "Space Coast Regional Airport",
        "City": "Titusville",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1565,
        "AirportCode": "AAP",
        "AirportName": "Andrau Airpark",
        "City": "Houston",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1566,
        "AirportCode": "OJC",
        "AirportName": "Johnson County Executive Airport",
        "City": "Olathe",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1567,
        "AirportCode": "MNZ",
        "AirportName": "Manassas Regional Airport/Harry P. Davis Field",
        "City": "Manassas",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1568,
        "AirportCode": "2H0",
        "AirportName": "Shelby County Airport",
        "City": "Shelbyville",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1569,
        "AirportCode": "FDW",
        "AirportName": "Fairfield County Airport",
        "City": "Winnsboro",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1570,
        "AirportCode": "DAW",
        "AirportName": "Skyhaven Airport",
        "City": "Rochester",
        "State": "NH",
        "Country": "US"
      },
      {
        "Id": 1571,
        "AirportCode": "EWK",
        "AirportName": "Newton City-County Airport",
        "City": "Newton",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1572,
        "AirportCode": "TZR",
        "AirportName": "Taszár Air Base",
        "City": "Columbus",
        "State": "",
        "Country": "HU"
      },
      {
        "Id": 1573,
        "AirportCode": "FBR",
        "AirportName": "Fort Bridger Airport",
        "City": "Fort Bridger",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1574,
        "AirportCode": "S40",
        "AirportName": "Prosser Airport",
        "City": "Prosser",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1575,
        "AirportCode": "CLS",
        "AirportName": "Chehalis Centralia Airport",
        "City": "Chehalis",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1576,
        "AirportCode": "M94",
        "AirportName": "Desert Aire Airport",
        "City": "Mattawa",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1577,
        "AirportCode": "EVW",
        "AirportName": "Evanston-Uinta County Airport-Burns Field",
        "City": "Evanston",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1578,
        "AirportCode": "K83",
        "AirportName": "Sabetha Municipal Airport",
        "City": "Sabetha",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1579,
        "AirportCode": "LRO",
        "AirportName": "Mt Pleasant Regional-Faison field",
        "City": "Mount Pleasant",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1580,
        "AirportCode": "EUF",
        "AirportName": "Weedon Field",
        "City": "Eufala",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1581,
        "AirportCode": "6J4",
        "AirportName": "Saluda County Airport",
        "City": "Saluda",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1582,
        "AirportCode": "MEO",
        "AirportName": "Dare County Regional Airport",
        "City": "Manteo",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1583,
        "AirportCode": "AUO",
        "AirportName": "Auburn Opelika Robert G. Pitts Airport",
        "City": "Auburn",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1584,
        "AirportCode": "CZG",
        "AirportName": "Tri Cities Airport",
        "City": "Endicott",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1585,
        "AirportCode": "EKY",
        "AirportName": "Bessemer Airport",
        "City": "Bessemer",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1586,
        "AirportCode": "A50",
        "AirportName": "Colorado Springs East Airport",
        "City": "Ellicott",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1587,
        "AirportCode": "MIC",
        "AirportName": "Crystal Airport",
        "City": "Crystal",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1588,
        "AirportCode": "23M",
        "AirportName": "Clarke County Airport",
        "City": "Quitman",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1589,
        "AirportCode": "DBN",
        "AirportName": "W H 'Bud' Barron Airport",
        "City": "Dublin",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1590,
        "AirportCode": "CVO",
        "AirportName": "Corvallis Municipal Airport",
        "City": "Corvallis",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1591,
        "AirportCode": "CWT",
        "AirportName": "Cowra Airport",
        "City": "Chatsworth",
        "State": "NSW",
        "Country": "AU"
      },
      {
        "Id": 1592,
        "AirportCode": "OGD",
        "AirportName": "Ogden Hinckley Airport",
        "City": "Ogden",
        "State": "UT",
        "Country": "US"
      },
      {
        "Id": 1593,
        "AirportCode": "RKR",
        "AirportName": "Robert S Kerr Airport",
        "City": "Poteau",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1594,
        "AirportCode": "AKO",
        "AirportName": "Colorado Plains Regional Airport",
        "City": "Akron",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1595,
        "AirportCode": "SHN",
        "AirportName": "Sanderson Field",
        "City": "Shelton",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1596,
        "AirportCode": "WNA",
        "AirportName": "Napakiak Airport",
        "City": "Napakiak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1597,
        "AirportCode": "PKA",
        "AirportName": "Napaskiak Airport",
        "City": "Napaskiak",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1598,
        "AirportCode": "2A5",
        "AirportName": "Causey Airport",
        "City": "Liberty",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1599,
        "AirportCode": "DYL",
        "AirportName": "Doylestown Airport",
        "City": "Doylestown",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1600,
        "AirportCode": "OCW",
        "AirportName": "Warren Field",
        "City": "Washington",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1601,
        "AirportCode": "7W6",
        "AirportName": "Hyde County Airport",
        "City": "Engelhard",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1602,
        "AirportCode": "UKF",
        "AirportName": "Wilkes County Airport",
        "City": "North Wilkesboro",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1603,
        "AirportCode": "JZI",
        "AirportName": "Charleston Executive Airport",
        "City": "Charleston",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1604,
        "AirportCode": "DAN",
        "AirportName": "Danville Regional Airport",
        "City": "Danville",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1605,
        "AirportCode": "0V4",
        "AirportName": "Brookneal/Campbell County Airport",
        "City": "Brookneal",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1606,
        "AirportCode": "CQW",
        "AirportName": "Cheraw Municipal Airport/Lynch Bellinger Field",
        "City": "Cheraw",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1607,
        "AirportCode": "HYW",
        "AirportName": "Conway Horry County Airport",
        "City": "Conway",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1608,
        "AirportCode": "HBI",
        "AirportName": "Asheboro Regional Airport",
        "City": "Asheboro",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1609,
        "AirportCode": "EMV",
        "AirportName": "Emporia Greensville Regional Airport",
        "City": "Emporia",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1610,
        "AirportCode": "EMT",
        "AirportName": "El Monte Airport",
        "City": "El Monte",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1611,
        "AirportCode": "I16",
        "AirportName": "Kee Field",
        "City": "Pineville",
        "State": "WV",
        "Country": "US"
      },
      {
        "Id": 1612,
        "AirportCode": "SSF",
        "AirportName": "Stinson Municipal Airport",
        "City": "Stinson",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1613,
        "AirportCode": "JSV",
        "AirportName": "Sallisaw Municipal Airport",
        "City": "Sallisaw",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1614,
        "AirportCode": "JAS",
        "AirportName": "Jasper County Airport-Bell Field",
        "City": "Jasper",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1615,
        "AirportCode": "87K",
        "AirportName": "El Dorado Springs Memorial Airport",
        "City": "El dorado springs",
        "State": "MO",
        "Country": "US"
      },
      {
        "Id": 1616,
        "AirportCode": "MRF",
        "AirportName": "Marfa Municipal Airport",
        "City": "Marfa",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1617,
        "AirportCode": "E38",
        "AirportName": "Alpine Casparis Municipal Airport",
        "City": "Alpine",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1618,
        "AirportCode": "CCB",
        "AirportName": "Cable Airport",
        "City": "Upland",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1619,
        "AirportCode": "IOB",
        "AirportName": "Mount Sterling Montgomery County Airport",
        "City": "Mount Sterling",
        "State": "KY",
        "Country": "US"
      },
      {
        "Id": 1620,
        "AirportCode": "EKI",
        "AirportName": "Elkhart Municipal Airport",
        "City": "Elkhart",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1621,
        "AirportCode": "C03",
        "AirportName": "Nappanee Municipal Airport",
        "City": "Nappanee ",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1622,
        "AirportCode": "CUB",
        "AirportName": "Jim Hamilton L.B. Owens Airport",
        "City": "Columbia",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1623,
        "AirportCode": "GMJ",
        "AirportName": "Grove Municipal Airport",
        "City": "Grove",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1624,
        "AirportCode": "MPR",
        "AirportName": "Mc Pherson Airport",
        "City": "Mc Pherson",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1625,
        "AirportCode": "GYH",
        "AirportName": "Donaldson Center Airport",
        "City": "Greenville",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1626,
        "AirportCode": "PXE",
        "AirportName": "Perry Houston County Airport",
        "City": "Perry",
        "State": "GA",
        "Country": "US"
      },
      {
        "Id": 1627,
        "AirportCode": "HVS",
        "AirportName": "Hartsville Regional Airport",
        "City": "Hartsville",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1628,
        "AirportCode": "IGX",
        "AirportName": "Horace Williams Airport",
        "City": "Chapel Hill",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1629,
        "AirportCode": "SUT",
        "AirportName": "Brunswick County Airport",
        "City": "Oak Island",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1630,
        "AirportCode": "FCI",
        "AirportName": "Chesterfield County Airport",
        "City": "Richmond",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1631,
        "AirportCode": "HNZ",
        "AirportName": "Henderson Oxford Airport",
        "City": "Oxford",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1632,
        "AirportCode": "LEE",
        "AirportName": "Leesburg International Airport",
        "City": "Leesburg",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1633,
        "AirportCode": "CNO",
        "AirportName": "Chino Airport",
        "City": "Chino",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1634,
        "AirportCode": "UYF",
        "AirportName": "Madison County Airport",
        "City": "London",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1635,
        "AirportCode": "PRB",
        "AirportName": "Paso Robles Municipal Airport",
        "City": "Paso Robles",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1636,
        "AirportCode": "HAF",
        "AirportName": "Half Moon Bay Airport",
        "City": "Half Moon Bay",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1637,
        "AirportCode": "WJF",
        "AirportName": "General WM J Fox Airfield",
        "City": "Lancaster",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1638,
        "AirportCode": "TDF",
        "AirportName": "Person County Airport",
        "City": "Roxboro",
        "State": "NC",
        "Country": "US"
      },
      {
        "Id": 1639,
        "AirportCode": "ASN",
        "AirportName": "Talladega Municipal Airport",
        "City": "Talladega",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1640,
        "AirportCode": "MKT",
        "AirportName": "Mankato Regional Airport",
        "City": "Mankato",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1641,
        "AirportCode": "TOI",
        "AirportName": "Troy Municipal Airport",
        "City": "Troy",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1642,
        "AirportCode": "SCD",
        "AirportName": "Merkel Field Sylacauga Municipal Airport",
        "City": "Sylacauga",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1643,
        "AirportCode": "EDN",
        "AirportName": "Enterprise Municipal Airport",
        "City": "Enterprise",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1644,
        "AirportCode": "ALX",
        "AirportName": "Thomas C Russell Field",
        "City": "Alexander City",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1645,
        "AirportCode": "UWL",
        "AirportName": "New Castle Henry Co. Municipal Airport",
        "City": "New Castle",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1646,
        "AirportCode": "TQK",
        "AirportName": "Scott City Municipal Airport",
        "City": "Scott City",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1647,
        "AirportCode": "HDE",
        "AirportName": "Brewster Field",
        "City": "Holdredge",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1648,
        "AirportCode": "PTT",
        "AirportName": "Pratt Regional Airport",
        "City": "Pratt",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1649,
        "AirportCode": "AHQ",
        "AirportName": "Wahoo Municipal Airport",
        "City": "Wahoo",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1650,
        "AirportCode": "LXN",
        "AirportName": "Jim Kelly Field",
        "City": "Lexington",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1651,
        "AirportCode": "19S",
        "AirportName": "Sublette Municipal Airport",
        "City": "Sublette",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1652,
        "AirportCode": "CBF",
        "AirportName": "Council Bluffs Municipal Airport",
        "City": "Council Bluffs",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1653,
        "AirportCode": "OKK",
        "AirportName": "Kokomo Municipal Airport",
        "City": "Kokomo",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1654,
        "AirportCode": "2K7",
        "AirportName": "Neodesha Municipal Airport",
        "City": "Neodesha",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1655,
        "AirportCode": "EHA",
        "AirportName": "Elkhart Morton County Airport",
        "City": "Elkhart",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1656,
        "AirportCode": "FTG",
        "AirportName": "Front Range Airport",
        "City": "Denver",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1657,
        "AirportCode": "GBG",
        "AirportName": "Galesburg Municipal Airport",
        "City": "Galesburg",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1658,
        "AirportCode": "GUY",
        "AirportName": "Guymon Municipal Airport",
        "City": "Guymon",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1659,
        "AirportCode": "MEJ",
        "AirportName": "Meade Municipal Airport",
        "City": "Meade",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1660,
        "AirportCode": "ULS",
        "AirportName": "Ulysses Airport",
        "City": "Ulysses",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1661,
        "AirportCode": "IDP",
        "AirportName": "Independence Municipal Airport",
        "City": "Independence",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1662,
        "AirportCode": "3AU",
        "AirportName": "Augusta Municipal Airport",
        "City": "Augusta",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1663,
        "AirportCode": "LQR",
        "AirportName": "Larned Pawnee County Airport",
        "City": "Larned",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1664,
        "AirportCode": "LZZ",
        "AirportName": "Lampasas Airport",
        "City": "Lampasas",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1665,
        "AirportCode": "BYY",
        "AirportName": "Bay City Municipal Airport",
        "City": "Bay City",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1666,
        "AirportCode": "PRX",
        "AirportName": "Cox Field",
        "City": "Paris",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1667,
        "AirportCode": "CFV",
        "AirportName": "Coffeyville Municipal Airport",
        "City": "Coffeyville",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1668,
        "AirportCode": "GXY",
        "AirportName": "Greeley–Weld County Airport",
        "City": "Greeley",
        "State": "CO",
        "Country": "US"
      },
      {
        "Id": 1669,
        "AirportCode": "OEL",
        "AirportName": "Oryol Yuzhny Airport",
        "City": "Oakley",
        "State": "Oryol Oblast",
        "Country": "RU"
      },
      {
        "Id": 1670,
        "AirportCode": "FET",
        "AirportName": "Fremont Municipal Airport",
        "City": "Fremont",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1671,
        "AirportCode": "LGD",
        "AirportName": "La Grande/Union County Airport",
        "City": "La Grande",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1672,
        "AirportCode": "MPO",
        "AirportName": "Pocono Mountains Municipal Airport",
        "City": "Mount Pocono",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1673,
        "AirportCode": "UKT",
        "AirportName": "Quakertown Airport",
        "City": "Quakertown",
        "State": "PA",
        "Country": "US"
      },
      {
        "Id": 1674,
        "AirportCode": "BNG",
        "AirportName": "Banning Municipal Airport",
        "City": "Banning",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1675,
        "AirportCode": "OFK",
        "AirportName": "Karl Stefan Memorial Airport",
        "City": "Norfolk  Nebraska",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1676,
        "AirportCode": "AWO",
        "AirportName": "Arlington Municipal Airport",
        "City": "Arlington",
        "State": "WA",
        "Country": "US"
      },
      {
        "Id": 1677,
        "AirportCode": "TPF",
        "AirportName": "Peter O Knight Airport",
        "City": "Tampa",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1678,
        "AirportCode": "MTP",
        "AirportName": "Montauk Airport",
        "City": "Montauk",
        "State": "NY",
        "Country": "US"
      },
      {
        "Id": 1679,
        "AirportCode": "VPZ",
        "AirportName": "Porter County Municipal Airport",
        "City": "Valparaiso IN",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1680,
        "AirportCode": "LDM",
        "AirportName": "Mason County Airport",
        "City": "Ludington",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1681,
        "AirportCode": "RHV",
        "AirportName": "Reid-Hillview Airport of Santa Clara County",
        "City": "San Jose",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1682,
        "AirportCode": "WVI",
        "AirportName": "Watsonville Municipal Airport",
        "City": "Watsonville",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1683,
        "AirportCode": "JGG",
        "AirportName": "Williamsburg Jamestown Airport",
        "City": "Williamsburg",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1684,
        "AirportCode": "MRT",
        "AirportName": "Union County Airport",
        "City": "Marysville",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1685,
        "AirportCode": "AXN",
        "AirportName": "Chandler Field",
        "City": "Alexandria",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1686,
        "AirportCode": "CLU",
        "AirportName": "Columbus Municipal Airport",
        "City": "Columbus",
        "State": "IN",
        "Country": "US"
      },
      {
        "Id": 1687,
        "AirportCode": "BBD",
        "AirportName": "Curtis Field",
        "City": "Brady",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1688,
        "AirportCode": "BIH",
        "AirportName": "Eastern Sierra Regional Airport",
        "City": "Bishop",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1689,
        "AirportCode": "BKE",
        "AirportName": "Baker City Municipal Airport",
        "City": "Baker City",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1690,
        "AirportCode": "BPI",
        "AirportName": "Miley Memorial Field",
        "City": "Big Piney",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1691,
        "AirportCode": "WMH",
        "AirportName": "Ozark Regional Airport",
        "City": "Mountain Home",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 1692,
        "AirportCode": "BTL",
        "AirportName": "W K Kellogg Airport",
        "City": "Battle Creek",
        "State": "MI",
        "Country": "US"
      },
      {
        "Id": 1693,
        "AirportCode": "BYI",
        "AirportName": "Burley Municipal Airport",
        "City": "Burley",
        "State": "ID",
        "Country": "US"
      },
      {
        "Id": 1694,
        "AirportCode": "CCY",
        "AirportName": "Northeast Iowa Regional Airport",
        "City": "Charles City",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1695,
        "AirportCode": "CNU",
        "AirportName": "Chanute Martin Johnson Airport",
        "City": "Chanute",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1696,
        "AirportCode": "CRG",
        "AirportName": "Jacksonville Executive at Craig Airport",
        "City": "Jacksonville",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1697,
        "AirportCode": "CSV",
        "AirportName": "Crossville Memorial Whitson Field",
        "City": "Crossville",
        "State": "TN",
        "Country": "US"
      },
      {
        "Id": 1698,
        "AirportCode": "DAA",
        "AirportName": "Davison Army Air Field",
        "City": "Fort Belvoir",
        "State": "VA",
        "Country": "US"
      },
      {
        "Id": 1699,
        "AirportCode": "DAG",
        "AirportName": "Barstow Daggett Airport",
        "City": "Daggett",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1700,
        "AirportCode": "DMN",
        "AirportName": "Deming Municipal Airport",
        "City": "Deming",
        "State": "NM",
        "Country": "US"
      },
      {
        "Id": 1701,
        "AirportCode": "DRA",
        "AirportName": "Desert Rock Airport",
        "City": "Mercury",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1702,
        "AirportCode": "EED",
        "AirportName": "Needles Airport",
        "City": "Needles",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1703,
        "AirportCode": "EGI",
        "AirportName": "Duke Field",
        "City": "Crestview",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1704,
        "AirportCode": "HYI",
        "AirportName": "San Marcos Municipal Airport",
        "City": "San Marcos",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1705,
        "AirportCode": "HYR",
        "AirportName": "Sawyer County Airport",
        "City": "Hayward",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1706,
        "AirportCode": "JCT",
        "AirportName": "Kimble County Airport",
        "City": "Junction",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1707,
        "AirportCode": "LLQ",
        "AirportName": "Monticello Municipal Ellis Field",
        "City": "Monticello",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 1708,
        "AirportCode": "LOL",
        "AirportName": "Derby Field",
        "City": "Lovelock",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1709,
        "AirportCode": "MBG",
        "AirportName": "Mobridge Municipal Airport",
        "City": "Mobridge",
        "State": "SD",
        "Country": "US"
      },
      {
        "Id": 1710,
        "AirportCode": "MCB",
        "AirportName": "Mc Comb/Pike County Airport/John E Lewis Field",
        "City": "Mc Comb",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1711,
        "AirportCode": "MDH",
        "AirportName": "Southern Illinois Airport",
        "City": "Carbondale/Murphysboro",
        "State": "IL",
        "Country": "US"
      },
      {
        "Id": 1712,
        "AirportCode": "MMT",
        "AirportName": "Mc Entire Joint National Guard Base",
        "City": "Eastover",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1713,
        "AirportCode": "NHZ",
        "AirportName": "Brunswick Executive Airport",
        "City": "Brunswick",
        "State": "ME",
        "Country": "US"
      },
      {
        "Id": 1714,
        "AirportCode": "NRB",
        "AirportName": "Naval Station Mayport (Admiral David L. Mcdonald Field)",
        "City": "Mayport",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1715,
        "AirportCode": "OGB",
        "AirportName": "Orangeburg Municipal Airport",
        "City": "Orangeburg",
        "State": "SC",
        "Country": "US"
      },
      {
        "Id": 1716,
        "AirportCode": "OLU",
        "AirportName": "Columbus Municipal Airport",
        "City": "Columbus",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1717,
        "AirportCode": "OTM",
        "AirportName": "Ottumwa Regional Airport",
        "City": "Ottumwa",
        "State": "IA",
        "Country": "US"
      },
      {
        "Id": 1718,
        "AirportCode": "OZR",
        "AirportName": "Cairns AAF (Fort Rucker) Air Field",
        "City": "Fort Rucker/Ozark",
        "State": "AL",
        "Country": "US"
      },
      {
        "Id": 1719,
        "AirportCode": "PWY",
        "AirportName": "Ralph Wenz Field",
        "City": "Pinedale",
        "State": "WY",
        "Country": "US"
      },
      {
        "Id": 1720,
        "AirportCode": "RNH",
        "AirportName": "New Richmond Regional Airport",
        "City": "New Richmond",
        "State": "WI",
        "Country": "US"
      },
      {
        "Id": 1721,
        "AirportCode": "RSL",
        "AirportName": "Russell Municipal Airport",
        "City": "Russell",
        "State": "KS",
        "Country": "US"
      },
      {
        "Id": 1722,
        "AirportCode": "RWF",
        "AirportName": "Redwood Falls Municipal Airport",
        "City": "Redwood Falls",
        "State": "MN",
        "Country": "US"
      },
      {
        "Id": 1723,
        "AirportCode": "SNS",
        "AirportName": "Salinas Municipal Airport",
        "City": "Salinas",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1724,
        "AirportCode": "SOA",
        "AirportName": "Sonora Municipal Airport",
        "City": "Sonora",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1725,
        "AirportCode": "SUZ",
        "AirportName": "Saline County Regional Airport",
        "City": "Benton",
        "State": "AR",
        "Country": "US"
      },
      {
        "Id": 1726,
        "AirportCode": "TPH",
        "AirportName": "Tonopah Airport",
        "City": "Tonopah",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1727,
        "AirportCode": "UAO",
        "AirportName": "Aurora State Airport",
        "City": "Aurora",
        "State": "OR",
        "Country": "US"
      },
      {
        "Id": 1728,
        "AirportCode": "UKI",
        "AirportName": "Ukiah Municipal Airport",
        "City": "Ukiah",
        "State": "CA",
        "Country": "US"
      },
      {
        "Id": 1729,
        "AirportCode": "UOX",
        "AirportName": "University Oxford Airport",
        "City": "Oxford",
        "State": "MS",
        "Country": "US"
      },
      {
        "Id": 1730,
        "AirportCode": "HTV",
        "AirportName": "Huntsville Regional Airport",
        "City": "Huntsville",
        "State": "TX",
        "Country": "US"
      },
      {
        "Id": 1731,
        "AirportCode": "VTN",
        "AirportName": "Miller Field",
        "City": "Valentine",
        "State": "NE",
        "Country": "US"
      },
      {
        "Id": 1732,
        "AirportCode": "WMC",
        "AirportName": "Winnemucca Municipal Airport",
        "City": "Winnemucca",
        "State": "NV",
        "Country": "US"
      },
      {
        "Id": 1733,
        "AirportCode": "WWR",
        "AirportName": "West Woodward Airport",
        "City": "Woodward",
        "State": "OK",
        "Country": "US"
      },
      {
        "Id": 1734,
        "AirportCode": "XMR",
        "AirportName": "Cape Canaveral AFS Skid Strip",
        "City": "Cocoa Beach",
        "State": "FL",
        "Country": "US"
      },
      {
        "Id": 1735,
        "AirportCode": "ZZV",
        "AirportName": "Zanesville Municipal Airport",
        "City": "Zanesville",
        "State": "OH",
        "Country": "US"
      },
      {
        "Id": 1736,
        "AirportCode": "ENN",
        "AirportName": "Nenana Municipal Airport",
        "City": "Nenana",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1737,
        "AirportCode": "WWA",
        "AirportName": "Wasilla Airport",
        "City": "Wasilla",
        "State": "AK",
        "Country": "US"
      },
      {
        "Id": 1738,
        "AirportCode": "CEK",
        "AirportName": "Mezhdunarodnyy Aeroport Chelyabinsk",
        "City": "Balandino",
        "State": "Chelyabinsk Oblast",
        "Country": "RU"
      },
      {
        "Id": 1739,
        "AirportCode": "HRK",
        "AirportName": "International Airport Kharkiv",
        "City": "Kharkiv",
        "State": "Kharkivs'ka oblast",
        "Country": "UA"
      },
      {
        "Id": 1740,
        "AirportCode": "ODS",
        "AirportName": "Odessa International Airport",
        "City": "Odesa",
        "State": "Odessa Oblast",
        "Country": "UA"
      },
      {
        "Id": 1741,
        "AirportCode": "OZH",
        "AirportName": "International Airport Zaporizhzhya",
        "City": "Shevchenkovskiy raion",
        "State": "Zaporiz'ka oblast",
        "Country": "UA"
      },
      {
        "Id": 1742,
        "AirportCode": "YQM",
        "AirportName": "Greater Moncton Roméo LeBlanc International Airport",
        "City": "Dieppe",
        "State": "NB",
        "Country": "CA"
      },
      {
        "Id": 1743,
        "AirportCode": "PIS",
        "AirportName": "Poitiers–Biard Airport",
        "City": "Biard",
        "State": "Nouvelle-Aquitaine",
        "Country": "FR"
      },
      {
        "Id": 1744,
        "AirportCode": "RSA",
        "AirportName": "Santa Rosa Airport",
        "City": "Santa Rosa",
        "State": "La Pampa",
        "Country": "AR"
      },
      {
        "Id": 1745,
        "AirportCode": "SZB",
        "AirportName": "Sultan Abdul Aziz Shah Airport",
        "City": "Subang",
        "State": "Selangor",
        "Country": "MY"
      },
      {
        "Id": 1746,
        "AirportCode": "NYM",
        "AirportName": "Nadym Airport",
        "City": "Yamalo-Nenets Autonomous Okrug",
        "State": "Ямало-Ненецкий Аутономус Область",
        "Country": "RU"
      },
      {
        "Id": 1747,
        "AirportCode": "BAX",
        "AirportName": "Barnaul International Airport",
        "City": "Barnaul",
        "State": "Altayskiy kray",
        "Country": "RU"
      },
      {
        "Id": 1748,
        "AirportCode": "REL",
        "AirportName": "Almirante Marcos A. Zar Airport",
        "City": "",
        "State": "Chubut Province",
        "Country": "Argentina"
      },
      {
        "Id": 1749,
        "AirportCode": "IGU",
        "AirportName": "Foz do Iguaçu International Airport",
        "City": "Foz do Iguaçu",
        "State": "PR",
        "Country": "BR"
      },
      {
        "Id": 1750,
        "AirportCode": "AJU",
        "AirportName": "Santa Maria Airport",
        "City": "Aeroporto",
        "State": "SE",
        "Country": "Brazil"
      },
      {
        "Id": 1751,
        "AirportCode": "COR",
        "AirportName": "Ingeniero Aeronáutico Ambrosio L.V. Taravella International Airport",
        "City": "Córdoba",
        "State": "Córdoba",
        "Country": "AR"
      },
      {
        "Id": 1752,
        "AirportCode": "PUY",
        "AirportName": "Pula Airport",
        "City": "Pula",
        "State": "Istarska županija",
        "Country": "HR"
      },
      {
        "Id": 1753,
        "AirportCode": "GEL",
        "AirportName": "Aeroporto Regional de Santo Ângelo - Sepé Tiaraju",
        "City": "Zona Rural",
        "State": "RS",
        "Country": "Brazil"
      },
      {
        "Id": 1754,
        "AirportCode": "CAC",
        "AirportName": "Cascavel Airport",
        "City": "Santos Dumont",
        "State": "PR",
        "Country": "Brazil"
      },
      {
        "Id": 1755,
        "AirportCode": "PMY",
        "AirportName": "El Tehuelche Airport",
        "City": "Puerto Madryn",
        "State": "Chubut",
        "Country": "AR"
      },
      {
        "Id": 1756,
        "AirportCode": "MGF",
        "AirportName": "Maringa Regional Airport",
        "City": "Parque Industrial Mario Bulhoes",
        "State": "PR",
        "Country": "BR"
      },
      {
        "Id": 1757,
        "AirportCode": "CPV",
        "AirportName": "Campina Grande Airport",
        "City": "Distrito Industrial",
        "State": "PB",
        "Country": "BR"
      },
      {
        "Id": 1758,
        "AirportCode": "SUF",
        "AirportName": "Lamezia Terme International Airport",
        "City": "Lamezia Terme",
        "State": "Calabria",
        "Country": "IT"
      },
      {
        "Id": 1759,
        "AirportCode": "KGS",
        "AirportName": "Aéroport International de l'île de Kos",
        "City": "Kos",
        "State": "Greece",
        "Country": "GR"
      },
      {
        "Id": 1760,
        "AirportCode": "DUD",
        "AirportName": "Dunedin International Airport",
        "City": "Momona",
        "State": "Otago",
        "Country": "NZ"
      },
      {
        "Id": 1761,
        "AirportCode": "LDB",
        "AirportName": "Londrina Airport",
        "City": "Bairro Aeroporto",
        "State": "PR",
        "Country": "Brazil"
      },
      {
        "Id": 1762,
        "AirportCode": "PNZ",
        "AirportName": "Petrolina Airport - Senator Nilo Coelho",
        "City": "Zona Rural",
        "State": "PE",
        "Country": "Brazil"
      },
      {
        "Id": 1763,
        "AirportCode": "RIA",
        "AirportName": "Aeroporto Municipal de Santa Maria",
        "City": "Camobi",
        "State": "RS",
        "Country": "Brazil"
      },
      {
        "Id": 1764,
        "AirportCode": "BFS",
        "AirportName": "Belfast International Airport",
        "City": "Belfast",
        "State": "Crumlin",
        "Country": "Ireland"
      },
      {
        "Id": 1765,
        "AirportCode": "YQQ",
        "AirportName": "Comox Valley Airport",
        "City": "Comox",
        "State": "BC",
        "Country": "Canada"
      },
      {
        "Id": 1766,
        "AirportCode": "FAO",
        "AirportName": "Faro Airport",
        "City": "Faro",
        "State": "Faro District",
        "Country": "Portugal"
      },
      {
        "Id": 1767,
        "AirportCode": "SJP",
        "AirportName": "Aeroporto Estadual de São José do Rio Preto",
        "City": "Vila Aeroporto",
        "State": "SP",
        "Country": "BR"
      },
      {
        "Id": 1768,
        "AirportCode": "YYT",
        "AirportName": "St. John's International Airport",
        "City": "St. John's",
        "State": "NL",
        "Country": "CA"
      },
      {
        "Id": 1769,
        "AirportCode": "FMO",
        "AirportName": "Münster Osnabrück International Airport",
        "City": "Greven",
        "State": "NRW",
        "Country": "Germany"
      },
      {
        "Id": 1770,
        "AirportCode": "BLL",
        "AirportName": "Billund Airport",
        "City": "Billund",
        "State": "DK",
        "Country": "Denmark"
      },
      {
        "Id": 1771,
        "AirportCode": "PRA",
        "AirportName": "Aeropuerto General Justo José de Urquiza",
        "City": "",
        "State": "Entre Ríos",
        "Country": "AR"
      },
      {
        "Id": 1772,
        "AirportCode": "MDQ",
        "AirportName": "Astor Piazzolla International Airport",
        "City": "Mar del Plata",
        "State": "Provincia de Buenos Aires",
        "Country": "AR"
      },
      {
        "Id": 1773,
        "AirportCode": "BEL",
        "AirportName": "Val de Cans International Airport",
        "City": "Val-de-Cans",
        "State": "PA",
        "Country": "BR"
      },
      {
        "Id": 1774,
        "AirportCode": "YQX",
        "AirportName": "Gander International Airport",
        "City": "Gander",
        "State": "NL",
        "Country": "CA"
      },
      {
        "Id": 1775,
        "AirportCode": "PNA",
        "AirportName": "Pamplona Airport",
        "City": "Noáin",
        "State": "NC",
        "Country": "ES"
      },
      {
        "Id": 1776,
        "AirportCode": "OAG",
        "AirportName": "Orange Regional Airport",
        "City": "Orange",
        "State": "NSW",
        "Country": "AU"
      },
      {
        "Id": 1777,
        "AirportCode": "GRX",
        "AirportName": "Federico García Lorca Granada Airport",
        "City": "Chauchina",
        "State": "AN",
        "Country": "ES"
      },
      {
        "Id": 1778,
        "AirportCode": "PMW",
        "AirportName": "Palmas–Brigadeiro Lysias Rodrigues Airport",
        "City": "Plano Diretor Expansão Sul",
        "State": "TO",
        "Country": "BR"
      },
      {
        "Id": 1779,
        "AirportCode": "CHC",
        "AirportName": "Christchurch International Airport",
        "City": "Christchurch",
        "State": "Canterbury",
        "Country": "NZ"
      },
      {
        "Id": 1780,
        "AirportCode": "VNO",
        "AirportName": "Vilnius International Airport",
        "City": "Vilnius",
        "State": "Vilniaus apskr.",
        "Country": "LT"
      },
      {
        "Id": 1781,
        "AirportCode": "BGY",
        "AirportName": "Milan Bergamo Airport",
        "City": "Milan",
        "State": "Lombardy",
        "Country": "IT"
      },
      {
        "Id": 1782,
        "AirportCode": "THE",
        "AirportName": "Teresina Airport",
        "City": "Aeroporto",
        "State": "PI",
        "Country": "BR"
      },
      {
        "Id": 1783,
        "AirportCode": "AAR",
        "AirportName": "Aarhus Airport",
        "City": "Kolind",
        "State": "",
        "Country": "DK"
      },
      {
        "Id": 1784,
        "AirportCode": "MTT",
        "AirportName": "Minatitlan International Airport",
        "City": "Cosoleacaque",
        "State": "Ver.",
        "Country": "MX"
      }
    ];
  }
}
