import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";
import { PreviewTimelineComponent } from "../../../../shared/preview-timeline/preview-timeline.component";
import { TrialService } from "../../../../core/services/trial.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { VideoUploadComponent } from "../../../../shared/video-upload/video-upload.component";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { environment } from "../../../../../environments/environment";
import { TimelineEvent } from "../../../../core/models/TimelineEvent";
import { TrialInfo } from "../../../../core/models/TrialInfo";
import { TrialUpdateTimelineEvent } from '../../../../core/services/interfaces/trail-updatetimelinevent.interface';
import { Cultures } from 'app/core/constants/cultures';
import { TrialUpdateTrialInfo } from "../../../../core/services/interfaces/trial-updatetrialinfo.interface";

@Component({
  selector: 'app-trial-info',
  templateUrl: './trial-info.component.html',
  styleUrls: ['./trial-info.component.scss']
})
export class TrialInfoComponent implements OnInit, AfterContentInit {
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild('timelinePreview') timelinePreview: PreviewTimelineComponent;
  @ViewChild('removeTimelineEventModal') removeTimelineEventModal: ModalComponent;
  @ViewChild('unsavedChangedModal') unsavedChangedModal: ModalComponent;
  @ViewChild('timelineDate') timelineDate: ElementRef;
  @ViewChild('timelineEventModal') timelineEventModal: ModalComponent;
  @ViewChild('removeTimelineAttachmentModal') removeTimelineAttachmentModal: ModalComponent;
  @ViewChild('uploadImageInput') uploadImageInput: ElementRef;
  @ViewChild('videoUpload') videoUpload: VideoUploadComponent;
  @ViewChild('timelineBoxType') timelineBoxType: DropdownInputComponent;

  @Output('updated') updated = new EventEmitter<boolean>();

  @Input('trialId') trialId: string;
  @Input('startDate') startDate: Date;
  @Input('endDate') endDate: Date;
  @Input('timelineEnabled') timelineEnabled: boolean;
  @Input('trialInfoEnabled') trialInfoEnabled: boolean;

  form: UntypedFormGroup;
  isProcessing = false;
  changed = false;
  availableCultures: { value: string, text: string }[] = [];
  boxTypes: { value: string, text: string }[] = [];

  timelineForm: UntypedFormGroup;
  timelineFormProcessing = false;
  timelineAttachment: { data: Blob, filename: string, imageUrl: any } = null;

  cultureForm: UntypedFormGroup;
  selectedCulture = 'en';

  trialInfoChanged = false;
  trialInfoLoaded = false;
  trialInfoList: TrialInfo[] = [];
  eventList: TimelineEvent[] = [];

  timelineEventToDelete: TimelineEvent = null;

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    Cultures.all().forEach(culture => {
      this.availableCultures.push({ value: culture.culture, text: culture.name });
    });

    this.boxTypes.push({ value: 'Illingworth', text: 'Illingworth' });
    this.boxTypes.push({ value: 'Manufacturer', text: 'Manufacturer' });
    this.boxTypes.push({ value: 'Patient', text: 'Patient' });

    this.form = new UntypedFormGroup({
      selectedCulture: new UntypedFormControl('en'),
      startDate: new UntypedFormControl(''),
      endDate: new UntypedFormControl(''),
      timelineEnabled: new UntypedFormControl(false),
      trialInfoEnabled: new UntypedFormControl(false)
    });

    this.cultureForm = new UntypedFormGroup({
      trialInfoId: new UntypedFormControl(''),
      culture: new UntypedFormControl('en', Validators.required),
      videoFilename: new UntypedFormControl(''),
      published: new UntypedFormControl(false),
      information: new UntypedFormControl('', Validators.required)
    });

    this.timelineForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      title: new UntypedFormControl('Add Timeline Event'),
      buttonLabel: new UntypedFormControl('Add Event'),
      culture: new UntypedFormControl('en', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      heading: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
      boxType: new UntypedFormControl('', Validators.required),
      imageFilename: new UntypedFormControl('')
    });
  }

  ngAfterContentInit() {
    this.form.patchValue({
      startDate: this.startDate !== null ? moment(this.startDate).format('DD/MM/YYYY') : '',
      endDate: this.endDate !== null ? moment(this.endDate).format('DD/MM/YYYY') : '',
      timelineEnabled: this.timelineEnabled,
      trialInfoEnabled: this.trialInfoEnabled
    });

    // Mark form as changed if any of the fields are changed
    this.form.valueChanges.subscribe(changes => {
      this.changed = true;
    });

    // When trial info checkbox changes, update the local variable
    this.form.get('trialInfoEnabled').valueChanges.subscribe(enabled => {
      this.trialInfoEnabled = enabled;
    });

    // When the user changes the culture, update the culture on the timeline modal
    this.form.get('selectedCulture').valueChanges.subscribe(culture => {
      this.timelineForm.patchValue({ culture: culture });
      this.changed = true;
      this.setCulture(culture);
    });

    this.cultureForm.valueChanges.subscribe(changes => {
      this.trialInfoChanged = true;
    });

    setTimeout(() => {
      this.loadTrialInfo();
    }, 1000);
  }

  findTrialInfoByCulture(culture: string): TrialInfo {
    return this.trialInfoList.find(trialInfo => trialInfo.culture === culture);
  }

  dateChanged(field: string): void {
    this.changed = true;
  }

  /**
   * When called it will render the trial info/timeline for the selected culture
   * @param culture
   */
  setCulture(culture: string) {
    let trialInfo: TrialInfo | null | undefined = this.findTrialInfoByCulture(culture);
    if (trialInfo === null || trialInfo === undefined) {
      let englishTrialInfo = this.findTrialInfoByCulture('en');

      trialInfo = new TrialInfo();
      trialInfo.events = englishTrialInfo.events;
      trialInfo.culture = culture;
      trialInfo.information = englishTrialInfo.information;
      trialInfo.published = false;
      trialInfo.videoUrl = englishTrialInfo.videoUrl;
      trialInfo.videoFilename = englishTrialInfo.videoFilename;

      trialInfo.events.forEach(event => {
        event.state = 'new';
      });

      this.alertService.showMultiLineSuccessAlert("New Language Selected", "The selected language has been populated with English translations to aid translation.");
    }

    this.cultureForm.patchValue({
      culture: culture,
      published: trialInfo.published,
      videoFilename: trialInfo.videoFilename,
      information: trialInfo.information,
      trialInfoId: trialInfo.trialInfoId
    });
    this.cultureForm.valueChanges.subscribe(changes => {
      this.trialInfoChanged = true;
      this.changed = true;
    });
    this.videoUpload.setVideoUrl(trialInfo.videoUrl);
    this.eventList = trialInfo.events;

    this.trialInfoLoaded = true;
    this.trialInfoChanged = false;
  }


  loadTrialInfo() {
    if (this.trialId !== null) {
      this.trialService.trialInfo(this.trialId).subscribe({
        next: (trialInfoList) => {
          this.trialInfoList = trialInfoList;

          // Render english on screen first
          this.setCulture('en');

          this.videoUpload.stateChange.subscribe(state => {
            if (state === 'has-video') {
              this.trialInfoChanged = true;
              this.changed = true;
            }
          });
        },
        error: (err) => {
          this.alertService.showWarningAlert(err.error ? err.error.title : 'Something went wrong');
        }
      });
    }
  }

  /**
   * Actioned when the user clicks on the add image button, it forces the browsers browse files window to open
   */
  onAddImage() {
    this.uploadImageInput.nativeElement.click();
  }

  onRemoveAttachment() {
    this.removeTimelineAttachmentModal.show();
  }

  onConfirmRemoveTimelineAttachment() {
    this.timelineAttachment = null;
    this.timelineForm.patchValue({ imageFilename: '' });
    this.removeTimelineAttachmentModal.hide();
  }

  /**
   * Actioned when the user selects an image to upload and handles grabbing the data for the image preview
   * @param files
   */
  preview(files) {
    if (files.length === 0)
      return;

    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    // Generate a UUID filename
    let ext = files[0].name.split('.').pop();
    let filename = uuidv4().replace(/-/gi, '') + '.' + ext.toLowerCase();

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.timelineAttachment = { data: files[0], filename: filename, imageUrl: reader.result };
      this.timelineForm.patchValue({ imageFilename: filename });
      this.uploadImageInput.nativeElement.value = '';
    }
  }

  /**
   * Actioned when a user clicks on the edit event button, it will open the modal to edit the event
   * @param event
   */
  onEditTimelineEvent(event: TimelineEvent) {
    this.timelineForm.reset();
    this.timelineForm.patchValue({
      id: event.id,
      heading: event.heading,
      description: event.description,
      boxType: event.boxType,
      culture: this.form.get('selectedCulture').value,
      title: 'Edit Timeline Event',
      buttonLabel: 'Update Event',
      date: event.date,
      imageFilename: event.imageFilename,
      imageUrl: event.imageUrl
    });

    this.timelineAttachment = { data: null, filename: event.imageFilename, imageUrl: event.imageUrl };
    this.timelineBoxType.setValue(event.boxType);
    this.timelineEventModal.show();
  }

  onShowAddTimelineEventModal() {
    this.timelineForm.reset();
    this.timelineBoxType.reset();
    this.timelineForm.patchValue({ culture: this.form.get('selectedCulture').value, title: 'Add Timeline Event', buttonLabel: 'Add Event' });
    this.timelineEventModal.show();
  }

  // Required to update the form group with values from the data input
  updateTimelineFormValues() {
    this.timelineForm.patchValue({
      date: this.timelineDate.nativeElement.value,
    });
  }

  onDropdownClick() {
    if (this.trialInfoChanged) {
      this.unsavedChangedModal.show();
    }
  }

  onDeleteTimelineEvent(event: TimelineEvent) {
    this.removeTimelineEventModal.show();
    this.timelineEventToDelete = event;
  }

  onConfirmDeleteTimelineEvent() {
    let event = this.eventList.find(event => event.id === this.timelineEventToDelete.id);
    event.state = 'deleted';

    this.timelineEventToDelete = null;
    this.removeTimelineEventModal.hide();
    this.trialInfoChanged = true;
    this.changed = true;
  }

  onVideoInvalid(message: string) {
    this.alertService.showWarningAlert(message);
  }

  /**
   * Actioned when the user clicks on the save timeline event button when adding or editing a timeline event
   */
  onSaveTimelineEvent() {
    let form = this.timelineForm;

    if (form.valid) {
      this.timelineFormProcessing = true;

      if (this.timelineAttachment == null || this.timelineAttachment.data === null) {
        // Add the timeline event, but don't upload the image as either one hasn't been uploaded or already exists
        this.completeSaveTimelineEvent(form);
      } else {
        // Upload the image and then add the timeline event
        this.trialService.uploadTimelineEventImage(this.timelineAttachment.data).subscribe({
          next: (rsp: any) => {
            this.timelineForm.patchValue({ imageFilename: rsp.fileName });

            this.completeSaveTimelineEvent(form);
          },
          error: (error) => {
            this.alertService.showWarningAlert('Failed to upload image');
            this.timelineFormProcessing = false;
          }
        });
      }
    }
  }

  /**
   * Called by the 'onSaveTimelineEvent' method when the image has been uploaded
   * @param form
   * @private
   */
  private completeSaveTimelineEvent(form: UntypedFormGroup) {
    // Create the timeline event
    let id = form.get('id').value;
    let newEvent = id === null || id === '';

    // We don't create the timeline event, instead we store it locally until the user saves the trial
    let event = newEvent ? new TimelineEvent() : this.eventList.find(e => e.id === id);
    event.heading = form.get('heading').value;
    event.description = form.get('description').value;
    event.boxType = form.get('boxType').value;
    event.imageFilename = form.get('imageFilename').value;
    if (event.imageFilename !== null && event.imageFilename !== '') {
      event.imageUrl = environment.timelineImageBaseUrl + event.imageFilename;
    }
    event.originalDate = moment(form.get('date').value, 'DD/MM/YYYY').toDate();
    event.date = moment(event.originalDate).format('DD.MM.YYYY');

    if (newEvent) {
      event.id = uuidv4().replace(/-/gi, '');
      event.state = 'new'
      this.eventList.push(event);
    } else {
      event.state = 'modified';
    }

    // Sort events by date
    this.eventList.sort((b, a) => new Date(b.originalDate).getTime() - new Date(a.originalDate).getTime());

    this.changed = true;
    this.trialInfoChanged = true;
    this.timelineFormProcessing = false;
    this.timelineEventModal.hide();
  }

  /**
   * Actioned when the user clicks on the save button, it saves the trial info
   */
  onSave() {
    if (this.changed && this.cultureForm.valid) {
      this.isProcessing = true;

      let videoFilename = '';
      if (this.cultureForm.get('videoFilename').value !== null && this.cultureForm.get('videoFilename').value !== '') {
        let videoFilenameParts = this.cultureForm.get('videoFilename').value.split('/');
        videoFilename = videoFilenameParts[videoFilenameParts.length - 1];
      }

      let startDate = this.startDateInput.nativeElement.value;
      let endDate = this.endDateInput.nativeElement.value;

      // If culture is EN, then it's always published and cannot be unpublished
      let published = this.cultureForm.get('culture').value == 'en' ? true : this.cultureForm.get('published').value;

      const updateTrialInfo: TrialUpdateTrialInfo = {
        startDate: startDate,
        endDate: endDate,
        timelineEnabled: this.form.get('timelineEnabled').value,
        trialInfoEnabled: this.form.get('trialInfoEnabled').value,
        videoFilename: videoFilename,
        information: this.cultureForm.get('information').value,
        published: published
      };

      // Update start/end date etc
      this.trialService.updateTrialInfo(this.trialId, this.cultureForm.get('culture').value, updateTrialInfo).subscribe({
        next: (trialInfo: TrialInfo) => {
          this.updated.emit(true);
          this.isProcessing = false;
          this.alertService.showSuccessAlert('Trial info has been successfully updated');

          this.form.patchValue({id: trialInfo.trialInfoId});

          this.eventList.forEach(event => {
            switch (event.state) {
              case 'new':
                this.createTimelineEvent(this.cultureForm.get('culture').value, event);
                break;
              case 'modified':
                this.updateTimelineEvent(this.cultureForm.get('culture').value, event);
                break;
              case 'deleted':
                this.removeTimelineEvent(this.cultureForm.get('culture').value, event);
                break;
            }
          });

          this.trialInfoChanged = false;
          this.changed = false;
          this.unsavedChangedModal.hide();
        },
        error: (error) => {
          this.isProcessing = false;
          this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was problem updating the trial inf');
        }
      });

    }
  }

  /**
   * Actioned during the save process, it creates a new timeline event on the server
   * @param culture
   * @param event
   * @private
   */
  private createTimelineEvent(culture: string, event: TimelineEvent) {
    this.trialService.createTimelineEvent(this.trialId, culture, event.date, event.heading, event.description, event.boxType, event.imageFilename).subscribe({
      next: (rsp: TimelineEvent) => {
        this.eventList.find(e => e.id === event.id).id = rsp.id;
        this.eventList.find(e => e.id === event.id).state = 'unchanged';
      },
      error: (error) => {
      }
    });
  }

  /**
   * Actioned during the save process, it updates a timeline event on the server
   * @param culture
   * @param event
   * @private
   */
  private updateTimelineEvent(culture: string, event: TimelineEvent) {
    const updateTimelineEvent: TrialUpdateTimelineEvent = {
      culture: culture,
      date: event.date,
      heading: event.heading,
      description: event.description,
      boxType: event.boxType,
      imageFilename: event.imageFilename
    };

    this.trialService.updateTimelineEvent(event.id, this.trialId, updateTimelineEvent).subscribe({
      next: (rsp: TimelineEvent) => {
        let index = this.eventList.findIndex(e => e.id === event.id);
        this.eventList[index] = new TimelineEvent().map(rsp);
      },
      error: (error) => {
      }
    });
  }

  /**
   * Actioned during the save process, it removes a timeline event from the server
   * @param culture
   * @param event
   * @private
   */
  private removeTimelineEvent(culture: string, event: TimelineEvent) {
    if (event.id !== undefined && event.id !== null && event.id !== '') {
      this.trialService.removeTimelineEvent(culture, event.id).subscribe({
        next: (rsp) => {
          this.eventList.splice(this.eventList.indexOf(event), 1);
        },
        error: (error) => {
          this.alertService.showWarningAlert(error.error.title);
        }
      });
    }
  }



}
