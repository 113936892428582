import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateAndLinkSubsidiaryRequest, TrialPaymentDetailsViewModel } from "app/features/trial/trial-details/trial-payments.model";
import { Observable } from "rxjs";
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryService {
  private apiUrl = `${environment.apiUrl}/subsidiary`;

  constructor(private readonly http: HttpClient) { }

  createAndLinkSubsidiary(request: CreateAndLinkSubsidiaryRequest): Observable<TrialPaymentDetailsViewModel> {
    return this.http.post<TrialPaymentDetailsViewModel>(`${this.apiUrl}/create-and-link-subsidiary`, request);
  }
}
