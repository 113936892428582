import { Permissions } from './../../../../../core/constants/permissions';
import { AuthService } from './../../../../../core/services/auth.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteList } from 'app/core/models/site-list.model';
import { SiteService } from 'app/core/services/site.service';
import { TrialService } from 'app/core/services/trial.service';
import { SiteAutocompleteComponent } from 'app/shared/site-autocomplete/site-autocomplete.component';
import { LogHelper } from 'app/core/helpers/log.helper';
import { AlertService } from 'app/shared/alert/alert.service';
import { SiteTrialRelation } from 'app/core/models/site-trial-relation.model';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { SiteAutocomplete } from 'app/shared/site-autocomplete/site-autocomplete.model';
import { TrialConstants } from 'app/core/constants/trial-constants';
import { ManageSiteContactsComponent } from '../manage-site-contacts/manage-site-contacts.component';
import { ObjectHelper } from '../../../../../core/helpers/object.helper';
import { SiteContactService } from '../../../../../core/services/site-contact.service';
import { TrialUpdateRelation } from '../../../../../core/services/interfaces/trial-updaterelation.interface';
import { TrialSiteContact } from '../../../../../core/models/trial-site-contact.model';
import { DateHelper } from '../../../../../core/helpers/date-helper';

@Component({
  selector: 'app-trial-update-site',
  templateUrl: './trial-update-site.component.html',
  styleUrls: ['./trial-update-site.component.scss']
})
export class TrialUpdateSiteComponent implements OnInit, AfterViewInit {
  @ViewChild('trialSiteContacts') trialSiteContacts: ManageSiteContactsComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('approvalStatusSelect') approvalStatusSelect: DropdownInputComponent;
  @ViewChild('introductionMethodSelect') introductionMethodSelect: DropdownInputComponent;
  @ViewChild('declinedReasonSelect') declinedReasonSelect: DropdownInputComponent;
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('introductionDateInput') introductionDateInput: ElementRef

  trialId: string;
  form: UntypedFormGroup;
  isFormProcessing = false;
  trialSites = new SiteList();
  sites = new SiteList();
  siteOptions: { value: string, text: string, line1: string, line2: string }[] = [];
  relation = new SiteTrialRelation();
  selectedTab = 'site-info';
  hideActionButtons = false;
  submitButtonDisabled = false;

  introductionMethodOptions = TrialConstants.introductionMethodOptions;
  approvalStatusOptions = TrialConstants.approvalStatusOptions;
  declinedReasonOptions = TrialConstants.declinedReasonOptions;

  assignedContacts: TrialSiteContact[] = [];
  Permissions = Permissions;

  constructor(private readonly siteService: SiteService, private readonly activatedRoute: ActivatedRoute, private readonly alertService: AlertService,
    private readonly trialService: TrialService, private readonly router: Router, private siteContactService: SiteContactService,
    public authService: AuthService) {

    this.form = new UntypedFormGroup({
      siteNumber: new UntypedFormControl(),
      site: new UntypedFormControl('', Validators.required),
      introductionMethod: new UntypedFormControl(),
      approvalStatus: new UntypedFormControl(),
      approvalDate: new UntypedFormControl(),
      declinedReason: new UntypedFormControl(),
      declinedText: new UntypedFormControl(),
      introductionOccurred: new UntypedFormControl(false),
      introductionDate: new UntypedFormControl()
    });
  }

  ngOnInit() {
    // Get trial Id from params (url)
    this.activatedRoute.params.subscribe(params => {
      this.trialId = params.trialId;
    });

    // Reload site contacts when the site value changes
    this.form.get('site').valueChanges.subscribe(siteId => {
      this.loadContacts(this.trialId, siteId);
    });
  }

  ngAfterViewInit(): void {
    // Load sites that are assigned to this trial
    this.siteService.retrieveSites(1, this.trialId, null, null, false,9999).subscribe({
      next: trialSiteList => {
        this.trialSites = trialSiteList;

        // Load sites for dropdown
        this.siteService.retrieveSites(1, null, null, null, false, 9999).subscribe({
          next: siteList => {
            this.sites = siteList;

            for (const site of this.sites.results) {
              if (this.showSite(site.id)) {
                const text = site.name + ' / ' + site.address;
                this.siteOptions.push({
                  value: site.id,
                  text: text.substring(0, 30) + ' ...',
                  line1: site.name,
                  line2: site.address + ' ' + site.country
                });
              }
            }

            // Site selected site if present in the URL
            this.activatedRoute.queryParams.subscribe(queryParams => {
              if (queryParams.siteId) {
                this.siteService.retrieveSite(queryParams.siteId).subscribe({
                  next: (rsp) => {
                    const text = rsp.name + ' / ' + rsp.address;
                    this.siteAutocomplete.setInitialValue(rsp.id, text.substring(0, 30) + ' ...');
                    this.form.patchValue({ site: queryParams.siteId });
                  }
                });
              }
            });
          }, error: () => {
            this.alertService.showWarningAlert('Sorry, there was a problem loading sites!');
          }
        });
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem loading assigned sites!');
      }
    });

    this.activatedRoute.params.subscribe(params => {
      this.trialService.getSiteTrialRelation(params.trialId, params.siteId).subscribe({
        next: relation => {
          var dateStr = '';

          // Check to make sure the approval date isn't empty, and that it doesn't have the default date value
          if (relation.approvalDate !== null && !relation.approvalDate.toString().includes('0001')) {
            const date = new Date(relation.approvalDate);
            dateStr = (`00${date.getDate()}`).slice(-2) + '/' + (`00${+date.getMonth() + 1}`).slice(-2) + '/' + date.getFullYear();
          }

          const introductionDateStr = !ObjectHelper.isUndefinedOrNull(relation.introductionDate) ? DateHelper.dateToInputStr(new Date(relation.introductionDate)) : '';

          this.siteAutocomplete.onSelectOption(new SiteAutocomplete(relation.siteId, '', (relation.siteName + ' / ' + relation.siteAddress).substring(0, 30) + ' ...', relation.siteName, relation.siteAddress));
          this.approvalStatusSelect.setValue(relation.approvalStatus.toString());
          relation.introductionMethod > 0 ? this.introductionMethodSelect.setValue(relation.introductionMethod.toString()) : this.introductionMethodSelect.reset();
          relation.declinedReason >= 0 ? this.declinedReasonSelect.setValue(relation.declinedReason.toString()) : this.declinedReasonSelect.reset();

          this.form.patchValue({
            siteNumber: relation.siteNumber,
            approvalDate: dateStr,
            declinedReason: relation.declinedReason,
            declinedText: relation.declinedReasonText,
            introductionOccurred: relation.introductionOccurred,
            introductionDate: relation.introductionOccurred ? introductionDateStr : ''
          });

          // Disable the site number field is API integration is enabled on the trial
          if (relation.trialApiEnabled) {
            this.form.get('siteNumber').disable();
          } else {
            this.form.get('siteNumber').enable();
          }
        },
        error: () => {
          this.alertService.showWarningAlert('Unable to load site/trial relation!');
        }
      });
    });

    if (this.authService.hasPermission(Permissions.SiteContactRead)) {
      this.trialSiteContacts.hideFormButtons.subscribe(hideFormButtons => {
        this.hideActionButtons = hideFormButtons;
      });

      this.trialSiteContacts.assignedContactsChanged.subscribe(contacts => {
        this.assignedContacts = contacts;
      });
    }
  }

  loadContacts(trialId: string, siteId: string) {
    if (ObjectHelper.isUndefinedNullOrEmpty(trialId) || ObjectHelper.isUndefinedNullOrEmpty(siteId) || !this.authService.hasPermission(Permissions.SiteContactRead)) {
      return;
    }

    this.siteContactService.receiveByTrialAndSite(trialId, siteId, 1, 999).subscribe({
      next: (contacts) => {
        this.trialSiteContacts.setAssignedContacts(contacts.results)
      }, error: (error) => {
        LogHelper.log(error);
      }
    });
  }

  showSite(siteId: string) {
    let show = true;
    this.trialSites.results.forEach(site => {
      if (siteId === site.id) {
        show = false;
      }
    });

    return show;
  }

  /**
   * Updates the date in the form when you move your mouse around, a bit of a bodge fix because angular doesn't
   * automatically detect changes
   * Called when an introduction date is selected from the calendar. Will update the form values and disable the submit
   * button until the value has been set to prevent blank dates being submitted.
   */
  onIntroductionDateChanged() {
    this.submitButtonDisabled = true;
    setTimeout(() => {
      if (this.introductionDateInput.nativeElement.value != this.form.get('introductionDate')) {
        this.form.patchValue({ introductionDate: this.introductionDateInput.nativeElement.value });
      }
      this.submitButtonDisabled = false;
    }, 500);
  }
  /**
   * Called when an approved date is selected from the calendar. Will update the form values and disable the submit
   * button until the value has been set to prevent blank dates being submitted.
   */
    onApprovedDateChanged() {
      this.submitButtonDisabled = true;
      setTimeout(() => {
        if (this.dateInput.nativeElement.value !== this.form.get('approvalDate')) {
          this.form.patchValue({ approvalDate: this.dateInput.nativeElement.value });
        }
        if (this.introductionDateInput.nativeElement.value != this.form.get('introductionDate')) {
          this.form.patchValue({ introductionDate: this.introductionDateInput.nativeElement.value });
        }
        this.submitButtonDisabled = false;
      }, 500);
    }

  /**
   * Set the value of site in the form when the dropdown value is changed
   * @param value
   */
  onSiteChanged(value: string) {
    this.form.patchValue({ site: value });
  }

  onIntroductionMethodChanged(introductionMethod: string) {
    this.form.patchValue({ introductionMethod: introductionMethod });
  }

  onApprovalStatusChanged(approvalStatus: string) {
    this.form.patchValue({ approvalStatus: approvalStatus });
    this.form.patchValue({ approvalDate: null });
    this.declinedReasonSelect.reset();
    this.form.patchValue({ declinedText: null });
  }

  onDeclinedReasonChanged(declinedReason: string) {
    this.form.patchValue({ declinedReason: declinedReason });
  }

  onHandleTabClick(selected: string) {
    this.selectedTab = selected;
  }

  onHandleCancel() {
    // A fix to force the datepicker to be hidden when the cancel button it clicked
    this.introductionDateInput.nativeElement.blur();
    setTimeout(() => {
      this.router.navigate(['trial', this.trialId]).then();
    }, 250);
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;
      const updateRelation: TrialUpdateRelation = {
        siteNumber: this.form.get('siteNumber').value,
        introductionMethod: this.form.get('introductionMethod').value ?? 0,
        approvalStatus: this.form.get('approvalStatus').value ?? 0,
        approvalDate: this.form.get('approvalDate').value,
        declinedReason: this.form.get('declinedReason').value ?? 0,
        declinedText: this.form.get('declinedText').value,
        siteContacts: this.assignedContacts,
        introductionOccurred: this.form.get('introductionOccurred').value,
        introductionDate: this.form.get('introductionDate').value
      };

      this.trialService.updateSiteTrialRelation(
        this.trialId,
        this.form.get('site')!.value,
        updateRelation
      ).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Site Successfully Added to Trial.');
          this.router.navigate(['/trial', this.trialId]).then();
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('There was a problem updating the site/trial relation!');
        }
      });
    }
  }
}
