<app-modal #modal class="duplicate-visit-modal">
  <div class="modal-header">
    <h2 class="modal-title">Duplicate Visit</h2>
  </div>
  <div class="modal-body">

    <form [formGroup]="form" aria-hidden="true">

      <table class="simple-form" aria-hidden="true">
        <tr>
          <th><label>Visit Title</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #visitSelect [parentForm]="form" name="targetVisitId" [options]="visitList"
                                  placeholder="Select..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr *ngIf="this.form.get('targetVisitId').value === 'NEW'">
          <th><label>New Visit Title</label></th>
          <td>
            <div class="form-group">
              <input type="text" formControlName="targetVisitTitle">
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td colspan="2">
            <div class="btn-wrap">
              <div class="row">
                <div class="col-6">
                  <a (click)="hide()" class="btn btn-secondary">Cancel</a>
                </div>
                <div class="col-6">
                  <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="onHandleFormSubmit()">
                        <span *ngIf="form.get('processing').value">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                    <span *ngIf="!form.get('processing').value">Duplicate</span>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </form>

  </div>
</app-modal>
