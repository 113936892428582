<app-modal #modal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Under Investigation</h2>
    </div>
    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="investigationForm">
        <div class="form-group">
          <label>Reason For Investigation</label>
          <textarea class="form-control" formControlName="reasonForInvestigation"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button class="btn btn-primary" [disabled]="investigationForm.invalid"  (click)="showAllClaimsModal()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #allClaimsModal class="all-claims-modal">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Under Investigation</h2>
    </div>
    <div class="modal-body">
      <p>Put all pending expenses for this patient visit under investigation?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a class="btn btn-primary" (click)="onSubmitStartInvestigation(true)">
            <span *ngIf="processing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processing">Yes all expenses</span>
          </a>
        </div>

        <div class="col-6">
          <a class="btn btn-secondary" (click)="onSubmitStartInvestigation(false)">
            <span *ngIf="processing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processing">No, only this expense</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
