import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from '../../../../shared/alert/alert.service';
import { TemplateService } from '../../../../core/services/template.service';
import { SiteContactService } from '../../../../core/services/site-contact.service';
import { SelectOption } from '../../../../core/models/select-option.model';
import { DropdownInputComponent } from '../../../../shared/dropdown-input/dropdown-input.component';
import { ManageUserSitesComponent } from '../manage-user-sites/manage-user-sites.component';
import { LogHelper } from "../../../../core/helpers/log.helper";

@Component({
  selector: 'app-add-site-contact',
  templateUrl: './add-site-contact.component.html',
  styleUrls: ['./add-site-contact.component.scss']
})
export class AddSiteContactComponent implements OnInit, AfterViewInit {
  @ViewChild('statusDropdown') statusDropdown: DropdownInputComponent;
  @ViewChild('siteManager') siteManager: ManageUserSitesComponent;

  form: UntypedFormGroup;
  selectedTab = 'info';
  statusOptions: SelectOption[] = [
    {value: 'true', text: 'Active'},
    {value: 'false', text: 'Inactive'}
  ];

  assignedSites: {siteId: string, trialId: string, role: number}[] = [];
  hideFormButtons = false;

  constructor(private siteContactService: SiteContactService, private alertService: AlertService,
    private router: Router, private templateService: TemplateService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      active: new UntypedFormControl('false'),
      consent: new UntypedFormControl(false, Validators.requiredTrue)
    });
  }

  ngAfterViewInit() {
    this.statusDropdown.setValue('false');
    this.cd.detectChanges();

    this.siteManager.assignedSitesChanged.subscribe(sites => {
      this.assignedSites = [];
      if (sites !== undefined && sites !== null) {
        sites.forEach(site => {
          this.assignedSites.push({siteId: site.siteId, trialId: site.trialId, role: site.role});
        });
      }
    });

    this.siteManager.hideFormButtons.subscribe(hideFormButtons => {
      this.hideFormButtons = hideFormButtons;
    });
  }

  onTabClick(selection: string) {
    this.selectedTab = selection;
    this.hideFormButtons = selection !== 'info';
  }

  onFormSubmit() {
    let form = this.form;

    if (!form.valid)
      return;

    form.patchValue({processing: true});

    this.siteContactService.createSiteContact(
      form.get('firstname').value,
      form.get('lastname').value,
      form.get('email').value,
      form.get('active').value === 'true',
      form.get('consent').value,
      this.assignedSites
    ).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Site contact successfully created.');
        this.router.navigate(['/admin'], {fragment: 'site-contacts'}).then();
        form.patchValue({processing: false});
      },
      error: err => {
        form.patchValue({processing: false});
        LogHelper.log(err);
        this.alertService.showWarningAlert(err.error.title);
      }
    });
  }

}
