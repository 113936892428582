<div class="settings-head">
  <div class="row">
    <div class="col-6">
      <h1>Settings</h1>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
    <div class="platform-version">
      <form [formGroup]="form" (ngSubmit)="onUpdatePlatformVersions()">
        <h2>Platform Minimum Versions</h2>

        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="form-group">
              <label>Android</label>
              <ngx-skeleton-loader class="d-block" *ngIf="!platformVersionLoaded"></ngx-skeleton-loader>
              <input type="text" formControlName="android" placeholder="Enter version..." [ngClass]="{'visibility-hidden': !platformVersionLoaded}">
            </div>
          </div>

          <div class="col-12 col-lg-4">
            <div class="form-group">
              <label>iOS</label>
              <ngx-skeleton-loader class="d-block" *ngIf="!platformVersionLoaded"></ngx-skeleton-loader>
              <input type="text" formControlName="ios" placeholder="Enter version..." [ngClass]="{'visibility-hidden': !platformVersionLoaded}">
            </div>
          </div>

          <div class="col-12 col-lg-4">
            <div class="update-btn" [ngClass]="{'visibility-hidden': !platformVersionLoaded}">
              <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
                <span *ngIf="form.get('processing').value">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </span>
                <span *ngIf="!form.get('processing').value">Update</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
