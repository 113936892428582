<div class="site-user-trial-list" [ngClass]="{'visibility-hidden': !showAssignForm}">
  <div class="row">
    <div class="col-12 text-right">
      <a class="btn btn-outline btn-outline-slim" (click)="onHandleShowAssignTrial()">Assign Trial Site Role</a>
    </div>
  </div>

  <table class="with-heading-columns">
    <thead>
    <tr>
      <th>Public Trial Code</th>
      <th>Nickname</th>
      <th>Opportunity Number</th>
      <th>Site Id</th>
      <th>Site</th>
      <th>Role</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let site of assignedSites; let i = index" [ngClass]="{'visibility-hidden': site.deleted}">
      <td>{{site.trialCode}}</td>
      <td>{{site.trialNickname}}</td>
      <td>{{site.trialOpportunityNumber}}</td>
      <td>{{site.irgSiteId}}</td>
      <td>{{site.siteName}}</td>
      <td>{{site.roleName | pascalSpaces }}</td>
      <td class="btns site-actions">
        <a (click)="onHandleEditAtIndex(i)" class="btn btn-secondary btn-edit">Edit</a>
        <a (click)="unassignModel.showUsingDeleteIndex(i)" class="btn btn-outline btn-slim btn-auto-width" data-toggle="modal" data-target="#delete-user-modal">Unassign</a>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<div class="site-user-trial-assign" [ngClass]="{'visibility-hidden': showAssignForm}">
  <h2><a (click)="onHandleReturnToForm()"><i class="far fa-angle-left" aria-hidden="true"></i></a> <span *ngIf="!objectHelper.isUndefinedNullOrEmpty(contactName)">{{ contactName }} / </span>Assign Trial Site Role</h2>

  <table class="simple-form">
    <tr>
      <th style="min-width: 50px;"><label>Public Trial Code</label></th>
      <td>
        <span *ngIf="modifyAtIndex >= 0">{{ modifyTrialCode }}</span>
        <div class="form-group" [ngClass]="{'visibility-hidden': modifyAtIndex >= 0}">
          <app-trial-autocomplete #trialAutocomplete [publicList]="true" [returnTrialId]="true" [form]="assignSiteForm" controlName="trialId" placeholder="Select a public trial code..."></app-trial-autocomplete>
        </div>
      </td>
    </tr>
    <tr>
      <th style="min-width: 50px;"><label>Site</label></th>
      <td>
        <span *ngIf="modifyAtIndex >= 0">{{ modifySiteIrgSiteId }} - {{ modifySiteName }}</span>
        <div class="form-group" [ngClass]="{'visibility-hidden': modifyAtIndex >= 0}">
          <app-site-autocomplete #siteAutocomplete [publicList]="true" [allowAllSites]="false" [form]="assignSiteForm" controlName="siteId" [disabled]="true"
                                 placeholder="Select site..." [trialFilter]="assignSiteForm.get('trialId').value"></app-site-autocomplete>
        </div>
      </td>
    </tr>
    <tr>
      <th style="vertical-align: middle"><label>Site Role</label></th>
      <td>
        <app-dropdown-input #roleDropdown [parentForm]="assignSiteForm" [options]="roleOptions" name="role" placeholder="Select role..."></app-dropdown-input>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <div class="btn-wrap">
          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-primary" (click)="onHandleAssignSite()" [disabled]="!assignSiteForm.valid" *ngIf="modifyAtIndex < 0">Assign</button>
              <button type="submit" class="btn btn-primary" (click)="onHandleUpdateSite(modifyAtIndex)" [disabled]="!assignSiteForm.valid" *ngIf="modifyAtIndex >= 0">Update</button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>

<!-- Start: Unassign site modal -->
<app-modal #unassignModel>
  <div class="modal-header">
    <h2 class="modal-title">Unassign Trial/Site?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to unassign this Trial/site?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onHandleDeleteConfirmed(unassignModel.deleteIndex)" class="btn btn-primary">
          Unassign
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign site modal -->
