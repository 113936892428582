import { Injectable } from '@angular/core';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let headers = new HttpHeaders();
    headers = headers.append('X-Platform', environment.platform);
    headers = headers.append('X-Version', environment.version);
    const modifiedReq = req.clone({
      headers: headers
    });
    return next.handle(modifiedReq);
  }
}
