import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const endDateAfterStartDateValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const date = group.get('date').value;
  const endDate = group.get('endDate').value;

  let timeHrs = 0;
  let timeMins = 0;
  let endTimeHrs = 0;
  let endTimeMins = 0;

  if (!date || !endDate) {
    // if either date is not set, return null (no error)
    return null;
  }

  if (group.get('time')?.value !== null && group.get('time')?.value !== '') {
    if (group.get('time')?.value !== null && group.get('time')?.value !== '') {
      timeHrs = parseInt(group.get('time')?.value.split(':')[0]);
      timeMins = parseInt(group.get('time')?.value.split(':')[1]);
    }
  }

  if (group.get('endTime')?.value !== null && group.get('endTime')?.value !== '') {
    if (group.get('endTime')?.value !== null && group.get('endTime')?.value !== '') {
      endTimeHrs = parseInt(group.get('endTime')?.value.split(':')[0]);
      endTimeMins = parseInt(group.get('endTime')?.value.split(':')[1]);
    }
  }

  const startDateDay = parseInt(date.split('/')[0]);
  const startDateMonth = parseInt(date.split('/')[1]);
  const startDateYear = parseInt(date.split('/')[2]);

  const endDateDay = parseInt(endDate.split('/')[0]);
  const endDateMonth = parseInt(endDate.split('/')[1]);
  const endDateYear = parseInt(endDate.split('/')[2]);

  const startDateTime = new Date(startDateYear, startDateMonth - 1, startDateDay, timeHrs, timeMins);
  const endDateTime = new Date(endDateYear, endDateMonth - 1, endDateDay, endTimeHrs, endTimeMins);

  if (endDateTime < startDateTime) {
    group.controls['endDate'].setErrors({ 'endBeforeStart': true });
    group.setErrors({ 'invalid': true });
    return { 'endBeforeStart': true };
  } else {
    group.controls['endDate'].setErrors(null);
    group.setErrors(null);
    return null;
  }
};

