import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from "../../../../core/services/patient.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientList } from "../../../../core/models/patient-list.model";
import { StringHelper } from '../../../../core/helpers/string-helper';
import { LogHelper } from '../../../../core/helpers/log.helper';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-patient-approved',
  templateUrl: './patient-approved.component.html'
})
export class PatientApprovedComponent implements OnInit {
  keywords: string;
  country: string;
  orderBy = 0;
  state = 0;
  results = new PatientList();
  hasResults = true;
  stringHelper = StringHelper;
  subscription: Subscription;
  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

  constructor(private readonly patientService: PatientService, private readonly alertService: AlertService,
    private readonly router: Router) { }

  ngOnInit() {
    // Initially load patients
    this.loadPatients(1, null, null, 0);
  }

  updateFilter(keywords: string, country: string = null, orderBy: number = 0) {
    this.keywords = keywords === '' ? null : keywords;
    this.country = country === '' ? null : country;
    this.orderBy = orderBy;
    this.loadPatients(1, keywords, country, orderBy);
  }

  onPatientClicked(patientTrialId: string) {
    this.router.navigate(['/patient', patientTrialId])
  }

  loadPatients(page: number, keywords: string, country: string, orderBy: number = 0) {
    this.dataLoaded = false;

    // If page NULL, then it means we need to stay on the current page
    if (page == null) {
      page = this.results.currentPage;
    }

    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.patientService.retrievePatients(this.state, keywords, country, page, orderBy).subscribe({
      next: patientList => {
        this.results = patientList;

        if (keywords === null && patientList.results.length === 0) {
          this.hasResults = false;
        }

        // If the current page is more than the number of pages available, use reload using the last page
        if (page > patientList.totalPageCount && patientList.totalRecordCount > 0) {
          this.loadPatients(patientList.totalPageCount, keywords, country, orderBy);
        }

        this.dataLoaded = true;
      },
      error: (error) => {
        this.dataLoaded = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to load approved patients!');
      }
    });
  }
}
