<div [formGroup]="parentForm" class="input-multi-select" [ngClass]="{'disabled' : parentForm.get(controlName).disabled}">
  <mat-select #matSelect [formControlName]="controlName" multiple [placeholder]="inputPlaceholder" [disabled]="parentForm.get(controlName).disabled">
    <input class="search-input" matInput #searchInput (keyup)="onSearchInputChanged()" [placeholder]="searchInputPlaceholder" *ngIf="allowFiltering">

    <div class="select-all" *ngIf="allowSelectDeselect">
      <a (click)="onSelectAll()">Select all</a> <a (click)="onDeselectAll()" *ngIf="parentForm.get(controlName).value !== null && parentForm.get(controlName).value.length > 0">Deselect all</a>
    </div>

    <mat-option *ngFor="let item of items" [value]="item.value" (click)="onItemClicks(item)">{{item.text}}</mat-option>
  </mat-select>
</div>
