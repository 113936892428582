import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '../../../../shared/modal/modal.component';
import { SiteAutocompleteComponent } from '../../../../shared/site-autocomplete/site-autocomplete.component';
import { TrialService } from '../../../../core/services/trial.service';
import { TrialAssignPatient } from '../../../../core/services/interfaces/trial-assign-patient.interface';
import { AlertService } from '../../../../shared/alert/alert.service';
import { PatientAutoCompleteComponent } from '../../../../shared/patient-autocomplete/patient-autocomplete.component';
import { PatientDetail } from '../../../../core/models/patient-detail.model';
import { ObjectHelper } from '../../../../core/helpers/object.helper';
import { PatientService } from '../../../../core/services/patient.service';
import { PatientAddModalComponent } from "../../../../shared/patient-add-modal/patient-add-modal.component";
import { PatientCreated } from "../../../../shared/patient-add-modal/patient-created.model";

@Component({
  selector: 'app-trial-assign-patient-modal',
  templateUrl: './trial-assign-patient-modal.component.html',
  styleUrls: ['./trial-assign-patient-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrialAssignPatientModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('patientAutocomplete') patientAutocomplete: PatientAutoCompleteComponent;
  @ViewChild('addPatientModal') addPatientModal: PatientAddModalComponent;

  @Output("patientAssigned") patientAssigned = new EventEmitter();

  form: UntypedFormGroup;
  patient: PatientDetail;

  constructor(private _trialService: TrialService, private _alertService: AlertService, private _patientService: PatientService) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      trialCode: new UntypedFormControl('', Validators.required),
      trialId: new UntypedFormControl('', Validators.required),
      patientId: new UntypedFormControl('', Validators.required),
      siteId: new UntypedFormControl('', Validators.required),
      patientCode: new UntypedFormControl('', Validators.required),
      consent: new UntypedFormControl(false, Validators.requiredTrue)
    });

    this.form.get('patientId').valueChanges.subscribe(patientId => {
      if (!ObjectHelper.isUndefinedNullOrEmpty(patientId)) {
        this._patientService.retrievePatientDetail(patientId).subscribe({
            next: patient => {
              this.patient = patient;
            }
          }
        );
      }
    });
  }


  show(trialId: string, trialCode: string): void {
    this.siteAutocomplete.clear();
    this.patientAutocomplete.onClear();
    this.patient = null;

    this.form.patchValue({
      trialId: trialId,
      trialCode: trialCode,
      patientId: '',
      patientCode: '',
      siteId: '',
      consent: false,
      processing: false
    });
    this.siteAutocomplete.setTrialId(trialId);

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();

    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
  }

  /**
   * Called when a patient is create through the add patient modal
   * Sets the patient Id on the assign patient form
   * @param created
   */
  onHandlePatientCreated(created: PatientCreated): void {
    this._alertService.showSuccessAlert(`Patient ${created.patientFirstname} ${created.patientLastname} created and assigned to trial ${created.trialCode} successfully`);
    this.patientAssigned.emit();
    this.hide();
  }

  onFormSubmit(): void {
    const form = this.form;

    if (form.valid) {
      this.form.patchValue({processing: true});

      const dto: TrialAssignPatient = {
        trialId: form.value.trialId,
        patientId: form.value.patientId,
        siteId: form.value.siteId,
        consent: form.value.consent,
        patientCode: form.value.patientCode
      };

      this._trialService.assignPatientToTrial(dto).subscribe({
        next: rsp => {
          this._alertService.showSuccessAlert('Patient assigned to trial successfully');
          this.hide();
          this.patientAssigned.emit();
          this.form.patchValue({processing: false});
        },
        error: err => {
          this.form.patchValue({processing: false});
          this._alertService.showErrorAlert(err);
        }
      });
    }

  }

}
