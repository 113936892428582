<!-- Start: Admin users -->
<div class="admin-users">

  <div class="row">
    <div class="col-12 text-right header-search">
      <a routerLink="/admin/add" class="btn btn-outline" *ngIf="authService.hasPermission(Permissions.UserAdd)">+ Add
        CMS User</a>

      <div class="admin-search">
        <app-search-input name="keywords" [parentForm]="searchForm"
          (inputChange)="onSearchInputChanged($event)"></app-search-input>
      </div>
    </div>
  </div>

  <table class="with-heading-columns" aria-hidden="true">

    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="dataLoaded">
      <tr *ngFor="let adminUser of results.results">
        <td>{{ adminUser.firstname + ' ' + adminUser.lastname }}</td>
        <td>{{ adminUser.email }}</td>
        <td class="btns">
          <a [routerLink]="['/admin/edit']" [queryParams]="{id: adminUser.id}"
            class="btn btn-secondary btn-slim btn-auto-width"
            *ngIf="editButtonVisible(adminUser.id)">Edit</a>
          <a (click)="onDeleteAdmin(adminUser.id)" class="btn btn-outline btn-slim btn-auto-width" data-toggle="modal"
            data-target="#delete-user-modal" *ngIf="deactivateAvailable(adminUser)">Delete</a>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="!dataLoaded">
      <tr *ngFor="let _ of loaderArray">
        <td style="width: 40%;"><ngx-skeleton-loader class="d-block" style="width: 50%;"></ngx-skeleton-loader></td>
        <td style="width: 48%;"><ngx-skeleton-loader class="d-block" style="width: 50%;"></ngx-skeleton-loader></td>
        <td style="width: 5%;"><ngx-skeleton-loader class="d-block" style="width: 90%;"></ngx-skeleton-loader></td>
        <td style="width: 7%;"><ngx-skeleton-loader class="d-block" style="width: 90%;"></ngx-skeleton-loader></td>
      </tr>
    </tbody>

  </table>

</div>

<app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
  (pageSelected)="onChangePage($event)"></app-pagination>
<!-- End: Admin users -->

<!-- Start: Delete user modal -->
<app-modal #deleteUserModel>
  <div class="modal-header">
    <h2 class="modal-title">Delete User?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this user?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteUserModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onDeleteConfirmed()" class="btn btn-primary">
          <span *ngIf="userIsBeingDeleted">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!userIsBeingDeleted">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete user modal -->
