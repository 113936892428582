import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseAutoApprovalRuleTypeString, ExpenseRuleListItemViewModel } from '../../trial-policy.model';
import { AutoApprovalResultString } from 'app/core/models/expense.model';

@Component({
  selector: 'app-trial-policy-rules-list',
  templateUrl: './trial-policy-rules-list.component.html',
  styleUrls: ['./trial-policy-rules-list.component.scss']
})
export class TrialPolicyRulesListComponent implements OnInit {
  @Input() expenseRules: ExpenseRuleListItemViewModel[] = [];
  @Input() showResult: boolean = false;
  @Output() updateRule: EventEmitter<ExpenseRuleListItemViewModel> = new EventEmitter<ExpenseRuleListItemViewModel>();
  ExpenseAutoApprovalRuleTypeString = ExpenseAutoApprovalRuleTypeString;
  AutoApprovalResultString = AutoApprovalResultString;

  constructor() { }

  ngOnInit(): void { }

  displayCategories(rule: ExpenseRuleListItemViewModel) {
    return rule.allCategories ? "all" : rule.categories.map(c => c.name).join(", ");
  }

  openUpdateRuleModal(rule: ExpenseRuleListItemViewModel) {
    this.updateRule.emit(rule);
  }

  getReceiptValue(rule: ExpenseRuleListItemViewModel): string {
    if (rule.receiptRequired && rule.receiptRequiredValue > 0) {
      return `>${rule.receiptRequiredValue}${rule.currency}`;
    }

    return rule.receiptRequired ? 'Y' : 'N';
  }
}
