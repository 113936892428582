<div class="view-head">
  <div class="row">

    <div class="col-12 d-none d-md-block col-md-2">
      <div class="heading-filter">
        <h2>Expenses</h2>
      </div>
    </div>

    <div class="col-12 col-md-10 text-right header-container">

      <div class="on-hold-expenses-switch">
        <label class="switch mb-0">
          <input (click)="viewInvestigationExpenses()" [checked]="expenseClaimsRequest.investigations" type="checkbox">
          <span class="slider round"></span>
        </label>
        <label class="switch-label">View Investigations</label>
      </div>

      <div class="on-hold-expenses-switch">
        <label class="switch mb-0">
          <input (click)="viewOnHoldExpenses()" [checked]="expenseClaimsRequest.onHold" type="checkbox">
          <span class="slider round"></span>
        </label>
        <label class="switch-label">View On-Hold</label>
      </div>

      <form [formGroup]="searchForm">
        <app-search-input #searchInput name="keywords" [parentForm]="searchForm"
          (inputChange)="onSearchInputChanged($event)">
        </app-search-input>
      </form>

      <app-dropdown-input class="order-by-dropdown" #orderBySelect [options]="orderByOptions"></app-dropdown-input>

      <div class="button-container ml-0">
        <a class="btn btn-secondary" (click)="exportExpensesModal.show()"
          *ngIf="authService.hasPermission(Permissions.ExpenseExportCsv)">Export CSV</a>
        <a (click)="createExpenseModal.show()" class="btn btn-primary"
          *ngIf="authService.hasPermission(Permissions.ExpenseWrite)">Create Expense</a>
      </div>

    </div>
  </div>
</div>

<div class="view-body">

  <div class="std-tabbed-content">

    <div class="tabs-wrap">
      <app-mobile-tabs #mobileTabs (onSelected)="onTabSelect(ExpenseClaimState[$event])">
        <ul>
          <li>
            <a class="selected" (click)="mobileTabs.onSelectItem(ExpenseClaimState.Pending.toString(), $event)">
              Pending
              <span *ngIf="claimsCount">
                ({{claimsCount[getExpenseStateString(ExpenseClaimState.Pending)]}})
              </span>
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.Approved.toString(), $event)">
              Approved - Manual Payment
              <span *ngIf="claimsCount">
                ({{claimsCount[getExpenseStateString(ExpenseClaimState.Approved)]}})
              </span>
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.CaxtonApproved.toString(), $event)">
              Approved - Caxton Auto Payment
              <span *ngIf="claimsCount">
                ({{claimsCount[getExpenseStateString(ExpenseClaimState.CaxtonApproved)]}})
              </span>
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.Processing.toString(), $event)">
              Payment Processing
              <span *ngIf="claimsCount">
                ({{claimsCount[getExpenseStateString(ExpenseClaimState.Processing)]}})
              </span>
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.CaxtonFailed.toString(), $event)">
              Caxton Failed
              <span *ngIf="claimsCount">
                ({{claimsCount[getExpenseStateString(ExpenseClaimState.CaxtonFailed)]}})
              </span>
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.Paid.toString(), $event)">
              Paid
            </a>
          </li>

          <li>
            <a (click)="mobileTabs.onSelectItem(ExpenseClaimState.Rejected.toString(), $event)">
              Rejected
            </a>
          </li>
        </ul>
      </app-mobile-tabs>

      <ul class="nav nav-tabs">

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.Pending)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.Pending}">
            Pending
            <div class="tab-loading spinner-border spinner-border-sm" role="status" *ngIf="!claimsCount">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="claimsCount">
              ({{claimsCount[getExpenseStateString(ExpenseClaimState.Pending)]}})
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.Approved)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.Approved}">
            Approved - Manual Payment
            <div class="tab-loading spinner-border spinner-border-sm" role="status" *ngIf="!claimsCount">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="claimsCount">
              ({{claimsCount[getExpenseStateString(ExpenseClaimState.Approved)]}})
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.CaxtonApproved)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.CaxtonApproved}">
            Approved - Caxton Auto Payment
            <div class="tab-loading spinner-border spinner-border-sm" role="status" *ngIf="!claimsCount">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="claimsCount">
              ({{claimsCount[getExpenseStateString(ExpenseClaimState.CaxtonApproved)]}})
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.Processing)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.Processing}">
            Payment Processing
            <div class="tab-loading spinner-border spinner-border-sm" role="status" *ngIf="!claimsCount">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="claimsCount">
              ({{claimsCount[getExpenseStateString(ExpenseClaimState.Processing)]}})
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.CaxtonFailed)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.CaxtonFailed}">
            Caxton Failed
            <div class="tab-loading spinner-border spinner-border-sm" role="status" *ngIf="!claimsCount">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="claimsCount">
              ({{claimsCount[getExpenseStateString(ExpenseClaimState.CaxtonFailed)]}})
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.Paid)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.Paid}">Paid</a>
        </li>

        <li class="nav-item">
          <a (click)="onTabSelect(ExpenseClaimState.Rejected)" class="nav-link"
            [ngClass]="{'active': selectedTab === ExpenseClaimState.Rejected}">Rejected</a>
        </li>
      </ul>
    </div>

    <div class="results-wrap">

      <div class="tab-content">

        <!-- Start: Expenses -->
        <div *ngIf="dataLoaded" class="tab-pane fade show active" id="expenses-pending">
          <div *ngIf="!isCaxtonTab()">
            <div class="expense-details" *ngFor="let expense of claimsResponse.results">
              <app-expense-details-head [expense]="expense" [dataLoaded]="dataLoaded"
                (searchFilter)="setSearch($event)"></app-expense-details-head>

              <div class="expense-container">
                <div class="expense-data">
                  <app-expense-state-badge [expense]="expense" [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-state-badge>
                  <app-expense-meta-data [expense]="expense" [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-meta-data>
                  <app-expense-cost [expense]="expense" [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-cost>
                  <app-expense-check-preview [expense]="expense"></app-expense-check-preview>
                  <app-expense-description [expense]="expense" [selectedTab]="selectedTab"></app-expense-description>
                  <app-expense-reject-reason [expense]="expense"></app-expense-reject-reason>
                  <app-expense-images [imagery]="expense.imageUrls"></app-expense-images>
                  <app-expense-edit-reasons [expense]="expense" [selectedTab]="selectedTab"></app-expense-edit-reasons>
                </div>
                <div class="expense-actions-container">
                  <app-expense-actions [expense]="expense" [selectedTab]="selectedTab" [dataLoaded]="dataLoaded"
                    [processingRequest]="processingRequest" (edit)="onEditExpense($event)"
                    (check)="onCheckExpense($event)" (approve)="onApproveExpenseClaim($event)"
                    (markAsPaid)="onMarkAsPaid($event)" (reject)="onRejectModal($event)"
                    (startInvestigation)="showStartInvestigationModal($event)"
                    (closeInvestigation)="onCloseInvestigation($event)"
                    (refreshResult)="loadPage(page, keywords, selectedTab)">
                  </app-expense-actions>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="isCaxtonTab()">
            <div *ngFor="let patientGroupExpense of caxtonClaimsResponse.results">
              <div class="expense-details" *ngFor="let expenses of patientGroupExpense.currencyGroupExpenses">

                <app-expense-caxton-details-head [expense]="expenses" [dataLoaded]="dataLoaded"
                  (searchFilter)="setSearch($event)"></app-expense-caxton-details-head>

                <div [ngClass]="{'processing-min-height': selectedTab === ExpenseClaimState.Processing}"
                  class="expense-wrapper" *ngFor="let expense of expenses.expenses">

                  <app-expense-state-badge [expense]="expense" [selectedTab]="selectedTab"
                    [dataLoaded]="dataLoaded"></app-expense-state-badge>
                  <button (click)="openAddNoteModal(expense)" class="add-note-btn btn btn-secondary btn-slim">
                    Add Note
                  </button>

                  <app-expense-meta-data [expense]="expense" [selectedTab]="selectedTab"
                    [dataLoaded]="dataLoaded"></app-expense-meta-data>
                  <app-expense-cost [expense]="expense" [selectedTab]="selectedTab"
                    [dataLoaded]="dataLoaded"></app-expense-cost>
                  <app-expense-caxton-payment-dates [expense]="expense" [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-caxton-payment-dates>
                  <app-expense-description [expense]="expense" [selectedTab]="selectedTab"></app-expense-description>
                  <app-expense-images [imagery]="expense.imageUrls"></app-expense-images>
                  <app-expense-edit-reasons [expense]="expense" [selectedTab]="selectedTab"></app-expense-edit-reasons>
                </div>

                <app-expense-caxton-payment-actions [expenses]="expenses" [selectedTab]="selectedTab"
                  [dataLoaded]="dataLoaded" [processingRequest]="processingRequest"
                  (caxtonFailed)="moveToCaxtonFailed($event)" (sendToFinance)="sendToFinance($event)"
                  (payByBank)="payByCaxtonBank($event)" (payByCard)="payByCaxtonCard($event)"
                  (investigation)="openCloseInvestigationModal($event)"
                  (transfer)="showCaxtonTransferModal($event.totalExpense, $event.patientName, $event.expenses, $event.paymentMethod)">
                </app-expense-caxton-payment-actions>

              </div>
            </div>
          </div>

        </div>
        <!-- End: Expenses -->

        <div *ngIf="!dataLoaded" id="expenses-pending">
          <div *ngIf="!isCaxtonTab()">
            <div class="expense-details" *ngFor="let _ of loaderArray">
              <app-expense-details-head [dataLoaded]="dataLoaded"></app-expense-details-head>

              <div class="expense-container">
                <div class="expense-data">
                  <app-expense-state-badge [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-state-badge>
                  <app-expense-meta-data [dataLoaded]="dataLoaded" [selectedTab]="selectedTab"></app-expense-meta-data>
                  <app-expense-cost [dataLoaded]="dataLoaded" [selectedTab]="selectedTab"></app-expense-cost>
                </div>
                <div class="expense-actions-container">
                  <app-expense-actions [dataLoaded]="dataLoaded" [selectedTab]="selectedTab"></app-expense-actions>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="isCaxtonTab()">
            <div *ngFor="let _ of loaderArray">
              <div class="expense-details" *ngFor="let expenses of [1]">

                <app-expense-caxton-details-head [dataLoaded]="dataLoaded"
                  (searchFilter)="setSearch($event)"></app-expense-caxton-details-head>

                <div [ngClass]="{'processing-min-height': selectedTab === ExpenseClaimState.Processing}"
                  class="expense-wrapper" *ngFor="let _ of [1,1]">

                  <app-expense-state-badge [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-state-badge>

                  <app-expense-meta-data [dataLoaded]="dataLoaded" [selectedTab]="selectedTab"></app-expense-meta-data>
                  <app-expense-cost [dataLoaded]="dataLoaded" [selectedTab]="selectedTab"></app-expense-cost>
                  <app-expense-caxton-payment-dates [dataLoaded]="dataLoaded"
                    [selectedTab]="selectedTab"></app-expense-caxton-payment-dates>
                </div>

                <app-expense-caxton-payment-actions [dataLoaded]="dataLoaded" [selectedTab]="selectedTab">
                </app-expense-caxton-payment-actions>

              </div>
            </div>
          </div>

        </div>

        <app-pagination *ngIf="!noResultsVisible()"
          [currentPage]="isCaxtonTab() ? caxtonClaimsResponse?.currentPage : claimsResponse?.currentPage"
          [totalPageCount]="isCaxtonTab() ? caxtonClaimsResponse?.totalPageCount : claimsResponse?.totalPageCount"
          (pageSelected)="loadPage($event, keywords, selectedTab)">
        </app-pagination>

        <div *ngIf="noResultsVisible()">
          <div class="no-results inside">
            <div class="inner">
              <div class="image">
                <img [src]="noResultsImage()" [alt]="noResultsText()" />
              </div>
              <div class="content">
                <h2>{{noResultsText()}}</h2>
                <p *ngIf="noSearchMatchingResults()">There are no expenses matching your search.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-modal #approveDistanceModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Approve Distance Expense?</h2>
    </div>
    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="approveDistanceForm">
        <div class="form-group">
          <label>Currency</label>
          <app-dropdown-input #currencySelect name="currency" [parentForm]="approveDistanceForm"
            [options]="currencyOptions" placeholder="Select Currency..."
            (selectValueChanged)="onCurrencySelectChanged($event)"></app-dropdown-input>
        </div>
        <div class="form-group">
          <label>Amount</label>
          <input type="number" class="form-control" formControlName="amount">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="approveDistanceModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button (click)="onApproveDistanceExpenseClaim()" class="btn btn-primary"
            [disabled]="processingDistanceApproveRequest">
            <span *ngIf="processingDistanceApproveRequest">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processingDistanceApproveRequest">Approve</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #rejectExpenseModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Reject Expense</h2>
    </div>
    <div class="modal-body">
      <p>This rejection reason will be displayed to the patient in their app.</p>
      <form class="modal-form-full-width" [formGroup]="rejectForm">
        <div class="form-group">
          <label>Rejection Reason</label>
          <textarea class="form-control" formControlName="reason"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="rejectExpenseModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmRejectExpense()" class="btn btn-primary" [ngClass]="{'disabled': !rejectForm.valid}">
            <span *ngIf="rejectFormIsProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!rejectFormIsProcessing">Reject</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<!-- Start: Export expenses modal -->
<app-modal #exportExpensesModal>
  <div class="modal-header">
    <h2 class="modal-title">Export CSV</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
      <div class="form-group">
        <label>Filter by</label>
        <app-dropdown-input [options]="exportDateOptions" placeholder="Select..." [parentForm]="exportForm"
          name="filterBy" (selectValueChanged)="onExportDateOptionChanged($event)" [selectedValue]="'0'">
        </app-dropdown-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportExpensesModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportChangeLog()" class="btn btn-primary" (mousemove)="updateExportFormValues()">
          <span *ngIf="exportIsProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!exportIsProcessing">Export CSV</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export expenses modal -->

<app-modal #foundOnCaxtonModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Caxton Transfer ID</h2>
    </div>
    <div class="modal-body found-on-caxton">

      <form [formGroup]="foundOnCaxtonForm">
        <table aria-hidden="true" class="simple-form">
          <tbody>

            <tr>
              <th class="valign-top"><label class="info-label">Amount</label></th>
              <td>
                <div class="form-group">
                  <label class="caxton-transfer-details">
                    {{caxtonTransferModalAmount / 100 | number: '1.2-2' }}
                    {{caxtonTransferModalCurrency}}
                  </label>
                </div>
              </td>
            </tr>

            <tr>
              <th class="valign-top"><label class="info-label">Recipient</label></th>
              <td>
                <div class="form-group">
                  <label class="caxton-transfer-details">{{caxtonTransferModalRecipient}}</label>
                </div>
              </td>
            </tr>

            <tr>
              <th class="valign-top"><label class="info-label">Date & Time</label></th>
              <td>
                <div class="form-group">
                  <label class="caxton-transfer-details">{{caxtonTransferModalDate | date:'dd/MM/yyyy - hh:mm'}}</label>
                </div>
              </td>
            </tr>

            <tr *ngIf="caxtonTransferModalPaymentMethod === ExpenseClaimPaymentMethod.CaxtonAutomatedBankTransfer">
              <th class="valign-top"><label class="label-input" for="transfer-id">Transfer ID</label></th>
              <td>
                <div class="form-group">
                  <input type="number" class="form-control" formControlName="transferId" id="transfer-id">
                </div>
              </td>
            </tr>

            <tr>
              <th class="valign-top"><label class="label-input" for="findings">Findings</label></th>
              <td>
                <div class="form-group">
                  <textarea class="form-control" formControlName="caxtonFailedFindings" id="findings"></textarea>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

      </form>

    </div>
    <div class="modal-footer">

      <div class="row">
        <div class="col-6">
          <a (click)="hideFoundOnCaxtonModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="setCaxtonTransferId()" class="btn btn-primary" [ngClass]="{'disabled': !foundOnCaxtonForm.valid}">
            <span *ngIf="foundOnCaxtonFormProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!foundOnCaxtonFormProcessing">Save</span>
          </a>
        </div>

      </div>
    </div>
  </div>
</app-modal>
<!-- Start: Send to Finance modal -->

<app-modal #sendToFinanceModal>
  <div class="modal-header">
    <h2 class="modal-title">Process as manual payment ?</h2>
  </div>
  <div class="modal-body">
    <label class="label-input">Reason</label>
    <textarea class="form-control send-to-finance-reason" #sendToFinanceReason></textarea>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="sendToFinanceModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmSendToFinance()" class="btn btn-primary">
          <span *ngIf="processingRequest">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!processingRequest">Process as manual payment</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Send to Finance modal -->

<app-modal #confirmExpenseApprove>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Expense will go to manual payment</h2>
    </div>
    <div class="modal-body">
      <p>{{caxtonIncompatibleData?.caxtonIncompatibleReason.split('|')[0]}}</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="closeConfirmExpenseApproveModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="confirmExpenseClaimApproval()" class="btn btn-primary">
            <span *ngIf="confirmExpenseClaimApprovalProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!confirmExpenseClaimApprovalProcessing">Continue</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #caxtonFailedFindingModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Add failure findings</h2>
    </div>
    <div class="modal-body">
      <label class="label-input">Findings</label>
      <textarea class="form-control send-to-finance-reason" #caxtonFailedFinding></textarea>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="closeCaxtonFailedFindingModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="closeInvestigation()" class="btn btn-primary">
            <span *ngIf="processingRequest">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processingRequest">Continue</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-expense-add-notes #addNoteModal (noteAdded)="getExpenses()"></app-expense-add-notes>
<app-create-expense-modal #createExpenseModal (expenseCreated)="getExpenses()"></app-create-expense-modal>
<app-start-investigation-modal #startInvestigationModal
  (refreshResult)="loadPage(page, keywords, selectedTab)"></app-start-investigation-modal>
<app-close-investigation-modal #closeInvestigationModal
  (refreshResult)="loadPage(page, keywords, selectedTab)"></app-close-investigation-modal>
<app-edit-expense-modal #editExpenseModal (expenseUpdated)="getExpenses()"></app-edit-expense-modal>
<app-check-expense-modal #checkExpenseModal (expenseClaimUpdated)="getExpenses()" (suggested)="getExpenses()"
  (saved)="getExpenses()" (approved)="onApproveExpenseClaim($event)"
  (rejected)="onRejectModal($event.id)" (overBudgetRequestRaised)="onOverBudgetRequestRaised()"></app-check-expense-modal>
