import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DropdownInputComponent } from '../../../../../shared/dropdown-input/dropdown-input.component';
import { ObjectHelper } from '../../../../../core/helpers/object.helper';
import { SelectOption } from '../../../../../core/models/select-option.model';
import { AlertService } from '../../../../../shared/alert/alert.service';
import { ModalComponent } from '../../../../../shared/modal/modal.component';
import { TrialSiteContact } from '../../../../../core/models/trial-site-contact.model';
import { SiteContactService } from '../../../../../core/services/site-contact.service';
import {
  SiteContactsDropdownComponent
} from '../../../../../shared/site-contacts-dropdown/site-contacts-dropdown.component';
import {
  CreateSiteContactModalComponent
} from '../../../../../shared/create-site-contact-modal/create-site-contact-modal.component';


@Component({
  selector: 'app-manage-site-contacts',
  templateUrl: './manage-site-contacts.component.html',
  styleUrls: ['./manage-site-contacts.component.scss']
})
export class ManageSiteContactsComponent implements OnInit, AfterViewInit {
  @ViewChild('createSiteContactModal') createSiteContactModal: CreateSiteContactModalComponent;
  @ViewChild('contactDropdown') contactDropdown: SiteContactsDropdownComponent;
  @ViewChild('unassignModel') public unassignModel: ModalComponent;
  @ViewChild('roleDropdown') roleDropdown: DropdownInputComponent;

  @Output('assignedContactsChanged') assignedContactsChanged = new BehaviorSubject<TrialSiteContact[]>(null);
  @Output('hideFormButtons') hideFormButtons = new EventEmitter<boolean>();

  showAssignForm = true;
  roleOptions: SelectOption[] = [
    {value: '0', text: 'Principle Investigator'},
    {value: '1', text: 'Sub Investigator'},
    {value: '2', text: 'Site Coordinator'}
  ];
  assignedContacts: TrialSiteContact[] = [];
  allContacts: SelectOption[] = [];

  assignContactForm: UntypedFormGroup;

  modifyAtIndex: number = -1;

  constructor(private alertService: AlertService, private siteContactService: SiteContactService) {
  }

  ngOnInit(): void {
    this.assignContactForm = new UntypedFormGroup({
      siteContactSiteId: new UntypedFormControl(''),
      siteContactId: new UntypedFormControl('', Validators.required),
      role: new UntypedFormControl('', Validators.required)
    });

    this.loadSiteContacts();
  }

  ngAfterViewInit() {
    this.createSiteContactModal.siteContactCreated.subscribe(success => {
      if (success) {
        this.loadSiteContacts();
      }
    });
  }

  loadSiteContacts() {
    this.siteContactService.retrieveSiteContacts(null, 1, 9999).subscribe({
      next: (contacts) => {
        this.allContacts = [];
        contacts.results.forEach(contact => {
          this.allContacts.push(new SelectOption(contact.id, contact.firstname + ' ' + contact.lastname + ' (' + contact.email + ')'));
        });
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  onHandleEditAtIndex(index: number) {
    let contact = this.assignedContacts[index];

    this.modifyAtIndex = index;
    this.assignContactForm.patchValue({role: contact.role.toString(), siteContactId: contact.siteContactId});

    this.roleDropdown.setValue(contact.role.toString());
    this.contactDropdown.setSelectedOption(contact.siteContactId);

    this.showAssignForm = false;
    this.hideFormButtons.emit(true);
  }

  setAssignedContacts(contacts: TrialSiteContact[]) {
    this.assignedContacts = contacts;

    // Raise event to let subscribers know that the assigned contacts have changed
    this.assignedContactsChanged.next(this.assignedContacts);
  }

  onHandleShowAssignTrial() {
    this.showAssignForm = false;
    this.hideFormButtons.emit(true);
    this.assignContactForm.reset();
    this.contactDropdown.onClear();
    this.roleDropdown.reset();
    this.modifyAtIndex = -1;
  }

  onHandleReturnToForm() {
    this.showAssignForm = true;
    this.hideFormButtons.emit(false);
  }

  onHandleDeleteConfirmed(index: number) {
    this.assignedContacts[index].deleted = true;

    // Raise event to let subscribers know that the assigned contacts have changed
    this.assignedContactsChanged.next(this.assignedContacts);

    // Reset the unassigning contact flag
    this.unassignModel.deleteIndex = -1;

    this.hideFormButtons.emit(false);

    // Hide the modal window
    this.unassignModel.hide();
  }

  onHandleRemoveAtIndex(index: number) {
    this.unassignModel.resetDeleteIndexAndId();
    this.unassignModel.show();
  }

  onHandleUpdateSite(index: number) {
    let role = this.roleOptions.find(x => x.value === this.assignContactForm.get('role').value);

    this.assignedContacts[index].role = +role.value;
    this.assignedContacts[index].roleName = role.text;

    this.showAssignForm = true;
    this.modifyAtIndex = -1;
    this.hideFormButtons.emit(false);

    // Raise event to let subscribers know that the assigned sites have changed
    this.assignedContactsChanged.next(this.assignedContacts);
  }

  onHandleAssignContact() {
    // Get the selected contact and separate the name from the email
    const nameAndEmail = this.contactDropdown.selectedOption.text;
    const name = nameAndEmail.substring(0, nameAndEmail.indexOf('(') - 1);
    const email = nameAndEmail.substring(nameAndEmail.indexOf('(') + 1, nameAndEmail.indexOf(')'));

    // First let's make sure this contact isn't already assigned
    const existingContact = this.assignedContacts.find(x => x.siteContactId === this.assignContactForm.value.siteContactId);

    // we need to make a call to the API to get the status of the site contact (active or inactive) as it is not returned in the dropdown
    this.siteContactService.retrieveSiteContact(this.assignContactForm.value.siteContactId).subscribe(contact => {
      if (ObjectHelper.isUndefinedOrNull(existingContact)) {
        this.assignedContacts.push(new TrialSiteContact({
          siteContactSiteId: null,
          siteContactId: this.assignContactForm.value.siteContactId,
          role: this.assignContactForm.value.role,
          roleName: this.roleOptions.find(x => x.value === this.assignContactForm.value.role).text,
          fullname: name,
          email: email,
          isActive: contact.isActive,
          deleted: false
        }));

        // Raise event to let subscribers know that the assigned sites have changed
        this.assignedContactsChanged.next(this.assignedContacts);
      } else {
        // Undelete the existing site
        if (existingContact.deleted) {
          existingContact.deleted = false;
        } else {
          this.alertService.showWarningAlert('This contact already exists on the trial.');
        }
      }

      this.assignContactForm.reset();
      this.showAssignForm = true;
      this.hideFormButtons.emit(false);
    });
  }

}
