import { CreatePatientRegistrationRequest, UploadSignatureResponse } from './../models/patient.model';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SiteContactList } from '../models/site-contact-list.model';
import { SiteContact } from '../models/SiteContact.model';
import { ObjectHelper } from '../helpers/object.helper';
import { TrialSiteContactList } from '../models/trial-site-contact-list.model';
import { ChangeLog } from '../../shared/change-log/change-log.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteContactService {
  readonly _ENDPOINT = '/sitecontact/';

  public currentPageNumber = 1;

  private anonymousHttpClient: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.anonymousHttpClient = new HttpClient(handler);
  }

  /**
   * Get the admin change log
   * @param page
   */
  changeLog(page = 1) {
    let params = new HttpParams().set('page', page.toString());

    return this.http.get<ChangeLog>(environment.apiUrl + this._ENDPOINT + 'changelog', { params: params }).pipe(map(rsp => {
      return new ChangeLog(rsp);
    }));
  }

  /**
   * Deactivates a site contact
   * @param id
   */
  deactivateSiteContact(id: string) {
    return this.http.post(environment.apiUrl + this._ENDPOINT + id + '/deactivate', {});
  }

  /**
   * Retrieve a list of site contacts
   * @param keywords
   * @param currentPage the page number of results to return
   * @param pageSize
   */
  retrieveSiteContacts(keywords: string, currentPage = 1, pageSize = null) {
    let params = new HttpParams().set('page', currentPage.toString());
    params = ObjectHelper.isUndefinedNullOrEmpty(keywords) ? params : params.set('keywords', keywords);
    params = ObjectHelper.isUndefinedNullOrEmpty(pageSize) ? params : params.set('pageSize', pageSize);

    return this.http.get<SiteContactList>(environment.apiUrl + this._ENDPOINT, { params: params });
  }

  /**
   * Retrieves a list of site contacts for a given trial/site combination
   * @param trialId
   * @param siteId
   * @param currentPage
   * @param pageSize
   */
  receiveByTrialAndSite(trialId: string, siteId: string, currentPage = 1, pageSize = null) {
    let params = new HttpParams().set('page', currentPage.toString());
    params = ObjectHelper.isUndefinedNullOrEmpty(pageSize) ? params : params.set('pageSize', pageSize);

    return this.http.get<TrialSiteContactList>(environment.apiUrl + this._ENDPOINT + 'trial/' + trialId + '/site/' + siteId, { params: params });
  }

  /**
   * Retrieve a single site contact
   * @param id admin Id
   */
  retrieveSiteContact(id: string) {
    return this.http.get<SiteContact>(environment.apiUrl + this._ENDPOINT + id);
  }

  /**
   * Updates an existing site contact
   * @param id the Id of the site contact to update
   * @param firstname site contact users firstname
   * @param lastname site contact users lastname
   * @param active is the site contact active
   * @param sites the sites to assign the site contact to
   */
  updateSiteContact(id: string, firstname: string, lastname: string, active: boolean, sites: { siteId: string, trialId: string, role: number }[]) {
    const body = {
      Firstname: firstname,
      Lastname: lastname,
      Active: active,
      Sites: sites
    };

    return this.http.put(environment.apiUrl + this._ENDPOINT + id, body);
  }

  /**
   * Creates a new site contact
   * @param id the Id of the site contact to update
   * @param firstname site contact users firstname
   * @param lastname site contact users lastname
   * @param email site contact users email
   * @param active is the site contact active
   * @param consent has the site contact consented to the terms of use
   * @param sites the sites to assign the site contact to
   */
  createSiteContact(firstname: string, lastname: string, email: string, active: boolean, consent: boolean, sites: { siteId: string, trialId: string, role: number }[]) {
    return this.http.post(environment.apiUrl + this._ENDPOINT, {
      Firstname: firstname,
      Lastname: lastname,
      Email: email,
      Active: active,
      Consent: consent,
      Sites: sites
    });
  }

  sendPatientRegistrationMail(siteContactId: string): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}${this._ENDPOINT}patient-registration/send-email/${siteContactId}`, null);
  }

  createPatientRegistration(request: CreatePatientRegistrationRequest): Observable<any> {
    return this.anonymousHttpClient.post<any>(`${environment.apiUrl}${this._ENDPOINT}patient-registration`, request);
  }

  uploadFile(blob: Blob, siteContactId: string, patientRegistrationCode: string): Observable<UploadSignatureResponse> {
    const formData = new FormData();
    formData.append('file', blob);

    return this.anonymousHttpClient.post<UploadSignatureResponse>(`${environment.apiUrl}${this._ENDPOINT}upload-file/${siteContactId}/${patientRegistrationCode}`, formData);
  }
}
