import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertService } from "../alert/alert.service";
import { AutosuggestDropdownInputComponent } from "../autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { SiteService } from "../../core/services/site.service";
import { Countries } from "../../core/constants/countries";
import { LogHelper } from "../../core/helpers/log.helper";
import { SelectOption } from "../../core/models/select-option.model";
import { ApiConsumerService } from "../../core/services/api-consumer.server";
import { ApiConsumerSimple } from "../../core/models/api-consumer-simple.model";

@Component({
  selector: 'app-create-site-modal',
  templateUrl: './create-site-modal.component.html'
})
export class CreateSiteModalComponent {
  @ViewChild('modalWindow') modalWindow: ModalComponent;
  @ViewChild('countryDropdown') countryDropdown: AutosuggestDropdownInputComponent;

  @Output('siteCreated') siteCreated = new EventEmitter();
  @Output('createdSite') createdSite = new EventEmitter();

  @Input('trialId') trialId: string = null;

  isOpen = false;
  form: UntypedFormGroup;
  countryOptions: { value: string, text: string }[] = [];
  isProcessing = false;
  apiConsumerOptions: SelectOption[] = [];

  constructor(private alertService: AlertService, private apiConsumerService: ApiConsumerService, private siteService: SiteService) {
    for (let country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      irgSiteId: new UntypedFormControl(''),
      siteAddress: new UntypedFormControl(null, Validators.required),
      apiConsumer: new UntypedFormControl(false),
      apiConsumerId: new UntypedFormControl('')
    });

    this.loadAndSetApiConsumerOptions();
  }

  open(): void {
    this.isOpen = true;
    this.form.reset();
    this.modalWindow.show();
    this.countryDropdown.onClear();
  }

  close(): void {
    this.isOpen = false;
    this.modalWindow.hide();
  }

  /**
   * Retrieves and sets the api consumer options to be used in the api consumer dropdown
   */
  loadAndSetApiConsumerOptions(): void {
    this.apiConsumerService.getAllApiConsumers().subscribe({
      next: (apiConsumers: any) => {
        this.apiConsumerOptions = apiConsumers.map((apiConsumer: ApiConsumerSimple) => {
          const consumerName = apiConsumer.enabled ? apiConsumer.name : `${apiConsumer.name} (Temporarily disabled)`;
          return new SelectOption(apiConsumer.id, consumerName);
        });
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isProcessing = true;

      this.siteService.createSite(
        this.form.get('name').value,
        this.form.get('address').value,
        this.form.get('country').value,
        this.form.get('irgSiteId').value,
        this.form.get('siteAddress').value,
        this.form.get('apiConsumerId').value
      ).subscribe({
        next: (site) => {
          let siteId = site.id;
          this.createdSite.emit(site);

          this.isProcessing = false;
          this.alertService.showSuccessAlert('Site Added Successfully');
          this.siteCreated.emit(siteId);
          this.form.reset();
          this.close();
        },
        error: (error) => {
          LogHelper.log(error);
          this.isProcessing = false;
          this.alertService.showWarningAlert('Unable to create site, please try again!');
        }
      });
    }
  }

}
