export class PatientNote {
  public id: string;
  public note: string;
  public author: string;
  public created: Date;

  public static fromObject(obj: any) {
    let note = new PatientNote();
    note.id = obj.id;
    note.note = obj.note;
    note.author = obj.author;
    note.created = new Date(obj.created);

    return note;
  }
}
