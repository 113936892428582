<div class="std-results">

  <!-- Start: View head -->
  <div class="view-head">
    <div class="row">
      <div class="col-12">
        <div class="back-btn">
          <a (click)="onBackClick()" class="btn btn-circle" aria-hidden="true"><i class="icon-left"
              aria-hidden="true"></i></a>
        </div>

        <h2>Trials Assigned to {{ site.name }}</h2>
      </div>
    </div>
  </div>
  <!-- End: View head -->

  <!-- Start: View body -->
  <div class="view-body">
    <div class="content-panel">
      <table class="std-results-list" *ngIf="site !== null && site.trials !== null">
        <tbody>
          <tr style="color: gray;">
            <th style="width: 10%;">Site Number</th>
            <th>Public Trial Code</th>
            <th>Nickname</th>
            <th>Opportunity Number</th>
            <th style="width: 10%;">Status</th>
            <th style="width: 20%;"></th>
          </tr>
          <tr data-toggle="link" data-toggle-class="more-btn" *ngFor="let trial of site.trials; let i = index;">
            <td><span class="result-name">{{ trial.siteNumber }} </span></td>
            <td>
              <span class="badge-reference-wrap">
                <span class="result-reference">{{ trial.code }}</span>
              </span>
            </td>
            <td>
              <span class="badge-reference-wrap">
                <span class="result-reference">{{ trial.nickname }}</span>
              </span>
            </td>
            <td>
              <span class="badge-reference-wrap">
                <span class="result-reference">{{ trial.opportunityNumber }}</span>
              </span>
            </td>
            <td><span class="result-name">{{ this.mapStatus(trial.approvalStatus) }} </span></td>
            <td class="result-btn negative-margin text-right">
              <a routerLink="/trial/{{ trial.id }}/site/{{ site.id }}/edit" class="btn btn-secondary btn-edit"
                *ngIf="authService.hasPermission(Permissions.SiteTrialEdit)" style="margin-right: 5px;">Edit</a>
              <a (click)="onUnassignTrial(trial.id)" *ngIf="authService.hasPermission(Permissions.SiteUnassignTrial) && !trial.trialApiEnabled"
                class="btn btn-primary btn-slim">Unassign</a>
            </td>
          </tr>
        </tbody>
      </table>


      <div class="no-results inside" *ngIf="site === null || site.trials === null || site.trials.length == 0">
        <div class="inner">
          <div class="image">
            <img src="assets/img/no-results-illustration.png" alt="No results found" />
          </div>
          <div class="content">
            <h2>No Results Found</h2>
            <p>There are no trials assigned to this site.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: View body -->

</div>

<!-- Start: Unassign site modal -->
<app-modal #deleteSiteModal customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Unassign Site from Trial?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to unassign the site from this trial?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteSiteModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmUnassignTrial()" class="btn btn-primary">
          <span *ngIf="isUnassigning">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!isUnassigning">Yes, unassign</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign sites modal -->
