<div class="view-head">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="heading-small with-padding">Add Site Contact</h2>
    </div>
  </div>
</div>

<div class="view-body">
  <div class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="std-tabbed-content margin-after-tabs">
        <div class="tabs-wrap">
          <app-mobile-tabs #mobileTabs (onSelected)="onTabClick($event)">
            <ul>
              <li><a class="selected" (click)="mobileTabs.onSelectItem('info', $event)">User Info</a></li>
              <li><a class="selected" (click)="mobileTabs.onSelectItem('sites', $event)">Assigned Trial Sites</a></li>
            </ul>
          </app-mobile-tabs>

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 'info'}" (click)="onTabClick('info')">User
                Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 'site-contacts'}" (click)="onTabClick('site-contacts')">Assigned
                Trial Sites</a>
            </li>
          </ul>
        </div>

        <div class="results-wrap">

          <div class="tab-content">
            <form [formGroup]="form" (submit)="onFormSubmit()">

              <!-- Start: User info -->
              <div [ngClass]="{'visibility-hidden': selectedTab !== 'info'}">

                <table class="simple-form">
                  <tr>
                    <th><label>First Name</label></th>
                    <td>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="firstname"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><label>Last Name</label></th>
                    <td>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="lastname"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><label>Email Address</label></th>
                    <td>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="email"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style="vertical-align: middle"><label>Status</label></th>
                    <td>
                      <app-dropdown-input #statusDropdown [parentForm]="form" [options]="statusOptions" name="active"></app-dropdown-input>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <div class="checkbox-wrap last">
                        <div class="form-group">
                          <label class="checkbox">
                            <input type="checkbox" id="consent" formControlName="consent">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="consent">I confirm that I have validated consent</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- End: User info -->

              <!-- Start: Assigned Project Sites -->
              <div [ngClass]="{'visibility-hidden': selectedTab !== 'site-contacts'}">

                <app-manage-user-sites #siteManager></app-manage-user-sites>

              </div>
              <!-- End: Assigned Project Sites -->

              <!-- Start: Form Actions -->
              <table class="form-buttons" [ngClass]="{'visibility-hidden': hideFormButtons}">
                <tbody>
                <tr>
                  <th></th>
                  <td>
                    <div class="btn-wrap">
                      <div class="row">
                        <div class="col-6">
                          <a routerLink="/admin" fragment="site-contacts" class="btn btn-secondary">Cancel</a>
                        </div>
                        <div class="col-6">
                          <button type="submit" class="btn btn-primary"
                                  [disabled]="!form.valid || form.get('processing').value">
                                <span *ngIf="form.get('processing').value">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  <span class="sr-only">Loading...</span>
                                </span>
                            <span *ngIf="!form.get('processing').value">Add Site Contact</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <!-- End: Form Actions -->

            </form>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
