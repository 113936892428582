import { Permissions } from './../../../../core/constants/permissions';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PatientService } from "../../../../core/services/patient.service";
import { AuthService } from "../../../../core/services/auth.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientList, PatientListItem } from "../../../../core/models/patient-list.model";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { StringHelper } from '../../../../core/helpers/string-helper';
import { Subscription } from "rxjs";
import { OrderBy } from 'app/core/models/expense.model';

@Component({
  selector: 'app-patient-pending',
  templateUrl: './patient-pending.component.html',
  styleUrls: ['./patient-pending.component.scss']
})
export class PatientPendingComponent implements OnInit, AfterViewInit {
  @Output() pendingPatientCountChanged = new EventEmitter<number>();
  @Output() patientSelectedForApproval = new EventEmitter<PatientListItem>();
  @Output() patientSelectedForRejection = new EventEmitter<PatientListItem>();

  @ViewChild('approvePatientModal') approvePatientModal: ModalComponent;

  state = 1;
  country: string;
  keywords: string;
  orderBy = 0;
  results = new PatientList();
  hasResults = true;
  stringHelper = StringHelper;
  Permissions = Permissions;
  OrderBy = OrderBy;
  subscription: Subscription;

  constructor(public authService: AuthService, private readonly patientService: PatientService,
     private readonly alertService: AlertService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadPatients(1, null, null);

  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  /**
   * Updates the keywords used to filter results, usually called by the parent component to let this component know
   * that the keywords have been changed so this component can call the API and update the patient list
   * @param keywords filter by these keywords
   * @param country
   */
  updateFilter(keywords: string, country: string, orderBy: number = 0) {
    this.keywords = keywords === '' ? null : keywords;
    this.country = country === '' ? null : country;
    this.orderBy = orderBy;
    this.loadPatients(1, keywords, country, orderBy);
  }

  /**
   * Loads patients from the API and stores them to be used in the patient list
   * @param page get results on page
   * @param keywords get results matching keyword
   * @param country
   * @param orderBy
   */
  loadPatients(page: number, keywords: string, country: string, orderBy: number = 0) {
    // If page NULL, then it means we need to stay on the current page
    if (page == null) {
      page = this.results.currentPage;
    }

    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.patientService.retrievePatients(this.state, keywords, country, page, orderBy).subscribe({
      next: patientList => {
        this.results = patientList;
        this.pendingPatientCountChanged.emit(this.results.results.length);

        if (keywords === null && patientList.results.length === 0) {
          this.hasResults = false;
        }

        // If the current page is more than the number of pages available, use reload using the last page
        if (page > patientList.totalPageCount && patientList.totalRecordCount > 0) {
          this.loadPatients(patientList.totalPageCount, keywords, country);
        }
      },
      error: () => {
        this.alertService.showWarningAlert('Unable to load approved patients!');
      }
    });
  }

  /**
   * When the 'Approve' button is clicked for a patient, this method renders the approve patient modal
   * @param patient the patient being approved
   */
  onApprovePatient(patient: PatientListItem) {
    this.patientSelectedForApproval.emit(patient);
  }

  /**
   * Initiates the process of marking a patient as rejected
   * @param patient the patient being rejected
   */
  onRejectPatient(patient: PatientListItem) {
    const index: number = this.results.results.indexOf(patient);
    if (index !== -1) {
      this.results.results.splice(index, 1);
    }

    this.patientSelectedForRejection.emit(patient);
  }
}
