import { NgModule } from "@angular/core";
import { DndDirective } from "./directives/dnd.directive";
import { ReplaceNewLinePipe } from "./pipes/replace-new-line.pipe";
import { CurrencyDecimalAmountPipe } from "./pipes/currency-decimal-amount.pipe";
import { DecimalPipe } from "@angular/common";

@NgModule({
  providers: [DecimalPipe, CurrencyDecimalAmountPipe],
  declarations: [DndDirective, ReplaceNewLinePipe, CurrencyDecimalAmountPipe],
  imports: [],
  exports: [ReplaceNewLinePipe, CurrencyDecimalAmountPipe],
})
export class CoreModule {}
