<div *ngIf="dataLoaded" class="payment-details">
  <div class="payment-info">
    <span class="patient-name">{{expenses.patientName}}</span>
    <span class="total-expense">
      {{expenses.currency}} {{expenses.totalExpense / 100 | number:'1.2-2' }}
      <span *ngIf="selectedTab === ExpenseClaimState.CaxtonApproved && expenses.displayBankFeeCover">
        +
        {{expenses.currency}} {{expenses.bankFeeCover | number:'1.2-2'}} fee
      </span>
    </span>
  </div>
  <div class="payment-buttons">
    <button *ngIf="selectedTab === ExpenseClaimState.Processing && authService.hasPermission(Permissions.ExpenseWrite)"
      [disabled]="processingRequest" class="large-button investigation-button"
      (click)="caxtonFailed.emit(expenses.expenses)">
      Requires Investigation
    </button>

    <button
      *ngIf="selectedTab === ExpenseClaimState.CaxtonApproved && authService.hasPermission(Permissions.ExpenseWrite)"
      (click)="sendToFinance.emit(expenses.expenses)" [disabled]="processingRequest" class="send-to-finance-button">
      Switch to Manual Payment
    </button>

    <button
      *ngIf="expenses.paymentMethod === ExpenseClaimPaymentMethod.CaxtonAutomatedBankTransfer &&
                   selectedTab === ExpenseClaimState.CaxtonApproved && authService.hasPermission(Permissions.ExpenseWrite)"
      [disabled]="processingRequest" class="large-button pay-button" (click)="payByBank.emit(expenses.expenses)">
      <span *ngIf="processingRequest">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!processingRequest">Pay all by Caxton Bank</span>
    </button>

    <button
      *ngIf="expenses.paymentMethod === ExpenseClaimPaymentMethod.CaxtonCard &&
                   selectedTab === ExpenseClaimState.CaxtonApproved && authService.hasPermission(Permissions.ExpenseWrite)"
      [disabled]="processingRequest" class="large-button pay-button" (click)="payByCard.emit(expenses.expenses)">
      <span *ngIf="processingRequest">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!processingRequest">Pay all by Caxton Card</span>
    </button>

    <button
      *ngIf="selectedTab === ExpenseClaimState.CaxtonFailed && authService.hasPermission(Permissions.ExpenseWrite)"
      [disabled]="processingRequest" class="btn btn-outline" (click)="investigation.emit(expenses.expenses)">
      Not on Caxton - Retry
    </button>

    <button
      *ngIf="selectedTab === ExpenseClaimState.CaxtonFailed && authService.hasPermission(Permissions.ExpenseWrite)"
      [disabled]="processingRequest" class="btn btn-primary" (click)="transfer.emit(expenses)">
      Found on Caxton
    </button>
  </div>
</div>

<div *ngIf="!dataLoaded" class="payment-details">
  <div class="payment-info">
    <div class="loaders">
      <ngx-skeleton-loader style="width: 40%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 15%;"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="payment-buttons">
    <div class="loaders">
      <ngx-skeleton-loader style="width: 211px;" class="investigation-button-loader"
        *ngIf="selectedTab === ExpenseClaimState.Processing && authService.hasPermission(Permissions.ExpenseWrite)"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 211px;"
        *ngIf="selectedTab === ExpenseClaimState.CaxtonApproved && authService.hasPermission(Permissions.ExpenseWrite)"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 222px;" class="payment-button-loader"
        *ngIf="selectedTab === ExpenseClaimState.CaxtonApproved && authService.hasPermission(Permissions.ExpenseWrite)"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 160px;" class="failed-button-loader"
        *ngIf="selectedTab === ExpenseClaimState.CaxtonFailed && authService.hasPermission(Permissions.ExpenseWrite)"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 136px;" class="failed-button-loader"
        *ngIf="selectedTab === ExpenseClaimState.CaxtonFailed && authService.hasPermission(Permissions.ExpenseWrite)"></ngx-skeleton-loader>
    </div>
  </div>
</div>
