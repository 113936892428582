<app-modal #modalWindow customClassName="create-site">
  <div class="modal-header">
    <h2 class="modal-title">Add New Site</h2>
  </div>
  <div class="modal-body">

    <form [formGroup]="form" (submit)="onSubmit()">

      <table class="simple-form">
        <tr>
          <th><label>Site Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="name">
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Global IRG Site Id</label></th>
          <td>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="irgSiteId">
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Country</label></th>
          <td>
            <div class="form-group">
              <app-autosuggest-dropdown-input #countryDropdown [required]="true" [options]="countryOptions" placeholder="Select Country..." [parentForm]="form" controlName="country"></app-autosuggest-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Site Address</label></th>
          <td>
            <div class="form-group">
              <app-input-address [parentForm]="form" controlName="siteAddress" [countryRequired]="true"></app-input-address>
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="checkbox-wrap last">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="apiConsumer" formControlName="apiConsumer" value="true">
                  <span></span>
                </label>
                <label class="checkbox-label" for="apiConsumer">Site belongs to API consumer?</label>
              </div>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': !form.get('apiConsumer').value}">
          <th><label for="visit-count">API Consumer</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #apiConsumerSelect id="apiConsumerId" [options]="apiConsumerOptions"
                                  [parentForm]="form" name="apiConsumerId" placeholder="Select Api Consumer..."></app-dropdown-input>
            </div>
          </td>
        </tr>
      </table>

    </form>

  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="modalWindow.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onSubmit()" class="btn btn-primary" [ngClass]="{'disabled': isProcessing}">
            <span *ngIf="isProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
          <span *ngIf="!isProcessing">Add Site</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
