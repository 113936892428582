import { ExpenseClaimState } from './../../../core/models/expense.model';
import { CloseInvestigationModalComponent } from './../close-investigation-modal/close-investigation-modal.component';
import { Permissions } from '../../../core/constants/permissions';
import { finalize } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { ExpenseClaimsResponsePage } from "../../../core/models/search-page.model";
import {
  AddExpenseClaimNoteRequest,
  ApproveExpenseClaimResult,
  CaxtonIncompatibleData,
  ExpenseCategoryType,
  ExpenseClaimPaidBy,
  ExpenseClaimPaymentMethod,
  ExpenseClaimPaymentMethodString,
  ExpenseClaimViewModel,
  ExpensePatientGroup,
  GetExpenseClaimsRequest,
  MoveToCaxtonFailedRequest,
  NotOnCaxtonRetryRequest,
  OrderBy,
  OrderByString,
  PayByCaxtonBankRequest,
  SendToFinanceRequest,
  SetCaxtonTransferIdRequest
} from "../../../core/models/expense.model";
import { LogHelper } from "../../../core/helpers/log.helper";
import { PaymentMethodString } from "../../../core/models/patient.model";
import { DateHelper } from "../../../core/helpers/date-helper";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { ExpenseService } from "../../../core/services/expense.service";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { StringHelper } from "../../../core/helpers/string-helper";
import { CreateExpenseModalComponent } from '../create-expense-modal/create-expense-modal.component';
import { EditExpenseModalComponent } from '../edit-expense-modal/edit-expense-modal.component';
import { Currencies } from 'app/core/constants/currency';
import { CheckExpenseModalComponent } from '../check-expense-modal/check-expense-modal.component';
import { SearchInputComponent } from 'app/shared/search-input/search-input.component';
import { StartInvestigationModalComponent } from '../start-investigation-modal/start-investigation-modal.component';
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import { ExpenseAddNotesComponent } from './expense-add-notes/expense-add-notes.component';
import { OverBudgetRequestDetailsStatus } from 'app/core/models/project-management.model';

@Component({
  selector: 'app-expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss']
})
export class ExpenseListComponent implements OnInit, AfterViewInit {
  claimsResponse: ExpenseClaimsResponsePage<ExpenseClaimViewModel>;
  caxtonClaimsResponse: ExpenseClaimsResponsePage<ExpensePatientGroup>;
  claimsCount: { [key in ExpenseClaimState]: number };
  ExpenseClaimState = ExpenseClaimState;
  expenseClaimsRequest: GetExpenseClaimsRequest;

  // Export
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('exportExpensesModal') exportExpensesModal: ModalComponent;
  @ViewChild('foundOnCaxtonModal') foundOnCaxtonModal: ModalComponent;
  @ViewChild('sendToFinanceModal') sendToFinanceModal: ModalComponent;
  @ViewChild('confirmExpenseApprove') confirmExpenseApproveModal: ModalComponent;
  @ViewChild('caxtonFailedFindingModal') caxtonFailedFindingModal: ModalComponent;
  @ViewChild('searchInput') searchInput: SearchInputComponent;
  exportIsProcessing = false;
  exportForm: UntypedFormGroup;

  @ViewChild('checkExpenseModal') checkExpenseModal: CheckExpenseModalComponent;
  @ViewChild('createExpenseModal') createExpenseModal: CreateExpenseModalComponent;
  @ViewChild('editExpenseModal') editExpenseModal: EditExpenseModalComponent;
  @ViewChild('startInvestigationModal') startInvestigationModal: StartInvestigationModalComponent;
  @ViewChild('closeInvestigationModal') closeInvestigationModal: CloseInvestigationModalComponent;
  @ViewChild('addNoteModal') addNoteModal: ExpenseAddNotesComponent;
  @ViewChild('rejectExpenseModal') rejectExpenseModal: ModalComponent;
  @ViewChild('approveDistanceModal') approveDistanceModal: ModalComponent;
  @ViewChild('currencySelect') currencySelect: DropdownInputComponent;
  @ViewChild('orderBySelect') orderBySelect: DropdownInputComponent;

  Permissions = Permissions;

  page: number = 1;
  searchForm: UntypedFormGroup;
  selectedTab = ExpenseClaimState.Pending;
  keywords: string;
  stringHelper = StringHelper;

  caxtonTransferModalAmount: number;
  caxtonTransferModalCurrency: string;
  caxtonTransferModalRecipient: string;
  caxtonTransferModalExpenses: ExpenseClaimViewModel[];
  caxtonTransferModalDate: Date;
  caxtonTransferModalPaymentMethod: ExpenseClaimPaymentMethod;
  ExpenseClaimPaymentMethod = ExpenseClaimPaymentMethod;

  rejectForm: UntypedFormGroup;
  approveDistanceForm: UntypedFormGroup;
  foundOnCaxtonForm: UntypedFormGroup;

  rejectFormIsProcessing = false;
  foundOnCaxtonFormProcessing = false;
  processingRequest = false;
  processingDistanceApproveRequest = false;

  currencyOptions: { value: string, text: string }[] = [];
  orderByOptions: { value: string, text: string }[] = [];

  investigationFormProcessing = false;
  expenseClaimPaidBy = ExpenseClaimPaidBy;
  paymentMethodString = PaymentMethodString;
  expenseClaimPaymentMethodString = ExpenseClaimPaymentMethodString;
  expenseClaimPaymentMethod = ExpenseClaimPaymentMethod;

  sendToFinanceRequest: SendToFinanceRequest;
  @ViewChild('sendToFinanceReason') sendToFinanceReason: ElementRef<HTMLTextAreaElement>;
  @ViewChild('caxtonFailedFinding') caxtonFailedFinding: ElementRef<HTMLTextAreaElement>;
  sendToFinanceExpenses: ExpenseClaimViewModel[] = [];

  exportDateOptions: { value: string, text: string }[] = [];
  dateHelper = DateHelper;
  caxtonIncompatibleData: CaxtonIncompatibleData;
  confirmExpenseClaimApprovalProcessing: boolean = false;

  caxtonFailedFindingsExpenses: ExpenseClaimViewModel[] = [];

  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  caxtonLoaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

  constructor(public authService: AuthService, private lightbox: Lightbox,
    private templateService: TemplateService, private alertService: AlertService,
    private cd: ChangeDetectorRef, private expenseService: ExpenseService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.templateService.showHeader();
    // Export sites form
    this.exportForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl(),
      filterBy: new UntypedFormControl('0')
    });

    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    this.initOrderBySelection();

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl('')
    });

    this.rejectForm = new UntypedFormGroup({
      expenseId: new UntypedFormControl('', Validators.required),
      reason: new UntypedFormControl('', Validators.required)
    });

    this.approveDistanceForm = new UntypedFormGroup({
      expenseId: new UntypedFormControl('', Validators.required),
      currency: new UntypedFormControl('', Validators.required),
      amount: new UntypedFormControl('', Validators.required)
    });

    this.foundOnCaxtonForm = new UntypedFormGroup({
      transferId: new UntypedFormControl(''),
      caxtonFailedFindings: new UntypedFormControl('')
    });

    // Apply filter from service
    if (this.expenseService.filterKeywords !== null) {
      this.searchForm.patchValue({ keywords: this.expenseService.filterKeywords });
      this.keywords = this.expenseService.filterKeywords;
    }

    this.expenseClaimsRequest = {
      page: this.page,
      state: ExpenseClaimState.Pending,
      orderBy: localStorage.getItem("order_by") === "1" ? OrderBy.Newest : OrderBy.Oldest,
      onHold: localStorage.getItem("on_hold") === "true",
      investigations: localStorage.getItem("investigations") === "true"
    }

    this.dataLoaded = false;
    this.getExpenses();

    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment != null && fragment !== '') {
        this.selectedTab = ExpenseClaimState[fragment];
      }
    });

    this.exportDateOptions.push({ value: '0', text: 'Approved Date' });
    this.exportDateOptions.push({ value: '1', text: 'Expense Submitted Date' });
    this.exportDateOptions.push({ value: '2', text: 'Paid Date' });
  }

  getExpenses() {
    if (this.isCaxtonTab()) {
      this.expenseService.getCaxtonExpenseClaims(this.expenseClaimsRequest).subscribe({
        next: response => {
          this.caxtonClaimsResponse = response;
          this.claimsCount = response.claimsCount;
          this.dataLoaded = true;
          this.processingRequest = false;
        },
        error: err => {
          LogHelper.log(err);
          this.dataLoaded = true;
          this.processingRequest = false;
        }
      });
    }
    else {
      this.expenseService.getExpenseClaims(this.expenseClaimsRequest).subscribe({
        next: response => {
          this.claimsResponse = response;
          this.claimsCount = response.claimsCount;
          this.dataLoaded = true;
          this.processingRequest = false;
        },
        error: err => {
          LogHelper.log(err);
          this.dataLoaded = true;
          this.processingRequest = false;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.orderBySelect.setValue(localStorage.getItem("order_by") ?? OrderBy.Oldest.toString());
    this.subscribeOrderByChanges();

    this.cd.detectChanges();
  }

  getExpenseStateString(state: ExpenseClaimState) {
    return Object.values(ExpenseClaimState)[state];
  }

  subscribeOrderByChanges() {
    this.orderBySelect?.selectValueChanged.subscribe(value => {
      this.expenseClaimsRequest.orderBy = value === "0" ? OrderBy.Oldest : OrderBy.Newest;
      localStorage.setItem("order_by", this.expenseClaimsRequest.orderBy.toString());
      this.dataLoaded = false;

      this.getExpenses();
    })
  }

  initOrderBySelection() {
    this.orderByOptions.push({ value: OrderBy.Oldest.toString(), text: OrderByString.Values[OrderBy.Oldest] });
    this.orderByOptions.push({ value: OrderBy.Newest.toString(), text: OrderByString.Values[OrderBy.Newest] });
  }

  loadPage(page: number, keywords: string, state: ExpenseClaimState) {
    this.dataLoaded = false;
    this.expenseClaimsRequest.page = page;
    this.expenseClaimsRequest.keywords = keywords;
    this.expenseClaimsRequest.state = state;

    this.getExpenses();
  }

  onEditExpense(expenseId: string) {
    this.editExpenseModal.show(expenseId);
  }

  onTabSelect(selectedTab: ExpenseClaimState) {
    this.selectedTab = selectedTab;
    this.expenseClaimsRequest.state = selectedTab;
    this.dataLoaded = false;
    this.page = 1;
    this.expenseClaimsRequest.page = 1;
    this.getExpenses();
  }

  onSearchInputChanged(keywords: string) {
    this.page = 1;
    this.expenseClaimsRequest.page = 1;
    this.dataLoaded = false;
    this.keywords = keywords;
    this.expenseClaimsRequest.keywords = keywords;
    this.getExpenses();
  }

  viewReceipt(imageUrl: string) {
    const album: { src: string, caption: string, thumb: string, downloadUrl: string }[] = [];
    album.push({ src: imageUrl, caption: '', thumb: imageUrl, downloadUrl: '' });

    this.lightbox.open(album, 0);
  }

  onApproveDistanceExpenseClaim() {
    this.processingDistanceApproveRequest = true;
    let expenseId = this.approveDistanceForm.get('expenseId').value;
    let currency = this.currencySelect.selectedValue;
    let amount = this.approveDistanceForm.get('amount').value;

    if (this.approveDistanceForm.valid) {
      this.expenseService.approveExpenseClaim(expenseId, this.caxtonIncompatibleData?.caxtonIncompatibleReason, currency, amount).subscribe({
        next: result => {
          this.processingDistanceApproveRequest = false;
          this.confirmExpenseClaimApprovalProcessing = false;
          this.approveDistanceModal.hide();

          this.handleApproveExpenseResponse(result, expenseId, ExpenseCategoryType.Distance);
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert(error.error.title);
          this.processingDistanceApproveRequest = false;
          this.confirmExpenseClaimApprovalProcessing = false;
        }
      });
    }
  }

  onApproveExpenseClaim(expense: ExpenseClaimViewModel) {
    if (expense.categoryType === ExpenseCategoryType.Distance) {
      this.currencySelect.reset();

      this.approveDistanceForm.patchValue({
        expenseId: expense.id,
        currency: expense?.currency,
        amount: expense?.amount / 100
      });

      if (expense) {
        this.currencySelect.setValue(expense.currency);
      }

      this.approveDistanceModal.show();
    } else {
      this.processingRequest = true;
      this.expenseService.approveExpenseClaim(expense.id, null, null, null).subscribe({
        next: result => {
          this.handleApproveExpenseResponse(result, expense.id, expense.categoryType);
        },
        error: error => {
          LogHelper.log(error);
          this.processingRequest = false;
          this.alertService.showWarningAlert('Unable to approve expense claim!');
        }
      });
    }
  }

  confirmExpenseClaimApproval() {
    this.confirmExpenseClaimApprovalProcessing = true;

    if (this.caxtonIncompatibleData.type === ExpenseCategoryType.Distance) {
      this.onApproveDistanceExpenseClaim();
    } else {
      this.expenseService.approveExpenseClaim(this.caxtonIncompatibleData.expenseClaimId, this.caxtonIncompatibleData.caxtonIncompatibleReason, null, null).subscribe({
        next: result => {
          this.handleApproveExpenseResponse(result, this.caxtonIncompatibleData.expenseClaimId, this.caxtonIncompatibleData.type);
          this.confirmExpenseClaimApprovalProcessing = false;
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert(error.error.title);
          this.confirmExpenseApproveModal.hide();
          this.confirmExpenseClaimApprovalProcessing = false;
        },
        complete: () => {
          this.confirmExpenseClaimApprovalProcessing = false;
        }
      });
    }
  }

  handleApproveExpenseResponse(result: ApproveExpenseClaimResult, expenseId: string, type: ExpenseCategoryType) {
    if (!result.approved) {
      this.caxtonIncompatibleData = {
        caxtonIncompatibleReason: result.caxtonIncompatibleMessage,
        expenseClaimId: expenseId,
        type: type
      };

      this.confirmExpenseApproveModal.show();
      this.processingRequest = false;
      return;
    }

    this.caxtonIncompatibleData = null;
    this.alertService.showSuccessAlert('Expense Claim Successfully Approved.');

    this.getExpenses();

    this.confirmExpenseApproveModal.hide();
  }

  closeConfirmExpenseApproveModal() {
    this.caxtonIncompatibleData = null;
    this.confirmExpenseApproveModal.hide();
  }

  onCurrencySelectChanged(value: string) {
    this.approveDistanceForm.patchValue({ currency: value });
  }

  onConfirmRejectExpense() {
    this.rejectFormIsProcessing = true;
    this.expenseService.rejectExpenseClaim(this.rejectForm.get('expenseId').value, this.rejectForm.get('reason').value).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Expense Claim Successfully Rejected.');
        this.rejectExpenseModal.hide();

        this.getExpenses();
        this.rejectFormIsProcessing = false;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert(error.error.title);
        this.rejectExpenseModal.hide();
      }
    });
  }

  onRejectModal(expenseId: string) {
    this.rejectForm.patchValue({
      expenseId: expenseId,
      reason: ''
    });
    this.rejectExpenseModal.show();
  }

  onMarkAsPaid(expenseId: string) {
    this.processingRequest = true;
    this.expenseService.markExpenseClaimAsPaid(expenseId).subscribe({
      next: () => {
        this.getExpenses();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Sorry, there was a problem!');
        this.processingRequest = false;
      }
    });
  }

  /**
   * Updates the angular form when you mouse over the export button, it pulls the dates from the fields and puts them
   * into the form. It's a bodge to fix an issue with the jQuery datepicker and angular
   */
  updateExportFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  onCloseInvestigation(claimId: string) {
    this.closeInvestigationModal.expenseClaimId = claimId;
    this.closeInvestigationModal.modal.show();
  }

  onExportChangeLog() {
    this.exportIsProcessing = true;
    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;
    const filterBy = this.exportForm.get('filterBy').value;

    this.expenseService.exportExpenses(from, to, filterBy).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportExpensesModal.hide();
        this.exportIsProcessing = false;
        this.exportForm.patchValue({
          dateFrom: '',
          dateTo: ''
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.exportExpensesModal.hide();
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }

  sendToFinance(expenses: ExpenseClaimViewModel[]) {
    this.sendToFinanceExpenses = expenses;
    this.sendToFinanceModal.show();
  }

  onConfirmSendToFinance() {
    this.processingRequest = true;

    const request: SendToFinanceRequest = {
      expenseClaimsIds: this.sendToFinanceExpenses.map(x => x.id),
      reason: this.sendToFinanceReason.nativeElement.value
    };

    this.expenseService.sendCaxtonExpenseClaimsToFinance(request).subscribe({
      next: () => {
        this.processingRequest = false;

        this.getExpenses();
        this.sendToFinanceModal.hide();
      },
      error: () => {
        this.processingRequest = false;
        this.alertService.showWarningAlert('Operation failed, please try again or contact an administrator');
      }
    });
  }

  payByCaxtonBank(expenses: ExpenseClaimViewModel[]) {
    this.processingRequest = true;

    const request: PayByCaxtonBankRequest = {
      expenseClaimsIds: expenses.map(x => x.id),
      requestId: uuidv4()
    };

    this.expenseService.payByCaxtonBank(request).subscribe(
      {
        next: () => {
          this.alertService.showSuccessAlert('Payment successfully initialized.');
          this.getExpenses();
        },
        error: error => {
          this.getExpenses();
          this.alertService.showWarningAlert(error.error.title);
        }
      }
    );
  }

  payByCaxtonCard(expenses: ExpenseClaimViewModel[]) {
    this.processingRequest = true;

    const request: PayByCaxtonBankRequest = {
      expenseClaimsIds: expenses.map(x => x.id),
      requestId: uuidv4()
    };

    this.expenseService.payByCaxtonCard(request).subscribe(
      {
        next: () => {
          this.alertService.showSuccessAlert('Payment successfully initialized.');
          this.getExpenses();
        },
        error: error => {
          this.getExpenses();
          this.alertService.showWarningAlert(error.error.title);
        }
      }
    );
  }

  openCloseInvestigationModal(expenses: ExpenseClaimViewModel[]) {
    this.caxtonFailedFindingsExpenses = expenses;
    this.caxtonFailedFindingModal.show();
  }

  closeCaxtonFailedFindingModal() {
    this.caxtonFailedFindingModal.hide();
    this.caxtonFailedFindingsExpenses = null;
  }

  closeInvestigation() {
    this.processingRequest = true;
    const request: NotOnCaxtonRetryRequest = {
      expenseClaimsIds: this.caxtonFailedFindingsExpenses.map(x => x.id),
      findings: this.caxtonFailedFinding.nativeElement.value
    };

    this.expenseService.notOnCaxtonRetry(request).pipe(
      finalize(() => {
        this.processingRequest = false;
        this.caxtonFailedFindingsExpenses = null;
        this.caxtonFailedFindingModal.hide();
        this.caxtonFailedFinding.nativeElement.value = '';
      })
    ).subscribe(
      {
        next: () => {
          this.getExpenses();
        },
        error: error => {
          this.processingRequest = false;
          this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was a problem. Please try again.');
        }
      }
    );
  }

  showCaxtonTransferModal(totalExpense: number, patientName: string, expenses: ExpenseClaimViewModel[], paymentMethod: ExpenseClaimPaymentMethod) {
    this.caxtonTransferModalAmount = totalExpense;
    this.caxtonTransferModalCurrency = expenses[0].currency;
    this.caxtonTransferModalRecipient = patientName;
    this.caxtonTransferModalDate = expenses[0].paymentRequestedOn;
    this.caxtonTransferModalExpenses = expenses;
    this.caxtonTransferModalPaymentMethod = paymentMethod;

    if (paymentMethod === ExpenseClaimPaymentMethod.CaxtonAutomatedBankTransfer) {
      this.foundOnCaxtonForm.get("transferId").setValidators(Validators.required);
    } else {
      this.foundOnCaxtonForm.get("transferId").clearValidators();
    }

    this.cd.detectChanges();
    this.foundOnCaxtonModal.show();
  }

  hideFoundOnCaxtonModal() {
    this.foundOnCaxtonForm.get("caxtonFailedFindings").setValue(null);
    this.foundOnCaxtonForm.get("transferId").setValue(null);

    document.getElementById("findings").classList.remove("ng-valid", "ng-touched");
    document.getElementById("transfer-id")?.classList.remove("ng-valid", "ng-touched");
    this.foundOnCaxtonModal.hide();
  }

  setCaxtonTransferId() {
    const request: SetCaxtonTransferIdRequest = {
      expenseClaimIds: this.caxtonTransferModalExpenses.map(x => x.id),
      transferId: this.foundOnCaxtonForm.controls.transferId.value,
      findings: this.foundOnCaxtonForm.controls.caxtonFailedFindings.value,
      expenseClaimPaymentMethod: this.caxtonTransferModalPaymentMethod
    };

    this.foundOnCaxtonFormProcessing = true;
    this.expenseService.setCaxtonTransferId(request).subscribe(
      {
        next: () => {
          this.getExpenses();
          this.hideFoundOnCaxtonModal();

          this.alertService.showSuccessAlert('Transfer successfully added');
        },
        error: () => {
          this.alertService.showWarningAlert('Adding transfer failed. Please check id and try again.');
        }
      }
    );

    this.foundOnCaxtonFormProcessing = false;
  }

  moveToCaxtonFailed(expenses: ExpenseClaimViewModel[]) {
    const request: MoveToCaxtonFailedRequest = {
      expenseClaimsIds: expenses.map(e => e.id)
    };

    this.processingRequest = true;

    this.expenseService.moveToCaxtonFailed(request).subscribe({
      next: () => {
        this.getExpenses();
      },
      error: error => {
        this.processingRequest = false;
        this.alertService.showWarningAlert(error.error
          ? error.error.title
          : 'Sorry, there was a problem. Please try again.');
      }
    });
  }

  onExportDateOptionChanged(filterBy: string) {
    this.exportForm.patchValue({ filterBy: filterBy });
  }

  getPaidByText(paidBy: ExpenseClaimPaidBy) {
    switch (paidBy) {
      case ExpenseClaimPaidBy.Finance:
        return 'Paid via Finance';
      case ExpenseClaimPaidBy.Caxton:
        return 'Paid via Caxton Bank';
      default:
        return 'Paid via Caxton Card';
    }
  }

  openAddNoteModal(expense: ExpenseClaimViewModel) {
    this.addNoteModal.expenseClaimId = expense.id;
    this.addNoteModal.addNoteForm.controls.notes.setValue(expense.notes);
    this.addNoteModal.modal.show();
  }

  viewOnHoldExpenses() {
    this.dataLoaded = false;
    this.expenseClaimsRequest.onHold = !this.expenseClaimsRequest.onHold;
    localStorage.setItem("on_hold", this.expenseClaimsRequest.onHold.toString());

    this.getExpenses();
  }

  viewInvestigationExpenses() {
    this.dataLoaded = false;
    this.expenseClaimsRequest.investigations = !this.expenseClaimsRequest.investigations;
    localStorage.setItem("investigations", this.expenseClaimsRequest.investigations.toString());

    this.getExpenses();
  }

  onCheckExpense(expense: ExpenseClaimViewModel): void {
    this.checkExpenseModal.show(expense);
  }

  buildPolicyRemindersText(claim: any) {
    let text = '';

    if (!StringHelper.isNullOrEmpty(claim.trialExpensePolicyReminders)) {
      text += claim.trialExpensePolicyReminders;
    }

    if (!StringHelper.isNullOrEmpty(claim.policyReminders)) {
      if (!StringHelper.isNullOrEmpty(text)) {
        text += ' / ';
      }

      text += claim.policyReminders;
    }

    if (!StringHelper.isNullOrEmpty(claim.adminExpenseNotes)) {
      if (!StringHelper.isNullOrEmpty(text)) {
        text += ' / ';
      }

      text += claim.adminExpenseNotes;
    }

    return text;
  }

  public uploadIsPdf(uploadUrl: string) {
    let parts = uploadUrl.toLowerCase().split('?');
    return parts[0].endsWith('.pdf');
  }

  isCaxtonTab() {
    return this.expenseClaimsRequest.state === ExpenseClaimState.CaxtonApproved ||
      this.expenseClaimsRequest.state === ExpenseClaimState.Processing ||
      this.expenseClaimsRequest.state === ExpenseClaimState.CaxtonFailed;
  }

  noResultsVisible(): boolean {
    return this.isCaxtonTab() ? this.dataLoaded && !this.caxtonClaimsResponse.results.length : this.dataLoaded && !this.claimsResponse.results.length;
  }

  noResultsImage(): string {
    return this.noSearchMatchingResults() ? 'assets/img/no-results-illustration.png' : 'assets/img/no-expenses.png';
  }

  noResultsText(): string {
    return this.noSearchMatchingResults() ? `No ${enumToText(ExpenseClaimState, this.selectedTab)} Expense Claims` : 'No Results Found';
  }

  noSearchMatchingResults(): boolean {
    return this.dataLoaded && this.searchForm.get("keywords").value;
  }

  setSearch(keywords: string) {
    this.searchInput.input.nativeElement.value = keywords;
    this.searchInput.inputHasValue = true;
    this.onSearchInputChanged(keywords);
  }

  showStartInvestigationModal(expenseClaimId: string) {
    this.startInvestigationModal.expenseClaimId = expenseClaimId;
    this.startInvestigationModal.modal.show();
  }

  onOverBudgetRequestRaised() {
    this.getExpenses();
  }

  protected readonly Date = Date;
}
