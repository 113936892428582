<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="api-integration-notice">
    <p>API Integration is enabled for this trial</p>
  </div>
  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr>
      <th style="width: 10%;">Enabled</th>
      <th>Consumer Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let consumer of apiConsumers">
      <td>
        <span class="badge badge-success" *ngIf="consumer.enabled">Enabled</span>
        <span class="badge badge-danger" *ngIf="!consumer.enabled">Disabled</span>
      </td>
      <td>{{ consumer.name }}</td>
    </tr>
    </tbody>
  </table>
  <div class="no-results inside" *ngIf="apiConsumers.length == 0">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No API Consumers Assigned</h2>
        <p>There are no API consumers assigned to this trial.</p>
      </div>
    </div>
  </div>
</div>
