<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="details-view-results-head">
    <a class="btn btn-outline btn-outline-slim" *ngIf="authService.hasPermission(Permissions.TrialPolicyAdd)" routerLink="/trial/{{trial.id}}/policy/add">+ Expense Policy</a>
  </div>

  <table class="details-view-policies">
    <tr class="trial-policy-row" *ngFor="let policy of policies" [policy]="policy" (remove)="onRemovePolicy($event)" (edit)="onEdit($event)"></tr>
  </table>
</div>

<!-- Start: Remove policy modal -->
<app-modal #removePolicyModal>
  <div class="modal-header">
    <h2 class="modal-title">Remove Expense Policy</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to remove this expense policy from the trial?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="removePolicyModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" (click)="onConfirmRemovePolicy()">
          Yes, remove
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Remove policy modal -->
