<div class="images">
  <div class="image-item" *ngFor="let image of imagery; let i = index">
    <ng-container *ngIf="!uploadIsPdf(image.imageUrl)">
      <app-expense-image [thumbnailUrl]="image.thumbnailUrl" [filename]="image.filename"></app-expense-image>
    </ng-container>

    <ng-container *ngIf="uploadIsPdf(image.imageUrl)">
      <a (click)="onDownloadFile(image.filename)" title="View PDF" class="pdf-icon">
        <i class="far fa-file-pdf"></i>
      </a>
    </ng-container>
  </div>
</div>
