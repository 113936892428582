import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function SortCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.errors && !control.errors.sortCode) {
      return
    }

    const valid = /^(\d){6}$/.test(control.value);

    return valid ? null : { sortCode: { value: control.value } };
  }
}
