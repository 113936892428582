import {ExpenseClaimPaymentMethod} from './expense.model';
import {ExpenseSubCategoryItem} from './expense-category-list.model';

export class ExpenseClaimDetails {
  public id: string;
  public state: string;
  public visitTitle: string;
  public categoryName: string;
  public categoryType: string;
  public amount: number;
  public amountBC: number;
  public currency: string;
  public distanceAmount: number;
  public distanceUnit: string;
  public imageUrls: string[] = [];
  public rejectionReason: string;
  public notes: string;
  public adminExpenseNotes: string;
  public created: Date;
  public type: string;
  public visitId: string;
  public trialId: string;
  public expenseCategoryId: string;
  public patientId: string;
  public patientName: string;
  public underInvestigation: string;
  public reasonForInvestigation: string;
  public subCategories: ExpenseSubCategoryItem[] = [];
  public subCategory: number;
  public paymentMethod: ExpenseClaimPaymentMethod;
  public onHold: boolean;
  public overspendApproved: boolean;
  public trialBaseCurrency: string;

  public static fromObject(obj: any) {
    let expense = new ExpenseClaimDetails();
    expense.id = obj.id;
    expense.state = obj.state;
    expense.visitTitle = obj.visitTitle;
    expense.categoryName = obj.categoryName;
    expense.categoryType = obj.categoryType;
    expense.amount = obj.amount > 0 ? obj.amount / 100 : null;
    expense.amountBC = obj.amountBC > 0 ? obj.amountBC / 100 : null;
    expense.currency = obj.currency;
    expense.distanceAmount = obj.distanceAmount;
    expense.distanceUnit = obj.distanceUnit;
    expense.imageUrls = obj.imageUrls;
    expense.rejectionReason = obj.rejectionReason;
    expense.notes = obj.notes;
    expense.adminExpenseNotes = obj.adminExpenseNotes;
    expense.created = obj.created;
    expense.type = expense.distanceAmount > 0 ? 'Distance' : 'Currency';
    expense.visitId = obj.visitId;
    expense.trialId = obj.trialId;
    expense.expenseCategoryId = obj.expenseCategoryId;
    expense.patientId = obj.patientId;
    expense.patientName = obj.patientName;
    expense.underInvestigation = obj.underInvestigation;
    expense.reasonForInvestigation = obj.reasonForInvestigation;
    expense.subCategory = obj.subCategory;
    expense.paymentMethod = obj.paymentMethod;
    expense.onHold = obj.onHold;
    expense.overspendApproved = obj.overspendApproved;
    expense.trialBaseCurrency = obj.trialBaseCurrency;

    if (obj.hasOwnProperty('subCategories')) {
      expense.subCategories = obj.subCategories.map((subCategory: any) => new ExpenseSubCategoryItem(subCategory.id, subCategory.name));
    }

    return expense;
  }
}
