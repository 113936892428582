export class Currencies {
  public static all() {
    return [
      {
        "cc": 'GBP',
        "symbol": '\u00a3',
        "name": 'British pound'
      },
      {
        "cc": 'EUR',
        "symbol": '\u20ac',
        "name": 'European euro'
      },
      {
        "cc": 'USD',
        "symbol": '$',
        "name": 'United States dollar'
      },
      {
        "cc": 'AED',
        "symbol": 'د.إ',
        "name": 'United Arab Emirates dirham'
      },
      {
        "cc": 'AUD',
        "symbol": '$',
        "name": 'Australian dollar'
      },
      {
        "cc": 'CAD',
        "symbol": '$',
        "name": 'Canadian dollar'
      },
      {
        "cc": 'CHF',
        "symbol": 'Fr.',
        "name": 'Swiss franc'
      },
      {
        "cc": 'CNY',
        "symbol": '¥',
        "name": 'Chinese yuan'
      },
      {
        "cc": 'CZK',
        "symbol": 'K\u010d',
        "name": 'Czech koruna'
      },
      {
        "cc": 'DKK',
        "symbol": 'Kr',
        "name": 'Danish kroner'
      },
      {
        "cc": 'HKD',
        "symbol": 'HK$',
        "name": 'Hong Kong dollar'
      },
      {
        "cc": 'HUF',
        "symbol": 'Ft',
        "name": 'Hungarian forint'
      },
      {
        "cc": 'ILS',
        "symbol": '\u20aa',
        "name": 'Israeli new sheqel'
      },
      {
        "cc": 'INR',
        "symbol": '₹',
        "name": 'Indian rupee'
      },
      {
        "cc": 'JPY',
        "symbol": '\u00a5',
        "name": 'Japanese yen'
      },
      {
        "cc": 'KWD',
        "symbol": 'د.ك',
        "name": 'Kuwaiti dinar'
      },
      {
        "cc": 'MAD',
        "symbol": 'MAD',
        "name": 'Moroccan dirham'
      },
      {
        "cc": 'MXN',
        "symbol": '$',
        "name": 'Mexican peso'
      },
      {
        "cc": 'NOK',
        "symbol": 'kr',
        "name": 'Norwegian krone'
      },
      {
        "cc": 'NZD',
        "symbol": 'NZ$',
        "name": 'New Zealand dollar'
      },
      {
        "cc": 'OMR',
        "symbol": 'ر.ع.',
        "name": 'Omani rial'
      },
      {
        "cc": 'PLN',
        "symbol": 'z\u0142',
        "name": 'Polish zloty'
      },
      {
        "cc": 'SAR',
        "symbol": '﷼',
        "name": 'Saudi riyal'
      },
      {
        "cc": 'SEK',
        "symbol": 'kr',
        "name": 'Swedish krona'
      },
      {
        "cc": 'SGD',
        "symbol": 'S$',
        "name": 'Singapore dollar'
      },
      {
        "cc": 'THB',
        "symbol": '\u0e3f',
        "name": 'Thai baht'
      },
      {
        "cc": 'TRY',
        "symbol": 'TRY',
        "name": 'Turkish new lira'
      },
      {
        "cc": 'ZAR',
        "symbol": 'R',
        "name": 'South African rand'
      },
      {
        "cc": 'ARS',
        "symbol": '$',
        "name": 'Argentine peso'
      },
      {
        "cc": 'BGN',
        "symbol": 'BGN',
        "name": 'Bulgarian lev'
      },
      {
        "cc": 'BRL',
        "symbol": 'R$',
        "name": 'Brazilian real'
      },
      {
        "cc": 'CLP',
        "symbol": '$',
        "name": 'Chilean peso'
      },
      {
        "cc": 'COP',
        "symbol": 'Col$',
        "name": 'Colombian peso'
      },
      {
        "cc": 'GEL',
        "symbol": 'GEL',
        "name": 'Georgian lari'
      },
      {
        "cc": 'HRK',
        "symbol": 'kn',
        "name": 'Croatian kuna'
      },
      {
        "cc": 'JOD',
        "symbol": 'JOD',
        "name": 'Jordanian dinar'
      },
      {
        "cc": 'KRW',
        "symbol": 'W',
        "name": 'South Korean won'
      },
      {
        "cc": 'MYR',
        "symbol": 'RM',
        "name": 'Malaysian ringgit'
      },
      {
        "cc": 'PEN',
        "symbol": 'S/.',
        "name": 'Peruvian nuevo sol'
      },
      {
        "cc": 'RON',
        "symbol": 'L',
        "name": 'Romanian leu'
      },
      {
        "cc": 'RSD',
        "symbol": 'din.',
        "name": 'Serbian dinar'
      },
      {
        "cc": 'RUB',
        "symbol": 'R',
        "name": 'Russian ruble'
      },
      {
        "cc": 'TWD',
        "symbol": 'NT$',
        "name": 'New Taiwan dollar'
      },
      {
        "cc": 'UAH',
        "symbol": 'UAH',
        "name": 'Ukrainian hryvnia'
      },
      {
        "cc": 'BAM',
        "symbol": 'KM',
        "name": 'Convertible mark'
      }
    ];
  }
}
