import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { filter } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AuthService } from "../../core/services/auth.service";
import { AlertService } from "../../shared/alert/alert.service";
import { RoleNames } from 'app/core/constants/role-names';
import { AuthStatus } from "../../core/auth-status.enum";
import { ModalComponent } from "../../shared/modal/modal.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('redirectingModal') redirectingModal: ModalComponent;

  loginDisplay = false;

  constructor(private readonly router: Router,
    private readonly authService: AuthService,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly alertService: AlertService) {
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnInit() {
    // When the user first logs in, they're redirected here. We need to figure out what they have access to
    // and sent the user to that section

    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    ).subscribe(() => {
      this.authService.authStatus.next(AuthStatus.Authenticated);
    });

    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    ).subscribe(() => {
      this.setLoginDisplay();
    });
  }

  private redirectToAppropriatePage() {
    this.authService.getRolesFromApi().subscribe({
      next: () => {
        let redirectUrlString = localStorage.getItem("redirect_url");

        if (redirectUrlString !== null) {
          let urlParts = redirectUrlString.split('?');
          let redirectPath = urlParts[0];

          let queryParamsString = urlParts.length > 1 ? urlParts[1].split('&') : null;
          let queryParams = {};

          if (queryParamsString) {
            queryParamsString.forEach(param => {
              let [key, value] = param.split('=');
              queryParams[key] = value;
            });
          }

          this.router.navigate([redirectPath], { queryParams: queryParams });
          localStorage.removeItem("redirect_url");
          return;
        }

        switch (true) {
          case this.authService.hasAnyRole(RoleNames.ProductAdministrator):
            this.router.navigate(['/trial']);
            return;
          case this.authService.hasAnyRole(RoleNames.SystemAdministrator):
            this.router.navigate(['/admin']);
            return;
          case this.authService.hasAnyRole(RoleNames.StartupCoordinator):
            this.router.navigate(['/trial']);
            return;
          case this.authService.hasAnyRole(RoleNames.ProjectCoordinatorExpenses):
            this.router.navigate(['/expense']);
            return;
          case this.authService.hasAnyRole(RoleNames.ProjectCoordinatorTravel):
            this.router.navigate(['/travel']);
            return;
          case this.authService.hasAnyRole(RoleNames.ProjectManager):
            this.router.navigate(['/project-management']);
            return;
          default:
            this.router.navigate(['/unauthorised']);
            return;
        }
      },
      error: () => {
        this.alertService.showWarningAlert(
          'Sorry, there was a problem logging you in. If you believe your account should have access to the system, please contact support');
        this.router.navigate(['/unauthorised']);
      }
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  setLoginDisplay() {
    const loggedIn = this.msalService.instance.getAllAccounts().length > 0;
    this.loginDisplay = loggedIn;
    if (loggedIn) {
      this.authService.authStatus.next(AuthStatus.Authenticated);
      this.redirectToAppropriatePage();
    }
  }
}
