import { SearchPage } from "./search-page.model";

export class PatientListItem {
  constructor(
      public id: string,
      public patientCode: string,
      public firstname: string,
      public lastname: string,
      public email: string,
      public address: string,
      public country: string,
      public trialId: string,
      public trialLabel: string,
      public siteId: string,
      public siteName: string,
      public siteAddress: string,
      public siteCountry: string,
      public consent: boolean,
      public consentDate: Date,
      public consentType: string,
      public created: Date,
      public siteNumber: string,
      public patientTrialId: string
  ) {
  }

  getFullName() {
    return this.firstname + ' ' + this.lastname;
  }

  getFullAddress(characterLimit = 0) {
    let address = this.address != null ? this.address.replace(/(?:\r\n|\r|\n)/g, ' ') : '';
    address = this.country == null ? address : address + ' ' + this.country;

    if (characterLimit > 0 && address.length > characterLimit) {
      return address.substring(0, characterLimit) + '...';
    }

    return address;
  }

  getTrialPublicCode(characterLimit = 0) {
    let name = this.trialLabel != null ? this.trialLabel : '';

    if (characterLimit > 0 && name.length > characterLimit) {
      return name.substring(0, characterLimit) + '...';
    }

    return name;
  }
}

export class PatientList extends SearchPage {
  results: PatientListItem[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(new PatientListItem(
          result.id,
          result.patientCode,
          result.firstname,
          result.lastname,
          result.email,
          result.address,
          result.country,
          result.trialId,
          result.trialLabel,
          result.siteId,
          result.siteName,
          result.siteAddress,
          result.siteCountry,
          result.consent,
          result.consentDate !== undefined && result.consentDate !== null ? new Date(result.consentDate) : null,
          result.consentType,
          result.created,
          result.siteNumber,
          result.hasOwnProperty('patientTrialId') ? result.patientTrialId : ''
      ));
    }

    return this;
  }
}
