import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectOption } from "../../../../core/models/select-option.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MuvService } from "../../../../core/services/muv.service";
import { MuvVehicle } from "../../../../core/models/muv-models/muv-vehicle.model";
import { AlertService } from "../../../../shared/alert/alert.service";
import { Airports } from "../../../../core/constants/airports";
import { StringHelper } from "../../../../core/helpers/string-helper";
import { ArrayHelper } from "../../../../core/helpers/array.helper";
import { Countries } from "../../../../core/constants/countries";
import { CreateMuvRide } from "../../../../core/services/interfaces/create-muv-ride.interface";
import { IllyTime } from "../../../../core/helpers/date-helper";
import moment from "moment";
import { CreateMuvRideStop } from "../../../../core/services/interfaces/create-muv-ride-stop.interface";
import { Address } from "../../../../shared/input-address/address.model";
import { VisitService } from "../../../../core/services/visit.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import {
  PatientTrip
} from "../../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { InputAddressComponent } from "../../../../shared/input-address/input-address.component";
import { PatientService } from "../../../../core/services/patient.service";

@Component({
  selector: 'app-muv-ride-form',
  templateUrl: './muv-ride-form.component.html',
  styleUrls: ['./muv-ride-form.component.scss']
})
export class MuvRideFormComponent implements AfterViewInit {
  @ViewChild('pickupDate') pickupDate: ElementRef;
  @ViewChild('dropoffDate') dropoffDate: ElementRef;
  @ViewChild('pickupLocationInput') pickupLocationInput: InputAddressComponent;
  @ViewChild('dropoffLocationInput') dropoffLocationInput: InputAddressComponent;

  @Output('showList') showList = new EventEmitter();

  @Input('patientTrip') patientTrip: PatientTrip;

  form: UntypedFormGroup;
  requestTypes: SelectOption[] = [
    { value: 'quote', text: 'Quote' },
    { value: 'booking', text: 'Booking' }
  ];
  rideTypes: SelectOption[] = [
    { value: 'one-way', text: 'One-way' },
    { value: 'hourly', text: 'Hourly Booking' }
  ];
  vehicles: SelectOption[] = [];
  allVehicles: MuvVehicle[] = [];
  pickupAirportMeetingProcedures: SelectOption[] = [];
  dropoffAirportMeetingProcedures: SelectOption[] = [];
  airlines: SelectOption[] = [];
  filteredPickupAirlines: SelectOption[] = [];
  filteredDropoffAirlines: SelectOption[] = [];
  allAirports: SelectOption[] = [];
  filteredPickupAirports: SelectOption[] = [];
  filteredDropoffAirports: SelectOption[] = [];
  passengersLabel = '';
  showPickupAirport = false;
  showDropoffAirport = false;
  stops: UntypedFormGroup[] = [];
  countries: { value: string, text: string }[] = [];
  filteredCountries: { value: string, text: string }[] = [];
  meetingProcedureInputPlaceholder = 'Select airport first...';

  constructor(private _muvService: MuvService, private _alertService: AlertService, private _visitService: VisitService, private _patientService: PatientService) {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      requestType: new UntypedFormControl('', Validators.required),
      rideType: new UntypedFormControl('', Validators.required),
      vehicleType: new UntypedFormControl('', Validators.required),
      passengers: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
      hoursNeeded: new UntypedFormControl(0),
      pickupDate: new UntypedFormControl('', Validators.required),
      pickupTime: new UntypedFormControl('', Validators.required),
      pickupLandmark: new UntypedFormControl(''),
      pickupLocation: new UntypedFormControl(null, Validators.required),
      dropoffLandmark: new UntypedFormControl(''),
      dropoffLocation: new UntypedFormControl(null, Validators.required),
      pickupAirportCode: new UntypedFormControl(),
      pickupAirlineId: new UntypedFormControl(),
      pickupFlightNumber: new UntypedFormControl(),
      pickupAirportMeetingProcedure: new UntypedFormControl(),
      dropoffAirportCode: new UntypedFormControl(),
      dropoffAirlineId: new UntypedFormControl(),
      dropoffFlightNumber: new UntypedFormControl(),
      specialInstructions: new UntypedFormControl(),
      patientTelephone: new UntypedFormControl('', Validators.required)
    });

    // Set validators for passengers
    this.form.get('vehicleType').valueChanges.subscribe((vehicleType) => {
      let type = this.allVehicles.find((vehicle) => vehicle.vehicle_type.toString() === vehicleType);

      if (type !== undefined && type !== null) {
        this.passengersLabel = `(Max ${type.max_passengers})`;
        this.form.get('passengers').setValidators([Validators.required, Validators.min(1), Validators.max(type.max_passengers)]);
      }
    });

    // Set validators for hours needed
    this.form.get('rideType').valueChanges.subscribe((rideType) => {
      if (rideType === 'one-way') {
        this.form.get('hoursNeeded').setValidators(null);
        this.form.patchValue({ hoursNeeded: 0 })
      } else {
        this.form.get('hoursNeeded').setValidators([Validators.required, Validators.min(1)]);
        this.form.patchValue({ hoursNeeded: 1 })
      }
    });

    // When pickup airport changes, load the meeting procedures
    this.form.get('pickupAirportCode').valueChanges.subscribe((airportCode) => {
      if (!StringHelper.isNullOrEmpty(airportCode)) {
        this._muvService.airportMeetingProcedures(airportCode).subscribe({
          next: (procedures) => {
            if (procedures.length > 0) {
              this.meetingProcedureInputPlaceholder = 'Select meeting procedure...';
            } else {
              this.meetingProcedureInputPlaceholder = 'No meeting procedures available...';
            }

            this.pickupAirportMeetingProcedures = [];
            this.pickupAirportMeetingProcedures.push({ value: '', text: 'None' });
            procedures.forEach(procedure => {
              this.pickupAirportMeetingProcedures.push({ value: procedure.id.toString(), text: procedure.value });
            });
          },
          error: (err) => this._alertService.showErrorAlert(err)
        });
      }
    });

    // When dropoff airport changes, load the meeting procedures
    this.form.get('dropoffAirportCode').valueChanges.subscribe((airportCode) => {
      if (!StringHelper.isNullOrEmpty(airportCode)) {
        this._muvService.airportMeetingProcedures(airportCode).subscribe({
          next: (procedures) => {
            this.dropoffAirportMeetingProcedures = [];
            this.dropoffAirportMeetingProcedures.push({ value: '', text: 'None' });
            procedures.forEach(procedure => {
              this.dropoffAirportMeetingProcedures.push({ value: procedure.id.toString(), text: procedure.value });
            });
          },
          error: (err) => this._alertService.showErrorAlert(err)
        });
      }
    });

    // Countries
    for (const country of Countries.all()) {
      this.countries.push({value: country.code, text: country.name});
    }
    this.filteredCountries = this.countries;
  }

  ngAfterViewInit(): void {
    // Airports - map to select options
    Airports.all().forEach(airport => this.allAirports.push({value: airport.AirportCode, text: airport.AirportCode + ' - ' + airport.AirportName}));
    this.filteredPickupAirports = this.allAirports;
    this.filteredDropoffAirports = this.allAirports;

    // Load airlines
    this._muvService.airlines().subscribe({
      next: (airlines) => {
        airlines.sort(ArrayHelper.dynamicSort("airline_name"));
        airlines.forEach(airline => {
          this.airlines.push({ value: airline.airline_code, text: airline.airline_code + ' - ' + airline.airline_name });
        });

        this.filteredDropoffAirlines = this.airlines;
        this.filteredPickupAirlines = this.airlines;
      },
      error: (err) => this._alertService.showErrorAlert(err)
    });

    // Load vehicles
    this._muvService.vehicles().subscribe({
      next: (vehicles) => {
        this.allVehicles = vehicles;
        vehicles.map((vehicle) => {
          this.vehicles.push({ value: vehicle.vehicle_type.toString(), text: vehicle.description });
        });
      },
      error: (err) => this._alertService.showErrorAlert(err)
    });

    this.prepopulateRide();
  }

  /**
   * Prepopulates the form with details from the patient and the trip
   */
  prepopulateRide() {
    // Pre-populate Muv ride with details from the patient / visit
    this.form.patchValue({
      pickupDate: !StringHelper.isNullOrEmpty(this.patientTrip.departureDate) ? moment(this.patientTrip.departureDate).format('DD/MM/YYYY') : null,
      pickupTime: !StringHelper.isNullOrEmpty(this.patientTrip.departureDate) ? moment(this.patientTrip.departureDate).format('HH:mm') : null,
      passengers: this.patientTrip.caregiverTravelling === 'Yes' || this.patientTrip.caregiverTravelling === 'TravelBooked' ? '2' : '1',
      patientTelephone: this.patientTrip.patientTelephone,
      dropoffLandmark: this.patientTrip.patientSiteName
    });

    // Set pickup address to the patient address and dropoff address to the site address
    this.pickupLocationInput.setAddress(this.patientTrip.patientHomeAddress);
    this.dropoffLocationInput.setAddress(this.patientTrip.patientSiteAddress);

    // Set the special instructions value
    if (this.patientTrip.visitTime !== null && this.patientTrip.visitTime !== undefined)
      this.form.patchValue({specialInstructions: 'Pickup time is an estimate but patient must arrive by appointment time '+this.patientTrip.visitTime+'. Please adjust the pickup time as appropriate to ensure on-time arrival.'});
  }

  addStop(): void {
    this.stops.push(new UntypedFormGroup({
      apt: new UntypedFormControl(),
      street: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl('', Validators.required),
      zip: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      pickupTime: new UntypedFormControl('', Validators.required)
    }));
  }

  /**
   * Handles swapping around the pickup and dropoff location
   */
  onSwapLocations(): void {
    let pickupLandmark = '';
    let dropoffLandmark = '';
    let pickup: Address = null;
    let dropoff: Address = null;

    let pickupControl = this.form.get('pickupLocation').value;
    if (pickupControl == null)
      pickupControl = new Address();

    pickupLandmark = this.form.get('pickupLandmark').value;
    pickup = new Address({
      id: pickupControl.id,
      apt: pickupControl.apt,
      street: pickupControl.street,
      city: pickupControl.city,
      state: pickupControl.state,
      zip: pickupControl.zip,
      countryCode: pickupControl.country
    });

    let dropoffControl = this.form.get('dropoffLocation').value;
    if (dropoffControl == null)
      dropoffControl = new Address();

    dropoffLandmark = this.form.get('dropoffLandmark').value;
    dropoff = new Address({
      id: dropoffControl.id,
      apt: dropoffControl.apt,
      street: dropoffControl.street,
      city: dropoffControl.city,
      state: dropoffControl.state,
      zip: dropoffControl.zip,
      countryCode: dropoffControl.country
    });

    this.pickupLocationInput.setAddress(dropoff);
    this.dropoffLocationInput.setAddress(pickup);
    this.form.patchValue({
      pickupLandmark: dropoffLandmark,
      dropoffLandmark: pickupLandmark
    })
  }

  onFilterPickupAirports(term: string): void {
    this.filteredPickupAirports = this.allAirports.filter((airport) => airport.text.toLowerCase().includes(term.toLowerCase()));
  }

  onFilterDropoffAirports(term: string): void {
    this.filteredDropoffAirports = this.allAirports.filter((airport) => airport.text.toLowerCase().includes(term.toLowerCase()));
  }

  onFilterPickupAirlines(term: string): void {
    this.filteredPickupAirlines = this.airlines.filter((airline) => airline.text.toLowerCase().includes(term.toLowerCase()));
  }

  onFilterDropoffAirlines(term: string): void {
    this.filteredDropoffAirlines = this.airlines.filter((airline) => airline.text.toLowerCase().includes(term.toLowerCase()));
  }

  /**
   * Toggle and set validation on pickup airport
   */
  togglePickupAirport() {
    this.showPickupAirport = !this.showPickupAirport;

    if (this.showPickupAirport) {
      this.form.patchValue({pickupIsAirport: true});
      this.form.get('pickupAirportCode').setValidators(Validators.required);
      this.form.get('pickupAirportCode').updateValueAndValidity();
      this.form.get('pickupAirlineId').setValidators(Validators.required);
      this.form.get('pickupAirlineId').updateValueAndValidity();
      this.form.get('pickupFlightNumber').setValidators(Validators.required);
      this.form.get('pickupFlightNumber').updateValueAndValidity();

      this.form.get('pickupLocation').setValidators(null);
      this.form.get('pickupLocation').setErrors(null);
      this.form.get('pickupLocation').updateValueAndValidity();
    } else {
      this.form.patchValue({
        pickupAirportCode: '',
        pickupAirlineId: '',
        pickupFlightNumber: '',
      });
      this.form.get('pickupAirportCode').setValidators(null);
      this.form.get('pickupAirportCode').setErrors(null);
      this.form.get('pickupAirportCode').updateValueAndValidity();
      this.form.get('pickupAirlineId').setValidators(null);
      this.form.get('pickupAirlineId').setErrors(null);
      this.form.get('pickupAirlineId').updateValueAndValidity();
      this.form.get('pickupFlightNumber').setValidators(null);
      this.form.get('pickupFlightNumber').setErrors(null);
      this.form.get('pickupFlightNumber').updateValueAndValidity();

      this.form.get('pickupLocation').setValidators(Validators.required);
      this.form.get('pickupLocation').updateValueAndValidity();
    }
  }

  /**
   * Toggle and set validation on dropoff airport
   */
  toggleDropoffAirport() {
    this.showDropoffAirport = !this.showDropoffAirport;

    if (this.showPickupAirport) {
      this.form.get('dropoffAirportCode').setValidators(Validators.required);
      this.form.get('dropoffAirportCode').updateValueAndValidity();

      this.form.get('dropoffLocation').setValidators(null);
      this.form.get('dropoffLocation').setErrors(null);
      this.form.get('dropoffLocation').updateValueAndValidity();
    } else {
      this.form.patchValue({
        dropoffAirportCode: '',
        dropoffAirlineId: '',
        dropoffFlightNumber: ''
      });
      this.form.get('dropoffAirportCode').setValidators(null);
      this.form.get('dropoffAirportCode').setErrors(null);
      this.form.get('dropoffAirportCode').updateValueAndValidity();
      this.form.get('dropoffAirlineId').setValidators(null);
      this.form.get('dropoffAirlineId').setErrors(null);
      this.form.get('dropoffAirlineId').updateValueAndValidity();
      this.form.get('dropoffFlightNumber').setValidators(null);
      this.form.get('dropoffFlightNumber').setErrors(null);
      this.form.get('dropoffFlightNumber').updateValueAndValidity();

      this.form.get('dropoffLocation').setValidators(Validators.required);
      this.form.get('dropoffLocation').updateValueAndValidity();
    }
  }

  private formatDateTimeString(date: string, time: IllyTime): moment.Moment {
    let response = null;
    if (date !== '' && time.isValid)
      response = moment(date + ' ' + time.to24HourString(), 'DD/MM/YYYY HH:mm');
    else if (date !== '' && !time.isValid)
      response = moment(date, 'DD/MM/YYYY');

    return response;
  }

  /**
   * Grabs the dropoff and pickup dates from the native element and stores them on the form
   */
  onMouseEnterForm() {
    if (this.pickupDate.nativeElement.value !== this.form.get('pickupDate')) {
      this.form.patchValue({ pickupDate: this.pickupDate.nativeElement.value });
    }
  }

  filterCountries(term: string) {
    if (term !== undefined && term !== null && term !== '') {
      this.filteredCountries = this.countries.filter(country => country.text.toLowerCase().startsWith(term.toLowerCase()));
      return;
    }

    this.filteredCountries = this.countries;
  }

  removeStop(index: number): void {
    this.stops.splice(index, 1);
  }

  onCancel(): void {
    this.prepopulateRide();
    this.stops = [];
    this.form.reset();
    this.showList.emit();
  }

  onFormSubmit(): void {
    let processing = this.form.get('processing').value;
    if (processing)
      return;

    const pickupTime = IllyTime.parseString(this.form.get('pickupTime').value);
    const pickupDate = this.formatDateTimeString(this.form.get('pickupDate').value, pickupTime);

    const stopsDto: CreateMuvRideStop[] = [];
    this.stops.forEach(stop => {
      let address = new Address();
      address.apt = stop.get('apt').value;
      address.street = stop.get('street').value;
      address.city = stop.get('city').value;
      address.state = stop.get('state').value;
      address.zip = stop.get('zip').value;
      address.country = stop.get('country').value;

      let stopPickupTime = IllyTime.parseString(stop.get('pickupTime').value);

      stopsDto.push({address: address, pickupTimeMinutes: stopPickupTime.totalMinutes()});
    });

    const vehicleType = this.vehicles.find((vehicle) => vehicle.value === this.form.get('vehicleType').value);

    const dto: CreateMuvRide = {
      requestType: this.form.get('requestType').value,
      rideType: this.form.get('rideType').value,
      vehicleType: this.form.get('vehicleType').value,
      vehicle: vehicleType.text,
      noOfPassengers: this.form.get('passengers').value,
      hoursNeeded: this.form.get('hoursNeeded').value,
      pickupDate: pickupDate.format('YYYY/MM/DD HH:mm:ss'),
      pickupTimeMinutes: pickupTime.totalMinutes(),
      pickupLandmark: this.form.get('pickupLandmark').value,
      pickupLocation: this.form.get('pickupLocation').value,
      dropoffLandmark: this.form.get('dropoffLandmark').value,
      dropoffLocation: this.form.get('dropoffLocation').value,
      pickupAirportCode: this.form.get('pickupAirportCode').value,
      pickupAirlineId: this.form.get('pickupAirlineId').value,
      pickupFlightNumber: this.form.get('pickupFlightNumber').value,
      airportMeetingProcedureId: this.form.get('pickupAirportMeetingProcedure').value,
      dropoffAirportCode: this.form.get('dropoffAirportCode').value,
      dropoffAirlineId: this.form.get('dropoffAirlineId').value,
      dropoffFlightNumber: this.form.get('dropoffFlightNumber').value,
      stops: stopsDto,
      specialInstructions: this.form.get('specialInstructions').value,
      patientTelephone: this.form.get('patientTelephone').value
    };

    this.form.patchValue({processing: true});
    this._visitService.createMuvRide(this.patientTrip.id, dto).subscribe({
      next: (rsp) => {
        LogHelper.log(rsp);
        this.form.patchValue({processing: false});
        this.onCancel();
      },
      error: (err) => {
        LogHelper.log(err);
        this._alertService.showErrorAlert(err);
        this.form.patchValue({processing: false});
      }
    });
  }

}
