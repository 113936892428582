<section class="over-budget-request-items">
  <div class="over-budget-request-items-header">
    <div class="travel-budget-button-wrapper">
      <button class="btn btn-secondary" (click)="onClickShowPatientDetails()">Travel budget info</button>
    </div>
    <button *ngIf="authService.hasPermission(Permissions.OverBudgetRequestReferBack)" class="btn btn-secondary" (click)="referBackRequest.emit()">Refer back</button>
    <button class="btn btn-outline" (click)="showHistoryModal()">
      <span *ngIf="historyLoading">
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
      </span>
      <span *ngIf="!historyLoading">View history</span>
    </button>
    <button class="btn btn-primary" (click)="showApproveAllModal()"
      *ngIf="overBudgetRequestCategory === OverBudgetRequestCategory.Trip && authService.hasPermission(Permissions.OverBudgetRequestApprove)" [disabled]="approveAllDisabled">Approve all
    </button>
    <button *ngIf="authService.hasPermission(Permissions.OverBudgetRequestDelete)" class="btn btn-primary" (click)="deleteRequest.emit()">Delete request</button>
  </div>
  <div *ngFor="let items of overBudgetRequestItemsViewModel" class="over-budget-request-items-body">
    <div class="items-details">
      <span><label>Caregiver:</label> {{items.careGiver ? 'Yes' : 'No'}}</span>
      <span *ngIf="items.category === OverBudgetRequestCategory.Expense"><label>Blanket receipt approval:</label>
        {{items.blanketReceiptApproval ?
        'Yes' : 'No'}}</span>
      <span *ngIf="items.category === OverBudgetRequestCategory.Expense"><label>Rule:</label> {{items.ruleName}}</span>
      <span *ngIf="items.category === OverBudgetRequestCategory.Expense"><label>Limit per:</label> {{items.rulePer |
        enumToText: ExpenseAutoApprovalRuleType }}</span>
      <div class="additional-details-container">
        <p><label>Additional details:</label> {{items.additionalDetails }}</p>
      </div>
    </div>
    <div class="items-list">
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Status</th>
            <th>Date closed</th>
            <th>Actual cost</th>
            <th>Actual Cost Policy Currency</th>
            <th *ngIf="items.category === OverBudgetRequestCategory.Expense">Missing receipt</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items.items"
            [class.approved]="item.status !== 'Rejected' && item.status !== 'Pending'"
            [class.rejected]="item.status === 'Rejected'">
            <td>{{item.type}}</td>
            <td>{{item.status}}</td>
            <td>{{item.date | date: 'dd/MM/yy'}}</td>
            <td>{{item.actualCost}}{{item.actualCostCurrency}}</td>
            <td>{{item.actualCostInBaseCurrency}}{{item.baseCurrency}}</td>
            <td *ngIf="items.category === OverBudgetRequestCategory.Expense">{{item.missingReceipt ? 'Yes' : 'No'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="items-summary">
      <div class="cost">
        <div class="total-cost">
          <span class="total-cost-label">Policy budget limit</span><span *ngIf="items.budgetCost" class="total-cost-value">{{items.budgetCost}}{{items.currency}}
            <ng-container *ngIf="items.accommodationBudgetType"> per {{items.accommodationBudgetType | enumToText: AccommodationBudgetType}}</ng-container>
          </span>
        </div>
        <div class="total-cost">
          <span class="total-cost-label">Total actual cost</span> <span
            class="total-cost-value">{{items.totalActualCost}}{{items.currency}}</span>
        </div>
      </div>
      <button *ngIf="items.status === null && authService.hasPermission(Permissions.OverBudgetRequestApprove)" class="btn btn-primary" (click)="showApproveTotalModal(items)">
        Approve Total
      </button>
      <button *ngIf="items.status === null && authService.hasPermission(Permissions.OverBudgetRequestReject)" class="btn btn-secondary" (click)="showRejectModal(items)">Reject</button>
      <span *ngIf="items.status === OverBudgetRequestDetailsStatus.Rejected" class="status-label">Rejected</span>
      <span *ngIf="items.status === OverBudgetRequestDetailsStatus.Approved" class="status-label">Paid
        {{items.amountPaid}}{{items.currency}}</span>
    </div>
    <div class="other-over-budget-request-container" *ngIf="items.newOverBudgetRequestExists">
      <p>New Over budget request for an additional
        {{items.newOverBudgetRequestAmount}}{{items.newOverBudgetRequestCurrency}} has been raised.</p>
    </div>
    <div class="other-over-budget-request-container" *ngIf="items.existingOverBudgetRequestExists">
      <p>Previous Over budget request for {{items.existingOverBudgetRequestAmount}}{{items.existingOverBudgetRequestCurrency}}
        <span *ngIf="items.existingOverBudgetRequestStatus === null">is still outstanding</span>
        <span *ngIf="items.existingOverBudgetRequestStatus === OverBudgetRequestDetailsStatus.Rejected">of this {{items.totalActualCost}}{{items.currency}} has been rejected</span>
        <span *ngIf="items.existingOverBudgetRequestStatus === OverBudgetRequestDetailsStatus.Approved">of this {{items.totalActualCost}}{{items.currency}} has been approved</span>. This is included in the summary above.
      </p>
    </div>
  </div>
</section>

<app-modal #approveTotalModal class="approve-total-modal">
  <div class="modal-header">
    <h2 class="modal-title">Approve</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="approveTotalForm">
      <div class="form-group" *ngIf="overBudgetItemToApprove?.category === OverBudgetRequestCategory.Expense">
        <label>Amount payable</label>
        <input class="form-control" formControlName="amountPayable" type="text">
        <span class="currency">{{overBudgetItemToApprove?.currency}}</span>
      </div>
      <div class="trip-types" *ngIf="overBudgetItemToApprove?.category === OverBudgetRequestCategory.Trip">
        <span *ngFor="let item of overBudgetItemToApprove.items" class="trip-type">
          {{item.type}}<span class="trip-type-value"> {{item.actualCost}}{{item.actualCostCurrency}}</span>
        </span>
      </div>
      <div class="form-group">
        <label>Approved by</label>
        <input class="form-control" formControlName="approvedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Approved</label>
        <app-datepickerv2-input [parentForm]="approveTotalForm" controlName="approvedAt"></app-datepickerv2-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="approveTotalModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onApproveTotal()">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #approveAllModal class="approve-total-modal">
  <div class="modal-header">
    <h2 class="modal-title">Approve all requests</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="approveAllForm">
      <div class="trip-types mb-3">
        <span *ngFor="let item of overBudgetRequestItemsViewModel" class="trip-types">
          <span *ngFor="let trip of item.items" class="trip-type">
            {{trip.type}}<span class="trip-type-value"> {{trip.actualCost}}{{trip.actualCostCurrency}}</span>
          </span>
        </span>
      </div>
      <div class="form-group">
        <label>Approved by</label>
        <input class="form-control" formControlName="approvedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Approved</label>
        <app-datepickerv2-input [parentForm]="approveAllForm" controlName="approvedAt"></app-datepickerv2-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="approveAllModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onApproveAll()">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #rejectOptionsModal class="reject-options-modal">
  <div class="modal-header">
    <h2 class="modal-title">Reject</h2>
  </div>
  <div class="modal-body">
    <button class="btn btn-outline" (click)="showExpenseRejectModal(false)">Reject full amount</button>
    <button class="btn btn-primary" (click)="showExpenseRejectModal(true)">Reject and pay partial amount</button>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="rejectOptionsModal.hide()">Cancel</button>
  </div>
</app-modal>

<app-modal #rejectModal class="reject-modal">
  <div class="modal-header">
    <h2 class="modal-title">Reject <ng-container *ngIf="rejectPartial">and pay partial</ng-container></h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="rejectForm">
      <div class="form-group" *ngIf="rejectPartial">
        <label>Amount to be paid</label>
        <input class="form-control" formControlName="amountToBePaid" type="number">
        <span class="currency">{{overBudgetItemToReject.currency}}</span>
      </div>
      <div class="form-group">
        <label>Rejected by</label>
        <input class="form-control" formControlName="rejectedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Rejected</label>
        <app-datepickerv2-input [parentForm]="rejectForm" controlName="rejectedAt"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Notes</label>
        <textarea class="form-control" formControlName="notes" type="text"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="rejectModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" [disabled]="loading || rejectForm.invalid" (click)="onReject()">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">{{ rejectPartial ? 'Submit' : 'Reject' }}</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-over-budget-request-history-modal #viewHistoryModal></app-over-budget-request-history-modal>
<app-visit-patient-detail-modal #visitPatientDetailModal></app-visit-patient-detail-modal>
