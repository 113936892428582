import { LogHelper } from "./log.helper";

export class NumberHelper {
  public static decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;

    let input = event.target.value;
    if (event.key === '.' || (+event.key >= 0 && +event.key <= 9))
      input += event.key;

    if (!reg.test(input) || event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
  }
}
