import { AlertService } from './../../../../../shared/alert/alert.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Currencies } from 'app/core/constants/currency';
import { ModalComponent } from 'app/shared/modal/modal.component';
import {
  CreateTrialPolicyExpenseRuleRequest,
  ExpenseAutoApprovalRuleType,
  ExpenseAutoApprovalRuleTypeString
} from '../../trial-policy.model';
import { TrialService } from 'app/core/services/trial.service';
import { LogHelper } from 'app/core/helpers/log.helper';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { ExpenseRuleCategoriesValidator } from 'app/core/validators/expense-rule-categories.validator';

@Component({
  selector: 'app-create-expense-rule-modal',
  templateUrl: './create-expense-rule-modal.component.html',
  styleUrls: ['./create-expense-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateExpenseRuleModalComponent implements OnInit {
  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @ViewChild("typeSelect") typeSelect: DropdownInputComponent;
  @Input() defaultCurrency: string;
  @Input() policyId: string;
  @Output() ruleAdded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent;

  ruleForm: UntypedFormGroup;
  expenseCategories: { id: string, value: string }[] = [];
  currencies: { value: string, text: string }[] = [];
  types: { value: string, text: string }[] = [];

  isFormProcessing = false;

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.initRuleForm();
  }

  show() {
    this.initiateCurrencies();
    this.initiateTypes();
    this.initiateCategories();

    this.modal.show();
  }

  initRuleForm() {
    this.ruleForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl(''),
      receiptRequired: new UntypedFormControl(false),
      receiptRequiredValue: new UntypedFormControl(0, Validators.min(0)),
      allCategories: new UntypedFormControl(false),
      amount: new UntypedFormControl('', [Validators.min(0), Validators.required]),
      caregiverAmount: new UntypedFormControl('', Validators.min(0)),
      currency: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      categories: new UntypedFormControl(null, Validators.required),
      overBudgetRequestInformation: new UntypedFormControl('')
    }, { validators: ExpenseRuleCategoriesValidator });

    this.ruleForm.get("allCategories").valueChanges.subscribe({
      next: val => {
        if (val) {
          this.ruleForm.get("categories").disable();
          this.ruleForm.get("categories").setValue(null);
        } else {
          this.ruleForm.get("categories").enable();
        }
      }
    })
  }

  initiateCurrencies() {
    if (!this.currencies.length) {
      this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
    }
    if (this.defaultCurrency) {
      setTimeout(() => {
        this.currencySelect.setValue(this.defaultCurrency);;
      }, 10);
    }
  }

  initiateTypes() {
    if (!this.types.length) {
      this.types.push({ value: ExpenseAutoApprovalRuleType.Visit.toString(), text: ExpenseAutoApprovalRuleTypeString.Values[ExpenseAutoApprovalRuleType.Visit] });
      this.types.push({ value: ExpenseAutoApprovalRuleType.Day.toString(), text: ExpenseAutoApprovalRuleTypeString.Values[ExpenseAutoApprovalRuleType.Day] });
    }
  }

  initiateCategories() {
    if (!this.expenseCategories.length) {
      this.trialService.getPolicyCategories(this.policyId).subscribe({
        next: response => {
          response.forEach(c => this.expenseCategories.push({ id: c.id, value: c.name }));
        }
      })
    }
  }

  submit() {
    let request = this.ruleForm.value as CreateTrialPolicyExpenseRuleRequest;
    request.policyId = this.policyId;

    if (!request.receiptRequiredValue) {
      request.receiptRequiredValue = 0;
    }

    if (!request.categories) {
      request.categories = [];
    }

    this.isFormProcessing = true;

    this.trialService.createTrialPolicyExpenseRule(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Expense rule successfully created.");
        this.ruleAdded.emit();
        this.isFormProcessing = false;
        this.hideModal();
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to create expense rule. Please check your input and try again or contact support.");
        this.isFormProcessing = false;
      }
    });
  }

  hideModal(){
    this.initRuleForm();
    this.currencySelect.reset();
    this.typeSelect.reset();
    this.modal.hide();
  }
}
