import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private _http: HttpClient) { }

  downloadExpenseFile(filename: string): Observable<Blob> {
    return this._http.get(environment.apiUrl + '/expense/download/' + filename, {
      responseType: 'blob'
    });
  }
}
