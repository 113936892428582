import {StringHelper} from "./string-helper";

export class CsvToJsonHelper {
  public static convert(csv: string): any[] {
    const lines = csv.split(/\r?\n/);
    const result = [];
    const headers = lines[0].split(',');

    // Clean up headings
    for (let i = 0; i < headers.length; i++) {
      headers[i] = StringHelper.convertToCamelCase(headers[i]);
    }

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  }
}
