import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { ExpenseService } from "../../../core/services/expense.service";
import { TemplateService } from "../../../core/services/template.service";
import { ExpenseCategoryTranslation } from "../../../core/models/expense-category-list.model";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { Cultures } from 'app/core/constants/cultures';

@Component({
  selector: 'app-expense-category-add',
  templateUrl: './expense-category-add.component.html',
  styleUrls: ['./expense-category-add.component.scss']
})
export class ExpenseCategoryAddComponent implements OnInit {
  @ViewChild('typeDropdown') typeDropdown: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;

  form: UntypedFormGroup;
  forms: UntypedFormGroup[] = [];
  isFormProcessing = false;
  languageSelectForm: UntypedFormGroup;

  allCultures: { value: string; text: string }[] = [];
  culturesInUse: { value: string; text: string }[] = [];
  currentCulture = 'en';

  typeOptions: { value: string; text: string }[] = [];

  constructor(private readonly alertService: AlertService, private readonly templateService: TemplateService, private readonly expenseService: ExpenseService, private readonly router: Router) {
    for (const culture of Cultures.all()) {
      this.allCultures.push({ value: culture.culture, text: culture.name });

      // Add english as a culture in use (default)
      if (culture.culture === 'en') {
        this.culturesInUse.push({ value: culture.culture, text: culture.name });
      }
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.typeOptions.push({ value: '0', text: 'Value' });
    this.typeOptions.push({ value: '1', text: 'Distance' });

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('0', Validators.required)
    });

    // set default type to value
    setTimeout(() => { this.typeDropdown.setValue('0'); }, 500);

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });

    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });
  }

  onTypeChanged(value: string) {
    this.form.patchValue({ type: value });
  }

  getFormAtIndex(index: 0) {
    if (this.forms.length > index) {
      return this.forms[index];
    }

    return null;
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    this.culturesInUse.forEach(culture => {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
      }
    });

    return inUse;
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
      let form = new UntypedFormGroup({
        culture: new UntypedFormControl(culture.value),
        name: new UntypedFormControl('')
      });
      this.forms.push(form);
      this.currentCulture = culture.value;
      this.languageSelectForm.patchValue({ culture: culture.value });
      this.addLanguageModal.hide();
    }
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
    }

    this.removeLanguageModal.hide();
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;

      // Build translations
      const translations: ExpenseCategoryTranslation[] = [];

      this.forms.forEach(form => {
        if (form.get('name').value !== '') {
          translations.push(new ExpenseCategoryTranslation(
            null,
            null,
            form.get('name').value,
            form.get('culture').value));
        }
      });

      this.expenseService.createExpenseCategory(
        this.form.get('name').value,
        +this.form.get('type').value,
        translations
      ).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Expense Category Added Successfully');
          this.router.navigate(['/category']);
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was a problem!');
        }
      });
    }
  }
}
