import { ExpenseCategoryType, ExpenseClaimState } from './../../../../core/models/expense.model';
import { Permissions } from './../../../../core/constants/permissions';
import { ExpenseClaimViewModel } from 'app/core/models/expense.model';
import { AuthService } from './../../../../core/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-expense-actions',
  templateUrl: './expense-actions.component.html',
  styleUrls: ['./expense-actions.component.scss']
})
export class ExpenseActionsComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;
  @Input() dataLoaded: boolean;
  @Input() processingRequest: boolean;

  @Output() edit: EventEmitter<string> = new EventEmitter<string>();
  @Output() check: EventEmitter<ExpenseClaimViewModel> = new EventEmitter<ExpenseClaimViewModel>();
  @Output() approve: EventEmitter<ExpenseClaimViewModel> = new EventEmitter<ExpenseClaimViewModel>();
  @Output() markAsPaid: EventEmitter<string> = new EventEmitter<string>();
  @Output() reject: EventEmitter<string> = new EventEmitter<string>();
  @Output() startInvestigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeInvestigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() refreshResult: EventEmitter<any> = new EventEmitter<any>();

  Permissions = Permissions;
  ExpenseCategoryType = ExpenseCategoryType;
  ExpenseClaimState = ExpenseClaimState;

  constructor(public authService: AuthService) { }

  ngOnInit(): void { }

  propagateResult(){
    if(this.expense.categoryType === ExpenseCategoryType.Distance){
      this.refreshResult.emit();
    }
  }
}
