import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeLog } from "./change-log.model";

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html'
})
export class ChangeLogComponent {
  @Output() exportClicked = new EventEmitter<void>();
  @Input() results: ChangeLog
  @Input() hideExportBtn = false;
  @Input() dataLoaded: boolean = false;

  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  @Output() pageChanged = new EventEmitter<number>();

  onChangePage(page: number) {
    this.pageChanged.emit(page);
  }
}
