import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from "../../../shared/alert/alert.service";
import { SettingsService } from "../../../core/services/settings.service";
import { TemplateService } from "../../../core/services/template.service";
import { PlatformVersionFormModel } from "./platform-version-form.model";
import { debounceTime } from "rxjs/operators";
import { LogHelper } from "../../../core/helpers/log.helper";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  form: FormGroup;
  platformVersionLoaded = false;

  constructor(private templateService: TemplateService, private readonly settingsService: SettingsService, private readonly alertService: AlertService) {
    this.templateService.showHeader();
  }

  ngOnInit() {
    this.form = new FormGroup<PlatformVersionFormModel>({
      android: new FormControl(null, [Validators.required]),
      ios: new FormControl(null, [Validators.required]),
      processing: new FormControl(false),
    });

    this.settingsService.getPlatformVersions().subscribe({
      next: rsp => {
        this.form.patchValue({
          android: rsp.android,
          ios: rsp.ios
        });
        this.platformVersionLoaded = true;
      },
      error: error => {
        this.alertService.showWarningAlert("There was a problem loading the platform versions. Please refresh to try again.");
      }
    });

    // Subscribe to field value changes so we can prevent the user from entering none numeric values
    this.subscribeToField('android');
    this.subscribeToField('ios');
  }

  /**
   * Subscribes to the value changes of a field and prevents the user from entering none numeric values
   * @param fieldName
   * @private
   */
  private subscribeToField(fieldName: string) {
    this.form.get(fieldName).valueChanges.pipe(
      debounceTime(100)
    ).subscribe(value => {
      const formattedValue = (value || '').replace(/[^0-9.]/g, '');
      if (formattedValue !== value) {
        this.form.get(fieldName).setValue(formattedValue, { emitEvent: false });
      }
    });
  }

  onUpdatePlatformVersions() {
    if (this.form.valid) {
      this.form.patchValue({ processing: true });
      this.settingsService.updatePlatformVersions(this.form.value['android'], this.form.value['ios']).subscribe({
        next: () => {
          this.alertService.showSuccessAlert("Platform versions updated successfully.");
          this.form.patchValue({ processing: false });
          this.form.markAsUntouched();
        },
        error: (error) => {
          LogHelper.log(error);
          this.form.patchValue({ processing: false });
          this.alertService.showWarningAlert("There was a problem updating the platform versions. Please try again.");
        }
      });
    }
  }
}
