import { SearchPage } from "./search-page.model";

export class TrialPatientListItem {
  constructor(
    public id: string,
    public patientCode: string,
    public patientTrialId: string,
    public firstname: string,
    public lastname: string,
    public address: string,
    public country: string,
    public stateOnTrial: string
  ) {
  }
}

export class TrialPatientList extends SearchPage {
  results: TrialPatientListItem[] = [];
}
