import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ComponentBase } from "../../core/component-base";
import { ModalV2Component } from "../modal-v2/modal-v2.component";
import { TabulatedContentComponent } from "../tabulated-content/tabulated-content.component";
import { CreateSiteModalComponent } from "../create-site-modal/create-site-modal.component";
import { ModalViewport } from "../modal-v2/modal-viewport";
import { TabItem } from "../tabulated-content/tab-item.model";
import { optionalEmailValidator } from "../../core/validators/optional-email.validator";
import { Address } from "../input-address/address.model";
import { InputAddressComponent } from "../input-address/input-address.component";
import { PatientDetail } from "../../core/models/patient-detail.model";
import { SelectOption } from "../../core/models/select-option.model";
import { LogHelper } from "../../core/helpers/log.helper";
import { AlertService } from "../alert/alert.service";
import { PatientService } from "../../core/services/patient.service";
import { AutosuggestDropdownInputComponent } from "../autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { IPatientUpdate } from "../../core/services/interfaces/patient-update.interface";
import { PatientTrial } from "../../core/models/patient-trial.model";
import { Countries } from "../../core/constants/countries";
import { LanguageCultureOptions } from "../../core/constants/language-culture-options";
import { PatientManageTrialsComponent } from "./patient-manage-trials/patient-manage-trials.component";
import { Permissions } from "../../core/constants/permissions";
import { AuthService } from "../../core/services/auth.service";
import { Router } from "@angular/router";
import { PatientDeleteModalComponent } from "../patient-delete-modal/patient-delete-modal.component";

@Component({
  selector: 'app-patient-edit-modal',
  templateUrl: './patient-edit-modal.component.html',
  styleUrl: './patient-edit-modal.component.scss'
})
export class PatientEditModalComponent extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChildren('patientTrial', { read: PatientManageTrialsComponent }) patientTrials: QueryList<PatientManageTrialsComponent>;
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;
  @ViewChild('createSiteModal') createSideModal: CreateSiteModalComponent;
  @ViewChild('countriesSelect') countriesSelect: AutosuggestDropdownInputComponent;
  @ViewChild('homeAddress') homeAddress: InputAddressComponent;
  @ViewChild('deletePatientModal') deletePatientModal: PatientDeleteModalComponent;

  @Output('patientUpdated') patientUpdated = new EventEmitter();

  viewPort: ModalViewport = ModalViewport.Medium;

  saveButtonDisabled = false;
  patientTrialForms: UntypedFormGroup[] = [];
  patient = new PatientDetail();
  hideAssignTrialButton = false;
  assignedTrialIds: string[] = [];
  hideAddress = false;
  form: FormGroup;
  patientInfoForm: FormGroup;
  extraInfoForm: FormGroup;
  patientLoaded = false;
  countries: { value: string, text: string }[] = [];
  languageOptions: SelectOption[] = [];

  tabs: TabItem[] = [
    new TabItem({title: 'Patient Info', visible: true}),
    new TabItem({title: 'Extra Info', visible: true}),
    new TabItem({title: 'Assigned Trials', visible: true})
  ];
  selectedTabIndex = 0;
  showCreatePatientButton = false;

  constructor(private _alertService: AlertService, private _patientService: PatientService, public authService: AuthService, private router: Router) {
    super();

    for (const country of Countries.all()) {
      this.countries.push({ value: country.code, text: country.name });
    }

    const languageCultures = LanguageCultureOptions.all();
    languageCultures.forEach(culture => {
      this.languageOptions.push({ value: culture.cultureInfoCode.toLowerCase(), text: culture.language + ' (' + culture.cultureInfoCode.toLowerCase() + ')' });
    });
    this.languageOptions.sort((a, b) => a.text.localeCompare(b.text));
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {

  }

  initForm(): void {
    this.saveButtonDisabled = false;
    this.patientLoaded = false;
    this.form = new FormGroup({
      processing: new FormControl<boolean>(false),
      patientInfo: new FormGroup({
        firstname: new FormControl<string>('', Validators.required),
        lastname: new FormControl<string>('', Validators.required),
        email: new FormControl<string>('', [Validators.required, optionalEmailValidator]),
        country: new FormControl<string>(''),
        address: new FormControl<Address>(null),
        homeAddress: new FormControl(null),
        culture: new FormControl<string>('en-gb')
      }),
      extraInfo: new FormGroup({
        notes: new FormControl<string>(''),
        policyReminders: new FormControl<string>(''),
        patientNotOnApp: new FormControl<boolean>(false, Validators.required)
      })
    });

    this.patientInfoForm = this.form.get('patientInfo') as FormGroup;
    this.extraInfoForm = this.form.get('extraInfo') as FormGroup;

    // Show exclamation mark on tabs is form is invalid
    this.form.valueChanges.subscribe(changes => {
      if (this.patientInfoForm.invalid) {
        this.tabulatedContent.addTabClass('Patient Info', 'invalid');
      } else {
        this.tabulatedContent.removeTabClass('Patient Info', 'invalid');
      }

      if (this.extraInfoForm.invalid) {
        this.tabulatedContent.addTabClass('Extra Info', 'invalid');
      } else {
        this.tabulatedContent.removeTabClass('Extra Info', 'invalid');
      }
    });
  }

  reset(): void {
    this.initForm();
  }

  /**
   * Shows the modal
   */
  show(patientId: string = null): void {
    this.reset();

    // Switch to the first tab
    this.tabulatedContent.switchTab(0, true);

    // Show modal
    this.modal.show();

    this._patientService.retrievePatientDetail(patientId).subscribe({
      next: patient => {
        this.patient = patient;
        this.patientLoaded = true;

        LogHelper.log(patient);

        this.patientInfoForm.setValue({
          firstname: patient.firstname,
          lastname: patient.lastname,
          email: patient.externalEmail ? patient.externalEmail : patient.email,
          country: patient.country,
          address: patient.address,
          homeAddress: patient.homeAddress,
          culture: patient.culture.toLowerCase()
        });

        this.extraInfoForm.setValue({
          notes: patient.note,
          policyReminders: patient.policyReminders,
          patientNotOnApp: patient.patientNotOnApp,
        });

        if (patient.homeAddress.equals(patient.address))
          this.hideAddress = true;

        if (!patient.country && !patient.address)
          this.hideAddress = true;

        this.homeAddress.setAddress(patient.homeAddress);

        this.countriesSelect.setSelectedOption(patient.country);

        this.patientTrialForms = [];
        patient.trials.forEach(patientTrial => {
          this.assignedTrialIds.push(patientTrial.trialId);

          let trialForm = new UntypedFormGroup({
            id: new UntypedFormControl(patientTrial.id),
            patientCode: new UntypedFormControl(patientTrial.patientCode, [Validators.required, Validators.maxLength(16)]),
            trialId: new UntypedFormControl(patientTrial.trialId, Validators.required),
            trialCode: new UntypedFormControl(patientTrial.trialCode),
            siteId: new UntypedFormControl(patientTrial.siteId, Validators.required),
            consent: new UntypedFormControl(true, Validators.requiredTrue),
            trialIdIsUnique: new UntypedFormControl(true, Validators.requiredTrue),
            bankFeesIncurred: new UntypedFormControl(patientTrial.bankFeesIncurred),
            overspendTravel: new UntypedFormControl(patientTrial.overspendTravel),
            overspendMileage: new UntypedFormControl(patientTrial.overspendMileage),
            fixedFeePerVisit: new UntypedFormControl(patientTrial.fixedFeePerVisit),
            approvalNoReceipts: new UntypedFormControl(patientTrial.approvalNoReceipts),
            stateOnTrial: new UntypedFormControl(patientTrial.stateOnTrial),
            endingParticipationReason: new UntypedFormControl({ value: patientTrial.endingParticipationReason, disabled: patientTrial.endingParticipationReason }),
            engineSize: new UntypedFormControl(patientTrial.engineSize)
          });

          if (patient.apiConsumerId !== null)
            trialForm.get('patientCode').disable();

          this.patientTrialForms.push(trialForm);
        });

        // Disable old site address
        this.patientInfoForm.get('address').disable();
      },
      error: error => {
        LogHelper.log(error);
        this._alertService.showWarningAlert('Unable to load patient information!');
      }
    });

    this.patientInfoForm.get('country').valueChanges.subscribe(country => {
      if (country !== '') {
        let address = this.patientInfoForm.get('homeAddress').value;

        if (address === null) {
          address = new Address();
        }

        address.countryCode = country;
        this.homeAddress.setAddress(address);
      }
    });

    this.reset();
  }

  /**
   * Hides the modal
   */
  hide(): void {
    this.patient = new PatientDetail();
    this.modal.hide();
  }

  onHandleTabChanged(index: number): void {
    this.selectedTabIndex = index;

    // Show the create patient button if the user has navigated to the last tab
    if (index === this.tabs.length - 1)
      this.showCreatePatientButton = true;
  }

  onTrialAdded(patientTrial: PatientTrial) {
    this.assignedTrialIds.push(patientTrial.trialId);
    this.patient.trials.push(patientTrial);
    this.hideAssignTrialButton = false;
    this.saveButtonDisabled = false;
  }

  onHandleCancelled() {
    this.hideAssignTrialButton = false;
    this.patientTrialForms.pop();
    this.saveButtonDisabled = false;
  }

  removeFromTrial(patientTrialId: string) {
    this.patient.trials = this.patient.trials.filter(pt => pt.id !== patientTrialId);
    this.patientTrialForms = this.patientTrialForms.filter(ptf => ptf.get("id").value !== patientTrialId);
  }

  onAddNewTrial() {
    this.hideAssignTrialButton = true;

    this.patientTrialForms.push(new UntypedFormGroup({
      id: new UntypedFormControl(''),
      patientCode: new UntypedFormControl('', Validators.required),
      trialId: new UntypedFormControl('', Validators.required),
      trialCode: new UntypedFormControl(''),
      siteId: new UntypedFormControl('', Validators.required),
      consent: new UntypedFormControl(false, Validators.requiredTrue),
      trialIdIsUnique: new UntypedFormControl(false, Validators.requiredTrue),
      bankFeesIncurred: new UntypedFormControl(false),
      overspendTravel: new UntypedFormControl(false),
      overspendMileage: new UntypedFormControl(false),
      fixedFeePerVisit: new UntypedFormControl(false),
      approvalNoReceipts: new UntypedFormControl(false),
      stateOnTrial: new UntypedFormControl("Active"),
      endingParticipationReason: new UntypedFormControl(''),
      engineSize: new UntypedFormControl('')
    }));

    this.saveButtonDisabled = true;
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.form.patchValue({ processing: true });

      // Before we save, iterate through the patient trials and put the patient code and site details onto the patient trial
      let i = 0;
      this.patientTrialForms.forEach(patientTrialForm => {
        this.patient.trials[i].patientCode = patientTrialForm.get('patientCode').value;
        this.patient.trials[i].siteId = patientTrialForm.get('siteId').value;
        this.patient.trials[i].fixedFeePerVisit = patientTrialForm.get('fixedFeePerVisit').value;
        this.patient.trials[i].bankFeesIncurred = patientTrialForm.get('bankFeesIncurred').value;
        this.patient.trials[i].overspendTravel = patientTrialForm.get('overspendTravel').value;
        this.patient.trials[i].overspendMileage = patientTrialForm.get('overspendMileage').value;
        this.patient.trials[i].approvalNoReceipts = patientTrialForm.get('approvalNoReceipts').value;
        this.patient.trials[i].stateOnTrial = patientTrialForm.get('stateOnTrial').value;
        this.patient.trials[i].endingParticipationReason = patientTrialForm.get('endingParticipationReason').value;
        this.patient.trials[i].engineSize = patientTrialForm.get('engineSize').value;
        i++;
      });

      const updateDto: IPatientUpdate = {
        firstname: this.patientInfoForm.get('firstname').value,
        lastname: this.patientInfoForm.get('lastname').value,
        country: this.patientInfoForm.get('country').value,
        address: this.patientInfoForm.get('address').value,
        notes: this.extraInfoForm.get('notes').value,
        reminders: this.extraInfoForm.get('policyReminders').value,
        patientNotOnApp: this.extraInfoForm.get('patientNotOnApp').value,
        email: this.patientInfoForm.get('email').value,
        trials: this.patient.trials,
        homeAddress: this.patientInfoForm.get('homeAddress').value,
        culture: this.patientInfoForm.get('culture').value,
      };

      this._patientService.updatePatient(this.patient.id, updateDto).subscribe({
        next: () => {
          this._alertService.showSuccessAlert('Patient Updated Successfully');
          this.hide();
          this.patientUpdated.emit();
        },
        error: error => {
          LogHelper.log(error);
          this.form.patchValue({ processing: false });
          this._alertService.showWarningAlert(error.error.title ? error.error.title : 'Unable to update patient information!');
        }
      });
    }
  }

  onDeletedPatient() {
    void this.router.navigate(["/patient"]);
  }

  protected readonly Permissions = Permissions;
}
