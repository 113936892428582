<div class="image-container {{ isImageProcessing ? 'state-processing' : 'state-loaded'}} {{ imageLoadFailed ? 'state-failed' : ''}}">
  <img [src]="thumbnailUrl" (click)="viewImage()" alt="" (error)="onImageError($event)" (load)="onImageLoad($event)" />

  <div class="loading-failed" *ngIf="imageLoadFailed">
    <i class="far fa-image-slash"></i>
  </div>

  <div class="loading" *ngIf="(isLoading || isImageProcessing) && !imageLoadFailed">
    <div class="spinner-border text-light" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
