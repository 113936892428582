import {Router} from '@angular/router';
import {Injectable, NgZone} from '@angular/core';
import {AuthService} from "./auth.service";

const MINUTES_UNITL_AUTO_LOGOUT = 30 // in Minutes
const CHECK_INTERVALL = 60000 // in ms
const STORE_KEY = 'lastAction';

/**
 * Service records the users last mouse click, if no click within 15 minutes the user is automatically logged out
 * This service is used ONLY within the header component as this is rendered on all pages where a user is authenticated
 */
@Injectable()
export class AutoLogoutService {

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private authService: AuthService
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY));
  }
  set lastAction(value) {
    sessionStorage.setItem(STORE_KEY, value.toString());
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        this.authService.logout(true);
      }
    });
  }
}
