<div class="toast-container" *ngIf="alerts.length > 0" [@inOutAnimation]>
  <div *ngFor="let alert of alerts;" class="toast {{ alert.className }}" [@inOutAnimation] (click)="onCloseToast(alert)" [class.toast-multi-line]="alert.multiLine">
    <div class="toast-icon">
      <i class="icon icon-tick" aria-hidden="true"></i>
      <i class="icon icon-cancel" aria-hidden="true"></i>
    </div>

    <div class="toast-content" *ngIf="alert.multiLine">
      <h2>{{ alert.title }}</h2>
      <p>{{ alert.message }}</p>
    </div>

    <div class="toast-content"  *ngIf="!alert.multiLine">
      <h2>{{ alert.message }}</h2>
    </div>
  </div>
</div>
