import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  bodyClass = new BehaviorSubject<string>('');
  headerIsVisible = new BehaviorSubject<boolean>(false);

  showHeader() {
    // Remove the full width classname from the main container
    // The visit component adds it to the main container.
    const mainContainer = document.querySelector('.main-container');
    mainContainer.classList.remove('full-width');

    this.headerIsVisible.next(true);
  }

  hideHeader() {
    this.headerIsVisible.next(false);
  }
}
