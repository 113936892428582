<div class="tab-pane fade show active details-view-results">
  <div class="patient-notes" [ngClass]="{'has-notes': notes.length > 0}">
    <div class="no-results inline">
      <div class="inner">
        <div class="content">
          <h2>No Notes</h2>
          <p>Start adding notes by typing them below</p>
        </div>
      </div>
    </div>

    <div class="inner">
      <div class="change-log-entries">
        <div class="change-log-entry" *ngFor="let note of notes">
          <p>{{ note.note }}</p>
          <p class="actioned-by">by <strong>{{ note.author }}</strong> on <strong>{{ note.created | date: 'd MMM yyyy' }}</strong> at <strong>{{ note.created | date: 'h:mm aaa' }}</strong></p>
        </div>
      </div>

      <form class="new-note" [formGroup]="newNoteForm" (submit)="onFormSubmit()">
        <div class="form-group ">
          <input type="text" class="form-control" formControlName="text">
          <button type="submit" class="btn btn-primary">
            <span *ngIf="isFormProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isFormProcessing">Add</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
