<div class="site-user-trial-list" [ngClass]="{'visibility-hidden': showAssignForm}">
  <div class="row">
    <div class="col-12 text-right">
      <button type="button" class="btn btn-outline btn-outline-slim" (click)="onHandleShowAssignTrial()">Assign
        Trials</button>
    </div>
  </div>

  <div class="scrollable-table">
    <table class="with-heading-columns scrollable" aria-hidden="true">
      <thead>
        <tr>
          <th>Public Trial Code</th>
          <th style="text-align: center;">Project<br/>Manager</th>
          <th style="text-align: center;">Designated<br/>Contact</th>
          <th class="text-right"><a class="unassign-all-trials" (click)="unassignAllTrialsModel.show()"
              *ngIf="administrator.trials.length > 0">Unassign from all trials</a></th>
        </tr>
      </thead>
      <tbody *ngIf="administrator">
        <ng-container *ngFor="let trial of getTrialsForPage(currentPage); let i = index">
          <tr>
            <td>{{trial.code}}</td>
            <td style="text-align: center;">
              <div class="pm-toggle" [class.checked]="trial.projectManager" (click)="onToggleProjectManager(trial.id)">
                <i class="fas fa-check"></i>
                <i class="fas fa-times"></i>
              </div>
            </td>
            <td style="text-align: center;">
              <div class="pm-toggle" [class.checked]="trial.designatedContact" (click)="onToggleDesignatedContact(trial.id)">
                <i class="fas fa-check"></i>
                <i class="fas fa-times"></i>
              </div>
            </td>
            <td class="btns site-actions">
              <a (click)="unassignModel.showUsingDeleteIndex(i)"
                class="btn btn-outline btn-slim btn-auto-width">Unassign</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <app-pagination [currentPage]="currentPage" [totalPageCount]="pageCount"
    (pageSelected)="onHandlePageChanged($event)"></app-pagination>
</div>

<div class="site-user-trial-assign" [ngClass]="{'visibility-hidden': !showAssignForm}">
  <form [formGroup]="assignTrialForm" (submit)="onHandleAssignTrials()">
    <h2><a (click)="onHandleReturnToForm()"><i class="far fa-angle-left" aria-hidden="true"></i></a> <span
        *ngIf="administrator !== null && administrator.id">{{ administrator.firstname }} {{ administrator.lastname }} /
      </span>Assign Trials</h2>

    <table class="simple-form" aria-hidden="true">
      <tr>
        <th style="min-width: 50px; vertical-align: middle"><label>Public Trial Code</label></th>
        <td>
          <app-input-multi-select #designatedContacts (onItemClick)="onHandleTrialClicked($event)"
            (onFilter)="filterTrials($event)" [parentForm]="assignTrialForm" controlName="dropdownTrialIds"
            [items]="filteredTrials" inputPlaceholder="Please select..." searchInputPlaceholder="Search for trials..."
            [allowFiltering]="true" [allowSelectDeselect]="true"></app-input-multi-select>
        </td>
      </tr>
      <tr>
        <th></th>
        <td>
          <div class="checkbox-wrap">
            <div class="form-group">
              <label class="checkbox">
                <input type="checkbox" id="isProjectManager" formControlName="projectManager" value="false">
                <span></span>
              </label>
              <label class="checkbox-label" for="isProjectManager">Project Manager</label>
            </div>

            <div class="designated-contacts-checkbox">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="isDesignatedContact" formControlName="designatedContact" value="false">
                  <span></span>
                </label>
                <label class="checkbox-label" for="isDesignatedContact">Designated Contact <span class="helper-text">Expense/Travel Coordinators Only</span></label>
              </div>
            </div>

            <div class="form-group">
              <label class="checkbox">
                <input type="checkbox" id="assignToAllTrials" formControlName="assignToAllTrials" value="true">
                <span></span>
              </label>
              <label class="checkbox-label" for="assignToAllTrials">Assign administrator to all current trials</label>
            </div>
          </div>

          <div class="checkbox-wrap">

          </div>
        </td>
      </tr>
      <tr>
        <th></th>
        <td>
          <div class="btn-wrap">
            <div class="row">
              <div class="col-6">
              </div>
              <div class="col-6">
                <button type="submit" class="btn btn-primary" (click)="onHandleAssignTrial()"
                  [disabled]="!assignTrialForm.valid">{{ assignButtonLabel }}</button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </form>
</div>

<!-- Start: Unassign site modal -->
<app-modal #unassignModel>
  <div class="modal-header">
    <h2 class="modal-title">Unassign Administrator from Trial?</h2>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to remove this administrator from the trial?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onHandleDeleteConfirmed(unassignModel.deleteIndex)" class="btn btn-primary">
          Unassign
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign site modal -->

<!-- Start: Unassign all trials modal -->
<app-modal #unassignAllTrialsModel>
  <div class="modal-header">
    <h2 class="modal-title">Unassign All Trials?</h2>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to unassign this administrator from all assigned trials?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignAllTrialsModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmUnassignAllTrials()" class="btn btn-primary">
          Unassign all trials
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign all trials modal -->
