import { Permissions } from './../../../../core/constants/permissions';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TrialDetail, TrialSupportContact } from 'app/core/models/trial-detail.model';
import { TrialService } from 'app/core/services/trial.service';
import { Countries } from 'app/core/constants/countries';
import { LogHelper } from 'app/core/helpers/log.helper';
import { AuthService } from 'app/core/services/auth.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { Cultures } from 'app/core/constants/cultures';
import { GlobalConstants } from 'app/core/constants/global-constants';

@Component({
  selector: 'app-trial-contacts',
  templateUrl: './trial-contacts.component.html',
  styleUrls: ['./trial-contacts.component.scss']
})
export class TrialContactsComponent implements OnInit, AfterViewInit {
  @Input('trial') trial: TrialDetail;
  cultures: { text: string, value: string }[] = [];
  countryList: { text: string, value: string }[] = [];
  isSaving = false;
  hasChanges = false;
  removedContacts: string[] = [];
  defaultContactForm: UntypedFormGroup;
  defaultContactsFormProcessing = false;
  Permissions = Permissions;

  constructor(public authService: AuthService, private readonly alertService: AlertService, private readonly trialService: TrialService) { }

  ngOnInit() {
    // Populate cultures
    this.cultures.push({ value: '', text: 'All' });
    for (const culture of Cultures.all()) {
      this.cultures.push({ value: culture.culture, text: culture.name });
    }

    // Populate countries
    for (const country of Countries.all()) {
      this.countryList.push({ value: country.code, text: country.name });
    }

    this.defaultContactForm = new UntypedFormGroup({
      supportEmail: new UntypedFormControl({
        value: '',
        disabled: !this.authService.hasPermission(Permissions.TrialSupportContactsAdd)
      }, [Validators.required, Validators.pattern(GlobalConstants.emailValidatorPattern)]),
      supportPhone: new UntypedFormControl({
        value: '',
        disabled: !this.authService.hasPermission(Permissions.TrialSupportContactsAdd)
      }, [Validators.required, Validators.pattern(GlobalConstants.telephoneNumberPattern)])
    });
  }


  ngAfterViewInit() {
    if (this.trial !== undefined && this.trial !== null) {
      this.defaultContactForm.patchValue({
        supportEmail: this.trial.supportEmail,
        supportPhone: this.trial.supportPhone
      });
    }
  }

  onRemoveContact(index: number) {
    this.removedContacts.push(this.trial.supportContacts[index].id);
    this.trial.supportContacts.splice(index, 1);
    this.hasChanges = true;
  }

  onUpdateCountry(index: number, value: string) {
    this.trial.supportContacts[index].country = value;
    this.hasChanges = true;
  }

  onUpdateCulture(index: number, value: string) {
    this.trial.supportContacts[index].culture = value;
    this.hasChanges = true;
  }

  onUpdateLabel(index: number, event: Event) {
    const element = event.target as HTMLInputElement;
    this.trial.supportContacts[index].label = element.value;
    this.hasChanges = true;
  }

  onUpdateContact(index: number, event: Event) {
    const element = event.target as HTMLInputElement;
    this.trial.supportContacts[index].contact = element.value;
    this.hasChanges = true;
  }

  onAddContact() {
    this.trial.supportContacts.push(new TrialSupportContact(null, '', '', 'AF', ''));
  }

  onUpdateSupportContacts() {
    let isValid = true;
    this.trial.supportContacts.forEach(contact => {
      if (contact.contact === null || contact.contact === '' || contact.label === null || contact.label === '') {
        isValid = false;
      }
    });

    if (isValid) {
      this.isSaving = true;
      this.trialService.updateContacts(
        this.trial.id,
        this.removedContacts,
        this.trial.supportContacts).subscribe({
          next: () => {
            this.alertService.showSuccessAlert('Support contacts have been updated.');
            this.removedContacts = [];
            this.hasChanges = false;
            this.isSaving = false;

            // Update trial to re-load contact Id's
            this.trialService.retrieveTrial(this.trial.id).subscribe(trial => {
              this.trial = trial;
            });
          },
          error: error => {
            LogHelper.log(error);
            this.isSaving = false;
            this.alertService.showWarningAlert(error.error.title);
          }
        });
    } else {
      this.alertService.showWarningAlert('Please provide a label and contact for ALL contacts above.');
    }
  }

  onUpdateDefaultSupportContacts(): void {
    const form = this.defaultContactForm;

    if (form.valid) {
      this.defaultContactsFormProcessing = true;
      this.trialService.updateDefaultSupportContact(
        this.trial.id,
        form.get('supportEmail').value,
        form.get('supportPhone').value).subscribe({
          next: () => {
            this.trial.supportPhone = form.get('supportPhone').value;
            this.trial.supportEmail = form.get('supportEmail').value;
            this.defaultContactForm.markAsUntouched();
            this.defaultContactsFormProcessing = false;
            this.alertService.showSuccessAlert('Default support contacts updated successfully');
          },
          error: () => {
            this.alertService.showWarningAlert('Sorry, there was a problem.');
            this.defaultContactsFormProcessing = false;
          }
        });
    }
  }
}
