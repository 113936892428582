import { Permissions } from "./../../../../core/constants/permissions";
import { AuthService } from "./../../../../core/services/auth.service";
import {
  ActivateCardsRequest,
  AssignAndMoveWalletRequest,
  AssignCardRequest,
  AvailableWalletsResponse,
  BankCoverFee,
  CaxtonAssignCardRequest,
  CaxtonTitle,
  Gender,
  GetCardPinRequest,
  GetWalletBalanceRequest,
  PatientBasicInfo,
} from "./../../../../core/models/patient.model";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, } from "@angular/forms";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, } from "@angular/core";
import { DropdownInputComponent } from "app/shared/dropdown-input/dropdown-input.component";
import { BankRequirements, CountryInfo, } from "../../../../core/models/payment.models";
import {
  AssignWalletOnParentRequest,
  BankTransferMethod,
  BankTransferMethodString,
  PatientPaymentInfoUpdateRequest,
  PaymentMethod,
  PaymentMethodString,
} from "../../../../core/models/patient.model";
import { SortCodeValidator } from "../../../../core/validators/sort-code.validator";
import { PatientService } from "../../../../core/services/patient.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PaymentService } from "../../../../core/services/payment.service";
import { v4 as uuidv4 } from "uuid";
import { Currencies } from "app/core/constants/currency";
import { IbanRegex } from "app/core/constants/ibanRegex";
import { SelectOption } from "app/core/models/select-option.model";
import { ModalComponent } from "app/shared/modal/modal.component";
import { LogHelper } from "app/core/helpers/log.helper";
import {
  AutosuggestDropdownInputComponent
} from "app/shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { BankAccount } from "../../../../core/models/bank-account.model";
import { RoleNames } from "app/core/constants/role-names";
import { CurrencyDecimalAmountPipe } from "app/core/pipes/currency-decimal-amount.pipe";

@Component({
  selector: "app-patient-payment-info",
  templateUrl: "./patient-payment-info.component.html",
  styleUrls: ["./patient-payment-info.component.scss"],
})
export class PatientPaymentInfoComponent implements OnInit, AfterViewInit {
  @ViewChild("defaultPaymentMethodSelect") defaultPaymentMethodSelect: DropdownInputComponent;
  @ViewChild("bankTransferMethodSelect") bankTransferMethodSelect: DropdownInputComponent;
  @ViewChild("titleSelect") titleSelect: DropdownInputComponent;
  @ViewChild("genderSelect") genderSelect: DropdownInputComponent;
  @ViewChild("countrySelect") countrySelect: DropdownInputComponent;
  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @ViewChild("bankCoverFeeCurrencySelect") bankCoverFeeCurrencySelect: DropdownInputComponent;
  @ViewChild("assignWalletSelect") assignWalletSelect: AutosuggestDropdownInputComponent;
  @ViewChild("confirmWalletAssignmentModal") confirmWalletAssignmentModal: ModalComponent;
  @ViewChild("confirmGrantOnlineAccessToCaxtonModal") confirmGrantOnlineAccessToCaxtonModal: ModalComponent;
  @ViewChild("grantOnlineAccessToCaxtonFormModal") grantOnlineAccessToCaxtonFormModal: ModalComponent;
  @ViewChild("cardAlreadyActiveModal") cardAlreadyActiveModal: ModalComponent;
  @ViewChild("viewCardPinModal") viewCardPinModal: ModalComponent;
  @ViewChild("dob") dob: ElementRef;

  PaymentMethod = PaymentMethod;
  BankTransferMethod = BankTransferMethod;

  @Input() trialId: string;
  @Input() patientId: string;
  @Input() patientBasicInfo: PatientBasicInfo;

  //Grant online access to caxton portal
  isCaxtonAccessProcessing: boolean = false;
  caxtonOnlineAccessForm: UntypedFormGroup;
  caxtonOnlineAccessGranted: boolean = false;
  genderOptions: SelectOption[] = [];
  defaultDOB: string = "01/01/1970";
  //Card activation
  isCardActivationProcessing: boolean = false;
  cardsActivated: boolean = false;
  //View card pin
  RoleNames = RoleNames;
  isCardPinProcessing: boolean = false;
  cardPinImageUrl: string;
  //Wallet balance
  isWalletBalanceProcessing: boolean = false;
  walletBalance: string = '';
  balanceSeparator: string = '/';

  previousBeneficiaryIds: string[] = [];
  showPreviousBeneficiaries: boolean = false;
  updateBeneficiary: boolean = false;
  currentBeneficiaryId: string;
  paymentInfoForm: UntypedFormGroup;
  defaultPaymentMethods: { value: string; text: string }[] = [];
  bankTransferMethods: { value: string; text: string }[] = [];
  titleOptions: { value: string; text: string }[] = [];
  countries: { value: string; text: string }[] = [];
  currencies: { value: string; text: string }[] = [];
  assignWalletOptions: SelectOption[] = [];
  availableWalletsList: AvailableWalletsResponse[] = [];
  countriesList: CountryInfo[] = [];
  isFormProcessing: boolean = false;
  formInitialized: boolean = false;
  trialCaxtonEnabled: boolean = false;
  invalidForm: boolean = false;
  currency: string;
  bankRequirements: BankRequirements = {
    usesIban: false,
    usesSortCode: false,
    usesSwift: false
  };
  bankFeeCoverData: BankCoverFee[] = [];
  Permissions = Permissions;
  isAssignmentProcessing: boolean = false;
  bankAccount = new BankAccount({ bankAccountEnabled: false });

  constructor(
    private paymentService: PaymentService,
    private patientService: PatientService,
    private alertService: AlertService,
    private changeDetector: ChangeDetectorRef,
    public authService: AuthService,
    private decimalPipe: CurrencyDecimalAmountPipe
  ) { }

  ngOnInit(): void {
    this.initiateDefaultPaymentMethods();
    this.initiateBankTransferMethod();
    this.initiatePaymentInfoForm();
    this.initializeGrantCaxtonOnlineAccessForm();
    this.initiateCurrencies();
    this.initiateTitleOptions();
    this.initializeGenderOptions();

    if (this.authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails)) {
      this.getFullPatientPaymentInfo();
    }
    else {
      this.getPatientPaymentInfo();
    }
  }

  ngAfterViewInit(): void {
    this.loadBankAccount();
  }

  loadBankAccount(): void {
    this.patientService.bankAccount(this.patientId).subscribe({
      next: (bankAccount) => {
        this.bankAccount = bankAccount;
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      },
    });
  }

  initiateTitleOptions() {
    this.titleOptions = CaxtonTitle.all().map((x) => ({ value: x, text: x }));
  }

  initializeGenderOptions() {
    this.genderOptions = Gender.all().map(
      (x) => ({ value: x, text: x } as SelectOption)
    );
  }

  initializeAvailableWallets() {
    let paymentMethod = this.paymentInfoForm.get("defaultPaymentMethod").value as PaymentMethod;
    if (paymentMethod.toString() === PaymentMethod.Card.toString() && this.availableWalletsList.length === 0) {
      this.getAvailableWallets();
    }
  }

  getAvailableWallets() {
    this.patientService.availableWallets(this.trialId).subscribe({
      next: (response) => {
        if (!this.assignWalletOptions.length) {
          response.forEach((r) =>
            this.assignWalletOptions.push({
              value: r.walletId,
              text: r.walletName,
            })
          );
          this.availableWalletsList = response;
        }
      },
      error: (error) => {
        this.alertService.showWarningAlert("Failed to get wallets.");
        LogHelper.log(error);
      },
    });
  }

  getWalletBalances() {
    if (this.cardsActivated) {
      this.isWalletBalanceProcessing = true;
      let walletId = this.paymentInfoForm.get("caxtonWalletId").value;

      let request = {
        patientTrialId: this.patientId,
        walletId: walletId
      } as GetWalletBalanceRequest

      this.paymentService.getWalletBalances(request).subscribe({
        next: result => {
          let formattedBalances = result.balances
            .filter(balance => balance.value !== 0)
            .map(balance => `${balance.symbol}${this.decimalPipe.transform(balance.value, '1.2-2', true)}`);

          this.walletBalance = formattedBalances.join(` ${this.balanceSeparator} `);
          if (formattedBalances.length === 0 && result.balances.length !== 0) {
            this.walletBalance = `0`;
          }
          this.isWalletBalanceProcessing = false;
        },
        error: () => {
          this.isWalletBalanceProcessing = false;
          this.walletBalance = 'N/A';
          this.alertService.showWarningAlert('There was a problem getting your account balance. Please retry and if problem persists contact administrator.');
        }
      });
    }
  }

  componentVisible(paymentMethod: PaymentMethod, bankTransferMethod: BankTransferMethod) {
    return (this.defaultPaymentMethodSelect?.selectedValue === paymentMethod.toString()
      && this.bankTransferMethodSelect.selectedValue === bankTransferMethod.toString());
  }

  getPatientPaymentInfo() {
    this.patientService.getPatientPaymentInfo(this.patientId).subscribe({
      next: (response) => {
        this.paymentInfoForm.patchValue({
          defaultPaymentMethod: response.defaultPaymentMethod,
          bankTransferMethod: response.bankTransferMethod,
          caxtonWalletName: response.caxtonWalletName,
          caxtonWalletId: response.caxtonWalletId,
        });

        this.bankTransferMethodSelect.setValue(
          response.bankTransferMethod.toString()
        );
        this.defaultPaymentMethodSelect.setValue(
          response.defaultPaymentMethod.toString()
        );

        this.bankTransferMethodSelect.disabled = true;
        this.defaultPaymentMethodSelect.disabled = true;

        this.formInitialized = true;
        this.initializeAvailableWallets();
      },
      error: () => {
        this.alertService.showWarningAlert(
          "Failed to get patient payment info. Please try again or contact an administrator"
        );
      },
    });
  }

  getFullPatientPaymentInfo() {
    this.patientService.getFullPatientPaymentInfo(this.patientId).subscribe({
      next: (response) => {
        this.previousBeneficiaryIds = response.previousBeneficiaryIds;
        this.trialCaxtonEnabled = response.trialCaxtonEnabled;
        this.caxtonOnlineAccessGranted = response.caxtonOnlineAccessGranted;
        this.cardsActivated = response.caxtonCardsActivated;

        this.paymentInfoForm.patchValue({
          defaultPaymentMethod: response.defaultPaymentMethod,
          bankTransferMethod: response.bankTransferMethod,
          beneficiaryId: response.caxtonBeneficiaryId,
          caxtonWalletName: response.caxtonWalletName,
          caxtonWalletId: response.caxtonWalletId,
          firstName: response.firstName,
          lastName: response.lastName,
          accountOwnerAddress: response.accountOwnerAddress,
          initialBankCoverFee: response.initialBankCoverFee,
          bankCoverFeeCurrency: response.bankCoverFeeCurrency,
        });

        this.titleSelect.setValue(response.title);
        this.bankTransferMethodSelect.setValue(
          response.bankTransferMethod.toString()
        );
        this.defaultPaymentMethodSelect.setValue(
          response.defaultPaymentMethod.toString()
        );
        this.bankCoverFeeCurrencySelect.setValue(response.bankCoverFeeCurrency);
        this.currency =
          response.defaultPaymentMethod === PaymentMethod.Bank &&
            response.bankTransferMethod === BankTransferMethod.CaxtonAutomated
            ? response.beneficiaryDetails?.currency
            : response.bankCoverFeeCurrency;
        this.bankFeeCoverData.push(...response.bankCoverFees);

        if (response.beneficiaryDetails) {
          this.paymentInfoForm.patchValue({
            iban: response.beneficiaryDetails.iban,
            sortCode: response.beneficiaryDetails.sortCode,
            swift: response.beneficiaryDetails.swift,
            accountNumber: response.beneficiaryDetails.accountNumber,
          });

          this.countries = response.countries.map((x) => ({
            value: x.code,
            text: x.name,
          }));
          this.countriesList = response.countries;
          this.bankRequirements = response.bankRequirements;

          this.changeDetector.detectChanges();

          this.countrySelect.setValue(response.beneficiaryDetails.country);
          this.currencySelect.setValue(response.beneficiaryDetails.currency);
        }

        if (
          response.bankTransferMethod === BankTransferMethod.CaxtonAutomated &&
          response.defaultPaymentMethod == PaymentMethod.Card
        ) {
          this.paymentInfoForm
            .get("caxtonWalletName")
            .setValidators([Validators.required]);
        }

        this.formInitialized = true;
        this.subscribeCountryValueChanges();
        this.subscribePaymentMethodValueChanges();
        this.subscribeBankTransferMethodValueChanges();
        this.subscribeBankCoverFeeCurrencyValueChanges();
        this.subscribeCurrencyValueChanges();
        this.initializeAvailableWallets();

        if (this.cardsActivated) {
          this.getWalletBalances();
        }
      },
      error: () => {
        this.alertService.showWarningAlert("Failed to get patient payment info. Please try again or contact an administrator");
      },
    });
  }

  subscribeCurrencyValueChanges() {
    this.paymentInfoForm.get("currency").valueChanges.subscribe((value) => {
      this.bankCoverFeeCurrencySelect.setValue(value);

      this.paymentService
        .getBankRequirements(this.trialId, this.countrySelect.selectedValue, value)
        .subscribe((response) => {
          Object.assign(this.bankRequirements, response);

          this.setValidation("sortCode", response.usesSortCode, [
            Validators.required,
            SortCodeValidator()
          ]);
          this.setValidation("swift", response.usesSwift, [
            Validators.required,
            Validators.pattern(
              /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/
            )
          ]);
          this.setValidation(
            "accountNumber",
            response.usesSortCode || response.usesSwift,
            [Validators.required]
          );
          this.setValidation("iban", response.usesIban, [
            Validators.required,
            Validators.pattern(
              IbanRegex.all()[this.countrySelect.selectedValue]?.regex
            )
          ]);

          this.changeDetector.detectChanges();
        });
    });
  }

  subscribeBankCoverFeeCurrencyValueChanges() {
    this.paymentInfoForm
      .get("bankCoverFeeCurrency")
      .valueChanges.subscribe((value) => {
        this.currency = value;
      });
  }

  initiateCountries() {
    if (!this.trialCaxtonEnabled) {
      this.alertService.showWarningAlert("Caxton is not enabled for trial of this patient.");
      return;
    }

    this.paymentService.getCountries(this.trialId).subscribe({
      next: (response) => {
        this.countries = response.map((x) => ({ value: x.code, text: x.name }));
        this.countriesList = response;
      },
      error: () => {
        this.alertService.showWarningAlert("Failed to get countries. Please try again or contact an administrator");
      },
    });
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({
      value: x.cc,
      text: `${x.name} (${x.cc})`,
    }));
  }

  initiateDefaultPaymentMethods() {
    this.defaultPaymentMethods.push({
      value: PaymentMethod.Bank.toString(),
      text: PaymentMethodString.Values[PaymentMethod.Bank],
    });
    this.defaultPaymentMethods.push({
      value: PaymentMethod.Card.toString(),
      text: PaymentMethodString.Values[PaymentMethod.Card],
    });
  }

  initiateBankTransferMethod() {
    this.bankTransferMethods.push({
      value: BankTransferMethod.Manual.toString(),
      text: BankTransferMethodString.Values[BankTransferMethod.Manual],
    });
    this.bankTransferMethods.push({
      value: BankTransferMethod.CaxtonAutomated.toString(),
      text: BankTransferMethodString.Values[BankTransferMethod.CaxtonAutomated],
    });
  }

  initiatePaymentInfoForm() {
    this.paymentInfoForm = new UntypedFormGroup({
      defaultPaymentMethod: new UntypedFormControl(""),
      bankTransferMethod: new UntypedFormControl(""),
      caxtonWalletName: new UntypedFormControl(""),
      caxtonWalletId: new UntypedFormControl({ value: "", disabled: true }),
      beneficiaryId: new UntypedFormControl({ value: "", disabled: true }),
      country: new UntypedFormControl(""),
      currency: new UntypedFormControl(""),
      iban: new UntypedFormControl({ value: "", disabled: true }),
      sortCode: new UntypedFormControl({ value: "", disabled: true }),
      accountNumber: new UntypedFormControl({ value: "", disabled: true }),
      swift: new UntypedFormControl({ value: "", disabled: true }),
      title: new UntypedFormControl(""),
      firstName: new UntypedFormControl({ value: "", disabled: true }),
      lastName: new UntypedFormControl({ value: "", disabled: true }),
      initialBankCoverFee: new UntypedFormControl(""),
      bankCoverFeeCurrency: new UntypedFormControl(""),
      accountOwnerAddress: new UntypedFormControl({ value: "", disabled: true }),
    });
  }

  initializeGrantCaxtonOnlineAccessForm() {
    this.caxtonOnlineAccessForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      gender: new UntypedFormControl("", [Validators.required]),
      dateOfBirth: new UntypedFormControl(""),
    });
  }

  loadGrantCaxtonOnlineAccessForm() {
    this.caxtonOnlineAccessForm.patchValue({ gender: Gender.Other });
    if (this.patientBasicInfo) {
      this.caxtonOnlineAccessForm.patchValue({
        email: this.patientBasicInfo.email,
        firstName: this.patientBasicInfo.firstName,
        lastName: this.patientBasicInfo.lastName,
      });
    }
    this.genderSelect.setValue(Gender.Other);
    this.dob.nativeElement.value = this.defaultDOB;
  }

  loadGrantCaxtonOnlineAccessFormDates() {
    this.caxtonOnlineAccessForm.patchValue({
      dateOfBirth: this.dob.nativeElement.value,
    });
  }

  private subscribePaymentMethodValueChanges() {
    this.paymentInfoForm
      .get("defaultPaymentMethod")
      .valueChanges.subscribe((value) => {
        this.handlePaymentChange(
          value as PaymentMethod,
          this.paymentInfoForm.get("bankTransferMethod").value as BankTransferMethod
        );
      });
  }

  private subscribeBankTransferMethodValueChanges() {
    this.paymentInfoForm
      .get("bankTransferMethod")
      .valueChanges.subscribe((value) => {
        this.handlePaymentChange(
          this.paymentInfoForm.get("defaultPaymentMethod").value as PaymentMethod,
          value as BankTransferMethod
        );
      });
  }

  private handlePaymentChange(
    paymentMethod: PaymentMethod,
    bankTransferMethod: BankTransferMethod
  ) {
    if (paymentMethod.toString() === PaymentMethod.Card.toString() && this.availableWalletsList.length === 0) {
      this.getAvailableWallets();
    }

    if (
      paymentMethod.toString() === PaymentMethod.Bank.toString() &&
      bankTransferMethod.toString() ===
      BankTransferMethod.CaxtonAutomated.toString()
    ) {
      this.invalidForm =
        this.paymentInfoForm.get("beneficiaryId").value === null;

      if (this.countries.length === 0) {
        this.initiateCountries();
      }

      this.bankCoverFeeCurrencySelect.setValue(
        this.paymentInfoForm.get("currency").value
      );

      this.paymentInfoForm.get("title").setValidators(Validators.required);
      this.paymentInfoForm.get("firstName").setValidators([Validators.required, Validators.pattern('^[A-Za-z]+$')]);
      this.paymentInfoForm.get("lastName").setValidators([Validators.required, Validators.pattern('^[A-Za-z]+$')]);
    } else {
      this.paymentInfoForm.get("title").clearValidators();
      this.paymentInfoForm.get("firstName").clearValidators();
      this.paymentInfoForm.get("lastName").clearValidators();

      this.paymentInfoForm.get("title").setErrors(null);
      this.paymentInfoForm.get("firstName").setErrors(null);
      this.paymentInfoForm.get("lastName").setErrors(null);
      this.invalidForm = false;
    }

    if (paymentMethod.toString() === PaymentMethod.Card.toString() &&
      bankTransferMethod.toString() === BankTransferMethod.CaxtonAutomated.toString()) {
      this.paymentInfoForm.get("caxtonWalletName").setValidators(Validators.required);
    } else {
      this.paymentInfoForm.get("caxtonWalletName").clearValidators();
      this.paymentInfoForm.get("caxtonWalletName").setErrors(null);
    }
  }

  private subscribeCountryValueChanges() {
    this.paymentInfoForm.get("country").valueChanges.subscribe((value) => {
      let currency = this.countriesList.find(
        (x) => x.code === value
      )?.currencyCode;
      this.currencySelect.setValue(currency);
      this.currency = currency;
    });
  }

  setValidation(
    controlName: string,
    condition: boolean,
    validators: ValidatorFn[]
  ) {
    if (condition) {
      this.paymentInfoForm.get(controlName).setValidators(validators);
    } else {
      this.paymentInfoForm.get(controlName).clearValidators();
      this.paymentInfoForm.get(controlName).setErrors(null);
      this.paymentInfoForm.get(controlName).setValue("");
    }

    this.paymentInfoForm.get(controlName).updateValueAndValidity();
  }

  updatePatient() {
    this.isFormProcessing = true;

    let request: PatientPaymentInfoUpdateRequest =
      this.paymentInfoForm.getRawValue() as PatientPaymentInfoUpdateRequest;
    request.bankCoverFees = [...this.bankFeeCoverData];
    request.patientTrialId = this.patientId;

    this.setCaxtonData(request);

    this.patientService.updatePatientPaymentInfo(request).subscribe({
      next: (result) => {
        this.isFormProcessing = false;
        this.cancel();
        this.paymentInfoForm.get("beneficiaryId").setValue(result.caxtonBeneficiaryId);

        if (this.currentBeneficiaryId) {
          this.previousBeneficiaryIds.push(this.currentBeneficiaryId);
        }

        this.currentBeneficiaryId = result.caxtonBeneficiaryId;
        this.alertService.showSuccessAlert("Patient updated successfully");
      },
      error: (error) => {
        this.isFormProcessing = false;
        this.alertService.showWarningAlert(`${error.error.title}`);
      },
    });
  }

  validateName(): boolean {
    let allowedCharactersRegex: RegExp = /^[a-zA-Z ]+$/;
    let validFirstName = allowedCharactersRegex.test(
      this.paymentInfoForm.get("firstName").value
    );
    let validLastName = allowedCharactersRegex.test(
      this.paymentInfoForm.get("lastName").value
    );

    if (!validFirstName || !validLastName) {
      this.alertService.showWarningAlert("First Name and Last Name fields can only contain alphabetical characters");
    }

    return validFirstName && validLastName;
  }

  setCaxtonData(request: PatientPaymentInfoUpdateRequest) {
    let iban = this.paymentInfoForm.get("iban").value;
    let sortCode = this.paymentInfoForm.get("sortCode").value;
    let swift = this.paymentInfoForm.get("swift").value;
    let accountNumber = this.paymentInfoForm.get("accountNumber").value;

    if (iban?.length ||
      sortCode?.length ||
      swift?.length ||
      accountNumber?.length) {
      request.beneficiaryDetails = {
        country: this.paymentInfoForm.get("country").value,
        currency: this.paymentInfoForm.get("currency").value,
        iban: iban,
        sortCode: sortCode,
        accountNumber: accountNumber,
        swift: swift,
      };

      if (request.defaultPaymentMethod.toString() === PaymentMethod.Bank.toString() &&
        request.bankTransferMethod.toString() === BankTransferMethod.CaxtonAutomated.toString()) {
        request.bankCoverFeeCurrency = request.beneficiaryDetails.currency;
      }
    }
  }

  cancel() {
    this.updateBeneficiary = false;
    this.paymentInfoForm.get("beneficiaryId").setValue(this.currentBeneficiaryId);

    this.paymentInfoForm.get("firstName").disable();
    this.paymentInfoForm.get("lastName").disable();
    this.paymentInfoForm.get("iban").disable();
    this.paymentInfoForm.get("sortCode").disable();
    this.paymentInfoForm.get("accountNumber").disable();
    this.paymentInfoForm.get("swift").disable();
    this.paymentInfoForm.get("accountOwnerAddress").disable();

    if (this.paymentInfoForm.get("defaultPaymentMethod").value === PaymentMethod.Bank.toString() &&
      this.paymentInfoForm.get("bankTransferMethod").value === BankTransferMethod.CaxtonAutomated.toString()) {
      this.invalidForm = this.paymentInfoForm.get("beneficiaryId").value === null;
    }
  }

  editBeneficiary() {
    this.currentBeneficiaryId = this.paymentInfoForm.get("beneficiaryId").value;
    this.paymentInfoForm.get("beneficiaryId").setValue("");

    this.paymentInfoForm.get("firstName").enable();
    this.paymentInfoForm.get("lastName").enable();
    this.paymentInfoForm.get("iban").enable();
    this.paymentInfoForm.get("sortCode").enable();
    this.paymentInfoForm.get("accountNumber").enable();
    this.paymentInfoForm.get("swift").enable();
    this.paymentInfoForm.get("accountOwnerAddress").enable();

    this.paymentInfoForm.get("title").setValidators(Validators.required);
    this.paymentInfoForm.get("firstName").setValidators([Validators.required, Validators.pattern('^[A-Za-z]+$')]);
    this.paymentInfoForm.get("lastName").setValidators([Validators.required, Validators.pattern('^[A-Za-z]+$')]);

    this.setValidation("sortCode", this.bankRequirements.usesSortCode, [
      Validators.required,
      SortCodeValidator()
    ]);
    this.setValidation("swift", this.bankRequirements.usesSwift, [
      Validators.required,
      Validators.pattern(
        /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/
      )
    ]);
    this.setValidation(
      "accountNumber",
      this.bankRequirements.usesSortCode || this.bankRequirements.usesSwift,
      [Validators.required]
    );
    this.setValidation("iban", this.bankRequirements.usesIban, [
      Validators.required,
      Validators.pattern(
        IbanRegex.all()[this.countrySelect.selectedValue]?.regex
      )
    ]);

    this.paymentInfoForm.get("title").updateValueAndValidity();
    this.updateBeneficiary = true;
    this.invalidForm = false;
  }

  togglePreviousBeneficiaries() {
    this.showPreviousBeneficiaries = !this.showPreviousBeneficiaries;
  }

  loadBankCoverFeeComponent() {
    this.bankFeeCoverData.push({
      id: uuidv4().toString().replace("-", ""),
      thresholdValue: 0,
      feeValue: 0,
    });
  }

  populateBankCoverFeeData(data: BankCoverFee) {
    let componentData = this.bankFeeCoverData.find((d) => d.id === data.id);
    componentData.thresholdValue = data.thresholdValue;
    componentData.feeValue = data.feeValue;
  }

  removeBankFeeCoverComponent(index: string) {
    this.bankFeeCoverData = this.bankFeeCoverData.filter((e) => e.id !== index);
  }

  showWalletAssignmentPopup() {
    if (this.assignWalletSelect.selectedOption?.value) {
      this.confirmWalletAssignmentModal.show();
      return;
    }

    this.alertService.showWarningAlert("Please select a wallet.");
  }

  onConfirmWalletAssignment() {
    this.isAssignmentProcessing = true;
    let wallet = this.availableWalletsList.find(w => w.walletId == this.assignWalletSelect.selectedOption.value);

    let request: AssignAndMoveWalletRequest = {
      patientTrialId: this.patientId,
      walletId: wallet.walletId,
      walletName: wallet.walletName,
      walletAccountHolder: wallet.walletAccountHolder,
      walletIsOnChildSubsidiary: wallet.walletIsOnChildSubsidiary
    };

    this.patientService.assignAndMoveWallet(request).subscribe({
      next: (result) => {
        this.cardsActivated = false;
        this.caxtonOnlineAccessGranted = false;

        if (result.cardAlreadyActive) {
          this.isAssignmentProcessing = false;
          this.confirmWalletAssignmentModal.hide();
          this.cardAlreadyActiveModal.show();
          return;
        }

        this.paymentInfoForm.get("caxtonWalletName").setValue(result.walletName);
        this.paymentInfoForm.get("caxtonWalletId").setValue(result.walletId);
        this.paymentInfoForm.get("caxtonWalletName").disable();
        this.paymentInfoForm.markAsDirty();
        this.isAssignmentProcessing = false;

        this.confirmWalletAssignmentModal.hide();

        this.alertService.showSuccessAlert(`You have successfully assigned and moved ${result.walletName}`);
      },
      error: (error) => {
        this.alertService.showWarningAlert(
          error.error
            ? error.error.title
            : "Failed to move wallet. Please try again or contact support."
        );
        this.isAssignmentProcessing = false;
        LogHelper.log(error);
      },
    });
  }

  activateCards() {
    this.isCardActivationProcessing = true;
    let walletId = this.paymentInfoForm.get("caxtonWalletId").value;

    let activateCardRequest: ActivateCardsRequest = {
      patientTrialId: this.patientId,
      walletId: walletId
    };

    this.patientService
      .activateCards(this.patientId, activateCardRequest)
      .subscribe({
        next: (result) => {
          this.isCardActivationProcessing = false;
          this.cardsActivated = result.cardsActivatedSuccessfully;
          this.caxtonOnlineAccessGranted = result.cardsOnlineAccessGranted;

          if(this.cardsActivated) {
            this.getWalletBalances();
          }
        },
        error: (error) => {
          this.alertService.showWarningAlert(
            error.error
              ? error.error.title
              : "Unable to activate cards. Please try again."
          );
          this.isCardActivationProcessing = false;
          LogHelper.log(error);
        },
      });
  }

  viewCardPin() {
    this.isCardPinProcessing = true;
    let walletId = this.paymentInfoForm.get("caxtonWalletId").value;

    let viewCardPinRequest: GetCardPinRequest = {
      patientTrialId: this.patientId,
      walletId: walletId
    };

    this.patientService
      .getCardPin(viewCardPinRequest)
      .subscribe({
        next: (result) => {
          this.cardPinImageUrl = result.cardPinImageUrl;
          this.isCardPinProcessing = false;
          this.viewCardPinModal.show();
        },
        error: (error) => {
          this.alertService.showWarningAlert(
            error.error
              ? error.error.title
              : "Unable to retrieve card pin. Please try again."
          );
          this.isCardPinProcessing = false;
          LogHelper.log(error);
        },
      });
  }

  itemVisible(roles: string[]): boolean {
    return this.authService.hasAnyRole(roles.join(','));
  }

  showGrantCaxtonAccessPopup() {
    this.confirmGrantOnlineAccessToCaxtonModal.show();
  }

  onConfirmGrantAccess() {
    this.confirmGrantOnlineAccessToCaxtonModal.hide();
    this.loadGrantCaxtonOnlineAccessForm();
    this.grantOnlineAccessToCaxtonFormModal.show();
  }

  onGrantOnlineAccessToCaxtonFormSubmit() {
    this.isCaxtonAccessProcessing = true;
    this.loadGrantCaxtonOnlineAccessFormDates();

    let walletId = this.paymentInfoForm.get("caxtonWalletId").value;
    let walletName = this.paymentInfoForm.get("caxtonWalletName").value;

    let caxtonAssignCardRequest = this.caxtonOnlineAccessForm.value as CaxtonAssignCardRequest;
    let assignCardRequest: AssignCardRequest = {
      patientTrialId: this.patientId,
      walletId: walletId,
      caxtonRequest: caxtonAssignCardRequest,
    };

    this.patientService
      .grantOnlineAccess(this.patientId, assignCardRequest)
      .subscribe({
        next: (result) => {
          this.isCaxtonAccessProcessing = false;
          this.caxtonOnlineAccessGranted = result.onlineAccessGranted;
          this.grantOnlineAccessToCaxtonFormModal.hide();
          this.alertService.showSuccessAlert(`You have successfully granted online access to cards inside wallet ${walletName}`);
        },
        error: (error) => {
          this.alertService.showWarningAlert(
            error.error
              ? error.error.title
              : "Failed to grant online access to cards in this wallet. Please try again or contact support."
          );
          this.isCaxtonAccessProcessing = false;
          LogHelper.log(error);
        },
      });
  }

  onContinueMoveActiveCard() {
    this.isAssignmentProcessing = true;

    let request: AssignWalletOnParentRequest = {
      trialId: this.trialId,
      patientTrialId: this.patientId,
      caxtonWalletId: this.assignWalletSelect.selectedOption.value,
      caxtonWalletName: this.assignWalletSelect.selectedOption.text,
    };

    this.patientService.assignWalletOnParent(request).subscribe({
      next: (result) => {
        this.isAssignmentProcessing = false;
        this.cardsActivated = false;
        this.caxtonOnlineAccessGranted = false;

        this.paymentInfoForm.get("caxtonWalletName").setValue(result.walletName);
        this.paymentInfoForm.get("caxtonWalletId").setValue(result.walletId);
        this.paymentInfoForm.get("caxtonWalletName").disable();
        this.paymentInfoForm.markAsDirty();
        this.isAssignmentProcessing = false;

        this.cardAlreadyActiveModal.hide();

        this.alertService.showSuccessAlert(`You have successfully assigned ${result.walletName}`);
      },
      error: () => {
        this.alertService.showWarningAlert("There was a problem connecting to Caxton. Please retry and if the problem persists contact IT Support.");
        this.isAssignmentProcessing = false;
      },
    });
  }
}
