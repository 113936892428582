import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {TrialBookingSearch} from "../models/trial-booking-search.model";
import {TrialBookingConfigItem} from "../models/trial-booking-config-item.model";
import {TrialBookingImportItem} from "./interfaces/trial-booking-import-item.interface";
import {Observable} from "rxjs";
import {CreateUpdateBookingConfiguration} from "./interfaces/create-update-booking-configuration.interface";

@Injectable({
  providedIn: 'root'
})
export class TrialBookingService {
  private _endpoint = '/trialbookings';

  constructor(private _http: HttpClient) { }

  /**
   * Deletes a trial configuration
   * @param trialId
   * @param configId
   */
  deleteConfiguration(trialId: string, configId: string) {
    return this._http.delete(environment.apiUrl + this._endpoint + '/trial/' + trialId + '/configuration/' + configId);
  }

  /**
   * Updated a trial configuration
   * @param trialId
   * @param configId
   * @param dto
   */
  updateConfiguration(trialId: string, configId: string, dto: CreateUpdateBookingConfiguration) {
    return this._http.put(environment.apiUrl + this._endpoint + '/trial/' + trialId + '/configuration/' + configId, dto);
  }

  /**
   * Creates a new configuration for a trial
   * @param trialId
   * @param dto
   */
  createConfiguration(trialId: string, dto: CreateUpdateBookingConfiguration) {
    return this._http.post(environment.apiUrl + this._endpoint + '/trial/' + trialId + '/configuration', dto);
  }

  /**
   * Sends an unsent invites for a trial
   * @param trialId
   */
  sendUnsentInvitesForTrial(trialId: string) {
    return this._http.put(environment.apiUrl + this._endpoint + '/invite/' + trialId + '/unsent', {});
  }

  /**
   * Sends an invite to a patient
   * @param patientBookingId
   */
  sendInvite(patientBookingId: string) {
    return this._http.put(environment.apiUrl + this._endpoint + '/invite/' + patientBookingId, {});
  }

  /**
   * Start the import process
   * @param trialBookingConfigId
   * @param items
   */
  import(trialBookingConfigId: string, items: TrialBookingImportItem[]) {
    let body = {
      trialBookingConfigId: trialBookingConfigId,
      items: items
    }

    return this._http.post(environment.apiUrl + this._endpoint + '/import', body);
  }

  /**
   * Returns a list of booking configurations for a trial
   */
  getBookingConfigurationsForTrial(trialId: string) {
    return this._http.get(environment.apiUrl + this._endpoint + '/trial/' + trialId + '/configurations').pipe(map((rsp: any) => {
      return rsp.map(r => new TrialBookingConfigItem(r));
    }));
  }

  /**
   * Returns a list of of trials and config info
   */
  getTrialBookingConfigItems() {
    return this._http.get(environment.apiUrl + this._endpoint + '/trials').pipe(map((rsp: any) => {
      return rsp.map(r => new TrialBookingConfigItem(r));
    }));
  }

  /**
   * Gets the count of invites that are unsent for a trial
   * @param trialId
   */
  unsentInvitesCountForTrial(trialId: string): Observable<{ count: number }> {
    return this._http.get<{ count: number }>(environment.apiUrl + this._endpoint + '/unsent/' + trialId);
  }

  /**
   * Loads a paginated list of bookings for a trial
   * @param trialId
   * @param bookingConfigId
   * @param page
   */
  trialBookings(trialId: string, bookingConfigId: string, page: number = 1) {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.set('trialId', trialId);

    if (bookingConfigId !== null && bookingConfigId !== '') {
      params = params.set('bookingConfigId', bookingConfigId);
    }

    return this._http.get(environment.apiUrl + this._endpoint, {params: params}).pipe(map(rsp => {
      let results = new TrialBookingSearch().resultsFromResponse(rsp);
      results.loaded = true;

      return results;
    }));
  }
}
