import { Component, Input } from '@angular/core';

@Component({
  selector: '[appPatientFeedbackRow]',
  templateUrl: './patient-feedback-row.component.html',
  styleUrls: ['./patient-feedback-row.component.scss']
})
export class PatientFeedbackRowComponent {
  @Input('patientTrialId') patientTrialId: string;
  @Input('patientId') patientId: string;
  @Input('patientCode') patientCode: string;
  @Input('trialCode') trialCode: string;
  @Input('trialNickname') trialNickname: string;
  @Input('trialOpportunityNumber') trialOpportunityNumber: string;
  @Input('date') date: Date;
  @Input('stars') stars: number;
}
