import { AlertService } from './../../../shared/alert/alert.service';
import { SiteContactService } from './../../../core/services/site-contact.service';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreatePatientRegistrationRequest } from 'app/core/models/patient.model';
import { LogHelper } from 'app/core/helpers/log.helper';

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
  styleUrls: ['./patient-register.component.scss']
})
export class PatientRegisterComponent implements OnInit, AfterViewInit {
  patientRegistrationCode: string;
  siteContactId: string;
  patientRegistrationForm: UntypedFormGroup;
  processingRequest: boolean = false;
  @ViewChild('signature') public signaturePad: SignaturePadComponent;
  signatureFile: File;


  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 840,
    canvasHeight: 150
  };

  constructor(private readonly activatedRoute: ActivatedRoute, private siteContactService: SiteContactService, private alertService: AlertService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientRegistrationCode = params.patientRegistrationCode;
      this.siteContactId = params.siteContactId;
    })

    this.initPatientRegistrationForm();
  }

  ngAfterViewInit(): void {
    this.signaturePad.set('minWidth', 5);
    this.signaturePad.clear();
  }

  initPatientRegistrationForm() {
    this.patientRegistrationForm = new UntypedFormGroup({
      siteNumber: new UntypedFormControl(''),
      trialCode: new UntypedFormControl(''),
      investigatorName: new UntypedFormControl(''),
      patientName: new UntypedFormControl(''),
      patientNumber: new UntypedFormControl(''),
      yearOfBirth: new UntypedFormControl(),
      address: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      contactName: new UntypedFormControl(''),
      contactPhoneNumber: new UntypedFormControl(''),
      contactRelationship: new UntypedFormControl('')
    });
  }

  createPatientRegistration() {
    this.processingRequest = true;
    let request = this.patientRegistrationForm.value as CreatePatientRegistrationRequest;
    request.siteContactId = this.siteContactId;
    request.patientRegistrationCode = this.patientRegistrationCode;

    this.siteContactService.uploadFile(this.dataUrlToBlob(this.signaturePad.toDataURL()), this.siteContactId, this.patientRegistrationCode).subscribe({
      next: result => {
        request.signatureFilename = result.signatureFilename;
        this.uploadSignature(request);
      },
      error: error => {
        LogHelper.log(error);
        this.processingRequest = false;
        this.alertService.showWarningAlert("Failed to upload patient signature. Please try again or contact support.");
      }
    });
  }

  private uploadSignature(request: CreatePatientRegistrationRequest) {
    this.siteContactService.createPatientRegistration(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Patient registration successfully created");
        this.processingRequest = false;
        this.patientRegistrationForm.reset();
        this.signaturePad.clear();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert("Failed to create patient registration. Please try again or contact support.");
        this.processingRequest = false;
      }
    });
  }

  private dataUrlToBlob(dataUrl: string) {
    var byteString = atob(dataUrl.split(',')[1]);

    var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]

    var ab = new ArrayBuffer(byteString.length);

    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
}
