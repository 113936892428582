import { Pipe, PipeTransform } from '@angular/core';
import { enumToText } from '../helpers/enum-to-text.function';

@Pipe({
  name: 'enumToText',
})
export class EnumToTextPipe implements PipeTransform {
  transform(value: number, enumType: any): string {
    return enumToText(enumType, value);
  }
}
