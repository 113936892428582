import { AlertService } from '../../../../../shared/alert/alert.service';
import { TrialService } from 'app/core/services/trial.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExpenseRuleListItemViewModel, SetDefaultExpenseCurrencyRequest } from '../../trial-policy.model';
import { LogHelper } from 'app/core/helpers/log.helper';
import { Currencies } from 'app/core/constants/currency';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { CreateExpenseRuleModalComponent } from '../create-expense-rule-modal/create-expense-rule-modal.component';
import { UpdateExpenseRuleModalComponent } from '../update-expense-rule-modal/update-expense-rule-modal.component';

@Component({
  selector: 'app-trial-policy-expense-rules',
  templateUrl: './trial-policy-expense-rules.component.html',
  styleUrls: ['./trial-policy-expense-rules.component.scss']
})
export class TrialPolicyExpenseRulesComponent implements OnInit {
  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @Input() policyId: string;
  @Input() trialId: string;
  expenseRules: ExpenseRuleListItemViewModel[] = [];
  saveProcessing: boolean = false;

  currencies: { value: string, text: string }[] = [];

  @ViewChild("createExpenseRuleModal") createExpenseRuleModal: CreateExpenseRuleModalComponent;
  @ViewChild("updateExpenseRuleModal") updateExpenseRuleModal: UpdateExpenseRuleModalComponent;

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.getExpenseRules();
    this.initiateCurrencies();
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  getExpenseRules() {
    this.trialService.getPolicyExpenseRules(this.policyId).subscribe({
      next: response => {
        this.expenseRules = response.rules;
        this.currencySelect.setValue(response.defaultPolicyCurrency);
      },
      error: err => {
        this.alertService.showWarningAlert("Failed to get policy expense rules. Please try again or contact support.");
        LogHelper.log(err);
      }
    })
  }

  showCreateExpenseRuleModal() {
    this.createExpenseRuleModal.defaultCurrency = this.currencySelect.selectedValue;
    this.createExpenseRuleModal.show();
  }

  saveDefaultCurrency() {
    this.saveProcessing = true;
    let request: SetDefaultExpenseCurrencyRequest = {
      policyId: this.policyId,
      currency: this.currencySelect.selectedValue
    }

    this.trialService.setPolicyExpenseRuleCurrency(request).subscribe({
      next: () => {
        this.saveProcessing = false;
        this.alertService.showSuccessAlert("Default currency successfully changed.");
      },
      error: err => {
        this.saveProcessing = false;
        this.alertService.showWarningAlert("Failed to change default currency. Please try again or contact support.");
        LogHelper.log(err);
      }
    })
  }

  openUpdateRuleModal(rule: ExpenseRuleListItemViewModel) {
    this.updateExpenseRuleModal.rule = rule;
    this.updateExpenseRuleModal.policyId = this.policyId;
    this.updateExpenseRuleModal.show();
  }
}
