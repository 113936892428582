import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";


@NgModule({
  declarations: [SettingsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule
    ]
})
export class SettingModule { }
