import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PatientService } from "../../../../core/services/patient.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientNote } from "../../../../core/models/patient-note.model";

@Component({
  selector: 'app-patient-detail-notes',
  templateUrl: './patient-detail-notes.component.html'
})
export class PatientDetailNotesComponent implements OnInit {
  @Input() patientId: string;

  newNoteForm: UntypedFormGroup;
  isFormProcessing = false
  notes: PatientNote[] = [];

  constructor(private readonly alertService: AlertService, private readonly patientService: PatientService) { }

  ngOnInit() {
    this.newNoteForm = new UntypedFormGroup({
      text: new UntypedFormControl('', Validators.required)
    });

    this.loadNotes();
  }

  loadNotes() {
    this.patientService.retrieveNotes(this.patientId).subscribe({
      next: notes => {
        this.notes = notes;
      },
      error: () => {
        this.alertService.showWarningAlert('Unable to load patient notes!');
      }
    });
  }

  onFormSubmit() {
    if (this.newNoteForm.valid) {
      this.isFormProcessing = true;
      this.patientService.addPatientNote(this.patientId, this.newNoteForm.get('text').value).subscribe({
        next: () => {
          this.newNoteForm.reset();
          this.isFormProcessing = false;
          this.loadNotes();
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to add note!');
          this.isFormProcessing = false;
        }
      });
    }
  }
}
