import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { AddAdminUserComponent } from './admin-users/add-admin-user/add-admin-user.component';
import { EditAdminUserComponent } from './admin-users/edit-admin-user/edit-admin-user.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { AdminChangeLogComponent } from './admin-change-log/admin-change-log.component';
import { AdminSiteContactsComponent } from './admin-site-contacts/admin-site-contacts.component';
import { AddSiteContactComponent } from './admin-site-contacts/add-site-contact/add-site-contact.component';
import { EditSiteContactComponent } from './admin-site-contacts/edit-site-contact/edit-site-contact.component';
import { ManageUserSitesComponent } from './admin-site-contacts/manage-user-sites/manage-user-sites.component';
import { SiteContactChangeLogComponent } from './site-contact-change-log/site-contact-change-log.component';
import { ManageAdminTrialsComponent } from './admin-users/manage-admin-trials/manage-admin-trials.component';

@NgModule({
    declarations: [
      AdminUsersComponent,
      AddAdminUserComponent,
      EditAdminUserComponent,
      AdminViewComponent,
      AdminChangeLogComponent,
      AdminSiteContactsComponent,
      AddSiteContactComponent,
      EditSiteContactComponent,
      ManageUserSitesComponent,
      SiteContactChangeLogComponent,
      ManageAdminTrialsComponent,
    ],
    exports: [
        ManageUserSitesComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ReactiveFormsModule
    ]
})
export class AdminModule { }
