import { environment } from "../../../environments/environment";
import { LogLevel } from "../log-level.enum";

export class LogHelper {
  public static infoLevels = [LogLevel.Info, LogLevel.Warn, LogLevel.Error];
  public static warnLevels = [LogLevel.Warn, LogLevel.Error];
  public static errorLevels = [LogLevel.Error];

  public static info(message: string, ...optionalParams: any[]): void {
    if (LogHelper.infoLevels.includes(environment.logLevel))
      console.log(`[Information]: ${message}`, ...optionalParams);
  }

  public static error(message: string, ...optionalParams: any[]): void {
    if (LogHelper.warnLevels.includes(environment.logLevel))
      console.error(`[Error]: ${message}`, ...optionalParams);
  }

  public static warning(message: string, ...optionalParams: any[]): void {
    if (LogHelper.errorLevels.includes(environment.logLevel))
      console.warn(`[Warning]: ${message}`, ...optionalParams);
  }

  public static log(message: any) {
    if (environment.displayConsoleLogs) {
      console.log(message);
    }
  }
}
