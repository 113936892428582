<div class="details-view">

  <div class="details-view-head">
    <div class="details-view-row-top">
      <div class="back-btn">
        <a class="btn btn-circle" routerLink="/patient/{{patientTrialId}}"><i aria-hidden="true"
            class="icon-left"></i></a>
      </div>
      <div class="details-view-patient-details-wrap">

        <div class="details-view-visit-info">
          <span class="label">{{ visit.title }}</span>
          <span class="details-view-visit-date-time">
            <span class="field-value details-view-visit-date">{{ visit.date | date: 'd MMM yyyy' }}</span>
            <span class="field-value details-view-visit-time">{{ visit.timeMinutes !== null ?
              timeMinutesToStr(visit.timeMinutes) : '' }}</span>
          </span>
        </div>

        <div class="details-view-visit-venue">
          <span class="label">{{ visit.typeDescription }}</span>
          <div *ngIf="visit.type != 'Home'">
            <span class="field-value details-view-visit-venue-name" *ngIf="visit.siteName">{{ visit.siteName }}</span>
            <span class="field-value details-view-visit-venue-address">{{ visit.siteAddress }}</span>
          </div>
        </div>

      </div>
      <div class="btn-wrap flex-row">
        <a (click)="deleteVisitModal.show()"
          *ngIf="authService.hasPermission(Permissions.PatientVisitsWrite) && !visit.hasExpenses"
          class="btn btn-outline btn-width-auto">Delete</a>
        <a class="btn btn-secondary btn-width-auto" (click)="onHandleEditVisit()">Edit</a>
      </div>
    </div>

    <div class="details-view-row-desc">
      <div class="text-labels">
        <span class="label" *ngIf="visit.type === 'OnSite'"><strong>{{visit.type}} Travel Requested?</strong> {{
          stringHelper.addSpaces(visit.travelRequested) }}</span>
        <span class="label" *ngIf="visit.type === 'OnSite'"><strong>Caregiver Travelling?</strong> {{
          stringHelper.addSpaces(visit.caregiverTravelling) }}</span>
        <span class="label"><strong>Attendance</strong> {{ stringHelper.addSpaces(visit.attendance) }}</span>
      </div>

      <p>{{ visit.description }}</p>

      <div *ngIf="visit.notes">
        <p><br>Notes: {{ visit.notes }}</p>
      </div>
    </div>
  </div>

  <div class="tabs-wrap">
    <div class="mobile-tabs">
      <select id="nav-tabs-select" class="selectpicker">
        <option value="#patient-visits-anchor">Visits</option>
        <option value="#patient-expenses-anchor">Expenses</option>
        <option value="#patient-additional-details-anchor">Additional Details</option>
        <option value="#patient-notes-anchor">Notes</option>
        <option value="#patient-activity-anchor">Activity</option>
        <option value="#patient-change-log-anchor" *ngIf="authService.hasPermission(Permissions.PatientChangeLogRead)">
          Change Log</option>
      </select>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" fragment="visits" routerLink="/patient/{{ patientTrialId }}">Visits</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" fragment="expenses" routerLink="/patient/{{ patientTrialId }}">Expenses</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" fragment="additional-details" routerLink="/patient/{{ patientTrialId }}">Additional
          Details</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" fragment="notes" routerLink="/patient/{{ patientTrialId }}">Notes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" fragment="activity" routerLink="/patient/{{ patientTrialId }}">Activity</a>
      </li>
      <li class="nav-item">
        <a *ngIf="authService.hasPermission(Permissions.PatientChangeLogRead)" class="nav-link" fragment="change-log"
          routerLink="/patient/{{ patientTrialId }}">Change Log</a>
      </li>
    </ul>
  </div>

  <div class="tab-content" id="details-view-tab-content">

    <div class="content-panel patient-trip-details">

      <div id="red-bar-container">
        <div class="patient-trip-red-bar" #redDetailsBar>
          <table aria-hidden="true">
            <tr>
              <td>
                <span class="label">Patient Name</span>
                <span class="text">{{ stringHelper.limit(patient.getFullName(), 30) }}</span>
              </td>
              <td>
                <span class="label">Patient ID</span>
                <span class="text">{{ patient.shortId }} <a
                    (click)="onCopiedToClipboard('Patient ID copied to your clipboard')"
                    [cdkCopyToClipboard]="patient.shortId" title="Copy Patient ID to clipboard"><img alt="Copy"
                      src="/assets/img/icon-copy.svg"></a></span>
              </td>
              <td>
                <span class="label">Visit ID</span>
                <span class="text">{{ visit.shortId }} <a
                    (click)="onCopiedToClipboard('Visit ID copied to your clipboard')"
                    title="Copy Visit ID to clipboard" [cdkCopyToClipboard]="visit.shortId"><img
                      src="/assets/img/icon-copy.svg" alt="Copy"></a></span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="patient-trip-head">
        <a class="btn btn-outline btn-slim btn-auto-width" (click)="addEditTripModal.showAddTripModal(visit.id)">+ Add Trip</a>

        <span class="badge badge-success" *ngIf="visit.trips.length > 0">Accepted</span>
      </div>

      <div cdkDropList>
        <div class="patient-visit-trip" *ngFor="let trip of visit.trips; let i = index;" cdkDrag
          (cdkDragDropped)="onDrop($event)" (cdkDragStarted)="onDrag(i)">
          <div class="patient-visit-trip-actions desktop">
            <a (click)="onDeleteTrip(trip)" class="btn btn-outline btn-slim btn-auto-width"
              *ngIf="authService.hasPermission(Permissions.PatientDelete) && (trip.rideHealthStatus === 'NotApplicable' || trip.rideHealthStatus === '')">Delete</a>
            <a class="btn btn-secondary btn-slim btn-auto-width" (click)="addEditTripModal.showEditTripModal(trip.id)">Edit</a>
          </div>

          <h3 cdkDragHandle><i class="icon-elipsis" aria-hidden="true"></i> {{ trip.type }} <span class="trip-status-badge badge badge-status-{{ trip.bookingStatus.toLowerCase() }}">{{ getTripBookingStatusDescription(trip.bookingStatus) }}</span></h3>

          <div class="inner">
            <table aria-hidden="true">
              <tr role="row">
                <td class="departure-date-time-cell" role="columnheader">
                  <span class="label">{{ getDepartureLabel(trip.type) }}</span>
                  <span class="date-time">
                    <span class="field-value date">{{ trip.departureDate | date: 'd MMM yyyy' }}</span>
                    <span class="field-value time" *ngIf="trip.departureTimeMinutes !== null">{{ trip.departureDate | date: 'h:mm aaa' }}</span>
                  </span>
                </td>
                <td class="departure-location-cell" role="columnheader">
                  <span *ngIf="trip.type !== 'Accommodation'">
                    <span class="label">From</span>
                    <span class="field-value location-from">{{ stringHelper.limit(trip.departureLocation, 30) }}</span>
                  </span>
                </td>
                <td class="arrival-date-time-cell" role="columnheader">
                  <div *ngIf="trip.arrivalDate !== null">
                    <span class="label">{{ getArrivalLabel(trip.type) }}</span>
                    <span class="date-time">
                      <span class="field-value date">{{ trip.arrivalDate | date: 'd MMM yyyy' }}</span>
                      <span class="field-value time" *ngIf="trip.arrivalTimeMinutes !== null">{{ trip.arrivalDate | date: 'h:mm aaa' }}</span>
                    </span>
                  </div>
                </td>
                <td class="arrival-location-cell" role="columnheader">
                  <span *ngIf="trip.type !== 'Accommodation'">
                    <span class="label">To</span>
                    <span class="location-from">{{ stringHelper.limit(trip.arrivalLocation, 30) }}</span>
                  </span>
                </td>
              </tr>
            </table>

            <table *ngIf="trip.rideHealthStatus !== 'NotApplicable'" aria-hidden="true" class="trip-state-table">
              <tr>
                <td class="departure-date-time-cell">
                  <span class="label">Trip State</span>
                  <span class="date-time">
                    <span class="field-value date">{{ trip.rideHealthState }}</span>
                  </span>
                </td>
                <td class="departure-location-cell">
                  <span *ngIf="trip.type !== 'Accommodation'">
                    <span class="label">Latest Status Received</span>
                    <span class="field-value location-from">{{ trip.rideHealthStatus }}</span>
                  </span>
                </td>
                <td class="arrival-date-time-cell">
                  <div *ngIf="trip.arrivalDate !== null">
                    <span class="label">Latest Status Date &amp; Time</span>
                    <span class="date-time">
                      <span class="field-value date">{{ trip.rideHealtStatusUpdatedAt | date: 'd MMM yyyy' }}</span>
                      <span class="field-value time">{{ trip.rideHealtStatusUpdatedAt | date: 'h:mm aaa' }}</span>
                    </span>
                  </div>
                </td>
                <td class="arrival-location-cell">
                </td>
              </tr>
            </table>

            <table *ngIf="trip.type == 'Accommodation'" aria-hidden="true" class="location-details-table">
              <tr>
                <td class="accommodation-location-cell">
                  <span class="label">Location</span>
                  <span class="field-value location-from">{{ trip.accommodationLocation }}</span>
                </td>
                <td class="accommodation-details-cell">
                  <span class="label">Details</span>
                  <span class="location-from">{{ trip.notes }}</span>
                </td>
              </tr>
            </table>

            <div class="pdf-generating" *ngIf="trip.pdfGenerating">
              <div class="spinner-border spinner-border-sm text-secondary" role="status"></div>
              <span class="generating-text">Generating PDF, please wait...</span>
            </div>

            <ul class="attachments" *ngIf="!trip.pdfGenerating">
              <li *ngIf="trip.ticketId"><i class="icon-paperclip" aria-hidden="true"></i> <a href="{{ trip.ticketUrl }}"
                  target="_blank" rel="noopener">{{ trip.ticketOriginalFilename }}</a>
              </li>
            </ul>

            <p class="added-by-user">Added by <strong>{{ trip.addedBy }}</strong> on
              <strong>{{ trip.addedByDate | date: 'd MMM yyyy' }}</strong> at
              <strong>{{ trip.addedByDate | date: 'h:mm aaa' }}</strong>
            </p>

            <div class="patient-visit-trip-actions mobile">
              <a class="btn btn-secondary btn-slim btn-auto-width" (click)="addEditTripModal.showEditTripModal(trip.id)">Edit</a>
              <a (click)="onDeleteTrip(trip)" class="btn btn-outline btn-slim btn-auto-width"
                *ngIf="authService.hasPermission(Permissions.PatientDelete)">Delete</a>
            </div>
          </div>

        </div>
      </div>

      <div class="no-results inside" *ngIf="visit.trips.length == 0">
        <div class="inner">
          <div class="content">
            <h2>No Trips</h2>
            <p>There are no trips for this patient.</p>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<!-- Start: Delete visit modal -->
<app-modal #deleteVisitModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Visit?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this visit?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteVisitModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onDeleteVisitConfirmed()" class="btn btn-primary">
          <span *ngIf="isDeleteVisitProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </span>
          <span *ngIf="!isDeleteVisitProcessing">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete visit modal -->

<!-- Start: Delete trip modal -->
<app-modal #deleteTripModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Delete Trip?</h2>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete this this?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="deleteTripModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmDeleteTrip()" class="btn btn-primary" [ngClass]="{'disabled': isDeleteProcessing}">
            <span *ngIf="isDeleteProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isDeleteProcessing">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete trip modal -->

<app-edit-visit-modal #editVisitModal></app-edit-visit-modal>

<app-add-edit-trip-modal #addEditTripModal (tripUpdated)="loadVisit()" (tripAdded)="onHandleTripAdded($event)" [muvEnabled]="visit.muvEnabled"></app-add-edit-trip-modal>


