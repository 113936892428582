import { NavigationState } from "../state/navigation.state";
import { NavigationActionType, UpdateLastRouteAction } from "../actions/navigation.action";

const initialState: NavigationState = {
  lastRoute: null
};

export function navigationReducer(state = initialState, action: UpdateLastRouteAction): NavigationState {
  if (action.type !== NavigationActionType.UPDATE_LAST_ROUTE)
    return state;

  return {
    ...state,
    lastRoute: action.payload
  };
}
