<app-modal #modal class="create-mileage-rate-modal">
  <div class="modal-header">
    <h2 class="modal-title">Update Engine Size Rate</h2>
  </div>

  <div class="modal-body">
    <form [formGroup]="ruleForm">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="engineSize">Engine Size</label>
          <input type="text" class="form-control w-100" id="engineSize" formControlName="engineSize" name="engineSize">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="currency">Distance Unit</label>
          <app-dropdown-input #distanceUnitSelect id="distanceUnit" [options]="distanceUnits" [parentForm]="ruleForm"
            name="distanceUnit" placeholder="Select distance unit">
          </app-dropdown-input>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="currency">Currency</label>
          <app-dropdown-input #currencySelect id="currency" [options]="currencies" [parentForm]="ruleForm"
            name="currency" placeholder="Select currency">
          </app-dropdown-input>
        </div>
      </div>

      <ng-container *ngFor="let form of distanceLimitForms; let i = index">
        <form [formGroup]="form">
          <div class="row">
            <div class="form-group col-md-3">
              <label for="distanceFrom">From (distance)</label>
              <input class="form-control w-100" type="number" id="distanceFrom" formControlName="distanceFrom"
                name="distanceFrom">
              <span class="error-text" *ngIf="form.getError('rangeError') || form.get('distanceFrom').getError('rangeError')">Value has to
                be greater than previous To distance and less than this To distance </span>
            </div>
            <div class="form-group col-md-3">
              <label for="distanceTo">To (distance)</label>
              <input class="form-control w-100" type="number" id="distanceTo" formControlName="distanceTo"
                name="distanceTo">
              <span class="error-text" *ngIf="form.getError('rangeError') || form.get('distanceTo').getError('rangeError')">Value has to
                be greater than this From distance and less than next To distance </span>
            </div>
            <div class="form-group col-md-3">
              <label for="amountPerDistance">Amount per d</label>
              <input class="form-control w-100" type="number" id="amountPerDistance" formControlName="amountPerDistance"
                name="amountPerDistance">
            </div>
            <div class="form-group col-md-3 trash-column">
              <div>
                <label for="extraAmount">Extra amount</label>
                <input class="form-control w-100" type="number" id="extraAmount" formControlName="extraAmount"
                  name="extraAmount">
              </div>
              <mat-icon (click)="removeLimit(i)" class="delete-icon" *ngIf="i !== 0">delete</mat-icon>
            </div>
          </div>
        </form>
      </ng-container>

      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary add-button" [disabled]="!nextLimitAvailable()"
            (click)="addDistanceLimit()">+</button>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="note">Shorthand notation</label>
          <textarea disabled class="form-control" style="width: 100% !important;">{{shorthandNotation}}</textarea>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="note">Notes / Description</label>
          <textarea class="form-control" style="width: 100% !important;" id="note" formControlName="note"
            name="note"></textarea>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary w-auto mr-2" (click)="hideModal()">Cancel</button>
    <button class="btn btn-outline w-auto" (click)="deleteMileageRateRuleModal.show()">Delete</button>

    <button type="submit" class="btn btn-primary w-auto" (click)="submit()" [disabled]="!formsValid()">
      <span *ngIf="isFormProcessing">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!isFormProcessing">Save</span>
    </button>
  </div>
</app-modal>

<app-modal #deleteMileageRateRuleModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Mileage Rate Rule?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this mileage rate rule?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteMileageRateRuleModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="deleteRule()" class="btn btn-primary">
          <span *ngIf="isDeleteProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!isDeleteProcessing">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
