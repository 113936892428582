<div *ngIf="result" class="tab-pane fade active show">

  <table class="std-results-list patients-approved">
    <tbody>
      <tr (click)="onPatientRegistrationClicked(patientRegistration.id)"
        *ngFor="let patientRegistration of result.results" data-toggle="link" data-toggle-class="more-btn">
        <td class="valign-top">
          <span class="label">{{ patientRegistration.patientNumber }}</span>

          <div class="patient-details">
            <span class="patient-name">{{ patientRegistration.patientName }}</span>
          </div>
        </td>
        <td class="valign-top">
          <span class="label d-none d-lg-block">&nbsp;</span>
          <span class="patient-trial-name">{{ patientRegistration.siteNumber }}</span>
        </td>
        <td class="result-btn text-right"><button class="btn btn-circle more-btn more-btn"
            (click)="onPatientRegistrationClicked(patientRegistration.id)"><i aria-hidden="true"
              class="icon-right"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="result.currentPage" [totalPageCount]="result.totalPageCount"
    (pageSelected)="loadPatientRegistrations($event, keywords, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="!result.results.length">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients matching your search.</p>
      </div>
    </div>
  </div>

</div>

<app-modal #patientRegistrationDetails customClassName="registration-details-modal">
  <div class="modal-header">
    <h2 class="modal-title">Patient Registration Details</h2>
  </div>
  <div class="modal-body" *ngIf="patientRegistration">

    <table class="simple-form">
      <tr>
        <th><label>Site Number</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.siteNumber">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Investigator Name</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.investigatorName">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Public Trial Code</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.trialCode">
          </div>
        </td>
      </tr>
    </table>

    <h3>Patient Details</h3>
    <table class="simple-form">
      <tr>
        <th><label>Patient Name</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.patientName">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Patient Number</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.patientNumber">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Year of Birth</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.yearOfBirth">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Address</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.address">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Phone Number</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.phoneNumber">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Email Address</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.email">
          </div>
        </td>
      </tr>
    </table>

    <h3>Emergency Contact Details</h3>
    <table class="simple-form">
      <tr>
        <th><label>Contact Name</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.contactName">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Contact Phone Number</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.contactPhoneNumber">
          </div>
        </td>
      </tr>

      <tr>
        <th><label>Contact Relationship</label></th>
        <td>
          <div class="form-group">
            <input type="text" class="form-control" disabled [value]="patientRegistration.contactRelationship">
          </div>
        </td>
      </tr>

    </table>

    <table class="simple-form">
      <tr>
        <th><label>Signature</label></th>
        <td>
          <div class="form-group">
            <img class="signature-img" [src]="patientRegistration.signatureUrl">
          </div>
        </td>
      </tr>
    </table>

  </div>
  <div class="modal-footer">
    <button (click)="patientRegistrationDetails.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
  </div>
</app-modal>
