import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Countries } from '../../core/constants/countries';
import { Address } from "./address.model";
import { filter } from "rxjs/operators";
import { StringHelper } from "../../core/helpers/string-helper";

@Component({
  selector: 'app-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss']
})
export class InputAddressComponent implements OnInit, AfterViewInit {
  @Input('showLabels') showLabels = false;
  @Input('parentForm') parentForm: FormGroup;
  @Input('controlName') controlName: string;

  @Input('countryRequired') countryRequired = false;
  @Input('zipRequired') zipRequired = false;
  @Input('streetRequired') streetRequired = false;
  @Input('aptRequired') aptRequired = false;
  @Input('stateRequired') stateRequired = false;
  @Input('cityRequired') cityRequired = false;
  @Input('fullAddressRequired') fullAddressRequired = false;
  @Input('allowReset') allowReset = false;

  @Input('countryDisabled') countryDisabled = false;

  form: FormGroup;
  countries: { value: string, text: string }[] = [];
  filteredCountries: { value: string, text: string }[] = [];
  address: Address;

  constructor() {
    for (const country of Countries.all()) {
      this.countries.push({value: country.code, text: country.name});
    }
    this.filteredCountries = this.countries;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      apt: new FormControl(),
      street: new FormControl(''),
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl(),
      country: new FormControl(),
    });
  }

  ngAfterViewInit() {
    if (this.fullAddressRequired) {
      this.aptRequired = true;
      this.streetRequired = true;
      this.cityRequired = true;
      this.stateRequired = true;
      this.zipRequired = true;
      this.countryRequired = true;
    }

    if (this.zipRequired)
      this.setRequired('zip')

    if (this.countryRequired)
      this.setRequired('country');

    if (this.aptRequired)
      this.setRequired('apt');

    if (this.streetRequired)
      this.setRequired('street');

    if (this.stateRequired)
      this.setRequired('state');

    if (this.cityRequired)
      this.setRequired('city');

    // Set the value of the form control on the parent form
    this.form.valueChanges.subscribe(() => {
      if (this.parentForm) {
        this.parentForm.get(this.controlName).setValue(this.value);

        let addressValid = true;

        if (this.countryRequired) {
          addressValid = addressValid && (
            this.countryDisabled
              ? !StringHelper.isNullOrEmpty(this.form.get('country').value)
              : this.form.get('country').valid
          );
        }

        if (this.zipRequired)
          addressValid = addressValid && this.form.get('zip').valid;

        if (this.aptRequired)
          addressValid = addressValid && this.form.get('apt').valid;

        if (this.streetRequired)
          addressValid = addressValid && this.form.get('street').valid;

        if (this.stateRequired)
          addressValid = addressValid && this.form.get('state').valid;

        if (this.cityRequired)
          addressValid = addressValid && this.form.get('city').valid;

        if (!addressValid) {
          this.setAddressInvalid();
        } else {
          this.setAddressValid();
        }
      }
    });

    if (this.countryDisabled)
      this.form.get('country').disable();
  }

  private setAddressValid() {
    this.parentForm.get(this.controlName).setErrors(null);
  }

  private setAddressInvalid() {
    this.parentForm.get(this.controlName).setErrors({invalidAddress: true});
  }

  private setRequired(controlName: string) {
    this.form.get(controlName).setValidators(Validators.required);
    this.form.get(controlName).updateValueAndValidity();
  }

  reset() {
    this.form.reset();
  }

  setAddress(address: Address) {
    if (address instanceof Address && address != null) {
      this.address = address;

      this.form.patchValue({
        id: this.address.id,
        apt: this.address.apt,
        street: this.address.street,
        city: this.address.city,
        state: this.address.state,
        zip: this.address.zip,
        country: this.address.countryCode
      });

      this.form.updateValueAndValidity();
    }
  }

  filterCountries(term: string) {
    if (term !== undefined && term !== null && term !== '') {
      this.filteredCountries = this.countries.filter(country => country.text.toLowerCase().startsWith(term.toLowerCase()));
      return;
    }

    this.filteredCountries = this.countries;
  }

  private hasAddress(): boolean {
    return !!(this.form.get('street').getRawValue() || this.form.get('city').getRawValue() || this.form.get('state').getRawValue() || this.form.get('zip').getRawValue() || this.form.get('country').getRawValue());
  }

  public get value(): Address | null {
    if (!this.hasAddress())
      return null;

    return new Address({
      id: this.form.value.id,
      apt: this.form.get('apt').getRawValue(),
      street: this.form.get('street').getRawValue(),
      city: this.form.get('city').getRawValue(),
      state: this.form.get('state').getRawValue(),
      zip: this.form.get('zip').getRawValue(),
      country: this.form.get('country').getRawValue()
    });
  }

  protected readonly filter = filter;
}
