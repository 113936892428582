import { EventEmitter, Injectable } from '@angular/core';
import { AlertModel } from "./alert.model";
import { LogHelper } from "../../core/helpers/log.helper";
import { ErrorResponse } from '../shared.model';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertAdded = new EventEmitter<AlertModel>();
  allAlertsCleared = new EventEmitter<void>();

  /**
   * Accepts an error object and tries to extract the error message from it to render a toast
   * @param error
   */
  showErrorAlert(error: any) {
    LogHelper.error("Alert Error", error);

    let message = 'Sorry, something went wrong!';

    if (error && error.error) {
      if (typeof error.error === 'string' || error.error instanceof String) {
        message = error.error;
      } else if (typeof error.error.title === 'string' || error.error.title instanceof String) {
        message = error.error.title;
      }
    }

    this.showWarningAlert(message);
  }

  showSuccessAlert(message: string) {
    this.alertAdded.emit(new AlertModel(
      'toast-success',
      false,
      null,
      message));
  }

  showMultiLineSuccessAlert(title: string, message: string) {
    this.alertAdded.emit(new AlertModel(
      'toast-success',
      true,
      title,
      message));
  }

  showWarningAlert(message: string) {
    this.alertAdded.emit(new AlertModel(
      'toast-error',
      false,
      null,
      message));
  }

  showMultiLineWarningAlert(title: string, message: string) {
    this.alertAdded.emit(new AlertModel(
      'toast-error',
      false,
      title,
      message));
  }

  showErrorResponse(error: ErrorResponse) {
    LogHelper.log(error);

    if (error.status === HttpStatusCode.BadRequest) {
      this.showWarningAlert(error.title);
      return;
    }

    this.showWarningAlert("Unexpected error occurred. Please try again or contact support.");
  }

  clearAll() {
    this.allAlertsCleared.emit();
  }
}
