<!-- Start: Patients approved -->
<div class="tab-pane fade active show">

  <table class="std-results-list patients-approved">
    <tbody *ngIf="dataLoaded">
      <tr (click)="onPatientClicked(patient.patientTrialId)" *ngFor="let patient of results.results" data-toggle="link"
        data-toggle-class="more-btn">
        <td class="valign-top">
          <span class="label">{{ patient.patientCode == null ? 'NA' : patient.patientCode }}</span>

          <div class="patient-details">
            <span class="patient-name">{{ patient.getFullName() }}</span>
            <span class="patient-address">{{ patient.siteCountry }}</span>
          </div>
        </td>
        <td class="valign-top">
          <span class="label d-none d-lg-block">&nbsp;</span>
          <span class="patient-trial-name">{{ stringHelper.limit(patient.trialLabel, 45) }}</span>
        </td>
        <td class="result-btn text-right"><a class="btn btn-circle more-btn more-btn"
            routerLink="/patient/{{ patient.patientTrialId }}"><i aria-hidden="true" class="icon-right"></i></a>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!dataLoaded" class="approved-patients-loader">
      <tr *ngFor="let _ of loaderArray">
        <td style="width: 15%;">
          <ngx-skeleton-loader class="d-block patient-code-loader"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="d-block"></ngx-skeleton-loader>
        </td>
        <td style="width: 40%;">
          <ngx-skeleton-loader class="d-block hidden-loader"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="d-block" style="width: 5%;"></ngx-skeleton-loader>
        </td>
        <td style="width: 45%;">
          <ngx-skeleton-loader class="d-block hidden-loader"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="d-block" style="width: 35%;"></ngx-skeleton-loader>
        </td>
        <td><ngx-skeleton-loader appearance="circle" class="d-block" style="margin-top: -25px"></ngx-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
    (pageSelected)="loadPatients($event, keywords, country, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="!hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-patients.png" alt="No approved patients" />
      </div>
      <div class="content">
        <h2>No Approved Patients</h2>
      </div>
    </div>
  </div>

  <div class="no-results inside" *ngIf="results.results.length == 0 && hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients matching your search.</p>
      </div>
    </div>
  </div>

</div>
<!-- End: Patients approved -->
