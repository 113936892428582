<div #autoCompleteParent class="autocomplete" (click)="onClick()" [ngClass]="{'focus': showDropdown}">
  <label *ngIf="selectedOption !== null">{{ selectedOption.text }}</label>
  <label class="placeholder" *ngIf="selectedOption === null">{{ placeholder }}</label>

  <a class="arrow"><i class="far fa-angle-down" aria-hidden="true"></i></a>
  <a class="clear" *ngIf="selectedOption !== null" (click)="onClear(); $event.stopPropagation()"><i class="far fa-times" aria-hidden="true"></i></a>

  <div class="autocomplete-dropdown" *ngIf="showDropdown">
    <div class="autocomplete-search">
      <div [formGroup]="form" class="form-group">
        <input #input formControlName="input" type="text" autocomplete="off" (click)="$event.stopPropagation();" (keyup)="onInputValueChanged($event)">
      </div>

      <i class="far fa-search" aria-hidden="true"></i>
    </div>

    <div class="autocomplete-results">
      <ul>
        <li *ngFor="let option of filteredOptions" [id]="option.value">
          <a (click)="selectOption(option)">
            <span>{{ option.text }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
