<div *ngIf="trialPaymentDetailsViewModel" class="view-body">

  <div class="content-panel payment-content">
    <table class="simple-form payments-form">
      <tr>
        <th style="width: 140px;"><label for="caxtonEnabled">Caxton bank enabled ?</label></th>
        <td>
          <div class="d-flex justify-content-between">
            <div class="form-group d-flex align-items-center">
              <label class="switch mb-0">
                <input type="checkbox" [disabled]="!authService.hasPermission(Permissions.TrialPaymentsCaxton)"
                  [checked]="trialPaymentDetailsViewModel.caxtonBankEnabled" (click)="caxtonSwitch()"
                  id="caxtonEnabled">
                <span class="slider round"></span>
              </label>
              <button type="submit" class="btn btn-primary test-connection-btn ml-5" (click)="testConnectionToCaxton()"
                [disabled]="textConnectionIsProcessing">
                <span *ngIf="textConnectionIsProcessing">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </span>
                <span *ngIf="!textConnectionIsProcessing">{{!trialPaymentDetailsViewModel.caxtonDataAvailable ?
                  'Create and Link Caxton Subsidiary' : 'Test Connection to Caxton'}}
                </span>
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th style="width: 140px;"><label for="automaticFundsSwitch">Auto-funding from Caxton Main Account ?</label></th>
        <td>
          <div class="form-group">
            <label class="switch mb-0">
              <input type="checkbox"
                [disabled]="!authService.hasPermission(Permissions.TrialPaymentsCaxton) || !trialPaymentDetailsViewModel.caxtonBankEnabled"
                [checked]="trialPaymentDetailsViewModel.automaticFundsTransferEnabled" (click)="automaticFundsSwitch()"
                id="automaticFundsSwitch">
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </tr>

      <ng-container *ngIf="trialPaymentDetailsViewModel.caxtonAccountDetails">
        <tr class="d-none">
          <th style="width: 140px;"><label for="">In-app Bank Details</label></th>
          <td>
            <div class="form-group">
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </td>
        </tr>
        <tr *ngIf="trialPaymentDetailsViewModel.caxtonBalances && trialPaymentDetailsViewModel.caxtonBalances.length">
          <th style="width: 140px;"><label class="separator-label">Read-only</label></th>
        </tr>
        <tr *ngIf="trialPaymentDetailsViewModel.caxtonAccountDetails">
          <th style="width: 140px;"><label>Caxton Payment Reference</label></th>
          <td>
            <div class="form-group caxton-balance-wrapper">
              <input type="text" class="form-control trial-payment-info" disabled
                [value]="trialPaymentDetailsViewModel.caxtonAccountDetails.caxtonPaymentReference">
            </div>
          </td>
        </tr>
        <tr *ngIf="trialPaymentDetailsViewModel.caxtonAccountDetails">
          <th style="width: 140px;"><label>Caxton Owner Email</label></th>
          <td>
            <div class="form-group caxton-balance-wrapper">
              <input type="text" class="form-control trial-payment-info" disabled
                [value]="trialPaymentDetailsViewModel.caxtonAccountDetails.caxtonOwnerEmail">
            </div>
          </td>
        </tr>
        <tr *ngIf="trialPaymentDetailsViewModel.caxtonBalances && trialPaymentDetailsViewModel.caxtonBalances.length">
          <th style="width: 140px;"><label for="">Currency Balances</label></th>
          <td>
            <div class="form-group caxton-balance-wrapper">
              <input
                *ngIf="trialPaymentDetailsViewModel.caxtonBalances && trialPaymentDetailsViewModel.caxtonBalances.length"
                type="text" class="form-control caxton-balance trial-payment-info" disabled
                [value]="trialPaymentDetailsViewModel.caxtonBalances[0].balance">
              <span>{{trialPaymentDetailsViewModel.caxtonBalances[0].currency}}</span>
            </div>
          </td>
        </tr>
        <tr
          *ngFor="let balance of trialPaymentDetailsViewModel.caxtonBalances && trialPaymentDetailsViewModel.caxtonBalances.length > 1 ? trialPaymentDetailsViewModel.caxtonBalances.slice(1) : []">
          <th style="width: 140px;"></th>
          <td>
            <div class="form-group caxton-balance-wrapper">
              <input type="text" class="form-control caxton-balance trial-payment-info" disabled
                [value]="balance.balance">
              <span>{{balance.currency}}</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="trialPaymentDetailsViewModel.caxtonBalances && trialPaymentDetailsViewModel.caxtonBalances.length">
          <th style="width: 140px;"></th>
          <td>
            <div class="form-group payment-buttons">
              <button [disabled]="textConnectionIsProcessing" type="submit" class="btn btn-outline"
                (click)="testConnectionToCaxton()">
                <img src="../../../assets/img/icon _ refresh.svg" alt="Refresh">
                <span *ngIf="textConnectionIsProcessing">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </span>
                <span *ngIf="!textConnectionIsProcessing">Refresh</span>
              </button>
              <button (click)="getBalanceAlerts()"
                [disabled]="!authService.hasPermission(Permissions.TrialPaymentsRead)" class="btn btn-secondary">
                <img src="../../../assets/img/icon _ alerts.svg" alt="Alerts">Alerts
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
    <table *ngIf="authService.hasPermission(Permissions.TrialPaymentsCaxton)" class="simple-form payments-form">
      <tr>
        <th style="width: 140px;"></th>
        <td>
          <div class="btn-wrap">
            <button (click)="setCaxtonEnabled()" type="submit" class="btn btn-primary">
              <span *ngIf="saveProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!saveProcessing">Save</span>
            </button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<app-modal #alertsModal [customClassName]="'alerts-modal'">
  <div class="modal-header">
    <i (click)="alertsModal.hide()" class="icon-cancel" aria-hidden="true"></i>
    <h2 class="modal-title">Currency Balance Alerts</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="alertForm">
      <div class="alert-columns">
        <label class="label">Current Balance</label>
        <label class="label">Alert at</label>
      </div>
      <div *ngFor="let balanceThreshold of balanceThresholds" class="form-group alert-balances">
        <label class="currency-label" [for]="balanceThreshold.currency">
          {{balanceThreshold.currentBalance}}
          {{balanceThreshold.currency}}
        </label>
        <div class="input-wrapper">
          <input type="number" [id]="balanceThreshold.currency" [formControlName]="balanceThreshold.currency"
            [value]="balanceThreshold.balance">
        </div>
      </div>
      <div class="form-group alert-email">
        <label for="alertEmail" class="label">Alert to:</label>
        <input id="alertEmail" formControlName="alertEmail" type="email">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="alertsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>
      <div class="col-6">
        <button class="btn btn-primary" (click)="updateAlerts()" [ngClass]="{'disabled': !alertForm.valid}">
          <span *ngIf="alertUpdateProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!alertUpdateProcessing">Save</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #createSubsidiaryModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Create and Link Caxton Subsidiary</h2>
    </div>

    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="createSubsidiaryForm">
        <div class="form-group">
          <label>Subsidiary Name</label>
          <input type="text" class="form-control" formControlName="subsidiaryName">
          <span
            *ngIf="createSubsidiaryForm.get('subsidiaryName').hasError('maxlength') || createSubsidiaryForm.get('subsidiaryName').hasError('minlength')"
            class="validation-error">Length of subsidiary name has to be between 5 and 50 characters long.</span>
        </div>
        <div class="form-group">
          <label>Owner email</label>
          <input type="email" class="form-control" formControlName="ownerEmail">
          <span *ngIf="createSubsidiaryForm.get('ownerEmail').hasError('forbiddenEmail')"
            class="validation-error">Please use a specific trial mailbox for the Caxton owner email.</span>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="createSubsidiaryModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmCreateSubsidiary()" class="btn btn-primary"
            [ngClass]="{'disabled': createSubsidiaryProcessing || createSubsidiaryForm.invalid}">
            <span *ngIf="createSubsidiaryProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!createSubsidiaryProcessing">Create & Link</span>
          </a>
        </div>

      </div>
    </div>
  </div>
</app-modal>
