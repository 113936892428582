import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TimelineEvent} from "../../core/models/TimelineEvent";

@Component({
  selector: 'app-trial-timeline-event',
  templateUrl: './trial-timeline-event.component.html',
  styleUrls: ['./trial-timeline-event.component.scss']
})
export class TrialTimelineEventComponent {
  @Output('deleted') deleted = new EventEmitter();
  @Output('edit') edit = new EventEmitter();

  @Input('timelineEvent') timelineEvent = new TimelineEvent();
  @Input('first') first: boolean = false;
  @Input('last') last: boolean = false;
  @Input('isPreview') isPreview: boolean = false;

  titleCaseWord(word: string) {
    if (!word)
      return word;

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
}
