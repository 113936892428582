<app-modal #modal>
  <div class="modal-header">
    <h2 class="modal-title">Merge Patient</h2>
  </div>
  <div class="modal-body">
    <p>
      Form here
    </p>
  </div>
  <div class="modal-footer">
    <div class="row">

      <div class="col-6">
        <a (click)="hideModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button [disabled]="!form.valid" (click)="onSubmitMergeRequest()" class="btn btn-primary">
          <span *ngIf="form.get('processing').value">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!form.get('processing').value">Merge</span>
        </button>
      </div>


    </div>
  </div>
</app-modal>
