<app-modal #modal [customClassName]="'add-note-modal'">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Add Expense Claim Note</h2>
    </div>
    <div class="modal-body">
      <form [formGroup]="addNoteForm">
        <label class="label-input">Note</label>
        <textarea class="form-control" formControlName="notes"></textarea>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="closeAddNoteModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button [disabled]="addNoteProcessing" (click)="addNoteToExpenseClaim()" class="btn btn-primary">
            <span *ngIf="addNoteProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!addNoteProcessing">Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal>
