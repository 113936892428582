<div *ngIf="expenseRules?.length" class="rules-list">
  <div class="rules-rows">
    <div class="rules-columns">
      <label style="flex: 2 0 5%;">Rule Name</label>
      <label style="flex: 3 0 5%;">Categories</label>
      <label style="flex: 1 0 5%;">Patient Limit</label>
      <label style="flex: 1 0 6%;">Caregiver Limit</label>
      <label style="flex: 0 0 5%;">Per</label>
      <label style="flex: 2 0 5%;">Receipt?</label>
      <label style="flex: 4 0 5%;">Notes</label>
      <label style="flex: 1 0 5%;" *ngIf="showResult">Decision</label>
      <label style="flex: 3 0 5%;" *ngIf="showResult">Reason</label>
    </div>
    <div class="rule" *ngFor="let rule of expenseRules" (click)="openUpdateRuleModal(rule)">
      <span style="flex: 2 0 5%;">{{rule.ruleName}}</span>
      <span style="flex: 3 0 5%;">{{displayCategories(rule)}}</span>
      <span style="flex: 1 0 5%;">{{rule.amount}}{{rule.amount ? rule.currency : ''}}</span>
      <span style="flex: 1 0 6%;">{{rule.caregiverAmount}}{{rule.caregiverAmount ? rule.currency : ''}}</span>
      <span style="flex: 0 0 5%;">{{ExpenseAutoApprovalRuleTypeString.Values[rule.type]}}</span>
      <span style="flex: 2 0 5%;">{{getReceiptValue(rule)}}</span>
      <span style="flex: 4 0 5%; overflow-wrap: break-word;">{{rule.note}}</span>
      <span style="flex: 1 0 5%;" *ngIf="showResult">{{AutoApprovalResultString.Values[rule.result]}}</span>
      <span style="flex: 3 0 5%;" *ngIf="showResult">{{rule.reason}}</span>
    </div>
  </div>

</div>
