import { Permissions } from './../../../../core/constants/permissions';
import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {TrialPolicy} from '../trial-policy.model';
import {Router} from '@angular/router';
import {ObjectHelper} from "../../../../core/helpers/object.helper";
import {TrialDetail} from "../../../../core/models/trial-detail.model";
import {TrialService} from "../../../../core/services/trial.service";
import {LogHelper} from "../../../../core/helpers/log.helper";
import {AuthService} from "../../../../core/services/auth.service";
import {AlertService} from "../../../../shared/alert/alert.service";
import {ModalComponent} from "../../../../shared/modal/modal.component";

@Component({
  selector: 'app-trial-policies',
  templateUrl: './trial-policies.component.html',
  styleUrls: ['./trial-policies.component.scss']
})

export class TrialPoliciesComponent implements AfterViewInit {
  @ViewChild('removePolicyModal') removePolicyModal: ModalComponent;

  @Input() trial = new TrialDetail();

  policies: TrialPolicy[] = [];
  policyIdToRemove: string = null;
  Permissions = Permissions;

  constructor(public authService: AuthService, public trialService: TrialService, public alertService: AlertService, public router: Router) {

  }

  ngAfterViewInit() {
    this.loadPolicies();
  }

  loadPolicies() {
    if (!ObjectHelper.isUndefinedOrNull(this.trial)) {
      this.trialService.policies(this.trial.id).subscribe({
        next: (policies: TrialPolicy[]) => {
          this.policies = policies;
        },
        error: (error) => {
          this.alertService.showErrorAlert(error);
        }
      });
    }
  }

  onEdit(policyId: string): void {
    this.router.navigate(['/trial/'+this.trial.id+'/policy/'+policyId+'/edit/']);
  }

  /**
   * Called when a user clicks to remove a policy
   * @param policyId
   */
  onRemovePolicy(policyId: string): void {
    this.policyIdToRemove = policyId;
    this.removePolicyModal.show();
  }

  /**
   * Called when a user has confirmed they wish to delete the selected policy
   */
  onConfirmRemovePolicy(): void {
    this.trialService.removePolicy(this.trial.id, this.policyIdToRemove).subscribe({
      next: () => {
        this.loadPolicies();
        this.removePolicyModal.hide();
        this.policyIdToRemove = null;
        this.alertService.showSuccessAlert('Policy successfull removed');
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }
}
