<div *ngIf="dataLoaded" class="expense-actions">

  <div class="secondary-buttons" *ngIf="authService.hasPermission(Permissions.ExpenseWrite)">
    <button (click)="startInvestigation.emit(expense.id)" class="btn btn-secondary btn-slim"
      *ngIf="!expense.underInvestigation && expense.state === ExpenseClaimState.Pending">
      Requires Investigation
    </button>
    <button (click)="closeInvestigation.emit(expense.id)" class="btn btn-secondary btn-slim btn-close-investigation"
      *ngIf="expense.underInvestigation && expense.state === ExpenseClaimState.Pending">
      Close Investigation
    </button>
    <button class="btn btn-secondary btn-slim" (click)="edit.emit(expense.id)"
      [disabled]="expense.overBudgetRequestRaised && expense.state === ExpenseClaimState.Pending">
      Edit
    </button>
  </div>

  <app-expense-auto-approval-result *ngIf="selectedTab === ExpenseClaimState.Pending"
    [result]="expense.approvalSuggestion" [expenseClaimId]="expense.id"
    (resultUpdated)="propagateResult()"></app-expense-auto-approval-result>

  <div class="primary-buttons">
    <button *ngIf="expense.state == ExpenseClaimState.Pending" (click)="check.emit(expense)"
      class="btn btn-primary btn-slim">
      Check
    </button>
    <button [disabled]="processingRequest" *ngIf="expense.state == ExpenseClaimState.Pending &&
                    !expense.underInvestigation &&
                    authService.hasPermission(Permissions.ExpenseWrite)" (click)="approve.emit(expense)"
      class="btn btn-primary btn-slim approve-btn">
      <span *ngIf="processingRequest">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!processingRequest">Approve</span>
    </button>
    <button [disabled]="processingRequest"
      *ngIf="expense.state == ExpenseClaimState.Approved && authService.hasPermission(Permissions.ExpenseWrite)"
      (click)="markAsPaid.emit(expense.id)" class="btn btn-primary btn-slim mark-paid-btn">
      <span *ngIf="processingRequest">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!processingRequest">Mark as Paid</span>
    </button>
    <button *ngIf="expense.state == ExpenseClaimState.Pending && authService.hasPermission(Permissions.ExpenseWrite)"
      (click)="reject.emit(expense.id)" [disabled]="expense.overBudgetRequestRaised" class="btn btn-outline btn-slim">
      Reject
    </button>
  </div>

  <div *ngIf="expense.state == ExpenseClaimState.Paid">&nbsp;</div>
</div>

<div *ngIf="!dataLoaded" class="expense-actions">
  <div class="loaders">
    <div class="security-buttons-loader" *ngIf="authService.hasPermission(Permissions.ExpenseWrite)">
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 50%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 15%;"></ngx-skeleton-loader>
    </div>
    <div class="auto-approval-suggestion-loader" *ngIf="selectedTab === ExpenseClaimState.Pending">
      <ngx-skeleton-loader style="width: 40%;" class="label-loader"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="approval-loader"></ngx-skeleton-loader>
    </div>
    <div class="primary-buttons-loader">
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Approved" class="approved-page-button-loader"
        style="width: 30%;"></ngx-skeleton-loader>
    </div>
  </div>
</div>
