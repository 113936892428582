import { RoleNames } from './constants/role-names';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "../features/home/home.component";
import { UnauthorisedComponent } from "../features/auth/unauthorised/unauthorised.component";
import { RoleAuthGuard } from "./guards/role-auth-guard.service";
import { TravelRequestListComponent } from "../features/travel/travel-request-list/travel-request-list.component";
import { SiteListComponent } from "../features/site/site-list/site-list.component";
import { TrialsListComponent } from "../features/trial/trials-list/trials-list.component";
import { AuthGuard } from "./guards/auth-guard.service";
import {
  TrialPolicyEditComponent
} from "../features/trial/trial-details/trial-policies/trial-policy-edit/trial-policy-edit.component";
import { TrialAddComponent } from "../features/trial/trial-add/trial-add.component";
import { TrialDetailsComponent } from "../features/trial/trial-details/trial-details.component";
import {
  TrialAssignSiteComponent
} from "../features/trial/trial-details/trial-sites/trial-assign-site/trial-assign-site.component";
import { PatientListComponent } from "../features/patient/patient-list/patient-list.component";
import {
  TrialPolicyAddComponent
} from "../features/trial/trial-details/trial-policies/trial-policy-add/trial-policy-add.component";
import { SettingsComponent } from "../features/settings/settings/settings.component";
import { PatientDetailComponent } from "../features/patient/patient-detail/patient-detail.component";
import { NotSupportedComponent } from "../shared/not-supported/not-supported.component";
import {
  ExpenseCategoryEditComponent
} from "../features/expense-category/expense-category-edit/expense-category-edit.component";
import { TrialReportComponent } from "../features/trial/trial-report/trial-report.component";
import { TrialEditComponent } from "../features/trial/trial-edit/trial-edit.component";
import {
  TrialNewSiteComponent
} from "../features/trial/trial-details/trial-sites/trial-new-site/trial-new-site.component";
import {
  ParentSubsidiaryDetailsComponent
} from "../features/caxton/parent-subsidiary-details/parent-subsidiary-details.component";
import {
  ExpenseCategoryAddComponent
} from "../features/expense-category/expense-category-add/expense-category-add.component";
import { SiteEditComponent } from "../features/site/site-edit/site-edit.component";
import { SiteAssignedTrialsComponent } from "../features/site/site-assigned-trials/site-assigned-trials.component";
import {
  PatientVisitDetailComponent
} from "../features/patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.component";
import {
  ExpenseCategoryListComponent
} from "../features/expense-category/expense-category-list/expense-category-list.component";
import { ExpenseListComponent } from "../features/expense/expense-list/expense-list.component";
import { SiteAddComponent } from "../features/site/site-add/site-add.component";
import { AddAdminUserComponent } from '../features/admin/admin-users/add-admin-user/add-admin-user.component';
import { EditAdminUserComponent } from '../features/admin/admin-users/edit-admin-user/edit-admin-user.component';
import {
  TrialUpdateSiteComponent
} from 'app/features/trial/trial-details/trial-sites/trial-update-site/trial-update-site.component';
import { AdminViewComponent } from '../features/admin/admin-view/admin-view.component';
import {
  AddSiteContactComponent
} from '../features/admin/admin-site-contacts/add-site-contact/add-site-contact.component';
import {
  EditSiteContactComponent
} from '../features/admin/admin-site-contacts/edit-site-contact/edit-site-contact.component';
import { VisitsComponent } from "../features/visit/visits/visits.component";
import { PatientRegisterComponent } from 'app/features/patient/patient-register/patient-register.component';
import {
  ProjectManagementListComponent
} from "../features/project-manager/project-manager-list/project-management-list.component";
import { AdminRoleAuthGuard } from './guards/admin-role-auth-guard.service';
import { CaxtonCardsListComponent } from 'app/features/card-tracker/caxton-cards-list/caxton-cards-list.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', redirectTo: '' },
  { path: 'unauthorised', component: UnauthorisedComponent, canActivate: [AuthGuard] },
  { path: 'browser-not-supported', component: NotSupportedComponent },
  { path: 'admin', component: AdminViewComponent, canActivate: [AuthGuard, AdminRoleAuthGuard], data: { roles: [RoleNames.ProductAdministrator, RoleNames.SystemAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses] } },
  { path: 'admin/add', component: AddAdminUserComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.SystemAdministrator] } },
  { path: 'admin/edit', component: EditAdminUserComponent, canActivate: [AuthGuard, AdminRoleAuthGuard], data: { roles: [RoleNames.ProductAdministrator, RoleNames.SystemAdministrator] } },
  { path: 'admin/contact/add', component: AddSiteContactComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.SystemAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProductAdministrator] } },
  { path: 'admin/contact/edit/:id', component: EditSiteContactComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.SystemAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProductAdministrator] } },
  { path: 'trial', component: TrialsListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'report', component: TrialReportComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectManager, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/add', component: TrialAddComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/:id', component: TrialDetailsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/:id/edit', component: TrialEditComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/:id/site/assign', component: TrialAssignSiteComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses] } },
  { path: 'trial/:trialId/site/:siteId/edit', component: TrialUpdateSiteComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses] } },
  { path: 'trial/:id/site/add', component: TrialNewSiteComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/:id/policy/add', component: TrialPolicyAddComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'trial/:id/policy/:policyId/edit', component: TrialPolicyEditComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'site', component: SiteListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'site/add', component: SiteAddComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'site/:id/edit', component: SiteEditComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'site/:id/trials', component: SiteAssignedTrialsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'patient', component: PatientListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProductAdministrator] } },
  { path: 'patient/:id', component: PatientDetailComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProductAdministrator] } },
  { path: 'patient/:id/visit/:visitId', component: PatientVisitDetailComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProductAdministrator] } },
  { path: 'patient-register/:patientRegistrationCode/:siteContactId', component: PatientRegisterComponent },
  { path: 'expense', component: ExpenseListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorTravel] } },
  { path: 'category', component: ExpenseCategoryListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel] } },
  { path: 'category/add', component: ExpenseCategoryAddComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'category/:id/edit', component: ExpenseCategoryEditComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'travel', component: TravelRequestListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProductAdministrator] } },
  { path: 'caxton', component: ParentSubsidiaryDetailsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.StartupCoordinator, RoleNames.ProductAdministrator] } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, RoleAuthGuard],data: { roles: [RoleNames.ProductAdministrator, RoleNames.SystemAdministrator ] } },
  { path: 'visit', component: VisitsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProductAdministrator] } },
  { path: 'project-management', component: ProjectManagementListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProjectManager, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel] } },
  { path: 'card-tracker', component: CaxtonCardsListComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { roles: [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.ProjectManager] } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
