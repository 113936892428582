import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { environment } from 'environments/environment';
import { RoleAuthGuard } from './role-auth-guard.service';

@Injectable()
export class AdminRoleAuthGuard extends RoleAuthGuard {

  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.environment === "DEVELOPMENT" || environment.environment === "STAGING") {
      return true;
    }

    super.canActivate(route, state);
  }
}
