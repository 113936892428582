import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fromBeforeToDateValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const fromDate = group.get('from');
  const toDate = group.get('to');

  if (!fromDate || !toDate || !fromDate.value || !toDate.value)
    return null;

  var fromTokens = fromDate.value.split("/")
  var toTokens = toDate.value.split("/")
  let from = new Date(fromTokens[2], fromTokens[1]-1, fromTokens[0])
  let to = new Date(toTokens[2], toTokens[1]-1, toTokens[0])
  if (from <= to)
    return null;

  group.controls['to'].setErrors({ 'endBeforeStart': true });
  group.setErrors({ 'invalid': true })
  return { 'endBeforeStart': true }
};
