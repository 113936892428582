export class TrialSiteContact {
  public siteContactId: string;
  public siteContactSiteId: string;
  public isActive: boolean = false;
  public fullname: string;
  public email: string;
  public role: number;
  public roleName: string;
  public deleted: boolean = false;

  public constructor(init?: Partial<TrialSiteContact>) {
    Object.assign(this, init);
  }
}
