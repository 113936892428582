import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: '[visit-table-cell-text]',
  templateUrl: './visit-table-cell-text.component.html',
  styleUrls: ['./visit-table-cell-text.component.scss']
})
export class VisitTableCellTextComponent {
  @ViewChild('input') input: ElementRef;
  @ViewChildren('td') tdElements: QueryList<ElementRef>;

  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('text') text: string;
  @Input('editable') editable: boolean = false;
  @Input('truncateLength') truncateLength: string = '0';
  @Input('minWidth') minWidth: string;
  @Input('textarea') textarea: boolean = false;
  @Input('link') link = "";

  editing = false;
  originalValue = '';

  get truncatedText(): string {
    if (this.text && +this.truncateLength > 0 && this.text.length > +this.truncateLength) {
      return this.text.substring(0, +this.truncateLength) + '...';
    }
    return this.text;
  }

  onEdit(): void {
    if (this.editable) {
      this.editing = true;
      this.originalValue = this.parentForm.get(this.controlName).value;
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 500);
    }
  }

  onBlur(): void {
    this.editing = false;

    // if the value has changed, then emit an event to let the parent component know about it
    if (this.parentForm.get(this.controlName).value != this.originalValue) {
      this.text = this.parentForm.get(this.controlName).value;
      this.valueChanged.emit();
    }
  }

}
