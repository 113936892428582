import { SelectOption } from "../models/select-option.model";

export class BookingStatusOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "InProgress",
        "text": "In Progress"
      },
      {
        "value": "NotStarted",
        "text": "Not Started"
      },
      {
        "value": "Done",
        "text": "Done"
      },
      {
        "value": "Cancelled",
        "text": "Cancelled"
      },
      {
        "value": "Na",
        "text": "N/A"
      },
      {
        "value": "AwaitingInfoFromPatient",
        "text": "Awaiting Info From Patient"
      },
      {
        "value": "AwaitingInfoFromSite",
        "text": "Awaiting Info From Site"
      },
      {
        "value": "ObAwaitingSponsor",
        "text": "OB Awaiting Sponsor"
      },
      {
        "value": "ChangeRequested",
        "text": "Change Requested"
      }
    ];
  }
}
