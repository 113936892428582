<!-- Start: Simple Header with Logo -->
<header class="simple" *ngIf="!userLoggedIn">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img class="il-logo" src="/assets/img/illingworth-logo.svg" alt="PatientGo">
      </div>
    </div>
  </div>
</header>
<!-- End: Simple Header with Logo -->
