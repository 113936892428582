import { SubsidiaryService } from './../../../../core/services/subsidiary.service';
import { Permissions } from './../../../../core/constants/permissions';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  BalanceThresholds,
  CaxtonEnabledRequest,
  CreateAndLinkSubsidiaryRequest,
  SetTrialBalanceAlertsRequest,
  TrialPaymentDetailsViewModel
} from '../trial-payments.model';
import { TrialService } from "../../../../core/services/trial.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { AuthService } from "../../../../core/services/auth.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { PaymentService } from "../../../../core/services/payment.service";
import { LogHelper } from 'app/core/helpers/log.helper';
import { forbiddenEmailValidator } from 'app/core/validators/forbidden-email.validator';

@Component({
  selector: 'app-trial-payments',
  templateUrl: './trial-payments.component.html',
  styleUrls: ['./trial-payments.component.scss']
})
export class TrialPaymentsComponent implements OnInit {
  @ViewChild('alertsModal') alertsModal: ModalComponent;
  @ViewChild('createSubsidiaryModal') createSubsidiaryModal: ModalComponent;

  @Input() trialId: string;
  trialPaymentDetailsViewModel: TrialPaymentDetailsViewModel;
  textConnectionIsProcessing = false;
  balanceThresholds: BalanceThresholds[];
  alertForm: UntypedFormGroup;
  createSubsidiaryForm: UntypedFormGroup;
  alertUpdateProcessing: boolean = false;
  saveProcessing: boolean = false;
  Permissions = Permissions;
  createSubsidiaryProcessing: boolean = false;

  constructor(private readonly trialService: TrialService, private readonly alertService: AlertService,
    private readonly paymentService: PaymentService, public authService: AuthService,
    private readonly subsidiaryService: SubsidiaryService) { }

  ngOnInit(): void {
    this.alertForm = new UntypedFormGroup({
      alertEmail: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.initiateCreateSubsidiaryForm();

    this.trialService.getTrialPaymentsInfo(this.trialId).subscribe({
      next: response => {
        this.trialPaymentDetailsViewModel = response;
        if (this.trialPaymentDetailsViewModel.caxtonDataAvailable && this.trialPaymentDetailsViewModel.caxtonAccountDetails == undefined) {
          this.alertService.showWarningAlert('Unable to retrieve trial payments info');
        }
      },
      error: () => {
        this.alertService.showWarningAlert('Unable to retrieve trial payments info');
      }
    });
  }

  testConnectionToCaxton() {
    if (!this.trialPaymentDetailsViewModel.caxtonDataAvailable) {
      this.createSubsidiaryForm.get("subsidiaryName").setValue(this.trialPaymentDetailsViewModel.trialLabel);
      this.createSubsidiaryForm.get("ownerEmail").setValue(this.trialPaymentDetailsViewModel.coordinatorEmail);

      this.createSubsidiaryModal.show();
      return;
    }

    this.textConnectionIsProcessing = true;
    this.paymentService.getBalances(this.trialId).subscribe({
      next: balances => {
        this.alertService.showSuccessAlert('Connection successful');
        this.trialPaymentDetailsViewModel.caxtonBalances = balances;
        this.textConnectionIsProcessing = false;
      },
      error: () => {
        this.alertService.showWarningAlert('Connection to Caxton failed');
        this.textConnectionIsProcessing = false;
      }
    });
  }

  getBalanceAlerts() {
    this.alertsModal.show();
    this.trialService.getBalanceAlerts(this.trialId).subscribe(result => {
      this.balanceThresholds = result.balanceThresholds;
      this.alertForm.controls.alertEmail.setValue(result.alertEmail);
      result.balanceThresholds.forEach(t => {
        this.alertForm.addControl(t.currency, new UntypedFormControl(t.balance, Validators.min(0)));
      });
    });
  }

  updateAlerts() {
    this.alertUpdateProcessing = true;
    const request: SetTrialBalanceAlertsRequest = {
      trialId: this.trialId,
      alertEmail: this.alertForm.get('alertEmail').value,
      currencyThresholds: []
    };

    Object.keys(this.alertForm.controls).forEach(key => {
      if (key !== 'alertEmail') {
        request.currencyThresholds.push({
          currency: key,
          balance: this.alertForm.get(key).value
        });
      }
    });

    this.trialService.updateBalanceAlerts(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Alerts successfully set.');
        this.alertsModal.hide();
        this.alertUpdateProcessing = false;
      },
      error: () => {
        this.alertService.showWarningAlert('Failed to set alerts. Please try again or contact support');
        this.alertUpdateProcessing = false;
      }
    });
  }

  caxtonSwitch() {
    this.trialPaymentDetailsViewModel.caxtonBankEnabled = !this.trialPaymentDetailsViewModel.caxtonBankEnabled;

    if (!this.trialPaymentDetailsViewModel.caxtonBankEnabled) {
      this.trialPaymentDetailsViewModel.automaticFundsTransferEnabled = false;
    }
  }

  automaticFundsSwitch() {
    this.trialPaymentDetailsViewModel.automaticFundsTransferEnabled = !this.trialPaymentDetailsViewModel.automaticFundsTransferEnabled;
  }

  setCaxtonEnabled() {
    this.saveProcessing = true;

    const request: CaxtonEnabledRequest = {
      trialId: this.trialId,
      caxtonEnabled: this.trialPaymentDetailsViewModel.caxtonBankEnabled,
      automaticFundsTransferEnabled: this.trialPaymentDetailsViewModel.automaticFundsTransferEnabled
    };

    this.trialService.setCaxtonEnabled(request).subscribe({
      next: () => {
        this.saveProcessing = false;
        this.alertService.showSuccessAlert('Changes saved successfully.');
      },
      error: error => {
        this.saveProcessing = false;
        this.alertService.showWarningAlert(error.error ? error.error.title : 'Failed to save changes. Please try again.');
      }
    });
  }

  initiateCreateSubsidiaryForm() {
    this.createSubsidiaryForm = new UntypedFormGroup({
      subsidiaryName: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      ownerEmail: new UntypedFormControl('', [Validators.required, Validators.email, forbiddenEmailValidator(['support@patientgo.com', 'support@illingworthresearch.com'])])
    });
  }

  onConfirmCreateSubsidiary() {
    this.createSubsidiaryProcessing = true;
    let request = this.createSubsidiaryForm.value as CreateAndLinkSubsidiaryRequest;
    request.trialId = this.trialId;

    this.subsidiaryService.createAndLinkSubsidiary(request).subscribe({
      next: response => {
        this.createSubsidiaryProcessing = false;
        this.trialPaymentDetailsViewModel = response;
        this.createSubsidiaryModal.hide();

        this.alertService.showSuccessAlert("Caxton Subsidiary Successfully Linked");
      },
      error: error => {
        this.createSubsidiaryProcessing = false;
        this.alertService.showWarningAlert(error.error ? error.error.title : "Failed to create and link Caxton Subsidiary. Please try again or contact support.");
        LogHelper.log(error);
      }
    });
  }
}
