<div class="tab-pane fade show active details-view-results">
  <div class="details-view-results-head">
    <div class="row">
      <div class="col-6">
        <form [formGroup]="filterForm">
          <app-dropdown-input (selectValueChanged)="onFilterChange($event)" name="showVisits" [parentForm]="filterForm" [options]="periodSelectOptions" selectedValue="upcoming" className="slim sm"></app-dropdown-input>
        </form>
      </div>
      <div class="col-6 text-right">
        <a *ngIf="patient" class="btn btn-outline btn-outline-slim" (click)="onHandleAddNewVisit()">+ Add New Visit</a>
      </div>
    </div>
  </div>
  <table class="details-view-tbl visits" aria-hidden="true">
    <tr (click)="onVisitClicked(patientTrialId, visit.id)" *ngFor="let visit of visibleVisits"
        [ngClass]="{'has-badge': visit.hasTravelRequests}"
        data-toggle="link">
      <td>
        <div class="visit-badges-placeholder">
          <div class="visit-badges">
            <span class="badge" [class.badge-success]="visit.publishedStatus === 'Published'"  [class.badge-draft]="visit.publishedStatus !== 'Published'">{{ visit.publishedStatus }}</span>
            <span class="badge badge-warning" *ngIf="visit.hasTravelRequests">Travel Requested</span>
          </div>
        </div>

        <span class="label">Visit ID</span>
        <span class="visit-id">{{ visit.shortId }}</span>
      </td>
      <td>
        <span class="label">{{ visit.title }}</span>
        <span class="date">{{ visit.date | date: 'd MMM yyyy' }}</span>
        <span class="time">{{ visit.timeMinutes !== null ? timeMinutesToStr(+visit.timeMinutes) : '' }}</span>
      </td>
      <td>
        <span class="label">{{ visit.typeDescription }}</span>
        <span *ngIf="visit.type == 'OnSite'" class="address">{{ visit.siteName + ' ' + visit.siteAddress }}</span>
      </td>
      <td class="details-view-btn"><a class="btn btn-circle more-btn more-btn"
                                      routerLink="/patient/{{patientTrialId}}/visit/{{visit.id}}"><i aria-hidden="true"
                                                                                                     class="icon-right"></i></a>
      </td>
    </tr>
  </table>
</div>

<!-- Create new visit modal -->
<app-add-visit-modal #addVisitModal (addedOrUpdated)="onHandleVisitAddedOrUpdated()"></app-add-visit-modal>
