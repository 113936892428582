import { AlertService } from './../../../shared/alert/alert.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StartExpenseClaimInvestigationRequest } from 'app/core/models/expense.model';
import { ExpenseService } from 'app/core/services/expense.service';
import { ModalComponent } from 'app/shared/modal/modal.component';

@Component({
  selector: 'app-start-investigation-modal',
  templateUrl: './start-investigation-modal.component.html',
  styleUrls: ['./start-investigation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StartInvestigationModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('allClaimsModal') allClaimsModal: ModalComponent;

  @Output() refreshResult: EventEmitter<any> = new EventEmitter<any>();

  investigationForm: UntypedFormGroup;
  processing: boolean = false;
  expenseClaimId: string;

  constructor(private expenseService: ExpenseService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.initInvestigationForm();
  }


  initInvestigationForm() {
    this.investigationForm = new UntypedFormGroup({
      reasonForInvestigation: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
    });
  }

  showAllClaimsModal(){
    this.allClaimsModal.show();
  }

  onSubmitStartInvestigation(allClaims: boolean) {
    if (this.investigationForm.invalid) {
      this.alertService.showWarningAlert("Please enter reason for investigation");
      return;
    }

    let request: StartExpenseClaimInvestigationRequest = {
      expenseClaimId: this.expenseClaimId,
      reasonForInvestigation: this.investigationForm.get('reasonForInvestigation').value,
      allClaims: allClaims
    }

    this.processing = true;
    this.expenseService.startInvestigation(request).subscribe({
      next: () => {
        this.allClaimsModal.hide();
        this.modal.hide();
        this.alertService.showSuccessAlert('Expense claim under investigation.');
        this.processing = false;
        this.investigationForm.reset();
        this.refreshResult.emit();
      },
      error: error => {
        this.processing = false;
        this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was a problem');
      }
    });
  }
}
