<form [formGroup]="form">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label *ngIf="showLabels">Apt <sup *ngIf="aptRequired || fullAddressRequired">*</sup></label>
        <sup class="no-label" *ngIf="!showLabels && (aptRequired || fullAddressRequired)">*</sup>
        <input type="text" placeholder="Apt..." formControlName="apt">
      </div>
    </div>
    <div class="col-8">
      <div class="form-group">
        <label *ngIf="showLabels">Number + Street <sup *ngIf="streetRequired || fullAddressRequired">*</sup></label>
        <sup class="no-label" *ngIf="!showLabels && (streetRequired || fullAddressRequired)">*</sup>
        <input type="text" placeholder="Number + Street..." formControlName="street">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label *ngIf="showLabels">City <sup *ngIf="cityRequired || fullAddressRequired">*</sup></label>
        <sup class="no-label" *ngIf="!showLabels && (cityRequired || fullAddressRequired)">*</sup>
        <input type="text" placeholder="City..." formControlName="city">
      </div>

      <div class="form-group">
        <label *ngIf="showLabels">State <sup *ngIf="stateRequired || fullAddressRequired">*</sup></label>
        <sup class="no-label" *ngIf="!showLabels && (stateRequired || fullAddressRequired)">*</sup>
        <input type="text" placeholder="State..." formControlName="state">
      </div>

      <div class="form-group">
        <label *ngIf="showLabels">Zip Code <sup *ngIf="zipRequired || fullAddressRequired">*</sup></label>
        <sup class="no-label" *ngIf="!showLabels && (zipRequired || fullAddressRequired)">*</sup>
        <input type="text" placeholder="Zip Code..." formControlName="zip">
      </div>

      <div class="form-group country-input">
        <sup class="no-label" *ngIf="countryRequired || fullAddressRequired">*</sup>
        <app-input-select [allowClear]="false" (onFilter)="filterCountries($event)" [items]="filteredCountries" [parentForm]="form" controlName="country" inputPlaceholder="Country..." [allowFiltering]="true" searchInputPlaceholder="Search countries..."></app-input-select>
      </div>

      <div class="reset-address" *ngIf="allowReset">
        <a (click)="reset()" class="btn btn-outline btn-outline-slim">Remove Address</a>
      </div>
    </div>
  </div>
</form>

