<!-- Start: Patients rejected -->
<div class="tab-pane fade active show">

  <table class="std-results-list patients-pending">
    <tbody>
    <tr *ngFor="let patient of results.results">
      <td class="valign-top">
        <span class="label d-none d-lg-block">&nbsp;</span>
        <div class="patient-name">{{ patient.getFullName() }}</div>
      </td>
      <td class="valign-top">
        <span class="label d-none d-lg-block">&nbsp;</span>
        <span class="patient-trial-name">{{ stringHelper.limit(patient.trialLabel, 45) }}</span>
      </td>
      <td class="valign-top">
        <span class="label">Email Address</span>
        <span class="patient-trial-name">{{ truncateRejectedEmail(patient.email) }}</span>
      </td>
      <td class="result-btn">
        <div class="buttons-container">
          <a (click)="onApprovePatient(patient)" class="btn btn-primary btn-slim" *ngIf="authService.hasPermission(Permissions.PatientApprove)">Approve</a>
          <a (click)="onSelectDeletePatient(patient)" class="btn btn-secondary btn-slim" *ngIf="authService.hasPermission(Permissions.PatientDelete)">Delete</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount" (pageSelected)="loadPatients($event, keywords, country, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="!hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-patients.png" alt="No rejected patients" />
      </div>
      <div class="content">
        <h2>No Rejected Patients</h2>
      </div>
    </div>
  </div>

  <div class="no-results inside" *ngIf="results.results.length == 0 && hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients matching your search.</p>
      </div>
    </div>
  </div>

</div>
<!-- End: Patients rejected -->

<app-patient-delete-modal #deletePatientModal [activeTrialCodes]="activeTrialCodes" [patientId]="patientToDelete?.id" (deletedPatient)="onDeletedPatient()"></app-patient-delete-modal>
