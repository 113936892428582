<app-modal #modalWindow customClassName="create-site">
  <div class="modal-header">
    <h2 class="modal-title">Add New Site Contact</h2>
  </div>
  <div class="modal-body">

    <form (submit)="onSubmit()" [formGroup]="form">

      <table aria-hidden="true" class="simple-form">
        <tr>
          <th><label>First Name</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="firstname" type="text"/>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Last Name</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="lastname" type="text"/>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Email Address</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="email" type="text"/>
            </div>
          </td>
        </tr>
        <tr>
          <th style="vertical-align: middle"><label>Status</label></th>
          <td>
            <app-dropdown-input #statusDropdown [options]="statusOptions" [parentForm]="form"
                                name="active"></app-dropdown-input>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="checkbox-wrap last">
              <div class="form-group">
                <label class="checkbox">
                  <input formControlName="consent" id="consent" type="checkbox">
                  <span></span>
                </label>
                <label class="checkbox-label" for="consent">I confirm that I have validated consent</label>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>

  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="modalWindow.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onSubmit()" [ngClass]="{'disabled': !form.valid || form.get('processing').value}"
           class="btn btn-primary">
            <span *ngIf="isProcessing">
                            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                          </span>
          <span *ngIf="!isProcessing">Add Site Contact</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
