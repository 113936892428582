import { Permissions } from './../../../core/constants/permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import {ExpenseCategoryList} from "../../../core/models/expense-category-list.model";
import {AuthService} from "../../../core/services/auth.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {ExpenseService} from "../../../core/services/expense.service";
import {TemplateService} from "../../../core/services/template.service";
import {ModalComponent} from "../../../shared/modal/modal.component";

@Component({
  selector: 'app-expense-category-list',
  templateUrl: './expense-category-list.component.html'
})
export class ExpenseCategoryListComponent implements OnInit {
  @ViewChild('deleteCategoryModal') deleteCategoryModal: ModalComponent;

  results = new ExpenseCategoryList();
  resultsPerPage = 25;
  deleteCategoryId: string;
  categoryIsDeleting = false;
  Permissions = Permissions;

  constructor(public authService: AuthService, private templateService: TemplateService, private expenseService: ExpenseService, private alertService: AlertService) { }

  ngOnInit() {
    this.templateService.showHeader();

    // Load categories
    this.loadCategories(1);
  }

  loadCategories(page: number) {
    this.expenseService.retrieveExpenseCategories(page, null, null, false, this.resultsPerPage).subscribe(categories => {
      this.results = categories;
    });
  }

  onDeleteCategory(categoryId: string) {
    this.deleteCategoryId = categoryId;
    this.deleteCategoryModal.show();
  }

  /**
   * Removes a site once a user has confirmed
   */
  onConfirmDeleteCategory() {
    this.categoryIsDeleting = true;

    this.expenseService.deleteExpenseCategory(this.deleteCategoryId).subscribe({
      next: () => {
        this.categoryIsDeleting = false;
        this.deleteCategoryId = '';
        this.deleteCategoryModal.hide();
        this.loadCategories(this.results.currentPage);
        this.alertService.showSuccessAlert('The category has been successfully deleted');
      },
      error: error => {
        this.categoryIsDeleting = false;
        this.deleteCategoryModal.hide();
        this.alertService.showWarningAlert(error.error ? error.error.title : 'There was a problem!');
      }
    });
  }
}
