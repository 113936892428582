import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-datepickerv2-input',
  templateUrl: './datepickerv2-input.component.html',
  styleUrls: ['./datepickerv2-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Datepickerv2InputComponent {
  @ViewChild('input') input: ElementRef;

  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('whiteBg') whiteBg: boolean = false;

}
