import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MileageRateDistanceLimitViewModel, MileageRateListItemViewModel } from '../../trial-policy.model';

@Component({
  selector: 'app-trial-policy-mileage-rates-list',
  templateUrl: './trial-policy-mileage-rates-list.component.html',
  styleUrls: ['./trial-policy-mileage-rates-list.component.scss']
})
export class TrialPolicyMileageRatesListComponent implements OnInit {
  @Input() mileageRateRules: MileageRateListItemViewModel[] = []
  @Output() updateRule: EventEmitter<MileageRateListItemViewModel> = new EventEmitter<MileageRateListItemViewModel>();

  constructor() { }

  ngOnInit(): void {
  }

  getShorthandNotation(distanceLimits: MileageRateDistanceLimitViewModel[]) {
    let shorthandNotation = '';
    distanceLimits.forEach(limit => {

      const firstVal = limit.distanceTo ? `<=${limit.distanceTo}` : `>${limit.distanceFrom}`;
      const secondVal = limit.amountPerDistance ? `dx${limit.amountPerDistance}` : '';
      const thirdVal = limit.extraAmount ? `(${secondVal})+${limit.extraAmount}` : secondVal;

      const final = `;${firstVal}=${thirdVal}`;

      shorthandNotation += final;

      const firstCharacter = shorthandNotation.charAt(0);
      if (firstCharacter === ';') {
        shorthandNotation = shorthandNotation.slice(1);
      }
    });

    return shorthandNotation;
  }

  openUpdateRuleModal(rule: MileageRateListItemViewModel) {
    this.updateRule.emit(rule);
  }
}
