import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { PatientAutoCompleteComponent } from '../../../shared/patient-autocomplete/patient-autocomplete.component';
import { DropdownInputComponent } from '../../../shared/dropdown-input/dropdown-input.component';
import {
  AutosuggestDropdownInputComponent
} from '../../../shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExpenseCategoryItem } from '../../../core/models/expense-category-list.model';
import { SelectOption } from '../../../core/models/select-option.model';
import { PatientDetail } from '../../../core/models/patient-detail.model';
import { TrialDetail } from '../../../core/models/trial-detail.model';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../../core/services/template.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { ExpenseService } from '../../../core/services/expense.service';
import { PatientService } from '../../../core/services/patient.service';
import { LogHelper } from '../../../core/helpers/log.helper';
import { TrialAutocompleteComponent } from '../../../shared/trial-autocomplete/trial-autocomplete.component';
import { TrialAutocomplete } from '../../../shared/trial-autocomplete/trial-autocomplete.model';
import { Currencies } from 'app/core/constants/currency';
import { StringHelper } from "../../../core/helpers/string-helper";
import { NumberHelper } from "../../../core/helpers/number.helper";
import { ExchangeRateRequest } from 'app/core/models/exchange-rate.model';
import { ExchangeRateService } from 'app/core/services/exchange-rate.service';
import { InputCurrencyComponent } from 'app/shared/input-currency/input-currency.component';

@Component({
  selector: 'app-create-expense-modal',
  templateUrl: './create-expense-modal.component.html',
  styleUrls: ['./create-expense-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateExpenseModalComponent implements OnInit, AfterViewInit {
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('patientAutoComplete') patientAutoComplete: PatientAutoCompleteComponent;
  @ViewChild('distanceUnitSelect') distanceUnitSelect: DropdownInputComponent;
  @ViewChild('currencySelect') currencySelect: AutosuggestDropdownInputComponent;
  @ViewChild('categorySelect') categorySelect: DropdownInputComponent;
  @ViewChild('visitSelect') visitSelect: DropdownInputComponent;
  @ViewChild('uploadImageInput') uploadImageInput: ElementRef;
  @ViewChild('removePhotoModal') removePhotoModal: ModalComponent;
  @ViewChild('amountQuotedBCControl') amountQuotedBCControl: InputCurrencyComponent;

  @Output() expenseCreated = new EventEmitter();

  //FX Rate
  transformedFxRateForm: UntypedFormGroup;
  transformedCurrencyProcessing: boolean = false;
  showFxTransformError: boolean = false;
  baseCurrency: string = '';

  //Expense Claim
  attachments: { data: Blob, filename: string, imageUrl: any, ext: string }[] = [];
  uploadedImageFilenames: string[] = [];
  imagesUploadedSubscription: Subscription;
  imageUploadedEvent = new EventEmitter<string[]>();
  removePhotoAtIndex = null;

  form: UntypedFormGroup;
  distanceUnitOptions: { value: string; text: string }[] = [];
  currencyOptions: { value: string; text: string }[] = [];
  visitOptions: { value: string; text: string }[] = [];
  categoryOptions: { value: string; text: string }[] = [];
  expenseId: string;
  categories: ExpenseCategoryItem[] = [];
  selectedCategory = new ExpenseCategoryItem('', '', '', '', false, null);
  isFormProcessing = false;
  returnTo: string;
  selectedPatientOption: SelectOption;
  patient: PatientDetail;
  trial: TrialDetail;
  numberHelper = NumberHelper;

  patientSuggestions: { value: string, text: string }[] = [];

  get canTransformRate(): boolean {
    let baseCurrency = this.patient.trialBaseCurrency;
    let amount = this.form.get('amount').value;
    let currency = this.form.get('currency').value;
    return baseCurrency !== '' && baseCurrency !== null && baseCurrency !== undefined
      && amount !== '' && amount !== null && amount !== undefined
      && currency !== '' && currency !== null && currency !== undefined;
  }

  constructor(
    private readonly templateService: TemplateService,
    private readonly alertService: AlertService,
    private readonly expenseService: ExpenseService,
    private readonly exchangeRateService: ExchangeRateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly patientService: PatientService) {
  }

  ngOnInit() {
    this.templateService.showHeader();

    // Populate distance units drop down
    this.distanceUnitOptions.push({ value: 'Miles', text: 'Miles' });
    this.distanceUnitOptions.push({ value: 'Kilometers', text: 'Kilometers' });

    // Populate currencies drop down
    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    this.form = new UntypedFormGroup({
      trialId: new UntypedFormControl('', Validators.required),
      patientId: new UntypedFormControl(''),
      patientName: new UntypedFormControl({ value: '', disabled: this.returnTo === 'patient' }),
      visitId: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      categoryId: new UntypedFormControl('', Validators.required),
      subCategoryId: new UntypedFormControl(''),
      currency: new UntypedFormControl(''),
      amount: new UntypedFormControl(''),
      distanceAmount: new UntypedFormControl(''),
      distanceUnit: new UntypedFormControl(''),
      notes: new UntypedFormControl('', Validators.maxLength(1000))
    });

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({value: ''}),
      transformedCurrency: new UntypedFormControl({value: '', disabled: true}),
    });

    this.form.get('categoryId').valueChanges.subscribe(value => {
      this.setSelectedCategory(value);
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(params => {
      this.expenseId = params.id;
    });

    this.patientAutoComplete?.selectedChanged.subscribe((option: SelectOption) => {
      this.form.reset();
      if (this.categorySelect !== undefined) {
        this.categorySelect.reset();
        this.selectedCategory = new ExpenseCategoryItem('', '', '', '', false, null);
      }
      if (this.currencySelect !== undefined && this.currencySelect !== null) {
        this.currencySelect.onClear();
      }
      if (this.distanceUnitSelect !== undefined) {
        this.distanceUnitSelect.reset();
      }
      if (this.visitSelect !== undefined) {
        this.visitSelect.reset();
      }
      this.selectedPatientOption = option;
      this.form.patchValue({ patientId: option.value });

      // Load patient details
      this.trialAutocomplete.setPatientId(option.value);
      this.loadPatientDetails(option.value);
    });

    this.trialAutocomplete.valueChanged.subscribe((option: TrialAutocomplete) => {
      this.loadPatientTrial(this.form.value.patientId, option.id);
    });
  }

  resetForm(): void {
    // Using form.reset() breaks the autocomplete
    this.form.patchValue({
      patientId: '',
      patientName: '',
      visitId: '',
      trialId: '',
      categoryId: '',
      subCategoryId: '',
      currency: '',
      amount: '',
      distanceAmount: '',
      distanceUnit: '',
      notes: ''
    });

    this.trial = undefined;
    this.patient = undefined;

    this.uploadedImageFilenames = [];
    this.uploadImageInput.nativeElement.value = '';
    this.attachments = [];

    this.form.get('notes').reset();

    if (this.visitSelect)
      this.visitSelect.reset();

    if (this.categorySelect) {
      this.categorySelect.reset();
      this.selectedCategory = new ExpenseCategoryItem('', '', '', '', false, null);
    }

    if (this.distanceUnitSelect)
      this.distanceUnitSelect.reset();

    if (this.patientAutoComplete)
      this.patientAutoComplete.onClear();

    if (this.currencySelect)
      this.currencySelect.onClear();

    if (this.trialAutocomplete)
      this.trialAutocomplete.reset();

  }

  showForPatient(patientId: string): void {
    this.resetForm();
    this.patient = null;
    this.returnTo = 'patient';
    this.loadPatientDetails(patientId);
    this.modal.show();
    this.selectedCategory = new ExpenseCategoryItem('', '', '', '', false, null);
    this.trialAutocomplete.setPatientId(patientId);

    this.form.get('patientName').disable();
  }

  show(): void {
    this.resetForm();
    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
  }

  setSelectedCategory(expenseCategoryId: string) {
    for (const category of this.categories.filter(c => c.id === expenseCategoryId)) {
      this.selectedCategory = category;

      if (category.type === 'Distance') {
        this.form.get('amount').setValidators(null);
        this.form.get('currency').setValidators(null);
        this.form.get('amount').setErrors({ required: false });
        this.form.get('currency').setErrors({ required: false });
        this.form.get('amount').reset();
        this.form.get('currency').reset();
        this.currencySelect.onClear();

        this.form.get('distanceAmount').setValidators(this.amountValidator);
        this.form.get('distanceAmount').setErrors({ required: true });

        this.form.get('distanceUnit').setValidators([Validators.required]);
        this.form.get('distanceUnit').setErrors({ required: true });
      }

      if (category.type === 'Value') {
        this.form.get('currency').setValidators([Validators.required]);
        this.form.get('amount').setValidators(this.amountValidator);
        this.form.get('distanceAmount').setValidators(null);
        this.form.get('distanceUnit').setValidators(null);
        this.form.get('distanceAmount').setErrors({ required: false });
        this.form.get('distanceUnit').setErrors({ required: false });
        this.form.get('distanceUnit').reset();
        this.form.get('distanceAmount').reset();

        this.form.get('currency').updateValueAndValidity();
        this.form.get('amount').updateValueAndValidity();
        this.prepareAmountBCControl();
      }

      // Update validation on the form depending on whether 'other' is selected as a category
      if (category.internalType.toLowerCase() === 'other') {
        this.form.patchValue({ subCategoryId: 0 });
        this.form.get('subCategoryId').setValidators([Validators.required]);
        this.form.get('subCategoryId').setErrors({ required: true });
      } else {
        this.form.get('subCategoryId').setValidators(null);
        this.form.get('subCategoryId').setErrors(null);
        this.form.patchValue({ subCategoryId: '' });
      }

      this.form.get('subCategoryId').updateValueAndValidity();
    }

    this.form.updateValueAndValidity();
  }

  amountValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value === undefined || value === null || value === '') {
      return { required: true };
    }

    const amount = +control.value;
    if (amount > 0) {
      return null;
    }

    return { invalid: true };
  }

  onCancelEditing() {
    this.resetFxFields();
    this.hide();
  }

  /**
   * Actioned when the user clicks on the add image button, it forces the browsers browse files window to open
   */
  onAddImage() {
    this.uploadImageInput.nativeElement.click();
  }

  onRemoveImage(index: number) {
    this.removePhotoAtIndex = index;
    this.removePhotoModal.show();
  }

  onConfirmRemoveImage() {
    if (this.removePhotoAtIndex != null) {
      let tmpAttachments = [];
      this.uploadedImageFilenames = [];
      this.uploadImageInput.nativeElement.value = '';

      let i = 0;
      for (let attachment of this.attachments) {
        if (i !== this.removePhotoAtIndex) {
          tmpAttachments.push(attachment);
          this.uploadedImageFilenames.push(attachment.filename);
        }

        i++;
      }

      this.attachments = tmpAttachments;
      this.removePhotoAtIndex = null;
    }

    this.removePhotoModal.hide();
  }

  private isFileTypeAllowed(file: File): boolean {
    const allowedTypes = /(image\/(jpeg|png|gif))|(application\/pdf)/;
    return allowedTypes.test(file.type);
  }

  /**
   * Actioned when the user selects an image to upload and handles grabbing the data for the image preview
   * @param files
   */
  preview(files) {
    if (files.length === 0)
      return;

    if (!this.isFileTypeAllowed(files[0]))
      return;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachments.push({ data: files[0], filename: files[0].name, imageUrl: reader.result, ext: StringHelper.getFileExtension(files[0].name) });
    };
  }

  /**
   * Initiates the call to create the expense claim
   * @param imageFilenames
   */
  actionCreateExpenseClaim(imageFilenames: string[] = []) {
    const dto = {
      visitId: this.form.get('visitId').value,
      expenseCategoryId: this.form.get('categoryId').value,
      currency: this.form.get('currency').value,
      amount: this.form.get('amount').value,
      amountBC: this.transformedFxRateForm.get('transformedAmount').value,
      distanceUnit: this.form.get('distanceUnit').value,
      distanceAmount: this.form.get('distanceAmount').value,
      notes: this.form.get('notes').value,
      subCategory: this.form.get('subCategoryId').value,
      imageFilenames: imageFilenames
    };

    this.expenseService.createExpenseClaim(this.form.get('patientId').value, dto).subscribe({
      next: () => {
        this.isFormProcessing = false;
        this.expenseCreated.emit();
        this.resetFxFields();
        this.hide();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showErrorAlert(error);
        this.isFormProcessing = false;
      }
    });
  }

  /**
   * Called when user wants to see amount in transformed FX currency.
   */
  onShowTransformedFxRate() {
    let amount = this.form.get('amount').value;
    let currency = this.form.get('currency').value;
    let toCurrency = this.baseCurrency;

    if (!this.canTransformRate) {
      this.showFxTransformError = true;
      return;
    }
    this.transformedCurrencyProcessing = true;

    let request = {
      fromCurrencyCode: currency,
      toCurrencyCode: toCurrency,
      quotedAmount: amount
    } as ExchangeRateRequest

    this.exchangeRateService.getTransformedFxRate(request).subscribe({
      next: result => {
        this.transformedCurrencyProcessing = false;
        if (result.result !== null) {
          this.transformedFxRateForm.patchValue({ transformedAmount: result.result / 100 });

        }
        else if(result.destinationCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for the base currency set on connected trial (${toCurrency}).`);
        }
        else if(result.sourceCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for this currency (${currency})`);
        }
      },
      error: error => {
        this.transformedCurrencyProcessing = false;
        this.showFxTransformError = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to retrieve transformed FX rate!');
      }
    });
  }

  resetFxFields() {
    this.transformedCurrencyProcessing = false;
    this.showFxTransformError = false;

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({value: ''}),
      transformedCurrency: new UntypedFormControl({value: '', disabled: true}),
    });
  }

  /**
   * Called when the create expense form is submitted by the user
   */
  onFormSubmit() {
    if (!this.form.valid)
      return;

    this.isFormProcessing = true;

    if (this.imagesUploadedSubscription == null) {
      this.imagesUploadedSubscription = this.imageUploadedEvent.subscribe(imageFilenames => {
        // Check if the number of imageFilenames (uploaded files) matches the number of attachments that needed to be uploaded
        if (imageFilenames.length === this.attachments.length) {
          this.actionCreateExpenseClaim(imageFilenames);
        }
      }, error => {
        LogHelper.log(error);
      });
    }

    // if there are no attachments create the expense claim and exit the function
    if (this.attachments.length === 0) {
      this.actionCreateExpenseClaim();
      return;
    }

    // Upload each attachment one by one. When each attachment is complete, make a call to an event emitter with an array
    // that contains a list of all uploaded filenames so far. A subscriber will monitor the number of uploaded files
    // and when it matches the number of files there were to be uploaded, it will make a call to create the expense claim
    for (let attachment of this.attachments) {
      this.expenseService.upload(attachment.data).subscribe({
        next: (rsp: any) => {
          if (rsp.success) {
            this.uploadedImageFilenames.push(rsp.fileName);
            this.imageUploadedEvent.emit(this.uploadedImageFilenames);
          } else {
            this.alertService.showWarningAlert('Sorry, there was a problem uploading one of your images.');
            this.imagesUploadedSubscription = null;
          }
        },
        error: (error) => {
          LogHelper.log(error);
          this.alertService.showWarningAlert("Upload failed. Supported file types are images and PDFs.");
          this.isFormProcessing = false;
        }
      });
    }
  }

  private prepareAmountBCControl() {
    if (this.selectedCategory !== null && this.selectedCategory.type === 'Value') {
      if (this.amountQuotedBCControl !== undefined && this.amountQuotedBCControl !== null && this.patient.trialBaseCurrency !== null) {
        this.amountQuotedBCControl.setCurrency(this.baseCurrency);
      }
    }
  }

  /**
   * Loads the patients details and then loads categories/visits for the patients trial
   * @param patientId
   */
  private loadPatientDetails(patientId: string) {
    this.patientService.retrievePatientDetail(patientId).subscribe({
      next: (patient) => {
        this.patient = patient;

        this.form.patchValue({
          patientId: patient.id,
          patientName: patient.firstname + ' ' + patient.lastname
        });
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showErrorAlert(error);
      }
    });
  }

  private loadPatientTrial(patientId: string, trialId: string) {
    if (this.visitSelect)
      this.visitSelect.loading = true;

    this.patientService.getTrialForPatient(patientId, trialId).subscribe({
      next: trial => {
        this.categories = trial.categories;
        this.baseCurrency = trial.baseCurrency;

        this.categoryOptions = [];
        for (const category of trial.categories) {
          this.categoryOptions.push({ value: category.id, text: category.name });
        }

        if (trial.categories.length === 0) {
          this.categoryOptions.push({ value: '', text: 'No categories' });
        }

        this.trial = trial;

        this.loadPatientVisits(this.form.value.patientId, trialId);
      },
      error: error => {
        LogHelper.log(error);
      }
    });
  }

  // Load patients visits
  private loadPatientVisits(patientId: string, trialId: string) {
    if (this.visitSelect)
      this.visitSelect.loading = true;

    this.patientService.retrievePatientVisitsForTrial(patientId, trialId).subscribe(visits => {
      this.visitOptions = [];
      for (const visit of visits) {
        this.visitOptions.push({ value: visit.id, text: visit.title });
      }

      if (visits.length === 0) {
        this.visitOptions.push({ value: '', text: 'No visits' });
      }

      if (this.visitSelect)
        this.visitSelect.loading = false;
    });
  }

}
