<div class="fees">
  <form [formGroup]="thresholdsForm">
    <p>
      For bank transfers over <input formControlName="thresholdValue" type="number"><strong>{{currency}}</strong>, add
      <input formControlName="feeValue" type="number"><strong>{{currency}}</strong>
      to each bank transfer instead.
    </p>
  </form>
  <button class="btn btn-outline btn-delete" (click)="removeComponent()">+</button>
</div>
