import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { InputCurrencyComponent } from "../input-currency/input-currency.component";

@Component({
  selector: '[table-inline-cell-amount-currency]',
  templateUrl: './table-inline-cell-amount-currency.component.html',
  styleUrl: './table-inline-cell-amount-currency.component.scss'
})
export class TableInlineCellAmountCurrencyComponent implements AfterViewInit {
  @ViewChild('currencySelect') currencySelect: InputCurrencyComponent;

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('amountControlName') amountControlName: string;
  @Input('currencyControlName') currencyControlName: string;
  @Input('editable') editable: boolean = false;

  @Output('valueChanged') valueChanged = new EventEmitter();

  editing = false;
  originalAmount = '';
  originalCurrency = '';

  ngAfterViewInit() {
    // Delay to wait for parent component to update
    setTimeout(() => {
      this.originalAmount = this.parentForm.get(this.amountControlName).value;
      this.originalCurrency = this.parentForm.get(this.currencyControlName).value;
      this.currencySelect.setCurrency(this.parentForm.get(this.currencyControlName).value);
    }, 500)
  }

  renderAmount(amount: number): string {
    if (amount === undefined || amount === null)
      return '';

    return amount.toFixed(2);
  }

  onBlur(): void {
    this.editing = false;

    let valueChanged = false;
    if (this.parentForm.get(this.amountControlName).value != this.originalAmount) {
      valueChanged = true;
    }

    if (this.parentForm.get(this.currencyControlName).value != this.originalCurrency) {
      valueChanged = true;
    }

    if (valueChanged) {
      this.originalAmount = this.parentForm.get(this.amountControlName).value;
      this.originalCurrency = this.parentForm.get(this.currencyControlName).value;
      this.valueChanged.emit();
    }
  }
}
