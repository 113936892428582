import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientTripMuvRide } from "../../../../core/models/patient-trip-muv-ride.model";
import { environment } from "../../../../../environments/environment";
import { Permissions } from "../../../../core/constants/permissions";

@Component({
  selector: 'app-muv-ride-list',
  templateUrl: './muv-ride-list.component.html',
  styleUrls: ['./muv-ride-list.component.scss']
})
export class MuvRideListComponent implements AfterViewInit {
  @Output('addTrip') addTrip = new EventEmitter();

  @Input('patientTripId') patientTripId: string;
  @Input('rides') rides: PatientTripMuvRide[] = [];

  @Output('viewRide') viewRide = new EventEmitter<PatientTripMuvRide>();

  muvViewRideUrl = `${environment.muvApiBaseUrl}`;
  hasActiveRide: boolean = false;

  ngAfterViewInit(): void {
    const activeRides = this.rides.find(x => !x.state.toLowerCase().includes('cancelled'));
    this.hasActiveRide = !!activeRides && this.rides.length > 0;
  }

  /**
   * Called when the user choosed to view a ride
   * @param ride
   */
  onViewRide(ride: PatientTripMuvRide) {
    this.viewRide.emit(ride);
  }

  protected readonly Permissions = Permissions;
}
