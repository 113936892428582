import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";
import { DropdownInputComponent } from "../dropdown-input/dropdown-input.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { PatientStateOnTrialOptions } from "../../core/constants/patient-state-on-trial-options";
import { AlertService } from "../alert/alert.service";
import { PatientService } from "../../core/services/patient.service";
import { DeletePatientRequest } from "../../core/models/patient.model";
import { LogHelper } from "../../core/helpers/log.helper";

@Component({
  selector: 'app-patient-delete-modal',
  templateUrl: './patient-delete-modal.component.html',
  styleUrl: './patient-delete-modal.component.scss'
})
export class PatientDeleteModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @Input() activeTrialCodes: string[];
  @Input() patientId: string;

  @Output() deletedPatient: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closingStateSelect') closingStateSelect: DropdownInputComponent;
  deletePatientForm: UntypedFormGroup;
  closingStateOptions = PatientStateOnTrialOptions.all().filter(s => s.value != PatientStateOnTrialOptions.Active);
  isDeleteFormProcessing: boolean = false;

  constructor(private alertService: AlertService, private patientService: PatientService) { }

  ngOnInit(): void {
    this.initDeletePatientForm();
  }

  public show() {
    this.deletePatientForm.reset();
    this.modal.show();
  }

  initDeletePatientForm() {
    this.deletePatientForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required),
      closingState: new UntypedFormControl('', Validators.required)
    })
  }

  onConfirmDeletePatient() {
    this.isDeleteFormProcessing = true;

    let request: DeletePatientRequest = this.deletePatientForm.value as DeletePatientRequest;
    request.patientId = this.patientId;

    this.patientService.deactivatePatient(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Patient successfully deleted.");

        this.hideModal();
        this.isDeleteFormProcessing = false;
        this.deletedPatient.emit();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert(error.error ? error.error.title : "Failed to delete patient. Please try again on contact support.");
        this.isDeleteFormProcessing = false;
      }
    });
  }

  hideModal(){
    this.closingStateSelect?.reset();
    this.modal.hide();
  }
}
