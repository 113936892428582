<div *ngIf="tabs.length > 0" [class.in-modal]="inModal" class="tabulated-content">
  <nav>
    <ul>
      <li class="nav-button" *ngIf="selectedIndex !== 0 && !disabled && showNavButtons"><a (click)="navigateToPreviousTab()"><i class="far fa-angle-left"></i></a></li>
      <ng-container *ngFor="let tab of tabs; index as i">
        <li  class="{{ tabClassNames[i] !== undefined ? tabClassNames[i].join(' ') : '' }}" [class.active]="i == selectedIndex" *ngIf="tab.visible"><a
                (click)="switchTab(i)">{{ tab.title }}</a>
        <span class="tab-badge" *ngIf="tab.showBadge">{{ tab.badge }}</span>
        </li>
      </ng-container>
      <li class="nav-button" *ngIf="selectedIndex !== getTabCount()-1 && !disabled && showNavButtons"><a (click)="navigateToNextTab()"><i class="far fa-angle-right"></i></a></li>
    </ul>
  </nav>

  <div class="tabulated-content-inner">
    <ng-content></ng-content>
  </div>
</div>
