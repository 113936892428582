<div class="view-head">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="heading-small with-padding">Edit CMS User</h2>
    </div>
  </div>
</div>

<div class="view-body">
  <div class="row">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

      <!-- Start: Tabs wrap -->
      <div class="tabs-wrap">
        <div class="std-tabbed-content margin-after-tabs">
          <app-mobile-tabs #mobileTabs (onSelected)="onTabClick($event)">
            <ul>
              <li><a (click)="mobileTabs.onSelectItem('info', $event)">User Info</a></li>
              <li><a (click)="mobileTabs.onSelectItem('trials', $event)">Assigned Trials</a></li>
            </ul>
          </app-mobile-tabs>

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 'info'}" (click)="onTabClick('info')">User
                Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 'trials'}"
                (click)="onTabClick('trials')">Assigned Trials</a>
            </li>
          </ul>

          <div class="results-wrap">
            <div class="tab-content">
              <!-- Start: User info -->
              <div [ngClass]="{'visibility-hidden': selectedTab !== 'info'}">
                <form [formGroup]="form" [ngClass]="{'form-loaded': dataHasLoaded}">
                  <table class="simple-form" aria-hidden="true">
                    <tr>
                      <th style="width: 130px;"><label>First Name</label></th>
                      <td>
                        <div class="form-group">
                          <div class="spinner-border spinner-border-sm form-element-loading" role="status"></div>
                          <input type="text" class="form-control" formControlName="firstname" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 130px;"><label>Last Name</label></th>
                      <td>
                        <div class="form-group">
                          <div class="spinner-border spinner-border-sm form-element-loading" role="status"></div>
                          <input type="text" class="form-control" formControlName="lastname" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 130px;"><label>Email Address</label></th>
                      <td>
                        <div class="form-group">
                          <div class="spinner-border spinner-border-sm form-element-loading" role="status"></div>
                          <input type="text" class="form-control" formControlName="email" />
                        </div>
                      </td>
                    </tr>

                    <tr *ngIf="rolesRetrieved && !isMyProfile" class="select-roles">
                      <th class="roles-label"><label>Choose user role:</label></th>
                      <td class="roles-wrap">
                        <ng-container *ngFor="let role of availableRoles">
                          <div class="form-group role-group">
                            <label class="checkbox">
                              <input type="checkbox" [checked]="userHasRole(role)" [id]="role"
                                (change)="selectRole($event, role)">
                              <span></span>
                            </label>
                            <label class="checkbox-label" [for]="role">{{getRoleText(role)}}</label>
                          </div>
                        </ng-container>
                      </td>
                    </tr>

                    <tr *ngIf="rolesRetrieved && isMyProfile" class="select-roles">
                      <th class="roles-label"><label>Assigned roles:</label></th>
                      <td class="roles-wrap">
                        <ng-container *ngFor="let role of selectedRoles">
                          <div class="form-group role-group">
                            <label class="checkbox-label readonly-role" [for]="role">{{getRoleText(role)}}</label>
                          </div>
                        </ng-container>
                      </td>
                    </tr>

                    <tr>
                      <th><label>MUV User Id</label></th>
                      <td>
                        <div class="form-group">
                          <div class="spinner-border spinner-border-sm form-element-loading" role="status"></div>
                          <input type="number" class="form-control" formControlName="muvUserId" />
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>
              </div>
              <!-- End: User info -->

              <!-- Start: Trials -->
              <div [ngClass]="{'visibility-hidden': selectedTab !== 'trials'}">

                <app-manage-admin-trials #manageTrials (assignedTrialChanged)="onAssignTrials($event)"
                  (hideFormButtons)="updateFormButtonVisibility($event)"></app-manage-admin-trials>

              </div>
              <!-- End: Trials -->

              <!-- Start: Form Actions -->
              <div class="btn-wrap" [ngClass]="{'visibility-hidden': hideFormButtons}">
                <div class="row">
                  <div class="col-6">
                    <a routerLink="/admin" class="btn btn-secondary">Cancel</a>
                  </div>
                  <div class="col-6">
                    <button type="button" (click)="onFormSubmit()" class="btn btn-primary"
                      [disabled]="!form.valid || isFormProcessing || !form.dirty || !selectedRoles.length">
                      <span *ngIf="isFormProcessing">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                      </span>
                      <span *ngIf="!isFormProcessing">Save</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- End: Form Actions -->
            </div>

          </div>
        </div>
      </div>
      <!-- End: Tabs wrap -->

    </div>
  </div>
</div>
