import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mobile-tabs',
  templateUrl: './mobile-tabs.component.html'
})
export class MobileTabsComponent implements AfterViewInit {
  @Output() onSelected = new EventEmitter<string>();

  selectedText: string;
  @Input() selectedValue: string;
  @ViewChild('unorderedList') unorderedList: ElementRef;

  isOpen = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.selectedText = this.getTextForValue(this.selectedValue);
    this.cdr.detectChanges();
  }

  private getTextForValue(value: string) {
    let li = this.unorderedList.nativeElement.querySelector('.selected');
    return li?.innerHTML;
  }

  onSelectItem(value: string, event: any) {
    this.selectedText = event.target.innerHTML;
    this.selectedValue = value;
    this.onSelected.emit(value);
    this.onToggle();
  }

  onToggle() {
    this.isOpen = !this.isOpen;
  }
}
