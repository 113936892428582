export class FeedbackListItem {
  constructor(
    public patientTrialId: string,
    public patientId: string,
    public patientCode: string,
    public trialCode: string,
    public trialNickname: string,
    public trialOpportunityNumber: string,
    public date: Date,
    public rating: number
  ) {}
}
