<div class="not-supported">
  <h2>Improve Your Experience</h2>

  <p>You’re using a web browser that is not
    supported. Please use one of the browsers
    listed below for a better experience.</p>

  <ul>
    <li><a href="https://www.google.co.uk/chrome/" target="_blank" class="btn btn-outline" rel="noopener">Chrome</a></li>
    <li><a href="https://www.microsoft.com/en-us/edge" target="_blank" class="btn btn-outline" rel="noopener">Edge</a></li>
    <li><a href="https://support.apple.com/en_GB/downloads/safari" target="_blank" class="btn btn-outline" rel="noopener">Safari</a></li>
  </ul>
</div>
