import { IEnvironment as Environment } from "./environments_class";
import { LogLevel } from "../app/core/log-level.enum";

// Devops release pipeline
export const environment: Environment = {
  production: true,
  apiUrl: '#{Cms.ApiUrl}#',
  pageSize: 25,
  displayConsoleLogs: true,
  logLevel: LogLevel.Info, // 'info' | 'warn' | 'error'
  platform: '#{Cms.PlatformName}#',
  version: require('../../package.json').version + '#{Cms.VersionSuffix}#',
  environment: '#{Cms.EnvironmentName}#',
  expenseClaimStorageUrl: '#{Cms.ExpenseClaimStorageUrl}#',
  videoStorageUrl: '#{Cms.VideoStorageUrl}#',
  videoBaseUrl: '#{Cms.VideoBaseUrl}#',
  timelineImageStorageUrl: '#{Cms.TimelineImageBaseUrl}#',
  timelineImageBaseUrl: '#{Cms.TimelineImageStorageUrl}#',
  muvApiBaseUrl: '#{Cms.MuvApiBaseUrl}#',
  defaultCoordinatorEmail: '#{Cms.DefaultCoordinatorEmail}#',
  msalConfig: {
    redirectUri: '#{Cms.MsalRedirectUrl}#',
    clientId: '#{Cms.MsalClientId}#',
    tenantId: '#{Cms.MsalTenantId}#',
    scope: '#{Cms.MsalScope}#'
  }
};
