import { SearchPage } from "./search-page.model";
import { FeedbackListItem } from "./FeedbackListItem";

export class FeedbackSearch extends SearchPage {
  public results: FeedbackListItem[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(new FeedbackListItem(
        result.patientTrialId,
        result.patientId,
        result.patientCode,
        result.trialCode,
        result.trialNickname,
        result.trialOpportunityNumber,
        result.date,
        result.rating
      ));
    }

    return this;
  }
}
