<div class="back-btn">

  <div class="buttons-right">
    <button class="btn btn-outline btn-small" (click)="onShowCancelRideOptions()" *ngIf="ride !== undefined && ride !== null && shouldShowCancelRideButton() && !showCancelRideOptions">Cancel MUV Ride</button>

    <div class="cancel-ride-confirmation" *ngIf="showCancelRideOptions">
      <button class="btn btn-primary btn-small" (click)="onCancelRide()">
       <span *ngIf="cancellingRide">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>
        <span *ngIf="!cancellingRide">Yes, cancel ride</span>
      </button>
      <button class="btn btn-secondary btn-small" (click)="onHideCancelRideOptions()">No</button>
      <p>Please confirm you wish to cancel this MUV ride?</p>
    </div>

    <div *ngIf="shouldShowAcceptDeclineButtons()" class="accept-reject">
      <button class="btn btn-success btn-small" (click)="acceptQuoteModal.show()">Accept Quote</button>
      <button class="btn btn-primary btn-small" (click)="rejectQuoteModal.show()">Reject Quote</button>
    </div>
  </div>

  <button class="btn btn-secondary btn-small" (click)="onBackToList()">< Back to MUV Rides</button>
</div>

<div class="view-ride-detail" *ngIf="ride !== undefined && ride !== null">

  <div class="flex-row">
    <div class="flex-col">
      <label>Status</label>
      <app-muv-ride-state-badge [state]="ride.state"></app-muv-ride-state-badge>
    </div>

    <div class="flex-col">
      <label>Date Requested</label>
      <span>{{ ride.created | date: 'd MMM yyyy' }}</span>
    </div>

    <div class="flex-col">
      <label>Amount Quoted</label>
      <span>{{ ride.amountQuoted === null ? '-' : ride.amountQuoted + ' USD' }}</span>
    </div>

    <div class="flex-col">
      <label>Date Updated</label>
      <span>{{ ride.updated | date: 'd MMM yyyy' }}</span>
    </div>
  </div>

  <div class="row-spacer"></div>

  <div class="flex-row">
    <div class="flex-col">
      <label>Request Type</label>
      <span>{{ requestTypeDisplayLabel(ride.requestType) }}</span>
    </div>

    <div class="flex-col">
      <label>Ride Type</label>
      <span>{{ rideTypeDisplayLabel(ride.rideType) }}</span>
    </div>

    <div class="flex-col">
      <label>Vehicle Type</label>
      <span>{{ vehicleDisplayLabel(ride.vehicleType) }}</span>
    </div>

    <div class="flex-col">
      <label>No Of Passengers</label>
      <span>{{ ride.noOfPassengers }}</span>
    </div>

    <div class="flex-col" *ngIf="ride.rideType.toLowerCase() === 'hourly'">
      <label>Hours Needed</label>
      <span>{{ ride.hoursNeeded }}</span>
    </div>

  </div>

  <div class="row-spacer"></div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <label>Pickup Date / Time</label>
      <span>{{ ride.pickupDate | date: 'd MMM yyyy HH:mm' }}</span>
    </div>

    <div class="col-12 col-lg-6">

    </div>
  </div>

  <div class="row-spacer"></div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <label>Pickup Location</label>

      <span *ngIf="ride.pickupLandmark !== null && ride.pickupLandmark !== ''" style="display: block;">{{ ride.pickupLandmark }}</span>
      <span [innerHtml]="displayAddress(ride.pickupAddress)" class="address"></span>

      <div class="airport-info" *ngIf="ride.pickupAirportCode">
        <div class="row-spacer"></div>
        <div class="row">
          <div class="col-4">
            <label>Airport Code</label>
            <span>{{ ride.pickupAirportCode }}</span>
          </div>

          <div class="col-4">
            <label>Airline</label>
            <span>{{ airlineDisplayName(ride.pickupAirlineId) }}</span>
          </div>

          <div class="col-4">
            <label>Flight Number</label>
            <span>{{ ride.pickupFlightNumber }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <label>Dropoff Location</label>

      <span *ngIf="ride.dropoffLandmark !== null && ride.dropoffLandmark !== ''" style="display: block;">{{ ride.dropoffLandmark }}</span>
      <span [innerHtml]="displayAddress(ride.dropOffAddress)" class="address"></span>

      <div class="airport-info" *ngIf="ride.dropOffAirportCode">
        <div class="row-spacer"></div>
        <div class="row">
          <div class="col-4">
            <label>Airport Code</label>
            <span>{{ ride.dropOffAirportCode }}</span>
          </div>

          <div class="col-4">
            <label>Airline</label>
            <span>{{ airlineDisplayName(ride.dropOffAirlineId) }}</span>
          </div>

          <div class="col-4">
            <label>Flight Number</label>
            <span>{{ ride.dropOffFlightNumber }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row-spacer"></div>

  <div class="row">
    <div class="col-12">
      <label>Special Instructions</label>
      <span>{{ ride.specialInstructions }}</span>
    </div>
  </div>

  <div class="row-spacer"></div>

  <div class="row">
    <div class="col-12">
      <div class="stops-heading">
        <h3>Stops</h3>
      </div>

      <table class="stops-table" aria-hidden="true" *ngIf="ride.stops.length > 0">
        <thead>
        <tr>
          <th>Apt</th>
          <th>Street</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Country</th>
          <th>Pickup Time</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stop of ride.stops;">
          <td>{{ stop.address.apt }}</td>
          <td>{{ stop.address.street }}</td>
          <td>{{ stop.address.city }}</td>
          <td>{{ stop.address.state }}</td>
          <td>{{ stop.address.zip }}</td>
          <td>{{ stop.address.countryName }}</td>
          <td>{{ stopPickupTimeDisplayLabel(stop.pickupTimeMinutes) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<!-- Start: Accept Quote Modal -->
<app-modal #acceptQuoteModal customClassName="confirmation-modal">
  <div class="modal-header">
    <h2 class="modal-title">Accept Quote?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to accept this quote</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="acceptQuoteModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmAcceptQuote()" class="btn btn-success">
          <span *ngIf="acceptingRejectingQuote">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!acceptingRejectingQuote">Yes, accept quote</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Accept Quote Modal -->

<!-- Start: Reject Quote Modal -->
<app-modal #rejectQuoteModal customClassName="confirmation-modal">
  <div class="modal-header">
    <h2 class="modal-title">Reject Quote?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to reject this quote</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="rejectQuoteModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmRejectQuote()" class="btn btn-primary">
          <span *ngIf="acceptingRejectingQuote">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!acceptingRejectingQuote">Yes, reject quote</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Reject Quote Modal -->
