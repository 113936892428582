import moment from "moment";
import {environment} from "../../../environments/environment";

export class TimelineEvent {
  public id: string;
  public originalDate: Date;
  public date: string;
  public heading: string;
  public description: string;
  public boxType: string;
  public imageFilename: string;
  public imageUrl: string;
  public state: string;

  public map(obj: any): TimelineEvent {
    this.id = obj.id;
    this.originalDate = obj.originalDate;
    this.date = moment(obj.date).format('DD.MM.YYYY');
    this.heading = obj.heading;
    this.description = obj.description;
    this.boxType = obj.type;
    this.imageFilename = obj.imageFilename;
    this.state = 'unchanged';

    if (this.imageFilename !== null && this.imageFilename !== undefined && this.imageFilename !== '') {
      this.imageUrl = environment.timelineImageBaseUrl + this.imageFilename;
    }

    return this;
  }

  public shortDescriptionHtml(): string {
    let shortDescription = this.description;
    if (shortDescription.length > 200) {
      shortDescription = this.description.substring(0, 200) + ' ...<span class="more"> Read more</span>';
    }

    return shortDescription.replace(/\n/g, '<br>');
  }
}
