<div [formGroup]="parentForm" class="select-field" [ngStyle]="{'min-width': minWidth ? minWidth : 'auto', 'max-width': maxWidth ? maxWidth : 'none'}">

  <span class="not-editable" *ngIf="!editable">{{ parentForm.get(controlName).value }}</span>

  <mat-form-field appearance="fill" *ngIf="editable">
    <mat-select (selectionChange)="onValueChanged($event)" [formControlName]="controlName"
                [multiple]="false"
                [placeholder]="inputPlaceholder"
                panelClass="hide-checkbox">

      <div class="filter">
        <input type="text" placeholder="Filter currencies..." #filterInput (keyup)="onFilterInputChanged($event)">
      </div>

      <mat-option *ngFor="let currency of filteredCurrencyOptions" [value]="currency.value">
        {{ currency.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
