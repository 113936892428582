import { enumToText } from 'app/core/helpers/enum-to-text.function';
import { AlertService } from './../../../shared/alert/alert.service';
import {
  GroundTransportTravelType,
  OverBudgetRequestForTripsRequest,
  PatientTripDirection
} from './../../../core/models/patient-trip.model';
import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OverBudgetRequestDetailsForTripsViewModel, PatientTripType } from 'app/core/models/patient-trip.model';
import { VisitService } from 'app/core/services/visit.service';
import { ModalViewport } from 'app/shared/modal-v2/modal-viewport';
import { TabItem } from 'app/shared/tabulated-content/tab-item.model';
import { CaregiverTravelling } from 'app/core/models/expense-check.model';
import { ModalV2Component } from 'app/shared/modal-v2/modal-v2.component';
import { TabulatedContentComponent } from 'app/shared/tabulated-content/tabulated-content.component';

@Component({
  selector: 'app-trips-over-budget-request-modal',
  templateUrl: './trips-over-budget-request-modal.component.html',
  styleUrl: './trips-over-budget-request-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TripsOverBudgetRequestModalComponent {
  @Output() overBudgetRequestRaised: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;
  submitAvailable: boolean = false;

  visitId: string;
  overBudgetRequestDetailsForTripsViewModel: OverBudgetRequestDetailsForTripsViewModel;
  overBudgetRequestForms: Record<string, FormGroup>;
  loading: boolean = false;
  viewPort: ModalViewport = ModalViewport.Large;

  PatientTripType = PatientTripType;
  GroundTransportTravelType = GroundTransportTravelType;
  CaregiverTravelling = CaregiverTravelling;
  PatientTripDirection = PatientTripDirection;
  isAnyFormInvalid: boolean = false;

  tabs: TabItem[] = [];

  constructor(private visitService: VisitService, private alertService: AlertService) { }

  submitOverBudgetRequest() {
    this.loading = true;
    let request = Object.values(this.overBudgetRequestForms).map(f => f.value as OverBudgetRequestForTripsRequest);

    this.visitService.createOverBudgetRequestForTrips(request).subscribe({
      next: () => {
        this.overBudgetRequestRaised.emit(this.visitId);
        this.loading = false;
        this.alertService.showSuccessAlert("Over budget request successfully raised.");
        this.hideModal();
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  hideModal() {
    this.modal.hide();
  }

  show() {
    this.overBudgetRequestForms = {};
    this.tabs = [];
    this.overBudgetRequestDetailsForTripsViewModel.tripTypeGroups.forEach(g => {
      this.tabs.push(new TabItem({ title: `${enumToText(PatientTripType, g.type)}`, visible: true }))

      g.subGroups.forEach(gg => {

        const key = `${g.type}${gg.groundTransportTravelType}`;
        let formGroup = new FormGroup({
          tripIds: new FormControl(gg.tripsDetails.map(t => t.tripId).join(",")),
          budgetCost: new FormControl(gg.budgetCost, Validators.min(0)),
          requiredInformation: new FormControl(gg.requiredInformationText),
          currency: new FormControl(gg.budgetCurrency),
          totalActualCost: new FormControl(gg.actualCostInBudgetCurrency)
        })

        if (gg.specificCategoryTrialPolicyText) {
          formGroup.get('requiredInformation').setValidators(Validators.required);
          formGroup.updateValueAndValidity();

          formGroup.get('requiredInformation').statusChanges.subscribe(val => {
            if (val === 'INVALID') {
              this.isAnyFormInvalid = true;
              this.tabulatedContent.addTabClass(`${enumToText(PatientTripType, g.type)}`, 'invalid');
            } else {
              this.isAnyFormInvalid = false;
              this.tabulatedContent.removeTabClass(`${enumToText(PatientTripType, g.type)}`, 'invalid');
            }
          });

        }

        this.overBudgetRequestForms[key] = formGroup;
      })
    });

    this.modal.show();

    setTimeout(() => {
      this.tabulatedContent.switchTab(0);
    }, 50);
  }
}
