<div *ngIf="results.totalRecordCount === 0">

  <div class="main-container std-results">
    <div class="container">
      <div class="row">

        <div class="no-results">
          <div class="inner">
            <div class="image">
              <img src="assets/img/no-sites.png" alt="" />
            </div>
            <div class="content">
              <h2>No Expense Categories, Yet</h2>
              <p>Your created expense categories will appear here.</p>

              <a routerLink="/category/add" class="btn btn-primary" *ngIf="authService.hasPermission(Permissions.ExpenseCategoryAdd)">+ Add New Category</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<div *ngIf="results.totalRecordCount > 0">

  <div class="view-head">
    <div class="row">
      <div class="col-12 d-none d-lg-block col-lg-6">
        <h2>Expense Categories</h2>
      </div>

      <div class="col-12 col-lg-6 text-right">
        <div class="button-container">
          <a routerLink="/category/add" class="btn btn-primary" *ngIf="authService.hasPermission(Permissions.ExpenseCategoryAdd)">+ Add New Category</a>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <div class="content-panel">
      <table class="std-results-list">
        <tbody>
        <tr data-toggle-class="more-btn" *ngFor="let category of results.results">
          <td> {{ category.name }}</td>
          <td class="text-right">
            <a (click)="onDeleteCategory(category.id)" class="btn btn-outline btn-delete" *ngIf="authService.hasPermission(Permissions.ExpenseCategoryDelete) && category.canDelete">Delete</a>
            <a routerLink="/category/{{ category.id }}/edit" class="btn btn-secondary btn-edit" *ngIf="authService.hasPermission(Permissions.ExpenseCategoryEdit)">Edit</a>
          </td>
        </tr>
        </tbody>
      </table>

      <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount" (pageSelected)="loadCategories($event)"></app-pagination>
    </div>
  </div>

</div>

<!-- Start: Delete category modal -->
<app-modal #deleteCategoryModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Expense Category?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to delete this category?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteCategoryModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmDeleteCategory()" class="btn btn-primary">
          <span *ngIf="categoryIsDeleting">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!categoryIsDeleting">Yes, delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete category modal -->
