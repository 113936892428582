import { AbstractControl, Validators } from "@angular/forms";

export class ComponentBase {

  /**
   * Checks if a control is required
   *
   * @param {AbstractControl} control - The control to check
   *
   * @return {boolean} - True if the control has the required validator, false otherwise
   */
  isRequired(control: AbstractControl): boolean {
    if (control) {
      return control.hasValidator(Validators.required);
    }
    return false;
  }
}
