<div id="trialVisitSchedule" class="details-view">
  <div class="details-view-results">
    <div class="details-view-results-head">
      <p>Add Visits</p>
      <input type="number" #addVisitsAmount>
      <button class="btn btn-primary" (click)="addVisits(addVisitsAmount.value)">Add</button>
    </div>

    <div *ngIf="visits.length" cdkDropList class="visit-list" (cdkDropListDropped)="drop($event)">
      <div class="visit-box" *ngFor="let visit of visits" cdkDrag><i class="fa fa-arrows"></i>
        <input #visitTitle class="visit-title" [value]="visit.title" (blur)="updateVisitTitle(visit, visitTitle.value)">
        <button class="remove-button" (click)="removeVisit(visit)">+</button>
        <div *cdkDragPreview class="visit-box-preview">{{visit.title}}</div>
      </div>
    </div>

    <div class="details-view-results-footer">
      <button class="btn btn-primary" (click)="save()">Save</button>
    </div>

  </div>
</div>
