import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { SelectOption } from "../../core/models/select-option.model";
import { UntypedFormGroup } from "@angular/forms";
import { debounce } from "lodash";

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputSelectComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  @Input('items') items: SelectOption[] = [];
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('searchInputPlaceholder') searchInputPlaceholder: string;
  @Input('allowFiltering') allowFiltering = false;
  @Input('allowClear') allowClear = false;
  @Input('panelClass') panelClass: string;

  @Output('onFilter') onFilter = new EventEmitter<string>();

  showClear = false;

  ngAfterViewInit() {
    if (this.parentForm && this.parentForm.get(this.controlName)) {
      let initValue = this.parentForm.get(this.controlName).value;

      if (initValue !== undefined && initValue !== null && initValue !== '') {
        this.showClear = true;
      }

      this.parentForm.get(this.controlName).valueChanges.subscribe(value => {
        this.setClearVisibility(value);
      });

      setTimeout(() => {
        this.setClearVisibility(this.parentForm.get(this.controlName).value);
      }, 1000);
    }
  }

  private setClearVisibility(value: string): void {
    this.showClear = value !== undefined && value !== null && value !== '';
  }

  onSearchInputChanged = debounce(() => {
    this.onFilter.emit(this.searchInput.nativeElement.value);
  }, 300);

  onClear(): void {
    this.parentForm.patchValue({ [this.controlName]: null });
  }
}
