import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-panel',
  templateUrl: './report-panel.component.html',
  styleUrls: ['./report-panel.component.scss']
})
export class ReportPanelComponent {
  @Input('iconClass') iconClass: string;
  @Input('label') label: string;
  @Input('value') value: number;
  @Input('altTheme') altTheme = false;
  @Input('secondaryValue') secondaryValue: string;
}
