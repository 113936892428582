import { Action } from '@ngrx/store';

export enum NavigationActionType {
  UPDATE_LAST_ROUTE = '[App] Update Last Route',
}

export class UpdateLastRouteAction implements Action {
   readonly type = NavigationActionType.UPDATE_LAST_ROUTE;

   constructor(public payload: string) {}
}
