import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LogHelper } from 'app/core/helpers/log.helper';
import { TrialService } from 'app/core/services/trial.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { MileageRateListItemViewModel, DistanceUnit, DistanceUnitString, SetDefaultPolicyDistanceUnitRequest } from '../../trial-policy.model';
import { CreateMileageRateRuleModalComponent } from '../create-mileage-rate-rule-modal/create-mileage-rate-rule-modal.component';
import { UpdateMileageRateRuleModalComponent } from '../update-mileage-rate-rule-modal/update-mileage-rate-rule-modal.component';

@Component({
  selector: 'app-trial-policy-mileage-rates',
  templateUrl: './trial-policy-mileage-rates.component.html',
  styleUrls: ['./trial-policy-mileage-rates.component.scss']
})
export class TrialPolicyMileageRatesComponent implements OnInit {
  @ViewChild("distanceUnitSelect") distanceUnitSelect: DropdownInputComponent;
  @Input() policyId: string;
  @Input() trialId: string;
  mileageRateRules: MileageRateListItemViewModel[] = [];
  saveProcessing: boolean = false;
  defaultCurrency: string;

  distanceUnits: { value: string, text: string }[] = [];

  @ViewChild("createMileageRatesRuleModal") createMileageRatesRuleModal: CreateMileageRateRuleModalComponent;
  @ViewChild("updateMileageRatesRuleModal") updateMileageRatesRuleModal: UpdateMileageRateRuleModalComponent;

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.getMileageRatesRules();
    this.initiateDistanceUnits();
  }

  initiateDistanceUnits() {
    if (!this.distanceUnits.length) {
      this.distanceUnits.push({ value: DistanceUnit.Km.toString(), text: DistanceUnitString.Values[DistanceUnit.Km] });
      this.distanceUnits.push({ value: DistanceUnit.Mile.toString(), text: DistanceUnitString.Values[DistanceUnit.Mile] });
    }
  }

  getMileageRatesRules() {
    this.trialService.getPolicyMileageRatesRules(this.policyId).subscribe({
      next: response => {
        this.mileageRateRules = response.rates;
        this.defaultCurrency = response.defaultCurrency;
        this.distanceUnitSelect.setValue(response.defaultDistanceUnit?.toString());
      },
      error: err => {
        this.alertService.showWarningAlert("Failed to get policy expense rules. Please try again or contact support.");
        LogHelper.log(err);
      }
    })
  }

  showCreateMileageRateRuleModal() {
    this.createMileageRatesRuleModal.defaultDistanceUnit = this.distanceUnitSelect.selectedValue;
    this.createMileageRatesRuleModal.defaultCurrency = this.defaultCurrency;
    this.createMileageRatesRuleModal.show();
  }

  saveDefaultCurrency() {
    this.saveProcessing = true;
    let request: SetDefaultPolicyDistanceUnitRequest = {
      policyId: this.policyId,
      distanceUnit: DistanceUnit[this.distanceUnitSelect.selectedValue as keyof typeof DistanceUnit]
    }

    this.trialService.setPolicyMileageRateDistanceUnit(request).subscribe({
      next: () => {
        this.saveProcessing = false;
        this.alertService.showSuccessAlert("Default distance unit successfully changed.");
      },
      error: err => {
        this.saveProcessing = false;
        this.alertService.showWarningAlert("Failed to change default distance unit. Please try again or contact support.");
        LogHelper.log(err);
      }
    })
  }

  openUpdateRuleModal(rule: MileageRateListItemViewModel) {
    this.updateMileageRatesRuleModal.rule = rule;
    this.updateMileageRatesRuleModal.policyId = this.policyId;
    this.updateMileageRatesRuleModal.show();
  }
}
