<!-- Start: Site contacts -->
<div class="admin-users">

  <div class="row">
    <div class="col-12 text-right">
      <a routerLink="/admin/contact/add" class="btn btn-outline btn-outline-slim" *ngIf="authService.hasPermission(Permissions.SiteContactWrite)">+ Add Site Contact</a>
    </div>
  </div>

  <table class="with-heading-columns">
    <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Trials/Sites</th>
      <th>Status</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngIf="dataLoaded">
    <tr *ngFor="let siteContact of results.results; let i = index">
      <td>{{ siteContact.firstname + ' ' + siteContact.lastname }}</td>
      <td>{{ siteContact.email }}</td>
      <td>
        <ul>
          <li *ngFor="let site of siteContact.sites">{{ site.trialLabel }} / {{ site.irgSiteId }}</li>
        </ul>
      </td>
      <td><span class="badge" [ngClass]="{'badge-success': siteContact.isActive, 'badge-warning': !siteContact.isActive}">{{ siteContact.isActive ? 'Active' : 'Inactive' }}</span></td>
      <td class="btns" [class.site-contact-btns]="authService.hasPermission(Permissions.SendSiteContactRegistrationLink)">
        <a [routerLink]="['/admin/contact/edit', siteContact.id]" class="btn btn-secondary btn-slim btn-auto-width" *ngIf="authService.hasPermission(Permissions.SiteContactWrite)">Edit</a>
        <a (click)="onDeleteSiteContact(siteContact.id)" class="btn btn-outline btn-slim btn-auto-width" data-toggle="modal" data-target="#delete-user-modal" *ngIf="authService.hasPermission(Permissions.SiteContactWrite)">Delete</a>
        <button class="btn btn-slim btn-primary" (click)="sendPatientRegistrationMail(siteContact.id, i)" *ngIf="authService.hasPermission(Permissions.SendSiteContactRegistrationLink)">
          <span *ngIf="buttonProcessing[i]">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!buttonProcessing[i]">Send Patient Registration Link</span></button>
      </td>
    </tr>
    </tbody>

    <tbody *ngIf="!dataLoaded">
      <tr *ngFor="let siteContact of loaderArray">
        <td style="width: 25%;"><ngx-skeleton-loader class="d-block" style="width: 80%;"></ngx-skeleton-loader></td>
        <td style="width: 20%;"><ngx-skeleton-loader class="d-block" style="width: 80%;"></ngx-skeleton-loader></td>
        <td style="width: 27%;">
          <ngx-skeleton-loader class="d-block" style="width: 80%;"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="d-block" style="width: 80%;"></ngx-skeleton-loader>
        </td>
        <td style="width: 8%;"><ngx-skeleton-loader class="d-block" style="width: 80%;"></ngx-skeleton-loader></td>
        <td style="width: 100%;" class="d-flex align-items-end flex-column">
          <ngx-skeleton-loader style="width: 15%;"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="width: 30%;"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="width: 100%;"></ngx-skeleton-loader>
        </td>
      </tr>
      </tbody>

  </table>

</div>

<app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount" (pageSelected)="onChangePage($event)"></app-pagination>

<div class="no-results inside" *ngIf="!hasResults && dataLoaded">
  <div class="inner">
    <div class="image">
      <img src="assets/img/no-patients.png" alt="No site contacts" />
    </div>
    <div class="content">
      <h2>No Site Contacts</h2>
    </div>
  </div>
</div>
<!-- End: Site contacts -->

<!-- Start: Delete user modal -->
<app-modal #deleteSiteContactModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Site Contact?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this site contact?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteSiteContactModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onDeleteConfirmed()" class="btn btn-primary">
          <span *ngIf="siteContactIsBeingDeleted">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!siteContactIsBeingDeleted">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete user modal -->
