import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TrialBookingConfigItem } from "../../../../core/models/trial-booking-config-item.model";
import { TrialBookingConfigModalComponent } from "./trial-booking-config-modal/trial-booking-config-modal.component";
import { AlertService } from "../../../../shared/alert/alert.service";
import { TrialBookingService } from "../../../../core/services/trial-booking.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";

@Component({
  selector: 'app-trial-bookings-config',
  templateUrl: './trial-bookings-config.component.html',
  styleUrls: ['./trial-bookings-config.component.scss']
})
export class TrialBookingsConfigComponent implements OnInit, AfterViewInit {
  @ViewChild('configModal') configModal: TrialBookingConfigModalComponent;
  @ViewChild('deleteConfigModal') deleteConfigModal: ModalComponent;

  @Input() trialId: string;

  @Output() back = new EventEmitter();

  configurations: TrialBookingConfigItem[] = [];

  configToDelete: TrialBookingConfigItem;
  deleteProcessing = false;

  constructor(private _alertService: AlertService, private _trialBookingService: TrialBookingService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadConfigurations();
  }

  /**
   * Called when the user clicks to go back to the list of appointments
   */
  onHandleBack(): void {
    this.back.emit();
  }

  onDelete(config: TrialBookingConfigItem): void {
    this.configToDelete = config;
    this.deleteConfigModal.show();
  }

  onConfirmDelete(): void {
    if (this.configToDelete !== undefined && this.configToDelete !== null) {
      this.deleteProcessing = true;
      this._trialBookingService.deleteConfiguration(this.trialId, this.configToDelete.id).subscribe({
        next: () => {
          this.deleteConfigModal.hide();
          this.configToDelete = null;
          this.deleteProcessing = false;
          this._alertService.showSuccessAlert('Booking configuration deleted.');
          this.loadConfigurations();
        },
        error: error => {
          this.deleteProcessing = false;
          this._alertService.showErrorAlert(error);
        }
      });
      this.deleteConfigModal.hide();
    }
  }

  /**
   * Loads booking configurations for the trial.
   */
  loadConfigurations(): void {
    this._trialBookingService.getBookingConfigurationsForTrial(this.trialId).subscribe({
      next: configs => {
        this.configurations = configs;
      },
      error: error => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

}
