<div class="back-btn">
  <button class="btn btn-primary btn-small" (click)="onFormSubmit()" [disabled]="!form.valid">
    <span *ngIf="form.get('processing').value">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>
    <span *ngIf="!form.get('processing').value">Request MUV Ride</span>
  </button>
  <button class="btn btn-secondary btn-small" (click)="onCancel()">< Back to MUV Rides</button>
</div>

<div class="form-container" [class.processing]="form.get('processing').value === true">
  <form [formGroup]="form" (mousemove)="onMouseEnterForm()">
    <div class="flex-row">
      <div class="flex-col">
        <div class="form-group">
          <label>Request Type <sup>*</sup></label>
          <app-input-select [items]="requestTypes" [parentForm]="form" controlName="requestType" inputPlaceholder="Select request type..."></app-input-select>
        </div>
      </div>
      <div class="flex-col">
        <div class="form-group">
          <label>Ride Type <sup>*</sup></label>
          <app-input-select [items]="rideTypes" [parentForm]="form" controlName="rideType" inputPlaceholder="Select ride type..."></app-input-select>
        </div>
      </div>
      <div class="flex-col">
        <div class="form-group">
          <label>Vehicle Type <sup>*</sup></label>
          <app-input-select [items]="vehicles" [parentForm]="form" controlName="vehicleType" [inputPlaceholder]="this.vehicles.length > 0 ? 'Select vehicle type...' : 'Loading...'"></app-input-select>
        </div>
      </div>
      <div class="flex-col">
        <div class="form-group">
          <label>No Of Passengers {{passengersLabel}} <sup>*</sup></label>
          <input type="number" formControlName="passengers">
        </div>
      </div>
      <div class="flex-col" *ngIf="form.get('rideType').value === 'hourly'">
        <div class="form-group">
          <label>Hours Needed <sup>*</sup></label>
          <input type="number" formControlName="hoursNeeded">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="departure-container">
          <a class="swap-btn" (click)="onSwapLocations()"><i class="far fa-arrow-right-arrow-left"></i></a>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>Pickup Date <sup>*</sup></label>
                <input #pickupDate type="text" class="date white-bg" data-toggle="datepicker" formControlName="pickupDate"
                       placeholder="Select Date...">
              </div>
            </div>

            <div class="col-3">
              <div class="form-group type-time">
                <label>Pickup Time <sup>*</sup></label>
                <mat-form-field>
                  <input type="time" matInput formControlName="pickupTime">
                </mat-form-field>
              </div>
            </div>

            <div class="col-5">
              <div class="location-buttons">
                <button class="btn btn-outline btn-small add-location" (click)="togglePickupAirport()">{{ showPickupAirport ? '- Airport' : '+ Airport' }}</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Pickup Location</label>

                <div [ngClass]="{'visibility-hidden': showPickupAirport}">
                  <div class="form-group">
                    <input type="text" formControlName="pickupLandmark" placeholder="Hospital name or landmark...">
                  </div>

                  <app-input-address #pickupLocationInput [parentForm]="form" controlName="pickupLocation" [aptRequired]="false" [stateRequired]="true" [streetRequired]="true" [zipRequired]="true" [countryRequired]="true"></app-input-address>
                </div>
              </div>
            </div>
          </div>

          <div class="row pickup-airport-location" [ngClass]="{'visibility-hidden': !showPickupAirport}">
            <div class="col-4">
              <div class="form-group">
                <label>Airport Code <sup>*</sup></label>
                <app-input-select panelClass="size-medium" (onFilter)="onFilterPickupAirports($event)" [items]="filteredPickupAirports" [parentForm]="form" controlName="pickupAirportCode" inputPlaceholder="Select airport..." [allowFiltering]="true" searchInputPlaceholder="Search for airport..."></app-input-select>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Airline <sup>*</sup></label>
                <app-input-select panelClass="size-medium" (onFilter)="onFilterPickupAirlines($event)" [items]="filteredPickupAirlines" [parentForm]="form" controlName="pickupAirlineId" inputPlaceholder="Select airline..." [allowFiltering]="true"></app-input-select>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Flight Number <sup>*</sup></label>
                <input type="text" formControlName="pickupFlightNumber">
              </div>
            </div>

            <div class="col-12">
              <label style="padding-left: 16px;">Airport Meeting Procedure</label>

              <div class="radio-group">
                <label *ngFor="let procedure of pickupAirportMeetingProcedures">
                  <input type="radio" formControlName="pickupAirportMeetingProcedure" [value]="procedure.value" />
                  {{ procedure.text}}
                </label>

                <p *ngIf="pickupAirportMeetingProcedures.length === 0">No meeting procedures available.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12 col-md-6">
        <div class="arrival-container">
          <div class="row">
            <div class="col-4">
            </div>

            <div class="col-3">
            </div>

            <div class="col-5">
              <div class="location-buttons">
                <button class="btn btn-outline btn-small add-location dropoff-airport" (click)="toggleDropoffAirport()">{{ showDropoffAirport ? '- Airport' : '+ Airport' }}</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Dropoff Location</label>

                <div [ngClass]="{'visibility-hidden': showDropoffAirport}">
                  <div class="form-group">
                    <input type="text" formControlName="dropoffLandmark" placeholder="Hospital name or landmark...">
                  </div>

                  <app-input-address #dropoffLocationInput [parentForm]="form" controlName="dropoffLocation" [aptRequired]="false" [stateRequired]="true" [streetRequired]="true" [zipRequired]="true" [countryRequired]="true"></app-input-address>
                </div>
              </div>
            </div>
          </div>

          <div class="row dropoff-airport-location" [ngClass]="{'visibility-hidden': !showDropoffAirport}">
            <div class="col-4">
              <div class="form-group">
                <label>Airport Code <sup>*</sup></label>
                <app-input-select (onFilter)="onFilterDropoffAirports($event)" [items]="filteredDropoffAirports" [parentForm]="form" controlName="dropoffAirportCode" inputPlaceholder="Select airport..." [allowFiltering]="true" searchInputPlaceholder="Search for airport..."></app-input-select>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Airline</label>
                <app-input-select panelClass="size-medium" (onFilter)="onFilterDropoffAirlines($event)" [items]="filteredDropoffAirlines" [parentForm]="form" controlName="dropoffAirlineId" inputPlaceholder="Select airline..." [allowFiltering]="true"></app-input-select>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Flight Number</label>
                <input type="text" panelClass="size-medium" formControlName="dropoffFlightNumber">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group special-instructions">
          <label>Special Instructions</label>
          <textarea class="form-control" formControlName="specialInstructions"></textarea>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="form-group patient-telephone">
          <label>Patient Telephone <sup>*</sup></label>
          <ngx-mat-input-tel
            [preferredCountries]="['us']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            name="patientTelephone"
            formControlName="patientTelephone"
          ></ngx-mat-input-tel>
        </div>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-12">
      <div class="stops-heading">
        <button class="btn btn-outline btn-small" (click)="addStop()">+ Add Stop</button>
        <h3>Stops</h3>
      </div>

      <table class="stops-table" aria-hidden="true" *ngIf="stops.length > 0">
        <thead>
        <tr>
          <th>Apt</th>
          <th>Street</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Country</th>
          <th>Arrival Time</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stop of stops; let i = index" [formGroup]="stop">
          <td><input type="text" formControlName="apt" placeholder="Apt..."></td>
          <td><sup class="stop-asterix">*</sup><input type="text" formControlName="street" placeholder="Street..."></td>
          <td><sup class="stop-asterix">*</sup><input type="text" formControlName="city" placeholder="City..."></td>
          <td><sup class="stop-asterix">*</sup><input type="text" formControlName="state" placeholder="State..."></td>
          <td><sup class="stop-asterix">*</sup><input type="text" formControlName="zip" placeholder="Zip..."></td>
          <td><sup class="stop-asterix">*</sup><app-input-select [allowClear]="false" (onFilter)="filterCountries($event)" [items]="filteredCountries" [parentForm]="stop" controlName="country" inputPlaceholder="Country..." [allowFiltering]="true" searchInputPlaceholder="Search countries..."></app-input-select></td>
          <td>
            <div class="form-group type-time">
              <sup class="stop-asterix">*</sup>
              <mat-form-field>
                <input type="time" matInput formControlName="pickupTime">
              </mat-form-field>
            </div>
          </td>
          <td><a (click)="removeStop(i)"><i class="far fa-times"></i></a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

