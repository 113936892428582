<div class="tab-pane fade show active details-view-results">
  <form (submit)="onFormSubmit()" [formGroup]="form">
    <div class="patient-additional-details">
      <div class="row">
        <div class="col-12 col-lg-6 col-left">
          <h2>Passport</h2>

          <table aria-hidden="true">
            <tr>
              <th><label class="label">Name on Passport</label></th>
              <td><input type="text" class="form-control" formControlName="passportName"></td>
            </tr>
            <tr>
              <th><label class="label">Passport Number</label></th>
              <td><input type="text" class="form-control" formControlName="passportNumber"></td>
            </tr>
            <tr>
              <th><label class="label">Expiry Date (yyyy/mm/dd)</label></th>
              <td><input #passportExpiry [dropSpecialCharacters]="false" [showMaskTyped]="true" class="date"
                         formControlName="passportExpiry"
                         mask="0000/00/00" placeHolderCharacter="*" type="text"></td>
            </tr>
            <tr>
              <th><label class="label">Date of Birth (yyyy/mm/dd)</label></th>
              <td><input #dateOfBirth [dropSpecialCharacters]="false" [showMaskTyped]="true" class="date"
                         formControlName="dateOfBirth"
                         mask="0000/00/00" placeHolderCharacter="*" type="text"></td>
            </tr>
            <tr>
              <th class="align-top"><label class="label">Visa Information</label></th>
              <td><textarea class="form-control" formControlName="visaInfo"></textarea></td>
            </tr>
            <tr>
              <th class="align-top"><label class="label">Other Notes</label></th>
              <td><textarea class="form-control" formControlName="otherNotes"></textarea></td>
            </tr>
          </table>

          <div *ngIf="patient.customDataFields.length > 0">
            <h2>3rd Party Info</h2>
            <app-custom-fields [form]="form" [customFields]="patient.customDataFields"></app-custom-fields>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-right">
          <h2 class="top-padding">Contact Information</h2>

          <table aria-hidden="true">
            <tr>
              <th><label class="label">Phone Number</label></th>
              <td>
                <ngx-mat-input-tel
                  [preferredCountries]="['us']"
                  [enablePlaceholder]="true"
                  [enableSearch]="true"
                  name="phone"
                  formControlName="phone"
                ></ngx-mat-input-tel>
              </td>
            </tr>
            <tr>
            <tr>
              <th><label class="label">Next of Kin Name</label></th>
              <td><input type="text" class="form-control" formControlName="nextOfKin"></td>
            </tr>
            <tr>
              <th><label class="label">Next of Kin Phone Number</label></th>
              <td><input type="text" class="form-control" formControlName="nextOfKinPhone"></td>
            </tr>
            <tr>
              <th class="align-top"><label class="label">Caregiver Details</label></th>
              <td><textarea class="form-control" formControlName="caregiverDetails"></textarea></td>
            </tr>
            <tr>
              <th></th>
              <td>
                <div class="checkbox-wrap last">
                  <div class="form-group">
                    <label class="checkbox">
                      <input type="checkbox" id="travellingWithCaregiver" formControlName="travellingWithCaregiver" value="true">
                      <span></span>
                    </label>
                    <label class="checkbox-label" for="travellingWithCaregiver">Travelling with Caregiver</label>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button type="submit" class="btn btn-primary" [disabled]="form.invalid || !form.dirty">
            <span *ngIf="isFormProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
            <span *ngIf="!isFormProcessing">Save</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
