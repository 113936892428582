import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { OverBudgetRequestHistoryItemStatus, OverBudgetRequestHistoryViewModel } from 'app/core/models/project-management.model';
import { ModalComponent } from 'app/shared/modal/modal.component';

@Component({
  selector: 'app-over-budget-request-history-modal',
  templateUrl: './over-budget-request-history-modal.component.html',
  styleUrl: './over-budget-request-history-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OverBudgetRequestHistoryModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  historyItems: OverBudgetRequestHistoryViewModel[];

  OverBudgetRequestHistoryItemStatus = OverBudgetRequestHistoryItemStatus;
}
