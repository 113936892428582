import { CustomData } from "./custom-data.model";
import { PatientTrial } from './patient-trial.model';
import { Address } from "../../shared/input-address/address.model";

export class PatientDetail {
  public id: string;
  public shortId: string;
  public patientCode: string;
  public firstname: string;
  public lastname: string;
  public email: string;
  public externalEmail: string;
  public caxtonCardNumber: string;
  public address: string;
  public country: string;
  public note: string;
  public approvedByName: string;
  public approvedDate: Date;
  public patientState: string;
  public trialId: string;
  public trialCode: string;
  public trialLabel: string;
  public trialName: string;
  public trialBaseCurrency: string;
  public trialAllowActivityCentre: string;
  public trialDesignatedContactIds: string[];
  public passportName: string;
  public passportExpiry: Date;
  public phone: string;
  public dateOfBirth: Date;
  public nextOfKin: string;
  public nextOfKinPhone: string;
  public canBeClaimed: boolean;
  public passportNumber: string;
  public visaInfo: string;
  public otherNotes: string;
  public policyReminders: string;
  public travellingWithCaregiver: boolean;
  public caregiverDetails: string;
  public paymentMethod: string;
  public trialAllowsExpenses: boolean;
  public customDataFields: CustomData[] = [];
  public siteId: string;
  public siteName: string;
  public siteAddress: string;
  public siteCountry: string;
  public consent: string;
  public consentDate: Date;
  public consentType: string;
  public patientNotOnApp: boolean;
  public siteNumber: string;
  public deactivated: boolean;
  public trials: PatientTrial[] = [];
  public activeTrialCodes: string[] = [];
  public homeAddress: Address;
  public bankAccountSelfCardManagementEnabled: boolean;
  public culture: string;
  public patientTrialId: string;
  public apiConsumerId: string;
  public apiConsumerName: string;

  fromObject(obj: any) {
    this.id = obj.id;
    this.shortId = obj.hasOwnProperty('shortId') ? obj.shortId : '';
    this.patientCode = obj.patientCode;
    this.firstname = obj.firstname;
    this.lastname = obj.lastname;
    this.patientState = obj.patientState;
    this.email = obj.email;
    this.externalEmail = obj.externalEmail;
    this.caxtonCardNumber = obj.caxtonCardNumber;
    this.address = obj.address;
    this.country = obj.country;
    this.note = obj.note;
    this.approvedByName = obj.approvedByName;
    this.approvedDate = obj.approvedDate;
    this.trialId = obj.trialId;
    this.trialCode = obj.trialCode;
    this.trialLabel = obj.trialLabel;
    this.trialName = obj.trialName;
    this.trialBaseCurrency = obj.trialBaseCurrency;
    this.trialAllowActivityCentre = obj.trialAllowActivityCentre;
    this.trialDesignatedContactIds = obj.trialDesignatedContactIds;
    this.passportName = obj.passportName;
    this.passportExpiry = obj.passportExpiry != null ? new Date(obj.passportExpiry) : null;
    this.phone = obj.phone;
    this.dateOfBirth = obj.dateOfBirth != null ? new Date(obj.dateOfBirth) : null;
    this.nextOfKin = obj.nextOfKin;
    this.nextOfKinPhone = obj.nextOfKinPhone;
    this.canBeClaimed = obj.canBeClaimed;
    this.passportNumber = obj.passportNumber;
    this.visaInfo = obj.visaInfo;
    this.otherNotes = obj.otherNotes;
    this.policyReminders = obj.policyReminders;
    this.travellingWithCaregiver = obj.travellingWithCaregiver;
    this.caregiverDetails = obj.caregiverDetails;
    this.paymentMethod = obj.paymentMethod;
    this.trialAllowsExpenses = obj.trialAllowsExpenses;
    this.siteId = obj.siteId;
    this.siteName = obj.siteName;
    this.siteAddress = obj.siteAddress;
    this.siteCountry = obj.siteCountry;
    this.consent = obj.consent;
    this.consentType = obj.consentType;
    this.consentDate = obj.consentDate !== null ? new Date(obj.consentDate) : null;
    this.patientNotOnApp = obj.patientNotOnApp;
    this.deactivated = obj.deactivated;
    this.siteNumber = obj.hasOwnProperty('siteNumber') ? obj.siteNumber : '';
    this.activeTrialCodes = obj.activeTrialCodes;
    this.homeAddress = obj.homeAddress ? new Address(obj.homeAddress) : null;
    this.bankAccountSelfCardManagementEnabled = obj.bankAccountSelfCardManagementEnabled;
    this.culture = obj.culture;
    this.patientTrialId = obj.patientTrialId;
    this.apiConsumerId = obj.apiConsumerId;
    this.apiConsumerName = obj.apiConsumerName;

    if (obj.hasOwnProperty('customFields')) {
      obj.customFields.forEach(customField => {
        this.customDataFields.push(CustomData.map(customField));
      });
    }

    if (obj.hasOwnProperty('trials')) {
      obj.trials.forEach(pt => {
        this.trials.push(new PatientTrial(pt));
      });
    }

    return this;
  }

  getFullAddress(characterLimit = 0) {
    let address = this.address != null ? this.address.replace(/(?:\r\n|\r|\n)/g, ' ') : '';

    if (this.homeAddress === null)
      address += ' ' + this.country;

    if (characterLimit > 0 && address.length > characterLimit) {
      return address.substring(0, characterLimit) + '...';
    }

    return address;
  }

  public getFullName() {
    return this.firstname + ' ' + this.lastname;
  }
}
