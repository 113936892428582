import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Currencies } from 'app/core/constants/currency';
import { LogHelper } from 'app/core/helpers/log.helper';
import { TrialService } from 'app/core/services/trial.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import {
  DistanceUnit,
  DistanceUnitString,
  MileageRateDistanceLimitRequest,
  MileageRateListItemViewModel,
  UpdateTrialPolicyMileageRateRequest
} from '../../trial-policy.model';
import { DistanceRangeValidator } from 'app/core/validators/distance-range.validator';

@Component({
  selector: 'app-update-mileage-rate-rule-modal',
  templateUrl: './update-mileage-rate-rule-modal.component.html',
  styleUrls: ['./update-mileage-rate-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateMileageRateRuleModalComponent implements OnInit {
  rule: MileageRateListItemViewModel;
  @Input() defaultCurrency: string;
  @Input() policyId: string;

  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @ViewChild("distanceUnitSelect") distanceUnitSelect: DropdownInputComponent;
  @Output() ruleUpdated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('deleteMileageRateRuleModal') deleteMileageRateRuleModal: ModalComponent;

  ruleForm: UntypedFormGroup;
  distanceLimitForms: UntypedFormGroup[] = [];
  currencies: { value: string, text: string }[] = [];
  engineSizes: { value: string, text: string }[] = [];
  distanceUnits: { value: string, text: string }[] = [];

  shorthandNotation: string = '';
  isFormProcessing = false;
  isDeleteProcessing = false;

  constructor(private trialService: TrialService, private alertService: AlertService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initiateCurrencies();
    this.initRuleForm();
  }

  show() {
    this.initiateDistanceUnits();

    this.patchForm();

    this.modal.show();
  }

  initRuleForm() {
    this.ruleForm = new UntypedFormGroup({
      engineSize: new UntypedFormControl('', Validators.required),
      distanceUnit: new UntypedFormControl('', Validators.required),
      currency: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl('')
    });
  }

  patchForm() {
    this.ruleForm.patchValue({
      note: this.rule.note,
      engineSize: this.rule.engineSize
    });

    this.distanceUnitSelect.setValue(this.rule.distanceUnit.toString());
    this.currencySelect.setValue(this.rule.currency);

    this.distanceLimitForms = [];
    this.rule.distanceLimits.forEach(limit => {
      const form = new UntypedFormGroup({
        id: new UntypedFormControl(limit.id),
        distanceFrom: new UntypedFormControl(limit.distanceFrom, [Validators.required, Validators.min(0)]),
        distanceTo: new UntypedFormControl(limit.distanceTo, Validators.min(0)),
        amountPerDistance: new UntypedFormControl(limit.amountPerDistance, [Validators.required, Validators.min(0)]),
        extraAmount: new UntypedFormControl(limit.extraAmount, Validators.min(0))
      }, { validators: DistanceRangeValidator });

      form.valueChanges.subscribe(() => {
        this.createShorthandNotation();
      });

      this.distanceLimitForms.push(form);
    });

    this.createShorthandNotation();

    this.cd.detectChanges();
  }

  initiateCurrencies() {
    if (!this.currencies.length) {
      this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
      if (this.defaultCurrency) {
        this.currencySelect.setValue(this.defaultCurrency);
      }
    }
  }

  initiateDistanceUnits() {
    if (!this.distanceUnits.length) {
      this.distanceUnits.push({ value: DistanceUnit.Km.toString(), text: DistanceUnitString.Values[DistanceUnit.Km] });
      this.distanceUnits.push({ value: DistanceUnit.Mile.toString(), text: DistanceUnitString.Values[DistanceUnit.Mile] });
    }
  }

  submit() {
    let request = this.ruleForm.value as UpdateTrialPolicyMileageRateRequest;
    request.id = this.rule.id;
    request.distanceLimits = this.distanceLimitForms.map(x => x.value as MileageRateDistanceLimitRequest);

    this.isFormProcessing = true;

    this.trialService.updateTrialPolicyMileageRateRule(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Mileage rate rule successfully created.");
        this.ruleUpdated.emit();
        this.isFormProcessing = false;
        this.ruleForm.reset();
        this.modal.hide();
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to update mileage rate rule. Please check you inputr and try again.");
        this.isFormProcessing = false;
      }
    });
  }

  addDistanceLimit() {
    const form = new UntypedFormGroup({
      distanceFrom: new UntypedFormControl(this.distanceLimitForms[this.distanceLimitForms.length - 1].get("distanceTo").value + 1, [Validators.required, Validators.min(0)]),
      distanceTo: new UntypedFormControl('', Validators.min(0)),
      amountPerDistance: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      extraAmount: new UntypedFormControl('', Validators.min(0))
    }, { validators: DistanceRangeValidator });

    this.distanceLimitForms.push(form);

    form.valueChanges.subscribe(() => {
      this.createShorthandNotation();
    });
  }

  nextLimitAvailable(): boolean {
    if (this.distanceLimitForms.length === 0) {
      return false;
    }
    const lastLimit = this.distanceLimitForms[this.distanceLimitForms.length - 1];

    if (this.distanceLimitForms.length === 1) {
      return lastLimit.get("amountPerDistance").value;
    }

    return lastLimit.get("distanceFrom").value && lastLimit.get("amountPerDistance").value;
  }

  formsValid(): boolean {
    return this.ruleForm.valid && this.distanceLimitForms.every(x => x.valid);
  }

  createShorthandNotation() {
    this.shorthandNotation = '';
    this.distanceLimitForms.forEach((form, index) => {
      const limit = form.value as MileageRateDistanceLimitRequest;

      if (index > 0) {
        this.validateLimits(limit, index);
      }

      const firstVal = limit.distanceTo ? `<=${limit.distanceTo}` : `>${limit.distanceFrom}`;
      const secondVal = limit.amountPerDistance ? `dx${limit.amountPerDistance}` : '';
      const thirdVal = limit.extraAmount ? `(${secondVal})+${limit.extraAmount}` : secondVal;

      const final = `;${firstVal}=${thirdVal}`;

      this.shorthandNotation += final;

      const firstCharacter = this.shorthandNotation.charAt(0);
      if (firstCharacter === ';') {
        this.shorthandNotation = this.shorthandNotation.slice(1);
      }
    });
  }

  validateLimits(limit: MileageRateDistanceLimitRequest, index: number) {
    const previousLimit = this.distanceLimitForms[index - 1].value as MileageRateDistanceLimitRequest;

    if (limit.distanceFrom <= previousLimit.distanceTo) {
      this.distanceLimitForms[index].get("distanceFrom").setErrors({ rangeError: true });
      this.distanceLimitForms[index - 1].get("distanceTo").setErrors({ rangeError: true });
    } else {
      this.distanceLimitForms[index].get("distanceFrom").setErrors(null);
      this.distanceLimitForms[index - 1].get("distanceTo").setErrors(null);
    }
  }

  hideModal() {
    this.shorthandNotation = "";
    this.modal.hide();
  }

  removeLimit(i: number) {
    this.distanceLimitForms.splice(i, 1);
    this.createShorthandNotation();
  }

  deleteRule(){
    this.isDeleteProcessing = true;
    this.trialService.deleteTrialPolicyMileageRateRule(this.rule.id).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Mileage rate rule successfully deleted.");
        this.ruleUpdated.emit();
        this.isDeleteProcessing = false;
        this.ruleForm.reset();
        this.hideModal();
        this.deleteMileageRateRuleModal.hide();
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to delete mileage rate rule. Please try again.");
        this.isDeleteProcessing = false;
      }
    });
  }
}
