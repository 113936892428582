import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ListType } from "../../core/constants/list-type";
import { ListService } from "../../core/services/list.service";
import { ListValue } from "../../core/models/list-value.model";

@Component({
  selector: 'app-list-autocomplete',
  templateUrl: './list-autocomplete.component.html',
  styleUrls: ['./list-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListAutocompleteComponent implements AfterViewInit {
  @Input('listType') listTypeStr: string;
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('labelPlaceholder') labelPlaceholder: string;

  listType: ListType;
  options: string[] = [];
  listItems: ListValue[] = [];
  filteredOptions: Observable<string[]>;

  constructor(private _listService: ListService) { }

  ngAfterViewInit() {
    this.listType = ListType[this.listTypeStr];
    this.loadList();

    this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }

  /**
   * Loads a list based on the list type
   */
  private loadList() {
    this._listService.search(this.listTypeStr).subscribe(items => {
      this.listItems = items;
      this.options = items.map(x => x.label);
    });
  }

  /**
   * Finds a list item based on the label
   * @param label
   */
  private find(label: string): ListValue {
    return this.listItems.find(x => x.label === label);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  reset(): void {
    this.loadList();
  }

}
