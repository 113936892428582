import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {AlertBalances, TrialPaymentDetailsViewModel} from "../../features/trial/trial-details/trial-payments.model";
import {SetSubsidiaryBalanceAlertsRequest} from "../models/caxton.models";

@Injectable({
  providedIn: 'root'
})
export class CaxtonService {

  constructor(private http: HttpClient) { }

  getParentSubsidiary(): Observable<TrialPaymentDetailsViewModel>{
    return this.http.get<TrialPaymentDetailsViewModel>(`${environment.apiUrl}/subsidiary/parent`);
  }

  getBalanceAlerts(): Observable<AlertBalances> {
    return this.http.get<AlertBalances>(`${environment.apiUrl}/subsidiary/balance-alerts`);
  }

  updateBalanceAlerts(request: SetSubsidiaryBalanceAlertsRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/subsidiary/balance-alerts`, request);
  }
}
