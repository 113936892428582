import { Address } from "../../shared/input-address/address.model";
import { PatientTripMuvRideStop } from "./patient-trip-muv-ride-stop.model";

export class PatientTripMuvRide {
  public id: string;
  public patientTripId: string;
  public muvRideId: number;
  public state: string;
  public requestType: string;
  public rideType: string;
  public vehicleType: number;
  public noOfPassengers: number;
  public hoursNeeded: number;
  public pickupDate: Date;
  public pickupTimeMinutes: number;
  public pickupLandmark: string;
  public pickupAddress: Address;
  public pickupAddressId: string;
  public dropoffLandmark: string;
  public dropOffAddress: Address;
  public dropOffAddressId: string;
  public pickupAirportCode: string;
  public pickupAirlineId: string;
  public pickupFlightNumber: string;
  public airportMeetingProcedureId: number | null;
  public dropOffAirportCode: string;
  public dropOffAirlineId: string;
  public dropOffFlightNumber: string;
  public stops: PatientTripMuvRideStop[];
  public created: Date;
  public updated: Date;
  public deleted: boolean;
  public deletedDate: Date | null;
  public amountQuoted: number;
  public specialInstructions: string;

  constructor(init? : Partial<PatientTripMuvRide>) {
    Object.assign(this, init);

    if (init && init.stops) {
      this.stops = init.stops.map(s => new PatientTripMuvRideStop(s));
    }
  }
}
