<div [formGroup]="parentForm" class="select-field" [ngStyle]="{'min-width': minWidth ? minWidth : 'auto', 'max-width': maxWidth ? maxWidth : 'none'}">
  <span *ngIf="!editable" class="label-only">{{ getSelectionText() }}</span>

  <mat-select (selectionChange)="onValueChanged($event)" [formControlName]="controlName" *ngIf="editable"
              [multiple]="false"
              [placeholder]="inputPlaceholder" panelClass="visits-caregiver-travelling hide-checkbox">
    <mat-option class="option-none" *ngIf="allowNone && this.parentForm.get(this.controlName).value" value="option_unset">
      Unset
      <i class="far fa-times" aria-hidden="true"></i>
    </mat-option>
    <mat-option *ngFor="let item of items" [value]="item.value">
      {{ item.text }}
    </mat-option>
  </mat-select>
</div>
