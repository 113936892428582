export class TrialBookingConfigItem {
  public id: string;
  public name: string;
  public aptDurationMinutes: number;
  public aptIntervalMinutes: number;
  public dayStartMinutes: number;
  public dayEndMinutes: number;
  public maxBookingsPerSlot: number;
  public earliestBookingDays: number;
  public includeWeekends: boolean;
  public trialEmail: string;
  public supportEmail: string;
  public supportPhone: string;
  public inviteIntroHtml: string;
  public inviteIntroText: string;
  public inviteNextStepsHtml: string;
  public inviteNextStepsText: string;
  public confirmationNextStepsHtml: string;
  public confirmationNextStepsText: string;

  constructor(init?: Partial<TrialBookingConfigItem>) {
    Object.assign(this, init);
  }
}
