<td><a (click)="onToggleTrips()" class="arrow-toggle"><i class="far fa-angle-right" *ngIf="!expanded"></i><i class="far fa-angle-down" *ngIf="expanded"></i></a></td>
<td visit-table-cell-text [text]="visit.publishedStatus" [class.visibility-hidden]="visit.publishedStatus !== 'Published'"></td>
<td visit-table-cell-select (valueChanged)="onChange()" [items]="publishedStatusOptions" [parentForm]="form" controlName="publishedStatus" minWidth="auto" maxWidth="115px" *ngIf="visit.publishedStatus !== 'Published'"></td>
<td visit-table-cell-patient-code [text]="visit.patientCode" (patientClicked)="viewPatientDetail.emit(visit)"></td>
<td visit-table-cell-text [text]="visit.trialCode" [link]="'/trial/' + visit.trialId"></td>
<td visit-table-cell-text (valueChanged)="onChange()" [text]="visit.visitTitle" [editable]="true" [parentForm]="form" controlName="visitTitle"></td>
<td visit-table-cell-multiselect class="col-designated-contact" (valueChanged)="onChange(true)" [items]="coordinatorOptions" [parentForm]="form" controlName="designatedContacts" inputPlaceholder="Select..."></td>
<td visit-table-cell-select class="col-travel-requested" (valueChanged)="onChange(true)" [items]="travelRequestedOptions" [parentForm]="form" controlName="travelRequested"></td>
<td visit-table-cell-text [text]="visit.overBudgetRequestRaised ? 'Yes' : 'No'"></td>
<td visit-table-cell-select class="col-booking-status {{ form.get('bookingStatus').value | lowercase }}" (valueChanged)="onChange(true)" [items]="bookingStatusOptions" [parentForm]="form" controlName="bookingStatus"></td>
<td visit-table-cell-select class="col-caregiver-travelling {{ form.get('caregiverTravelling').value | lowercase }}" (valueChanged)="onChange(true)" [items]="caregiverTravellingOptions" [parentForm]="form" controlName="caregiverTravelling"></td>
<td visit-table-cell-select class="col-visit-status {{ form.get('attendance').value | lowercase }}" (valueChanged)="onChange(true)" [items]="visitAttendanceOptions" [parentForm]="form" controlName="attendance" minWidth="240px"></td>
<td visit-table-cell-date class="col-visit-date" (valueChanged)="onChange()" [parentForm]="form" [date]="visit.startDate" controlName="date"></td>
<td visit-table-cell-time #startTime (valueChanged)="onChange()" [editable]="visit.startDate !== null" [time]="this.form.get('time')?.value" [parentForm]="form" controlName="time"></td>
<td visit-table-cell-date class="col-visit-date" (valueChanged)="onChange()" [parentForm]="form" [date]="visit.endDate" controlName="endDate"></td>
<td visit-table-cell-time #endTime (valueChanged)="onChange()" [editable]="visit.endDate !== null" [time]="this.form.get('endTime')?.value" [parentForm]="form" controlName="endTime"></td>
<td visit-table-cell-select class="col-visit-type" (valueChanged)="onChange()" [items]="visitTypeOptions" [parentForm]="form" controlName="visitType"></td>
<td visit-table-cell-text class="col-description" (valueChanged)="onChange()" minWidth="300" [textarea]="true" [text]="visit.description" truncateLength="30" [editable]="true" [parentForm]="form" controlName="description"></td>
<td visit-table-cell-text class="col-notes" (valueChanged)="onChange()" minWidth="300" [textarea]="true" [text]="visit.notes" truncateLength="30" [editable]="true" [parentForm]="form" controlName="notes"></td>
<td [ngStyle]="{'min-width': actionsCellWidth + 'px', 'left': 'calc(100vw - 200px - 240px + '+scrollPosition+'px)'}" *ngIf="unsentNotifications">
  <div class="visit-save-btn" [ngStyle]="{'left': 'calc(100vw - 200px - 240px + '+scrollPosition+'px)'}">
    <a (click)="onClearNotifications()" class="btn btn-secondary" title="Clear unsent notifications" *ngIf="unsentNotifications">Clear</a>
    <a (click)="onSendNotifications()" class="btn btn-primary btn-orange" title="Unsent notifications" *ngIf="unsentNotifications">Send notification</a>
  </div>
</td>

<app-patient-update-address #patientUpdateAddressModal (onAddressChanged)="onPatientAddressUpdated($event)"></app-patient-update-address>
