<div class="mileage-rates-rules-list">
  <div class="rates-header">
    <div style="width: 200px;">
      <label for="distanceUnit">Default Distance Unit</label>
      <app-dropdown-input #distanceUnitSelect id="distanceUnit" [options]="distanceUnits"
        placeholder="Select distance unit"></app-dropdown-input>
    </div>
    <button class="btn btn-primary mb-0" (click)="showCreateMileageRateRuleModal()">Add Rule</button>
  </div>
  <app-trial-policy-mileage-rates-list [mileageRateRules]="mileageRateRules" (updateRule)="openUpdateRuleModal($event)">
  </app-trial-policy-mileage-rates-list>
  <div class="rates-footer">
    <a routerLink="/trial/{{trialId}}" fragment="policies" class="btn btn-secondary">Cancel</a>
    <button type="submit" class="btn btn-primary w-auto" (click)="saveDefaultCurrency()">
      <span *ngIf="saveProcessing">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!saveProcessing">Save</span>
    </button>
  </div>
</div>

<app-create-mileage-rate-rule-modal [policyId]="policyId" (ruleAdded)="getMileageRatesRules()"
  #createMileageRatesRuleModal>
</app-create-mileage-rate-rule-modal>

<app-update-mileage-rate-rule-modal (ruleUpdated)="getMileageRatesRules()" #updateMileageRatesRuleModal>
</app-update-mileage-rate-rule-modal>
