<div class="expense-rules-list">
  <div class="rules-header">
    <div style="width: 200px;">
      <label for="currency">Default Expense Currency</label>
      <app-dropdown-input #currencySelect id="currency" [options]="currencies"
        placeholder="Select currency"></app-dropdown-input>
    </div>
    <button class="btn btn-primary mb-0" (click)="showCreateExpenseRuleModal()">Add Rule</button>
  </div>
  <app-trial-policy-rules-list [expenseRules]="expenseRules" (updateRule)="openUpdateRuleModal($event)">
  </app-trial-policy-rules-list>
  <div class="rules-footer">
    <a routerLink="/trial/{{trialId}}" fragment="policies" class="btn btn-secondary">Cancel</a>
    <button type="submit" class="btn btn-primary w-auto" (click)="saveDefaultCurrency()">
      <span *ngIf="saveProcessing">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span *ngIf="!saveProcessing">Save</span>
    </button>
  </div>
</div>

<app-create-expense-rule-modal [policyId]="policyId" (ruleAdded)="getExpenseRules()" #createExpenseRuleModal>
</app-create-expense-rule-modal>

<app-update-expense-rule-modal (ruleUpdated)="getExpenseRules()" #updateExpenseRuleModal>
</app-update-expense-rule-modal>
