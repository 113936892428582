import { Permissions } from './../../../core/constants/permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { AuthService } from '../../../core/services/auth.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../../core/services/template.service';
import { LogHelper } from '../../../core/helpers/log.helper';
import { SiteContactService } from '../../../core/services/site-contact.service';
import { SiteContactList } from '../../../core/models/site-contact-list.model';

@Component({
  selector: 'app-admin-site-contacts',
  templateUrl: './admin-site-contacts.component.html',
  styleUrls: ['./admin-site-contacts.component.scss']
})
export class AdminSiteContactsComponent implements OnInit {
  @ViewChild('deleteSiteContactModal') deleteSiteContactModal: ModalComponent;

  results = new SiteContactList();
  buttonProcessing: boolean[];
  deleteSiteContactId = '';
  siteContactIsBeingDeleted = false;
  hasResults = false;
  sendRegistrationMailProcessing = false;
  Permissions = Permissions;
  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  constructor(private siteContactService: SiteContactService, public authService: AuthService,
    private alertService: AlertService, private activatedRoute: ActivatedRoute,
    private templateService: TemplateService) { }

  ngOnInit() {
    this.templateService.showHeader();

    // Load the page number
    this.activatedRoute.params.subscribe(params => {
      this.siteContactService.currentPageNumber = params.page || 1;

      // Only load the site contacts users once we've got a page number
      this.loadSiteContacts(this.siteContactService.currentPageNumber);
    });
  }

  loadSiteContacts(pageNumber: number) {
    this.dataLoaded = false;
    this.siteContactService.retrieveSiteContacts(null, pageNumber).subscribe({
      next: siteContactList => {
        this.results = siteContactList;
        this.hasResults = siteContactList.results.length > 0;
        this.buttonProcessing = Array(this.results.results.length).fill(false);
        this.dataLoaded = true;
      }, error: error => {
        this.dataLoaded = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem, please try again!');
      }
    });
  }

  onChangePage(pageNumber: number) {
    // Store the selected page number in the site contact service so that it is remembered
    this.siteContactService.currentPageNumber = pageNumber;

    // Reload results
    this.loadSiteContacts(pageNumber);
  }

  onDeleteSiteContact(id: string) {
    this.deleteSiteContactId = id;
    this.deleteSiteContactModal.show();
  }

  onDeleteConfirmed() {
    this.siteContactIsBeingDeleted = true;
    this.siteContactService.deactivateSiteContact(this.deleteSiteContactId).subscribe({
      next: () => {
        this.loadSiteContacts(this.siteContactService.currentPageNumber);
        this.alertService.showSuccessAlert('User successfully deactivated.');
        this.deleteSiteContactModal.hide();
        this.siteContactIsBeingDeleted = false;
      },
      error: error => {
        this.alertService.showWarningAlert(error.error
          ? error.error.title
          : 'There was a problem deleting the administrator');
        this.deleteSiteContactModal.hide();
        this.siteContactIsBeingDeleted = false;
      }
    });
  }

  sendPatientRegistrationMail(siteContactId: string, index: number) {
    this.buttonProcessing[index] = true;

    this.siteContactService.sendPatientRegistrationMail(siteContactId).subscribe({
      next: () => {
        this.buttonProcessing[index] = false;
        this.alertService.showSuccessAlert("Patient registration mail successfully sent");
      },
      error: error => {
        this.buttonProcessing[index] = false;
        this.alertService.showWarningAlert("Failed to send registration mail. Please try again or contact support.");
        LogHelper.log(error);
      }
    })
  }
}
