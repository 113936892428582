<div *ngIf="loginDisplay">
  <div class="row">
    <div class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
      <div class="login-wrap text-center">
        <h2>Login Successful</h2>

        <p>Please wait while we redirect you to the correct page...</p>

        <app-loader></app-loader>

      </div>
    </div>
  </div>
</div>

<div *ngIf="!loginDisplay">
  <div class="row">
    <div class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="login-wrap">
        <h2>Log In</h2>
        <div class="form-group text-center">
          <button class="btn btn-primary" (click)="login()">
            <span>Log In</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
