import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInputComponent } from "../../../../../shared/dropdown-input/dropdown-input.component";
import { SiteService } from "../../../../../core/services/site.service";
import { TrialService } from "../../../../../core/services/trial.service";
import { Countries } from "../../../../../core/constants/countries";
import { LogHelper } from "../../../../../core/helpers/log.helper";
import { AlertService } from "../../../../../shared/alert/alert.service";
import { ExpenseService } from "../../../../../core/services/expense.service";
import { TemplateService } from "../../../../../core/services/template.service";
import { ModalComponent } from "../../../../../shared/modal/modal.component";
import { Cultures } from 'app/core/constants/cultures';
import {
  AccommodationBudgetType,
  CreateTrialPolicyRequest,
  TrialPolicyTranslationRequest
} from '../../trial-policy.model';
import { Currencies } from 'app/core/constants/currency';
import { enumToText } from 'app/core/helpers/enum-to-text.function';

@Component({
  selector: 'app-trial-policy-add',
  templateUrl: './trial-policy-add.component.html',
  styleUrls: ['./trial-policy-add.component.scss']
})
export class TrialPolicyAddComponent implements OnInit {
  @ViewChild('languageSelect') languageSelect: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('translationVersionDropdown') translationVersionDropdown: DropdownInputComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;
  @ViewChild('policyCurrencySelect') policyCurrencySelect: DropdownInputComponent;
  @ViewChild('accommodationBudgetTypeSelect') accommodationBudgetTypeSelect: DropdownInputComponent;

  trialId: string = null;

  expenseCategories: { id: string, value: string }[] = [];
  cmsOnlyCategories: { id: string, value: string }[] = [];
  languageSelectForm: UntypedFormGroup;
  defaultForm: UntypedFormGroup;
  forms: UntypedFormGroup[] = [];
  formIsProcessing = false;
  countryOptions: { value: string, text: string }[] = [];
  allCultures: { value: string, text: string }[] = [];
  culturesInUse: { value: string, text: string }[] = [];
  siteOptions: { value: string, text: string }[] = [];
  currencies: { value: string, text: string }[] = [];
  accommodationBudgetTypes: { value: string, text: string }[] = [];
  currentCulture = 'en';

  constructor(private activatedRoute: ActivatedRoute, private trialService: TrialService,
    private alertService: AlertService, private expenseService: ExpenseService, private router: Router,
    private templateService: TemplateService, private siteService: SiteService) {
    for (let country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }

    for (let culture of Cultures.all()) {
      this.allCultures.push({
        value: culture.culture,
        text: culture.name
      });

      // Add english as a culture in use (default)
      if (culture.culture === 'en') {
        this.culturesInUse.push({
          value: culture.culture,
          text: culture.name
        });
      }
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.activatedRoute.params.subscribe(params => {
      this.trialId = params['id'];
    });

    // Build the add new trial form
    this.defaultForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en'),
      country: new UntypedFormControl('', Validators.required),
      sites: new UntypedFormControl([]),
      expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      categories: new UntypedFormControl([]),
      cmsOnlyCategories: new UntypedFormControl([]),
      allowTravelRequests: new UntypedFormControl(false),
      allowExpenseRequests: new UntypedFormControl(false),
      internalPolicyNotes: new UntypedFormControl(''),
      policyCurrency: new UntypedFormControl(''),

      carHireIncluded: new UntypedFormControl(false),
      carHirePatientBudget: new UntypedFormControl(null),
      carHireCaregiverBudget: new UntypedFormControl(null),
      carHireAdditionalInformation: new UntypedFormControl(),

      accommodationIncluded: new UntypedFormControl(false),
      accommodationPatientBudget: new UntypedFormControl(null),
      accommodationCaregiverBudget: new UntypedFormControl(null),
      accommodationBudgetType: new UntypedFormControl(null),
      accommodationAdditionalInformation: new UntypedFormControl(),

      airTravelIncluded: new UntypedFormControl(false),
      airTravelPatientBudget: new UntypedFormControl(null),
      airTravelCaregiverBudget: new UntypedFormControl(null),
      airTravelReturnTripIncluded: new UntypedFormControl(false),
      airTravelAdditionalInformation: new UntypedFormControl(),

      railTravelIncluded: new UntypedFormControl(false),
      railTravelPatientBudget: new UntypedFormControl(null),
      railTravelCaregiverBudget: new UntypedFormControl(null),
      railTravelReturnTripIncluded: new UntypedFormControl(false),
      railTravelAdditionalInformation: new UntypedFormControl(),

      homeToAirportTravelIncluded: new UntypedFormControl(false),
      homeToAirportTravelPatientBudget: new UntypedFormControl(null),
      homeToAirportTravelCaregiverBudget: new UntypedFormControl(null),
      homeToAirportTravelReturnTripIncluded: new UntypedFormControl(false),
      homeToAirportTravelAdditionalInformation: new UntypedFormControl(),

      airportToHotelTravelIncluded: new UntypedFormControl(false),
      airportToHotelTravelPatientBudget: new UntypedFormControl(null),
      airportToHotelTravelCaregiverBudget: new UntypedFormControl(null),
      airportToHotelTravelReturnTripIncluded: new UntypedFormControl(false),
      airportToHotelTravelAdditionalInformation: new UntypedFormControl(),

      hotelToSiteTravelIncluded: new UntypedFormControl(false),
      hotelToSiteTravelPatientBudget: new UntypedFormControl(null),
      hotelToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      hotelToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      hotelToSiteTravelAdditionalInformation: new UntypedFormControl(),

      homeToSiteTravelIncluded: new UntypedFormControl(false),
      homeToSiteTravelPatientBudget: new UntypedFormControl(null),
      homeToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      homeToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      homeToSiteTravelAdditionalInformation: new UntypedFormControl(),

      airportToSiteTravelIncluded: new UntypedFormControl(false),
      airportToSiteTravelPatientBudget: new UntypedFormControl(null),
      airportToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      airportToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      airportToSiteTravelAdditionalInformation: new UntypedFormControl(),
    });

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });

    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });

    this.defaultForm.get('country').valueChanges.subscribe(value => {
      this.loadSites();
    });

    // Start loading expense categories
    this.initiateCurrencies();
    this.loadExpenseCategories();
    this.initiateAccommodationBudgetTypes();
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  initiateAccommodationBudgetTypes() {
    this.accommodationBudgetTypes.push({ value: AccommodationBudgetType.Visit.toString(), text: enumToText(AccommodationBudgetType, AccommodationBudgetType.Visit) });
    this.accommodationBudgetTypes.push({ value: AccommodationBudgetType.Night.toString(), text: enumToText(AccommodationBudgetType, AccommodationBudgetType.Night) });
  }

  loadSites() {
    this.siteOptions = [];
    this.siteService.retrieveSites(1, this.trialId, '', this.defaultForm.get('country').value, false, 9999).subscribe({
      next: (sites: any) => {
        sites.results.forEach(site => {
          this.siteOptions.push({ text: site.name, value: site.id });
        });
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  getFormAtIndex(index: 0) {
    if (this.forms.length > index) {
      return this.forms[index];
    }

    return null;
  }

  formsValid(): boolean {
    return this.defaultForm.valid && this.forms.every((group) => group.valid);
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    this.culturesInUse.forEach(culture => {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
      }
    });

    return inUse;
  }

  /**
   * Makes a call to the API, gets a list of all expense categories and stores them locally
   */
  loadExpenseCategories() {
    this.expenseCategories = []; // reset array
    this.cmsOnlyCategories = []; // reset array
    this.expenseService.retrieveExpenseCategories(1, '', '', false, 9999).subscribe({
      next: (expenseCategoryList) => {
        for (const category of expenseCategoryList.results) {
          this.expenseCategories.push({ id: category.id, value: category.name });
          this.cmsOnlyCategories.push({ id: category.id, value: category.name });
        }
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was problem loading categories!');
      }
    });
  }

  /**
   * Called when the add trial form is submitted
   */
  onFormSubmit() {
    this.formIsProcessing = true;

    // Build translations
    let translations: TrialPolicyTranslationRequest[] = [];

    let englishTranslation: TrialPolicyTranslationRequest = {
      translationId: null,
      culture: 'en',
      expensePolicy: this.defaultForm.get('expensePolicy').value
    }
    translations.push(englishTranslation);

    this.forms.forEach(form => {
      if (form.get('expensePolicy').value) {
        translations.push(form.value as TrialPolicyTranslationRequest);
      }
    });

    let createTrialPolicyRequest: CreateTrialPolicyRequest = this.defaultForm.value as CreateTrialPolicyRequest;
    createTrialPolicyRequest.translations = translations;
    createTrialPolicyRequest.trialId = this.trialId;

    this.trialService.createPolicy(createTrialPolicyRequest).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Expense Policy Added Successfully');
        this.router.navigate(['/trial', this.trialId], { fragment: 'policies' });
      },
      error: (error) => {
        this.formIsProcessing = false;
        this.alertService.showErrorAlert(error);
      }
    });
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
      this.languageSelect.setValue('en');
    }

    this.removeLanguageModal.hide();
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
      let form = new UntypedFormGroup({
        culture: new UntypedFormControl(culture.value),
        expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      });
      this.forms.push(form);
      this.currentCulture = culture.value;
      this.languageSelectForm.patchValue({ culture: culture.value });
      this.languageSelect.setValue(culture.value);
      this.addLanguageModal.hide();
    }
  }
}
