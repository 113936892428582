import { Permissions } from './../../../core/constants/permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from "../../../core/services/site.service";
import { TrialService } from "../../../core/services/trial.service";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { SiteListItem } from "../../../core/models/site-list.model";
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-site-assigned-trials',
  templateUrl: './site-assigned-trials.component.html',
  styleUrls: ['./site-assigned-trials.component.scss']
})
export class SiteAssignedTrialsComponent implements OnInit {
  @ViewChild('deleteSiteModal') deleteSiteModal: ModalComponent;

  siteId: string;
  site: SiteListItem = SiteListItem.empty();
  isUnassigning = false;
  unassignTrialId = '';
  Permissions = Permissions;

  constructor(private readonly templateService: TemplateService, private readonly location: Location, private readonly trialService: TrialService,
    private readonly activatedRoute: ActivatedRoute, private readonly alertService: AlertService, private readonly siteService: SiteService, public authService: AuthService) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.id;

      this.loadSite(this.siteId);
    });
  }

  loadSite(siteId: string) {
    this.siteService.retrieveSite(siteId).subscribe({
      next: site => {
        this.site = site;
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem loading the site!');
      }
    });
  }

  onBackClick() {
    this.location.back();
  }

  onUnassignTrial(id: string) {
    this.unassignTrialId = id;
    this.deleteSiteModal.show();
  }

  onConfirmUnassignTrial() {
    this.trialService.unassignSite(this.unassignTrialId, this.siteId).subscribe({
      next: () => {
        this.deleteSiteModal.hide();
        this.loadSite(this.siteId);
        this.alertService.showSuccessAlert('Site successfully unassigned from the selected trial.');
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showErrorAlert(error);
      }
    });
  }

  mapStatus(int: number) {
    switch (int) {
      case 0:
        return "Pending"
      case 1:
        return "Approved"
      case 2:
        return "Declined"
      default:
        break;
    }
  }
}
