<div class="tab-pane show active fade details-view-results" id="trial-contacts" role="tabpanel">
  <div class="trial-default-support-contacts">
    <div class="title">
      <h2>Default</h2>
    </div>

    <form [formGroup]="defaultContactForm" (submit)="onUpdateDefaultSupportContacts()">
      <div class="default-sc-container">
        <div class="default-cs-col">
          <label>Trial Support Email Address</label>
        </div>
        <div class="default-cs-col">
          <div class="form-group">
            <input type="text" formControlName="supportEmail">
          </div>
        </div>

        <div class="default-cs-col">
          <label>Trial Support Phone Number</label>
        </div>
        <div class="default-cs-col">
          <div class="form-group">
            <input type="text" formControlName="supportPhone">
          </div>
        </div>

        <div class="default-cs-col">
          <div class="form-group" *ngIf="authService.hasPermission(Permissions.TrialEditPublic)">
            <button type="submit" class="btn btn-primary" [disabled]="!defaultContactForm.valid || defaultContactsFormProcessing">
              <span *ngIf="defaultContactsFormProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
              <span *ngIf="!defaultContactsFormProcessing">Update</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="details-view-results-head">
    <a class="btn btn-outline btn-outline-slim" (click)="onAddContact()" *ngIf="authService.hasPermission(Permissions.TrialSupportContactsAdd)">+ Add Support Contact</a>
  </div>

  <div class="support-contacts" *ngIf="trial.supportContacts.length > 0 || hasChanges">
    <table class="support-contacts-tbl">
      <tr>
        <th>Country</th>
        <th>Language</th>
        <th>Label</th>
        <th>Number</th>
        <th></th>
      </tr>
      <tr *ngFor="let contact of trial.supportContacts; let i = index">
        <td class="country-cell"><app-dropdown-input placeholder="Select country..." [options]="countryList" [selectedValue]="contact.country" (selectValueChanged)="onUpdateCountry(i, $event)"></app-dropdown-input></td>
        <td class="culture-cell"><app-dropdown-input placeholder="All" [options]="cultures" [selectedValue]="contact.culture" (selectValueChanged)="onUpdateCulture(i, $event)"></app-dropdown-input></td>
        <td class="label-cell"><input type="text" class="form-control" (keyup)="onUpdateLabel(i, $event)" [value]="contact.label"></td>
        <td><input type="text" class="form-control" (keyup)="onUpdateContact(i, $event)" [value]="contact.contact"></td>
        <td><a (click)="onRemoveContact(i)" *ngIf="authService.hasPermission(Permissions.TrialSupportContactsDelete)" class="btn btn-outline">Remove</a></td>
      </tr>
    </table>

    <div class="button">
      <button class="btn btn-primary" [disabled]="!hasChanges" (click)="onUpdateSupportContacts()">
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">Loading...</span>
      </span>
        <span *ngIf="!isSaving">Update Support Contacts</span>
      </button>
    </div>
  </div>

  <div class="no-results inside" *ngIf="trial.supportContacts.length == 0 && !hasChanges">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no support contacts assigned to this trial.</p>
      </div>
    </div>
  </div>
</div>
