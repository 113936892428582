<app-modal-v2 #modal class="visit-patient-details-modal" [viewPort]="viewPort" [tabulated]="true">
  <div class="modal-header">
    <h2 class="modal-title">{{ loading ? 'Loading Patient...' : 'Patient Details' }}</h2>

    <div class="modal-actions">
      <a routerLink="/patient/{{ patientTrialId }}" class="btn btn-primary" *ngIf="!loading && patient">View Patient</a>
    </div>
  </div>
  <div class="modal-body">

    <app-tabulated-content #tabulatedContent [inModal]="true" [tabs]="tabs" [showNavButtons]="false">

      <!-- Start: Patient Details -->
      <section>
        <div class="overview-panel">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-6">
                  <div class="patient-detail-output">
                    <label>First Name</label>
                    <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                    <span *ngIf="!loading && patient">{{ patient.firstname }}</span>
                  </div>
                </div>

                <div class="col-6">
                  <div class="patient-detail-output">
                    <label>Last Name</label>
                    <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                    <span *ngIf="!loading && patient">{{ patient.lastname }}</span>
                  </div>
                </div>

                <div class="col-12">
                  <div class="patient-detail-output patient-email">
                    <label>Email Address</label>
                    <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                    <span *ngIf="!loading && patient">{{ patient.email }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-4">
              <div class="patient-detail-output">
                <label>Address</label>
                <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="d-block" *ngIf="loading"></ngx-skeleton-loader>

                <span [innerHTML]="patient.homeAddress !== null ? patient.homeAddress.toStringWithHtmlLineBreaks() : patient.getFullAddress()" *ngIf="!loading && patient"></span>
              </div>
            </div>

            <div class="col-12 col-lg-2">
              <div class="patient-detail-output patient-ontheapp">
                <label>Patient On The App?</label>
                <ngx-skeleton-loader class="d-block" *ngIf="loading" style="width: 22px;"></ngx-skeleton-loader>
                <span *ngIf="!loading && patient">
              <i class="fas fa-check-square" *ngIf="!patient.patientNotOnApp"></i>
            <i class="fas fa-times-square" *ngIf="patient.patientNotOnApp"></i>
            </span>
              </div>
            </div>
          </div>
        </div>

        <div class="more-patient-info">
          <div class="row">
            <div class="col-6">
              <div *ngIf="!loading && patient">
                <label>Important Information</label>
                <span [innerHtml]="patient.note !== null && patient.note !== '' ? patient.note.replace('/r', '<br>') : '-'"></span>
              </div>
            </div>

            <div class="col-6">
              <div *ngIf="!loading && patient">
                <label>Expense Policy Reminders</label>
                <span [innerHtml]="patient.policyReminders !== null && patient.policyReminders !== '' ? patient.policyReminders.replace('/r', '<br>') : '-'"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End: Patient Details -->

      <!-- Start: Travel Budget Information -->
      <section>
        <div *ngIf="policy !== undefined && policy !== null">
          <table class="travel-budget" aria-hidden="true">
            <thead>
            <tr>
              <th>Travel Category</th>
              <th>Patient Budget (one way) {{ policy.policyCurrency ? '(' + policy.policyCurrency + ')' : '' }}</th>
              <th>Additional Caregiver Budget (one way) {{ policy.policyCurrency ? '(' + policy.policyCurrency + ')' : '' }}</th>
              <th>Return trip allowed?</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="policy.airTravelIncluded">
              <td>Air Travel</td>
              <td>{{ renderBudget(policy.airTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.airTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.airTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.airTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.railTravelIncluded">
              <td>Rail Travel</td>
              <td>{{ renderBudget(policy.railTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.railTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.railTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.railTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.homeToAirportTravelIncluded">
              <td>Home-to-airport</td>
              <td>{{ renderBudget(policy.homeToAirportTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.homeToAirportTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.homeToAirportTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.homeToAirportTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.airportToHotelTravelIncluded">
              <td>Airport-to-hotel</td>
              <td>{{ renderBudget(policy.airportToHotelTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.airportToHotelTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.airportToHotelTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.airportToHotelTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.airportToSiteTravelIncluded">
              <td>Airport-to-site</td>
              <td>{{ renderBudget(policy.airportToSiteTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.airportToSiteTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.airportToSiteTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.airportToSiteTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.homeToSiteTravelIncluded">
              <td>Home-to-site</td>
              <td>{{ renderBudget(policy.homeToSiteTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.homeToSiteTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.homeToSiteTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.homeToSiteTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.hotelToSiteTravelIncluded">
              <td>Hotel-to-site</td>
              <td>{{ renderBudget(policy.hotelToSiteTravelPatientBudget) }}</td>
              <td>{{ renderBudget(policy.hotelToSiteTravelCaregiverBudget) }}</td>
              <td>
                <i class="fas fa-check-square" *ngIf="policy.hotelToSiteTravelReturnTripIncluded"></i>
                <i class="fas fa-times-square" *ngIf="!policy.hotelToSiteTravelReturnTripIncluded"></i>
              </td>
            </tr>
            <tr *ngIf="policy.carHireIncluded">
              <td>Car Hire</td>
              <td>{{ renderBudget(policy.carHirePatientBudget) }}</td>
              <td>{{ renderBudget(policy.carHireCaregiverBudget) }}</td>
              <td></td>
            </tr>
            <tr *ngIf="policy.accommodationIncluded">
              <td>Accommodation {{ renderAccommodationType(policy.accommodationBudgetType) }}</td>
              <td>{{ renderBudget(policy.accommodationPatientBudget) }}</td>
              <td>{{ renderBudget(policy.accommodationCaregiverBudget) }}</td>
              <td></td>
            </tr>
            </tbody>
          </table>

          <div class="internal-policy-notes">
            <label>Internal Policy Notes</label>
            <p>{{ policy.internalPolicyNotes }}</p>
          </div>
        </div>
      </section>
      <!-- End: Travel Budget Information -->

      <!-- Start: Travel Requests -->
      <section>
        <div class="content-panel travel-requests" *ngIf="travelRequestResults != null && travelRequestResults.results.length > 0 && travelRequestsLoaded">

          <table class="travel-request-table">
            <thead>
            <tr>
              <th style="min-width: 130px;">State</th>
              <th style="min-width: 170px;">Request Date</th>
              <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let request of travelRequestResults.results">
              <td><span class="badge {{ travelRequestStateBadgeStyle(request.state) }}">{{ request.state }}</span></td>
              <td>{{ request.created | date: 'd MMM yyyy h:mm aaa' }}</td>
              <td><app-expandable-text [text]="request.notes"></app-expandable-text></td>
            </tr>
            </tbody>
          </table>

          <app-pagination [currentPage]="travelRequestResults.currentPage" [totalPageCount]="travelRequestResults.totalPageCount"
                          (pageSelected)="loadTravelRequests($event)"></app-pagination>
        </div>
      </section>
      <!-- End: Travel Requests -->

    </app-tabulated-content>

  </div>
  <div class="modal-footer">

  </div>
</app-modal-v2>
