import { Component, OnInit } from '@angular/core';
import {AlertModel} from './alert.model';
import {AlertService} from './alert.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AlertComponent implements OnInit {
  alerts: AlertModel[] = [];

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.alertService.alertAdded.subscribe(alert => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.removeAlert(alert);
      }, 10000);
    });

    this.alertService.allAlertsCleared.subscribe(() => {
      this.alerts = [];
    });
  }

  removeAlert(alert) {
    const index = this.alerts.indexOf(alert, 0);
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
  }

  onCloseToast(alert) {
    this.removeAlert(alert);
  }

}
