import { PatientTripMuvAddress } from "./patient-trip-muv-address.model";

export class PatientTripMuvRideStop {
  public id: string;
  public muvRideId: string;
  public address: PatientTripMuvAddress;
  public addressId: string;
  public pickupTimeMinutes: number;

  constructor(init? : Partial<PatientTripMuvRideStop>) {
    Object.assign(this, init);
  }
}
