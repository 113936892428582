import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TravelRequestListComponent} from './travel-request-list/travel-request-list.component';
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [TravelRequestListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule
  ]
})
export class TravelModule { }
