import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UntypedFormGroup } from '@angular/forms';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent {
  @ViewChild('fileUpload') fileUpload: ElementRef;

  @Output('state') stateChange = new EventEmitter<string>();
  @Output('invalid') invalid = new EventEmitter<string>();

  @Input('parentForm') form: UntypedFormGroup;
  @Input('controlName') controlName: string;

  @Input('state') state = 'none';
  @Input('maxSizeMb') maxSizeMb: number = 50;
  filename: string;
  videoUrl: string;
  uploadProgress: number = 0;
  uploadProgressStyle: string;
  uploadSub: Subscription;
  supportedExtensions = ['mp4'];

  constructor(private readonly http: HttpClient, private readonly alertService: AlertService) { }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.uploadFile(file);
  }

  setVideoUrl(videoUrl: string) {
    if (videoUrl !== null && videoUrl !== '') {
      this.videoUrl = videoUrl;
      this.state = 'has-video';
      this.stateChange.emit('has-video');
    }
  }

  private uploadFile(file: File) {
    if (file) {
      const sizeMb = file.size / 1000000;

      if (sizeMb <= this.maxSizeMb) {
        this.filename = file.name;

        // Check file extension
        const filenameParts = file.name.split('.');
        const fileExtension = filenameParts[filenameParts.length - 1];

        if (this.supportedExtensions.indexOf(fileExtension) !== -1) {
          const formData = new FormData();
          formData.append('thumbnail', file);

          this.state = 'uploading';
          const upload$ = this.http.post(environment.videoStorageUrl, formData, {
            reportProgress: true,
            observe: 'events'
          }).pipe(finalize(() => this.reset()));

          this.uploadSub = upload$.subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                this.uploadProgressStyle =
                  `linear-gradient(90deg, rgba(228,233,243,1) 0%, rgba(228,233,243,1) ${this.uploadProgress}%, rgba(255,255,255,1) ${this.uploadProgress}%`;
              } else if (event.hasOwnProperty('body')) {
                this.videoUrl = event.body.url;
                this.state = 'has-video';
                this.stateChange.emit('has-video');

                this.form.patchValue({ [this.controlName]: this.videoUrl });
              }
            },
            error: () => {
              this.state = 'none';
              this.stateChange.emit('none');
            }
          });
        } else {
          this.invalid.emit('File type not supported');
        }
      } else {
        this.alertService.showWarningAlert(`File size cannot exceed ${this.maxSizeMb}MB`);
      }
    }
  }

  onFileDropped($event) {
    this.uploadFile($event);
  }

  onFileUploadClicked($event) {
    this.fileUpload.nativeElement.click();

    $event.stopPropagation();
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    setTimeout(() => this.reset(), 500);
  }

  reset() {
    this.state = 'none';
    this.uploadProgress = 0;
    this.uploadSub = null;
    this.uploadProgressStyle = 'none';
  }

  removeVideo(event: Event) {
    event.stopPropagation();

    this.uploadProgressStyle = '';
    this.filename = null;
    this.videoUrl = null;
    this.state = 'none';
    this.stateChange.emit('none');
    this.form.patchValue({ [this.controlName]: '' });
    this.reset();
  }
}
