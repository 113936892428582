import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from "../../../core/services/site.service";
import { Countries } from "../../../core/constants/countries";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import {
  AutosuggestDropdownInputComponent
} from "../../../shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { InputAddressComponent } from "../../../shared/input-address/input-address.component";
import { Address } from "../../../shared/input-address/address.model";
import { SiteListItem } from "../../../core/models/site-list.model";

@Component({
  selector: 'app-site-edit',
  templateUrl: './site-edit.component.html'
})
export class SiteEditComponent implements OnInit {
  @ViewChild('siteAddress') siteAddress: InputAddressComponent;
  @ViewChild('countrySelect') countrySelect: AutosuggestDropdownInputComponent;
  siteId: string;
  form: UntypedFormGroup;
  isFormProcessing = false;
  countryOptions: { value: string, text: string }[] = [];
  hideAddressInput = false;
  site: SiteListItem;
  siteAddressCountryDisabled = true;

  constructor(private readonly templateService: TemplateService, private readonly activatedRoute: ActivatedRoute, private readonly siteService: SiteService,
    private readonly alertService: AlertService, private readonly router: Router) {
    for (const country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      irgSiteId: new UntypedFormControl(''),
      siteAddress: new UntypedFormControl(null, Validators.required)
    });

    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.id;
      this.siteService.retrieveSite(this.siteId).subscribe({
        next: site => {
          this.site = site;
          this.form.setValue({
            name: site.name,
            address: site.address,
            country: site.country,
            irgSiteId: site.irgSiteId,
            siteAddress: site.siteAddress
          });
          this.countrySelect.setSelectedOption(site.country);
          this.countrySelect.disable();

          this.form.get('address').disable();

          let siteAddress = new Address(site.siteAddress);
          this.siteAddress.setAddress(siteAddress);

          if (siteAddress.equals(site.address)) {
            this.form.get('address').disable();
            this.form.get('country').disable();
            this.hideAddressInput = true;
          }
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('There was a problem loading the site!');
        }
      });
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;

      // Ensure the country field is set correctly when the form input might be disabled
      const siteAddress = this.form.get('siteAddress').value;
      if (this.siteAddressCountryDisabled) {
        if (siteAddress && this.site?.siteAddress?.country) {
          siteAddress.country = this.site.siteAddress.country;
        }
      }

      this.siteService.updateSite(
        this.siteId,
        this.form.get('name').value,
        this.form.get('address').value,
        this.form.get('country').value,
        this.form.get('irgSiteId').value,
        this.siteAddressCountryDisabled ? siteAddress : this.form.get('siteAddress').value
      ).subscribe({
        next: () => {
          this.alertService.clearAll();
          this.alertService.showSuccessAlert('Site Updated Successfully');
          this.router.navigate(['/site']);
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('Unable to update site, please try again!');
        }
      });
    }
  }
}
