<!-- Start: Patients Feedback -->
<div class="tab-pane fade active show">

  <table>
    <thead>
    <tr>
      <th>Patient Id</th>
      <th>Public Trial Code</th>
      <th>Nickname</th>
      <th>Opportunity Number</th>
      <th class="text-center">Date</th>
      <th class="text-center">Rating</th>
    </tr>
    </thead>
    <tbody>
    <tr appPatientFeedbackRow *ngFor="let result of feedback.results"
        [date]="result.date"
        [patientCode]="result.patientCode"
        [patientId]="result.patientId"
        [patientTrialId]="result.patientTrialId"
        [stars]="result.rating"
        [trialCode]="result.trialCode"
        [trialNickname]="result.trialNickname"
        [trialOpportunityNumber]="result.trialOpportunityNumber"
    ></tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="feedback.currentPage" [totalPageCount]="feedback.totalPageCount" (pageSelected)="loadFeedback($event, country, searchTerm, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="feedback.results === null || feedback.results.length === 0">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No patient feedback" />
      </div>
      <div class="content">
        <h2>No Patient Feedback</h2>
      </div>
    </div>
  </div>

</div>
<!-- End: Patients Feedback -->
