import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { StringHelper } from "../../core/helpers/string-helper";

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('info') info: string;
  @Input('accepts') accepts: string;

  fileSelected: boolean = false;
  fileName: string = '';
  fileNameLabel: string = '';
  file: File;

  private fileData: string = '';

  constructor() { }

  getFileData(): string {
    if (!this.fileData)
      return '';

    return this.fileData;
  }

  onRemove(): void {
    this.fileSelected = false;
    this.fileInput.nativeElement.value = '';
    this.fileData = null;
    this.file = null;
    this.parentForm.patchValue({[this.controlName]: ''});
  }

  onFileSelect(files) {
    if (files.length === 0)
      return;

    this.readFileContent(files[0]).then(data => {
      this.file = files[0];
      this.fileSelected = true;
      this.fileName = files[0].name;
      this.fileNameLabel = StringHelper.limit(this.fileName, 20);
      this.fileData = data;
      this.parentForm.patchValue({[this.controlName]: this.fileName});
    });
  }

  private readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target?.result as string;
        resolve(fileContent);
      };

      reader.onerror = (event) => {
        reject(event.target?.error);
      };

      reader.readAsText(file);
    });
  }

}
