import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { TravelResult } from "../models/travel-results.model";
import { OrderBy } from '../models/expense.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TravelRequestService {

  constructor(private http: HttpClient) { }

  /**
   * Remove a travel request
   * @param requestId
   */
  removeRequest(requestId: string) {
    return this.http.delete(environment.apiUrl + '/travel/' + requestId);
  }

  /**
   * Retrieve a list of outstanding travel requests
   * @param page
   * @param keywords
   * @param visitId
   * @param state
   * @param orderBy
   * @param showDeleted
   */
  retrieveTravelRequests(page: number, keywords: string, visitId: string, state: number, orderBy: OrderBy, showDeleted: boolean = false) {
    let params = new HttpParams().set('page', page.toString());
    params = params.set('orderBy', orderBy);

    if (visitId != null) {
      params = params.set('visitId', visitId);
    }

    if (state != null) {
      params = params.set('state', state);
    }

    if (keywords != null) {
      params = params.set('keywords', keywords);
    }

    if (showDeleted) {
      params = params.set('showDeleted', showDeleted);
    }

    return this.http.get<TravelResult>(environment.apiUrl + '/travel', { params: params }).pipe(map(rsp => {
      return new TravelResult().resultsFromResponse(rsp);
    }));
  }

  setWip(travelRequestId: string): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/travel/set-wip/${travelRequestId}`, null);
  }

  stopWip(travelRequestId: string): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/travel/stop-wip/${travelRequestId}`, null);
  }
}
