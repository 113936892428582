import { Permissions } from './../../../core/constants/permissions';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BalanceThresholds } from '../../trial/trial-details/trial-payments.model';
import {
  SetSubsidiaryBalanceAlertsRequest,
  SubsidiaryPaymentDetailsViewModel
} from "../../../core/models/caxton.models";
import { CaxtonService } from "../../../core/services/caxton.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { AuthService } from "../../../core/services/auth.service";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { LogHelper } from "../../../core/helpers/log.helper";

@Component({
  selector: 'app-parent-subsidiary-details',
  templateUrl: './parent-subsidiary-details.component.html',
  styleUrls: ['./parent-subsidiary-details.component.scss']
})
export class ParentSubsidiaryDetailsComponent implements OnInit {
  @Input() subsidiaryId: string;
  subsidiaryPaymentDetailsViewModel: SubsidiaryPaymentDetailsViewModel;
  textConnectionIsProcessing = false;
  @ViewChild('alertsModal') alertsModal: ModalComponent;
  balanceThresholds: BalanceThresholds[];
  alertForm: UntypedFormGroup;
  alertUpdateProcessing: boolean = false;
  saveProcessing: boolean = false;
  Permissions = Permissions;

  constructor(private readonly alertService: AlertService, public authService: AuthService, private readonly caxtonService: CaxtonService, private readonly templateService: TemplateService) { }

  ngOnInit(): void {
    this.templateService.showHeader();

    this.caxtonService.getParentSubsidiary().subscribe({
      next: response => {
        this.subsidiaryPaymentDetailsViewModel = response;
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to retrieve trial payments info');
      }
    });

    this.alertForm = new UntypedFormGroup({
      alertEmail: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  testConnectionToCaxton() {
    this.textConnectionIsProcessing = true;
    this.caxtonService.getParentSubsidiary().subscribe({
      next: response => {
        this.alertService.showSuccessAlert('Refresh successful');
        this.subsidiaryPaymentDetailsViewModel = response;
        this.textConnectionIsProcessing = false;
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Connection to Caxton failed');
        this.textConnectionIsProcessing = false;
      }
    });
  }

  getBalanceAlerts() {
    this.alertsModal.show();
    this.caxtonService.getBalanceAlerts().subscribe(result => {
      this.balanceThresholds = result.balanceThresholds;
      this.alertForm.controls.alertEmail.setValue(result.alertEmail);
      result.balanceThresholds.forEach(t => {
        this.alertForm.addControl(t.currency, new UntypedFormControl(t.balance, Validators.min(0)));
      });
    });
  }

  updateAlerts() {
    this.alertUpdateProcessing = true;
    const request: SetSubsidiaryBalanceAlertsRequest = {
      alertEmail: this.alertForm.get('alertEmail').value,
      currencyThresholds: []
    };

    Object.keys(this.alertForm.controls).forEach(key => {
      if (key !== 'alertEmail') {
        request.currencyThresholds.push({
          currency: key,
          balance: this.alertForm.get(key).value
        });
      }
    });

    this.caxtonService.updateBalanceAlerts(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Alerts successfully set.');
        this.alertsModal.hide();
        this.alertUpdateProcessing = false;
      },
      error: () => {
        this.alertService.showWarningAlert('Failed to set alerts. Please try again or contact support');
        this.alertUpdateProcessing = false;
      }
    });
  }
}
