<div class="tab-pane fade show active details-view-results" id="trial-patients" role="tabpanel" *ngIf="!showConfiguration">
  <div class="details-view-results-head">
    <div class="row">
      <div class="col-3">
        <div class="booking-filter" *ngIf="authService.hasPermission(Permissions.TrialBookingFull)">
          <form [formGroup]="filterForm">
            <app-input-select [parentForm]="filterForm" controlName="bookingConfigId" [items]="filterConfigOptions"></app-input-select>
          </form>
        </div>
      </div>

      <div class="col-9 text-right booking-actions">
        <a class="btn btn-outline btn-outline-slim" *ngIf="authService.hasPermission(Permissions.TrialBookingFull)" (click)="onShowImportModal()">Import Patients</a>
        <a class="btn btn-secondary btn-slim" (click)="toggleConfiguration()">Configuration</a>
      </div>
    </div>
  </div>

  <div class="unsent-invites" *ngIf="unsentCount > 0">
    <h3>Unsent Invites</h3>
    <p>There {{ unsentCount > 1 ? 'are' : 'is' }} <strong>{{unsentCount}}</strong> unsent invite{{ unsentCount > 1 ? 's' : '' }}, would you like to send {{ unsentCount > 1 ? 'them' : 'it' }} now?</p>

    <a (click)="unsentModal.show()" class="btn btn-slim btn-warning">Send Invites</a>
  </div>

  <table *ngIf="authService.hasPermission(Permissions.TrialBookingFull)" aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr style="color: gray;">
      <th></th>
      <th>Appointment / Date</th>
      <th>Patient</th>
      <th></th>
      <th>Invite Sent Date</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let booking of result.results">
        <td><span class="badge {{getBadgeColourForStatus(booking.status)}}">{{ booking.status | uppercase }}</span></td>
        <td>
          <div class="multiline">
            <span class="ml-label">{{ booking.displayName}}</span>
            <span class="ml-text" *ngIf="booking.status === 'Booked'">{{ booking.aptDateTime | date: 'd MMM yyyy' }} at {{ booking.aptDateTime | date: 'h:mm aaa' }}</span>
            <span class="ml-text" *ngIf="booking.status !== 'Booked'">- &nbsp;&nbsp;&nbsp;&nbsp; -</span>
          </div>
        </td>
        <td>
          <div class="multiline">
            <span class="ml-label">{{ booking.patientCode}}</span>
            <span class="ml-text">{{ booking.patientName }}</span>
          </div>
        </td>
        <td>
          <div class="multiline">
            <span class="ml-label">Patient Email</span>
            <span class="ml-text">{{ booking.patientEmail }}</span>
          </div>
        </td>
        <td>
          <span *ngIf="booking.status === 'New' || booking.status === 'Expired'">- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -</span>
          <span *ngIf="booking.status !== 'New' && booking.status !== 'Expired'">{{ booking.inviteSentDate | date: 'd MMM yyyy' }} at {{ booking.inviteSentDate | date: 'h:mm aaa' }}</span>
        </td>
        <td>
          <div class="actions">
            <a (click)="onSendInvite(booking.id)" class="btn btn-primary btn-small" *ngIf="booking.status === 'New'">Send</a>
            <a (click)="onSendInvite(booking.id, true)" class="btn btn-primary btn-small" *ngIf="booking.status === 'Pending'">Resend</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="no-results inside" *ngIf="result.results.length == 0">
    <div class="inner">
      <div class="image">
        <img alt="No results found" src="assets/img/no-results-illustration.png"/>
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no bookings for this trial.</p>
      </div>
    </div>
  </div>

  <app-pagination [currentPage]="result.currentPage" [totalPageCount]="result.totalPageCount" (pageSelected)="onChangePage($event)"></app-pagination>
</div>

<app-trial-bookings-config [trialId]="trialId" (back)="toggleConfiguration()" *ngIf="showConfiguration"></app-trial-bookings-config>

<!-- Start: Import modal -->
<app-modal #importModal customClassName="size-md">
  <div class="modal-header">
    <h2 class="modal-title">Import Patient Bookings</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="importForm">
      <div class="form-group">
        <label>Type</label>
        <app-input-select [parentForm]="importForm" controlName="configId" [items]="configOptions" inputPlaceholder="Please select..."></app-input-select>
      </div>

      <div class="form-group" [class.visibility-hidden]="importForm.get('configId').value === null || importForm.get('configId').value === ''">
        <label>Import File</label>
        <app-input-file #inputFile [parentForm]="importForm" controlName="csvFile" accepts=".csv" info="Import supports csv files only"></app-input-file>
      </div>

      <p class="csv-errors" *ngIf="csvError !== ''" [innerHTML]="csvError"></p>
      <p class="csv-errors" *ngIf="this.importForm.get('csvValid').value && validCsvRows === 0">The import contains no data to import</p>
      <p class="csv-errors-success" *ngIf="this.importForm.get('csvValid').value && validCsvRows > 0">You are about to import {{ validCsvRows }} {{ validCsvRows === 1 ? 'patient' : 'patients' }}.</p>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="onHideImportModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onStartImport()" [disabled]="!importForm.valid || csvError || validCsvRows === 0">
          <span *ngIf="importForm.get('processing')?.value">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!importForm.get('processing')?.value">Import</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Import modal -->

<!-- Start: Unsent modal -->
<app-modal #unsentModal customClassName="size-md">
  <div class="modal-header">
    <h2 class="modal-title">Send Invites</h2>
  </div>
  <div class="modal-body">
    <p *ngIf="unsentCount > 1">Please confirm that you would like to send invites to <strong>{{ unsentCount }}</strong> patients.</p>
    <p *ngIf="unsentCount === 1">Please confirm that you would like to send an invite to <strong>{{ unsentCount }}</strong> patient.</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unsentModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onSendUnsentInvites()">
          <span *ngIf="sendingAllUnsent">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!sendingAllUnsent">Send {{ unsentCount }} invites</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unsent modal -->
