<div class="expandable-text">

  <div class="truncated-text" *ngIf="!expanded">
    {{ truncatedText }}
    <span class="toggle-link" *ngIf="expandable"><a (click)="toggle()">Expand</a></span>
  </div>

  <div class="expanded-text" *ngIf="expanded">
    {{ text }}
    <span class="toggle-link" *ngIf="expandable"><a (click)="toggle()">Close</a></span>
  </div>

</div>
