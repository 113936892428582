<div
  class="visit-table-cell-short-date"
  [ngStyle]="{ 'min-width': minWidth ? minWidth : '' }"
  [formGroup]="parentForm">

  <span *ngIf="!editable" class="label-only">{{ parentForm.get(controlName).value | date: 'MM/yyyy' }}</span>

  <mat-form-field *ngIf="editable">
    <input matInput [matDatepicker]="dp" [formControlName]="controlName">
    <mat-hint>MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
</div>
