import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "environments/environment";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig: Configuration = {
  auth: {
    clientId: environment.msalConfig.clientId, // This is your client ID
    authority: 'https://login.microsoftonline.com/' + environment.msalConfig.tenantId, // This is your tenant ID
    knownAuthorities: [],
    redirectUri: environment.msalConfig.redirectUri,// This is your redirect URI
    postLogoutRedirectUri: environment.msalConfig.redirectUri + "/login",
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE,
  },
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [environment.msalConfig.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory() {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}
