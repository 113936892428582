export class TabItem {
  public title: string;
  public visible: boolean;
  public badge: number = 0;
  public showBadge: boolean = false;

  constructor(init?: Partial<TabItem>) {
    Object.assign(this, init);
  }
}
