import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { TabItem } from "./tab-item.model";

@Component({
  selector: 'app-tabulated-content',
  templateUrl: './tabulated-content.component.html',
  styleUrls: ['./tabulated-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabulatedContentComponent implements AfterViewInit {
  @Input('tabs') tabs: TabItem[] = [];
  @Input('inModal') inModal: boolean = false;
  @Input('disabled') disabled: boolean = false;
  @Input('showNavButtons') showNavButtons: boolean = false;

  @Output('onTabChange') onTabChange: EventEmitter<number> = new EventEmitter<number>();

  tabClassNames: Array<string[]> = [];
  selectedIndex = 0;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.tabs.forEach(tab => this.tabClassNames.push([]));
    this.setSectionVisibility(0);
  }

  /**
   * Adds a class to a tab
   * @param tabText
   * @param className
   */
  addTabClass(tabText: string, className: string): void {
    const tab = this.tabs.find(t => t.title === tabText);
    const index = this.tabs.indexOf(tab);
    if (index !== -1) {
      if (!this.tabClassNames[index].includes(className)) {
        this.tabClassNames[index].push(className);
      }
    }
  }

  getTabCount(): number {
    return this.tabs.filter(t => t.visible).length;
  }

  navigateToPreviousTab(): void {
    if (this.selectedIndex > 0) {
      this.switchTab(this.selectedIndex - 1);
    }
  }

  navigateToNextTab(force = false): void {
    if (this.disabled && !force)
      return;

    if (this.selectedIndex < this.tabs.length - 1) {
      this.switchTab(this.selectedIndex + 1);
    }
  }

  /**
   * Removes a class from a tab
   * @param tabText
   * @param className
   */
  removeTabClass(tabText: string, className: string): void {
    const tab = this.tabs.find(t => t.title === tabText);
    const index = this.tabs.indexOf(tab);
    if (index >= 0) {
      if (this.tabClassNames[index] !== undefined && this.tabClassNames[index].includes(className)) {
        const i = this.tabClassNames[index].indexOf(className);
        this.tabClassNames[index].splice(i, 1);
      }
    }
  }

  switchTab(index: number, force= false) {
    if (this.disabled && !force)
      return;

    this.selectedIndex = index;
    this.setSectionVisibility(index);
    this.onTabChange.emit(index);
  }

  private setSectionVisibility(index: number) {
    let i = 0;
    this.elementRef.nativeElement.querySelectorAll('section').forEach((section: ElementRef) => {
      this.renderer.setAttribute(section, 'class', index === i ? 'active' : '');
      i++;
    });
  }
}
