import { Permissions } from './../../../core/constants/permissions';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";
import { TrialList } from "../../../core/models/trial-list.model";
import { TrialService } from "../../../core/services/trial.service";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AuthService } from "../../../core/services/auth.service";
import { UpdateLastRouteAction } from "../../../core/actions/navigation.action";
import { AppState } from "../../../core/state/app.state";
import { TemplateService } from "../../../core/services/template.service";
import { Subscription } from "rxjs";
import { ModalViewport } from "../../../shared/modal-v2/modal-viewport";

@Component({
  selector: 'app-trials-list',
  templateUrl: './trials-list.component.html'
})
export class TrialsListComponent implements OnInit, AfterViewInit {
  results = new TrialList();
  searchForm: UntypedFormGroup;
  Permissions = Permissions;
  subscription: Subscription;
  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

  constructor(public authService: AuthService, private trialService: TrialService, private templateService: TemplateService, private router: Router, private cdr: ChangeDetectorRef, private location: Location, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.templateService.showHeader();

    let keywords = localStorage.getItem('trial_query') !== null && localStorage.getItem('trial_query') !== 'null' ? localStorage.getItem('trial_query') : '';

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl(keywords)
    });

    this.store.dispatch(new UpdateLastRouteAction(this.location.path()));
  }

  ngAfterViewInit() {
    this.loadTrials(1, this.searchForm.get('keywords').value);
  }

  loadTrials(page, keywords: string) {
    localStorage.setItem('trial_query', keywords);
    this.dataLoaded = false;

    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.trialService.retrieveTrials(page, keywords, null).subscribe({
      next: (trialList) => {
        this.results = trialList;
        this.dataLoaded = true;

        this.cdr.detectChanges();
      }, error: (error) => {
        this.dataLoaded = true;
        LogHelper.log(error);
      }
    });
  }

  /**
   * Called when the value of the search input changes, it loads trials based on the value of the field
   */
  onSearchInputChanged(keywords: string) {
    this.loadTrials(1, keywords);
  }

  onClickTrial(trialId: string) {
    void this.router.navigate(['/trial', trialId]);
  }

  protected readonly ModalViewport = ModalViewport;
}
