import { SearchPage } from "./search-page.model";

export class TrialListItem {
  constructor(
    public id: string,
    public code: string,
    public label: string,
    public visitCount: number,
    public state: string,
    public supportEmail: string,
    public supportPhone: string,
    public apiEnabled: boolean
  ) {
  }
}

export class TrialList extends SearchPage {
  results: TrialListItem[] = [];
}
