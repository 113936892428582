import { AlertService } from './../../../shared/alert/alert.service';
import { ExpenseService } from './../../../core/services/expense.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { CloseExpenseClaimInvestigationRequest } from 'app/core/models/expense.model';

@Component({
  selector: 'app-close-investigation-modal',
  templateUrl: './close-investigation-modal.component.html',
  styleUrls: ['./close-investigation-modal.component.scss']
})
export class CloseInvestigationModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('allClaimsModal') allClaimsModal: ModalComponent;

  @Output() refreshResult: EventEmitter<any> = new EventEmitter<any>();

  processing: boolean = false;
  expenseClaimId : string;

  constructor(private expenseService: ExpenseService, private alertService: AlertService) { }

  ngOnInit(): void {
  }

  showAllClaimsModal(){
    this.allClaimsModal.show();
  }

  onConfirmCloseInvestigation(allClaims: boolean) {
    this.processing = true;
    let request: CloseExpenseClaimInvestigationRequest = {
      expenseClaimId: this.expenseClaimId,
      allClaims: allClaims
    }

    this.expenseService.closeInvestigation(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Investigation closed.');
        this.modal.hide();
        this.allClaimsModal.hide();
        this.processing = false;
        this.refreshResult.emit();
      },
      error: error => {
        this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was a problem');
        this.processing = false;
      }
    });
  }

}
