import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { CurrentAdmin } from "../models/current-admin.model";
import { environment } from "../../../environments/environment";
import { PlatformVersions } from "../models/platform-versions.model";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  /**
   * Makes a call to the API to update platform versions
   * @param android
   * @param ios
   */
  updatePlatformVersions(android: string, ios: string) {
    const body = {
      android: android,
      ios: ios
    };

    return this.http.put(environment.apiUrl + '/setting/platform-versions', body);
  }

  /**
   * Gets the current platform versions
   */
  getPlatformVersions() {
    return this.http.get<PlatformVersions>(environment.apiUrl + '/setting/platform-versions');
  }

  /**
   * Retrieves the current logged in administrator
   */
  retrieveCurrentAdmin() {
    return this.http.get<CurrentAdmin>(environment.apiUrl + '/admin/current').pipe(map(rsp => {
      return CurrentAdmin.fromObject(rsp);
    }));
  }
}
