<!-- Start: Patients pending -->
<div class="tab-pane fade active show">

  <table class="std-results-list patients-pending" aria-hidden="true">
    <tbody>
      <tr *ngFor="let patient of results.results">
        <td class="valign-top">
          <span class="label d-none d-lg-block">{{ patient.email }}</span>
          <div class="patient-name">{{ patient.getFullName() }}</div>
        </td>
        <td class="valign-top">
          <span class="label d-none d-lg-block">&nbsp;</span>
          <span class="patient-trial-name">{{ stringHelper.limit(patient.trialLabel, 45) }}</span>
        </td>
        <td class="valign-top">
          <span class="label d-none d-lg-block">&nbsp;</span>
          <span class="patient-trial-name">{{ patient.created | date: 'd MMM yyyy' }}
            at {{ patient.created | date: 'h:mm aaa' }}</span>
        </td>
        <td class="result-btn">
          <a class="btn btn-primary btn-slim" (click)="onApprovePatient(patient)"
            *ngIf="authService.hasPermission(Permissions.PatientApprove)">Approve</a>
          <a class="btn btn-outline btn-slim" (click)="onRejectPatient(patient)"
            *ngIf="authService.hasPermission(Permissions.PatientApprove)">Reject</a>
        </td>
      </tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
    (pageSelected)="loadPatients($event, keywords, country, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="!hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-patients.png" alt="No pending patients" />
      </div>
      <div class="content">
        <h2>No Pending Patients</h2>
      </div>
    </div>
  </div>

  <div class="no-results inside" *ngIf="results.results.length == 0 && hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients matching your search.</p>
      </div>
    </div>
  </div>

</div>
<!-- End: Patients pending -->
