import { Component, OnInit } from '@angular/core';
import { ChangeLog } from '../../../shared/change-log/change-log.model';
import { AdminService } from '../../../core/services/admin.service';
import { AlertService } from '../../../shared/alert/alert.service';

@Component({
  selector: 'app-admin-change-log',
  templateUrl: './admin-change-log.component.html'
})
export class AdminChangeLogComponent implements OnInit {
  changeLogResults: ChangeLog;
  dataLoaded: boolean = false;

  constructor(private adminService: AdminService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.loadChangeLog();
  }

  loadChangeLog(page = 1) {
    this.dataLoaded = false;
    this.adminService.changeLog(page).subscribe({
      next: changeLog => {
        this.changeLogResults = changeLog;
        this.dataLoaded = true;
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem loading the change log.');
      }
    });
  }

  onPageChanged(page: number) {
    this.loadChangeLog(page);
  }
}
