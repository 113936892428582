<div class="tab-pane fade show active details-view-results">
  <div class="create-expense-button" *ngIf="authService.hasPermission(Permissions.PatientExpenseWrite)">
    <a (click)="createExpenseModal.showForPatient(patientId)" class="btn btn-outline btn-outline-slim">+ Add Expense</a>
  </div>

  <div *ngIf="results.policyReminders" class="policy-reminder">
    <span class="text">
      <span class="text-style-1">Policy Reminder:</span>
      {{ results.policyReminders }}
    </span>
  </div>


  <div class="patient-expense" *ngFor="let expense of results.results; let i = index;">
    <button
      *ngIf="(expense.state === 'Pending' || expense.state === 'Approved' || expense.state === 'Rejected') &&  authService.hasPermission(Permissions.PatientExpenseEditInvestigation)"
      (click)="onEditExpense(expense.id)" class="btn btn-secondary btn-slim btn-edit"
      [disabled]="expense.overBudgetRequestRaised && expense.state === 'Pending'">
      Edit
    </button>
    <a (click)="onStartInvestigation(expense.id)" class="btn btn-secondary btn-slim btn-edit"
      *ngIf="authService.hasPermission(Permissions.PatientExpenseEditInvestigation) && !expense.underInvestigation && expense.state === 'Pending'">
      Requires
      Investigation
    </a>
    <a (click)="onCloseInvestigation(expense.id)" class="btn btn-secondary btn-slim btn-edit btn-close-investigation"
      *ngIf="authService.hasPermission(Permissions.PatientExpenseWrite) && expense.underInvestigation && expense.state === 'Pending'">
      Close
      Investigation
    </a>

    <div class="badge-wrap">
      <span class="badge" [ngClass]="{'badge-warning': expense.state == 'Pending',
                                  'badge-success': (expense.state == 'CaxtonApproved' || expense.state == 'Processing' || expense.state == 'Approved'),
                                  'badge-danger': (expense.state == 'Rejected' || expense.state == 'CaxtonFailed'),
                                  'badge-grey': expense.state == 'Paid'}">
        {{ getBadgeLabel(expense.state) }}
      </span>

      <span class="label" *ngIf="expense.state === 'CaxtonApproved' && expense.dateApproved">
        on {{ expense.dateApproved | date: 'd MMM yyyy' }} at {{
        expense.dateApproved | date: 'h:mm aaa'
        }}
      </span>

      <span class="label" *ngIf="expense.state === 'Rejected' && expense.dateRejected">
        on {{ expense.dateRejected | date: 'd MMM yyyy' }} at {{
        expense.dateRejected | date: 'h:mm aaa'
        }}
      </span>

      <span class="label" *ngIf="expense.state === 'Paid' && expense.datePaid">
        on {{ expense.datePaid | date: 'd MMM yyyy' }} at {{ expense.datePaid | date: 'h:mm aaa' }}
      </span>

      <span *ngIf="expense.state === 'Pending' && (expense.overBudgetRequestStatus !== null || expense.overBudgetRequestRaised)"
        class="over-budget over-budget-{{(expense.overBudgetRequestStatus | enumToText: OverBudgetRequestDetailsStatus)?.replace(' ', '-') | lowercase}}">
        <mat-icon>error</mat-icon>
        Over budget - {{expense.overBudgetRequestStatus !== null ? (expense.overBudgetRequestStatus | enumToText:
        OverBudgetRequestDetailsStatus) : 'Pending'}} decision
      </span>
      <span class="over-budget referred-back" *ngIf="expense.state === 'Pending' && expense.referredBack">
        <mat-icon>error</mat-icon>
        Over budget - Referred back
      </span>
    </div>

    <span class="label submitted-date">
      {{expense.addedByPatient ? 'Submitted by patient on ' : 'Created by administrator on '}} {{ expense.created |
      date:'d MMM yyyy' }} at {{ expense.created | date: 'h:mm aaa' }}
    </span>
    <span class="label visit-desc">{{ expense.visitType }}: {{ expense.visitTitle }}: {{ expense.visitAttendance |
      enumToText: VisitAttendance }}</span>

    <div class="title-cost-wrap" *ngIf="expense.categoryType == 'Distance'">
      <span class="result-heading title">{{ expense.categoryName }}</span>
      <span class="result-heading cost">
        {{ expense.distanceAmount }} {{ expense.distanceUnit }}
        <span *ngIf="expense.amount > 0"> / {{ expense.currency }} {{ expense.amount | number:'1.2-2' }}</span>
      </span>
    </div>

    <app-expense-check-preview [patientExpense]="expense"></app-expense-check-preview>

    <div class="title-cost-wrap" *ngIf="expense.categoryType == 'Value'">
      <span class="result-heading title">
        {{ expense.categoryName }}<span
          *ngIf="!stringHelper.isNullOrEmpty(expense.subCategoryName) && expense.subCategoryName !== 'None'">
          - {{
          expense.subCategoryName
          }}
        </span>
      </span>
      <span class="result-heading cost">{{ expense.currency}} {{ expense.amount | number:'1.2-2' }}</span>
    </div>

    <div class="desc">
      <p *ngIf="expense.policyReminders">{{ expense.policyReminders }}</p>
      <p *ngIf="expense.notes">{{ expense.notes }}</p>
    </div>

    <p class="rejected-reason orange" *ngIf="expense.underInvestigation">
      <strong>Reason for Investigation:</strong> {{
      expense.reasonForInvestigation
      }}
    </p>

    <p class="rejected-reason" *ngIf="expense.state == 'Rejected'">
      <strong>Rejection Reason:</strong> {{
      expense.rejectionReason
      }}
    </p>

    <div class="images">
      <div class="image-item" *ngFor="let image of expense.imageUrls; let i = index">
        <ng-container *ngIf="!uploadIsPdf(image.imageUrl)">
          <img [src]="image.thumbnailUrl" (click)="viewReceipt(image.imageUrl)" alt="" />
        </ng-container>

        <ng-container *ngIf="uploadIsPdf(image.imageUrl)">
          <a href="{{ image.imageUrl }}" target="_blank" title="View PDF" class="pdf-icon">
            <i class="far fa-file-pdf"></i>
          </a>
        </ng-container>
      </div>
    </div>


    <!--<div class="images">
    <div class="image-item" *ngFor="let imageUrl of expense.imageUrls; let i = index">
      <ng-container *ngIf="!uploadIsPdf(imageUrl)">
        <img [src]="imageUrl" (click)="viewReceipt(imageUrl)" alt="" />
      </ng-container>

      <ng-container *ngIf="uploadIsPdf(imageUrl)">
        <span class="pdf-icon">
          <a href="{{ imageUrl }}" target="_blank" title="View PDF"><i class="far fa-file-pdf"></i></a>
        </span>
      </ng-container>
    </div>
  </div>-->

    <ul *ngIf="expense.state !== 'Rejected'" class="actions">
      <li
        *ngIf="expense.state === 'Pending' && !expense.underInvestigation && authService.hasPermission(Permissions.PatientExpenseApproveReject)">
        <a (click)="onApproveExpenseClaim(expense.id, ExpenseCategoryType[expense.categoryType])"
          class="btn btn-primary btn-slim">Approve</a>
      </li>
      <li *ngIf="expense.state == 'Approved' && authService.hasPermission(Permissions.ExpenseWrite)">
        <a (click)="onMarkAsPaid(expense.id)" class="btn btn-primary btn-slim">Mark as Paid</a>
      </li>
      <li *ngIf="expense.state === 'Pending' && authService.hasPermission(Permissions.PatientExpenseApproveReject)">
        <button (click)="onRejectModal(expense.id)" [disabled]="expense.overBudgetRequestRaised"
          class="btn btn-outline btn-slim reject-button">Reject</button>
      </li>
      <li *ngIf="expense.state == 'Paid'">&nbsp;</li>
    </ul>
    <div class="clearfix"></div>

    <div class="reason-for-edit" *ngIf="expense.reasonsForEdit.length > 0">
      <label><i class="fas fa-pencil" aria-hidden="true"></i> Reasons for edit</label>
      <ul>
        <li *ngFor="let reason of expense.reasonsForEdit">
          {{ reason.created | date: 'd MMM yyyy' }} at {{ reason.created | date: 'h:mm aaa' }} -
          {{reason.reason}}
        </li>
      </ul>
    </div>
  </div>

</div>

<app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
  (pageSelected)="loadPatientExpenses($event)"></app-pagination>

<app-modal #approveDistanceModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Approve Distance Expense?</h2>
    </div>
    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="approveDistanceForm">
        <div class="form-group">
          <label>Currency</label>
          <app-dropdown-input #currencySelect name="currency" [parentForm]="approveDistanceForm"
            [options]="currencyOptions" placeholder="Select Currency..."
            (selectValueChanged)="onCurrencySelectChanged($event)"></app-dropdown-input>
        </div>
        <div class="form-group">
          <label>Amount</label>
          <input type="text" class="form-control" formControlName="amount">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="approveDistanceModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onApproveDistanceExpenseClaim()" class="btn btn-primary"
            [ngClass]="{'disabled': !approveDistanceForm.valid}">
            <span *ngIf="approveFormIsProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!approveFormIsProcessing">Approve</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #rejectExpenseModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Reject Expense</h2>
    </div>
    <div class="modal-body">
      <p>This rejection reason will be displayed to the patient in their app.</p>
      <form class="modal-form-full-width" [formGroup]="rejectForm">
        <div class="form-group">
          <label>Rejection Reason</label>
          <textarea class="form-control" formControlName="reason"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="rejectExpenseModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmRejectExpense()" class="btn btn-primary" [ngClass]="{'disabled': !rejectForm.valid}">
            <span *ngIf="rejectFormIsProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!rejectFormIsProcessing">Reject</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #confirmExpenseApprove>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Expense will go to manual payment</h2>
    </div>
    <div class="modal-body">
      <p>{{caxtonIncompatibleData?.caxtonIncompatibleReason}}</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="closeConfirmExpenseApproveModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="confirmExpenseClaimApproval()" class="btn btn-primary">
            <span *ngIf="confirmExpenseClaimApprovalProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!confirmExpenseClaimApprovalProcessing">Continue</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-create-expense-modal #createExpenseModal (expenseCreated)="reloadExpenses()"></app-create-expense-modal>
<app-edit-expense-modal #editExpenseModal (expenseUpdated)="reloadExpenses()"></app-edit-expense-modal>
<app-start-investigation-modal #startInvestigationModal
  (refreshResult)="loadPatientExpenses(page)"></app-start-investigation-modal>
<app-close-investigation-modal #closeInvestigationModal
  (refreshResult)="loadPatientExpenses(page)"></app-close-investigation-modal>