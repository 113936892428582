export class SiteTrialRelation {
  public id: string;
  public code: string;
  public name: string;
  public siteId: string;
  public siteName: string;
  public siteAddress: string;
  public siteNumber: string;
  public introductionMethod: number;
  public approvalStatus: number;
  public approvalDate: Date;
  public declinedReason: number;
  public declinedReasonText: string;
  public introductionOccurred: boolean;
  public introductionDate: Date;
  public trialApiEnabled: boolean;

  public static fromObject(obj: any) {
    let relation = new SiteTrialRelation();
    relation.id = obj.id;
    relation.code = obj.code;
    relation.name = obj.name;
    relation.siteId = obj.siteId;
    relation.siteName = obj.siteName;
    relation.siteAddress = obj.siteAddress;
    relation.siteNumber = obj.siteNumber;
    relation.introductionMethod = obj.introductionMethod;
    relation.approvalStatus = obj.approvalStatus;
    relation.approvalDate = new Date(obj.approvalDate);
    relation.declinedReason = obj.declinedReason;
    relation.declinedReasonText = obj.declinedReasonText;
    relation.introductionOccurred = obj.introductionOccurred;
    relation.introductionDate = obj.introductionDate !== null && obj.introductionDate !== undefined ? new Date(obj.introductionDate) : null;
    relation.trialApiEnabled = obj.trialApiEnabled;

    return relation;
  }
}
