import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import {CaxtonBalance} from "../../features/trial/trial-details/trial-payments.model";
import {BankRequirements, CountryInfo} from "../models/payment.models";
import {environment} from "../../../environments/environment";
import { GetWalletBalanceRequest, WalletBalancesViewModel } from '../models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) { }

  getBalances(trialId: string): Observable<CaxtonBalance[]> {
    return this.httpClient.get<CaxtonBalance[]>(`${environment.apiUrl}/payment/balances?trialId=${trialId}`);
  }

  getCountries(trialId: string): Observable<CountryInfo[]> {
    return this.httpClient.get<CountryInfo[]>(`${environment.apiUrl}/payment/countries?trialId=${trialId}`);
  }

  getBankRequirements(trialId: string, ccyCountry: string, ccyCurrency: string): Observable<BankRequirements> {
    return this.httpClient.get<BankRequirements>(`${environment.apiUrl}/payment/bank-requirements?trialId=${trialId}&ccyCountry=${ccyCountry}&ccyCurrency=${ccyCurrency}`);
  }

  getWalletBalances(request: GetWalletBalanceRequest): Observable<WalletBalancesViewModel> {
    return this.httpClient.get<WalletBalancesViewModel>(`${environment.apiUrl}/payment/${request.patientTrialId}/wallet/${request.walletId}/view-balance`);
  }
}
