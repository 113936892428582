import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';
import { SelectOption } from '../../core/models/select-option.model';
import { SiteContactService } from '../../core/services/site-contact.service';
import { DropdownInputComponent } from '../dropdown-input/dropdown-input.component';

@Component({
  selector: 'app-create-site-contact-modal',
  templateUrl: './create-site-contact-modal.component.html',
  styleUrls: ['./create-site-contact-modal.component.scss']
})
export class CreateSiteContactModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') modalWindow: ModalComponent;
  @ViewChild('statusDropdown') statusDropdown: DropdownInputComponent;

  @Output('siteContactCreated') siteContactCreated = new EventEmitter<boolean>();

  statusOptions: SelectOption[] = [
    {value: 'true', text: 'Active'},
    {value: 'false', text: 'Inactive'}
  ];

  isOpen = false;
  form: UntypedFormGroup;
  isProcessing = false;

  constructor(private alertService: AlertService, private router: Router, private siteContactService: SiteContactService) {

  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      active: new UntypedFormControl('false'),
      consent: new UntypedFormControl(false, Validators.requiredTrue)
    });
  }

  ngAfterViewInit(): void {
    this.statusDropdown.setValue('false');
  }

  open(): void {
    this.isOpen = true;
    this.form.reset();
    this.modalWindow.show();
    this.statusDropdown.setValue('false');
  }

  close(): void {
    this.isOpen = false;
    this.modalWindow.hide();
  }

  onSubmit(): void {
    const form = this.form;

    if (!form.valid) {
      return;
    }

    form.patchValue({processing: true});

    this.siteContactService.createSiteContact(
      form.get('firstname').value,
      form.get('lastname').value,
      form.get('email').value,
      form.get('active').value === 'true',
      form.get('consent').value,
      []
    ).subscribe({
      next: () => {
        form.value.processing = false;

        this.actionSuccessful();
      },
      error: err => {
        form.value.processing = false;
        this.alertService.showWarningAlert(err.error.title);
      }
    });
  }

  private actionSuccessful() {
    this.isProcessing = false;
    this.alertService.showSuccessAlert('Site Contact Successfully Created');
    this.siteContactCreated.emit(true);
    this.form.reset();
    this.close();
  }

}
