import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";
import { FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { PatientService } from "../../core/services/patient.service";
import { PatientMergeFormModel } from "./patient-merge-form.model";

@Component({
  selector: 'app-patient-merge-modal',
  templateUrl: './patient-merge-modal.component.html',
  styleUrl: './patient-merge-modal.component.scss'
})
export class PatientMergeModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  @Output() mergeRequested: EventEmitter<any> = new EventEmitter<any>();

  @Input() patientTrialId: string;

  form: FormGroup;

  constructor(private _patientService: PatientService) { }

  ngOnInit(): void {
    this.form = new FormGroup<PatientMergeFormModel>({
      trialId: new UntypedFormControl('', Validators.required),
      patientId: new UntypedFormControl('', Validators.required),
      processing: new UntypedFormControl(false)
    });
  }

  public show() {
    this.modal.show();
  }

  hideModal(){
    this.modal.hide();
  }

  onSubmitMergeRequest() {

  }
}
