export class BankAccount {
  public bankAccountEnabled: boolean;
  public bankNameAddress: string;
  public accountHolderName: string;
  public accountNumber: string;
  public bicSwiftCode: string;
  public routingNumber: string;

  constructor(init?: Partial<BankAccount>) {
    Object.assign(this, init);
  }
}
