import { SelectOption } from "../models/select-option.model";

export class VisitAttendanceOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "Attended",
        "text": "Attended"
      },
      {
        "value": "Cancelled",
        "text": "Cancelled"
      },
      {
        "value": "NoShow",
        "text": "No Show"
      },
      {
        "value": "PendingConfirmation",
        "text": "Pending Confirmation"
      },
      {
        "value": "AutoAttended",
        "text": "Auto Attended"
      }
    ];
  }
}
