<app-modal #modal>
  <form [formGroup]="form" (submit)="onFormSubmit()">
    <div class="modal-header">
      <h2 class="modal-title">{{title}}</h2>
    </div>
    <div class="modal-body">
      <p style="margin-bottom: 30px; text-align: center;" *ngIf="text">{{text}}</p>

      <div [ngClass]="{'visibility-hidden': !patientLoaded}">
        <label style="margin-left: 16px;">Home Address<sup>*</sup></label>
        <app-input-address #homeAddress [parentForm]="form" controlName="homeAddress"></app-input-address>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button type="submit" class="btn btn-primary">
            <span *ngIf="form.get('processing').value">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!form.get('processing').value">Save Address</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</app-modal>
