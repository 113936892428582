import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from "./core/services/auth.service";
import { AuthStatus } from "./core/auth-status.enum";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  appTitle = "PatientGo";
  environmentName = "";
  versionNumber = '';
  isIframe: boolean;
  private readonly _destroying$ = new Subject<void>();

  constructor(private titleService: Title, private authService: AuthService, private msalBroadcastService: MsalBroadcastService, private msalService: MsalService, private renderer: Renderer2) {
    this.environmentName = environment.environment;
    this.versionNumber = environment.version;
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })
    this.titleService.setTitle(this.appTitle);

    // Add class names to the body based on the users authentication status
    this.authService.authStatus.subscribe((status) => {
      if (status === AuthStatus.Authenticated) {
        this.renderer.addClass(document.body, "authenticated");
        this.renderer.removeClass(document.body, "unauthenticated");
      } else {
        this.renderer.removeClass(document.body, "authenticated");
        this.renderer.addClass(document.body, "unauthenticated");
      }
    });

    this.authService.rolesLoaded.subscribe((rolesLoaded) => {
      if (rolesLoaded) {
        this.renderer.addClass(document.body, "roles-loaded");
      } else {
        this.renderer.removeClass(document.body, "roles-loaded");
      }
    });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
