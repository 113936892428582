import moment from "moment";

export class DateHelper {

  public static toDateNoTimeZone(dateStr: string): Date | null {
    if (!dateStr) return null;

    const momentDate = moment.utc(dateStr);
    const localDate = new Date(momentDate.year(), momentDate.month(), momentDate.date(), momentDate.hour(), momentDate.minute(), momentDate.second());

    return localDate;
  }

  public static dateAsUTC(date: Date) {
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;

    return new Date(date.getTime() - userTimezoneOffset);
  }

  public static timeToMinutes(hrs: number, minutes: number, period: string) {
    if (hrs > 0) {
      if (period === 'PM' && hrs < 12) {
        hrs = hrs + 12;
      }

      return (hrs * 60) + minutes;
    }

    return null;
  }

  public static minutesToTimeStr(totalMinutes: number) {
    let period = 'AM';
    let lz = s => ('00' + s).slice(-2);
    // @ts-ignore
    let hours = Math.round(Math.floor(totalMinutes / 60));
    // @ts-ignore
    let mins = totalMinutes - (hours * 60);

    if (hours >= 12) {
      period = 'PM';
    }

    if (hours > 12) {
      hours = hours - 12;
    }

    return hours + ':' + lz(mins) + " " + period;
  }

  public static dateUTCToLocal(sourceDate: Date) {
    if (sourceDate === null) {
      return null;
    }

    return new Date(sourceDate + 'Z');
  }

  public static dateToInputStr(date: Date) {
    let lz = s => ('00' + s).slice(-2);
    let month: number = date.getMonth() + 1;
    return lz(date.getDate()) + '/' + lz(month) + '/' + date.getFullYear();
  }

  public static dateToDdMmYyyy(date: Date) {
    if (date === undefined || date == null || date instanceof Date === false)
      return '';

    let lz = s => ('00' + s).slice(-2);
    let month: number = date.getMonth() + 1;
    return lz(date.getDate()) + '/' + lz(month) + date.getFullYear();
  }

  public static dateToYyyyMmDd(date: Date) {
    if (date == null)
      return '';

    let lz = s => ('00' + s).slice(-2);
    let month: number = date.getMonth() + 1;
    return date.getFullYear() + '/' + lz(month) + '/' + lz(date.getDate());
  }

  public static yyyyMmDdToDate(str: string) {
    if (str == null || str === '') {
      return null;
    }

    return new Date(str.replace(new RegExp("/", "g"), "-") + 'T12:00:00.000Z');
  }

  public static stringToDate(str: string) {
    if (str === undefined || str == null || str === '') {
      return null;
    }

    const parts = str.split('/');

    return new Date(parts[2] + '-' + parts[1] + '-' + parts[0] + 'T12:00:00.000Z');
  }

}

export class IllyTime {
  public hours: number = 0;
  public minutes: number = 0;
  public period: string = 'AM';
  public isValid = false;

  public static parseMinutes(totalMinutes: number): IllyTime {
    let t = new IllyTime();
    if (totalMinutes >= 0) {
      t.hours = Math.floor(totalMinutes / 60);
      t.minutes = totalMinutes % 60;
      t.period = t.hours >= 12 ? 'PM' : 'AM';
      t.isValid = true;

      if (t.hours > 12) {
        t.hours = t.hours - 12;
      }

      return t;
    }

    return t;
  }

  public static parseString(time: string): IllyTime {
    let t = new IllyTime();
    if (time !== null && time.length === 5 && time.includes(':')) {
      let parts = time.split(':');
      t.hours = +parts[0];
      t.minutes = +parts[1];
      t.period = t.hours >= 12 ? 'PM' : 'AM';

      if (t.hours > 12) {
        t.hours = t.hours - 12;
      }

      t.isValid = true;

      return t;
    }

    return t;
  }

  hoursAs24(): number {
    return this.period === 'PM' && this.hours < 12 ? this.hours + 12 : this.hours;
  }

  /**
   * Returns time as 24 hour string i.e. 23:30
   */
  to24HourString(): string {
    if (this.isValid) {
      let lz = s => ('00' + s).slice(-2);
      let hours = this.hoursAs24();

      return lz(hours) + ':' + lz(this.minutes);
    }

    return '';
  }

  /**
   * Returns the time expressed in minutes i.e. 630 (10:30)
   */
  totalMinutes(): number {
    if (this.isValid) {
      if (this.period === 'PM') {
        return ((this.hours % 12 + 12) * 60) + this.minutes;
      } else {
        return (this.hours * 60) + this.minutes;
      }
    }

    return null;
  }
}
