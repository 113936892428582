import { ExpenseClaimState } from './../../../../core/models/expense.model';
import { Component, Input, OnInit } from '@angular/core';
import { ExpenseClaimViewModel } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-reject-reason',
  templateUrl: './expense-reject-reason.component.html',
  styleUrls: ['./expense-reject-reason.component.scss']
})
export class ExpenseRejectReasonComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;

  ExpenseClaimState = ExpenseClaimState;

  constructor() { }

  ngOnInit(): void {
  }

}
