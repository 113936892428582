import { CaregiverTravelling } from "./expense-check.model";

export enum PatientTripType {
  GroundTransport = 0,
  Flight = 1,
  Train = 2,
  Accommodation = 3,
  RentalCar = 4
}

export enum GroundTransportTravelType {
  HomeToAirport,
  AirportToHotel,
  HotelToSite,
  HomeToSite,
  AirportToSite
}

export enum PatientTripDirection {
  Outbound,
  Return
}

export interface OverBudgetRequestDetailsForTripsViewModel {
  careGiverTravelling?: CaregiverTravelling,
  visitTitle: string;
  visitDate?: Date;
  tripTypeGroups: OverBudgetRequestTripTypeGroup[]
}

export interface OverBudgetRequestTripTypeGroup {
  type: PatientTripType,
  subGroups: OverBudgetRequestTripTypeSubGroup[]
}

export interface OverBudgetRequestTripTypeSubGroup {
  groundTransportTravelType?: GroundTransportTravelType,
  budgetCurrency: string,
  budgetCost?: number,
  actualCostInBudgetCurrency: number,
  specificCategoryTrialPolicyText: string,
  requiredInformationText: string,
  actualCosts: OverBudgetRequestTripTypeSubGroupActualValue[],
  tripsDetails: OverBudgetRequestTripsDetails[]
}

export interface OverBudgetRequestTripTypeSubGroupActualValue {
  currency: string;
  value?: number;
}

export interface OverBudgetRequestTripsDetails {
  tripId: string,
  startDate?: Date,
  endDate?: Date,
  currency: string,
  amount?: number,
  tripDirection?: PatientTripDirection
}

export interface OverBudgetRequestForTripsRequest {
  tripIds: string[],
  requiredInformation: string,
  budgetCost?: number,
  currency: string,
  totalActualCost: number
}

export interface UpdatePatientTripStatusRequest {
  patientTripId: string;
  tripBookingStatus: string;
}

export interface UpdatePatientTripStatusResult {
  overBudgetRequestClosed: boolean;
}
