import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { MuvVehicle } from "../models/muv-models/muv-vehicle.model";
import { MuvAirline } from "../models/muv-models/muv-airline.model";
import { Observable } from "rxjs";
import { MuvAiportMeetingProcedure } from "../models/muv-models/muv-airport-meeting-procedure.model";

@Injectable({
  providedIn: 'root'
})
export class MuvService {
  private _endpoint = '/muvride';

  constructor(private _httpClient: HttpClient) { }

  /**
   * Get a list of vehicles from MUV
   */
  vehicles(): Observable<MuvVehicle[]> {
    return this._httpClient.get<MuvVehicle[]>(environment.apiUrl + this._endpoint + '/vehicles');
  }

  /**
   * Get a list of airlines from MUV
   */
  airlines(): Observable<MuvAirline[]> {
    return this._httpClient.get<MuvAirline[]>(environment.apiUrl + this._endpoint + '/airlines');
  }

  /**
   * Get a list of airport meeting procedures from MUV
   */
  airportMeetingProcedures(airportCode: string): Observable<MuvAiportMeetingProcedure[]> {
    let params = new HttpParams();
    params = params.set('airportCode', airportCode);

    return this._httpClient.get<MuvAiportMeetingProcedure[]>(environment.apiUrl + this._endpoint + '/AirportMeetingProcedures', {params: params});
  }
}
