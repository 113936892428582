import { DateHelper } from "../helpers/date-helper";

export class TravelRequest {
  public id: string;
  public state: string;
  public created: Date;
  public isWip: boolean;
  public wipUpdateDate?: Date;
  public patientCode: string;
  public patientId: string;
  public patientName: string;
  public patientAddress: string;
  public patientCountry: string;
  public visitId: string;
  public visitTitle: string;
  public visitDate: Date;
  public visitTimeMinutes: number;
  public visitType: string;
  public siteId: string;
  public siteName: string;
  public siteAddress: string;
  public siteCountry: string;
  public trialId: string;
  public trialLabel: string;
  public patientTrialId: string;
  public updatingAdministratorFirstName: string;
  public updatingAdministratorLastName: string;
  public notes: string;
  public apiConsumerId: string;
  public apiConsumerName: string;

  public static fromObject(rsp: any) {
    let request = new TravelRequest();
    request.id = rsp.id;
    request.state = rsp.state;
    request.created = DateHelper.dateUTCToLocal(rsp.created);
    request.patientCode = rsp.patientCode;
    request.patientId = rsp.patientId;
    request.patientName = rsp.patientName;
    request.patientAddress = rsp.patientAddress;
    request.patientCountry = rsp.patientCountry;
    request.visitId = rsp.visitId;
    request.visitTitle = rsp.visitTitle;
    request.visitDate = new Date(rsp.visitDate);
    request.visitTimeMinutes = rsp.visitTimeMinutes;
    request.siteId = rsp.siteId;
    request.siteName = rsp.siteName;
    request.siteAddress = rsp.siteAddress;
    request.siteCountry = rsp.siteCountry;
    request.trialId = rsp.trialId;
    request.trialLabel = rsp.trialLabel;
    request.patientTrialId = rsp.patientTrialId;
    request.isWip = rsp.isWip;
    request.wipUpdateDate = DateHelper.dateUTCToLocal(rsp.wipUpdateDate);
    request.updatingAdministratorFirstName = rsp.updatingAdministratorFirstName;
    request.updatingAdministratorLastName = rsp.updatingAdministratorLastName;
    request.notes = rsp.notes;
    request.apiConsumerId = rsp.apiConsumerId;
    request.apiConsumerName = rsp.apiConsumerName;

    switch (rsp.visitType) {
      case 'Home':
        request.visitType = 'Home Visit';
        break;
      case 'OnSite':
        request.visitType = 'On-Site';
        break;
      case 'Virtual':
        request.visitType = 'Virtual';
        break;
      case 'Telephone':
        request.visitType = 'Telephone';
        break;
    }

    return request;
  }
}
