import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SelectOption } from "../../../../../core/models/select-option.model";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: '[visit-table-cell-select]',
  templateUrl: './visit-table-cell-select.component.html',
  styleUrls: ['./visit-table-cell-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisitTableCellSelectComponent {
  @Input('items') items: SelectOption[] = [];
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('minWidth') minWidth: string;
  @Input('maxWidth') maxWidth: string;

  @Output('valueChanged') valueChanged = new EventEmitter();

  onValueChanged(event: any) {
    this.valueChanged.emit(event);
  }

}
