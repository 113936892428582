import { AlertService } from './../../../../shared/alert/alert.service';
import { ExpenseService } from './../../../../core/services/expense.service';
import { CaregiverTravelling } from './../../../../core/models/expense-check.model';
import { OverBudgetRequestDetailsViewModel, ExpenseClaimState, CreateOverBudgetRequestByExpenseClaimRequest, CreateOverBudgetRequestByCategoriesRequest } from './../../../../core/models/expense.model';
import { Component, ElementRef, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-over-budget-request-modal',
  templateUrl: './over-budget-request-modal.component.html',
  styleUrl: './over-budget-request-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OverBudgetRequestModalComponent {
  @Output() overBudgetRequestRaised: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('modalBody') modalBody: ElementRef;
  ExpenseClaimState = ExpenseClaimState;
  CaregiverTravelling = CaregiverTravelling;
  loading: boolean = false;
  ruleExists: boolean = false;

  overBudgetRequestForm = new FormGroup({
    requiredInformation: new FormControl<string | null>(''),
    actualCostInBudgetCurrency: new FormControl<number | null>(null, Validators.required),
    budgetCost: new FormControl<number | null>(null)
  });

  overBudgetRequestDetailsViewModel: OverBudgetRequestDetailsViewModel;
  expenseClaimId: string;
  visitId: string;

  constructor(private expenseService: ExpenseService, private alertService: AlertService) { }

  getBadgeClass(state: ExpenseClaimState) {
    switch (state) {
      case ExpenseClaimState.Pending:
      case ExpenseClaimState.CaxtonFailed:
        return 'badge-warning';
      case ExpenseClaimState.Approved:
      case ExpenseClaimState.CaxtonApproved:
      case ExpenseClaimState.Processing:
        return 'badge-success';
      case ExpenseClaimState.Rejected:
        return 'badge-danger';
      default:
        return 'badge-grey';
    }
  }

  show() {
    if (this.overBudgetRequestDetailsViewModel.overBudgetRequirement !== null) {
      this.overBudgetRequestForm.controls.requiredInformation.addValidators(Validators.required);
    }

    if (this.overBudgetRequestDetailsViewModel.actualBudgetCost !== null) {
      this.overBudgetRequestForm.controls.actualCostInBudgetCurrency.disable();
      this.overBudgetRequestForm.controls.actualCostInBudgetCurrency.setValue(this.overBudgetRequestDetailsViewModel.actualBudgetCost);
    } else {
      this.overBudgetRequestForm.controls.actualCostInBudgetCurrency.enable();
    }

    if (this.ruleExists) {
      this.overBudgetRequestForm.controls.budgetCost.setValue(this.overBudgetRequestDetailsViewModel.budgetCost);
      this.overBudgetRequestForm.controls.budgetCost.disable();
    } else {
      this.overBudgetRequestForm.controls.budgetCost.addValidators(Validators.required);
      this.overBudgetRequestForm.controls.budgetCost.enable();
    }

    this.modal.show();

  }

  submitOverBudgetRequest() {
    this.loading = true;

    if (this.ruleExists) {
      this.submitOverBudgetRequestByExpenseClaim();
      return;
    }

    this.submitOverBudgetRequestByCategories();
  }

  submitOverBudgetRequestByCategories() {
    let request = this.overBudgetRequestForm.value as CreateOverBudgetRequestByCategoriesRequest;
    request.categories = this.overBudgetRequestDetailsViewModel.categories;
    request.visitId = this.visitId

    this.expenseService.createOverBudgetRequestByCategories(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Over budget request successfully created.");
        this.overBudgetRequestRaised.emit();
        this.loading = false;
        this.hideModal();
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  submitOverBudgetRequestByExpenseClaim() {
    let request = this.overBudgetRequestForm.value as CreateOverBudgetRequestByExpenseClaimRequest;
    request.expenseClaimId = this.expenseClaimId;

    this.expenseService.createOverBudgetRequestByExpenseClaim(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Over budget request successfully created.");
        this.overBudgetRequestRaised.emit();
        this.loading = false;
        this.hideModal()
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  hideModal() {
    this.overBudgetRequestForm.reset();
    this.ruleExists = false;

    let element = document.getElementById("modalBody");
    element.scrollTop = 0;

    this.modal.hide();
  }
}
