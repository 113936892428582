import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ObjectHelper } from '../../core/helpers/object.helper';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() pageSelected = new EventEmitter<number>();
  @Input() currentPage: number = 1;
  @Input() totalPageCount: number = 1;
  pageCount = [];
  pagesToShow: number[] = [];
  private MAX_PAGE_LINKS = 10;

  ngOnInit() {
    this.pageCount = new Array(this.totalPageCount);
    this.pagesToShow = this.getPagesToShow();
  }

  ngOnChanges() {
    this.pageCount = new Array(this.totalPageCount);
    this.pagesToShow = this.getPagesToShow();
  }

  goToPage(page: number) {
    if (page < 1 || page > this.totalPageCount) {
      return;
    }

    this.currentPage = page;
    this.pagesToShow = this.getPagesToShow();

    this.pageSelected.emit(page);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private getPagesToShow() {
    const pagesToShow = [];
    const maxPageLinks = Math.min(this.totalPageCount, this.MAX_PAGE_LINKS);
    const half = Math.floor(maxPageLinks / 2);

    if (ObjectHelper.isUndefinedNullOrEmpty(this.currentPage))
      this.currentPage = 1;

    if (ObjectHelper.isUndefinedNullOrEmpty(this.totalPageCount))
      this.totalPageCount = 1;

    let start = 1;
    let end = this.totalPageCount;
    if (this.totalPageCount > this.MAX_PAGE_LINKS) {
      start = this.currentPage - half;
      end = this.currentPage + half;

      if (start < 1) {
        end += 1 - start;
        start = 1;
      }

      if (end > this.totalPageCount) {
        start -= end - this.totalPageCount;
        end = this.totalPageCount;
      }
    }

    for (let i = start; i <= end; i++) {
      pagesToShow.push(i);
    }

    return pagesToShow;
  }
}
