import {SearchPage} from "./search-page.model";
import {DateHelper} from "../helpers/date-helper";

export class TrialBookingSearch extends SearchPage {
  public results: TrialBookingSearchResult[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(new TrialBookingSearchResult(result));
    }

    return this;
  }
}

export class TrialBookingSearchResult {
  public id: string;
  public created: Date;
  public aptDateTime: Date;
  public expires: Date;
  public status: string;
  public patientId: string;
  public patientCode: string;
  public patientName: string;
  public patientEmail: string;
  public trialName: string;
  public trialCode: string;
  public trialId: string;
  public patientTrialId: string;
  public inviteSentDate: Date;
  public bookingConfigName: string;
  public displayName: string;

  constructor(init?: Partial<TrialBookingSearchResult>) {
    Object.assign(this, init);

    if (init.inviteSentDate)
      this.inviteSentDate = DateHelper.dateUTCToLocal(init.inviteSentDate);
  }
}
