import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PatientTripMuvRide } from "../../../../core/models/patient-trip-muv-ride.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { MuvVehicle } from "../../../../core/models/muv-models/muv-vehicle.model";
import { MuvService } from "../../../../core/services/muv.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { Address } from "../../../../shared/input-address/address.model";
import { ArrayHelper } from "../../../../core/helpers/array.helper";
import { DateHelper } from "../../../../core/helpers/date-helper";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { VisitService } from "../../../../core/services/visit.service";
import { Countries } from "../../../../core/constants/countries";
import { MuvAirline } from "../../../../core/models/muv-models/muv-airline.model";

@Component({
  selector: 'app-muv-ride-view',
  templateUrl: './muv-ride-view.component.html',
  styleUrls: ['./muv-ride-view.component.scss']
})
export class MuvRideViewComponent implements AfterViewInit {
  @ViewChild('cancelRideModal') cancelRideModal: ModalComponent;
  @ViewChild('acceptQuoteModal') acceptQuoteModal: ModalComponent;
  @ViewChild('rejectQuoteModal') rejectQuoteModal: ModalComponent;

  @Output('backToList') backToList= new EventEmitter();

  ride: PatientTripMuvRide;
  allVehicles: MuvVehicle[] = [];
  airlines: MuvAirline[] = [];
  countries: { value: string, text: string }[] = [];

  cancellingRide = false;
  showCancelRideOptions = false;

  acceptingRejectingQuote = false;

  constructor(private _muvService: MuvService, private _alertService: AlertService, private _visitService: VisitService) { }

  ngAfterViewInit(): void {
    // Load vehicles
    this._muvService.vehicles().subscribe({
      next: (vehicles) => {
        this.allVehicles = vehicles;
      },
      error: (err) => this._alertService.showErrorAlert(err)
    });

    // Load airlines
    this._muvService.airlines().subscribe({
      next: (airlines) => {
        airlines.sort(ArrayHelper.dynamicSort("airline_name"));
        this.airlines = airlines;
      },
      error: (err) => this._alertService.showErrorAlert(err)
    });

    // Countries
    for (const country of Countries.all()) {
      this.countries.push({value: country.code, text: country.name});
    }

    LogHelper.log(this.ride);
  }

  shouldShowAcceptDeclineButtons(): boolean {
    let visibleStates = ['QuoteReady','QuoteChanged'];
    if (this.ride)
      return visibleStates.includes(this.ride.state) && !this.showCancelRideOptions;

    return false;
  }

  shouldShowCancelRideButton(): boolean {
    let visibleStates = ['QuotePending', 'QuoteReady', 'QuoteReceived', 'QuoteChanged', 'Unassigned', 'Active'];
    if (this.ride)
      return visibleStates.includes(this.ride.state);

    return false;
  }

  /**
   * Called when a user selects to view a ride - handles showing the ride details on screen
   * @param ride
   */
  viewRide(ride: PatientTripMuvRide) {
    LogHelper.info('Setting MUV ride on view screen', ride)
    this.ride = ride;
  }

  onShowCancelRideOptions() {
    this.showCancelRideOptions = true;
  }

  onHideCancelRideOptions() {
    this.showCancelRideOptions = false;
  }

  /**
   * Called when the user selects to go back to the list of MUV rides for the trip
   */
  onBackToList(): void {
    this.backToList.emit();
  }

  /**
   * Called when the user confirms that they want to cancel the ride
   */
  onCancelRide(): void {
    if (this.cancellingRide)
      return;

    this.cancellingRide = true;

    this._visitService.cancelMuvRide(this.ride.patientTripId, this.ride.id).subscribe({
      next: () => {
        this.ride.state = 'CancelledCms'; // Mark the ride as CMS cancelled so we don't to reload the list
        this.cancellingRide = false;
        this._alertService.showSuccessAlert('Ride cancelled successfully');
        this.showCancelRideOptions = false;
      },
      error: err => {
        this.cancellingRide = false;
        this._alertService.showErrorAlert(err)
      },
    });
  }

  onConfirmAcceptQuote() {
    this.acceptingRejectingQuote = true;

    this._visitService.acceptMuvQuote(this.ride.patientTripId, this.ride.id).subscribe({
      next: () => {
        this.ride.state = 'QuoteAccepted';
        this.acceptingRejectingQuote = false;
        this._alertService.showSuccessAlert('Quote successfully accepted');
        this.acceptQuoteModal.hide();
      },
      error: err => {
        this.acceptingRejectingQuote = false;
        this._alertService.showErrorAlert(err);
        this.acceptQuoteModal.hide();
      }
    });
  }

  onConfirmRejectQuote() {
    this.acceptingRejectingQuote = true;

    this._visitService.rejectMuvQuote(this.ride.patientTripId, this.ride.id).subscribe({
      next: () => {
        this.ride.state = 'QuoteDeclined';
        this.acceptingRejectingQuote = false;
        this.rejectQuoteModal.hide();
        this._alertService.showSuccessAlert('Quote successfully rejected');
      },
      error: err => {
        LogHelper.log(err);
        this.acceptingRejectingQuote = false;
        this._alertService.showErrorAlert(err);
        this.rejectQuoteModal.hide();
      }
    });
  }

  /**
   * Display label for the vehicle type
   * @param vehicleId
   */
  vehicleDisplayLabel(vehicleId: number): string {
    const vehicle = this.allVehicles.find(v => v.vehicle_type === vehicleId);

    return vehicle ? vehicle.description : 'Unknown';
  }

  /**
   * Display label for the request type
   * @param requestType
   */
  requestTypeDisplayLabel(requestType: string): string {
    return requestType === 'booking' ? 'Booking' : 'Quote';
  }

  /**
   * Display label for the ride type
   * @param rideType
   */
  rideTypeDisplayLabel(rideType: string): string {
    return rideType.toLowerCase() === 'hourly' ? 'Hourly Booking' : 'One-way'
  }

  /**
   * Display label for the airline
   * @param airlineId
   */
  airlineDisplayName(airlineId: string): string {
    const airline = this.airlines.find(a => a.airline_code === airlineId);

    return airline ? airline.airline_name : 'Unknown';
  }

  /**
   * Display label for the meeting procedure
   * @param minutes
   */
  stopPickupTimeDisplayLabel(minutes: number): string {
    return DateHelper.minutesToTimeStr(minutes);
  }

  /**
   * Displays an address is a suitable format for the UI
   * @param address
   */
  displayAddress(address: Address): string {
    if (address === null)
      return '';

    const addressParts = [];

    if (address.apt) {
      addressParts.push(address.apt);
    }

    if (address.street) {
      addressParts.push(address.street);
    }

    if (address.city) {
      addressParts.push(address.city);
    }

    if (address.state) {
      addressParts.push(address.state);
    }

    if (address.zip) {
      addressParts.push(address.zip);
    }

    if (address.countryCode) {
      addressParts.push(address.countryCode);
    }

    return addressParts.join('<br>');
  }

}
