import { AlertService } from './../../../../shared/alert/alert.service';
import { TrialService } from 'app/core/services/trial.service';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit } from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import { UpdateVisitScheduleRequest, VisitScheduleViewModel } from "../trial-visit-schedule.model";

@Component({
  selector: 'app-trial-visit-schedule',
  templateUrl: './trial-visit-schedule.component.html',
  styleUrls: ['./trial-visit-schedule.component.scss']
})
export class TrialVisitScheduleComponent implements OnInit {
  @Input() trialId: string;
  visits: VisitScheduleViewModel[] = [];

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.populateVisitSchedule();
  }

  populateVisitSchedule() {
    this.trialService.getTrialVisitSchedule(this.trialId).subscribe({
      next: response => {
        this.visits = response
      },
      error: error => {
        this.alertService.showWarningAlert('Unable to retrieve trial visit schedule. Try again or contact support.');
        console.error(error);
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.visits, event.previousIndex, event.currentIndex);
  }

  removeVisit(visit: VisitScheduleViewModel) {
    this.visits.splice(this.visits.findIndex(v => v.title === visit.title), 1);
  }

  addVisits(amount: string) {
    let start = this.visits.length;
    let end = this.visits.length + (+amount);

    for (let index = start; index < end; index++) {
      this.visits.push({
        templateId: uuidv4(),
        scheduleIndex: index,
        title: `Visit ${index + 1}`,
        keyVisit: false
      });
    }
  }

  updateVisitTitle(visit: VisitScheduleViewModel, newValue: string) {
    visit.title = newValue;
  }

  save() {
    let request: UpdateVisitScheduleRequest[] = []

    for (let index = 0; index < this.visits.length; index++) {
      request.push({
        templateId: this.visits[index].templateId,
        scheduleIndex: index,
        visitTitle: this.visits[index].title
      })
    }

    this.trialService.updateTrialVisitSchedule(this.trialId, request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Visit schedule successfully updated.");
      },
      error: error => {
        this.alertService.showErrorAlert("Failed to update visit schedule. Please try again or contact an administrator.");
        console.error(error);
      }
    })
  }
}
