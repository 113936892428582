<app-modal #modal customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Close Investigation?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to close this investigation?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="showAllClaimsModal()" class="btn btn-primary">
          Yes, close
        </a>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #allClaimsModal class="all-claims-modal">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Under Investigation</h2>
    </div>
    <div class="modal-body">
      <p>Put all pending expenses for this patient visit under investigation?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a class="btn btn-primary" (click)="onConfirmCloseInvestigation(true)">
            <span *ngIf="processing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processing">Yes all expenses</span>
          </a>
        </div>

        <div class="col-6">
          <a class="btn btn-secondary" (click)="onConfirmCloseInvestigation(false)">
            <span *ngIf="processing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processing">No, only this expense</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
