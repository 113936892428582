import { UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const ExpenseRuleCategoriesValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const allCategories = formGroup.get('allCategories');
  const categories = formGroup.get('categories');

  if (allCategories && categories) {
    const allCategoriesValue = allCategories.value as boolean;
    const categoriesValue = categories.value as string[] | null;

    if (!allCategoriesValue && (!categoriesValue || categoriesValue.length === 0)) {
      return { categoriesRequired: true };
    }
  }

  return null;
};
