import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieve a list of available translation versions
   */
  list() {
    return this.http.get(environment.apiUrl + '/translation');
  }
}
