import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddTripV2ModalComponent } from "./add-trip-v2-modal/add-trip-v2-modal.component";
import { EditTripV2ModalComponent } from "./edit-trip-v2-modal/edit-trip-v2-modal.component";

@Component({
  selector: 'app-add-edit-trip-modal',
  templateUrl: './add-edit-trip-modal.component.html',
  styleUrls: ['./add-edit-trip-modal.component.scss']
})
export class AddEditTripModalComponent {
  @ViewChild('addTripModal') addTripModal: AddTripV2ModalComponent;
  @ViewChild('editTripModal') editTripModal: EditTripV2ModalComponent;

  @Output('tripAdded') tripAdded = new EventEmitter<{visitId: string, addMuvRides: boolean}>();
  @Output('tripUpdated') tripUpdated = new EventEmitter<string>();

  @Input('muvEnabled') muvEnabled = false;

  /**
   * Shows the add trip modal
   * @param visitId
   */
  showAddTripModal(visitId: string, muvEnabled: boolean = null): void {
    if (muvEnabled !== null) {
      this.muvEnabled = muvEnabled;
    }

    this.addTripModal.show(visitId);
  }

  /**
   * Hides the add trip modal
   */
  hideAddTripModal(): void {
    this.addTripModal.hide();
  }

  /**
   * Shows the edit trip modal
   * @param tripId
   * @param defaultTab
   */
  showEditTripModal(tripId: string, defaultTab = null): void {
    this.editTripModal.show(tripId, defaultTab);
  }

  /**
   * Hides the edit trip modal
   */
  hideEditTripModal(): void {
    this.editTripModal.hide();
  }

  onTripAdded(event: {visitId: string, tripId: string, addMuvRides: boolean}): void {
    this.tripAdded.emit(event);

    if (event.addMuvRides) {
      this.showEditTripModal(event.tripId, 'MUV Rides');
    }
  }

  onTripUpdated(visitId: string): void {
    this.tripUpdated.emit(visitId);
  }

}
