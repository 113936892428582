<div class="view-head">
  <div class="row">

    <div class="col-12 d-none d-md-block col-md-3">
      <h2>Caxton</h2>
    </div>

  </div>
</div>

<div class="view-body" *ngIf="subsidiaryPaymentDetailsViewModel">

  <div class="content-panel payment-content">
    <table class="simple-form payments-form">
      <tr *ngIf="subsidiaryPaymentDetailsViewModel.caxtonAccountDetails">
        <th style="width: 140px;"><label>Caxton Payment Reference</label></th>
        <td>
          <div class="form-group caxton-balance-wrapper">
            <input type="text" class="form-control trial-payment-info" disabled
                   [value]="subsidiaryPaymentDetailsViewModel.caxtonAccountDetails.caxtonPaymentReference">
          </div>
        </td>
      </tr>
      <tr *ngIf="subsidiaryPaymentDetailsViewModel.caxtonAccountDetails">
        <th style="width: 140px;"><label>Caxton Owner Email</label></th>
        <td>
          <div class="form-group caxton-balance-wrapper">
            <input type="text" class="form-control trial-payment-info" disabled
                   [value]="subsidiaryPaymentDetailsViewModel.caxtonAccountDetails.caxtonOwnerEmail">
          </div>
        </td>
      </tr>
      <tr *ngIf="subsidiaryPaymentDetailsViewModel.caxtonBalances && subsidiaryPaymentDetailsViewModel.caxtonBalances.length">
        <th style="width: 140px;"><label for="">Currency Balances</label></th>
        <td>
          <div class="form-group caxton-balance-wrapper">
            <input *ngIf="subsidiaryPaymentDetailsViewModel.caxtonBalances && subsidiaryPaymentDetailsViewModel.caxtonBalances.length"
                   type="text" class="form-control caxton-balance trial-payment-info" disabled
                   [value]="subsidiaryPaymentDetailsViewModel.caxtonBalances[0].balance">
            <span>{{subsidiaryPaymentDetailsViewModel.caxtonBalances[0].currency}}</span>
          </div>
        </td>
      </tr>
      <tr *ngFor="let balance of subsidiaryPaymentDetailsViewModel.caxtonBalances && subsidiaryPaymentDetailsViewModel.caxtonBalances.length > 1 ? subsidiaryPaymentDetailsViewModel.caxtonBalances.slice(1) : []">
        <th style="width: 140px;"></th>
        <td>
          <div class="form-group caxton-balance-wrapper">
            <input type="text" class="form-control caxton-balance trial-payment-info" disabled
                   [value]="balance.balance">
            <span>{{balance.currency}}</span>
          </div>
        </td>
      </tr>
      <tr *ngIf="subsidiaryPaymentDetailsViewModel.caxtonBalances && subsidiaryPaymentDetailsViewModel.caxtonBalances.length">
        <th style="width: 140px;"></th>
        <td>
          <div class="form-group payment-buttons">
            <button [disabled]="textConnectionIsProcessing" type="submit" class="btn btn-outline"
                    (click)="testConnectionToCaxton()">
              <img src="../../../assets/img/icon _ refresh.svg" alt="Refresh">
              <span *ngIf="textConnectionIsProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!textConnectionIsProcessing">Refresh</span>
            </button>
            <button (click)="getBalanceAlerts()" [disabled]="!authService.hasPermission(Permissions.TrialPaymentsCaxton)"
                    class="btn btn-secondary">
              <img src="../../../assets/img/icon _ alerts.svg" alt="Alerts">Alerts
            </button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<app-modal #alertsModal [customClassName]="'alerts-modal'">
  <div class="modal-header">
    <i (click)="alertsModal.hide()" class="icon-cancel" aria-hidden="true"></i>
    <h2 class="modal-title">Currency Balance Alerts</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="alertForm">
      <div class="alert-columns">
        <label class="label">Current Balance</label>
        <label class="label">Alert at</label>
      </div>
      <div *ngFor="let balanceThreshold of balanceThresholds" class="form-group alert-balances">
        <label class="currency-label" [for]="balanceThreshold.currency">
          {{balanceThreshold.currentBalance}}
          {{balanceThreshold.currency}}
        </label>
        <div class="input-wrapper">
          <input type="number" [id]="balanceThreshold.currency" [formControlName]="balanceThreshold.currency"
                 [value]="balanceThreshold.balance">
        </div>
      </div>
      <div class="form-group alert-email">
        <label for="alertEmail" class="label">Alert to:</label>
        <input id="alertEmail" formControlName="alertEmail" type="email">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="alertsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>
      <div class="col-6">
        <button class="btn btn-primary" (click)="updateAlerts()" [ngClass]="{'disabled': !alertForm.valid}">
          <span *ngIf="alertUpdateProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!alertUpdateProcessing">Save</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
