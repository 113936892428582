import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseClaimPaymentMethodString, ExpenseCurrencyGroup } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-caxton-details-head',
  templateUrl: './expense-caxton-details-head.component.html',
  styleUrls: ['./expense-caxton-details-head.component.scss']
})
export class ExpenseCaxtonDetailsHeadComponent implements OnInit {
  @Input() expense: ExpenseCurrencyGroup;
  @Input() dataLoaded: boolean;
  ExpenseClaimPaymentMethodString = ExpenseClaimPaymentMethodString;
  @Output() searchFilter: EventEmitter<string> = new EventEmitter<string>();
  trialText: string;

  ngOnInit(): void {
    if(this.expense){
      this.setTrialText();
    }
  }

  private setTrialText() {
    this.trialText = this.expense.trialNickname ?
      `${this.expense.trialCode} ${this.expense.trialNickname} - ${this.expense.trialOpportunityNumber}` :
      `${this.expense.trialCode} ${this.expense.trialOpportunityNumber}`;
  }

  getPatientAddress(address: string | null, country: string | null): string {
    const formattedAddress = address ?? '';
    const lastTwoCharsOfAddress = formattedAddress.slice(-2);

    return country && country !== '' && country !== lastTwoCharsOfAddress
      ? `${formattedAddress}, ${country}`
      : formattedAddress;
  }

  filterExpenses(){
    this.searchFilter.emit(this.expense.patientCode);
  }
}
