export class Address {
  public id: string;
  public apt: string;
  public street: string;
  public city: string;
  public state: string;
  public zip: string;
  public country: number;
  public countryCode: string;
  public countryName: string;

  constructor(init?: Partial<Address>) {
    Object.assign(this, init);
  }

  equals(sourceAddress: string): boolean {
    return this.toString() === sourceAddress;
  }

  hasValue(): boolean {
    return !!(this.apt && this.apt.trim().length > 0 ||
      this.street && this.street.trim().length > 0 ||
      this.city && this.city.trim().length > 0 ||
      this.state && this.state.trim().length > 0 ||
      this.zip && this.zip.trim().length > 0 ||
      this.country !== undefined && this.country !== null ||
      this.countryCode && this.countryCode.trim().length > 0);
  }

  toStringWithHtmlLineBreaks(): string {
    const addressParts = [];

    if (this.apt) {
      addressParts.push(this.apt);
    }

    if (this.street) {
      addressParts.push(this.street);
    }

    if (this.city) {
      addressParts.push(this.city);
    }

    if (this.state) {
      addressParts.push(this.state);
    }

    if (this.zip) {
      addressParts.push(this.zip);
    }

    if (this.countryCode) {
      addressParts.push(this.countryCode);
    }

    return addressParts.join('<br>');
  }

  toString(joinString = ', '): string {
    const addressParts = [];

    if (this.apt) {
      addressParts.push(this.apt);
    }

    if (this.street) {
      addressParts.push(this.street);
    }

    if (this.city) {
      addressParts.push(this.city);
    }

    if (this.state) {
      addressParts.push(this.state);
    }

    if (this.zip) {
      addressParts.push(this.zip);
    }

    if (this.countryCode) {
      addressParts.push(this.countryCode);
    }

    return addressParts.join(joinString);
  }
}
