import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function forbiddenEmailValidator(emailsToExclude: string[]): ValidatorFn  {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (emailsToExclude.includes(email)) {
      return { forbiddenEmail: true };
    }
    return null;
  };
}
