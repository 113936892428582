<!-- Start: Patient delete requests -->
<div class="tab-pane fade active show">

  <table class="std-results-list patients-delete-requests">
    <tbody>
    <tr *ngFor="let patient of results.results">
      <td class="valign-top">
        <span class="label">{{ patient.patientCode == null ? 'NA' : patient.patientCode }}</span>

        <div class="patient-details">
          <span class="patient-name">{{ patient.getFullName() }}</span>
          <span class="patient-address">{{ patient.country }}</span>
        </div>
      </td>
      <td class="valign-top">
        <span class="label d-none d-lg-block">&nbsp;</span>
        <span class="patient-trial-name">{{ stringHelper.limit(patient.trialLabel, 45) }}</span>
      </td>
      <td class="result-btn"><a (click)="onSelectDeletePatient(patient)" class="btn btn-outline btn-slim" data-toggle="modal" data-target="#delete-account-modal" *ngIf="authService.hasPermission(Permissions.PatientDelete)">Delete Account</a></td>
    </tr>
    </tbody>
  </table>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount" (pageSelected)="loadPatients($event, keywords, country, orderBy)"></app-pagination>

  <div class="no-results inside" *ngIf="!hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-patients.png" alt="No deletion requests" />
      </div>
      <div class="content">
        <h2>No Deletion Requests</h2>
      </div>
    </div>
  </div>

  <div class="no-results inside" *ngIf="results.results.length == 0 && hasResults">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients matching your search.</p>
      </div>
    </div>
  </div>

</div>
<!-- End: Patient delete requests -->

<app-patient-delete-modal #deletePatientModal [activeTrialCodes]="activeTrialCodes" [patientId]="patientToDelete?.id" (deletedPatient)="onDeletedPatient()"></app-patient-delete-modal>


