<div class="list-autocomplete" [formGroup]="parentForm">
  <input type="text"
         [placeholder]="labelPlaceholder"
         matInput
         [formControlName]="controlName"
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</div>
