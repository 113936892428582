import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { SelectOption } from "../../core/models/select-option.model";

@Component({
  selector: 'app-autosuggest-dropdown-input',
  templateUrl: './autosuggest-dropdown-input.component.html',
  styleUrls: ['./autosuggest-dropdown-input.component.scss']
})
export class AutosuggestDropdownInputComponent implements OnInit, AfterViewInit {
  @Output() selectValueChanged = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  @ViewChild('autoCompleteParent') autoCompleteParent: ElementRef;

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('options') options: SelectOption[] = [];
  @Input('className') className = '';
  @Input('placeholder') placeholder = '';
  @Input('required') required = false;

  filteredOptions: SelectOption[];

  displayValue: string;
  form: UntypedFormGroup;
  selectedOption: SelectOption = null;
  showDropdown = false;
  disabled = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.input !== undefined) {
      if (!(this.input.nativeElement.contains(event.target) || this.autoCompleteParent.nativeElement.contains(event.target))) {
        this.showDropdown = false;
      }
    }
  }

  constructor() { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      input: new UntypedFormControl('', [this.optionSelectedValidator.bind(this)])
    });
    let parentControl = this.parentForm.get(this.controlName);
    if (parentControl !== null) {
      let isParentControlDisabled = this.parentForm.controls[this.controlName].disabled;
      if (isParentControlDisabled) {
        this.disable();
      }
    }
  }

  ngAfterViewInit() {
    this.filteredOptions = this.options;
  }

  reset(): void {
    this.parentForm.patchValue({[this.controlName]: ''});
    this.displayValue = '';
    if (this.input)
      this.input.nativeElement.value = '';
  }

  disable() {
    this.form.get('input').disable();
    this.disabled = true;
  }

  enable() {
    this.form.get('input').enable();
    this.disabled = false;
  }

  optionSelectedValidator(control: UntypedFormControl): { [s: string]: boolean } | null {
    if (this.selectedOption === null || this.selectedOption === undefined) {
      return { 'noOptionSelected': true };
    }

    return null;
  }

  onInputValueChanged(event: any) {
    let value = event.target.value;

    if (value !== null && value !== '') {
      this.filteredOptions = this._filter(event.target.value);
    } else {
      this.filteredOptions = this.options;
    }
  }

  onClick(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        setTimeout(() => {
          this.input.nativeElement.value = '';
          this.input.nativeElement.focus();
          this.filteredOptions = this.options;
        }, 500);
      }
    }
  }

  /**
   * Called when a country is selected from the country dropdown
   * @param option
   */
  selectOption(option: SelectOption) {
    this.selectedOption = option;
    this.parentForm.patchValue({ [this.controlName]: option.value });
    this.displayValue = option.text.substring(0, 30) + (option.text.length > 30 ? '...' : '');
    this.selectValueChanged.emit(option.value);
  }

  setOptions(options: SelectOption[]) {
    this.options = options;
  }

  setSelectedOption(value: string) {
    let option = this.findOptionByValue(value);
    if (option !== null) {
      this.selectOption(option);
    }
  }

  onClear() {
    this.selectedOption = null;
    this.parentForm.patchValue({ [this.controlName]: '' });
    this.filteredOptions = this.options;
  }

  findOptionByValue(value: string): SelectOption {
    let foundOption: SelectOption = null;

    this.options.forEach(option => {
      if (value?.toLowerCase() === option.value?.toLowerCase()) {
        foundOption = option;
      }
    });

    return foundOption;
  }

  /**
   * Handles filtering the field
   * @param name
   * @private
   */
  private _filter(name: string): SelectOption[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.text.toLowerCase().includes(filterValue));
  }
}
