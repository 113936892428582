import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PatientTripMuvRide } from "../../../core/models/patient-trip-muv-ride.model";
import { MuvRideViewComponent } from "./muv-ride-view/muv-ride-view.component";
import {
  PatientTrip
} from "../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";

@Component({
  selector: 'app-trip-muv-rides',
  templateUrl: './trip-muv-rides.component.html',
  styleUrls: ['./trip-muv-rides.component.scss']
})
export class TripMuvRidesComponent implements OnChanges {
  @ViewChild('viewRideDetail') viewRideDetail: MuvRideViewComponent;

  @Output('muvRideFormVisible') muvRideFormVisible = new EventEmitter<boolean>();
  @Output('refreshTrip') refreshTrip = new EventEmitter();

  @Input('patientTrip') patientTrip: PatientTrip;
  @Input('rides') rides: PatientTripMuvRide[] = [];

  showMuvRideForm = false;
  viewingRide = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientTrip?.currentValue.id != changes.patientTrip.previousValue?.id) {
      this.viewingRide = false;
    }
  }

  showForm(): void {
    this.showMuvRideForm = true;
    this.muvRideFormVisible.emit(true);
  }

  hideForm(): void {
    this.showMuvRideForm = false;
    this.muvRideFormVisible.emit(false);
    this.refreshTrip.emit();
  }

  viewRide(ride: PatientTripMuvRide) {
    this.viewingRide = true;
    this.viewRideDetail.viewRide(ride);
  }

  onBackToList(): void {
    this.viewingRide = false;
  }

}
