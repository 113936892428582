<div class="timeline-event type-{{ timelineEvent.boxType.toLowerCase() }}" [ngClass]="{'first': first, 'last': last, 'changed': timelineEvent.state !== 'unchanged', 'is-preview': isPreview}" *ngIf="timelineEvent.state !== 'deleted'">

  <div class="row">
    <div class="col-12" [ngClass]="{'col-lg-4': !isPreview}">
      <span class="date">{{ timelineEvent.date }} <span class="type">{{ titleCaseWord( timelineEvent.boxType) }}</span></span>

      <div class="image">
        <img src="{{ timelineEvent.imageUrl}}" alt="timeline event" *ngIf="timelineEvent.imageUrl !== undefined && timelineEvent.imageUrl !== null && timelineEvent.imageUrl !== ''">
      </div>
    </div>

    <div class="col-12" [ngClass]="{'col-lg-8': !isPreview}">
      <div class="description-actions">
        <h2>{{ timelineEvent.heading }}</h2>
        <p [innerHTML]="timelineEvent.shortDescriptionHtml()"></p>

        <div class="actions" *ngIf="!isPreview">
          <a class="btn btn-outline btn-delete" (click)="deleted.emit()">Delete</a>
          <a class="btn btn-primary btn-edit" (click)="edit.emit()">Edit</a>
        </div>
      </div>
    </div>
  </div>
</div>
