import { VisitAttendance } from './../../../../core/models/expense.model';
import { DateHelper } from './../../../../core/helpers/date-helper';
import { Component, Input, OnInit } from '@angular/core';
import { ExpenseClaimState, ExpenseClaimViewModel, VisitType } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-meta-data',
  templateUrl: './expense-meta-data.component.html',
  styleUrls: ['./expense-meta-data.component.scss']
})
export class ExpenseMetaDataComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;
  @Input() dataLoaded: boolean;
  VisitType = VisitType;
  VisitAttendance = VisitAttendance;

  constructor() { }

  ngOnInit(): void {
  }

  isCaxtonTab(): boolean {
    return this.selectedTab === ExpenseClaimState.CaxtonApproved || this.selectedTab === ExpenseClaimState.Processing || this.selectedTab === ExpenseClaimState.CaxtonFailed;
  }

  getLocalDate(date: Date){
    return this.isCaxtonTab() ? DateHelper.dateUTCToLocal(date) : date;
  }
}
