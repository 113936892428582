import { ExpenseClaimState, ExpenseCategoryType, ExpenseSubCategory, VisitType, VisitAttendance } from './../../../core/models/expense.model';
import { ExpenseClaimPaymentMethod } from 'app/core/models/expense.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseClaimPaidBy, ExpenseClaimViewModel } from '../../../core/models/expense.model';
import { AuthService } from '../../../core/services/auth.service';
import { Permissions } from '../../../core/constants/permissions';
import { StringHelper } from '../../../core/helpers/string-helper';
import { Lightbox } from "ngx-lightbox";
import { OverBudgetRequestDetailsStatus } from 'app/core/models/project-management.model';

@Component({
  selector: 'app-expense-card',
  templateUrl: './expense-card.component.html',
  styleUrls: ['./expense-card.component.scss']
})
export class ExpenseCardComponent implements OnInit {
  @Input('expense') expense: ExpenseClaimViewModel;
  @Input('showExpensePaidBy') showExpensePaidBy: boolean = false;
  @Input('showReasonForEdit') showReasonForEdit: boolean = true;
  @Input('showInvestigation') showInvestigation: boolean = true;
  @Input('showApproveOptions') showApproveOptions: boolean = true;
  @Input('showDescription') showDescription: boolean = true;
  @Input('showImages') showImages: boolean = true;

  @Output('onEdit') onEdit = new EventEmitter();

  Permissions = Permissions;
  stringHelper = StringHelper;
  ExpenseClaimPaymentMethod = ExpenseClaimPaymentMethod;
  ExpenseClaimState = ExpenseClaimState;
  ExpenseCategoryType = ExpenseCategoryType;
  ExpenseSubCategory = ExpenseSubCategory;
  VisitType = VisitType;
  VisitAttendance = VisitAttendance;
  OverBudgetRequestDetailsStatus = OverBudgetRequestDetailsStatus;

  
  constructor(public authService: AuthService, private lightbox: Lightbox){
  }

  ngOnInit(): void {
  }

  onStartInvestigation(expenseId: string): void {
  }

  onCloseInvestigation(expenseId: string): void {
  }

  viewReceipt(imageUrl: string) {
    const album: { src: string, caption: string, thumb: string, downloadUrl: string }[] = [];
    album.push({ src: imageUrl, caption: '', thumb: imageUrl, downloadUrl: '' });

    this.lightbox.open(album, 0);
  }

  getPaidByText(paidBy: ExpenseClaimPaidBy) {
    switch (paidBy) {
      case ExpenseClaimPaidBy.Finance:
        return 'Paid via Finance';
      case ExpenseClaimPaidBy.Caxton:
        return 'Paid via Caxton Bank';
      default:
        return 'Paid via Caxton Card';
    }
  }

  onApproveExpenseClaim(expenseId: string, type: ExpenseCategoryType) {
  }

  onMarkAsPaid(expenseId: string) {
  }

  onRejectModal(expenseId: string) {
  }

  getPatientAddress(address: string | null, country: string | null): string {
    const formattedAddress = address ?? '';
    const lastTwoCharsOfAddress = formattedAddress.slice(-2);

    return country && country !== '' && country !== lastTwoCharsOfAddress
      ? `${formattedAddress}, ${country}`
      : formattedAddress;
  }
}
