import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ExchangeRateRequest, TransformedExchangeRateResult } from '../models/exchange-rate.model';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {
  private _endpoint = '/exchangerate';

  constructor(private _httpClient: HttpClient) { }

  getTransformedFxRate(request: ExchangeRateRequest): Observable<TransformedExchangeRateResult> {
    let params = new HttpParams();
    let amount = +request.quotedAmount > 0 ? +request.quotedAmount * 100 : 0;
    params = params.set('fromCurrencyCode', request.fromCurrencyCode);
    params = params.set('toCurrencyCode', request.toCurrencyCode);
    params = params.set('quotedAmount', amount);

    return this._httpClient.get<TransformedExchangeRateResult>(environment.apiUrl +  this._endpoint, {params: params});
  }
}
