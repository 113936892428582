import { Component, Input, OnInit } from '@angular/core';
import { ChartItem } from "./chart-item.model";

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html'
})
export class DonutChartComponent implements OnInit {
  @Input('data') data: ChartItem[] = [];
  @Input('chartTitle') chartTitle: string;
  @Input('iconClass') iconClass: string;
  @Input('hasData') hasData = false;

  view: [number, number] = [250, 250];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = true;

  customColours: any[] = [];

  ngOnInit(): void {
    if (this.data.length > 0) {
      this.customColours = [];
      this.data.forEach(item => {
        this.customColours.push({ name: item.name, value: item.colour });
      });
    }
  }
}
