<div *ngIf="dataLoaded" class="expense-meta-data">
  <span class="label submitted-date">
    {{expense.addedByPatient ? 'Submitted by patient on ' : 'Created by administrator on '}} {{
    getLocalDate(expense.created) | date: 'd MMM yyyy'
    }} at {{ getLocalDate(expense.created) | date: 'h:mm aaa'}}
  </span>

  <span class="label visit-desc">{{ expense.visitType | enumToText:VisitType }}: {{ expense.visitTitle }}: {{
    expense.visitAttendance | enumToText:VisitAttendance }}</span>
  <span class="label visit-date">Visit date: {{getLocalDate(expense.visitDate) | date: 'dd/MM/yyyy'}}</span>
</div>

<div *ngIf="!dataLoaded" class="expense-meta-data">
  <div class="loaders">
    <ngx-skeleton-loader [ngStyle]="{'width': isCaxtonTab() ? '25%' : '50%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [ngStyle]="{'width': isCaxtonTab() ? '10%' : '20%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [ngStyle]="{'width': isCaxtonTab() ? '10%' : '20%'}" [ngClass]="{'visit-date-loader': isCaxtonTab()}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [ngStyle]="{'width': isCaxtonTab() ? '10%' : '0%'}" class="note-button-loader" [ngClass]="{'d-none': !isCaxtonTab()}"></ngx-skeleton-loader>
  </div>
</div>
