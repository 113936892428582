import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function invalidCharactersValidator(characters: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value || '';

    const containsInvalidCharacters: boolean = characters.some(char => value.includes(char));

    if (containsInvalidCharacters) {
      return { invalidCharacters: true };
    }

    return null;
  };
}
