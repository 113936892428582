import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomData } from "../../core/models/custom-data.model";

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  @Input('form') form: UntypedFormGroup;
  @Input('customFields') customFields: CustomData[] = [];

  ngOnInit(): void {
    this.customFields.forEach(customField => {
      this.form.addControl(customField.id, new UntypedFormControl({ value: customField.value, disabled: customField.disabled }));
    });
  }

}
