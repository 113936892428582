import { UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const DistanceRangeValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const distanceFromControl = formGroup.get('distanceFrom');
  const distanceToControl = formGroup.get('distanceTo');

  if (distanceFromControl && distanceToControl &&  distanceToControl.value) {
    const distanceFromValue = distanceFromControl.value;
    const distanceToValue = distanceToControl.value;

    if (distanceFromValue !== '' && distanceToValue !== '' && distanceFromValue > distanceToValue) {
      distanceToControl.setErrors({ rangeError: true });
      return { rangeError: true };
    }
  }

  distanceToControl?.setErrors(null);
  return null;
};
