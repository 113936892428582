<div id="cardTracker">
  <div class="view-head">
    <div class="head-title">
      <h2>Caxton Card Tracker</h2>
    </div>

    <div class="head-actions">
      <app-trial-autocomplete (valueChanged)="loadCards()" [returnTrialId]="true" [form]="searchForm" controlName="trialId" placeholder="Select a trial..."></app-trial-autocomplete>
      <app-search-input #searchInput name="searchTerm" [parentForm]="searchForm" (inputChange)="loadCards()"> </app-search-input>
      <app-dropdown-input #cardTypeSelect [selectedValue]="CaxtonCardTypeFilter.AllCards.toString()" [options]="cardTypeOptions" (selectValueChanged)="loadCards()" [loading]="loading"></app-dropdown-input>
      <app-dropdown-input #markAsSelect [options]="markAsOptions" (selectValueChanged)="onMarkAs()" [loading]="loading" [placeholder]="'Mark as'"
        [disabled]="selectedCards.length === 0" *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite)"></app-dropdown-input>
      <button class="btn btn-secondary" [disabled]="selectedCards.length === 0" (click)="openAssignCardsModal()" *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite)">Assign</button>
      <button class="btn btn-outline" (click)="showExportModal()" *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite) || _authService.hasRole(RoleNames.ProjectManager)">Export</button>
      <button class="btn btn-primary" (click)="showAddCardsModal()" *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite)">Add Cards</button>
    </div>
  </div>

  <div class="view-body">
    <div class="content-panel">
      <div class="horizontal-scroll">
        <table class="pgo-data-grid full-width" [ngClass]="{'read-only': !_authService.hasPermission(Permissions.CaxtonCardWrite)}" aria-hidden="true">
          <thead>
            <tr>
              <th *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite)"></th>
              <th>Card name</th>
              <th>Expiry</th>
              <th>Delivery</th>
              <th>Trial</th>
              <th>Oracle code</th>
              <th>Site</th>
              <th>Address</th>
              <th>Site contact</th>
              <th>Date sent</th>
              <th>Courier cost</th>
              <th>Tracking No.</th>
              <th>Card Status</th>
              <th>Patient ID</th>
              <th>Card info</th>
            </tr>
          </thead>
          <tbody *ngIf="cards">
            <tr *ngFor="let card of cards.results">
              <td table-inline-cell-checkbox class="col-checkbox" (itemSelected)="onCardSelected($event, card.id)" *ngIf="_authService.hasPermission(Permissions.CaxtonCardWrite)"></td>
              <td table-inline-cell-text class="col-name" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'6'"  [text]="card.name" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="name"></td>
              <td table-inline-cell-short-date class="col-expiry" (valueChanged)="onChangesMade(card.id)" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="expiry"></td>
              <td table-inline-cell-select class="col-delivery" (valueChanged)="onChangesMade(card.id)" [allowNone]="true" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [items]="deliveryOptions" [parentForm]="updateCardForms[card.id]" controlName="delivery"></td>
              <td table-inline-cell-select class="col-trial" (valueChanged)="onTrialChanged(card.id, $event)" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [displaySearch]="true" [items]="trialOptions" [filteredItems]="trialOptions" [parentForm]="updateCardForms[card.id]" controlName="trialId"></td>
              <td table-inline-cell-text class="col-oracle-code" [text]="card.oracleCode" [truncateLength]="'9'" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)"></td>
              <td table-inline-cell-select class="col-site" (valueChanged)="onSiteChanged(card.id, $event)" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [items]="siteOptions[card.id]" [parentForm]="updateCardForms[card.id]" controlName="siteId"></td>
              <td table-inline-cell-text class="col-address" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'18'" [text]="card.address" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="address"></td>
              <td table-inline-cell-text class="col-site-contact" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'25'"  [text]="card.siteContact" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="siteContact"></td>
              <td table-inline-cell-date class="col-date-sent" (valueChanged)="onChangesMade(card.id)" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [emitFirstChange]="true" [parentForm]="updateCardForms[card.id]" controlName="dateSent"></td>
              <td table-inline-cell-text class="col-courier-cost" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'8'" [text]="card.courierCost" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="courierCost"></td>
              <td table-inline-cell-text class="col-tracking-no" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'8'" [text]="card.trackingNumber" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="trackingNumber"></td>
              <td table-inline-cell-select class="col-card-status" (valueChanged)="onChangesMade(card.id)" [allowNone]="true" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [items]="cardStatusOptions" [parentForm]="updateCardForms[card.id]" controlName="status"></td>
              <td table-inline-cell-text class="col-patient-id" [truncateLength]="'8'" [text]="card.patientId" [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)"></td>
              <td table-inline-cell-text class="col-card-info" (valueChanged)="onChangesMade(card.id)" [truncateLength]="'8'" [text]="card.cardInfo"  [editable]="_authService.hasPermission(Permissions.CaxtonCardWrite)" [parentForm]="updateCardForms[card.id]" controlName="cardInfo"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <app-pagination [currentPage]="cards?.currentPage" [totalPageCount]="cards?.totalPageCount"
        (pageSelected)="loadCards($event)">
      </app-pagination>
    </div>
  </div>
</div>

<app-modal-v2 #addCardsModal [customClassName]="'add-cards-modal'">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Add cards</h2>
    </div>
    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="addCardsForm">
        <div class="form-group">
          <label>Number of cards</label>
          <input type="number" class="form-control" formControlName="numberOfCards">
        </div>
        <div class="form-group">
          <label>Last card no.</label>
          <input type="number" class="form-control" formControlName="lastCardNo">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="addCardsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button (click)="onAddCards()" class="btn btn-primary"
            [disabled]="processingModalRequest || addCardsForm.invalid">
            <span *ngIf="processingModalRequest">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processingModalRequest">Add Cards</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>

<app-modal-v2 #assignCardsModal [customClassName]="'assign-cards-modal'">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Assign Cards</h2>
    </div>
    <div class="modal-body">
      <form class="modal-form-full-width" [formGroup]="assignCardsForm">
        <div class="form-group">
          <label>Trial</label>
          <app-trial-autocomplete #trialAutocomplete (valueChanged)="onFilterTrialChanged($event)" [form]="assignCardsForm"
            controlName="trialId" placeholder="Select a trial..."></app-trial-autocomplete>
        </div>
        <div class="form-group" [hidden]="assignCardPatientView">
          <label>Site</label>
          <app-dropdown-input #assignCardsSiteDropdown [options]="assignCardsSiteOptions" [parentForm]="assignCardsForm" [name]="'siteId'"></app-dropdown-input>
        </div>
        <div class="form-group" [hidden]="assignCardPatientView">
          <label>Site contact</label>
          <input type="text" class="form-control" formControlName="siteContact">
        </div>
        <div class="form-group" [hidden]="!assignCardPatientView">
          <label>Patient details</label>
          <input type="text" class="form-control" formControlName="patientDetails">
        </div>
        <div class="assign-cards-view-btn-wrapper">
          <button (click)="switchAssignCardsModalView()" class="assign-cards-view-btn">Send to <span *ngIf="!assignCardPatientView">Patient</span><span *ngIf="assignCardPatientView">Site</span>?</button>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="assignCardsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button (click)="onAssignCards()" class="btn btn-primary"
            [disabled]="processingModalRequest || assignCardsForm.invalid">
            <span *ngIf="processingModalRequest">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!processingModalRequest">Assign Cards</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>

<app-modal #exportModal>
  <div class="modal-header">
    <h2 class="modal-title">Export</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Date Range Start</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="startDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Date Range End</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="endDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Trial<span class="required-star">*</span></label>
        <app-trial-autocomplete #exportTrialAutocomplete (valueChanged)="onExportTrialChanged($event)"
          [form]="exportForm" controlName="trialId" placeholder="Select a trial..."></app-trial-autocomplete>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="this.exportModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button [disabled]="exportForm.invalid" (click)="export()" class="btn btn-primary">
          <span *ngIf="processingModalRequest">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!processingModalRequest">Export</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
