import { Component, OnInit } from '@angular/core';
import { FeedbackSearch } from "../../../../core/models/FeedbackSearch";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { FeedbackService } from "../../../../core/services/feedback.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-patient-feedback',
  templateUrl: './patient-feedback.component.html',
  styleUrls: ['./patient-feedback.component.scss']
})
export class PatientFeedbackComponent implements OnInit {
  feedback: FeedbackSearch = new FeedbackSearch();
  searchTerm: string = null;
  country: string = null;
  orderBy = 0;
  subscription: Subscription;

  constructor(private _feedbackService: FeedbackService) { }

  ngOnInit(): void {
    this.loadFeedback();
  }

  updateFilter(searchTerm: string, country: string, orderBy: number = 0) {
    this.searchTerm = searchTerm === '' ? null : searchTerm;
    this.country = country === '' ? null : country;
    this.orderBy = orderBy;
    this.loadFeedback(1, country, searchTerm, orderBy);
  }

  loadFeedback(page: number = 1, country: string = null, searchTerm: string = null, orderBy: number = 0): void {
    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this._feedbackService.retrieveFeedback(searchTerm, country, page, orderBy).subscribe({
        next: (rsp) => {
          this.feedback = rsp;
        },
        error: (err) => {
          LogHelper.log(err);
        }
      });
  }
}
