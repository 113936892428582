import {SelectOption} from "../models/select-option.model";

export class CaregiverTravellingOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "No",
        "text": "No or Not Applicable"
      },
      {
        "value": "Yes",
        "text": "Yes"
      },
      {
        "value": "TravelBooked",
        "text": "Travel Booked"
      }
    ];
  }
}
