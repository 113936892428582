<td table-inline-cell-checkbox (itemSelected)="onTripSelected($event)" [checked]="trip.overBudget"></td>
<td table-inline-cell-text [text]="trip.overBudgetLabel"></td>
<td class="trip-col-type">
  <span *ngIf="_authService.hasPermission(Permissions.VisitTripEdit)">
    <a (click)="onHandleShowEditTripModal()" title="{{ trip.type }}"><i class="{{ getTripTypeIcon(trip.type) }}"></i></a>
  </span>

  <span *ngIf="!_authService.hasPermission(Permissions.VisitTripEdit)">{{ trip.type }}</span>
</td>
<td table-inline-cell-select class="trip-col-status trip-status-{{ form.get('bookingStatus').value.toLowerCase() }}" (valueChanged)="updateTripStatus()" [items]="tripBookingStatusOptions" [parentForm]="form" controlName="bookingStatus" minWidth="auto"></td>
<td table-inline-cell-date class="trip-col-date" [parentForm]="form" controlName="departureDate" (valueChanged)="onChangesMade()"></td>
<td table-inline-cell-time #startTime class="trip-col-time" (valueChanged)="onChangesMade()" [editable]="form.get('departureDate').value != null && form.get('departureDate').value !== ''" [time]="this.form.get('departureTime')?.value" [parentForm]="form" controlName="departureTime"></td>
<td table-inline-cell-date class="trip-col-date" [parentForm]="form" (valueChanged)="onChangesMade()" controlName="arrivalDate"></td>
<td table-inline-cell-time #endTime class="trip-col-time" (valueChanged)="onChangesMade()" [editable]="form.get('arrivalDate').value != null && form.get('arrivalDate').value !== ''" [time]="this.form.get('arrivalTime')?.value" [parentForm]="form" controlName="arrivalTime"></td>
<td table-inline-cell-text class="trip-col-reference" (valueChanged)="onChangesMade()" truncateLength="15" [text]="trip.reference" [editable]="true" [parentForm]="form" controlName="reference"></td>
<td table-inline-cell-text class="trip-col-reference" (valueChanged)="onChangesMade()" [text]="trip.ticketOrConfirmationNo" [editable]="true" [parentForm]="form" controlName="ticketConfirmationNo"></td>
<td table-inline-cell-text (valueChanged)="onChangesMade()" [text]="trip.bookingProvider" [editable]="true" [parentForm]="form" controlName="bookingProvider"></td>
<td table-inline-cell-text (valueChanged)="onChangesMade()" [text]="trip.carrier" [editable]="true" [parentForm]="form" controlName="carrier"></td>
<td table-inline-cell-amount-currency (valueChanged)="onChangesMade()" class="trip-col-amount-quoted" [parentForm]="form" currencyControlName="quotedCurrency" amountControlName="quotedAmount" [editable]="true"></td>
<td table-cell-amount class="trip-col-reference" (valueChanged)="onChangesMade()" [amount]="trip.quotedAmountBC?.toString()" [editable]="true" [parentForm]="form" controlName="quotedAmountBc"></td>
<td table-inline-cell-text class="trip-col-reference" [text]="trip.trialBaseCurrency" [editable]="false" [parentForm]="form" controlName="quotedCurrencyBc"></td>
<td table-inline-cell-text class="trip-col-reference" (valueChanged)="onChangesMade()" [text]="trip.invoiceNo" [editable]="true" [parentForm]="form" controlName="invoiceNo"></td>

<app-modal-v2 #recalculateAmountBcModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Recalculate BC amount ?</h2>
    </div>
    <div class="modal-body text-center">
      <p>Trip quoted amount or currency changed, do you want to recalculate amount in BC ?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <button (click)="updateTrip(false)" class="btn btn-secondary">
            <span *ngIf="isUpdateProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isUpdateProcessing">No</span>
          </button>
        </div>

        <div class="col-6">
          <button (click)="updateTrip(true)" class="btn btn-primary">
            <span *ngIf="isUpdateProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isUpdateProcessing">Yes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>
