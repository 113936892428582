import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "currencyDecimalAmount" })
export class CurrencyDecimalAmountPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: any,
    digitsInfo?: string,
    removeTrailingZeros?: boolean,
    locale?: string
  ): string | null {
    let result;
    result = this.decimalPipe.transform(value, digitsInfo, locale);
    if (result.endsWith("00")) {
      result = this.decimalPipe.transform(value, "1.0-0", locale);
    }

    return result;
  }
}
