import { DateHelper } from './../../../../core/helpers/date-helper';
import { Component, Input, OnInit } from '@angular/core';
import { ExpenseClaimState, ExpenseClaimViewModel } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-caxton-payment-dates',
  templateUrl: './expense-caxton-payment-dates.component.html',
  styleUrls: ['./expense-caxton-payment-dates.component.scss']
})
export class ExpenseCaxtonPaymentDatesComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;
  @Input() dataLoaded: boolean;
  DateHelper = DateHelper;
  ExpenseClaimState = ExpenseClaimState;

  constructor() { }

  ngOnInit(): void {
  }

}
