import { SharedEmitterService } from './../../../../shared/share-emitter/shared-emitter.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VisitSearchResult } from "../../../../core/models/visit-search.model";
import { StringHelper } from "../../../../core/helpers/string-helper";
import { CaregiverTravellingOptions } from "../../../../core/constants/caregiver-travelling-options";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { VisitTypeOptions } from "../../../../core/constants/visit-type-options";
import { TravelRequestedOptions } from "../../../../core/constants/travel-requested-options";
import { SelectOption } from "../../../../core/models/select-option.model";
import { VisitAttendanceOptions } from "../../../../core/constants/visit-attendance-options";
import { BookingStatusOptions } from "../../../../core/constants/booking-status-options";
import { PublishedOptions } from "../../../../core/constants/published-options";
import { PatientService } from "../../../../core/services/patient.service";
import { DateHelper, IllyTime } from "../../../../core/helpers/date-helper";
import { PatientUpdateVisit } from "../../../../core/services/interfaces/patient-update-visit.interface";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { VisitService } from "../../../../core/services/visit.service";
import moment from "moment";
import { VisitTableCellTimeComponent } from "./visit-table-cell-time/visit-table-cell-time.component";
import {
  PatientUpdateAddressComponent
} from "../../../../shared/patient-update-address/patient-update-address.component";
import { Address } from "../../../../shared/input-address/address.model";

@Component({
  selector: '[visit-table-row]',
  templateUrl: './visit-table-row.component.html',
  styleUrls: ['./visit-table-row.component.scss']
})
export class VisitTableRowComponent implements AfterViewInit {
  @ViewChild('patientUpdateAddressModal') patientUpdateAddressModal: PatientUpdateAddressComponent;
  @ViewChild('startTime') startTime: VisitTableCellTimeComponent;
  @ViewChild('endTime') endTime: VisitTableCellTimeComponent;

  @Input() visit: VisitSearchResult;
  @Input() coordinatorOptions: SelectOption[] = [];
  @Input() scrollPosition = 0;

  @Output() visitExpanded = new EventEmitter<string>();
  @Output() visitCollapsed = new EventEmitter<string>();
  @Output() viewPatientDetail = new EventEmitter<VisitSearchResult>();

  form: UntypedFormGroup;

  stringHelper = StringHelper;
  caregiverTravellingOptions = CaregiverTravellingOptions.all();
  visitTypeOptions = VisitTypeOptions.all();
  travelRequestedOptions = TravelRequestedOptions.all();
  visitAttendanceOptions = VisitAttendanceOptions.all();
  bookingStatusOptions = BookingStatusOptions.all();
  publishedStatusOptions = PublishedOptions.all();

  @Input('expanded') expanded = false;
  unsentNotifications = false;

  actionsCellWidth = 0;

  tds: any[] = [];

  constructor(private elementRef: ElementRef, private patientService: PatientService,
    private alertService: AlertService, private visitService: VisitService,
    private sharedEmitterService: SharedEmitterService) {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      date: new UntypedFormControl(''),
      endDate: new UntypedFormControl(''),
      time: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      visitTitle: new UntypedFormControl(),
      caregiverTravelling: new UntypedFormControl(),
      visitType: new UntypedFormControl(),
      description: new UntypedFormControl(),
      notes: new UntypedFormControl(),
      travelRequested: new UntypedFormControl(),
      designatedContacts: new UntypedFormControl(),
      attendance: new UntypedFormControl(),
      bookingStatus: new UntypedFormControl(),
      publishedStatus: new UntypedFormControl(),
      siteId: new UntypedFormControl(),
      visitTemplateId: new UntypedFormControl()
    });

    this.form.get('processing')?.valueChanges.subscribe(processing => {
      if (processing) {
        this.addClassToTds('processing');
      } else {
        this.removeClassFromTds('processing')
        this.addClassToTds('processed');

        setTimeout(() => {
          this.removeClassFromTds('processed')
        }, 500);
      }
    });

    this.form.get('publishedStatus')?.valueChanges.subscribe(status => {
      if (status === 'Published') {
        this.removeClassFromTds('draft');
      } else {
        this.addClassToTds('draft');
      }
    });

    // When trip type is changed to home, make sure the patient has an address
    this.form.get('visitType')?.valueChanges.subscribe(visitType => {
      if (visitType === 'Home' && this.visit.visitType !== 'Home') {
        if (this.visit.patientAddress === null || this.visit.patientAddress === '') {
          this.patientUpdateAddressModal.openModal(this.visit.patientId, 'Missing Address', 'If creating a home visit, you will need to set this patient up with a home address.');
          this.form.patchValue({ visitType: this.visit.visitType });
        }
      }
    });
  }

  private removeClassFromTds(className: string) {
    for (let td of this.tds) {
      td.classList.remove(className);
    }
  }

  private addClassToTds(className: string) {
    for (let td of this.tds) {
      td.classList.add(className);
    }
  }

  ngAfterViewInit() {
    this.tds = this.elementRef.nativeElement.querySelectorAll('td');

    const time = this.visit.timeMinutes !== null ? IllyTime.parseMinutes(this.visit.timeMinutes) : null;
    const endTime = this.visit.endTimeMinutes !== null ? IllyTime.parseMinutes(this.visit.endTimeMinutes) : null;

    this.form.patchValue({
      date: this.visit.startDate !== null ? new Date(this.visit.startDate) : '',
      endDate: this.visit.endDate !== null ? new Date(this.visit.endDate) : '',
      time: time !== null ? time.to24HourString() : '',
      endTime: endTime !== null ? endTime.to24HourString() : '',
      visitTitle: this.visit.visitTitle,
      caregiverTravelling: this.visit.careGiverTravelling,
      visitType: this.visit.visitType,
      description: this.visit.description,
      notes: this.visit.notes,
      travelRequested: this.visit.travelRequested,
      designatedContacts: this.visit.designatedContacts,
      attendance: this.visit.attendance,
      bookingStatus: this.visit.bookingStatus,
      publishedStatus: this.visit.publishedStatus,
      siteId: this.visit.siteId,
      patientId: this.visit.patientId,
      visitTemplateId: this.visit.visitTemplateId
    });

    if (this.visit.unsentNotifications) {
      this.unsentNotifications = true;
      this.addClassToTds('unsent-notifications');
    }

    if (this.visit.overBudgetRequestClosed) {
      this.addClassToTds('ob-closed');
    }

    this.form.get('date').valueChanges.subscribe(date => {
      this.startTime.editable = date !== null;
    });

    this.form.get('endDate').valueChanges.subscribe(date => {
      this.endTime.editable = date !== null;
    });

    this.sharedEmitterService.overBudgetRequestClosedEvent.subscribe(visitId => {
      if (visitId === this.visit.id) {
        this.removeClassFromTds('ob-closed');
      }
    });

    this.sharedEmitterService.overBudgetRequestClosedOpened.subscribe(visitId => {
      if (visitId === this.visit.id) {
        this.addClassToTds('ob-closed');
      }
    })
  }

  onToggleTrips() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.visitExpanded.emit(this.visit.id);
    } else {
      this.visitCollapsed.emit(this.visit.id);
    }
  }

  /**
   * Accepts a date string and time object and returns a moment object combining the two.
   * @param date
   * @param time
   * @private
   */
  private formatDateTimeString(date: string, time: IllyTime): moment.Moment {
    let response = null;
    if (date !== '' && time.isValid) {
      response = moment(date + ' ' + time.to24HourString(), 'DD/MM/YYYY HH:mm');
    } else if (date !== '' && !time.isValid) {
      response = moment(date, 'DD/MM/YYYY');
    }

    return response;
  }

  saveChanges(sendPatientNotification: boolean): void {
    let canSave = true;

    // Update the values on the local visit object
    this.visit.publishedStatus = this.form.get('publishedStatus')?.value;
    this.visit.bookingStatus = this.form.get('bookingStatus')?.value;
    this.visit.attendance = this.form.get('attendance')?.value;
    this.visit.designatedContacts = this.form.get('designatedContacts')?.value;
    this.visit.travelRequested = this.form.get('travelRequested')?.value;
    this.visit.notes = this.form.get('notes')?.value;
    this.visit.description = this.form.get('description')?.value;
    this.visit.visitType = this.form.get('visitType')?.value;
    this.visit.careGiverTravelling = this.form.get('caregiverTravelling')?.value;
    this.visit.visitTitle = this.form.get('visitTitle')?.value;

    // Persist the visit via the API
    let time = this.form.get('time').value !== null && this.form.get('time').value !== '' ? IllyTime.parseString(this.form.get('time').value) : IllyTime.parseString('00:00');
    let endTime = this.form.get('endTime').value !== null && this.form.get('endTime').value !== '' ? IllyTime.parseString(this.form.get('endTime').value) : IllyTime.parseString('23:59');

    if (this.form.get('date').value !== null && this.form.get('date').value !== '' && this.form.get('endDate').value !== null && this.form.get('endDate').value !== '') {
      let startDateTime = this.formatDateTimeString(DateHelper.dateToDdMmYyyy(this.form.get('date').value), time);
      let endDateTime = this.formatDateTimeString(DateHelper.dateToDdMmYyyy(this.form.get('endDate').value), endTime);

      if (endDateTime < startDateTime) {
        canSave = false;
        this.alertService.showWarningAlert('Unable to save Visit, End date cannot be before start date');
      }
    }

    const dto: PatientUpdateVisit = {
      date: DateHelper.dateToDdMmYyyy(this.form.get('date').value),
      endDate: DateHelper.dateToDdMmYyyy(this.form.get('endDate').value),
      timeMinutes: time.totalMinutes(),
      endTimeMinutes: endTime.totalMinutes(),
      title: this.form.get('visitTitle').value,
      description: this.form.get('description').value,
      notes: this.form.get('notes').value,
      visitType: this.form.get('visitType').value,
      siteId: this.form.get('siteId').value,
      travelRequested: this.form.get('travelRequested').value,
      caregiverTravelling: this.form.get('caregiverTravelling').value,
      attendance: this.form.get('attendance').value,
      bookingStatus: this.form.get('bookingStatus')?.value,
      publishedStatus: this.form.get('publishedStatus')?.value,
      sendPatientNotification: sendPatientNotification,
      designatedContacts: this.form.get('designatedContacts')?.value,
      templateId: this.form.get('visitTemplateId').value
    };

    if (canSave) {
      this.patientService.updateVisit(this.visit.patientId, this.visit.id, dto).subscribe({
        next: () => {
          // Do nothing
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to update visit!');
        }
      });
    }
  }

  /**
   * Called with an editable field is updated
   * @param internalField
   */
  onChange(internalField = false): void {
    if (this.form.valid) {
      if (this.form.get('publishedStatus')?.value === 'Published') {
        if (!internalField) {
          this.unsentNotifications = true;
          this.addClassToTds('unsent-notifications');
          this.calculateActionsCellWidth();
        }
      }

      this.saveChanges(false);
    }
  }

  /**
   * Called when the user selects to send notifications for a visit
   */
  onSendNotifications(): void {
    this.visitService.sendNotification(this.visit.id).subscribe({
      next: () => {
        this.unsentNotifications = false;
        this.removeClassFromTds('unsent-notifications');
        this.calculateActionsCellWidth();
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Called when the user selects to clear notifications for a visit
   */
  onClearNotifications(): void {
    this.visitService.clearNotification(this.visit.id).subscribe({
      next: () => {
        this.unsentNotifications = false;
        this.removeClassFromTds('unsent-notifications');
        this.calculateActionsCellWidth();
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Used to set the width of the actions column in the table
   */
  calculateActionsCellWidth() {
    this.actionsCellWidth = 0;

    if (this.unsentNotifications) {
      this.actionsCellWidth = 175;
    }
  }

  onPatientAddressUpdated(address: Address) {
    if (address === null || !address.hasValue())
      return;

    this.visit.patientAddress = address.toString();

    // Wait a second, and then set the trip type to home
    setTimeout(() => {
      this.form.patchValue({ visitType: 'Home' });

      this.saveChanges(false);
    }, 500);
  }

}
