<div class="tab-pane fade show active details-view-results">
  <app-change-log
    [dataLoaded]="dataLoaded"
    [results]="changeLogResults"
    (pageChanged)="onPageChanged($event)"
    (exportClicked)="exportChangeLogModal.show()">
  </app-change-log>
</div>

<!-- Start: Export patient change log modal -->
<app-modal #exportChangeLogModal>
  <div class="modal-header">
    <h2 class="modal-title">Export Change Log</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportChangeLogModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportChangeLog()" class="btn btn-primary" (mousemove)="updateExportFormValues()">
          <span *ngIf="exportIsProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!exportIsProcessing">Export CSV</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export patient change log modal -->
