<div class="suggestion-icons">
  <div class="suggestion-title" [style.border-color]="borderColor">
    <span>Recommended Action</span>
  </div>
  <div class="suggestion-content" [class]="suggestionClass">
    <mat-icon class="suggestion-icon">{{expenseSuggestionData.icon}}</mat-icon>
    <p>{{expenseSuggestionData.text}}</p>
    <button type="submit" class="btn btn-outline btn-slim" (click)="suggestApproval()">
      <span *ngIf="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <span class="button-content" *ngIf="!loading">
        <mat-icon class="re-check-icon">cached</mat-icon>
        <span>Re-check</span>
      </span>
    </button>
  </div>
</div>
