import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateHelper } from "../../../../core/helpers/date-helper";
import { PatientDetail } from "../../../../core/models/patient-detail.model";
import { PatientService } from "../../../../core/services/patient.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { ObjectHelper } from '../../../../core/helpers/object.helper';
import {
  UpdatePatientAdditionalDetails
} from "../../../../core/services/interfaces/update-patient-additional-details.interface";

@Component({
  selector: 'app-patient-detail-additional',
  templateUrl: './patient-detail-additional.component.html',
  styleUrls: ['./patient-detail-additional.component.scss']
})
export class PatientDetailAdditionalComponent implements OnInit {
  @ViewChild('passportExpiry') passportExpiry: ElementRef;
  @ViewChild('dateOfBirth') dateOfBirth: ElementRef;
  @Output() patientInfoUpdated = new EventEmitter();
  @Input() patient: PatientDetail;
  form: UntypedFormGroup;
  isFormProcessing = false;

  constructor(private readonly patientService: PatientService, private readonly alertService: AlertService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      passportName: new UntypedFormControl(this.patient.passportName),
      passportExpiry: new UntypedFormControl(ObjectHelper.isUndefinedNullOrEmpty(this.patient.passportExpiry) ? null : DateHelper.dateToYyyyMmDd(this.patient.passportExpiry)),
      dateOfBirth: new UntypedFormControl(ObjectHelper.isUndefinedNullOrEmpty(this.patient.dateOfBirth) ? null : DateHelper.dateToYyyyMmDd(this.patient.dateOfBirth)),
      phone: new UntypedFormControl(this.patient.phone),
      nextOfKin: new UntypedFormControl(this.patient.nextOfKin),
      nextOfKinPhone: new UntypedFormControl(this.patient.nextOfKinPhone),
      passportNumber: new UntypedFormControl(this.patient.passportNumber),
      visaInfo: new UntypedFormControl(this.patient.visaInfo),
      otherNotes: new UntypedFormControl(this.patient.otherNotes),
      travellingWithCaregiver: new UntypedFormControl(this.patient.travellingWithCaregiver),
      caregiverDetails: new UntypedFormControl(this.patient.caregiverDetails)
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;

      // Custom Data
      const customData: any = {};
      this.patient.customDataFields.forEach(field => {
        const control = this.form.get(field.id);
        customData[field.id] = control.value;
      });

      const dto: UpdatePatientAdditionalDetails = {
        passportName: this.form.get('passportName').value,
        passportExpiryDate: this.form.get('passportExpiry').value,
        dob: this.form.get('dateOfBirth').value,
        phone: this.form.get('phone').value,
        nextOfKin: this.form.get('nextOfKin').value,
        nextOfKinPhone: this.form.get('nextOfKinPhone').value,
        passportNumber: this.form.get('passportNumber').value,
        visaInfo: this.form.get('visaInfo').value,
        otherNotes: this.form.get('otherNotes').value,
        travellingWithCaregiver: this.form.get('travellingWithCaregiver').value,
        caregiverDetails: this.form.get('caregiverDetails').value,
        customData: customData
      };

      this.patientService.updatePatientAdditionalDetails(this.patient.id, dto).subscribe({
        next: () => {
          this.patientInfoUpdated.emit();

          this.alertService.showSuccessAlert('Patient Updated Successfully');
          this.isFormProcessing = false;
          this.form.markAsUntouched();
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('Unable to update patient details!');
        }
      });
    }
  }

}
