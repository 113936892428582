import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectOption } from "../../core/models/select-option.model";
import { UntypedFormGroup } from "@angular/forms";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: 'app-input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss']
})
export class InputMultiSelectComponent {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('matSelect') matSelect: MatSelect;

  @Input('items') items: SelectOption[] = [];
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('searchInputPlaceholder') searchInputPlaceholder: string;
  @Input('allowFiltering') allowFiltering = false;
  @Input('allowSelectDeselect') allowSelectDeselect = false;

  @Output('onFilter') onFilter = new EventEmitter<string>();
  @Output('onItemClick') onItemClick = new EventEmitter<string>();

  filterForm: UntypedFormGroup;

  constructor() {
    this.filterForm = new UntypedFormGroup({
      searchInput: new UntypedFormGroup({})
    });
  }

  onSearchInputChanged(): void {
    this.onFilter.emit(this.searchInput.nativeElement.value);
  }

  onItemClicks(item): void {
    this.onItemClick.emit(item.value);
  }

  onSelectAll(): void {
    let selectedItems = this.parentForm.get(this.controlName).value;
    this.items.forEach(item => {
      // Only active the click event if the trial isn't already selected
      if (selectedItems === undefined || selectedItems === null || !selectedItems.includes(item.value)) {
        this.onItemClick.emit(item.value);
      }
    });
  }

  onDeselectAll(): void {
    let selectedItems = this.parentForm.get(this.controlName).value;
    this.items.forEach(item => {
      // Only active the click event if the trial is already selected
      if (selectedItems.includes(item.value)) {
        this.onItemClick.emit(item.value);
      }
    });
  }
}
