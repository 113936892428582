export class TrialConstants {
  public static readonly defaultTermsUrl: string = 'https://illingworthresearch.com/patientgo-terms';
  public static readonly defaultPrivacyPolicyUrl: string = 'https://illingworthresearch.com/patientgo-privacy-policy';

  public static readonly trialInfoTabEnabled: boolean = false;
  public static readonly trialDashboardEnabled: boolean = false;

  public static readonly therapeuticAreaOptions: { value: string, text: string }[] = [
    { value: '1', text: 'Allergy' },
    { value: '2', text: 'Anaesthesiology' },
    { value: '3', text: 'Cardiovascular' },
    { value: '4', text: 'Dental and Oral health' },
    { value: '5', text: 'Dermatology' },
    { value: '6', text: 'Dietetics/Nutrition' },
    { value: '7', text: 'Endocrinology/Metabolic Disease' },
    { value: '8', text: 'Gastroenterology' },
    { value: '9', text: 'Genetic Diseases' },
    { value: '10', text: 'Hematology' },
    { value: '11', text: 'Hepatology (Liver, Pancreatic)' },
    { value: '12', text: 'Immunology' },
    { value: '13', text: 'Infectious Diseases' },
    { value: '14', text: 'Musculoskeletal' },
    { value: '15', text: 'Nephrology (Renal)' },
    { value: '16', text: 'Neurodegenerative Diseases' },
    { value: '17', text: 'Neurology' },
    { value: '18', text: 'Neuromuscular Disorder' },
    { value: '19', text: 'Obstetrics/Gynaecology' },
    { value: '20', text: 'Oncology' },
    { value: '21', text: 'Opthalmology' },
    { value: '22', text: 'Orthopaedics' },
    { value: '23', text: 'Other' },
    { value: '24', text: 'Otolaryngology (Ear, Nose, Throat)' },
    { value: '25', text: 'Plastic/Cosmetic Surgery' },
    { value: '26', text: 'Psychiatric/Psychology' },
    { value: '27', text: 'Respiratory' },
    { value: '28', text: 'Rheumatology' },
    { value: '29', text: 'Tissue Viability' },
    { value: '30', text: 'Trauma/Emergency Medicine' },
    { value: '31', text: 'Urology' }
  ];

  public static readonly introductionMethodOptions: { value: string, text: string }[] = [
    { value: '1', text: 'Email From Illingworth' },
    { value: '2', text: 'Email From Client' },
    { value: '3', text: 'Study Newsletter' },
    { value: '4', text: 'Site Webinar' },
    { value: '5', text: 'CRA' }
  ];

  public static readonly approvalStatusOptions: { value: string, text: string }[] = [
    { value: '0', text: 'Pending' },
    { value: '1', text: 'Approved' },
    { value: '2', text: 'Declined' }
  ];

  public static readonly declinedReasonOptions: { value: string, text: string }[] = [
    { value: '0', text: 'Solution in place already' },
    { value: '1', text: 'Site organising themselves' },
    { value: '2', text: 'Other' }
  ];
}

export enum TrialState {
  Live = 0,
  Pending = 1,
  Closed = 2,
  Cancelled = 3
}

export class TrialStateString {
  public static readonly Live: string = "Live";
  public static readonly Pending: string = "Pending";
  public static readonly Closed: string = "Closed";
  public static readonly Cancelled: string = "Cancelled";

  public static Values = {
    0: "Live",
    1: "Pending",
    2: "Closed",
    3: "Cancelled"
  }
}
