import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { environment } from 'environments/environment';

@Injectable()
export class RoleAuthGuard {

  constructor(protected authService: AuthService, protected router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let requiredRoles = route.data.roles as Array<string>;

    if (!this.authService.hasAnyRole(JSON.stringify(requiredRoles))) {
      this.router.navigate(["/unauthorised"]);
    }

    return true;
  }
}
