import { CountryIbanRegex } from "app/shared/shared.model";

export class IbanRegex {
  public static all(): { [code: string]: CountryIbanRegex } {
    return {
      "AD": { regex: new RegExp(/^AD\d{10}[A-Z0-9]{12}$/), name: 'Andorra' },
      "AE": { regex: new RegExp(/^AE\d{21}$/), name: 'United Arab Emirates' },
      "AL": { regex: new RegExp(/^AL\d{10}[A-Z0-9]{16}$/), name: 'Albania' },
      "AT": { regex: new RegExp(/^AT\d{18}$/), name: 'Austria' },
      "AZ": { regex: new RegExp(/^AZ\d{2}[A-Z]{4}[A-Z0-9]{20}$/), name: 'Azerbaijan' },
      "BA": { regex: new RegExp(/^BA\d{18}$/), name: 'Bosnia and Herzegovina' },
      "BE": { regex: new RegExp(/^BE\d{14}$/), name: 'Belgium' },
      "BG": { regex: new RegExp(/^BG\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}$/), name: 'Bulgaria' },
      "BH": { regex: new RegExp(/^BH\d{2}[A-Z]{4}[A-Z0-9]{14}$/), name: 'Bahrain' },
      "BR": { regex: new RegExp(/^BR\d{25}[A-Z][A-Z0-9]$/), name: 'Brazil' },
      "BY": { regex: new RegExp(/^BY\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}$/), name: 'Republic of Belarus' },
      "CH": { regex: new RegExp(/^CH\d{7}[A-Z0-9]{12}$/), name: 'Switzerland' },
      "CR": { regex: new RegExp(/^CR\d{20}$/), name: 'CostaRica' },
      "CY": { regex: new RegExp(/^CY\d{10}[A-Z0-9]{16}$/), name: 'Cyprus' },
      "DE": { regex: new RegExp(/^DE\d{20}$/), name: 'Germany' },
      "DK": { regex: new RegExp(/^DK\d{16}$/), name: 'Denmark' },
      "DO": { regex: new RegExp(/^DO\d{2}[A-Z0-9]{4}\d{20}$/), name: 'Dominican Republic' },
      "EE": { regex: new RegExp(/^EE\d{18}$/), name: 'Estonia' },
      "EG": { regex: new RegExp(/^EG\d{27}$/), name: 'Egypt' },
      "ES": { regex: new RegExp(/^ES\d{22}$/), name: 'Spain' },
      "FI": { regex: new RegExp(/^FI\d{16}$/), name: 'Finland' },
      "FO": { regex: new RegExp(/^FO\d{16}$/), name: 'FaroeIslands' },
      "FR": { regex: new RegExp(/^FR\d{12}[A-Z0-9]{11}\d{2}$/), name: 'France' },
      "GB": { regex: new RegExp(/^GB\d{2}[A-Z]{4}\d{14}$/), name: 'United Kingdom' },
      "GE": { regex: new RegExp(/^GE\d{2}[A-Z]{2}\d{16}$/), name: 'Georgia' },
      "GI": { regex: new RegExp(/^GI\d{2}[A-Z]{4}[A-Z0-9]{15}$/), name: 'Gibraltar' },
      "GL": { regex: new RegExp(/^GL\d{16}$/), name: 'Greenland' },
      "GR": { regex: new RegExp(/^GR\d{9}[A-Z0-9]{16}$/), name: 'Greece' },
      "GT": { regex: new RegExp(/^GT\d{2}[A-Z0-9]{24}$/), name: 'Guatemala' },
      "HR": { regex: new RegExp(/^HR\d{19}$/), name: 'Croatia' },
      "HU": { regex: new RegExp(/^HU\d{26}$/), name: 'Hungary' },
      "IE": { regex: new RegExp(/^IE\d{2}[A-Z]{4}\d{14}$/), name: 'Ireland' },
      "IL": { regex: new RegExp(/^IL\d{21}$/), name: 'Israel' },
      "IQ": { regex: new RegExp(/^IQ\d{2}[A-Z]{4}\d{15}$/), name: 'Iraq' },
      "IS": { regex: new RegExp(/^IS\d{24}$/), name: 'Iceland' },
      "IT": { regex: new RegExp(/^IT\d{2}[A-Z]\d{10}[A-Z0-9]{12}$/), name: 'Italy' },
      "JO": { regex: new RegExp(/^JO\d{2}[A-Z]{4}\d{4}[A-Z0-9]{18}$/), name: 'Jordan' },
      "KW": { regex: new RegExp(/^KW\d{2}[A-Z]{4}[A-Z0-9]{22}$/), name: 'Kuwait' },
      "KZ": { regex: new RegExp(/^KZ\d{5}[A-Z0-9]{13}$/), name: 'Kazakhstan' },
      "LB": { regex: new RegExp(/^LB\d{6}[A-Z0-9]{20}$/), name: 'Lebanon' },
      "LC": { regex: new RegExp(/^LC\d{2}[A-Z]{4}[A-Z0-9]{24}$/), name: '	Saint Lucia' },
      "LI": { regex: new RegExp(/^LI\d{7}[A-Z0-9]{12}$/), name: 'Liechtenstein' },
      "LT": { regex: new RegExp(/^LT\d{18}$/), name: 'Lithuania' },
      "MC": { regex: new RegExp(/^MC\d{12}[A-Z0-9]{11}\d{2}$/), name: 'Monaco' },
      "MD": { regex: new RegExp(/^MD\d{2}[A-Z0-9]{20}$/), name: 'Moldova' },
      "ME": { regex: new RegExp(/^ME\d{20}$/), name: 'Montenegro' },
      "MK": { regex: new RegExp(/^MK\d{5}[A-Z0-9]{10}\d{2}$/), name: 'Macedonia' },
      "MR": { regex: new RegExp(/^MR\d{25}$/), name: 'Mauritania' },
      "MT": { regex: new RegExp(/^MT\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}$/), name: 'Malta' },
      "MU": { regex: new RegExp(/^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$/), name: 'Mauritius' },
      "NL": { regex: new RegExp(/^NL\d{2}[A-Z]{4}\d{10}$/), name: 'Netherlands' },
      "NO": { regex: new RegExp(/^NO\d{13}$/), name: 'Norway' },
      "PK": { regex: new RegExp(/^PK\d{2}[A-Z]{4}[A-Z0-9]{16}$/), name: 'Pakistan' },
      "PL": { regex: new RegExp(/^PL\d{26}$/), name: 'Poland' },
      "PS": { regex: new RegExp(/^PS\d{2}[A-Z]{4}[A-Z0-9]{21}$/), name: 'Palestine' },
      "PT": { regex: new RegExp(/^PT\d{23}$/), name: 'Portugal' },
      "QA": { regex: new RegExp(/^QA\d{2}[A-Z]{4}[A-Z0-9]{21}$/), name: 'Qatar' },
      "RO": { regex: new RegExp(/^RO\d{2}[A-Z]{4}[A-Z0-9]{16}$/), name: 'Romania' },
      "RS": { regex: new RegExp(/^RS\d{20}$/), name: 'Serbia' },
      "SA": { regex: new RegExp(/^SA\d{4}[A-Z0-9]{18}$/), name: '	Saudi Arabia' },
      "SC": { regex: new RegExp(/^SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}$/), name: 'Seychelles' },
      "SE": { regex: new RegExp(/^SE\d{22}$/), name: 'Sweden' },
      "SI": { regex: new RegExp(/^SI\d{17}$/), name: 'Slovenia' },
      "SK": { regex: new RegExp(/^SK\d{22}$/), name: 'Slovakia' },
      "SM": { regex: new RegExp(/^SM\d{2}[A-Z]\d{10}[A-Z0-9]{12}$/), name: 'SanMarino' },
      "ST": { regex: new RegExp(/^ST\d{23}$/), name: 'Sao Tome and Principe' },
      "SV": { regex: new RegExp(/^SV\d{2}[A-Z]{4}\d{20}$/), name: 'El Salvador' },
      "TL": { regex: new RegExp(/^TL\d{21}$/), name: 'Timor-Leste' },
      "TN": { regex: new RegExp(/^TN\d{22}$/), name: 'Tunisia' },
      "TR": { regex: new RegExp(/^TR\d{8}[A-Z0-9]{16}$/), name: 'Turkey' },
      "UA": { regex: new RegExp(/^UA\d{8}[A-Z0-9]{19}$/), name: 'Ukraine' },
      "VA": { regex: new RegExp(/^VA\d{20}$/), name: 'Vatican City State' },
      "VG": { regex: new RegExp(/^VG\d{2}[A-Z]{4}\d{16}$/), name: 'Virgin Islands' },
      "XK": { regex: new RegExp(/^XK\d{18}$/), name: 'Kosovo' }
    };
  }
}
