import { AfterViewChecked, Component, Input } from '@angular/core';

@Component({
  selector: 'app-muv-ride-state-badge',
  templateUrl: './muv-ride-state-badge.component.html',
  styleUrls: ['./muv-ride-state-badge.component.scss']
})
export class MuvRideStateBadgeComponent implements AfterViewChecked {
  @Input('state') state: string = '';

  displayText: string = 'Not Set';
  classNames: string = 'badge-grey';

  constructor() { }

  ngAfterViewChecked(): void {
    this.displayText = this.addSpacesToText(this.state);
    this.classNames = 'badge-' + this.state.toLowerCase();
  }

  private addSpacesToText(text: string): string {
    return text.split('').map((char, index) => {
      if (char.toUpperCase() === char && char !== char.toLowerCase() && index !== 0) {
        return ` ${char}`;
      }
      return char;
    }).join('');
  }

}
