import {SearchPage} from "./search-page.model";
import {TravelRequest} from "./travel-request.model";

export class TravelResult extends SearchPage {
  results: TravelRequest[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(TravelRequest.fromObject(result));
    }

    return this;
  }
}
