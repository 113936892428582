import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { TrialDetail } from "../../../core/models/trial-detail.model";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { ExpenseService } from "../../../core/services/expense.service";
import { ExpenseCategoryItem, ExpenseCategoryTranslation } from "../../../core/models/expense-category-list.model";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { Cultures } from 'app/core/constants/cultures';

@Component({
  selector: 'app-expense-category-edit',
  templateUrl: './expense-category-edit.component.html',
  styleUrls: ['./expense-category-edit.component.scss']
})
export class ExpenseCategoryEditComponent implements OnInit {
  @ViewChild('typeSelect') typeSelect: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;

  trial = new TrialDetail();
  expenseCategory: ExpenseCategoryItem;
  form: UntypedFormGroup;
  forms: UntypedFormGroup[] = [];
  languageSelectForm: UntypedFormGroup;
  formIsProcessing = false;

  typeOptions: { value: string; text: string }[] = [];
  allCultures: { value: string; text: string }[] = [];
  culturesInUse: { value: string; text: string }[] = [];
  currentCulture = 'en';

  constructor(private readonly alertService: AlertService, private readonly expenseService: ExpenseService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly templateService: TemplateService) {
    for (const culture of Cultures.all()) {
      this.allCultures.push({ value: culture.culture, text: culture.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.typeOptions.push({ value: '0', text: 'Value' });
    this.typeOptions.push({ value: '1', text: 'Distance' });

    // Build the add new trial form
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required)
    });

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });

    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });

    // Load the request trial
    this.activatedRoute.params.subscribe(params => {
      this.expenseService.retrieveExpenseCategory(params.id).subscribe({
        next: category => {
          this.expenseCategory = category;
          this.populateFormWithCategory(category);
        }, error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to load expense category!');
        }
      });
    });
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    this.culturesInUse.forEach(culture => {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
      }
    });

    return inUse;
  }

  onTypeChanged(value: string) {
    this.form.patchValue({ type: value });
  }

  populateFormWithCategory(category: ExpenseCategoryItem) {
    // Find out which translations are used on this category
    this.culturesInUse.push({ value: 'en', text: 'English' });
    category.translations.forEach(translation => {
      this.allCultures.forEach(culture => {
        if (culture.value.toLowerCase() === translation.culture.toLowerCase()) {
          this.culturesInUse.push({ value: culture.value, text: culture.text });
        }
      });

      // Add a new form
      let form = new UntypedFormGroup({
        id: new UntypedFormControl(translation.id),
        culture: new UntypedFormControl(translation.culture),
        name: new UntypedFormControl(translation.name)
      });
      this.forms.push(form);
    });

    this.culturesInUse.sort((a, b) => a.text.localeCompare(b.text));

    this.form.patchValue({
      name: category.name,
      type: category.type
    });

    this.typeSelect.setValue(category.type.toString());
  }

  /**
   * Called when the add trial form is submitted
   */
  onFormSubmit() {
    if (this.form.valid) {
      this.formIsProcessing = true;

      const translations: ExpenseCategoryTranslation[] = [];
      this.forms.forEach(form => {
        if (form.get('name').value !== '') {
          const id = form.get('id') !== null ? form.get('id').value : null;
          translations.push(new ExpenseCategoryTranslation(id,
            this.expenseCategory.id,
            form.get('name').value,
            form.get('culture').value));
        }
      });

      this.expenseService.updateExpenseCategory(
        this.expenseCategory.id,
        this.form.get('name').value,
        this.form.get('type').value,
        translations
      ).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Expense Category Updated Successfully');
          this.router.navigate(['/category']);
        },
        error: error => {
          LogHelper.log(error);
          this.formIsProcessing = false;
          this.alertService.showWarningAlert('Sorry, there was a problem.');
        }
      });
    }
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
    }

    this.removeLanguageModal.hide();
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
      let form = new UntypedFormGroup({
        culture: new UntypedFormControl(culture.value),
        name: new UntypedFormControl(''),
        description: new UntypedFormControl('', Validators.maxLength(1000)),
        expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      });
      this.forms.push(form);
      this.currentCulture = culture.value;
      this.languageSelectForm.patchValue({ culture: culture.value });
      this.addLanguageModal.hide();
    }
  }
}
