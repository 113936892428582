import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Currencies } from 'app/core/constants/currency';
import { NumberHelper } from "../../core/helpers/number.helper";
import { AutosuggestDropdownInputComponent } from '../autosuggest-dropdown-input/autosuggest-dropdown-input.component';

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit, AfterViewInit {
  @ViewChild('currencySelect') currencySelect: AutosuggestDropdownInputComponent;

  @Input('parentForm') form: UntypedFormGroup;
  @Input('amountControlName') amountControlName: string;
  @Input('currencyControlName') currencyControlName: string;
  @Input('placeholder') placeholder: string;
  @Input('defaultCurrency') defaultCurrency = '';

  @Output('valueChanged') valueChanged = new EventEmitter();

  allCurrencies: { value: string, text: string }[] = [];
  numberHelper = NumberHelper;

  constructor() {
    this.allCurrencies = Currencies.all().map(c => ({ value: c.cc, text: c.cc }));
    this.allCurrencies.sort((a, b) => a.value.localeCompare(b.value));
  }

  ngOnInit(): void {
  }

  disable() {
    this.currencySelect.disable();
  }

  ngAfterViewInit() {
    this.currencySelect.setSelectedOption(this.form.get(this.currencyControlName).value);

    this.form.get(this.amountControlName).valueChanges.subscribe(value => {
      if (!value) {
        this.form.get(this.currencyControlName).clearValidators();
        this.form.get(this.currencyControlName).updateValueAndValidity();
      } else {
        // If value is not empty, set the currencyControlName input to be required
        this.form.get(this.currencyControlName).setValidators([Validators.required]);
        this.form.get(this.currencyControlName).updateValueAndValidity();

        if (this.form.get(this.currencyControlName).value == null || this.form.get(this.currencyControlName).value === '') {
          this.onCurrencySelectChanged(this.defaultCurrency);
          this.currencySelect.setSelectedOption(this.defaultCurrency);
        }

        const roundedValue = parseFloat((Number(value)).toFixed(2));
        if (!isNaN(roundedValue) && roundedValue !== value) {
          this.form.get(this.amountControlName).setValue(roundedValue, { emitEvent: false });
        }
      }
    });
  }

  setCurrency(currency: string) {
    if (currency !== null && currency !== '')
      this.currencySelect.setSelectedOption(currency);
  }

  reset() {
    this.form.get(this.amountControlName).reset();
    this.form.get(this.currencyControlName).reset();
    this.currencySelect.onClear();
  }

  onAmountBlur() {
    this.valueChanged.emit();
  }

  onCurrencySelectChanged(currency: string): void {
    this.form.patchValue({ [this.currencyControlName]: currency });
    this.valueChanged.emit();
  }
}
