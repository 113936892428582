import { ExpenseClaimState } from './../../../../core/models/expense.model';
import { Component, Input, OnInit } from '@angular/core';
import { StringHelper } from 'app/core/helpers/string-helper';
import { ExpenseClaimViewModel } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-description',
  templateUrl: './expense-description.component.html',
  styleUrls: ['./expense-description.component.scss']
})
export class ExpenseDescriptionComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;

  ExpenseClaimState = ExpenseClaimState;

  constructor() { }

  ngOnInit(): void {
  }

  buildPolicyRemindersText(claim: any) {
    let text = '';

    if (!StringHelper.isNullOrEmpty(claim.trialExpensePolicyReminders)) {
      text += claim.trialExpensePolicyReminders;
    }

    if (!StringHelper.isNullOrEmpty(claim.policyReminders)) {
      if (!StringHelper.isNullOrEmpty(text)) {
        text += ' / ';
      }

      text += claim.policyReminders;
    }

    if (!StringHelper.isNullOrEmpty(claim.adminExpenseNotes)) {
      if (!StringHelper.isNullOrEmpty(text)) {
        text += ' / ';
      }

      text += claim.adminExpenseNotes;
    }

    return text;
  }

}
