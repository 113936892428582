<div class="autocomplete" (click)="onClick()" [ngClass]="{'focus': showDropdown, 'disabled': targetForm.get(controlName).disabled}">
  <label *ngIf="selected !== null">{{ displayValue }}</label>
  <label class="placeholder" *ngIf="selected === null">{{ placeholder }}</label>

  <a class="arrow"><i class="far fa-angle-down" aria-hidden="true"></i></a>
  <a class="clear-selection" (click)="onClearSelection(true)" *ngIf="selected !== null"><i class="far fa-times" aria-hidden="true"></i></a>

  <div class="autocomplete-dropdown" *ngIf="showDropdown">
    <div class="autocomplete-search">
      <div [formGroup]="form" class="form-group">
        <input #input formControlName="input" type="text" (click)="$event.stopPropagation();">
      </div>

      <i class="far fa-search" aria-hidden="true"></i>
    </div>

    <p *ngIf="searchHasValue && filteredOptions.length === 0">No results found!</p>

    <div *ngIf="filteredOptions.length > 0 && resultsAreLoading === false" class="autocomplete-results">
      <ul>
        <li *ngFor="let option of filteredOptions" [id]="option.id">
          <a (click)="onSelectOption(option)">
            <span>{{ option.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
