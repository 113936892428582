export class CustomData {
  public constructor(public id: string, public label: string, public value: string, public source: string, public type: string, public disabled: boolean) {
  }

  public static map(obj: any): CustomData {
    return new CustomData(
      obj.hasOwnProperty('id') ? obj.id : '',
      obj.hasOwnProperty('label') ? obj.label : '',
      obj.hasOwnProperty('value') ? obj.value : '',
      obj.hasOwnProperty('source') ? obj.source : '',
      obj.hasOwnProperty('type') ? obj.type : '',
        obj.hasOwnProperty('disabled') ? obj.disabled : false
    );
  }
}
