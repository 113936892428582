<div class="trials">

  <!-- Start: View head -->
  <div class="view-head">
    <div class="row">

      <div class="col-12 text-center">
        <h2 class="heading-small with-padding">Edit Expense Category</h2>
      </div>

    </div>
  </div>
  <!-- End: View head -->

  <!-- Start: View body -->
  <div class="view-body">

    <div class="row">
      <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

        <div class="content-panel">

          <div class="trial-cultures">
            <form  [formGroup]="languageSelectForm">
              <div class="select select-secondary">
                <select formControlName="culture">
                  <option *ngFor="let culture of culturesInUse" [value]="culture.value">{{ culture.text }}</option>
                </select>
                <span class="focus"></span>
              </div>

              <button class="btn btn-outline btn-slim add-language" (click)="addLanguageModal.show()">+ Add Language</button>
            </form>
          </div>

          <!-- Start: Default form -->
          <form [formGroup]="form" [ngClass]="{'visibility-hidden': currentCulture !== 'en'}">
            <table class="simple-form">
              <tr>
                <th style="width: 140px;"><label for="category-name">Name</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="category-name" class="form-control" formControlName="name">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label for="category-type">Type</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input #typeSelect id="category-type" [options]="typeOptions" [parentForm]="form" name="type" placeholder="Select Type..." (selectValueChanged)="onTypeChanged($event)"></app-dropdown-input>
                  </div>
                </td>
              </tr>
            </table>
          </form>
          <!-- End: Default form -->

          <!-- Start: Translations form -->
          <div *ngFor="let form of forms">
            <form [formGroup]="form" [ngClass]="{'visibility-hidden': form.get('culture').value !== currentCulture}" *ngIf="form.get('culture').value !== 'en'">
              <table class="simple-form">
                <tr>
                  <th style="width: 140px;"><label>Name</label></th>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="name">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td class="text-right">
                    <a (click)="removeLanguageModal.show()" class="btn btn-outline">Remove Language</a>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <!-- End: Translations form -->

          <!-- Start: Buttons -->
          <table class="simple-form">
            <tr>
              <th style="width: 140px;"></th>
              <td>
                <div class="btn-wrap">
                  <div class="row">
                    <div class="col-6">
                      <a routerLink="/category" class="btn btn-secondary">Cancel</a>
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary" (click)="onFormSubmit()">
                          <span *ngIf="formIsProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                        <span *ngIf="!formIsProcessing">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <!-- End: Buttons -->

        </div>

      </div>
    </div>

  </div>
  <!-- End: View body -->

</div>

<!-- Start: Add language modal -->
<app-modal #addLanguageModal customClassName="add-language">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <ul>
      <li *ngFor="let culture of allCultures" [ngClass]="{'disabled': isCultureInUse(culture.value)}"><a (click)="onAddLanguage(culture)">{{ culture.text }}</a></li>
    </ul>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <a (click)="addLanguageModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export change log modal -->

<!-- Start: Remove language modal -->
<app-modal #removeLanguageModal>
  <div class="modal-header">
    <h2 class="modal-title">Remove Language</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to remove this language from the trial?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="removeLanguageModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" (click)="onConfirmedRemoveLanguage()">
          Yes, remove
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Remove language modal -->
