export class TrialReport {
  public patientsEnrolled = 0;
  public patientsRetained = 0;
  public visitsScheduled = 0;
  public visitsCompleted = 0;
  public expenseRequests = 0;
  public expenseRequestsProcessed = 0;
  public carTrips = 0;
  public carPercent = 0;
  public trainTrips = 0;
  public trainPercent = 0;
  public planeTrips = 0;
  public planePercent = 0;
}
