import { Permissions } from './../../../core/constants/permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { TrialService } from 'app/core/services/trial.service';
import { TranslationService } from 'app/core/services/translation.service';
import { LogHelper } from 'app/core/helpers/log.helper';
import { AlertService } from 'app/shared/alert/alert.service';
import { TemplateService } from 'app/core/services/template.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { TrialConstants } from 'app/core/constants/trial-constants';
import { Countries } from 'app/core/constants/countries';
import { TrialCreate } from '../../../core/services/interfaces/trial-create.interface';
import { Cultures } from 'app/core/constants/cultures';
import { GlobalConstants } from 'app/core/constants/global-constants';
import { environment } from 'environments/environment';
import { Currencies } from 'app/core/constants/currency';
import {
  AutosuggestDropdownInputComponent
} from 'app/shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component';
import { SelectOption } from "../../../core/models/select-option.model";
import { ApiConsumerService } from "../../../core/services/api-consumer.server";
import { ApiConsumerSimple } from "../../../core/models/api-consumer-simple.model";

@Component({
  selector: 'app-trial-add',
  templateUrl: './trial-add.component.html',
  styleUrls: ['./trial-add.component.scss']
})
export class TrialAddComponent implements OnInit {
  @ViewChild('languageSelect') languageSelect: DropdownInputComponent;
  @ViewChild('visitCountElement') visitCountElement: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('translationVersionDropdown') translationVersionDropdown: DropdownInputComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;
  @ViewChild('therapeuticAreaSelect') therapeuticAreaSelect: DropdownInputComponent;
  @ViewChild('baseCurrencySelect') baseCurrencySelect: AutosuggestDropdownInputComponent;

  expenseCategories: { id: string, value: string }[] = [];
  currencyOptions: { value: string; text: string }[] = [];
  languageSelectForm: UntypedFormGroup;
  defaultForm: UntypedFormGroup;
  forms: UntypedFormGroup[] = [];
  visitCountOptions = Array<{ value: string, text: string }>();
  versionOptions: { value: string, text: string }[] = [];
  formIsProcessing = false;

  allCultures: { value: string, text: string }[] = [];
  culturesInUse: { value: string, text: string }[] = [];
  currentCulture = 'en';
  defaultCoordinatorEmail = environment.defaultCoordinatorEmail;
  trialDashboardEnabled = TrialConstants.trialDashboardEnabled;
  therapeuticAreaOptions = TrialConstants.therapeuticAreaOptions;
  countries = Countries.all();
  selectedCountries: { code: string, name: string }[] = [];
  Permissions = Permissions;
  apiConsumerOptions: SelectOption[] = [];

  constructor(private trialService: TrialService, private apiConsumerService: ApiConsumerService, private translationService: TranslationService,
    private alertService: AlertService, private router: Router,
    private templateService: TemplateService) {
    for (let culture of Cultures.all()) {
      this.allCultures.push({
        value: culture.culture,
        text: culture.name
      });

      // Add english as a culture in use (default)
      if (culture.culture === 'en') {
        this.culturesInUse.push({
          value: culture.culture,
          text: culture.name
        });
      }
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    // Create options 1..50 for the visit count dropdown
    for (let i = 1; i <= 50; i++) {
      this.visitCountOptions.push({
        value: i.toString(),
        text: i.toString()
      });
    }

    // Build the add new trial form
    this.defaultForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en'),
      code: new UntypedFormControl('', Validators.required),
      internalCode: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      client: new UntypedFormControl('', Validators.required),
      sponsor: new UntypedFormControl(''),
      nickname: new UntypedFormControl(''),
      opportunityNumber: new UntypedFormControl('', Validators.required),
      protocolNumber: new UntypedFormControl('', Validators.required),
      indications: new UntypedFormControl(''),
      therapeuticArea: new UntypedFormControl(''),
      visitCount: new UntypedFormControl(1),
      description: new UntypedFormControl('', Validators.maxLength(1000)),
      expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      expenseCategories: new UntypedFormControl([]),
      allowTravelRequests: new UntypedFormControl(false),
      allowExpenseRequests: new UntypedFormControl(false),
      allowActivityCentre: new UntypedFormControl(false),
      caxtonEnabled: new UntypedFormControl(true),
      inAppBankDetails: new UntypedFormControl(false),
      enableSmartNotifications: new UntypedFormControl(false),
      rideHealthEnabled: new UntypedFormControl(false),
      dashboardEnabled: new UntypedFormControl(false),
      allowFeedbackRequests: new UntypedFormControl(false),
      translationVersionId: new UntypedFormControl('Default', Validators.required),
      termsUrl: new UntypedFormControl(this.trialService.defaultTermsUrl, [Validators.required, Validators.pattern(GlobalConstants.urlValidatorPattern)]),
      privacyPolicyUrl: new UntypedFormControl(this.trialService.defaultPrivacyPolicyUrl, [Validators.required, Validators.pattern(GlobalConstants.urlValidatorPattern)]),
      coordinatorEmail: new UntypedFormControl(this.defaultCoordinatorEmail, [Validators.required, Validators.email]),
      countries: new UntypedFormControl([]),
      expensePolicyReminders: new UntypedFormControl(),
      bookingEnabled: new UntypedFormControl(false),
      patientAutoCompletion: new UntypedFormControl(false),
      bankAccountEnabled: new UntypedFormControl(false),
      bankAccountSelfManagementEnabled: new UntypedFormControl(false),
      baseCurrency: new UntypedFormControl('', Validators.required),
      bankAccountSelfCardManagementEnabled: new UntypedFormControl(false),
      muvEnabled: new UntypedFormControl(false),
      apiEnabled: new UntypedFormControl(false),
      apiConsumerIds: new UntypedFormControl([])
    });

    // Enable / disable the api consumer dropdown based on the api enabled checkbox
    this.defaultForm.get('apiEnabled').valueChanges.subscribe((enabled) => {
      if (enabled)
        this.defaultForm.get('apiConsumerIds').enable();
      else
        this.defaultForm.get('apiConsumerIds').disable();
    });

    this.loadAndSetApiConsumerOptions();

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });

    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });

    // Update country tags
    this.defaultForm.get('countries').valueChanges.subscribe(c => {
      this.selectedCountries = [];
      c.forEach(country => {
        this.selectedCountries.push(this.countries.find(c => c.code === country));
      });
    });

    // Populate Currencies drop down
    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    // Load translation versions
    this.loadTranslationVersion();

    this.listenToTrialNameValues();
  }

  /**
   * Retrieves and sets the api consumer options to be used in the api consumer dropdown
   */
  loadAndSetApiConsumerOptions(): void {
    this.apiConsumerService.getAllApiConsumers().subscribe({
      next: (apiConsumers: any) => {
        this.apiConsumerOptions = apiConsumers.map((apiConsumer: ApiConsumerSimple) => {
          const consumerName = apiConsumer.enabled ? apiConsumer.name : `${apiConsumer.name} (Temporarily disabled)`;
          return new SelectOption(apiConsumer.id, consumerName);
        });
      }
    });
  }

  getFormAtIndex(index: 0) {
    if (this.forms.length > index) {
      return this.forms[index];
    }

    return null;
  }

  formsValid(): boolean {
    return this.defaultForm.valid && this.forms.every((group) => group.valid);
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    this.culturesInUse.forEach(culture => {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
      }
    });

    return inUse;
  }

  loadTranslationVersion() {
    this.versionOptions = [];
    this.translationService.list().subscribe({
      next: (versions: any) => {
        versions.forEach(version => {
          this.versionOptions.push({ value: version.id, text: version.version });
        });
      }
    });
  }

  /**
   * Called when the add trial form is submitted
   */
  onFormSubmit() {
    if (this.defaultForm.valid) {
      this.formIsProcessing = true;

      const createTrial: TrialCreate = {
        trialCode: this.defaultForm.get('code').value,
        internalCode: this.defaultForm.get('internalCode').value,
        name: this.defaultForm.get('name').value,
        description: this.defaultForm.get('description').value,
        client: this.defaultForm.get('client').value,
        sponsor: this.defaultForm.get('sponsor').value,
        nickname: this.defaultForm.get('nickname').value,
        opportunityNumber: this.defaultForm.get('opportunityNumber').value,
        protocolNumber: this.defaultForm.get('protocolNumber').value,
        indications: this.defaultForm.get('indications').value,
        therapeuticArea: +this.defaultForm.get('therapeuticArea').value,
        baseCurrency: this.defaultForm.get('baseCurrency').value,
        visitCount: +this.visitCountElement.selectedValue,
        allowTravelRequests: this.defaultForm.get('allowTravelRequests').value,
        allowExpenseRequests: this.defaultForm.get('allowExpenseRequests').value,
        allowActivityCentre: this.defaultForm.get('allowActivityCentre').value,
        enableSmartNotifications: this.defaultForm.get('enableSmartNotifications').value,
        expenseCategories: this.defaultForm.get('expenseCategories').value,
        termsUrl: this.defaultForm.get('termsUrl').value,
        privacyPolicyUrl: this.defaultForm.get('privacyPolicyUrl').value,
        coordinatorEmail: this.defaultForm.get('coordinatorEmail').value,
        translationVersionId: this.defaultForm.get('translationVersionId').value,
        rideHealthEnabled: this.defaultForm.get('rideHealthEnabled').value,
        dashboardEnabled: this.defaultForm.get('dashboardEnabled').value,
        allowFeedbackRequests: this.defaultForm.get('allowFeedbackRequests').value,
        countries: this.defaultForm.get('countries').value,
        expensePolicyReminders: this.defaultForm.get('expensePolicyReminders').value,
        bookingEnabled: this.defaultForm.get('bookingEnabled').value,
        patientAutoCompletion: this.defaultForm.get('patientAutoCompletion').value,
        bankAccountEnabled: this.defaultForm.get('bankAccountEnabled').value,
        bankAccountSelfManagementEnabled: this.defaultForm.get('bankAccountSelfManagementEnabled').value,
        bankAccountSelfCardManagementEnabled: this.defaultForm.get('bankAccountSelfCardManagementEnabled').value,
        muvEnabled: this.defaultForm.get('muvEnabled').value,
        apiEnabled: this.defaultForm.get('apiEnabled').value,
        apiConsumerIds: this.defaultForm.get('apiConsumerIds').value
      };

      this.trialService.createTrial(createTrial).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Trial Added Successfully');
          void this.router.navigate(['/trial']).then();
        },
        error: (error) => {
          this.formIsProcessing = false;
          LogHelper.log(error);
          this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry, there was a problem!');
        }
      });
    }
  }

  onTherapeuticAreaChanged(therapeuticArea: string) {
    this.defaultForm.patchValue({ therapeuticArea: therapeuticArea });
  }

  onBaseCurrencySelected(baseCurrency: string) {
    this.defaultForm.patchValue({ baseCurrency: baseCurrency });
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
      this.languageSelect.setValue('en');
    }

    this.removeLanguageModal.hide();
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
    }
  }

  listenToTrialNameValues() {
    this.defaultForm.get('client').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('sponsor').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('nickname').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('opportunityNumber').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });
  }

  setTrialNameValue() {
    const client = this.defaultForm.get('client').value;
    const sponsor = this.defaultForm.get('sponsor').value;
    const nickname = this.defaultForm.get('nickname').value;
    const opportunityNumber = this.defaultForm.get('opportunityNumber').value;

    const value = `${client}${client && (sponsor) ? ' / ' : ''}${sponsor}${nickname && (client || sponsor) ? ' / ' : ''}${nickname}${opportunityNumber && (client || sponsor || nickname) ? ' - ' : ''}${opportunityNumber}`;

    this.defaultForm.controls['name'].setValue(value);
  }

  removeCountryFromTrial(countryCode: string): void {
    const index = this.defaultForm.get('countries').value.indexOf(countryCode);

    if (index >= 0) {
      const countries = this.defaultForm.get('countries').value;
      countries.splice(index, 1);
      this.defaultForm.patchValue({ countries: countries });
    }
  }
}
