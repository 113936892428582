import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: '[table-inline-cell-time]',
  templateUrl: './table-inline-cell-time.component.html',
  styleUrls: ['./table-inline-cell-time.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableInlineCellTimeComponent {
  @ViewChild('input') input: ElementRef;
  @ViewChildren('td') tdElements: QueryList<ElementRef>;

  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('minWidth') minWidth: string;
  @Input('editable') editable: boolean = false;
  @Input('time') time: string;
  @Input('twentyFourHour') twentyFourHour: boolean = false;

  editing = false;
  originalValue = '';

  onEdit(): void {
    if (this.editable) {
      this.editing = true;
      this.originalValue = this.parentForm.get(this.controlName).value;
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 500);
    }
  }

  onBlur(): void {
    this.editing = false;

    // if the value has changed, then emit an event to let the parent component know about it
    if (this.parentForm.get(this.controlName).value != this.originalValue) {
      this.valueChanged.emit();
      this.time = this.parentForm.get(this.controlName)?.value;
    }
  }

  getFormattedTime(): string {
    if (this.time === null || this.time === '') {
      return '--:--';
    }

    if (this.twentyFourHour) {
      return this.time;
    }

    return this.convertToTwelveHourClock(this.time);
  }

  private convertToTwelveHourClock(time: string): string {
    const [hours, minutes] = time.split(":");
    const parsedHours = parseInt(hours);
    const isPM = parsedHours >= 12;
    const twelveHourFormatHours = ((parsedHours % 12) || 12).toString();
    const minutesString = minutes.padStart(2, "0");
    const period = isPM ? "PM" : "AM";
    return `${twelveHourFormatHours}:${minutesString} ${period}`;
  }


}

