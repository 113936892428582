<!-- Start: View head -->
<div class="view-head global-visit-list">
  <div class="row">

    <div class="col-12 d-none d-md-block col-md-3">
      <h2>Visits</h2>
    </div>

    <div class="col-12 col-md-9 text-right">
      <div class="button-container">
        <a (click)="onShowExportFilter()" class="btn btn-outline">Export</a>
        <a (click)="addVisitModal.show()" class="btn btn-primary" *ngIf="_authService.hasPermission(Permissions.VisitAdd)">+ Add Visit</a>
      </div>

      <form [formGroup]="searchForm">
        <app-search-input name="searchTerm" [parentForm]="searchForm" (inputChange)="onSearchInputChanged($event)"></app-search-input>
      </form>

      <div class="filter-button">
        <a class="btn btn-secondary" (click)="filterModal.show()"><i class="fas fa-filter"></i></a>
      </div>

      <div class="on-hold-expenses-switch">
        <label class="switch mb-0">
          <input (click)="onHandleViewMyTasks()" type="checkbox">
          <span class="slider round"></span>
        </label>
        <label class="switch-label">My Tasks</label>
      </div>
    </div>

  </div>
</div>
<!-- End: View head -->

<div class="view-body global-visits">

  <div class="content-panel">
    <div class="horizontal-scroll" #horizontalScroll (scroll)="onScroll()">
      <table class="pgo-data-grid" aria-hidden="true" [class.results-loading]="!results.loaded">
        <thead>
        <tr>
          <th></th>
          <th>Published Status</th>
          <th>Patient ID</th>
          <th>Trial Code</th>
          <th>Visit Title</th>
          <th class="col-designated-contact">Designated Contact</th>
          <th class="col-travel-requested">Travel</th>
          <th>OB</th>
          <th class="col-booking-status">Booking Status</th>
          <th class="col-caregiver-travelling">Caregiver Travel?</th>
          <th class="col-visit-status">Attendance</th>
          <th>Visit Start Date</th>
          <th>Start Time</th>
          <th>Visit End Date</th>
          <th>End Time</th>
          <th class="col-visit-type">Visit Type</th>
          <th class="col-description">Visit Description</th>
          <th class="col-notes">Visit Notes</th>

          <th></th>
        </tr>
        </thead>
        <tbody *ngIf="this.results.loading">
        <tr class="loading-results">
          <td colspan="18" class="text-center">
            <div class="loading-placeholder">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p>Loading Visits...</p>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="!this.results.loading">
        <ng-container *ngFor="let visit of results.results">
          <tr visit-table-row (viewPatientDetail)="onHandleShowPatientDetail($event)" [visit]="visit" [expanded]="expandedVisits.includes(visit.id)" [scrollPosition]="scrollPosition" class="visits-row" (visitExpanded)="onExpandVisit($event)" (visitCollapsed)="onCollapseVisit($event)" [coordinatorOptions]="coordinatorOptions"></tr>
          <tr class="trips-row" [class.visibility-hidden]="!expandedVisits.includes(visit.id)">
            <td></td>
            <td colspan="17">
              <div class="visit-trips-head text-right" [ngStyle]="{'margin-left': scrollPosition + 'px'}">
                <a class="btn btn-primary btn-slim btn-auto-width" style="float: left" (click)="onDeleteVisit(visit.id)" *ngIf="_authService.hasPermission(Permissions.VisitDelete)">Delete Visit</a>

                <button class="btn btn-primary" [disabled]="!raiseOverBudgetAvailable[visit.id]" (click)="onRaiseOverBudget(visit.id)">
                  <span *ngIf="raiseOverBudgetLoading[visit.id]">
                    <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                  </span>
                  <span *ngIf="!raiseOverBudgetLoading[visit.id]">Raise Over Budget</span>
                </button>
                <a class="btn btn-outline btn-slim btn-auto-width" (click)="duplicateVisitModal.show(visit.id)" *ngIf="_authService.hasPermission(Permissions.VisitAdd)">Duplicate Visit</a>
                <a class="btn btn-secondary btn-slim btn-auto-width" (click)="editVisitModal.show(visit.patientTrialId, visit.id)" *ngIf="_authService.hasPermission(Permissions.VisitEdit)">Edit Visit</a>
                <a class="btn btn-primary btn-slim btn-auto-width" (click)="onAddTripToVisit(visit.id, visit.trialMuvEnabled)" *ngIf="_authService.hasPermission(Permissions.VisitTripAdd)">Add Trip</a>
              </div>

              <div class="visit-trips-no-results text-center" *ngIf="visit.trips.length === 0">
                <p>No trips found for this visit.</p>
              </div>

              <table class="visit-trips-table inline-editing-enabled" *ngIf="visit.trips.length > 0" aria-hidden="true">
                <thead>
                <tr>
                  <th>OB</th>
                  <th>OB Status</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th class="col-date">Departure<br>Check-in date</th>
                  <th>Departure<br>Check-in time</th>
                  <th class="col-date">Arrival<br>Check-out date</th>
                  <th>Arrival<br>Check-out time</th>
                  <th>Reference</th>
                  <th>Ticket No</th>
                  <th>Booked Through</th>
                  <th>Carrier</th>
                  <th>Amount Quoted &amp; Currency</th>
                  <th>Amount Quoted (BC)</th>
                  <th>Currency (BC)</th>
                  <th>Invoice No</th>
                </tr>
                </thead>
                <tbody>
                <tr trip-table-row [trip]="trip" *ngFor="let trip of visit.trips"
                  (showEditModal)="addEditTripModal.showEditTripModal(trip.id)"
                  (tripForOverBudgetSelected)="onTripSelected($event, trip.id, visit.id)">
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

    <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount" (pageSelected)="onChangePage($event)"></app-pagination>
  </div>

</div>

<!-- Start: Delete trip modal -->
<app-modal #deleteTripModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Delete Trip?</h2>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete this this?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="deleteTripModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmDeleteTrip()" class="btn btn-primary" [ngClass]="{'disabled': isDeleteProcessing}">
            <span *ngIf="isDeleteProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
            <span *ngIf="!isDeleteProcessing">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete trip modal -->

<!-- Start: Delete visit modal -->
<app-modal #deleteVisitModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Visit?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this visit?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteVisitModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onDeleteVisitConfirmed()" class="btn btn-primary">
          <span *ngIf="isDeleteVisitProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
          <span *ngIf="!isDeleteVisitProcessing">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete visit modal -->

<!-- Create new visit modal -->
<app-add-visit-modal #addVisitModal (addedOrUpdated)="onHandleVisitAddedOrUpdated()"></app-add-visit-modal>

<app-edit-visit-modal #editVisitModal (addedOrUpdated)="onHandleVisitAddedOrUpdated()"></app-edit-visit-modal>

<app-add-edit-trip-modal #addEditTripModal (tripUpdated)="onHandleTripUpdated($event)" (tripAdded)="onHandleTripAdded($event)"></app-add-edit-trip-modal>

<!-- Start: Filter modal -->
<app-modal #filterModal>
  <div class="modal-header">
    <h2 class="modal-title">Filter Visits</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="filterForm">

      <ul class="filter-tabs">
        <li [ngClass]="{'active': filterForm.get('showDateRangeInputs').value}"><a (click)="onToggleDateRangeInputs()">Custom Range</a></li>
        <li [ngClass]="{'active': !filterForm.get('showDateRangeInputs').value}"><a (click)="onToggleDateRangeInputs()">Month / Year</a></li>
      </ul>

      <div [ngClass]="{'visibility-hidden': filterForm.get('showDateRangeInputs').value}">
        <div class="form-group">
          <label>Month</label>
          <app-input-select [parentForm]="filterForm" controlName="month" [items]="monthOptions" inputPlaceholder="All"></app-input-select>
        </div>
        <div class="form-group">
          <label>Year</label>
          <app-input-select [parentForm]="filterForm" controlName="year" [items]="yearOptions" inputPlaceholder="All"></app-input-select>
        </div>
      </div>

      <div [ngClass]="{'visibility-hidden': !filterForm.get('showDateRangeInputs').value}">
        <div class="form-group">
          <label>Date Range Start</label>
          <app-datepickerv2-input [parentForm]="filterForm" controlName="startDate"></app-datepickerv2-input>
        </div>
        <div class="form-group">
          <label>Date Range End</label>
          <app-datepickerv2-input [parentForm]="filterForm" controlName="endDate"></app-datepickerv2-input>
        </div>
      </div>
      <div class="form-group">
        <label>Patient</label>
        <app-patient-autocomplete #patientAutocomplete [trialId]="this.filterForm.get('trialId')?.value" [parentForm]="filterForm"
                                  controlName="patientId" placeholder="Patient Name or Id..."></app-patient-autocomplete>
      </div>
      <div class="form-group">
        <label>Trial</label>
        <app-trial-autocomplete #trialAutocomplete [patientId]="filterForm.get('patientId')?.value" [form]="filterForm" controlName="trialCode"
                                placeholder="Select a trial..."></app-trial-autocomplete>
      </div>
      <div class="form-group">
        <label>Booking Status</label>
        <app-input-select panelClass="hide-checkbox" [parentForm]="filterForm" controlName="bookingStatus"
                          [items]="bookingStatusOptions" inputPlaceholder="All"></app-input-select>
      </div>
      <div class="form-group">
        <label>Designated Contact</label>
        <app-input-select [parentForm]="filterForm" controlName="designatedContact"
                          [items]="filterDesignatedContacts" inputPlaceholder="All"></app-input-select>
      </div>
      <div class="checkbox-wrap last">
        <div class="form-group">
          <label class="checkbox">
            <input type="checkbox" id="showHistoricOpen" formControlName="showHistoricOpen" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="showHistoricOpen">Include all open visits</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="filterModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onFilterVisits()" class="btn btn-primary">
          <span *ngIf="filterForm.get('processing')?.value">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!filterForm.get('processing')?.value">Apply Filter</span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <a (click)="onResetFilter()" class="btn btn-link">Reset Filter</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Filter modal -->

<!-- Start: Export modal -->
<app-modal #exportModal>
  <div class="modal-header">
    <h2 class="modal-title">Export Visits</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Date Range Start</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="startDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Date Range End</label>
        <app-datepickerv2-input [parentForm]="exportForm" controlName="endDate"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Patient</label>
        <app-patient-autocomplete #exportPatientAutocomplete [trialId]="this.exportForm.get('trialId')?.value" [parentForm]="exportForm"
                                  controlName="patientId" placeholder="Patient Name or Id..."></app-patient-autocomplete>
      </div>
      <div class="form-group">
        <label>Trial</label>
        <app-trial-autocomplete #exportTrialAutocomplete (valueChanged)="onExportTrialChanges($event)" [patientId]="exportForm.get('patientId')?.value" [form]="exportForm" controlName="trialCode"
                                placeholder="Select a trial..."></app-trial-autocomplete>
      </div>
      <div class="form-group">
        <label>Booking Status</label>
        <app-input-select panelClass="hide-checkbox" [parentForm]="exportForm" controlName="bookingStatus"
                          [items]="bookingStatusOptions" inputPlaceholder="All"></app-input-select>
      </div>
      <div class="form-group">
        <label>Designated Contact</label>
        <app-input-select [parentForm]="exportForm" controlName="designatedContact"
                          [items]="filterDesignatedContacts" inputPlaceholder="All"></app-input-select>
      </div>
      <div class="checkbox-wrap last">
        <div class="form-group">
          <label class="checkbox">
            <input type="checkbox" id="exportShowHistoricOpen" formControlName="showHistoricOpen" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="exportShowHistoricOpen">Include all open visits</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportVisits()" class="btn btn-primary">
          <span *ngIf="exportForm.get('processing')?.value">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!exportForm.get('processing')?.value">Export</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export modal -->

<app-duplicate-visit-modal #duplicateVisitModal (duplicated)="onVisitDuplicated()"></app-duplicate-visit-modal>

<app-visit-patient-detail-modal #visitPatientDetailModal></app-visit-patient-detail-modal>

<app-trips-over-budget-request-modal #tripsOverBudgetRequestModal (overBudgetRequestRaised)="onOverBudgetRequestRaised($event)"></app-trips-over-budget-request-modal>
