import { SelectOption } from "../models/select-option.model";

export class TravelRequestedOptions {
  public static all(): SelectOption[] {
    return [
      {
        "value": "No",
        "text": "No"
      },
      {
        "value": "Yes",
        "text": "Yes"
      },
      {
        "value": "TBC",
        "text": "TBC"
      },
      {
        "value": "ExpenseReimbursementOnly",
        "text": "Expense Reimbursement Only"
      }
    ];
  }
}
