<input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload {{ state }}" [ngStyle]="{'background': uploadProgressStyle}" (click)="onFileUploadClicked($event)" appDnd (fileDropped)="onFileDropped($event)">

  <a class="remove-video" (click)="removeVideo($event)">
    <i class="far fa-times" aria-hidden="true"></i>
  </a>

  <div class="text" *ngIf="uploadProgress === 0">
    <p class="title">Upload Header Video</p>
    <p class="text">Click to select a file, or drag and drop here</p>
    <p class="formats">Accepted format is MP4 only. Max file size {{ maxSizeMb }}MB</p>
  </div>

  <div class="text uploading" *ngIf="uploadProgress > 0">
    <h2>{{ uploadProgress }}%</h2>
    <a (click)="cancelUpload(); $event.stopPropagation();" class="btn btn-primary btn-outline">Cancel Upload</a>
  </div>

  <div class="video">
    <video controls playsinline [src]="videoUrl"></video>
  </div>
</div>
