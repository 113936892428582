<div class="visit-table-cell-time" (dblclick)="onEdit()" [class.editing]="editing" [class.editable]="editable">
  <span *ngIf="!editing">
    {{ getFormattedTime() }}
  </span>

  <span *ngIf="editing && editable" class="editing" [formGroup]="parentForm">
    <mat-form-field>
    <input #input type="time" matInput [formControlName]="controlName" (blur)="onBlur()">
  </mat-form-field>
  </span>
</div>

