<div class="patient-registration-container view-body">
  <div class="patient-registration-header">
    <h1>Patient Registration Form for PatientGO®'s Services</h1>
  </div>

  <div class="content-panel">
    <form [formGroup]="patientRegistrationForm" (submit)="createPatientRegistration()">

      <table class="simple-form">
        <tr>
          <th><label for="siteNumber">Site Number</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="siteNumber" class="form-control" formControlName="siteNumber">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="investigatorName">Investigator Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="investigatorName" class="form-control" formControlName="investigatorName">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="trialCode">Public Trial Code</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="trialCode" class="form-control" formControlName="trialCode">
            </div>
          </td>
        </tr>
      </table>

      <h2>Patient Details</h2>
      <table class="simple-form">
        <tr>
          <th><label for="patientName">Patient Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="patientName" class="form-control" formControlName="patientName">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="patientNumber">Patient Number</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="patientNumber" class="form-control" formControlName="patientNumber">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="yearOfBirth">Year of Birth</label></th>
          <td>
            <div class="form-group">
              <input type="number" id="yearOfBirth" class="form-control" formControlName="yearOfBirth">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="address">Address</label></th>
          <td>
            <div class="form-group">
              <textarea type="text" id="address" class="form-control" formControlName="address"></textarea>
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="phoneNumber">Phone Number</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="phoneNumber" class="form-control" formControlName="phoneNumber">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="email">Email Address</label></th>
          <td>
            <div class="form-group">
              <input type="email" id="email" class="form-control" formControlName="email">
            </div>
          </td>
        </tr>
      </table>

      <h2>Emergency Contact Details (if provided)</h2>
      <table class="simple-form">
        <tr>
          <th><label for="contactName">Contact Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="contactName" class="form-control" formControlName="contactName">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="contactPhoneNumber">Contact Phone Number</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="contactPhoneNumber" class="form-control" formControlName="contactPhoneNumber">
            </div>
          </td>
        </tr>

        <tr>
          <th><label for="contactRelationship">Relationship</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="contactRelationship" class="form-control" formControlName="contactRelationship">
            </div>
          </td>
        </tr>
      </table>

      <h2>To be Completed by the Investigator or Delegate</h2>
      <table class="simple-form">
        <tr>
          <th><label for="signature">Signature</label></th>
          <td>
            <div class="form-group">
              <signature-pad class="signature-pad" #signature [options]="signaturePadOptions"></signature-pad>
            </div>
          </td>
        </tr>
      </table>

      <button type="submit" class="btn btn-primary">
        <span *ngIf="processingRequest">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!processingRequest">Create Patient Registration</span></button>
    </form>
  </div>

</div>
