import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Currencies } from 'app/core/constants/currency';
import { LogHelper } from 'app/core/helpers/log.helper';
import { TrialService } from 'app/core/services/trial.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import {
  CreateTrialPolicyMileageRateRequest,
  DistanceUnit,
  DistanceUnitString,
  MileageRateDistanceLimitRequest
} from '../../trial-policy.model';
import { DistanceRangeValidator } from 'app/core/validators/distance-range.validator';

@Component({
  selector: 'app-create-mileage-rate-rule-modal',
  templateUrl: './create-mileage-rate-rule-modal.component.html',
  styleUrls: ['./create-mileage-rate-rule-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateMileageRateRuleModalComponent implements OnInit {
  @ViewChild("currencySelect") currencySelect: DropdownInputComponent;
  @ViewChild("distanceUnitSelect") distanceUnitSelect: DropdownInputComponent;
  @Input() defaultDistanceUnit: string;
  @Input() defaultCurrency: string;
  @Input() policyId: string;
  @Output() ruleAdded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent;

  ruleForm: UntypedFormGroup;
  distanceLimitForms: UntypedFormGroup[] = [];
  currencies: { value: string, text: string }[] = [];
  engineSizes: { value: string, text: string }[] = [];
  distanceUnits: { value: string, text: string }[] = [];

  isFormProcessing = false;
  shorthandNotation: string = '';

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.initRuleForm();
    this.initDistanceLimitForms();
  }

  show() {
    this.initiateCurrencies();
    this.initiateDistanceUnits();

    this.modal.show();
  }

  initRuleForm() {
    this.ruleForm = new UntypedFormGroup({
      engineSize: new UntypedFormControl('', Validators.required),
      distanceUnit: new UntypedFormControl('', Validators.required),
      currency: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl('')
    });
  }

  initDistanceLimitForms() {
    const form = new UntypedFormGroup({
      distanceFrom: new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
      distanceTo: new UntypedFormControl('', Validators.min(0)),
      amountPerDistance: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      extraAmount: new UntypedFormControl('', Validators.min(0))
    }, { validators: DistanceRangeValidator });

    form.valueChanges.subscribe(() => {
      this.createShorthandNotation();
    });

    this.distanceLimitForms.push(form);
  }

  initiateCurrencies() {
    if (!this.currencies.length) {
      this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
    }
    if (this.defaultCurrency) {
      setTimeout(() => {
        this.currencySelect.setValue(this.defaultCurrency);;
      }, 10);
    }
  }

  initiateDistanceUnits() {
    if (!this.distanceUnits.length) {
      this.distanceUnits.push({ value: DistanceUnit.Km.toString(), text: DistanceUnitString.Values[DistanceUnit.Km] });
      this.distanceUnits.push({ value: DistanceUnit.Mile.toString(), text: DistanceUnitString.Values[DistanceUnit.Mile] });
    }
    if (this.defaultDistanceUnit) {
      this.distanceUnitSelect.setValue(this.defaultDistanceUnit);
    }
  }

  submit() {
    let request = this.ruleForm.value as CreateTrialPolicyMileageRateRequest;
    request.trialPolicyId = this.policyId;
    request.distanceLimits = this.distanceLimitForms.map(x => x.value as MileageRateDistanceLimitRequest);

    this.isFormProcessing = true;

    this.trialService.createTrialPolicyMileageRateRule(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Mileage rate rule successfully created.");
        this.ruleAdded.emit();
        this.isFormProcessing = false;
        this.hideModal();
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to create mileage rate rule. Please check your input and try again or contact support.");
        this.isFormProcessing = false;
      }
    });
  }

  addDistanceLimit() {
    const form = new UntypedFormGroup({
      distanceFrom: new UntypedFormControl(this.distanceLimitForms[this.distanceLimitForms.length - 1].get("distanceTo").value + 1, [Validators.required, Validators.min(0)]),
      distanceTo: new UntypedFormControl('', Validators.min(0)),
      amountPerDistance: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      extraAmount: new UntypedFormControl('', Validators.min(0))
    }, { validators: DistanceRangeValidator });

    this.distanceLimitForms.push(form);

    form.valueChanges.subscribe(() => {
      this.createShorthandNotation();
    });
  }

  hideModal() {
    this.shorthandNotation = "";
    this.distanceLimitForms = [];
    this.initDistanceLimitForms();
    this.ruleForm.reset();
    this.currencySelect.reset();
    this.distanceUnitSelect.reset();
    this.modal.hide();
  }

  nextLimitAvailable(): boolean {
    if (this.distanceLimitForms.length === 0) {
      return false;
    }
    const lastLimit = this.distanceLimitForms[this.distanceLimitForms.length - 1];

    if (this.distanceLimitForms.length === 1) {
      return lastLimit.get("amountPerDistance").value;
    }

    return lastLimit.get("distanceFrom").value && lastLimit.get("amountPerDistance").value;
  }

  formsValid(): boolean {
    return this.ruleForm.valid && this.distanceLimitForms.every(x => x.valid);
  }

  createShorthandNotation() {
    this.shorthandNotation = '';
    this.distanceLimitForms.forEach((form, index) => {
      const limit = form.value as MileageRateDistanceLimitRequest;

      if (index > 0) {
        this.validateLimits(limit, index);
      }

      const firstVal = limit.distanceTo ? `<=${limit.distanceTo}` : `>${limit.distanceFrom}`;
      const secondVal = limit.amountPerDistance ? `dx${limit.amountPerDistance}` : '';
      const thirdVal = limit.extraAmount && limit.extraAmount !== 0 ? `(${secondVal})+${limit.extraAmount}` : secondVal;

      const final = `;${firstVal}=${thirdVal}`;

      this.shorthandNotation += final;

      const firstCharacter = this.shorthandNotation.charAt(0);
      if (firstCharacter === ';') {
        this.shorthandNotation = this.shorthandNotation.slice(1);
      }
    });
  }

  validateLimits(limit: MileageRateDistanceLimitRequest, index: number) {
    const previousLimit = this.distanceLimitForms[index - 1].value as MileageRateDistanceLimitRequest;

    if (limit.distanceFrom <= previousLimit.distanceTo) {
      this.distanceLimitForms[index].get("distanceFrom").setErrors({ rangeError: true });
      this.distanceLimitForms[index - 1].get("distanceTo").setErrors({ rangeError: true });
    } else {
      this.distanceLimitForms[index].get("distanceFrom").setErrors(null);
      this.distanceLimitForms[index - 1].get("distanceTo").setErrors(null);
    }
  }

  removeLimit(i: number) {
    this.distanceLimitForms.splice(i, 1);
    this.createShorthandNotation();
  }
}

