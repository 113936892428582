<div *ngIf="selectedTab === ExpenseClaimState.Processing && dataLoaded" class="payment-data">
  <p>
    Payment requested on:
    <strong>
      {{ DateHelper.dateUTCToLocal(expense.paymentRequestedOn) | date: 'dd/MM/yy' }}
    </strong>
  </p>
  <p>
    Expected clearance date:
    <strong>
      {{ DateHelper.dateUTCToLocal(expense.expectedClearanceDate) | date: 'dd/MM/yy'}}
    </strong>
  </p>
</div>

<div *ngIf="selectedTab === ExpenseClaimState.Processing && !dataLoaded" class="payment-data">
  <div class="loaders">
    <ngx-skeleton-loader style="width: 200px;"></ngx-skeleton-loader>
    <ngx-skeleton-loader style="width: 210px;"></ngx-skeleton-loader>
  </div>
</div>
