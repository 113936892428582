
export class NavigationItem {
  public text: string;
  public routerLink: string;
  public roles: string[];
  public iconClassName: string;
  public iconImageUrl: string;
  public iconImageAlt: string;

  constructor(text: string, routerLink: string, iconClassName: string, roles: string[], iconImageUrl: string = null, iconImageAlt: string = null) {
    this.text = text;
    this.routerLink = routerLink;
    this.roles = roles;
    this.iconClassName = iconClassName;
    this.iconImageUrl = iconImageUrl;
    this.iconImageAlt = iconImageAlt;
  }
}
