import { AuthService } from './../../../core/services/auth.service';
import { Permissions } from './../../../core/constants/permissions';
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TrialBookingService } from "../../../core/services/trial-booking.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TrialBookingSearch } from "../../../core/models/trial-booking-search.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectOption } from "../../../core/models/select-option.model";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { InputFileComponent } from "../../../shared/input-file/input-file.component";
import { CsvToJsonHelper } from "../../../core/helpers/csvtojson.helper";
import { StringHelper } from "../../../core/helpers/string-helper";
import { TrialBookingImportItem } from "../../../core/services/interfaces/trial-booking-import-item.interface";

@Component({
  selector: 'app-trial-bookings',
  templateUrl: './trial-bookings.component.html',
  styleUrls: ['./trial-bookings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrialBookingsComponent implements AfterViewInit, OnInit {
  @ViewChild('importModal') importModal: ModalComponent;
  @ViewChild('inputFile') inputFile: InputFileComponent;
  @ViewChild('unsentModal') unsentModal: ModalComponent;

  Permissions = Permissions;

  @Input('trialId') trialId: string;

  headers: string[] = ["PatientID", "FirstName", "Name", "Surname", "Email", "StartDate", "EndDate", "AppointmentStatus", "Study", "SiteID"];

  result = new TrialBookingSearch();
  configOptions: SelectOption[] = [];
  importForm: UntypedFormGroup;
  csvError = '';
  csvJsonArray: any[] = [];
  unsentCount = 0;
  sendingAllUnsent = false;
  filterConfigOptions: SelectOption[] = [];
  validCsvRows = 0;
  showConfiguration = false;

  filterForm = new UntypedFormGroup({
    bookingConfigId: new UntypedFormControl('')
  });

  constructor(private _trialBookingService: TrialBookingService, private _alertService: AlertService, public authService: AuthService) {
    this.importForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      configId: new UntypedFormControl('', Validators.required),
      csvFile: new UntypedFormControl('', Validators.required),
      csvValid: new UntypedFormControl(false)
    });
  }

  ngOnInit(): void {
    this.importForm.get('csvFile').valueChanges.subscribe(value => {
      if (value !== undefined && value !== null && value !== '') {
        const jsonArray = CsvToJsonHelper.convert(this.inputFile.getFileData());

        const result = this.verifyImport(jsonArray);
        if (result.success) {
          this.importForm.patchValue({csvValid: true});
          this.csvJsonArray = jsonArray;
          this.csvError = '';
        } else {
          this.importForm.patchValue({csvValid: false});
          this.csvError = result.errors[0];
        }
      } else {
        this.csvError = '';
        this.importForm.patchValue({csvValid: false});
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadTrialConfigs();

    if(this.authService.hasPermission(Permissions.TrialBookingFull)){
      this.loadBookings(1);
      this.loadUnsentCount();

      // Reload results when the filter has been changed
      this.filterForm.get('bookingConfigId').valueChanges.subscribe(bookingConfigId => {
        this.loadBookings(1);
      });
    }

  }

  toggleConfiguration() {
    this.showConfiguration = !this.showConfiguration;

    // Reload trial configurations if the user has just returned from the config view
    if (!this.showConfiguration)
        this.loadTrialConfigs();
  }

  /**
   * Verifies that an import file is valid
   * @param jsonArray
   * @private
   */
  private verifyImport(jsonArray: any): {success: boolean, errors: string[]} {
    this.validCsvRows = 0;
    const configItem = this.configOptions.find(x => x.value === this.importForm.get('configId').value);
    const appointmentStatus = configItem.text;

    // Verify that the required headers are present
    this.headers.forEach(header => {
      if (!jsonArray.hasOwnProperty(header))
        return {success: false, errors: ["The file is missing the required header: " + header + "."]};
    });

    // Verify that there are results in the array
    if (jsonArray.length < 1)
      return {success: false, errors: ["File has no data."]};

    const rowOffset = 2;

    // Verify that each row has the correct number of columns and that no columns are empty
    for (let i = 0; i < jsonArray.length; i++) {
      if (Object.keys(jsonArray[i]).length !== this.headers.length)
        return {success: false, errors: ["Row " + (i + rowOffset) + " has an incorrect number of columns."]};

      this.headers.forEach(header => {
        if (jsonArray[i][header] === '')
          return {success: false, errors: ["Row " + (i + rowOffset) + " has an empty column."]};
      });
    }

    // Verify that the email address on each row is valid and that start/end dates are present and valid
    for (let i = 0; i < jsonArray.length; i++) {
      if (!this.isRowEmpty(jsonArray[i])) {
        // valid email address?
        if (!StringHelper.isEmailValid(jsonArray[i].Email))
          return {success: false, errors: ["Row " + (i + rowOffset) + " has an invalid email address '"+jsonArray[i].Email+"'."]};

        // Has firstname
        if (jsonArray[i].FirstName === undefined || jsonArray[i].FirstName === null || jsonArray[i].FirstName === '')
          return {success: false, errors: ["Row " + (i + rowOffset) + " is missing the patient firstname."]};

        // Has surname
        if (jsonArray[i].Surname === undefined || jsonArray[i].Surname === null || jsonArray[i].Surname === '')
          return {success: false, errors: ["Row " + (i + rowOffset) + " is missing the patient surname."]};

        // Valid start date?
        if (this.importDateStrToDate(jsonArray[i].StartDate) === null)
          return {success: false, errors: ["Row " + (i + rowOffset) + " has an invalid start date. Format of dd-MMM-yy is expected."]};

        // Valid end date?
        if (this.importDateStrToDate(jsonArray[i].EndDate) === null)
          return {success: false, errors: ["Row " + (i + rowOffset) + " has an invalid end date. Format of dd-MMM-yy is expected."]};

        // Appointment status matches selected config?
        if (jsonArray[i].AppointmentStatus !== appointmentStatus)
          return {success: false, errors: ["Row " + (i + rowOffset) + " has an invalid appointment status of " + jsonArray[i].AppointmentStatus + ", expecting " + appointmentStatus + "."]};

        this.validCsvRows++;
      }
    }

    return {success: true, errors: []};
  }

  private isRowEmpty(row: any): boolean {
    let empty = true;

    this.headers.forEach(key => {
      if (row[key] !== undefined && row[key] !== null && row[key] !== '')
        empty = false;
    });

    return empty;
  }

  /**
   * Verifies that a date string is valid and returns the date object
   * @param dateString
   * @private
   */
  importDateStrToDate(dateString: string): Date {
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateParts = dateString.split('-');

    // Verify that the date is compromised of three parts
    if (dateParts.length !== 3)
      return null;

    if (!this.isValidDateFormat(dateString))
      return null;

    // Verify that day
    const day = Number(dateParts[0]);
    if (isNaN(day) || day < 1 || day > 31)
      return null;

    // Verify the month is a valid abbreviation
    if (!monthAbbreviations.includes(dateParts[1]))
      return null;

    // Verify year value is correct
    const year = Number(dateParts[2]) + 2000;
    if (isNaN(year) || year < 2022 || year > 2099)
      return;

    // Convert the month abbreviation to a numeric month value
    const month = monthAbbreviations.indexOf(dateParts[1]);

    // Create the Date object using the parts
    return new Date(year, month, Number(dateParts[0]), 12, 0 ,0);
  }

  private isValidDateFormat(input: string): boolean {
    const dateFormatRegex = /^(0[1-9]|[1-2]\d|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{2}$/i;
    return dateFormatRegex.test(input);
  }

  /**
   * Load bookings for the trial
   * @param page
   * @private
   */
  private loadBookings(page = 1): void {
    this._trialBookingService.trialBookings(this.trialId, this.filterForm.get('bookingConfigId').value, page).subscribe({
      next: result => {
        this.result = result;
      },
      error: error => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Loads the number of unsent invites
   * @private
   */
  private loadUnsentCount(): void {
    this._trialBookingService.unsentInvitesCountForTrial(this.trialId).subscribe({
      next: rsp => {
        this.unsentCount = rsp.count;
      },
      error: error => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

  private loadTrialConfigs(): void {
    this._trialBookingService.getBookingConfigurationsForTrial(this.trialId).subscribe({
      next: items => {
        this.configOptions = items.map(i => new SelectOption(i.id, i.name));
        this.filterConfigOptions = items.map(i => new SelectOption(i.id, i.name));
        this.filterConfigOptions.unshift({value: '', text: 'All Types'});
      },
      error: error => {
        this._alertService.showErrorAlert(error);
      }
    })
  }

  onHideImportModal(): void {
    this.importForm.reset();
    this.inputFile.onRemove();
    this.csvError = '';
    this.importModal.hide();
  }

  /**
   * Called when the user changes the page
   * @param page
   */
  onChangePage(page: number): void {
    this.loadBookings(page);
  }

  onShowImportModal(): void {
    this.importForm.reset();
    this.importForm.patchValue({processing: false});
    this.importModal.show();
  }

  /**
   * Sends an invite to a patient
   * @param patientBookingId
   * @param resend
   */
  onSendInvite(patientBookingId: string, resend = false): void {
    this._trialBookingService.sendInvite(patientBookingId).subscribe({
      next: () => {
        if (resend) {
          this._alertService.showSuccessAlert('The invite has been resent to the patient.');
        } else {
          if (this.unsentCount > 0) {
            this.unsentCount --;
          }

          this._alertService.showSuccessAlert('An invite has been sent to the patient.');
        }

        this.loadBookings(this.result.currentPage);
      },
      error: (error) => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Sends an invite to all patients who are yet to be sent an invite
   */
  onSendUnsentInvites(): void {
    this.sendingAllUnsent = true;
    this._trialBookingService.sendUnsentInvitesForTrial(this.trialId).subscribe({
      next: () => {
        this.sendingAllUnsent = true;
        this._alertService.showSuccessAlert('An invite has been sent to ' + this.unsentCount + ' patients.');

        this.unsentModal.hide();
        this.unsentCount = 0;
        this.loadBookings(this.result.currentPage);
      },
      error: (error) => {
        this.sendingAllUnsent = true;
        this._alertService.showErrorAlert(error);
      }
    });
  }

  onStartImport(): void {
    let items: TrialBookingImportItem[] = [];

    this.csvJsonArray.forEach(item => {
      if (!this.isRowEmpty(item)) {
        items.push({
          patientCode: item.PatientID,
          patientFirstname: item.FirstName,
          patientLastname: item.Surname,
          patientEmail: item.Email,
          startDate: this.importDateStrToDate(item.StartDate),
          endDate: this.importDateStrToDate(item.EndDate),
          appointmentStatus: item.AppointmentStatus,
          trialCode: item.Study,
          siteCode: item.SiteID
        });
      }
    });

    this.importForm.patchValue({processing: true});
    this._trialBookingService.import(this.importForm.get('configId').value, items).subscribe({
      next: (rsp: any) => {
        if (!rsp.success) {
          this._alertService.showMultiLineWarningAlert('Import Error', 'There are ' + rsp.errors.length + ' errors in the import file. Please correct the errors and try again.');

          this.importForm.patchValue({processing: false});
          this.inputFile.onRemove();
          this.csvError = rsp.errors.join('<br>');
          return;
        } else {
          this.csvError = '';
          this.importForm.patchValue({processing: false});
          this._alertService.showSuccessAlert("Import successful");
          this.onHideImportModal();
          this.loadBookings(1);
          this.loadUnsentCount();
        }
      },
      error: (error) => {
        this.importForm.patchValue({processing: false});
        this._alertService.showErrorAlert(error);
      }
    });
  }

  getBadgeColourForStatus(status: string): string {
    switch (status) {
      case 'Pending':
        return 'badge-warning';
      case 'Booked':
        return 'badge-success';
      case 'Cancelled':
        return 'badge-danger';
    }

    return 'badge-info';
  }

}
