export class FileAttachment {
  public id: string;
  public weight: number;
  public entityType: string;
  public entityId: string;
  public originalFileName: string;
  public fileName: string;
  public container: string;
  public contentType: string;
  public url: string;
  public content: Blob;

  constructor(init?: Partial<FileAttachment>) {
    Object.assign(this, init);
  }
}
