import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";
import {TimelineEvent} from "../../core/models/TimelineEvent";

@Component({
  selector: 'app-preview-timeline',
  templateUrl: './preview-timeline.component.html',
  styleUrls: ['./preview-timeline.component.scss']
})
export class PreviewTimelineComponent {
  @ViewChild('previewModal') previewModal: ModalComponent;

  @Input("timelineEvents") timelineEvents: TimelineEvent[] = [];

  onPreview() {
    this.previewModal.show();
  }

  onClose() {
    this.previewModal.hide();
  }
}
