import { ExpenseCategoryType, ExpenseClaimState, ExpenseSubCategory } from './../../../../core/models/expense.model';
import { Component, Input, OnInit } from '@angular/core';
import { StringHelper } from 'app/core/helpers/string-helper';
import { ExpenseClaimViewModel } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-cost',
  templateUrl: './expense-cost.component.html',
  styleUrls: ['./expense-cost.component.scss']
})
export class ExpenseCostComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;
  @Input() dataLoaded: boolean;

  ExpenseCategoryType = ExpenseCategoryType;
  ExpenseSubCategory = ExpenseSubCategory;
  stringHelper = StringHelper;

  constructor() { }

  ngOnInit(): void {
  }

  isCaxtonTab() {
    return this.selectedTab === ExpenseClaimState.CaxtonApproved || this.selectedTab === ExpenseClaimState.Processing || this.selectedTab === ExpenseClaimState.CaxtonFailed;
  }
}
