import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaxtonCardsListComponent } from './caxton-cards-list/caxton-cards-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [
    CaxtonCardsListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class CardTrackerModule { }
