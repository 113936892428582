import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownInputComponent } from '../../../../shared/dropdown-input/dropdown-input.component';
import { TrialAutocompleteComponent } from '../../../../shared/trial-autocomplete/trial-autocomplete.component';
import { SiteAutocompleteComponent } from '../../../../shared/site-autocomplete/site-autocomplete.component';
import { SelectOption } from '../../../../core/models/select-option.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ObjectHelper } from '../../../../core/helpers/object.helper';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent } from '../../../../shared/modal/modal.component';
import { SiteContactSiteViewModel } from '../SiteContactSite.view.model';
import { AlertService } from '../../../../shared/alert/alert.service';
import { TrialService } from 'app/core/services/trial.service';

@Component({
  selector: 'app-manage-user-sites',
  templateUrl: './manage-user-sites.component.html',
  styleUrls: ['./manage-user-sites.component.scss']
})
export class ManageUserSitesComponent implements OnInit {
  @ViewChild('unassignModel') public unassignModel: ModalComponent;
  @ViewChild('roleDropdown') roleDropdown: DropdownInputComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;

  @Output('assignedSitesChanged') assignedSitesChanged = new BehaviorSubject<SiteContactSiteViewModel[]>(null);
  @Output('hideFormButtons') hideFormButtons = new EventEmitter<boolean>();

  @Input('contactName') contactName: string = null;

  showAssignForm = true;
  roleOptions: SelectOption[] = [
    { value: '0', text: 'Principle Investigator' },
    { value: '1', text: 'Sub Investigator' },
    { value: '2', text: 'Site Coordinator' }
  ];
  assignedSites: SiteContactSiteViewModel[] = [];

  assignSiteForm: UntypedFormGroup;
  objectHelper = ObjectHelper;

  modifyAtIndex: number = -1;
  modifySiteIrgSiteId: string = '';
  modifySiteName: string = '';
  modifyTrialCode: string = '';

  constructor(private alertService: AlertService, private trialService: TrialService) { }

  ngOnInit(): void {
    this.assignSiteForm = new UntypedFormGroup({
      trialId: new UntypedFormControl('', Validators.required),
      siteId: new UntypedFormControl('', Validators.required),
      role: new UntypedFormControl('', Validators.required)
    });

    this.assignSiteForm.get('trialId').valueChanges.subscribe(value => {
      if (!ObjectHelper.isUndefinedNullOrEmpty(value)) {
        this.siteAutocomplete.enableInput();
        this.siteAutocomplete.setTrialId(value);
      }
    });
  }

  onHandleEditAtIndex(index: number) {
    let site = this.assignedSites[index];

    this.modifyAtIndex = index;
    this.modifyTrialCode = site.trialNickname?.length ?
      `${site.trialCode} ${site.trialNickname} - ${site.trialOpportunityNumber}` :
      `${site.trialCode} ${site.trialOpportunityNumber}`;
    this.modifySiteName = site.siteName;
    this.modifySiteIrgSiteId = site.irgSiteId;
    this.assignSiteForm.patchValue({ role: site.role.toString() });
    this.roleDropdown.setValue(site.role.toString());

    this.showAssignForm = false;
    this.hideFormButtons.emit(true);
    this.assignSiteForm.patchValue({
      trialId: this.assignedSites[index].trialId,
      siteId: this.assignedSites[index].siteId,
      role: this.assignedSites[index].role
    });
  }

  setAssignedSites(sites: SiteContactSiteViewModel[]) {
    this.assignedSites = sites;

    // Raise event to let subscribers know that the assigned sites have changed
    this.assignedSitesChanged.next(this.assignedSites);
  }

  onHandleShowAssignTrial() {
    this.showAssignForm = false;
    this.hideFormButtons.emit(true);
  }

  onHandleReturnToForm() {
    this.showAssignForm = true;
    this.hideFormButtons.emit(false);

    this.assignSiteForm.reset();
    this.trialAutocomplete.reset();
    this.siteAutocomplete.clear();
    this.modifyAtIndex = -1;
    this.hideFormButtons.emit(false);
  }

  onHandleDeleteConfirmed(index: number) {
    this.assignedSites[index].deleted = true;

    // Raise event to let subscribers know that the assigned sites have changed
    this.assignedSitesChanged.next(this.assignedSites);

    // Reset the unassigning Site flag
    this.unassignModel.deleteIndex = -1;

    this.hideFormButtons.emit(false);

    // Hide the modal window
    this.unassignModel.hide();
  }

  onHandleRemoveAtIndex(index: number) {
    this.unassignModel.resetDeleteIndexAndId();
    this.unassignModel.show();
  }

  onHandleUpdateSite(index: number) {
    let role = this.roleOptions.find(x => x.value === this.assignSiteForm.get('role').value);

    this.assignedSites[index].role = +role.value;
    this.assignedSites[index].roleName = role.text;

    this.showAssignForm = true;
    this.modifyAtIndex = -1;
    this.modifySiteName = '';
    this.modifyTrialCode = '';

    // Raise event to let subscribers know that the assigned sites have changed
    this.assignedSitesChanged.next(this.assignedSites);
    this.hideFormButtons.emit(false);
  }

  onHandleAssignSite() {
    let site = this.siteAutocomplete.selected;

    // First let's make sure this site isn't already assigned
    let existingSite = this.assignedSites.find(x => x.siteId === site.id && x.trialId === this.assignSiteForm.get('trialId').value);

    this.trialService.retrieveTrial(this.assignSiteForm.get('trialId').value).subscribe({
      next: response => {
        if (ObjectHelper.isUndefinedOrNull(existingSite)) {
          this.assignedSites.push(new SiteContactSiteViewModel(
            '',
            site.irgSiteId,
            site.id,
            this.assignSiteForm.get('trialId').value,
            response.code,
            response.nickname,
            response.opportunityNumber,
            this.assignSiteForm.get('role').value,
            site.name,
            this.roleOptions.find(x => x.value === this.assignSiteForm.get('role').value).text,
            false
          ));

          // Raise event to let subscribers know that the assigned sites have changed
          this.assignedSitesChanged.next(this.assignedSites);
        } else {
          // Undelete the existing site
          if (existingSite.deleted) {
            existingSite.deleted = false;
          } else {
            this.alertService.showWarningAlert('This trial/site combination is already assigned to this contact.');
          }
        }

        this.assignSiteForm.reset();
        this.trialAutocomplete.reset();
        this.siteAutocomplete.clear();
        this.showAssignForm = true;
        this.hideFormButtons.emit(false);
      }
    });
  }
}
