<app-modal #modal class="create-expense-modal">
  <div class="modal-header">
    <h2 class="modal-title">Create Expense</h2>
  </div>
  <div class="modal-body">
    <form (submit)="onFormSubmit()" [formGroup]="form">
      <input #uploadImageInput (change)="preview(uploadImageInput.files)" accept='image/*,.pdf' style="display: none;"
             type="file">

      <table aria-hidden="true" class="simple-form">
        <tr *ngIf="returnTo !== 'patient'">
          <th><label>Patient</label></th>
          <td>
            <div class="form-group">
              <app-patient-autocomplete #patientAutoComplete [options]="patientSuggestions" [parentForm]="form"
                                        [required]="true" controlName="patientName"
                                        placeholder="Patient Name or Id..."></app-patient-autocomplete>
            </div>
          </td>
        </tr>
        <tr *ngIf="patient && returnTo === 'patient'">
          <th><label>Patient</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="patientName" type="text">
            </div>
          </td>
        </tr>
        <tr [class.visibility-hidden]="form.get('patientId').value === ''">
          <th><label>Trial</label></th>
          <td>
            <div class="form-group">
              <app-trial-autocomplete #trialAutocomplete [form]="form" controlName="trialId"
                                      placeholder="Select a trial..."></app-trial-autocomplete>
            </div>
          </td>
        </tr>
        <tbody *ngIf="patient && trial">
        <tr>
          <th><label>Visit</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #visitSelect [options]="visitOptions" [parentForm]="form" name="visitId"
                                  placeholder="Select Visit..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Expense Category</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #categorySelect [options]="categoryOptions" [parentForm]="form" name="categoryId"
                                  placeholder="Select Category..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr
          *ngIf="trial !== undefined"
          [ngClass]="{'visibility-hidden': selectedCategory === null || selectedCategory.internalType.toLowerCase() !== 'other'}">
          <th></th>
          <td>
            <div class="radio-button-container">
              <div *ngFor="let subCategory of trial.subCategories" class="form-group">
                <label class="radio-button">
                  <input [value]="subCategory.id" formControlName="subCategoryId" type="radio"/>
                  <span></span>
                  {{ subCategory.name }}
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>Currency</label></th>
          <td>
            <div class="form-group">
              <app-autosuggest-dropdown-input #currencySelect [options]="currencyOptions" [parentForm]="form"
                                              controlName="currency"
                                              placeholder="Select Currency..."></app-autosuggest-dropdown-input>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>Amount</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="amount" type="number" (keydown)="numberHelper.decimalFilter($event)">
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>&nbsp;</label></th>
          <td>
            <a class="col-12 btn btn-primary" (click)="onShowTransformedFxRate()">
              <span *ngIf="transformedCurrencyProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!transformedCurrencyProcessing">FX</span>
            </a>
          </td>
        </tr>
        <tr *ngIf="showFxTransformError && !canTransformRate" [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>&nbsp;</label></th>
          <td>
            <div class="col-12 col-lg-12 quoted-amount-error-message-wrapper">
              <span class="quoted-amount-error-message">The quoted amount, currency and the base currency on connected trial cannot be blank.</span>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>Amount Quoted (BC)</label></th>
          <td>
            <div class="form-group">
              <div class="row">
                <div class="col-12">
                  <app-input-currency
                    #amountQuotedBCControl
                    [parentForm]="transformedFxRateForm"
                    placeholder="0.00"
                    amountControlName="transformedAmount"
                    currencyControlName="transformedCurrency">
                  </app-input-currency>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Distance'}">
          <th class="valign-top"><label>Distance Unit</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #distanceUnitSelect [options]="distanceUnitOptions" [parentForm]="form"
                                  name="distanceUnit"
                                  placeholder="Select Distance Unit..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Distance'}">
          <th class="valign-top"><label>Distance</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="distanceAmount" type="number">
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Expense Notes</label></th>
          <td>
            <div class="form-group">
              <textarea class="form-control" formControlName="notes"></textarea>
            </div>
          </td>
        </tr>
        <tr *ngIf="selectedCategory.name !== 'Mileage'">
          <th class="vertical-align-top"><label>Receipt Photos</label></th>
          <td class="expense-add-images" colspan="2">
            <div class="form-group">
              <ul class="images">
                <li *ngFor="let attachment of attachments; let i = index;">
                  <ng-container *ngIf="attachment.ext !== 'pdf'">
                    <img [src]="attachment.imageUrl" alt=""/>
                    <a (click)="onRemoveImage(i)" class="remove">
                      <i aria-hidden="true" class="far fa-times"></i>
                    </a>
                  </ng-container>

                  <ng-container *ngIf="attachment.ext === 'pdf'">
                    <span class="pdf-icon">
                      <i class="far fa-file-pdf"></i>
                    </span>
                    <a (click)="onRemoveImage(i)" class="remove">
                      <i aria-hidden="true" class="far fa-times"></i>
                    </a>
                  </ng-container>
                </li>
                <li><a (click)="onAddImage()">+</a></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        <tr>
          <th></th>
          <td>
            <div class="btn-wrap">
              <div class="row">
                <div class="col-6">
                  <a (click)="onCancelEditing()" class="btn btn-secondary">Cancel</a>
                </div>
                <div class="col-6">
                  <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                        <span *ngIf="isFormProcessing">
                            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                          </span>
                    <span *ngIf="!isFormProcessing">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </form>
  </div>
</app-modal>

<app-modal #removePhotoModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Photo</h2>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="removePhotoModal.hide()" class="btn btn-secondary btn-full-span">Cancel</button>
      </div>
      <div class="col-6">
        <button (click)="onConfirmRemoveImage()" class="btn btn-primary btn-full-span">Confirm</button>
      </div>
    </div>
  </div>
</app-modal>
