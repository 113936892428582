import { Component, Input, OnInit } from '@angular/core';
import {PatientService} from "../../../../core/services/patient.service";
import {LogHelper} from "../../../../core/helpers/log.helper";
import {AlertService} from "../../../../shared/alert/alert.service";

@Component({
  selector: 'app-patient-detail-activity',
  templateUrl: './patient-detail-activity.component.html'
})
export class PatientDetailActivityComponent implements OnInit {
  @Input() patientId: string;

  activity: any;
  currentPage = 1;

  constructor(private readonly alertService: AlertService, private readonly patientService: PatientService) { }

  ngOnInit() {
    this.loadActivity(1);
  }

  refreshView() {
    this.loadActivity(this.currentPage);
  }

  loadActivity(page = 1) {
    this.patientService.loadPatientActivity(this.patientId, page).subscribe({
      next: patientActivity => {
        this.activity = patientActivity;
        this.currentPage = page;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert(error.error
          ? error.error.title
          : 'There was a problem loading patient activity');
      }
    });
  }

  onPageSelect(page: number) {
    this.loadActivity(page);
  }
}
