<div class="tab-pane fade show active details-view-results" id="trial-patients" role="tabpanel">
  <div class="details-view-results-head">
    <div class="row">
      <div class="col-3">
        <a class="btn btn-secondary btn-slim" (click)="onHandleBack()"><i class="far fa-angle-left"></i> Back to appointments</a>
      </div>

      <div class="col-9">
        <a class="btn btn-outline btn-outline-slim" (click)="configModal.show(trialId)">Add Configuration</a>
      </div>
    </div>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr style="color: gray;">
      <th>Appointment Type</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let config of configurations">
      <td>{{ config.name }}</td>
      <td>
        <div class="actions">
          <a class="btn btn-outline btn-small" (click)="onDelete(config)">Remove</a>
          <a class="btn btn-primary btn-small" (click)="configModal.showForEdit(trialId, config)">Edit</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="no-results inside" *ngIf="configurations.length == 0">
    <div class="inner">
      <div class="image">
        <img alt="No results found" src="assets/img/no-results-illustration.png"/>
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no bookings configurations for this trial.</p>
      </div>
    </div>
  </div>
</div>

<app-trial-booking-config-modal #configModal (save)="loadConfigurations()"></app-trial-booking-config-modal>

<!-- Start: Delete config modal -->
<app-modal #deleteConfigModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Booking Configuration?</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this booking configuration?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteConfigModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmDelete()" class="btn btn-primary">
          <span *ngIf="deleteProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!deleteProcessing">Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete config modal -->
