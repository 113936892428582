export class PatientVisitListItem {
  public id: string;
  public shortId: string;
  public title: string;
  public date: Date;
  public timeMinutes: string;
  public type: string;
  public typeDescription: string;
  public siteName: string;
  public siteAddress: string;
  public hasTravelRequests: boolean;
  public visitUpcoming: boolean;
  public hasExpenses: boolean;
  public designatedContacts: string[] = [];
  public bookingStatus: string;
  public publishedStatus: string;

  public static fromObject(obj: any) {
    let visit = new PatientVisitListItem();
    visit.id = obj.id;
    visit.shortId = obj.shortId;
    visit.title = obj.title;
    visit.date = obj.date;
    visit.timeMinutes = obj.timeMinutes;
    visit.siteName = obj.siteName;
    visit.siteAddress = obj.siteAddress;
    visit.hasTravelRequests = obj.hasTravelRequests;
    visit.visitUpcoming = obj.visitUpcoming;
    visit.type = obj.type;
    visit.hasExpenses = obj.hasOwnProperty('hasExpenses') ? obj.hasExpenses : false;
    visit.designatedContacts = obj.hasOwnProperty('designatedContacts') ? obj.designatedContacts : [];
    visit.bookingStatus = obj.hasOwnProperty('bookingStatus') ? obj.bookingStatus : '';
    visit.publishedStatus = obj.hasOwnProperty('publishedStatus') ? obj.publishedStatus : '';

    switch (obj.type) {
      case 'Home':
        visit.typeDescription = 'Home Visit';
        break;
      case 'OnSite':
        visit.typeDescription = 'On-Site';
        break;
      case 'Virtual':
        visit.typeDescription = 'Virtual';
        break;
      case 'Telephone':
        visit.typeDescription = 'Telephone';
        break;
    }

    return visit;
  }
}
