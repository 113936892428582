export class VisitSearchCriteria {
  public page: number = 1;
  public pageSize: number = 25;
  public searchTerm: string = null;
  public bookingStatus: string = null;
  public showAllOpenVisits: boolean = true;
  public startDate: Date = null;
  public endDate: Date = null;
  public myTasks: boolean = false;
  public patientId: string = null;
  public trialId: string = null;
  public designatedContact: string = null;

  constructor(init?: Partial<VisitSearchCriteria>) {
    Object.assign(this, init);
  }
}
