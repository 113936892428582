import { MsalService } from '@azure/msal-angular';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard  {

  constructor(private msalService: MsalService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.msalService.instance.getAllAccounts().length < 1) {
      localStorage.setItem("redirect_url", state.url);
      this.router.navigate(["/"]);
    }

    return true;
  }
}
