import { Component, OnInit } from '@angular/core';
import { ChangeLog } from '../../../shared/change-log/change-log.model';
import { AlertService } from '../../../shared/alert/alert.service';
import { SiteContactService } from '../../../core/services/site-contact.service';

@Component({
  selector: 'app-site-contact-change-log',
  templateUrl: './site-contact-change-log.component.html'
})
export class SiteContactChangeLogComponent implements OnInit {
  changeLogResults: ChangeLog;
  dataLoaded: boolean = false;

  constructor(private siteContactService: SiteContactService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.loadChangeLog();
  }

  loadChangeLog(page = 1) {
    this.dataLoaded = false;
    this.siteContactService.changeLog(page).subscribe({
      next: changeLog => {
        this.changeLogResults = changeLog;
        this.dataLoaded = true;
      },
      error: (error) => {
        this.dataLoaded = true;
        this.alertService.showWarningAlert('There was a problem loading the change log.');
      }
    });
  }

  onPageChanged(page: number) {
    this.loadChangeLog(page);
  }

}
