import { ExpenseService } from 'app/core/services/expense.service';
import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { AddExpenseClaimNoteRequest } from 'app/core/models/expense.model';
import { AlertService } from 'app/shared/alert/alert.service';

@Component({
  selector: 'app-expense-add-notes',
  templateUrl: './expense-add-notes.component.html',
  styleUrl: './expense-add-notes.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ExpenseAddNotesComponent {
  expenseClaimId: string;
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent;
  addNoteProcessing: boolean = false;

  addNoteForm = new FormGroup({
    notes: new FormControl('')
  });

  constructor(private expenseService: ExpenseService, private alertService: AlertService) { }

  closeAddNoteModal() {
    this.addNoteForm.reset();
    this.modal.hide();
  }

  addNoteToExpenseClaim() {
    this.addNoteProcessing = true;

    const request: AddExpenseClaimNoteRequest = {
      expenseClaimId: this.expenseClaimId,
      note: this.addNoteForm.controls.notes.value
    };

    this.expenseService.addNoteToExpenseClaim(request).subscribe({
      next: () => {
        this.addNoteProcessing = false;
        this.alertService.showSuccessAlert('Note added successfully.');
        this.noteAdded.emit();
        this.closeAddNoteModal();
      },
      error: error => {
        this.addNoteProcessing = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }
}
