import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteListComponent } from './site-list/site-list.component';
import { SharedModule } from "../../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SiteAddComponent } from './site-add/site-add.component';
import { RouterModule } from "@angular/router";
import { SiteEditComponent } from './site-edit/site-edit.component';
import { SiteAssignedTrialsComponent } from './site-assigned-trials/site-assigned-trials.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    SiteListComponent,
    SiteAddComponent,
    SiteEditComponent,
    SiteAssignedTrialsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatInputModule
  ]
})
export class SiteModule { }
