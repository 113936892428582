<div *ngIf="dataLoaded" class="expense-details-head">
  <div class="patient">
    <mat-icon (click)="filterExpenses()">filter_alt</mat-icon>
    <span class="label">
      <a routerLink="/patient/{{expense.patientTrialId}}">{{expense.patientCode}}</a>
    </span>
    <span class="field-value">{{expense.patientName}}</span>
    <span class="field-value">
      {{ getPatientAddress(expense.patientAddress, expense.patientCountry) }}
    </span>
  </div>

  <div class="payment-method" *ngIf="expense.paymentMethod !== null">
    <span class="field-value">
      {{expense.paymentMethod | enumToText:ExpenseClaimPaymentMethod}}
      <span *ngIf="expense.paymentMethod === ExpenseClaimPaymentMethod.CaxtonCard"> - {{expense.cardIdentifier}}</span>
    </span>
  </div>

  <div class="trial">
    <span class="label"><a routerLink="/trial/{{expense.trialId}}">{{trialText}}</a></span>
  </div>
</div>

<div *ngIf="!dataLoaded" class="expense-details-head">
  <div class="loaders">
    <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
    <ngx-skeleton-loader style="width: 10%; margin-right: 20px;"></ngx-skeleton-loader>
    <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
    <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
  </div>
</div>
