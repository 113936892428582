import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import moment from "moment";
import { environment } from "../../../environments/environment";
import { AdminUser, UpdateAdminUserRequest } from "../models/admin-user.model";
import { ChangeLog } from "../../shared/change-log/change-log.model";
import { AdminUserList } from "../models/admin-user-list.model";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  currentPageNumber = 1;

  constructor(private http: HttpClient) { }

  /**
   * Exports admin and site users
   * @param dateFrom
   * @param dateTo
   */
  exportUsers(dateFrom: string, dateTo: string) {
    let body = {
      dateFrom: dateFrom ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateTo ? moment(dateTo + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/admin/export', body);
  }

  /**
   * Export admin change log
   * @param dateFrom
   * @param dateTo
   */
  exportChangeLog(dateFrom: string, dateTo: string) {
    let body = {
      dateFrom: dateFrom ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateTo ? moment(dateTo + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/admin/changelog/export', body);
  }

  /**
   * Get the admin change log
   * @param page
   */
  changeLog(page = 1) {
    let params = new HttpParams().set('page', page.toString());

    return this.http.get<ChangeLog>(environment.apiUrl + '/admin/changelog', { params: params }).pipe(map(rsp => {
      return new ChangeLog(rsp);
    }));
  }

  /**
   * Deactivates an admin user
   * @param id
   */
  deactivateAdminUser(id: string) {
    return this.http.post(environment.apiUrl + '/admin/' + id + '/deactivate', {});
  }

  /**
   * Retrieve a list of admin users
   * @param currentPage the page number of results to return
   * @param pageSize
   * @param roleNames
   * @param keywords
   */
  retrieveAdminUsers(currentPage = 1, pageSize = 0, roleNames: string = null, keywords: string = null) {
    let params = new HttpParams().set('page', currentPage.toString());
    if (pageSize > 0) {
      params = params.set('pageSize', pageSize.toString());
    }
    if (roleNames) {
      params = params.set('roleNames', roleNames);
    }
    if (keywords) {
      params = params.set('keywords', keywords);
    }

    return this.http.get<AdminUserList>(environment.apiUrl + '/admin', { params: params });
  }

  /**
   * Retrieve a single admin user
   * @param id admin Id
   */
  retrieveAdminUser(id: string) {
    return this.http.get<AdminUser>(environment.apiUrl + '/admin/' + id);
  }

  /**
   * Updates an existing administrator
   */
  updateAdminUser(request: UpdateAdminUserRequest) {
    return this.http.put(environment.apiUrl + '/admin', request);
  }

  /**
   * Creates a new admin user
   * @param firstname admin users firstname
   * @param lastname admin users lastname
   * @param email admin users email address
   * @param roles an array containing a list of roles to be applied to the account
   * @param trialIds
   * @param projectManagerTrialIds
   * @param muvUserId
   */
  createAdminUser(firstname: string, lastname: string, email: string, roles: string[], trialIds: string[], projectManagerTrialIds: string[], muvUserId: string) {
    return this.http.post(environment.apiUrl + '/admin', {
      Firstname: firstname,
      Lastname: lastname,
      Email: email,
      Roles: roles,
      TrialIds: trialIds,
      projectManagerTrialIds: projectManagerTrialIds,
      muvUserId: muvUserId
    });
  }
}
