import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { LogHelper } from '../../core/helpers/log.helper';
import { UpdateLastRouteAction } from '../../core/actions/navigation.action';
import { AppState } from '../../core/state/app.state';
import { ObjectHelper } from '../../core/helpers/object.helper';

@Component({
  selector: '[backBtn]',
  templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
  @Input('alternativeBackRoute') alternativeBackRoute: string;

  constructor(private readonly store: Store<AppState>, private readonly location: Location, private readonly router: Router) {
  }

  handleBackButtonClick() {
    this.store.select('navigation').pipe(take(1)).subscribe(state => {
      let lastRoute = state.lastRoute;

      if (lastRoute !== null && lastRoute !== '' && lastRoute !== this.location.path()) {
        this.router.navigate([lastRoute]).then(() => {
          // Clear the lastRoute value after navigating to it
          this.store.dispatch(new UpdateLastRouteAction(null));
        });
      } else {
        if (ObjectHelper.isUndefinedNullOrEmpty(this.alternativeBackRoute)) {
          this.location.back();
        } else {
          this.router.navigate([this.alternativeBackRoute]).then();
        }
      }
    });
  }
}
