import { Permissions } from './../../../../../core/constants/permissions';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrialPolicy} from '../../trial-policy.model';
import {ObjectHelper} from "../../../../../core/helpers/object.helper";
import {AuthService} from "../../../../../core/services/auth.service";
import {StringHelper} from "../../../../../core/helpers/string-helper";
import { Countries } from 'app/core/constants/countries';

@Component({
  selector: '.trial-policy-row',
  templateUrl: './trial-policy-row.component.html',
  styleUrls: ['./trial-policy-row.component.scss']
})
export class TrialPolicyRowComponent implements OnInit {
  @Output('remove') remove = new EventEmitter<string>();
  @Output('edit') edit = new EventEmitter<string>();

  @Input('policy') policy: TrialPolicy;

  showSites = false;
  country: string;
  objectHelper = ObjectHelper;
  stringHelper = StringHelper;
  Permissions = Permissions;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
      this.country = Countries.all().find(c => c.code === this.policy.countryCode).name;
  }

  onRemove() {
    this.remove.emit(this.policy.id);
  }

  onEdit() {
    this.edit.emit(this.policy.id);
  }

  onShowInfo() {
    this.showSites = true;
  }

  onHideInfo() {
    this.showSites = false;
  }

  categoriesToString(objects: { id: string, name: string}[]): string {
    let stringArray: string[] = [];
    objects.forEach(obj => {
      stringArray.push(obj.name);
    });

    return StringHelper.join(stringArray, ', ', 'None');
  }

}
