import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { VisitSearch } from "../models/visit-search.model";
import { environment } from "../../../environments/environment";
import { VisitSearchCriteria } from "../models/visit-search-criteria.model";
import {
  PatientTrip
} from "../../features/patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { CreateMuvRide } from "./interfaces/create-muv-ride.interface";
import { LogHelper } from "../helpers/log.helper";
import { Observable } from 'rxjs';
import { OverBudgetRequestDetailsForTripsViewModel, OverBudgetRequestForTripsRequest } from '../models/patient-trip.model';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  private _endpoint = '/visit';

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Reject MUV quote
   * @param patientTripId
   * @param rideId
   */
  rejectMuvQuote(patientTripId: string, rideId: string) {
    return this.http.put(environment.apiUrl + this._endpoint + '/trip/' + patientTripId + '/muv-ride/' + rideId + '/reject-quote', {});
  }

  /**
   * Accept MUV quote
   * @param patientTripId
   * @param rideId
   */
  acceptMuvQuote(patientTripId: string, rideId: string) {
    return this.http.put(environment.apiUrl + this._endpoint + '/trip/' + patientTripId + '/muv-ride/' + rideId + '/accept-quote', {});
  }

  /**
   * Cancel an existing MUV ride
   * @param patientTripId
   * @param rideId
   */
  cancelMuvRide(patientTripId: string, rideId: string) {
    return this.http.delete(environment.apiUrl + this._endpoint + '/trip/' + patientTripId + '/muv-ride/' + rideId);
  }

  /**
   * Create a new MUV ride
   * @param dto
   */
  createMuvRide(patientTripId: string, dto: CreateMuvRide)
  {
    // If a pickup airline is set, remove the pickup address
    if (dto.pickupAirlineId !== null && dto.pickupAirlineId !== '') {
      dto.pickupLocation = null;
    }

    // If the dropoff airline is set, remove the dropoff address
    if (dto.dropoffAirlineId !== null && dto.dropoffAirlineId !== '') {
      dto.dropoffLocation = null;
    }

    return this.http.post(environment.apiUrl + this._endpoint + '/trip/' + patientTripId + '/muv-ride', dto);
  }

  /**
   * Gets a list of visits associated with a visit (same patient and trial
   * @param visitId
   */
  associatedVisits(visitId: string) {
    return this.http.get<{id: string, title: string}[]>(environment.apiUrl + this._endpoint + '/' + visitId + '/associated-visits');
  }

  /**
   * Duplicates a visit
   * @param visitId
   * @param targetVisitId
   * @param targetVisitTitle
   */
  duplicateVisit(visitId: string, targetVisitId: string, targetVisitTitle: string) {
    return this.http.put(environment.apiUrl + this._endpoint + '/' + visitId + '/duplicate', {
      targetVisitId: targetVisitId,
      targetVisitTitle: targetVisitTitle
    });
  }

  /**
   * Clears the unsent notification flag for a visit
   * @param visitId
   */
  clearNotification(visitId: string) {
    return this.http.put(environment.apiUrl + this._endpoint + '/' + visitId + '/notification/clear', {});
  }

  /**
   * Triggers a visit added or visit updated notification to a patient
   * @param visitId
   */
  sendNotification(visitId: string) {
    return this.http.put(environment.apiUrl + this._endpoint + '/' + visitId + '/notification/send', {});
  }

  /**
   * Retrieve a list of trips for a visit
   * @param visitId
   */
  trips(visitId: string) {
    return this.http.get<PatientTrip[]>(environment.apiUrl + this._endpoint + '/' + visitId + '/trip');
  }

  exportVisits(criteria: VisitSearchCriteria) {
    const body = {
      showAllOpenVisits: criteria.showAllOpenVisits,
      startDate: criteria.startDate,
      endDate: criteria.endDate,
      bookingStatus: criteria.bookingStatus,
      designatedContactId: criteria.designatedContact,
      patientId: criteria.patientId,
      trialId: criteria.trialId
    };

    return this.http.post(environment.apiUrl + this._endpoint + '/export', body);
  }

  /**
   * Gets a paginated list of visits
   * @param criteria
   */
  visits(criteria: VisitSearchCriteria) {
    let params = new HttpParams();
    params = params.set('page', criteria.page.toString());
    params = params.set('myTasks', criteria.myTasks);
    params = params.set('showAllOpenVisits', criteria.showAllOpenVisits);
    if (criteria.startDate) {
      // Ensure the start date starts at midnight
      criteria.startDate.setHours(0, 0, 0);
      params = params.set('startDate', criteria.startDate.toISOString());
    }
    if (criteria.endDate) {
      // Ensure the end date ends at 11:59:59
      criteria.endDate.setHours(23, 59, 59);
      params = params.set('endDate', criteria.endDate.toISOString());
    }
    if (criteria.bookingStatus) {
      params = params.set('bookingStatus', criteria.bookingStatus.toString());
    }
    if (criteria.designatedContact) {
      params = params.set('designatedContactId', criteria.designatedContact);
    }
    if (criteria.searchTerm) {
      params = params.set('searchTerm', criteria.searchTerm);
    }
    if (criteria.patientId) {
      params = params.set('patientId', criteria.patientId);
    }
    if (criteria.trialId) {
      params = params.set('trialId', criteria.trialId);
    }
    if (criteria.pageSize) {
      params = params.set('pageSize', criteria.pageSize.toString());
    }

    return this.http.get(environment.apiUrl + this._endpoint, {params: params}).pipe(map(rsp => {
      let results = new VisitSearch().resultsFromResponse(rsp);
      results.loaded = true;

      return results;
    }));
  }

  getOverBudgetRequestDetailsForTrips(tripIds: string[]): Observable<OverBudgetRequestDetailsForTripsViewModel> {
    return this.http.get<OverBudgetRequestDetailsForTripsViewModel>(`${environment.apiUrl}${this._endpoint}/over-budget-request-details?tripIds=${tripIds.join('&tripIds=')}`);
  }

  createOverBudgetRequestForTrips(request: OverBudgetRequestForTripsRequest[]): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}${this._endpoint}/over-budget-request`, request);
  }
}
