<div class="panel panel-expand">
  <div class="panel-icon-text">
    <span class="panel-icon">
      <i class="icon {{ iconClass }}" aria-hidden="true"></i>
    </span>

    <h2>{{ chartTitle }}</h2>
  </div>

  <div class="row" *ngIf="hasData">
    <div class="col-12 col-lg-4">
      <div class="chart-key-wrap">
        <ul class="chart-key align-middle">
          <li *ngFor="let option of data"><i class="chart-key-dot" [ngStyle]="{ 'backgroundColor': option.colour }" aria-hidden="true"></i> {{ option.name }}</li>
        </ul>
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <ngx-charts-pie-chart [view]="view"
                            [customColors]="customColours"
                            [results]="data"
                            [gradient]="gradient"
                            [legend]="showLegend"
                            [labels]="showLabels"
                            [doughnut]="isDoughnut">
        <ng-template #tooltipTemplate let-model="model">
          <strong>{{ model.label }}</strong><br>
          {{ model.value }}%
        </ng-template>
      </ngx-charts-pie-chart>
    </div>

    <div class="col-12 col-lg-4">
      <div class="chart-key-wrap">
        <ul class="chart-key-alt align-middle">
          <li *ngFor="let option of data"><span [ngStyle]="{ 'backgroundColor': option.colour}">{{ option.label }}</span></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="no-data" *ngIf="!hasData">
    <p>No data to report</p>
  </div>
</div>
