<app-modal-v2 #modal class="view-history-modal">
  <div class="modal-header">
    <h2 class="modal-title">History</h2>
  </div>
  <div class="modal-body">
    <div class="history-item" *ngFor="let item of historyItems">
      <div class="item-details">
        <label>{{item.type}}</label>
      </div>
      <table [class.mb-4]="!item.notes">
        <thead>
          <tr>
            <th>Visit Title</th>
            <th>Budget Cost</th>
            <th>Actual Cost</th>
            <th *ngIf="item.status !== OverBudgetRequestHistoryItemStatus.Rejected">Amount Paid</th>
            <th *ngIf="item.status === OverBudgetRequestHistoryItemStatus.Rejected">Amount Rejected</th>
            <th>Date</th>
            <th><span *ngIf="item.status === OverBudgetRequestHistoryItemStatus.Booked || item.status === OverBudgetRequestHistoryItemStatus.Blank">Booked </span>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{item.visitTitle}}</td>
            <td>{{item.budgetCost}} <ng-container *ngIf="item.budgetCost">{{item.budgetCurrency}}</ng-container></td>
            <td>{{item.actualCost}} {{item.itemCurrency}}</td>
            <td *ngIf="item.status !== OverBudgetRequestHistoryItemStatus.Rejected">{{item.amountPaid}} {{item.itemCurrency}}</td>
            <td *ngIf="item.status === OverBudgetRequestHistoryItemStatus.Rejected">{{item.amountRejected}} {{item.itemCurrency}}</td>
            <td>{{item.closedAt | date: 'dd MMM yy'}}</td>
            <td><span class="item-badge"
                [ngClass]="{'approved': item.status === OverBudgetRequestHistoryItemStatus.Approved|| item.status === OverBudgetRequestHistoryItemStatus.Booked, 
                          'rejected': item.status === OverBudgetRequestHistoryItemStatus.Rejected, 
                          'pending': item.status === OverBudgetRequestHistoryItemStatus.Pending || item.status === OverBudgetRequestHistoryItemStatus.Blank}">
                {{item.status | enumToText: OverBudgetRequestHistoryItemStatus}}</span></td>
          </tr>
        </tbody>
      </table>
      <div class="item-notes" [ngStyle]="{ 'border-bottom': item.notes ? '1px solid #f5f6fa' : 'none' }">
        <p *ngIf="item.notes"><label>Notes:</label>{{item.notes}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</app-modal-v2>