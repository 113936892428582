<app-modal #modal>
  <div class="modal-header">
    <h2 class="modal-title">Delete patient</h2>
  </div>
  <div class="modal-body" *ngIf="!activeTrialCodes.length">
    <p>
      Are you want to delete this patient?
    </p>
  </div>
  <div class="modal-body" *ngIf="activeTrialCodes.length">
    <p>This patient is currently active on the following trials:</p>
    <ul style="font-size: 11px;">
      <li *ngFor="let code of activeTrialCodes">{{code}}</li>
    </ul>
    <p>Please select the reason for deleting this patient:</p>
    <form [formGroup]="deletePatientForm">
      <div class="form-group">
        <label for="closingState">Reason</label>
        <app-dropdown-input #closingStateSelect [parentForm]="deletePatientForm" id="closingState" name="closingState"
                            [options]="closingStateOptions" placeholder="Select..."></app-dropdown-input>
      </div>
      <div class="form-group">
        <label for="reason">Reason Text</label>
        <textarea class="form-control" formControlName="reason" style="width: 100% !important;" name="reason"
                  id="reason"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">

      <div class="col-6">
        <a (click)="hideModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button [disabled]="!deletePatientForm.valid && activeTrialCodes.length" (click)="onConfirmDeletePatient()" class="btn btn-primary">
          <span *ngIf="isDeleteFormProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!isDeleteFormProcessing">Continue</span>
        </button>
      </div>


    </div>
  </div>
</app-modal>
