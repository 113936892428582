<div class="dashboard" (mousemove)="onMouseEnterForm()">
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="filter-form">
        <div class="trial-cultures">
          <form [formGroup]="filterForm" (submit)="onFormSubmit()"> <!-- (mousemove)="onMouseEnterForm()"> -->

            <div class="trial-filter-container">
              <div class="trial-dropdown">
                <app-dropdown-input #studySelect [options]="trialList" placeholder="Select trial..."
                                    [parentForm]="filterForm" name="trialId"></app-dropdown-input>
              </div>

              <div class="reset-trial">
                <a (click)="onViewAll()" class="btn btn-primary btn-outline" [ngClass]="{'disabled': !showViewAllBtn}">View all
                  trials</a>
              </div>
            </div>

            <div class="form-group filters">
              <table aria-hidden="true">
                <tr>
                  <td>
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="allTime" formControlName="allTime" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="allTime">All Time</label>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="lastMonth" formControlName="lastMonth" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="lastMonth">Last Month</label>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="last30Days" formControlName="last30Days" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="last30Days">Last 30 Days</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group" id="from-date">
                      <input type="text" #fromDateInput class="date" data-toggle="datepicker" formControlName="from">
                    </div>
                  </td>
                  <td>
                    <div class="form-group" id="to-date">
                      <input type="text" #toDateInput class="date" data-toggle="datepicker" formControlName="to">
                    </div>
                  </td>
                  <td>
                    <div class="col-6">
                      <button id="submit" type="submit" class="btn btn-primary" [disabled]="!filterForm.valid">
                        <span *ngIf="isFormProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                        <span *ngIf="!isFormProcessing">Filter</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'refreshing': !reportLoaded}">
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-report-panel iconClass="icon-user" label="Patients Enrolled"
          [value]="report.patientsEnrolled"></app-report-panel>
      </div>

      <div class="col-12 col-lg-3">
        <app-report-panel iconClass="icon-icon-visits" label="Visits Scheduled"
          [value]="report.visitsScheduled"></app-report-panel>
      </div>

      <div class="col-12 col-lg-3">
        <app-report-panel iconClass="icon-icon-visits" [altTheme]="true" label="Visits Completed"
          [value]="report.visitsCompleted"></app-report-panel>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-3">
        <app-report-panel iconClass="icon-icon-reimbursement" label="Reimbursements Requested"
          [value]="report.expenseRequests"></app-report-panel>

        <app-report-panel iconClass="icon-icon-reimbursement" label="Reimbursements Processed"
          [value]="report.expenseRequestsProcessed"></app-report-panel>
      </div>

      <div class="col-12 col-lg-9">
        <app-donut-chart *ngIf="reportLoaded" [data]="chartData" [hasData]="hasTravelSplit" iconClass="icon-cabinet"
          chartTitle="% Split for type of travel"></app-donut-chart>
      </div>
    </div>
  </div>
</div>
