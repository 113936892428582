import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { Router } from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { AuthStatus } from "../../core/auth-status.enum";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  userLoggedIn = false;
  supportedBrowsers: string[];

  constructor(public authService: AuthService, private deviceService: DeviceDetectorService, private router: Router) {
  }

  ngOnInit() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.supportedBrowsers = environment.environment === "DEVELOPMENT" ?
      ['Chrome', 'Safari', 'MS-Edge-Chromium', 'Firefox'] : ['Chrome', 'Safari', 'MS-Edge-Chromium'];


    if (!this.browserIsSupported(deviceInfo.browser)) {
      void this.router.navigate(['/browser-not-supported']);
    }

    this.authService.authStatus.subscribe(status => {
      this.userLoggedIn = status === AuthStatus.Authenticated
    });
  }

  ngAfterViewInit(): void {
    // Remove the full width classname from the main container
    // The visit component adds it to the main container.
    const mainContainer = document.querySelector('.main-container');
    mainContainer.classList.remove('full-width');
  }

  browserIsSupported(browser: string) {
    for (let b of this.supportedBrowsers) {
      if (browser === b) {
        return true;
      }
    }

    return false;
  }

}
