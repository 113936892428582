<div class="input-file-container">
  <div class="input-file" [class.has-file]="fileSelected">
    <span class="placeholder">No file selected...</span>
    <span class="label">{{ fileNameLabel }}</span>

    <button (click)="fileInput.click()" class="btn btn-primary btn-browse">Browse</button>
    <button (click)="onRemove()" class="btn btn-primary btn-remove">Remove</button>
  </div>

  <p class="info" *ngIf="info !== null && info !== ''">{{ info }}</p>

  <input #fileInput type="file" (change)="onFileSelect(fileInput.files)" [accept]="accepts">
</div>
