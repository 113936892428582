import { AdminUser } from './../../../core/models/admin-user.model';
import { Permissions } from './../../../core/constants/permissions';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { AdminService } from '../../../core/services/admin.service';
import { LogHelper } from '../../../core/helpers/log.helper';
import { AuthService } from '../../../core/services/auth.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { AdminUserList } from '../../../core/models/admin-user-list.model';
import { TemplateService } from '../../../core/services/template.service';
import { RoleNames } from 'app/core/constants/role-names';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { environment } from 'environments/environment';
import { SettingsService } from 'app/core/services/settings.service';
import { CurrentAdmin } from 'app/core/models/current-admin.model';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('deleteUserModel') deleteUserModal: ModalComponent;

  results = new AdminUserList();
  deleteAdminId = '';
  userIsBeingDeleted = false;
  Permissions = Permissions;
  searchForm: UntypedFormGroup;
  dataLoaded: boolean = false;
  currentAdmin: CurrentAdmin;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  constructor(private readonly adminService: AdminService, public authService: AuthService,
    private readonly alertService: AlertService, private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplateService, private settingsService: SettingsService) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl('')
    })

    // Load the page number
    this.activatedRoute.params.subscribe(params => {
      this.adminService.currentPageNumber = params.page || 1;

      // Only load the admin users once we've got a page number
      this.loadAdminUsers(this.adminService.currentPageNumber);
    });

    this.settingsService.retrieveCurrentAdmin().subscribe({
      next: currentAdmin => {
        this.currentAdmin = currentAdmin;
      }
    });
  }
  loadAdminUsers(pageNumber: number, keywords: string = null) {
    this.dataLoaded = false;
    this.adminService.retrieveAdminUsers(pageNumber, 25, null, keywords).subscribe({
      next: adminUserList => {
        this.results = adminUserList;
        this.dataLoaded = true;
      }, error: error => {
        this.dataLoaded = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem, please try again!');
      }
    });
  }

  onSearchInputChanged(keywords: string) {
    this.searchForm.patchValue({ keywords: keywords });
    this.loadAdminUsers(1, keywords)
  }

  onChangePage(pageNumber: number) {
    // Store the selected page number in the admin service so that it is remembered
    this.adminService.currentPageNumber = pageNumber;

    // Reload results
    this.loadAdminUsers(pageNumber);
  }

  onDeleteAdmin(id: string) {
    this.deleteAdminId = id;
    this.deleteUserModal.show();
  }

  onDeleteConfirmed() {
    this.userIsBeingDeleted = true;
    this.adminService.deactivateAdminUser(this.deleteAdminId).subscribe({
      next: () => {
        this.loadAdminUsers(this.adminService.currentPageNumber);
        this.alertService.showSuccessAlert('User successfully deactivated.');
        this.deleteUserModal.hide();
        this.userIsBeingDeleted = false;
      },
      error: error => {
        this.alertService.showWarningAlert(error.error
          ? error.error.title
          : 'There was a problem deactivating the administrator');
        this.deleteUserModal.hide();
        this.userIsBeingDeleted = false;
      }
    });
  }

  deactivateAvailable(admin: AdminUser): boolean {
    let adminIsSystemAdmin = admin.roles.includes(RoleNames.SystemAdministrator);

    return adminIsSystemAdmin ?
      this.authService.hasRole(RoleNames.SystemAdministrator) :
      this.authService.hasPermission(Permissions.UserDeactivate);
  }

  editButtonVisible(adminId: string) {
    return this.authService.hasPermission(Permissions.UserEdit) ||
      ((environment.environment === "DEVELOPMENT" || environment.environment === "STAGING") && adminId === this.currentAdmin.id);
  }
}
