import {Component, Input, Renderer2} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {
  visible = false;
  @Input() customClassName?= '';
  @Input() scrollable = false;

  @Input() deleteIndex = -1;
  @Input() deleteId: string;

  constructor(private _renderer: Renderer2) {
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }

  showUsingDeleteIndex(index: number) {
    this.deleteIndex = index;
    this.show();
  }

  showUsingDeleteId(id: string) {
    this.deleteId = id;
    this.show();
  }

  resetDeleteIndexAndId() {
    this.deleteIndex = -1;
    this.deleteId = null;
  }
}
