<div class="trials" *ngIf="policy">

  <!-- Start: View head -->
  <div class="view-head">
    <div class="row">

      <div class="col-12 text-center">
        <h2 class="heading-small with-padding">{{policy.isDefault !== null && policy.isDefault ? 'Edit Default Policy' :
          'Edit Policy'}}</h2>
      </div>

    </div>
  </div>
  <!-- End: View head -->

  <!-- Start: View body -->
  <div class="view-body">

    <div class="row">
      <div class="policy-container">

        <div class="std-tabbed-content margin-after-tabs">
          <div class="tabs-wrap">
            <app-mobile-tabs #mobileTabs (onSelected)="onTabClick($event)">
              <ul>
                <li><a (click)="mobileTabs.onSelectItem('details', $event)" class="selected">Policy Details</a></li>
                <li *ngIf="authService.hasPermission(Permissions.TrialAutoStipend)"><a
                    (click)="mobileTabs.onSelectItem('auto-payments', $event)" class="selected">Key visit
                    auto-payments</a>
                </li>
                <li><a (click)="mobileTabs.onSelectItem('rules', $event)" class="selected">Expense Rules</a></li>
                <li><a (click)="mobileTabs.onSelectItem('mileage', $event)" class="selected">Mileage Rates</a></li>
              </ul>
            </app-mobile-tabs>

            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a (click)="onTabClick('details')" [ngClass]="{'active': selectedTab == 'details'}"
                  class="nav-link">Policy Details</a>
              </li>
              <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialAutoStipend)">
                <a (click)="onTabClick('auto-payments')" [ngClass]="{'active': selectedTab == 'auto-payments'}"
                  class="nav-link">Key visit auto-payments</a>
              </li>
              <li class="nav-item">
                <a (click)="onTabClick('rules')" [ngClass]="{'active': selectedTab == 'rules'}" class="nav-link">
                  Expense Rules
                </a>
              </li>
              <li class="nav-item">
                <a (click)="onTabClick('mileage')" [ngClass]="{'active': selectedTab == 'mileage'}" class="nav-link">
                  Mileage Rates
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="content-panel">

          <div [ngClass]="{'visibility-hidden': selectedTab !== 'details'}" class="tab-content">
            <div class="trial-cultures">
              <form [formGroup]="languageSelectForm" class="language-form">
                <div class="language-select">
                  <app-dropdown-input #languageSelect [options]="culturesInUse"
                    [placeholder]="culturesInUse[0] !== undefined ? culturesInUse[0].text : ''"
                    [parentForm]="languageSelectForm" name="culture"></app-dropdown-input>
                </div>
                <button class="btn-basic" (click)="addLanguageModal.show()">+ Add Language</button>
              </form>
            </div>
            <!-- Start: Default form (English) -->
            <form class="form-wrapper" [formGroup]="defaultForm"
              [ngClass]="{'visibility-hidden': currentCulture !== 'en'}">
              <table class="simple-form">
                <tr [ngClass]="{'visibility-hidden': policy.isDefault}">
                  <th style="width: 140px;"><label>Country</label></th>
                  <td>
                    <div class="form-group">
                      <app-autosuggest-dropdown-input #countrySelect [required]="true" [options]="countryOptions"
                        placeholder="Select Country..." [parentForm]="defaultForm"
                        controlName="country"></app-autosuggest-dropdown-input>
                    </div>
                  </td>
                </tr>
                <tr
                  [ngStyle]="{'display': defaultForm.get('country').value !== '' && siteOptions.length > 0 ? 'table-row' : 'none'}"
                  [ngClass]="{'visibility-hidden': policy.isDefault}">
                  <th><label>Sites</label></th>
                  <td>
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>All Sites</mat-label>
                      <mat-select formControlName="sites" [formGroup]="defaultForm" multiple>
                        <mat-option *ngFor="let site of siteOptions" [value]="site.value">{{site.text}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <th style="width: 140px;"></th>
                  <td>
                    <div class="checkbox-wrap">
                      <div class="form-group">
                        <label class="checkbox">
                          <input type="checkbox" id="allowTravelRequests" formControlName="allowTravelRequests"
                            value="true">
                          <span></span>
                        </label>
                        <label class="checkbox-label" for="allowTravelRequests">Patients Can Request Travel</label>
                      </div>
                    </div>
                    <div class="checkbox-wrap last">
                      <div class="form-group">
                        <label class="checkbox">
                          <input type="checkbox" id="allowExpenseRequests" formControlName="allowExpenseRequests"
                            value="true">
                          <span></span>
                        </label>
                        <label class="checkbox-label" for="allowExpenseRequests">Patients Can Claim Expenses</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top; padding-top: 21px;"><label for="expense-policy">Expense Policy</label>
                  </th>
                  <td>
                    <div class="form-group">
                      <textarea class="form-control" id="expense-policy" formControlName="expensePolicy"
                        placeholder="Add details of the expense policy..."></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style="width: 16%;"><label>In-App Expense Categories</label></th>
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select formControlName="categories" [formGroup]="defaultForm" multiple>
                          <mat-option *ngFor="let category of expenseCategories"
                            [value]="category.id">{{category.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th><label>CMS-only Expense Categories</label></th>
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select formControlName="cmsOnlyCategories" [formGroup]="defaultForm" multiple>
                          <mat-option *ngFor="let category of cmsOnlyCategories"
                            [value]="category.id">{{category.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th><label>Policy currency</label></th>
                  <td>
                    <div class="form-group" style="max-width: 250px">
                      <app-dropdown-input #policyCurrencySelect id="policyCurrency" [options]="currencies"
                        [parentForm]="defaultForm" name="policyCurrency" placeholder="Select currency">
                      </app-dropdown-input>
                    </div>
                  </td>
                </tr>


                <tr>
                  <th></th>
                  <td>
                    <table aria-hidden="true" class="category-budgets">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Patient Budget (one way)</th>
                          <th>Additional Caregiver Budget (one way)</th>
                          <th>Return trip allowed ?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="carHireIncluded" formControlName="carHireIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="carHireIncluded">Car Hire</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="carHirePatientBudget" formControlName="carHirePatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="carHireCaregiverBudget" formControlName="carHireCaregiverBudget">
                            </div>
                          </td>
                          <td></td>
                        </tr>
                        <tr *ngIf="defaultForm.get('carHireIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="carHireAdditionalInformation"
                                formControlName="carHireAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="accommodationIncluded"
                                  formControlName="accommodationIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="accommodationIncluded">Accommodation</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="accommodationPatientBudget"
                                formControlName="accommodationPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="accommodationCaregiverBudget"
                                formControlName="accommodationCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <app-dropdown-input #accommodationBudgetTypeSelect id="accommodationBudgetType"
                                [options]="accommodationBudgetTypes" [parentForm]="defaultForm"
                                name="accommodationBudgetType" placeholder="Per Visit/Night...">
                              </app-dropdown-input>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('accommodationIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="accommodationAdditionalInformation"
                                formControlName="accommodationAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airTravelIncluded" formControlName="airTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airTravelIncluded">Air Travel</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airTravelPatientBudget" formControlName="airTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airTravelCaregiverBudget"
                                formControlName="airTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airTravelReturnTripIncluded"
                                  formControlName="airTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('airTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="airTravelAdditionalInformation"
                                formControlName="airTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="railTravelIncluded" formControlName="railTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="railTravelIncluded">Rail Travel</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="railTravelPatientBudget"
                                formControlName="railTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="railTravelCaregiverBudget"
                                formControlName="railTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="railTravelReturnTripIncluded"
                                  formControlName="railTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="railTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('railTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="railTravelAdditionalInformation"
                                formControlName="railTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr class="has-heading">
                          <th>Ground Travel</th>
                          <th>Patient Budget (one way)</th>
                          <th>Additional Caregiver Budget (one way)</th>
                          <th>Return trip allowed ?</th>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="homeToAirportTravelIncluded"
                                  formControlName="homeToAirportTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="homeToAirportTravelIncluded">Home-to-Airport</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="homeToAirportTravelPatientBudget"
                                formControlName="homeToAirportTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="homeToAirportTravelCaregiverBudget"
                                formControlName="homeToAirportTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="homeToAirportTravelReturnTripIncluded"
                                  formControlName="homeToAirportTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="homeToAirportTravelReturnTripIncluded"
                                title="Return Trip"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('homeToAirportTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="homeToAirportTravelAdditionalInformation"
                                formControlName="homeToAirportTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airportToHotelTravelIncluded"
                                  formControlName="airportToHotelTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airportToHotelTravelIncluded">Airport-to-Hotel</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airportToHotelTravelPatientBudget"
                                formControlName="airportToHotelTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airportToHotelTravelCaregiverBudget"
                                formControlName="airportToHotelTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airportToHotelTravelReturnTripIncluded"
                                  formControlName="airportToHotelTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airportToHotelTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('airportToHotelTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="airportToHotelTravelAdditionalInformation"
                                formControlName="airportToHotelTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="hotelToSiteTravelIncluded"
                                  formControlName="hotelToSiteTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="hotelToSiteTravelIncluded">Hotel-to-Site</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="hotelToSiteTravelPatientBudget"
                                formControlName="hotelToSiteTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="hotelToSiteTravelCaregiverBudget"
                                formControlName="hotelToSiteTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="hotelToSiteTravelReturnTripIncluded"
                                  formControlName="hotelToSiteTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="hotelToSiteTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('hotelToSiteTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="hotelToSiteTravelAdditionalInformation"
                                formControlName="hotelToSiteTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="homeToSiteTravelIncluded"
                                  formControlName="homeToSiteTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="homeToSiteTravelIncluded">Home-to-Site</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="homeToSiteTravelPatientBudget"
                                formControlName="homeToSiteTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="homeToSiteTravelCaregiverBudget"
                                formControlName="homeToSiteTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="homeToSiteTravelReturnTripIncluded"
                                  formControlName="homeToSiteTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="homeToSiteTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('homeToSiteTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="homeToSiteTravelAdditionalInformation"
                                formControlName="homeToSiteTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airportToSiteTravelIncluded"
                                  formControlName="airportToSiteTravelIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airportToSiteTravelIncluded">Airport-to-Site</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airportToSiteTravelPatientBudget"
                                formControlName="airportToSiteTravelPatientBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input type="number" id="airportToSiteTravelCaregiverBudget"
                                formControlName="airportToSiteTravelCaregiverBudget">
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <label class="checkbox">
                                <input type="checkbox" id="airportToSiteTravelReturnTripIncluded"
                                  formControlName="airportToSiteTravelReturnTripIncluded">
                                <span></span>
                              </label>
                              <label class="checkbox-label" for="airportToSiteTravelReturnTripIncluded"></label>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="defaultForm.get('airportToSiteTravelIncluded').value">
                          <td colspan="4">
                            <div class="form-group over-budget-additional-information">
                              <label>Additional information for over budget request</label>
                              <textarea class="form-control" id="airportToSiteTravelAdditionalInformation"
                                formControlName="airportToSiteTravelAdditionalInformation"></textarea>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <th style="vertical-align: top; padding-top: 21px;"><label for="internalPolicyNotes">Internal Policy
                      Notes</label></th>
                  <td>
                    <div class="form-group">
                      <textarea class="form-control" id="internalPolicyNotes"
                        formControlName="internalPolicyNotes"></textarea>
                    </div>
                  </td>
                </tr>

              </table>
            </form>
            <!-- End: Default form (English) -->

            <!-- Start: Translations form -->
            <div *ngFor="let form of forms">
              <form [formGroup]="form" [ngClass]="{'visibility-hidden': form.get('culture').value !== currentCulture}"
                *ngIf="form.get('culture').value !== 'en'">
                <table class="simple-form">
                  <tr>
                    <th style="width: 140px; vertical-align: top; padding-top: 21px;"><label>Expense Policy</label></th>
                    <td>
                      <div class="form-group">
                        <textarea class="form-control" formControlName="expensePolicy"
                          placeholder="Add details of the expense policy..."></textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="text-right">
                      <a (click)="removeLanguageModal.show()" class="btn btn-outline">Remove Language</a>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
            <!-- End: Translations form -->
            <!-- Start: Buttons -->
            <table class="simple-form">
              <tr>
                <th style="width: 140px;"></th>
                <td>
                  <div class="btn-wrap">
                    <div class="row">
                      <div class="col-6">
                        <a routerLink="/trial/{{trialId}}" fragment="policies" class="btn btn-secondary">Cancel</a>
                      </div>
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary" (click)="onFormSubmit()"
                          [disabled]="!formsValid()">
                          <span *ngIf="formIsProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                          <span *ngIf="!formIsProcessing">Save Policy</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <!-- End: Buttons -->
          </div>
          <div [ngClass]="{'visibility-hidden': selectedTab !== 'auto-payments'}" class="tab-content auto-payments">

            <form [formGroup]="visitAutoPaymentsForm">

              <div class="form-group" style="width: 210px">
                <label for="currency">Auto-payment type</label>
                <app-dropdown-input #autoPaymentTypeSelect id="autoPaymentType" [options]="autoPaymentTypes"
                  [parentForm]="visitAutoPaymentsForm" name="autoPaymentType"
                  placeholder="Select Auto Payment Type"></app-dropdown-input>
              </div>
              <div class="checkbox-wrap">
                <div class="form-group checkbox-input-field">
                  <label class="checkbox input-checkbox">
                    <input type="checkbox" id="assumeVisitAttendance" formControlName="assumeVisitAttendance"
                      value="true">
                    <span></span>
                  </label>
                  <label class="checkbox-label" for="assumeVisitAttendance">Assume visit attendance after <input
                      formControlName="assumeVisitAttendanceAfterDays" class="days-input" type="number"> days</label>
                </div>
              </div>

              <div class="form-group" style="width: 210px">
                <label for="currency">Auto-payment currency</label>
                <app-dropdown-input #currencySelect id="autoPaymentCurrency" [options]="currencies"
                  [parentForm]="visitAutoPaymentsForm" name="autoPaymentCurrency" placeholder="Select currency">
                </app-dropdown-input>
              </div>

              <table *ngIf="visits.length" mat-table [dataSource]="visits" [fixedLayout]="true" class="visits-table">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Visit Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>

                <ng-container matColumnDef="keyVisit">
                  <th mat-header-cell *matHeaderCellDef> Key Visit </th>
                  <td mat-cell *matCellDef="let element">
                    <label class="checkbox">
                      <input #keyVisit type="checkbox" id="keyVisit" (change)="onKeyVisitChecked(element, $event)"
                        [checked]="element.keyVisit">
                      <span></span>
                    </label>
                  </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef> Amount </th>
                  <td mat-cell *matCellDef="let element">
                    <input [disabled]="!element.keyVisit" #amount class="amount-input" type="number"
                      (blur)="onAmountSet(element, amount.value)" [value]="element.amount">
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedVisitColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedVisitColumns;"></tr>
              </table>

            </form>

            <div class="auto-payments-footer">
              <a routerLink="/trial/{{trialId}}" fragment="policies" class="btn btn-secondary">Cancel</a>
              <button class="btn btn-primary" type="submit" [disabled]="visitAutoPaymentsForm.invalid"
                (click)="save()">Save</button>
            </div>

          </div>

          <app-trial-policy-expense-rules [policyId]="policyId" [trialId]="trialId" *ngIf="selectedTab === 'rules'">
          </app-trial-policy-expense-rules>
          <app-trial-policy-mileage-rates [policyId]="policyId" [trialId]="trialId"
            *ngIf="selectedTab === 'mileage'"></app-trial-policy-mileage-rates>

        </div>

      </div>
    </div>

  </div>
  <!-- End: View body -->

</div>

<!-- Start: Add language modal -->
<app-modal #addLanguageModal customClassName="add-language">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <ul>
      <li *ngFor="let culture of allCultures" [ngClass]="{'disabled': isCultureInUse(culture.value)}"><a
          (click)="onAddLanguage(culture)">{{ culture.text }}</a></li>
    </ul>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <a (click)="addLanguageModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export change log modal -->

<!-- Start: Remove language modal -->
<app-modal #removeLanguageModal>
  <div class="modal-header">
    <h2 class="modal-title">Remove Language</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to remove this language from the policy?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="removeLanguageModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" (click)="onConfirmedRemoveLanguage()">
          Yes, remove
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Remove language modal -->
