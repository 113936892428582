import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SelectOption } from "../../../../core/models/select-option.model";
import { UntypedFormGroup } from "@angular/forms";
import { Currencies } from "../../../../core/constants/currency";

@Component({
  selector: '[table-cell-currency]',
  templateUrl: './table-cell-currency.component.html',
  styleUrls: ['./table-cell-currency.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableCellCurrencyComponent {
  @ViewChild('filterInput') filterInput: ElementRef;

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('minWidth') minWidth: string;
  @Input('maxWidth') maxWidth: string;
  @Input('editable') editable: boolean;

  @Output('valueChanged') valueChanged = new EventEmitter();

  currencyOptions: SelectOption[] = [];
  filteredCurrencyOptions: SelectOption[] = [];

  constructor() {
    this.currencyOptions = Currencies.all().map((x) => ({ value: x.cc, text: x.cc }));
    this.filteredCurrencyOptions = this.currencyOptions;
  }

  onValueChanged(event: any) {
    this.valueChanged.emit(event);
  }

  onFilterInputChanged(event: any) {
    const filter = event.target.value.toLowerCase();
    if (filter === '')
      this.filteredCurrencyOptions = this.currencyOptions;
    else
      this.filteredCurrencyOptions = this.currencyOptions.filter((x) => x.text.toLowerCase().includes(filter));
  }

}
