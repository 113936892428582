import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpenseCheckSubTotal } from 'app/core/models/expense-check.model';
import { ExpenseCategorySelected } from 'app/core/models/expense.model';

@Component({
  selector: 'app-approved-expenses-container',
  templateUrl: './approved-expenses-container.component.html',
  styleUrl: './approved-expenses-container.component.scss'
})
export class ApprovedExpensesContainerComponent {
  @Input() subTotals: ExpenseCheckSubTotal[];
  @Input() showCheckbox: boolean = false;

  @Output() toggleCategory: EventEmitter<ExpenseCategorySelected> = new EventEmitter<ExpenseCategorySelected>();

  onCategorySelected(event: any, category: string) {
    this.toggleCategory.emit({ checked: event.target.checked, category: category });
  }
}
