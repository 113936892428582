<div class="trial-container" [formGroup]="parentForm">

  <div class="row">
    <div class="col-12 col-lg-6">
      <label>Trial {{ index + 1 }}</label>
      <div class="form-group">
        <span [class.visibility-hidden]="!added" class="fake-field">{{ selectedTrialLabel }}</span>
        <div [class.visibility-hidden]="added" class="trial-autocomplete">
          <app-trial-autocomplete #trialAutocomplete controlName="trialCode" [showApiEnabledTrials]="false" placeholder="Select a public trial code..."
                                  [form]="parentForm" [liveTrials]="true" (valueChanged)="setFakeFieldValue($event)"></app-trial-autocomplete>
        </div>

        <p *ngIf="!parentForm.get('trialIdIsUnique').value && parentForm.get('trialId').value !== ''"
           class="validation-error" style="padding-left: 15px; padding-top: 3px;">This trial is
          already assigned to the patient, please select another trial.</p>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label>Patient Code</label>
        <input class="form-control" formControlName="patientCode" id="patientCode" maxlength="16" type="text" style="width: 100%;">
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label>Site</label>
        <span [class.visibility-hidden]="!added" class="fake-field">{{ selectedSiteLabel }}</span>
        <div [class.visibility-hidden]="added">
          <app-site-autocomplete #siteAutocomplete [appendCountryCode]="true" [truncateMaxChars]="20" [allowAllSites]="false" [disabled]="true" [form]="parentForm"
                                 [showApprovedOnly]="true" [showSiteNumbers]="true" controlName="siteId" (valueChanged)="setFakeSiteFieldValue($event)"
                                 placeholder="Select a site..."></app-site-autocomplete>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label>Status on Trial</label>
        <app-dropdown-input #stateOnTrialSelect [parentForm]="parentForm" name="stateOnTrial"
                            [options]="stateOnTrialOptions" placeholder="Select..."></app-dropdown-input>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-12 col-lg-6" [hidden]="!engineSizes.length">
      <div class="form-group">
        <label>Vehicle Engine Size</label>
        <app-dropdown-input #engineSizeSelect id="engineSize" [options]="engineSizes"
                            [parentForm]="parentForm" name="engineSize" placeholder="Select engine size"></app-dropdown-input>
      </div>
    </div>

    <div class="col-12" *ngIf="endingParticipationReasonVisible">
      <div class="form-group">
        <label>Reason for ending participation</label>
        <textarea style="width: 100% !important; min-height: 50px !important;" rows="2" class="form-control" formControlName="endingParticipationReason"
                  id="endingParticipationReason"></textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="options-toggle" (click)="onToggleOptions()" [class.visibility-hidden]="!added">
        <span>Options</span>
        <i class="far fa-angle-down" *ngIf="!optionsVisible"></i>
        <i class="far fa-angle-up" *ngIf="optionsVisible"></i>
      </div>
    </div>

    <div class="row" [class.visibility-hidden]="!optionsVisible">
      <div class="col-4">
        <div class="checkbox-wrap">
          <div class="form-group">
            <label class="checkbox">
              <input formControlName="bankFeesIncurred" id="bankFeesIncurred" type="checkbox">
              <span></span>
            </label>
            <label class="checkbox-label" for="bankFeesIncurred">Bank fees incurred</label>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="checkbox-wrap">
          <div class="form-group">
            <label class="checkbox">
              <input formControlName="approvalNoReceipts" id="approvalNoReceipts" type="checkbox">
              <span></span>
            </label>
            <label class="checkbox-label" for="approvalNoReceipts">Blanket approval for no receipts</label>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="checkbox-wrap">
          <div class="form-group">
            <label class="checkbox">
              <input formControlName="fixedFeePerVisit" id="fixedFeePerVisit" type="checkbox">
              <span></span>
            </label>
            <label class="checkbox-label" for="fixedFeePerVisit">Fixed fee per visit</label>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="checkbox-wrap">
          <div class="form-group">
            <label class="checkbox">
              <input formControlName="overspendTravel" id="overspendTravel" type="checkbox">
              <span></span>
            </label>
            <label class="checkbox-label" for="overspendTravel">Blanket approval for overspend on
              travel</label>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="checkbox-wrap">
          <div class="form-group">
            <label class="checkbox">
              <input formControlName="overspendMileage" id="overspendMileage" type="checkbox">
              <span></span>
            </label>
            <label class="checkbox-label" for="overspendMileage">Blanket approval for
              overspend on mileage</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="patientTrial === null">
    <div class="col-6">
      <div class="checkbox-wrap last" style="margin-left: -15px;">
        <div class="form-group">
          <label class="checkbox">
            <input formControlName="consent" id="consent" type="checkbox">
            <span></span>
          </label>
          <label class="checkbox-label" for="consent">I confirm that I have seen and verified patient consent</label>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="buttons">
        <button (click)="onCancel()" class="btn btn-secondary" type="button">Cancel</button>
        <button (click)="onTrialAdded()" [disabled]="!parentForm.valid" class="btn btn-primary" type="button">Add Trial
        </button>
      </div>
    </div>
  </div>

</div>
