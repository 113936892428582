import { Component, OnInit } from '@angular/core';
import {TemplateService} from "../../../core/services/template.service";

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent implements OnInit {

  constructor(private templateService: TemplateService) { }

  ngOnInit() {
    this.templateService.showHeader();
  }

}
