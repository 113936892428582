import { Permissions } from './../../../../core/constants/permissions';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PatientService } from "../../../../core/services/patient.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AuthService } from "../../../../core/services/auth.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientList, PatientListItem } from "../../../../core/models/patient-list.model";
import { Subscription } from "rxjs";
import { StringHelper } from 'app/core/helpers/string-helper';
import { PatientDeleteModalComponent } from "../../../../shared/patient-delete-modal/patient-delete-modal.component";

@Component({
  selector: 'app-patient-rejected',
  templateUrl: './patient-rejected.component.html',
  styleUrls: ['./patient-rejected.component.scss']
})

export class PatientRejectedComponent implements OnInit {
  @Output() patientSelectedForApproval = new EventEmitter<PatientListItem>();

  @ViewChild('deletePatientModal') deletePatientModal: PatientDeleteModalComponent;
  isDeleteFormProcessing = false;
  deleteForm: UntypedFormGroup;
  patientToDelete: PatientListItem;

  keywords: string;
  country: string;
  orderBy: number = 0;
  state = 2;
  results = new PatientList();
  hasResults = true;
  Permissions = Permissions;
  subscription: Subscription;
  activeTrialCodes: string[] = [];
  stringHelper = StringHelper;

  constructor(public authService: AuthService, private readonly patientService: PatientService, private readonly alertService: AlertService) { }

  ngOnInit() {
    this.deleteForm = new UntypedFormGroup({});

    // Initially load patients
    this.loadPatients(1, null, null);
  }

  updateFilter(keywords: string, country: string, orderBy: number = 0) {
    this.keywords = keywords === '' ? null : keywords;
    this.country = country === '' ? null : country;
    this.orderBy = orderBy;
    this.loadPatients(1, keywords, country, orderBy);
  }

  loadPatients(page: number, keywords: string, country: string, orderBy: number = 0) {
    // If page NULL, then it means we need to stay on the current page
    if (page == null) {
      page = this.results.currentPage;
    }

    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.patientService.retrievePatients(this.state, keywords, country, page, orderBy).subscribe({
      next: patientList => {
        this.results = patientList;

        if (keywords === null && patientList.results.length === 0) {
          this.hasResults = false;
        }

        // If the current page is more than the number of pages available, use reload using the last page
        if (page > patientList.totalPageCount && patientList.totalRecordCount > 0) {
          this.loadPatients(patientList.totalPageCount, keywords, country);
        }
      },
      error: () => {
        this.alertService.showWarningAlert('Unable to load rejected patients!');
      }
    });
  }

  /**
   * When the 'Approve' button is clicked for a patient, this method renders the approve patient modal
   * @param patient the patient being approved
   */
  onApprovePatient(patient: PatientListItem) {
    this.patientSelectedForApproval.emit(patient);
  }

  /**
   * When the delete user button is pressed, set the user to be deleted and show the delete user model to get
   * confirmation from the user that the account is to be deleted
   * @param patient
   */
  onSelectDeletePatient(patient: PatientListItem) {
    this.patientService.getActiveTrialCodesForPatient(patient.id).subscribe({
      next: response => {
        this.activeTrialCodes = response.map(r => r.code);
        this.patientToDelete = patient;
        this.deletePatientModal.show();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert("Failed to get active patient trials. Please try again.");
      }
    })
  }

  onDeletedPatient(){
    this.loadPatients(null, this.keywords, this.country);
  }

  truncateRejectedEmail(email: string): string {
    let parts = email.split('@');
    return StringHelper.truncateMiddle(parts[0], 40) + '@' + parts[1];
  }
}
