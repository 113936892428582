<div class="text-xs-center" *ngIf="totalPageCount > 1">
  <ul class="pagination justify-content-center">
    <li *ngIf="currentPage > 1" class="page-item"><a (click)="goToPage(1)" class="page-link">First</a></li>
    <li *ngIf="currentPage > 1" class="page-item"><a (click)="goToPage(currentPage - 1)" class="page-link">Previous</a>
    </li>
    <ng-container *ngFor="let p of pagesToShow">
      <li [class.active]="p === currentPage" class="page-item"><a (click)="goToPage(p)" class="page-link">{{p}}</a></li>
    </ng-container>
    <li *ngIf="currentPage < totalPageCount" class="page-item"><a (click)="goToPage(currentPage + 1)" class="page-link">Next</a>
    </li>
    <li *ngIf="currentPage < totalPageCount" class="page-item"><a (click)="goToPage(totalPageCount)" class="page-link">Last</a>
    </li>
  </ul>
</div>
