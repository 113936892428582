import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";
import { PatientDetail } from "../../core/models/patient-detail.model";
import { FormControl, FormGroup } from "@angular/forms";
import { Address } from "../input-address/address.model";
import { LogHelper } from "../../core/helpers/log.helper";
import { PatientService } from "../../core/services/patient.service";
import { AlertService } from "../alert/alert.service";
import { InputAddressComponent } from "../input-address/input-address.component";

@Component({
  selector: 'app-patient-update-address',
  templateUrl: './patient-update-address.component.html',
  styleUrl: './patient-update-address.component.scss'
})
export class PatientUpdateAddressComponent {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('homeAddress') homeAddress: InputAddressComponent;

  @Output('onAddressChanged') onAddressChanged = new EventEmitter<Address>();

  patientLoaded = false;
  patient = new PatientDetail();
  form: FormGroup;

  defaultTitle = 'Update Patient Address';
  defaultText = '';
  title = this.defaultTitle;
  text = this.defaultText;

  constructor(private _patientService: PatientService, private _alertService: AlertService) {
    this.form = new FormGroup({
      processing: new FormControl<boolean>(false),
      homeAddress: new FormControl<Address>(null),
    });
  }

  public openModal(patientId: string, title = null, text = null) {
    this.modal.show();

    this.title = title || this.defaultTitle;
    this.text = text || this.defaultText;

    this._patientService.retrievePatientDetail(patientId).subscribe({
      next: patient => {
        this.patient = patient;
        this.patientLoaded = true;

        this.form.patchValue({
          homeAddress: patient.homeAddress,
        });

        this.homeAddress.setAddress(patient.homeAddress);
      },
      error: error => {
        LogHelper.log(error);
        this._alertService.showWarningAlert('Unable to load patient information!');
      }
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.form.patchValue({ processing: true });

      this._patientService.updatePatientAddress(this.patient.id, this.form.get('homeAddress').value).subscribe({
        next: () => {
          this._alertService.showSuccessAlert('Patient Address Updated Successfully');
          this.onAddressChanged.emit(this.form.get('homeAddress').value);
          this.form.patchValue({ processing: false });
          this.modal.hide();
        },
        error: error => {
          LogHelper.log(error);
          this.form.patchValue({ processing: false });
          this._alertService.showWarningAlert(error.error ? error.error.title : 'Unable to update patient address!');
        }
      });
    }
  }
}
