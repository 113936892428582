import { DateHelper } from "../helpers/date-helper";
import { CaregiverTravelling } from "./expense-check.model";
import { ExpenseImage } from "./expense-image.model";
import { UpdateEntityAmountRequest, UpdateEntityCurrencyRequest } from "./patient.model";
import { OverBudgetRequestDetailsStatus } from "./project-management.model";

export interface ExpenseClaimIdsRequest {
  expenseClaimsIds: string[];
}

export interface SendCaxtonExpenseClaimsToFinanceRequest extends ExpenseClaimIdsRequest {
}

export interface PayByCaxtonBankRequest extends ExpenseClaimIdsRequest {
  requestId: string;
}

export interface PayByCaxtonCardRequest extends PayByCaxtonBankRequest { }

export interface NotOnCaxtonRetryRequest extends ExpenseClaimIdsRequest {
  findings: string;
}

export interface MoveToCaxtonFailedRequest extends ExpenseClaimIdsRequest { }

export interface SendToFinanceRequest extends ExpenseClaimIdsRequest {
  reason: string;
}

export interface SetCaxtonTransferIdRequest {
  expenseClaimIds: string[];
  transferId: number;
  findings: string;
  expenseClaimPaymentMethod: ExpenseClaimPaymentMethod;
}

export interface AddExpenseClaimNoteRequest {
  expenseClaimId: string;
  note: string;
}

export interface StartExpenseClaimInvestigationRequest {
  expenseClaimId: string;
  reasonForInvestigation: string;
  allClaims: boolean;
}

export interface CloseExpenseClaimInvestigationRequest {
  expenseClaimId: string;
  allClaims: boolean;
}

export enum ExpenseClaimPaidBy {
  Finance,
  Caxton,
  CaxtonCard
}

export interface ExpenseApprovalSuggestionResult {
  autoApprovalResult: AutoApprovalResult
}

export class ReasonForEdit {
  public id: string;
  public expenseId: string;
  public reason: string;
  public created: Date;

  public static fromObject(obj: any): ReasonForEdit {
    let reason = new ReasonForEdit();
    reason.id = obj.id;
    reason.expenseId = obj.expenseId;
    reason.reason = obj.reason;
    reason.created = DateHelper.dateUTCToLocal(obj.created);

    return reason;
  }
}

export class PatientPaymentMethod {
  public static Methods = {
    0: "Bank Transfer",
    1: "Caxton Card"
  }
}

export class ExpensePatientGroup {
  public currencyGroupExpenses: ExpenseCurrencyGroup[];
}

export class ExpenseCurrencyGroup {
  public patientId: string;
  public patientName: string;
  public patientTrialId: string;
  public patientCode: string;
  public patientAddress: string;
  public patientCountry: string;
  public totalExpense: number;
  public paymentDate: Date;
  public currency: string;
  public bankFeeCover: number;
  public paymentMethod: ExpenseClaimPaymentMethod;
  public displayBankFeeCover: boolean;
  public trialCode: string;
  public trialNickname: string;
  public trialOpportunityNumber: string;
  public trialId: string;
  public expenses: ExpenseClaimViewModel[]
}

export interface ApproveExpenseClaimResult {
  approved: boolean;
  state?: ExpenseClaimState;
  caxtonIncompatibleMessage: string;
}

export interface CaxtonIncompatibleData {
  caxtonIncompatibleReason: string;
  expenseClaimId: string;
  type: ExpenseCategoryType;
}

export enum ExpenseClaimState {
  Pending = 0,
  Approved = 1,
  Paid = 2,
  Rejected = 3,
  CaxtonApproved = 4,
  Processing = 5,
  CaxtonFailed = 6
}

export enum ExpenseClaimPaymentMethod {
  ManualBankTransfer = 0,
  CaxtonCard = 1,
  CaxtonAutomatedBankTransfer = 2
}

export class ExpenseClaimPaymentMethodString {
  public static Values = {
    0: "Manual Bank Transfer",
    1: "Caxton Card",
    2: "Caxton Automated Bank Transfer"
  }
}

export class UpdateExpenseClaimRequest {
  trialId: string;
  visitId: string;
  expenseCategoryId: string;
  // This should be enum - Adis
  subCategory: number;
  currency: string;
  amount?: number;
  amountBC?: number;
  recalculateAmountBc?: boolean;
  distanceUnit: string;
  distanceAmount?: number;
  rejectionReason: string;
  reasonForEdit: string;
  paymentMethod: ExpenseClaimPaymentMethod;
  onHold: boolean;
  overspendApproved: boolean;
  notes: string;
}

export enum AutoApprovalResult {
  Approve,
  Reject,
  ManualReview
}

export class AutoApprovalResultString {
  static Values: { [key in AutoApprovalResult]: string } = {
    [AutoApprovalResult.Approve]: "Approve",
    [AutoApprovalResult.Reject]: "Reject",
    [AutoApprovalResult.ManualReview]: "Manual Review",
  };
}

export interface ExpenseSuggestionData {
  icon: string;
  text: string;
}

export enum ExpenseCategoryType {
  Value,
  Distance
}

export enum OrderBy {
  Oldest,
  Newest
}

export class OrderByString {
  static Values: { [key in OrderBy]: string } = {
    [OrderBy.Oldest]: "Oldest",
    [OrderBy.Newest]: "Newest",
  };
}

export interface GetExpenseClaimsRequest {
  page: number;
  orderBy: OrderBy;
  state: ExpenseClaimState;
  onHold: boolean;
  investigations: boolean;
  keywords?: string;
}

export class ExpenseClaimViewModel {
  id: string;
  state: ExpenseClaimState;
  visitTitle: string;
  categoryName: string;
  categoryType: ExpenseCategoryType;
  amount: number;
  distanceAmount: number;
  currency: string;
  distanceUnit: string;
  rejectionReason: string;
  notes: string;
  imageUrls: ExpenseImage[];
  created: Date;
  dateApproved?: Date;
  dateRejected?: Date;
  datePaid?: Date;
  underInvestigation: boolean;
  reasonForInvestigation: string;
  subCategory: ExpenseSubCategory;
  patientId: string;
  patientCode: string;
  patientName: string;
  patientAddress: string;
  patientCountry: string;
  patientTrialId: string;
  trialCode: string;
  trialNickname: string;
  trialOpportunityNumber: string;
  trialId: string;
  paymentMethod: ExpenseClaimPaymentMethod;
  addedByPatient: boolean;
  paymentRequestedOn: Date;
  expectedClearanceDate: Date;
  paidBy: ExpenseClaimPaidBy;
  cardIdentifier: string;
  caxtonTransferId: string;
  sendToFinanceReason: string;
  visitId: string;
  visitType?: VisitType;
  visitAttendance?: VisitAttendance;
  visitDate?: Date;
  currencySymbol: string;
  policyReminders: string;
  type: string;
  caxtonFailedReason: string;
  caxtonFailedFindings: string;
  trialExpensePolicyReminders: string;
  adminExpenseNotes: string;
  bankFeesIncurred: boolean;
  overspendMileage: boolean;
  overspendTravel: boolean;
  approvalNoReceipts: boolean;
  fixedFeePerVisit: boolean;
  overspendApproved: boolean;
  overBudgetRequestRaised: boolean;
  overBudgetRequestStatus?: OverBudgetRequestDetailsStatus;
  approvalSuggestion?: AutoApprovalResult;
  referredBack: boolean;
  reasonsForEdit: ReasonForEditViewModel[];

  public static fromObject(obj: any): ExpenseClaimViewModel {
    let claim = new ExpenseClaimViewModel();

    claim.id = obj.id;
    claim.state = obj.state;
    claim.visitTitle = obj.visitTitle;
    claim.categoryName = obj.categoryName;
    claim.categoryType = obj.categoryType;
    claim.amount = obj.amount;
    claim.distanceAmount = obj.distanceAmount;
    claim.currency = obj.currency;
    claim.distanceUnit = obj.distanceUnit;
    claim.rejectionReason = obj.rejectionReason;
    claim.notes = obj.notes;
    claim.imageUrls = obj.imageUrls;
    claim.created = obj.created ? new Date(obj.created) : null;
    claim.dateApproved = obj.dateApproved ? new Date(obj.dateApproved) : null;
    claim.dateRejected = obj.dateRejected ? new Date(obj.dateRejected) : null;
    claim.datePaid = obj.datePaid ? new Date(obj.datePaid) : null;
    claim.underInvestigation = obj.underInvestigation;
    claim.reasonForInvestigation = obj.reasonForInvestigation;
    claim.subCategory = obj.subCategory;
    claim.patientId = obj.patientId;
    claim.patientCode = obj.patientCode;
    claim.patientName = obj.patientName;
    claim.patientAddress = obj.patientAddress;
    claim.patientCountry = obj.patientCountry;
    claim.patientTrialId = obj.patientTrialId;
    claim.trialCode = obj.trialCode;
    claim.trialNickname = obj.trialNickname;
    claim.trialOpportunityNumber = obj.trialOpportunityNumber;
    claim.trialId = obj.trialId;
    claim.paymentMethod = obj.paymentMethod;
    claim.addedByPatient = obj.addedByPatient;
    claim.paymentRequestedOn = obj.paymentRequestedOn ? new Date(obj.paymentRequestedOn) : null;
    claim.expectedClearanceDate = obj.expectedClearanceDate ? new Date(obj.expectedClearanceDate) : null;
    claim.paidBy = obj.paidBy;
    claim.cardIdentifier = obj.cardIdentifier;
    claim.caxtonTransferId = obj.caxtonTransferId;
    claim.sendToFinanceReason = obj.sendToFinanceReason;
    claim.visitId = obj.visitId;
    claim.visitType = obj.visitType;
    claim.visitAttendance = obj.visitAttendance;
    claim.visitDate = obj.visitDate ? DateHelper.toDateNoTimeZone(obj.visitDate) : null;
    claim.currencySymbol = obj.currencySymbol;
    claim.policyReminders = obj.policyReminders;
    claim.type = obj.type;
    claim.caxtonFailedReason = obj.caxtonFailedReason;
    claim.caxtonFailedFindings = obj.caxtonFailedFindings;
    claim.trialExpensePolicyReminders = obj.trialExpensePolicyReminders;
    claim.adminExpenseNotes = obj.adminExpenseNotes;
    claim.bankFeesIncurred = obj.bankFeesIncurred;
    claim.overspendMileage = obj.overspendMileage;
    claim.overspendTravel = obj.overspendTravel;
    claim.approvalNoReceipts = obj.approvalNoReceipts;
    claim.fixedFeePerVisit = obj.fixedFeePerVisit;
    claim.overspendApproved = obj.overspendApproved;
    claim.overBudgetRequestRaised = obj.overBudgetRequestRaised;
    claim.overBudgetRequestStatus = obj.overBudgetRequestStatus;
    claim.approvalSuggestion = obj.approvalSuggestion;
    claim.reasonsForEdit = obj.reasonsForEdit;
    claim.referredBack = obj.referredBack;

    return claim;
  }

}

export interface ReasonForEditViewModel {
  reason: string;
  created: Date;
}

export enum ExpenseSubCategory {
  None = 0,
  CardPreloadPayment = 1,
  DrawdownAgainstCardPreload = 2,
  StipendPayment = 3,
  BankFees = 4,
  FixedFee = 5
}

export enum VisitType {
  OnSite = 0,
  Home = 1,
  Virtual = 2,
  Telephone = 3
}

export enum ExpenseClaimType {
  Currency,
  Distance
}

export enum VisitAttendance {
  Cancelled = 0,
  Attended = 1,
  NoShow = 2,
  PendingConfirmation = 3,
  AutoAttended = 4
}

export interface OverBudgetRequestDetailsViewModel {
  ruleName: string;
  categories: string[];
  expenseClaims: ExpenseClaimOverBudgetRequestDetails[],
  budgetDetails: OverBudgetRequestBudgetDetails[],
  budgetCost: number,
  actualBudgetCostCurrency: string,
  actualBudgetCost?: number;
  careGiver?: CaregiverTravelling,
  blanketApprovalForNoReceipts: boolean,
  overBudgetRequirement: string
}

export interface ExpenseClaimOverBudgetRequestDetails {
  created: Date,
  amount?: number,
  currency: string,
  category: string,
  state: ExpenseClaimState,
  receiptUrls: ExpenseImage[],
  receiptMissing: boolean
}

export interface OverBudgetRequestBudgetDetails {
  actualCost: number,
  localCurrency: string,
}

export interface CreateOverBudgetRequestByExpenseClaimRequest {
  expenseClaimId: string;
  requiredInformation: string;
  actualCostInBudgetCurrency?: number;
}

export interface CreateOverBudgetRequestByCategoriesRequest {
  requiredInformation: string;
  actualCostInBudgetCurrency?: number;
  budgetCost?: number;
  categories: string[];
  visitId: string;
}

export interface ExpenseCategorySelected {
  checked: boolean;
  category: string;
}

export interface UpdateExpenseClaimResult {
  saved: boolean;
}

export interface UpdateExpenseClaimAmountRequest extends UpdateEntityAmountRequest {
}

export interface UpdateExpenseClaimAmountBcRequest extends UpdateEntityAmountRequest {
}

export interface UpdateExpenseClaimCurrencyRequest extends UpdateEntityCurrencyRequest {
}
