import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedEmitterService {
  overBudgetRequestClosedEvent: EventEmitter<string> = new EventEmitter<string>();
  overBudgetRequestClosedOpened: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }
}
