<ul cdkDropList (cdkDropListDropped)="drop($event)">
  <li *ngFor="let attachment of attachments; let i = index" cdkDrag>
    <a cdkDragHandle class="move-handle"><i class="icon-elipsis"></i></a>
    <a href="{{attachment.url}}" target="_blank"> {{ renderOriginalFileName(attachment.originalFileName) }}</a>
    <a class="trash" (click)="remove(i)"><i class="far fa-times"></i></a>
  </li>
</ul>

<a (click)="onOpenFileBrowser($event)" class="btn btn-outline btn-slim btn-add-ticket">+ Add Ticket(s)</a>
<input #fileUploadInput type="file" id="file-upload-input" multiple accept="application/pdf"
       (change)="onFileSelected($event)">
