import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitsComponent } from './visits/visits.component';
import { SharedModule } from "../../shared/shared.module";
import { VisitTableRowComponent } from './visits/visit-table-row/visit-table-row.component';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  VisitTableCellTextComponent
} from './visits/visit-table-row/visit-table-cell-text/visit-table-cell-text.component';
import { RouterModule } from "@angular/router";
import {
  VisitTableCellSelectComponent
} from './visits/visit-table-row/visit-table-cell-select/visit-table-cell-select.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddVisitModalComponent } from './add-visit-modal/add-visit-modal.component';
import { EditVisitModalComponent } from './edit-visit-modal/edit-visit-modal.component';
import { VisitTripComponent } from './visits/visit-trip/visit-trip.component';
import {
  VisitTableCellMultiselectComponent
} from './visits/visit-table-row/visit-table-cell-multiselect/visit-table-cell-multiselect.component';
import {
  VisitTableCellDateComponent
} from './visits/visit-table-row/visit-table-cell-date/visit-table-cell-date.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  VisitTableCellTimeComponent
} from './visits/visit-table-row/visit-table-cell-time/visit-table-cell-time.component';
import { DuplicateVisitModalComponent } from './visits/duplicate-visit-modal/duplicate-visit-modal.component';
import { TripMuvRidesComponent } from './trip-muv-rides/trip-muv-rides.component';
import { MuvRideListComponent } from './trip-muv-rides/muv-ride-list/muv-ride-list.component';
import { MuvRideFormComponent } from './trip-muv-rides/muv-ride-form/muv-ride-form.component';
import {
  MuvRideStateBadgeComponent
} from './trip-muv-rides/muv-ride-list/muv-ride-state-badge/muv-ride-state-badge.component';
import { AddEditTripModalComponent } from './add-edit-trip-modal/add-edit-trip-modal.component';
import { AddTripV2ModalComponent } from "./add-edit-trip-modal/add-trip-v2-modal/add-trip-v2-modal.component";
import { EditTripV2ModalComponent } from "./add-edit-trip-modal/edit-trip-v2-modal/edit-trip-v2-modal.component";
import { MuvRideViewComponent } from './trip-muv-rides/muv-ride-view/muv-ride-view.component';
import { ManageTripTicketsComponent } from './add-edit-trip-modal/manage-trip-tickets/manage-trip-tickets.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import {
  VisitTableCellPatientCodeComponent
} from "./visits/visit-table-row/visit-table-cell-patient-code/visit-table-cell-patient-code.component";
import {
  VisitPatientDetailModalComponent
} from "./visits/visit-patient-detail-modal/visit-patient-detail-modal.component";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { TripTableRowComponent } from "./visits/trip-table-row/trip-table-row.component";
import { TripsOverBudgetRequestModalComponent } from './trips-over-budget-request-modal/trips-over-budget-request-modal.component';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  declarations: [
    VisitsComponent,
    VisitTableRowComponent,
    VisitTableCellTextComponent,
    VisitTableCellSelectComponent,
    AddVisitModalComponent,
    EditVisitModalComponent,
    VisitTripComponent,
    VisitTableCellMultiselectComponent,
    VisitTableCellDateComponent,
    VisitTableCellTimeComponent,
    VisitTableCellPatientCodeComponent,
    DuplicateVisitModalComponent,
    AddTripV2ModalComponent,
    EditTripV2ModalComponent,
    TripMuvRidesComponent,
    MuvRideListComponent,
    MuvRideFormComponent,
    MuvRideStateBadgeComponent,
    AddEditTripModalComponent,
    MuvRideViewComponent,
    ManageTripTicketsComponent,
    TripTableRowComponent,
    TripsOverBudgetRequestModalComponent
  ],
  exports: [
    AddVisitModalComponent,
    EditVisitModalComponent,
    AddEditTripModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatRadioModule,
    VisitPatientDetailModalComponent,
    NgxMatInputTelComponent,
    MatStepperModule
  ]
})
export class VisitModule { }
