import { Permissions } from './../../../core/constants/permissions';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SiteList } from "../../../core/models/site-list.model";
import { SelectOption } from "../../../core/models/select-option.model";
import { SiteService } from "../../../core/services/site.service";
import { Countries } from "../../../core/constants/countries";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {
  // Export
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('exportSitesModal') exportSitesModal: ModalComponent;
  @ViewChild('deleteSiteModal') deleteSiteModal: ModalComponent;

  exportIsProcessing = false;
  exportForm: UntypedFormGroup;
  siteIsDeleting = false;
  deletingSiteId = '';

  searchForm: UntypedFormGroup;
  results = new SiteList();
  keywords: string = null;
  hasSites = true;
  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

  selectedCountry = '';
  countryOptions: SelectOption[] = [];
  filteredCountries: Observable<SelectOption[]>;
  Permissions = Permissions;
  excludeApiConsumerSites: boolean = true;

  constructor(public authService: AuthService, private readonly templateService: TemplateService, private readonly siteService: SiteService, private readonly alertService: AlertService, private renderer: Renderer2, private elementRef: ElementRef) {
    for (const country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl(''),
      country: new UntypedFormControl('')
    });

    this.loadSites(1, null, null);

    // Export sites form
    this.exportForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl('', Validators.required),
      dateTo: new UntypedFormControl('', Validators.required),
      allData: new UntypedFormControl(false)
    });

    this.exportForm.get('allData').valueChanges.subscribe(allData => {
      if (allData) {
        this.exportForm.get('dateFrom').disable();
        this.exportForm.get('dateTo').disable();
        this.exportForm.get('dateFrom').setValidators(null);
        this.exportForm.get('dateTo').setValidators(null);
        this.exportForm.patchValue({ dateFrom: null, dateTo: null });
      } else {
        this.exportForm.get('dateFrom').enable();
        this.exportForm.get('dateTo').enable();
        this.exportForm.get('dateFrom').setValidators(Validators.required);
        this.exportForm.get('dateTo').setValidators(Validators.required);
      }
    });

    // Reload search results if country field is cleared
    this.searchForm.get('country').valueChanges.subscribe(value => {
      this.loadSites(1, this.keywords, value);
    });
  }

  onExportMouseMouse() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value,
    });
    this.exportForm.updateValueAndValidity()
  }

  loadSites(page, keywords: string, country: string) {
    this.dataLoaded = false;
    this.siteService.retrieveSites(page, null, keywords, country, this.excludeApiConsumerSites).subscribe({
      next: sites => {
        this.results = sites;

        if (page === 1 && keywords === null && this.searchForm.get('country').value === null && sites.results.length === 0) {
          this.hasSites = false;
        }
        this.dataLoaded = true;
      },
      error: error => {
        this.dataLoaded = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem loading sites.');
      }
    });
  }

  /**
   * Called when the value of the search input changes, it loads trials based on the value of the field
   */
  onSearchInputChanged(keywords: string) {
    this.keywords = keywords === '' ? null : keywords;
    this.loadSites(1, keywords, this.searchForm.get('country').value);
  }

  /**
   * Updates the angular form when you mouse over the export button, it pulls the dates from the fields and puts them
   * into the form. It's a bodge to fix an issue with the jQuery datepicker and angular
   */
  updateExportFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  /**
   * Shows the delete site modal window
   * @param siteId
   */
  onDeleteSite(siteId: string) {
    this.deletingSiteId = siteId;
    this.deleteSiteModal.show();
  }

  onHandleToggleApiConsumerSites(): void {
    this.excludeApiConsumerSites = !this.excludeApiConsumerSites;

    setTimeout(() => {
      this.loadSites(1, this.keywords, this.searchForm.get('country').value);
    }, 600);
  }

  /**
   * Removes a site once a user has confirmed
   */
  onConfirmDeleteSite() {
    this.siteIsDeleting = true;

    this.siteService.removeSite(this.deletingSiteId).subscribe({
      next: () => {
        this.siteIsDeleting = false;
        this.deletingSiteId = '';
        this.deleteSiteModal.hide();
        this.loadSites(this.results.currentPage, this.searchForm.get('keywords').value, this.searchForm.get('country').value);
        this.alertService.showSuccessAlert('The site has been successfully deleted');
      },
      error: error => {
        LogHelper.log(error);
        this.siteIsDeleting = false;
        this.deleteSiteModal.hide();
        this.alertService.showWarningAlert(error.error ? error.error.title : 'There was a problem removing the site');
      }
    });
  }

  onExportSites() {
    this.exportIsProcessing = true;

    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;

    this.siteService.exportSites(from, to).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportSitesModal.hide();
        this.exportIsProcessing = false;
        this.exportForm.patchValue({
          dateFrom: '',
          dateTo: ''
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }
}
