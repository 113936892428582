<app-modal #modal [customClassName]="'over-budget-categories-select-modal'">
  <div class="modal-header">
    <h2>Select categories for over budget request</h2>
  </div>
  <div class="modal-body">
    <app-approved-expenses-container (toggleCategory)="toggleCategory($event)" [subTotals]="subTotals"
      [showCheckbox]="true"></app-approved-expenses-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="hideModal()">Cancel</button>
    <button class="btn btn-primary" [disabled]="!continueAvailable" (click)="onRaiseOverBudget()">
      <span *ngIf="loading">
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
      </span>
      <span *ngIf="!loading">Continue</span>
    </button>
  </div>
</app-modal>
