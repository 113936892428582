import { SelectOption } from "../models/select-option.model";

export class TripBookingStatus {
  public static getDescription(status: string): string {
    const statusObj = this.all().find(x => x.value === status);
    return statusObj ? statusObj.text : '';
  }

  public static all(): SelectOption[] {
    return [
      {
        "value": "Booked",
        "text": "Booked"
      },
      {
        "value": "NotStarted",
        "text": "Not Started"
      },
      {
        "value": "InProgress",
        "text": "In Progress"
      },
      {
        "value": "QuoteRequested",
        "text": "Quote Requested"
      },
      {
        "value": "ObAwaitingSponsorApproval",
        "text": "OB - Awaiting Sponsor Approval"
      },
      {
        "value": "ChangeRequested",
        "text": "Change Requested"
      },
      {
        "value": "Cancelled",
        "text": "Cancelled"
      },
      {
        "value": "CoveredByPatient",
        "text": "Covered By Patient"
      },
      {
        "value": "AwaitingPatientConfirmation",
        "text": "Awaiting Patient Confirmation"
      },
      {
        "value": "ObResponded",
        "text": "OB Responded"
      }
    ];
  }
}
