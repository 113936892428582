import { AccommodationBudgetType } from './../../../trial/trial-details/trial-policy.model';
import { AlertService } from './../../../../shared/alert/alert.service';
import { ProjectManagementService } from './../../../../core/services/project-management.service';
import { OverBudgetRequestApproveAllRequest, OverBudgetRequestApproveTotalRequest, OverBudgetRequestDetailsStatus, OverBudgetRequestStatus, PartialRejectOverBudgetRequestRequest, RejectOverBudgetRequestRequest } from 'app/core/models/project-management.model';
import { ExpenseAutoApprovalRuleType } from 'app/features/trial/trial-details/trial-policy.model';
import { OverBudgetRequestCategory, OverBudgetRequestItemsViewModel } from './../../../../core/models/project-management.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import { OverBudgetRequestHistoryModalComponent } from '../over-budget-request-history-modal/over-budget-request-history-modal.component';
import moment from 'moment';
import { VisitPatientDetailModalComponent } from 'app/features/visit/visits/visit-patient-detail-modal/visit-patient-detail-modal.component';
import { AuthService } from 'app/core/services/auth.service';
import { Permissions } from 'app/core/constants/permissions';

@Component({
  selector: 'app-over-budget-request-items',
  templateUrl: './over-budget-request-items.component.html',
  styleUrl: './over-budget-request-items.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OverBudgetRequestItemsComponent implements OnInit {
  @Input() overBudgetRequestItemsViewModel: OverBudgetRequestItemsViewModel[];
  @Input() overBudgetRequestId: string;
  @Input() overBudgetRequestCategory: OverBudgetRequestCategory;
  @Input() patientTrialId: string;
  @Output() deleteRequest: EventEmitter<any> = new EventEmitter<any>();
  @Output() referBackRequest: EventEmitter<any> = new EventEmitter<any>();
  @Output() overBudgetRequestClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshItems: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('approveTotalModal') approveTotalModal: ModalComponent;
  @ViewChild('approveAllModal') approveAllModal: ModalComponent;
  @ViewChild('rejectOptionsModal') rejectOptionsModal: ModalComponent;
  @ViewChild('rejectModal') rejectModal: ModalComponent;
  @ViewChild('viewHistoryModal') viewHistoryModal: OverBudgetRequestHistoryModalComponent;
  @ViewChild('visitPatientDetailModal') visitPatientDetailModal: VisitPatientDetailModalComponent;

  approveTotalForm = new FormGroup({
    overBudgetRequestDetailsId: new FormControl(''),
    amountPayable: new FormControl({ value: null, disabled: true }),
    approvedBy: new FormControl(''),
    approvedAt: new FormControl()
  });

  approveAllForm = new FormGroup({
    approvedBy: new FormControl(''),
    approvedAt: new FormControl()
  });

  rejectForm = new FormGroup({
    overBudgetRequestDetailsId: new FormControl(''),
    amountToBePaid: new FormControl(),
    rejectedBy: new FormControl(''),
    rejectedAt: new FormControl(),
    notes: new FormControl('', Validators.required)
  });

  ExpenseAutoApprovalRuleType = ExpenseAutoApprovalRuleType;
  OverBudgetRequestCategory = OverBudgetRequestCategory;
  OverBudgetRequestStatus = OverBudgetRequestStatus;
  OverBudgetRequestDetailsStatus = OverBudgetRequestDetailsStatus;
  AccommodationBudgetType = AccommodationBudgetType;

  overBudgetItemToApprove: OverBudgetRequestItemsViewModel;
  overBudgetItemToReject: OverBudgetRequestItemsViewModel;
  loading: boolean = false;
  historyLoading: boolean = false;
  approveAllDisabled: boolean = false;
  rejectPartial: boolean = false;

  Permissions = Permissions;

  constructor(private projectManagementService: ProjectManagementService, private alertService: AlertService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.checkApproveAllButtonAvailability();
  }

  onApproveTotal() {
    this.loading = true;
    let request = this.approveTotalForm.value as OverBudgetRequestApproveTotalRequest;
    request.approvedAt = this.approveTotalForm.controls.approvedAt.value !== null ?
      new Date(moment(this.approveTotalForm.controls.approvedAt.value).format('YYYY-MM-DD')) : null;

    this.projectManagementService.approveTotalFromOverBudgetRequest(request).subscribe({
      next: result => {
        this.overBudgetItemToApprove.status = OverBudgetRequestDetailsStatus.Approved;
        this.overBudgetItemToApprove.amountPaid = this.overBudgetItemToApprove.totalActualCost;
        this.refreshItems.emit();
        this.checkApproveAllButtonAvailability();

        if (result.status === OverBudgetRequestStatus.Closed) {
          this.overBudgetRequestClosed.emit();
        }

        this.loading = false;
        this.approveTotalModal.hide();
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  onApproveAll() {
    this.loading = true;
    let request = this.approveAllForm.value as OverBudgetRequestApproveAllRequest;
    request.overBudgetRequestId = this.overBudgetRequestId;
    request.approvedAt = this.approveAllForm.controls.approvedAt.value !== null ?
      new Date(moment(this.approveAllForm.controls.approvedAt.value).format('YYYY-MM-DD')) : null;

    this.projectManagementService.approveAllFromOverBudgetRequest(request).subscribe({
      next: () => {
        this.overBudgetRequestItemsViewModel.forEach(item => {
          item.status = OverBudgetRequestDetailsStatus.Approved;
          item.amountPaid = item.totalActualCost;

          item.items.forEach(trip => {
            trip.status = enumToText(OverBudgetRequestDetailsStatus, OverBudgetRequestDetailsStatus.Approved);
            trip.date = request.approvedAt;
          })
          this.approveAllDisabled = true;
          this.overBudgetRequestClosed.emit();
          this.loading = false;
          this.approveAllModal.hide();
        })
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  showApproveAllModal() {
    this.approveAllForm.reset();
    this.approveAllForm.controls.approvedAt.setValue(new Date());
    this.approveAllModal.show();
  }

  showApproveTotalModal(items: OverBudgetRequestItemsViewModel) {
    this.approveTotalForm.reset();
    this.overBudgetItemToApprove = items;
    this.approveTotalForm.controls.approvedAt.setValue(new Date());
    this.approveTotalForm.controls.overBudgetRequestDetailsId.setValue(items.id);
    this.approveTotalForm.controls.amountPayable.setValue(items.totalActualCost);
    this.approveTotalModal.show();
  }

  checkApproveAllButtonAvailability() {
    this.approveAllDisabled = this.overBudgetRequestItemsViewModel.some(i => i.status !== null);
  }

  showRejectModal(items: OverBudgetRequestItemsViewModel) {
    this.rejectForm.reset();
    this.rejectForm.controls.overBudgetRequestDetailsId.setValue(items.id);
    this.rejectForm.controls.rejectedAt.setValue(new Date());
    this.overBudgetItemToReject = items;

    if (this.overBudgetRequestCategory === OverBudgetRequestCategory.Expense) {
      this.rejectOptionsModal.show();
    } else {
      this.rejectPartial = false;
      this.rejectModal.show();
    }
  }

  showExpenseRejectModal(rejectPartial: boolean) {
    this.rejectPartial = rejectPartial;

    this.rejectModal.show();
  }

  onReject() {
    if (this.rejectPartial) {
      this.partialRejectItems();
    } else {
      this.rejectItems();
    }
  }

  showHistoryModal() {
    this.historyLoading = true;

    this.projectManagementService.viewHistory(this.overBudgetRequestId).subscribe({
      next: response => {
        this.viewHistoryModal.historyItems = response;
        this.viewHistoryModal.modal.show();
        this.historyLoading = false;
      },
      error: error => {
        this.historyLoading = false;
        this.alertService.showErrorResponse(error.error);
      }
    })
  }

  private rejectItems() {
    this.loading = true;
    let request = this.rejectForm.value as RejectOverBudgetRequestRequest;
    request.rejectedAt = this.rejectForm.controls.rejectedAt.value !== null ?
      new Date(moment(this.rejectForm.controls.rejectedAt.value).format('YYYY-MM-DD')) : null;

    this.projectManagementService.rejectOverBudgetRequest(request).subscribe({
      next: result => {
        this.overBudgetItemToReject.status = OverBudgetRequestDetailsStatus.Rejected;
        this.refreshItems.emit();

        this.approveAllDisabled = true;

        if (result.overBudgetClosed) {
          this.overBudgetRequestClosed.emit();
        }

        this.loading = false;
        this.rejectModal.hide();
        if (this.overBudgetRequestCategory === OverBudgetRequestCategory.Expense) {
          this.rejectOptionsModal.hide();
        }
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  private partialRejectItems() {
    this.loading = true;
    let request = this.rejectForm.value as PartialRejectOverBudgetRequestRequest;
    request.rejectedAt = this.rejectForm.controls.rejectedAt.value !== null ?
      new Date(moment(this.rejectForm.controls.rejectedAt.value).format('YYYY-MM-DD')) : null;

    this.projectManagementService.partialRejectOverBudgetRequest(request).subscribe({
      next: result => {
        this.refreshItems.emit();

        if (result.overBudgetClosed) {
          this.overBudgetRequestClosed.emit();
        }
        this.loading = false;
        this.rejectModal.hide();
        this.rejectOptionsModal.hide();
      },
      error: error => {
        this.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  onClickShowPatientDetails() {
    this.visitPatientDetailModal.showTravelBudgetInfo(this.patientTrialId);
  }
}
