import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TrialService } from "../../../../core/services/trial.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { ChangeLog } from "../../../../shared/change-log/change-log.model";
import { ModalComponent } from "../../../../shared/modal/modal.component";

@Component({
  selector: 'app-trial-change-log',
  templateUrl: './trial-change-log.component.html'
})
export class TrialChangeLogComponent implements OnInit {
  // Export
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('exportChangeLogModal') exportChangeLogModal: ModalComponent;
  exportIsProcessing = false;
  exportForm: UntypedFormGroup;
  dataLoaded: boolean = false;

  @Input() trialId: string;
  changeLogResults: ChangeLog;

  constructor(private readonly trialService: TrialService, private readonly alertService: AlertService) { }

  ngOnInit() {
    // Export sites form
    this.exportForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl()
    });

    this.loadChangeLog();
  }

  loadChangeLog(page = 1) {
    this.trialService.changeLog(this.trialId, page).subscribe({
      next: results => {
        this.changeLogResults = results;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
        this.alertService.showWarningAlert('There was a problem loading the change log.');
      }
    });
  }

  onPageChanged(page: number) {
    this.loadChangeLog(page);
  }

  /**
   * Updates the angular form when you mouse over the export button, it pulls the dates from the fields and puts them
   * into the form. It's a bodge to fix an issue with the jQuery datepicker and angular
   */
  updateExportFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  onExportChangeLog() {
    this.exportIsProcessing = true;
    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;

    this.trialService.exportChangeLog(this.trialId, from, to).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportChangeLogModal.hide();
        this.exportIsProcessing = false;
        this.exportForm.patchValue({
          dateFrom: '',
          dateTo: ''
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }
}
