import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ListType} from "../constants/list-type";
import {map} from "rxjs/operators";
import {ListValue} from "../models/list-value.model";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private _endpoint = '/list';

  constructor(private _http: HttpClient) { }

  /**
   * Removes an item from a list
   * @param type
   * @param id
   */
  remove(type: ListType, id: string) {
    return this._http.delete(environment.apiUrl + this._endpoint + '/' + type + '/' + id);
  }

  /**
   * Adds a new item to a list
   * @param type
   * @param label
   */
  add(type: ListType, label: string): Observable<ListValue> {
    return this._http.put(environment.apiUrl + this._endpoint + '/' + type, {label: label}).pipe(map(rsp => {
      return new ListValue(rsp);
    }));
  }

  /**
   * Gets items on a list
   * @param type
   * @param searchTerm
   */
  search(listType: string, searchTerm: string = null): Observable<ListValue[]> {
    let params = new HttpParams();
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this._http.get(environment.apiUrl + this._endpoint + '/' + listType, {params: params}).pipe(map(rsp => {
      return (rsp as any[]).map(x => new ListValue(x));
    }));
  }
}
