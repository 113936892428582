<div class="approved-expenses-container">
  <label *ngIf="!showCheckbox">All expenses for this visit</label>
  <div class="approved-expenses">
    <div class="approved-expenses--header">
      <div class="approved-expenses--header--cell">Category</div>
      <div class="approved-expenses--header--cell">Currency</div>
      <div class="approved-expenses--header--cell">Approved</div>
      <div class="approved-expenses--header--cell">Pending</div>
      <div class="approved-expenses--header--cell">Total</div>
      <div class="approved-expenses--header--cell checkbox-cell" *ngIf="showCheckbox"></div>
    </div>
    <div *ngFor="let category of subTotals" class="approved-expenses--item">
      <div class="approved-expenses--item--cell">{{ category.categoryName }}</div>
      <div class="approved-expenses--item--cell">{{ category.currency}}</div>
      <div class="approved-expenses--item--cell">{{ category.approved }}</div>
      <div class="approved-expenses--item--cell">{{ category.pending }}</div>
      <div class="approved-expenses--item--cell">{{ category.total }}</div>
      <div class="approved-expenses--item--cell checkbox-cell" *ngIf="showCheckbox">
        <label class="checkbox">
          <input type="checkbox" (click)="onCategorySelected($event, category.categoryName)">
          <span></span>
        </label>
      </div>
    </div>
  </div>
</div>
