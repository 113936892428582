import { AuthService } from './../../../../core/services/auth.service';
import { RoleNames } from './../../../../core/constants/role-names';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AdminService } from 'app/core/services/admin.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { TemplateService } from 'app/core/services/template.service';
import { ManageAdminTrialsComponent } from "../manage-admin-trials/manage-admin-trials.component";
import { AdminUser } from "../../../../core/models/admin-user.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AdministratorTrial } from "../../../../core/models/administrator-trial.model";

@Component({
  selector: 'app-add-admin-user',
  templateUrl: './add-admin-user.component.html',
  styleUrls: ['./add-admin-user.component.scss']
})
export class AddAdminUserComponent implements OnInit, AfterViewInit {
  @ViewChild('manageTrials') manageTrials: ManageAdminTrialsComponent;

  form: UntypedFormGroup;
  isFormProcessing = false;
  selectedTab = 'info';

  selectedRoles: string[] = [];
  availableRoles: string[] = [
    RoleNames.ProductAdministrator,
    RoleNames.ProjectCoordinatorExpenses,
    RoleNames.ProjectCoordinatorTravel,
    RoleNames.StartupCoordinator,
    RoleNames.ProjectManager
  ]
  hideFormButtons = false;
  administrator: AdminUser = new AdminUser();

  constructor(private adminService: AdminService, private alertService: AlertService,
    private router: Router, private templateService: TemplateService,
    public authService: AuthService) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      trialIds: new UntypedFormControl(),
      projectManagerTrialIds: new UntypedFormControl(),
      muvUserId: new UntypedFormControl('')
    });

    this.addSystemAdminRoleToList();
  }

  ngAfterViewInit(): void {
    this.manageTrials.hideFormButtons.subscribe(hideFormButtons => {
      this.hideFormButtons = hideFormButtons;
    });
  }

  addSystemAdminRoleToList() {
    if (this.authService.hasRole(RoleNames.SystemAdministrator)) {
      this.availableRoles.splice(0, 0, RoleNames.SystemAdministrator)
    }
  }

  onTabClick(selection: string) {
    this.selectedTab = selection;
  }

  onFormSubmit() {
    if (!this.form.valid)
      return;

    this.isFormProcessing = true;

    this.adminService.createAdminUser(
      this.form.get('firstname').value,
      this.form.get('lastname').value,
      this.form.get('email').value,
      this.selectedRoles,
      this.form.get('trialIds').value,
      this.form.get('projectManagerTrialIds').value,
      this.form.get('muvUserId').value
    ).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('User successfully created.');
        this.router.navigate(['/admin']);
      },
      error: err => {
        this.isFormProcessing = false;
        this.alertService.showWarningAlert(err.error.title);
      }
    });
  }

  selectRole(checkEvent: any, roleName: string) {
    if (checkEvent.target.checked) {
      this.selectedRoles.push(roleName);
    } else {
      this.selectedRoles = this.selectedRoles.filter(role => role !== roleName);
    }
  }

  getRoleText(roleName: string): string {
    switch (roleName) {
      case RoleNames.SystemAdministrator:
        return "System Administrator";
      case RoleNames.ProductAdministrator:
        return "Product Administrator";
      case RoleNames.ProjectCoordinatorExpenses:
        return "Project Coordinator - Expenses";
      case RoleNames.ProjectCoordinatorTravel:
        return "Project Coordinator - Travel";
      case RoleNames.StartupCoordinator:
        return "Startup Coordinator";
      case RoleNames.ProjectManager:
        return "Project Manager";
    }
  }

  onAssignTrials(adminTrials: AdministratorTrial[]): void {
    LogHelper.log('Administrator trials');
    LogHelper.log(adminTrials);
  }

  onAssignTrialsOld(trialIds: string[]): void {
    let currentTrialIds: string[] = this.form.get('trialIds').value;
    if (!Array.isArray(currentTrialIds))
      currentTrialIds = [];

    trialIds.forEach(trialId => {
      if (!currentTrialIds.includes(trialId))
        currentTrialIds.push(trialId);
    });

    this.form.patchValue({trialIds: currentTrialIds});
  }

  updateFormButtonVisibility(hide: boolean): void {
    this.hideFormButtons = hide;
  }

  onHandleAssignedProjectManager(projectManagerTrialIds: string[]) {
    if (projectManagerTrialIds.length > 0) {
      // Retrieve the value and ensure it's an array
      let t: string[] = this.form.get('projectManagerTrialIds').value;
      if (!Array.isArray(t)) {
        t = [];
      }

      projectManagerTrialIds.forEach(trialId => {
        if (!t.includes(trialId))
          t.push(trialId);
      });

      this.form.patchValue({ projectManagerTrialIds: t });
    }
  }


  protected readonly RoleNames = RoleNames;
}
