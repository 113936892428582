<app-modal #previewModal customClassName="timeline-preview-modal">
  <div class="modal-header">
    <a (click)="onClose()" class="close">
      <i class="far fa-times" aria-hidden="true"></i>
    </a>
    <h2 class="modal-title modal-title-left">Timeline Preview</h2>
  </div>
  <div class="modal-body">
    <div class="timeline-events">
      <app-trial-timeline-event *ngFor="let timelineEvent of timelineEvents; let i = index" [first]="i == 0" [last]="i == timelineEvents.length-1" [timelineEvent]="timelineEvent" [isPreview]="true"></app-trial-timeline-event>
    </div>
  </div>
</app-modal>
