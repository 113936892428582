import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export function optionalEmailValidator(control: AbstractControl): ValidationErrors | null {
  let emailValue: string = control.value;
  if (emailValue === null || emailValue === '')
    return null;

  emailValue = emailValue.trim();

  if (emailValue === '') {
    return { 'empty-string': 'Value cannot be empty' };
  }

  if (emailValue && emailValue.indexOf('@') === -1) {
    return null;
  }

  return Validators.email(control);
}
