<div class="view-head">
  <form [formGroup]="searchForm">
    <div class="patients-header">

      <h2>Patients</h2>

      <div class="country-dropdown-wrapper">
        <app-autosuggest-dropdown-input #countryDropdown className="small" [options]="countryOptions"
          class="country-dropdown" placeholder="All Countries" [parentForm]="searchForm"
          controlName="country"></app-autosuggest-dropdown-input>
      </div>

      <div class="search-input">
        <app-search-input name="keywords" [parentForm]="searchForm" (inputChange)="onSearchInputChanged($event)">
        </app-search-input>
      </div>

      <app-dropdown-input style="width: 110px; margin-right: 10px;" class="order-by-dropdown" #orderBySelect
                          [options]="orderByOptions"></app-dropdown-input>

      <a class="btn btn-outline" (click)="exportPatientsModal.show()"
        *ngIf="authService.hasPermission(Permissions.PatientExportCsv)">Export CSV</a>

      <a (click)="addPatientModal.show()" class="btn btn-primary"
         *ngIf="authService.hasPermission(Permissions.PatientWrite) && authService.hasPermission(Permissions.TrialRead)">+
        Add New Patient</a>
    </div>
  </form>
</div>

<div class="view-body">

  <div class="std-tabbed-content">

    <div class="tabs-wrap">
      <app-mobile-tabs #mobileTabs (onSelected)="onSelectTab($event)">
        <ul>
          <li><a class="selected" (click)="mobileTabs.onSelectItem('approved', $event)">Approved</a>
          <li><a (click)="mobileTabs.onSelectItem('pending', $event)">Pending ({{ pendingPatientsCount }})</a></li>
          <li><a (click)="mobileTabs.onSelectItem('rejected', $event)">Rejected</a></li>
          <li><a (click)="mobileTabs.onSelectItem('deletion-requests', $event)">Account Deletion Requests</a></li>
          <li><a (click)="mobileTabs.onSelectItem('app-feedback', $event)">App Feedback</a></li>
          <li><a (click)="mobileTabs.onSelectItem('patient-registrations', $event)">Patient Registrations</a></li>
        </ul>
      </app-mobile-tabs>

      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'approved'}"
            (click)="onSelectTab('approved')">Approved</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'pending'}" (click)="onSelectTab('pending')">Pending
            ({{ pendingPatientsCount }})</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'rejected'}"
            (click)="onSelectTab('rejected')">Rejected</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'deletion-requests'}"
            (click)="onSelectTab('deletion-requests')">Account Deletion Requests</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'app-feedback'}"
            (click)="onSelectTab('app-feedback')">App Feedback</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'patient-registrations'}"
            (click)="onSelectTab('patient-registrations')">Patient Registrations</a>
        </li>
      </ul>
    </div>

    <div class="results-wrap">

      <div class="tab-content">

        <div class="{{ selectedTab == 'approved' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-approved #approvedTab></app-patient-approved>
        </div>

        <div class="{{ selectedTab == 'pending' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-pending #pendingTab (pendingPatientCountChanged)="onPendingPatientCountChanged($event)"
            (patientSelectedForApproval)="onPatientSelectedForApproval($event)"
            (patientSelectedForRejection)="onPatientSelectedForRejection($event)">
          </app-patient-pending>
        </div>

        <div class="{{ selectedTab == 'rejected' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-rejected #rejectedTab
            (patientSelectedForApproval)="onPatientSelectedForApproval($event)"></app-patient-rejected>
        </div>

        <div class="{{ selectedTab == 'deletion-requests' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-deletion-request #deletionRequestTab></app-patient-deletion-request>
        </div>

        <div class="{{ selectedTab == 'app-feedback' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-feedback #appFeedbackTab></app-patient-feedback>
        </div>

        <div class="{{ selectedTab == 'patient-registrations' ? 'visibility-visible' : 'visibility-hidden' }}">
          <app-patient-registrations #appPatientRegistrationsTab></app-patient-registrations>
        </div>

      </div>

    </div>

  </div>

</div>

<app-modal #approvePatientModal customClassName="approve-patient-modal">
  <form class="modal-form-full-width" [formGroup]="approvePatientForm" (submit)="onApprovePatientFormSubmit()">
    <div class="modal-header">
      <h2 class="modal-title">Approve Patient</h2>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label>Patient ID</label>
        <input type="text" class="form-control" formControlName="patientCode">
      </div>
      <div class="form-group">
        <label>Site</label>
        <app-site-autocomplete #siteAutocomplete [allowAllSites]="false" [form]="approvePatientForm"
          [showApprovedOnly]="true" [showSiteNumbers]="true" [trialFilter]="approvingPatient?.trialId"
          controlName="siteId" placeholder="Start typing to find a site..."></app-site-autocomplete>
      </div>

      <div class="patient-consent">
        <p class="consent-given"
          [ngClass]="{'visibility-hidden': approvePatientForm.get('showPatientConsentCheckbox').value}">
          <i class="far fa-check" aria-hidden="true"></i>
          Patient gave consent via the {{ approvePatientForm.get('patientConsentType').value }} on {{
          approvePatientForm.get('patientConsentDate').value | date: 'd MMM yyyy' }}
        </p>

        <div class="form-group"
          [ngClass]="{'visibility-hidden': !approvePatientForm.get('showPatientConsentCheckbox').value }">
          <label class="checkbox">
            <input type="checkbox" id="patientConsent" formControlName="patientConsent" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="patientConsent">I confirm that I have seen and verified patient
            consent</label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="hideApproveModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button type="submit" class="btn btn-primary" [disabled]="!approvePatientForm.valid">
            <span *ngIf="isApprovalFormProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </span>
            <span *ngIf="!isApprovalFormProcessing">Approve</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<!-- Start: Export patient change log modal -->
<app-modal #exportPatientsModal>
  <div class="modal-header">
    <h2 class="modal-title">Export CSV</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
      <div class="checkbox-container">
        <label class="checkbox">
          <input type="checkbox" name="anonymisePatientData" value="true" formControlName="anonymisePatientData"
            id="anonymisePatientData" />
          <span></span>
        </label>
        <label class="info" for="anonymisePatientData">Anonymise patient data</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportPatientsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportPatientData()" class="btn btn-primary" (mousemove)="updateExportFormValues()">
          <span *ngIf="exportIsProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!exportIsProcessing">Export CSV</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export patient change log modal -->

<!-- Start: Merge Patient Modal -->
<app-modal #mergePatientModal customClassName="size-md">
  <div class="modal-header">
    <h2 class="modal-title">Merge Patient?</h2>
  </div>
  <div class="modal-body">
    <p style="margin-left: 15px; margin-right: 15px;">The patient code is already is use on this trial, would you like to merge this patient with the existing record?</p>

    <div class="patient-merge-details" *ngIf="patientApprovalCheck !== undefined && patientApprovalCheck !== null">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h2>Existing Patient</h2>

          <label>Patient Name</label>
          <p>{{ patientApprovalCheck.existingPatientName }}</p>

          <label>Patient Email</label>
          <p>{{ patientApprovalCheck.existingPatientEmail }}</p>

          <label>Patient Code</label>
          <p>{{ patientApprovalCheck.existingPatientCode }}</p>

          <label>Trial Code</label>
          <p>{{ patientApprovalCheck.existingTrialCode }}</p>
        </div>

        <div class="col-12 col-lg-6">
          <h2>Approving Patient</h2>

          <label>Patient Name</label>
          <p>{{ patientApprovalCheck.approvingPatientName }}</p>

          <label>Patient Email</label>
          <p>{{ patientApprovalCheck.approvingPatientEmail }}</p>

          <label>Patient Code</label>
          <p>{{ approvePatientForm.get('patientCode').value }}</p>

          <label>Trial Code</label>
          <p>{{ patientApprovalCheck.approvingTrialCode }}</p>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <a class="btn btn-outline" (click)="approvePatientNoMerge()">
              <span *ngIf="isApprovalFormProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!isApprovalFormProcessing">No, Approve without merge</span>
            </a>
          </div>

          <div class="col-6">
            <a class="btn btn-primary" (click)="mergePatient()">
              <span *ngIf="isApprovalFormProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!isApprovalFormProcessing">Yes, Merge</span>
            </a>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <a class="btn btn-secondary" (click)="cancelMergePatient()" style="margin-top: 20px;">Cancel</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Merge Patient Modal -->

<app-patient-add-modal #addPatientModal (patientCreated)="onPatientCreated()"></app-patient-add-modal>
