import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "../../../../../shared/modal/modal.component";
import { TrialBookingConfigItem } from "../../../../../core/models/trial-booking-config-item.model";
import { TrialBookingService } from "../../../../../core/services/trial-booking.service";
import {
  CreateUpdateBookingConfiguration
} from "../../../../../core/services/interfaces/create-update-booking-configuration.interface";
import { AlertService } from "../../../../../shared/alert/alert.service";
import { StringHelper } from "../../../../../core/helpers/string-helper";

@Component({
  selector: 'app-trial-booking-config-modal',
  templateUrl: './trial-booking-config-modal.component.html',
  styleUrls: ['./trial-booking-config-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrialBookingConfigModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @Output('save') save = new EventEmitter();

  form: UntypedFormGroup;

  config: TrialBookingConfigItem = null;

  constructor(private _trialBookingService: TrialBookingService, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.reset();
  }

  durationValidator(control: UntypedFormControl): {[s: string]: boolean} {
    if (this.form !== undefined && this.form !== null) {
      if (control.value > this.form.get('aptIntervalMinutes').value) {
        return {'durationTooLong': true};
      }
    }

    return null;
  }

  reset(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      trialId: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      dayStartMinutes: new UntypedFormControl('', Validators.required),
      dayEndMinutes: new UntypedFormControl('', Validators.required),
      aptDurationMinutes: new UntypedFormControl(30, [Validators.required, Validators.min(1), this.durationValidator.bind(this)]),
      aptIntervalMinutes: new UntypedFormControl(60, [Validators.required, Validators.min(1)]),
      maxBookingsPerSlot: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
      includeWeekends: new UntypedFormControl(false),
      earliestBookingDays: new UntypedFormControl(0, Validators.required),
      trialEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      supportEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      supportPhone: new UntypedFormControl('', Validators.required),
      inviteIntroHtml: new UntypedFormControl(''),
      inviteIntroText: new UntypedFormControl(''),
      inviteNextStepsHtml: new UntypedFormControl(''),
      inviteNextStepsText: new UntypedFormControl(''),
      confirmationNextStepsHtml: new UntypedFormControl(''),
      confirmationNextStepsText: new UntypedFormControl('')
    });

    this.form.updateValueAndValidity();

    this.config = null;

    this.form.get('inviteIntroText').valueChanges.subscribe(text => {
      let html = StringHelper.formatTextAsHTML(text);
      this.form.patchValue({inviteIntroHtml: html});
    });

    this.form.get('inviteNextStepsText').valueChanges.subscribe(text => {
      let html = StringHelper.formatTextAsHTML(text);
      this.form.patchValue({inviteNextStepsHtml: html});
    });

    this.form.get('confirmationNextStepsText').valueChanges.subscribe(text => {
      let html = StringHelper.formatTextAsHTML(text);
      this.form.patchValue({confirmationNextStepsHtml: html});
    });
  }

  show(trialId: string): void {
    this.reset();
    this.modal.show();

    this.form.patchValue({
      trialId: trialId
    })
  }

  showForEdit(trialId: string, config: TrialBookingConfigItem): void {
    this.reset();
    this.config = config;

    this.form.patchValue({
      trialId: trialId,
      name: config.name,
      dayStartMinutes: this.minutesToTime(config.dayStartMinutes),
      dayEndMinutes: this.minutesToTime(config.dayEndMinutes),
      aptDurationMinutes: config.aptDurationMinutes,
      aptIntervalMinutes: config.aptIntervalMinutes,
      maxBookingsPerSlot: config.maxBookingsPerSlot,
      includeWeekends: config.includeWeekends,
      earliestBookingDays: config.earliestBookingDays,
      trialEmail: config.trialEmail,
      supportEmail: config.supportEmail,
      supportPhone: config.supportPhone,
      inviteIntroHtml: config.inviteIntroHtml,
      inviteIntroText: config.inviteIntroText,
      inviteNextStepsHtml: config.inviteNextStepsHtml,
      inviteNextStepsText: config.inviteNextStepsText,
      confirmationNextStepsHtml: config.confirmationNextStepsHtml,
      confirmationNextStepsText: config.confirmationNextStepsText
    });

    this.modal.show();
  }

  hide(): void {
    this.reset();
    this.modal.hide();
  }

  timeToMinutes(time: string): number {
    const parts = time.split(':');

    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  }

  minutesToTime(minutes: number): string {
    let lz = s => ('00' + s).slice(-2);
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${lz(hours)}:${lz(mins)}`;
  }

  onSubmit(): void {
    let form = this.form;

    if (form.valid) {
      const dto: CreateUpdateBookingConfiguration = {
        name: form.get('name').value,
        dayStartMinutes: this.timeToMinutes(form.get('dayStartMinutes').value),
        dayEndMinutes: this.timeToMinutes(form.get('dayEndMinutes').value),
        aptDurationMinutes: form.get('aptDurationMinutes').value,
        aptIntervalMinutes: form.get('aptIntervalMinutes').value,
        maxBookingsPerSlot: form.get('maxBookingsPerSlot').value,
        includeWeekends: form.get('includeWeekends').value,
        earliestBookingDays: form.get('earliestBookingDays').value,
        trialEmail: form.get('trialEmail').value,
        supportEmail: form.get('supportEmail').value,
        supportPhone: form.get('supportPhone').value,
        inviteIntroHtml: form.get('inviteIntroHtml').value,
        inviteIntroText: form.get('inviteIntroText').value,
        inviteNextStepsHtml: form.get('inviteNextStepsHtml').value,
        inviteNextStepsText: form.get('inviteNextStepsText').value,
        confirmationNextStepsHtml: form.get('confirmationNextStepsHtml').value,
        confirmationNextStepsText: form.get('confirmationNextStepsText').value
      };

      console.log(dto);

      if (this.config !== null) {
        this.onUpdate(this.config.id, form.get('trialId').value, dto);
      } else {
        this.onSaveNew(form.get('trialId').value, dto);
      }
    }
  }

  private onSaveNew(trialId: string, dto): void {
    this._trialBookingService.createConfiguration(trialId, dto).subscribe({
      next: () => {
        this.save.emit();
        this.hide();
        this._alertService.showSuccessAlert('Booking configuration created.');
      },
      error: (error) => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

  private onUpdate(id: string, trialId: string, dto): void {
    this._trialBookingService.updateConfiguration(trialId, id, dto).subscribe({
      next: () => {
        this.save.emit();
        this.hide();
        this._alertService.showSuccessAlert('Booking configuration updated.');
      },
      error: (error) => {
        this._alertService.showErrorAlert(error);
      }
    });
  }

}
