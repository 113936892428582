import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiConsumerSimple } from "../models/api-consumer-simple.model";

;

@Injectable({
  providedIn: 'root'
})
export class ApiConsumerService {
  private _endpoint = '/apiconsumer';

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Returns a list of all API consumers
   */
  getAllApiConsumers() {
    return this.http.get<ApiConsumerSimple>(environment.apiUrl + this._endpoint);
  }
}
