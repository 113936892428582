import {DateHelper} from "../../core/helpers/date-helper";
import {SearchPage} from "../../core/models/search-page.model";

export class ChangeLogItem {
  public description: string;
  public date: Date;
  public name: string;

  public static map(obj: any) {
    let item = new ChangeLogItem();
    item.description = obj.description;
    item.date = DateHelper.dateUTCToLocal(obj.date);
    item.name = obj.name;

    return item;
  }
}

export class ChangeLog extends SearchPage {
  results: ChangeLogItem[] = [];

  constructor(obj: any) {
    super();
    this.fromResponse(obj);

    for (let log of obj.results) {
      this.results.push(ChangeLogItem.map(log));
    }
  }
}
