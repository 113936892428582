import { ExpenseClaimState } from './expense.model';
export class SearchPage {
  public currentPage: number;
  public pageSize: number;
  public totalRecordCount: number;
  public filteredRecordCount: number;
  public totalPageCount: number;
  public policyReminders: string;
  public loaded = false;
  public loading = false;

  protected fromResponse(response: any) {
    this.currentPage = response.currentPage;
    this.pageSize = response.pageSize;
    this.totalRecordCount = response.totalRecordCount;
    this.filteredRecordCount = response.filteredRecordCount;
    this.policyReminders = response.policyReminders;
    this.totalPageCount = Math.ceil(response.filteredRecordCount / response.pageSize);

    return this;
  }
}

export class ResponsePage<T>{
  results: T[];
  currentPage: number;
  totalPageCount: number;
  filteredPageCount: number;
}

export class ExpenseClaimsResponsePage<T> extends ResponsePage<T>{
  claimsCount: { [key in ExpenseClaimState]: number };
}
