import { ExpenseClaimState } from './../../../../core/models/expense.model';
import { Component, Input, OnInit } from '@angular/core';
import { DateHelper } from 'app/core/helpers/date-helper';
import { ExpenseClaimPaidBy, ExpenseClaimViewModel } from 'app/core/models/expense.model';
import { OverBudgetRequestDetailsStatus } from 'app/core/models/project-management.model';

@Component({
  selector: 'app-expense-state-badge',
  templateUrl: './expense-state-badge.component.html',
  styleUrls: ['./expense-state-badge.component.scss']
})
export class ExpenseStateBadgeComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;
  @Input() dataLoaded: boolean;

  ExpenseClaimState = ExpenseClaimState;
  OverBudgetRequestDetailsStatus = OverBudgetRequestDetailsStatus;

  constructor() { }

  ngOnInit(): void {
  }

  getPaidByText(paidBy: ExpenseClaimPaidBy) {
    switch (paidBy) {
      case ExpenseClaimPaidBy.Finance:
        return 'Paid via Finance';
      case ExpenseClaimPaidBy.Caxton:
        return 'Paid via Caxton Bank';
      default:
        return 'Paid via Caxton Card';
    }
  }

  getBadgeClass() {
    switch (this.expense.state) {
      case ExpenseClaimState.Pending:
      case ExpenseClaimState.CaxtonFailed:
        return 'badge-warning';
      case ExpenseClaimState.Approved:
      case ExpenseClaimState.CaxtonApproved:
      case ExpenseClaimState.Processing:
        return 'badge-success';
      case ExpenseClaimState.Rejected:
        return 'badge-danger';
      default:
        return 'badge-grey';
    }
  }

  getBadgeText() {
    switch (this.expense.state) {
      case ExpenseClaimState.CaxtonApproved:
        return 'Caxton Approved';
      case ExpenseClaimState.CaxtonFailed:
        return 'Caxton Failed';
      case ExpenseClaimState.Processing:
        return 'Payment Processing';
      default:
        return this.expense.state;
    }
  }

  isCaxtonTab() {
    return this.selectedTab === ExpenseClaimState.CaxtonApproved || this.selectedTab === ExpenseClaimState.Processing || this.selectedTab === ExpenseClaimState.CaxtonFailed;
  }

  getLocalDate(date: Date) {
    return this.isCaxtonTab() ? DateHelper.dateUTCToLocal(date) : date;
  }
}
