import { DateHelper } from './../../../../core/helpers/date-helper';
import { Component, Input, OnInit } from '@angular/core';
import { ExpenseClaimState, ExpenseClaimViewModel } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-edit-reasons',
  templateUrl: './expense-edit-reasons.component.html',
  styleUrls: ['./expense-edit-reasons.component.scss']
})
export class ExpenseEditReasonsComponent implements OnInit {
  @Input() expense: ExpenseClaimViewModel;
  @Input() selectedTab: ExpenseClaimState;

  constructor() { }

  ngOnInit(): void {
  }

  isCaxtonTab() {
    return this.selectedTab === ExpenseClaimState.CaxtonApproved || this.selectedTab === ExpenseClaimState.Processing || this.selectedTab === ExpenseClaimState.CaxtonFailed;
  }
  getLocalDate(date: Date){
    return this.isCaxtonTab() ? DateHelper.dateUTCToLocal(date) : date;
  }
}
