import { Component, Input } from '@angular/core';
import { ExpenseImage } from 'app/core/models/expense-image.model';
import { LogHelper } from '../../../../core/helpers/log.helper';
import { FileDownloadService } from "../../../../core/services/file-download.service";

@Component({
  selector: 'app-expense-images',
  templateUrl: './expense-images.component.html',
  styleUrls: ['./expense-images.component.scss']
})
export class ExpenseImagesComponent {
  @Input() imagery: ExpenseImage[] = [];

  constructor(private _fileDownloadService: FileDownloadService) { }

  public uploadIsPdf(uploadUrl: string) {
    let parts = uploadUrl.toLowerCase().split('?');
    return parts[0].endsWith('.pdf');
  }

  onDownloadFile(filename: string) {
    this._fileDownloadService.downloadExpenseFile(filename).subscribe({
      next: (blob) => {
        this.downloadBlob(blob, filename);
      },
      error: (err) => {
        LogHelper.log(err);
      }
    });
  }

  private downloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
