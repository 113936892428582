import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements AfterViewInit {
  @Input('rating') rating = 0;
  stars = [
    'assets/img/icon-star-off.svg',
    'assets/img/icon-star-off.svg',
    'assets/img/icon-star-off.svg',
    'assets/img/icon-star-off.svg',
    'assets/img/icon-star-off.svg'
  ];

  ngAfterViewInit() {
    this.updateStars(this.rating);
  }

  updateStars(index: number) {
    for (let i = 0; i < this.stars.length; i++) {
      if (i < index) {
        this.stars[i] = 'assets/img/icon-star.svg';
      } else {
        this.stars[i] = 'assets/img/icon-star-off.svg';
      }
    }
  }
}
