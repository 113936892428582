export interface CaxtonCardViewModel {
  id: string;
  name: string;
  delivery?: CaxtonCardDelivery;
  expiry?: Date;
  address: string;
  siteContact: string;
  dateSent?: Date;
  courierCost: string;
  trackingNumber: string;
  status?: CaxtonCardStatus;
  cardInfo: string;
  trialId: string;
  siteId: string;
  patientId: string;
  oracleCode: string;
}

export interface AddCaxtonCardRequest {
  numberOfCards: number;
  lastCardNo: number;
}

export interface UpdateCaxtonCardRequest {
  id: string;
  name: string;
  delivery?: CaxtonCardDelivery;
  trialId: string;
  siteId: string;
  address: string;
  siteContact: string;
  dateSent: string;
  expiry: string;
  courierCost: string;
  trackingNumber: string;
  status?: CaxtonCardStatus;
  cardInfo: string;
}

export interface GetCaxtonCardsRequest {
  page: number;
  filter: CaxtonCardTypeFilter;
  trialId?: string;
  searchTerm?: string;
}

export interface UpdateCaxtonCardsDeliveryStatusRequest {
  cardIds: string[];
  delivery: CaxtonCardDelivery;
}

export interface AssignCardsRequest {
  cardIds: string[];
  trialId: string;
  siteId: string;
  siteContact: string;
  patientDetails: string;
}

export interface ExportCaxtonCardsRequest {
  startDate: string;
  endDate: string;
  trialId: string;
}

export enum CaxtonCardTypeFilter {
  AllCards,
  Missing,
  NotSent
}

export enum MarkCaxtonCardAs {
  Received,
  Sent,
  Missing
}

export enum CaxtonCardDelivery {
  Received,
  Sent,
  Missing
}

export enum CaxtonCardStatus {
  Activated,
  Archived
}
