import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenseCategoryListComponent } from './expense-category-list/expense-category-list.component';
import {RouterModule} from "@angular/router";
import { ExpenseCategoryAddComponent } from './expense-category-add/expense-category-add.component';
import {ReactiveFormsModule} from "@angular/forms";
import { ExpenseCategoryEditComponent } from './expense-category-edit/expense-category-edit.component';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [ExpenseCategoryListComponent, ExpenseCategoryAddComponent, ExpenseCategoryEditComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ExpenseCategoryModule { }
