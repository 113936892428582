export class GlobalConstants {
  public static readonly userRolesStorageKey: string = 'user_roles';
  public static readonly userClaimsStorageKey: string = 'user_claims';

  public static readonly urlValidatorPattern: RegExp = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-\/]))?/;
  public static readonly emailValidatorPattern: RegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  //RFC5322 standard regex taken from: https://www.abstractapi.com/guides/email-address-pattern-validation#rfc-5322-official-standard-regular-expression-to-validate-email-addresses
  public static readonly emailValidatorRFC5322Pattern: RegExp = /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~а-яА-Я]+(\.[-!#-'*+/-9=?A-Z^-~а-яА-Я]+)*|\[[\t -Z^-~]*])$/;
  public static readonly telephoneNumberPattern: RegExp = /^(\+)?[0-9 -]{1,20}$/;
}
