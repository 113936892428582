import { SelectOption } from "../models/select-option.model";

export class PatientStateOnTrialOptions {
  public static readonly Active: string = "Active";
  public static readonly ScreenFail: string = "ScreenFail";
  public static readonly Dropout: string = "Dropout";
  public static readonly Withdrawn: string = "Withdrawn";
  public static readonly Completed: string = "Completed";
  public static readonly RequestedInError: string = "RequestedInError";
  public static readonly DuplicateAccount: string = "DuplicateAccount";

  public static all(): SelectOption[] {
    return [
      {
        value: "Active",
        text: "Active"
      },
      {
        value: "ScreenFail",
        text: "Screen Fail"
      },
      {
        value: "Dropout",
        text: "Dropout"
      },
      {
        value: "Withdrawn",
        text: "Withdrawn"
      },
      {
        value: "Completed",
        text: "Completed"
      },
      {
        value: "RequestedInError",
        text: "Requested In Error"
      },
      {
        value: "DuplicateAccount",
        text: "Duplicate Account"
      }
    ]
  }
}
