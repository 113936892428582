import { BankRequirements, CountryInfo } from './payment.models';

export interface CreatePatientResponse {
  patientId: string;
  patientTrialId: string;
}

export interface PatientPaymentInfoUpdateRequest {
  patientTrialId: string;
  defaultPaymentMethod: PaymentMethod;
  bankTransferMethod: BankTransferMethod;
  caxtonWalletName: string;
  caxtonWalletId: number;
  title: string;
  firstName: string;
  lastName: string;
  beneficiaryDetails: CaxtonPatientPaymentInfoRequest;
  initialBankCoverFee: number;
  bankCoverFeeCurrency: string;
  accountOwnerAddress: string;
  bankCoverFees: BankCoverFee[];
}

export interface CaxtonPatientPaymentInfoRequest {
  country: string;
  currency: string;
  iban: string;
  swift: string;
  sortCode: string;
  accountNumber: string;
}

export interface AssignAndMoveWalletRequest {
  patientTrialId: string;
  walletId: string;
  walletName: string;
  walletAccountHolder: string;
  walletIsOnChildSubsidiary: boolean;
}

export interface AssignWalletResult {
  walletName: string;
  walletId: string;
  cardAlreadyActive: boolean;
}

export interface AssignWalletOnParentRequest {
  caxtonWalletName: string;
  caxtonWalletId: string;
  trialId: string;
  patientTrialId: string;
}

export interface AvailableWalletsResponse {
  walletAccountHolder: string;
  walletName: string;
  walletId: string;
  walletIsOnChildSubsidiary: boolean;
}

export interface PatientPaymentInfo {
  defaultPaymentMethod: PaymentMethod;
  bankTransferMethod: BankTransferMethod;
  caxtonWalletName: string;
  caxtonWalletId: string;
}

export interface FullPatientPaymentInfo extends PatientPaymentInfo {
  caxtonBeneficiaryId: string;
  trialCaxtonEnabled: boolean;
  caxtonOnlineAccessGranted: boolean;
  caxtonCardsActivated: boolean;
  firstName: string;
  lastName: string;
  title: string;
  previousBeneficiaryIds: string[];
  beneficiaryDetails: BeneficiaryDetails;
  countries: CountryInfo[];
  bankRequirements: BankRequirements;
  initialBankCoverFee: number;
  bankCoverFeeCurrency: string;
  accountOwnerAddress: string;
  bankCoverFees: BankCoverFee[];
}

export interface CreatePatientRegistrationRequest {
  siteContactId: string;
  patientRegistrationCode: string;
  siteNumber: string;
  trialCode: string;
  investigatorName: string;
  patientName: string;
  patientNumber: string;
  yearOfBirth: number;
  address: string;
  phoneNumber: string;
  email: string;
  contactName: string;
  contactPhoneNumber: string;
  contactRelationship: string;
  signatureFilename: string;
}

export interface UploadSignatureResponse {
  signatureFilename: string;
}

export interface PatientRegistrationListViewModel {
  id: string;
  patientName: string;
  patientNumber: string;
  siteNumber: string;
}

export interface PatientRegistrationViewModel extends PatientRegistrationListViewModel {
  trialCode: string;
  investigatorName: string;
  yearOfBirth: number;
  address: string;
  phoneNumber: string;
  email: string;
  contactName: string;
  contactPhoneNumber: string;
  contactRelationship: string;
  signatureUrl: string;
}

export interface BankCoverFee {
  thresholdValue: number;
  feeValue: number;
  id: string
}

export interface BeneficiaryDetails {
  iban: string;
  bankCode: string;
  sortCode: string;
  swift: string;
  accountNumber: string;
  currency: string;
  country: string;
}

export enum PaymentMethod {
  Bank = 0,
  Card = 1
}

export class PaymentMethodString {
  public static Values = {
    0: "Bank Transfer",
    1: "Caxton Card"
  }
}

export enum BankTransferMethod {
  Manual = 0,
  CaxtonAutomated = 1
}

export class BankTransferMethodString {
  public static Values = {
    0: "Manual",
    1: "Caxton Automated"
  }
}

export class CaxtonTitle {
  public static readonly Mr: string = "Mr";
  public static readonly Mrs: string = "Mrs";
  public static readonly Miss: string = "Miss";
  public static readonly Ms: string = "Ms";
  public static readonly Dr: string = "Dr";
  public static readonly Lord: string = "Lord";
  public static readonly Professor: string = "Professor";
  public static readonly Reverend: string = "Reverend";
  public static readonly Mx: string = "Mx";

  public static all(): string[] {
    return [
      this.Mr,
      this.Mrs,
      this.Miss,
      this.Ms,
      this.Dr,
      this.Lord,
      this.Professor,
      this.Reverend,
      this.Mx
    ]
  }
}

export class Gender {
  public static readonly Male: string = "Male";
  public static readonly Female: string = "Female";
  public static readonly Other: string = "Other";

  public static all(): string[] {
    return [
      this.Male,
      this.Female,
      this.Other,
    ]
  }
}

export class PatientBasicInfo {
  public firstName: string;
  public lastName: string;
  public email: string;
}

export interface AssignCardRequest {
  patientTrialId: string;
  walletId: string;
  caxtonRequest: CaxtonAssignCardRequest;
}

export interface ActivateCardsRequest {
  patientTrialId: string;
  walletId: string;
}

export interface GetCardPinRequest extends ActivateCardsRequest {
}

export interface CaxtonAssignCardRequest {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
}

export interface AssignCardResult {
  onlineAccessGranted: boolean;
}

export interface ActivateCardsResult {
  cardsActivatedSuccessfully: boolean;
  cardsOnlineAccessGranted: boolean;
}

export interface DeletePatientRequest {
  patientId: string;
  closingState: string;
  reason: string;
}

export interface ActivePatientTrialCodesViewModel {
  code: string;
}

export interface CardPinViewModel {
  cardPinImageUrl: string;
}

export class GetWalletBalanceRequest {
  patientTrialId: string;
  walletId?: string;
}

export interface WalletBalancesViewModel {
  balances: WalletBalanceViewModel[];
}

export interface WalletBalanceViewModel {
  value: number;
  symbol: string;
}

export interface UpdateEntityAmountRequest {
  entityId: string;
  amount: number;
}

export interface UpdateEntityCurrencyRequest {
  entityId: string;
  currency: string;
}

export interface UpdatePatientTripInvoicedAmountRequest extends UpdateEntityAmountRequest {
}

export interface UpdatePatientTripInvoicedCurrencyRequest extends UpdateEntityCurrencyRequest {
}

export interface UpdatePatientTripInvoicedAmountBcRequest extends UpdateEntityAmountRequest {
}

export interface UpdatePatientTripQuotedAmountRequest extends UpdateEntityAmountRequest {
}

export interface UpdatePatientTripQuotedAmountBcRequest extends UpdateEntityAmountRequest {
}

export interface UpdatePatientTripQuotedCurrencyRequest extends UpdateEntityCurrencyRequest {
}
