<div *ngIf="dataLoaded" class="badge-wrap">
  <span class="badge" [ngClass]="getBadgeClass()">
    {{expense.state | enumToText:ExpenseClaimState}}
  </span>
  <span *ngIf="expense.state === ExpenseClaimState.Pending && (expense.overBudgetRequestStatus !== null || expense.overBudgetRequestRaised)"
    class="over-budget over-budget-{{(expense.overBudgetRequestStatus | enumToText: OverBudgetRequestDetailsStatus)?.replace(' ', '-') | lowercase}}">
    <mat-icon>error</mat-icon>
    Over budget - {{expense.overBudgetRequestStatus !== null ? (expense.overBudgetRequestStatus | enumToText:
    OverBudgetRequestDetailsStatus) : 'Pending'}} decision
  </span>
  <span class="over-budget referred-back" *ngIf="expense.state === ExpenseClaimState.Pending && expense.referredBack">
    <mat-icon>error</mat-icon>
    Over budget - Referred back
  </span>

  <span *ngIf="selectedTab === ExpenseClaimState.Paid" class="expense-paid-by">
    {{ getPaidByText(expense.paidBy) }}
  </span>

  <span class="label"
    *ngIf="(expense.state === ExpenseClaimState.Approved || expense.state === ExpenseClaimState.CaxtonApproved) && expense.dateApproved">
    on {{ getLocalDate(expense.dateApproved) | date: 'd MMM yyyy' }} at {{ getLocalDate(expense.dateApproved) | date:
    'h:mm aaa' }}
  </span>

  <span class="label" *ngIf="expense.state === ExpenseClaimState.Rejected && expense.dateRejected">
    on {{ getLocalDate(expense.dateRejected) | date: 'd MMM yyyy' }} at {{ getLocalDate(expense.dateRejected) | date:
    'h:mm aaa' }}
  </span>

  <span class="label" *ngIf="expense.state === ExpenseClaimState.Paid && expense.datePaid">
    on {{ getLocalDate(expense.datePaid) | date: 'd MMM yyyy' }} at {{ getLocalDate(expense.datePaid) | date: 'h:mm aaa'
    }}
  </span>
</div>

<div *ngIf="!dataLoaded" class="badge-wrap">
  <div class="loaders">
    <ngx-skeleton-loader class="badge-loader" style="width: 10%;"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="selectedTab !== ExpenseClaimState.Pending" class="approved-date-loader"
      style="width: 30%;"></ngx-skeleton-loader>
  </div>
</div>